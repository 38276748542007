import { schema } from 'normalizr'

const labelsSchema = new schema.Entity(
    'labels',
    {},
    {
        idAttribute: 'office_label_id',
    }
)

export default [labelsSchema]
