import React from 'react'
import { Col, Row } from '@advanza/grid'
import { Button, Divider } from '@advanza/ui'
import { saveProvider } from 'actions/providers'
import { useChangeEntity } from 'hooks/entityHooks'

function getFields() {
    return {
        franchise_status: {
            type: 'select',
            options: [
                { value: 1, title: 'Read-only franchise' },
                { value: 2, title: 'Full-access franchise' },
            ],
        },
    }
}

const FranchiseSettings = ({ providerId }) => {
    const {
        entity: provider,
        renderInput,
        onSaveEntity,
    } = useChangeEntity(
        {
            store: 'providers',
            name: 'providers',
            entityId: providerId,
            saveFunc: saveProvider,
        },
        getFields()
    )

    return (
        provider && (
            <>
                {renderInput('franchise_status')}
                <Divider m />
                <Row end="xs">
                    <Col x>
                        <Button
                            disabled={!provider._isTouched || provider._saving}
                            onClick={onSaveEntity}>
                            {provider._saving ? '..saving' : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </>
        )
    )
}

export default FranchiseSettings
