import { useCalendlyWindowEvents } from 'hooks/calendlyHooks'
import React from 'react'
import Page from '../ui/Page'
import ProviderView from './ProviderView'

const ProviderViewPage = ({ match }) => {
    const { id, tab } = match.params
    useCalendlyWindowEvents()

    return (
        <Page title="Providers">
            <ProviderView providerId={id} tab={tab} />
        </Page>
    )
}

export default ProviderViewPage
