import { schema } from 'normalizr'

const adminUsersSchema = new schema.Entity(
    'adminUsers',
    {},
    {
        idAttribute: 'id',
    }
)

export default [adminUsersSchema]
