import { Icon, Select } from '@advanza/advanza_generic'
import { localToUTC } from 'date'
import PropTypes from 'prop-types'
import React from 'react'
import LabelPickerContainer from '../../containers/LabelPickerContainer'
import OfficeLabel from '../OfficeLabel'

class LabelPicker extends React.Component {
    constructor(props) {
        super(props)
        this.filterLabels = this.filterLabels.bind(this)
        this.renderItem = this.renderItem.bind(this)
        this.onChangeLabels = this.onChangeLabels.bind(this)
    }

    componentDidMount() {
        const { fetchLabels, categories } = this.props
        fetchLabels({
            searchKey: categories.join('_'),
            categories: categories,
            noLimit: true,
        })
    }

    filterLabels(label) {
        const { categories, filter } = this.props

        const isNew = typeof label.office_label_id === 'string'
        const hasCategory = !categories || categories.indexOf(label.category) !== -1
        return !isNew && hasCategory && (filter ? filter(label) : true)
    }

    renderItem(label, i) {
        const { categories } = this.props
        const hasCategory = !categories || categories.indexOf(label.category) !== -1

        if (hasCategory) {
            return <OfficeLabel key={i} label={label} />
        }
    }

    onChangeLabels(values) {
        const { onValueChange } = this.props
        const addUserId = (value) => {
            const { user_id, created } = value._joinData || {
                user_id: window._user.id,
                created: localToUTC(new Date()),
            }
            return {
                ...value,
                _joinData: {
                    ...value._joinData,
                    user_id,
                    created,
                },
            }
        }

        const newValues = values.map(addUserId)

        onValueChange(newValues)
    }

    render() {
        const {
            labels,
            selectedLabelIds,
            labelIds = [],
            wrapValues,
            classNames,
            isStatusLabel,
            disabledCategories = [],
            placeholderAdd = 'label',
            ...rest
        } = this.props
        const options = labelIds
            .map((id) => ({
                ...labels[id],
                disabled: disabledCategories.indexOf(labels[id].category) !== -1,
            }))
            .filter(this.filterLabels)
        const placeholder = isStatusLabel ? (
            <small className="text">
                <Icon color="purp-grey" small>
                    label_important
                </Icon>{' '}
                set status
            </small>
        ) : (
            <small className="c-grey">
                <Icon color="grey" small>
                    add
                </Icon>{' '}
                {placeholderAdd}
            </small>
        )

        const className = [
            'select-min',
            'select-stealth',
            'select-wide',
            ...(classNames || []),
        ].join(' ')
        const value =
            rest.value || (labels && selectedLabelIds && selectedLabelIds.map((id) => labels[id]))
        return (
            <Select
                options={options}
                className={className}
                multiple
                unShiftValue
                wrapValues={wrapValues}
                placeholder={placeholder}
                renderItem={this.renderItem}
                useSearch
                optionKeys={{
                    valueKey: 'office_label_id',
                    msgKey: 'description',
                    queryKey: 'description',
                }}
                value={value}
                onValueChange={this.onChangeLabels}
            />
        )
    }
}

LabelPicker.propTypes = {
    categories: PropTypes.array,
    disabledCategories: PropTypes.array,
    classNames: PropTypes.array,
    isStatusLabel: PropTypes.bool,
    onValueChange: PropTypes.func,

    // from container
    value: PropTypes.array,
    labelIds: PropTypes.array,
    labels: PropTypes.object,
    fetchLabels: PropTypes.func,
}

export default LabelPickerContainer(LabelPicker)
