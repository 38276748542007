import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, LoadingDots, PreIcon } from '@advanza/ui'
import { reclaimMatch } from 'actions/matches'
import { reclaimPhoneClick } from 'actions/phoneClicks'
import { reclaimWebsiteClick } from 'actions/websiteClicks'
import UndoReclaimButton from 'components/reclaims/UndoReclaimButton'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import DatePicker from 'components/DatePicker'

function useReclaimFunc(leadType, id) {
    const dispatch = useDispatch()
    return (date) => {
        if (leadType === 'matches') {
            return dispatch(reclaimMatch(id, date))
        } else if (leadType === 'phoneClicks') {
            return dispatch(reclaimPhoneClick(id, date))
        } else if (leadType === 'websiteClicks') {
            return dispatch(reclaimWebsiteClick(id, date))
        }
    }
}

const ManualReclaimButton = ({ lead, leadType, id }) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [date, setDate] = useState(false)
    const [showCustomDate, setShowCustomDate] = useState(false)
    const reclaimFunc = useReclaimFunc(leadType, id)
    const onReclaim = () => {
        setLoading(true)
        reclaimFunc(date).then(
            () => {
                setLoading(false)
                setOpen(false)
            },
            ({ error }) => {
                setLoading(false)
                setError(error)
            }
        )
    }
    const recColor =
        lead.reclaimId && lead.reclaimDenied
            ? { red: true }
            : lead.reclaimId
            ? { orange: true }
            : {}
    return (
        <div>
            {lead.reclaimed ? (
                <small>
                    reclaimed {leadType !== 'websiteClicks' && <UndoReclaimButton lead={lead} />}
                </small>
            ) : (
                <button onClick={() => setOpen(true)}>
                    <Icon name="replay" {...recColor} />
                </button>
            )}
            <Modal open={open} close={() => setOpen(false)}>
                <h3>
                    {loading ? (
                        <Fragment>
                            Reclaiming <LoadingDots />
                        </Fragment>
                    ) : error ? (
                        'Error'
                    ) : (
                        'Reclaim lead'
                    )}
                </h3>
                {lead.reclaimDenied && (
                    <b style={{ color: 'red' }}>
                        Deze reclaim is staat op denied. Weet je zeker dat je hem wilt goedkeuren?
                    </b>
                )}
                {!lead.reclaimDenied && lead.reclaimId && (
                    <b style={{ color: 'orange' }}>Deze match heeft een openstaande reclamatie.</b>
                )}
                {lead.advanzaId && (
                    <a
                        style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}
                        href={`https://leads.advanza.nl/Company/wrapper?company_id=${lead.advanzaId}`}>
                        {' '}
                        Let op! dit bedrijf is ook aanwezig op advanza.
                    </a>
                )}
                <Divider m />
                {showCustomDate ? (
                    <Row>
                        <Col x>
                            <DatePicker value={date} onChange={(e) => setDate(e.target.value)} opened />
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <Button name="text" onClick={() => setShowCustomDate(true)}>
                            <PreIcon icon="keyboard_arrow_down">set custom date</PreIcon>
                        </Button>
                        <Divider />
                    </div>
                )}
                <Divider m />
                <Row middle="xs">
                    <Col x>
                        {!error && lead.reclaimId && !lead.reclaimDenied ? (
                            ''
                        ) : !error ? (
                            <Button onClick={onReclaim}>
                                {loading ? <LoadingDots color="#dadada" /> : 'Confirm reclaim'}
                            </Button>
                        ) : (
                            error
                        )}
                    </Col>
                    <Col x>
                        <button onClick={() => setOpen(false)}>cancel</button>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default ManualReclaimButton
