import { Icon, PreIcon } from '@advanza/ui'
import { useDeleteModal } from 'hooks/modalHooks'
import { useProfileProjectsPhoto } from 'hooks/profileProjectsHooks'
import { Fragment } from 'react'
import style from './profileProjectsPhotosEntity.module.css'

const ProfileProjectsPhotosEntity = ({ fileId, projectId }) => {
    const { entity, getImgStyle, onDelete } = useProfileProjectsPhoto(fileId, projectId)
    const { openModal: openDeleteModal, modal: deleteModal } = useDeleteModal(
        entity._saving,
        onDelete,
        'photo'
    )

    const renderPhoto = () => {
        const hasError = entity && entity._errors && entity._errors.error
        return (
            <div
                className={[style.bgImg, hasError ? style.withError : ''].join(' ')}
                style={getImgStyle()}>
                <div
                    className={[style.editBtn, style.flexCenter].join(' ')}
                    onClick={openDeleteModal}>
                    <Icon fontSize={14} name="clear" white />
                </div>
                {hasError && (
                    <div className={style.errorMsg}>
                        <PreIcon name="error" fontSize={22} red>
                            <span>{entity._errors.error}</span>
                        </PreIcon>
                    </div>
                )}
            </div>
        )
    }

    return (
        <Fragment>
            {renderPhoto()}
            {deleteModal}
        </Fragment>
    )
}

export default ProfileProjectsPhotosEntity
