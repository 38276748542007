import { Fragment } from 'react'

interface Props {
    text: string
    paragraphs?: boolean
}

const BreakText = ({ text, paragraphs }: Props) => {
    const splittedText = text.split('\n')

    return (
        <>
            {splittedText.map((item, key) => {
                const isLastItem = key === splittedText.length - 1
                if (item.length === 0) {
                    return !isLastItem ? <br key={key} /> : null
                }
                if (paragraphs) {
                    return <p key={key}>{item}</p>
                } else {
                    return (
                        <Fragment key={key}>
                            <span>{item}</span>
                            {!isLastItem && <br />}
                        </Fragment>
                    )
                }
            })}
        </>
    )
}

export default BreakText
