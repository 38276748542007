import { Divider } from '@advanza/ui'
import ExpectationSettingsList from 'components/expectationsettings/ExpectationSettingsList'
import Page from 'components/ui/Page'

const ExpectationSettingsPage = () => (
    // @ts-ignore
    <Page title="Expectation settings">
        <Divider />
        <ExpectationSettingsList />
    </Page>
)

export default ExpectationSettingsPage
