import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    appendMapsScript,
    mapsPromise,
} from '@advanza/advanza_generic/src/services/googleMapsLoader'

class ServiceAreaMap extends Component {
    constructor(props) {
        super(props)

        this.mapsElement = null

        this.initMap = this.initMap.bind(this)
    }

    componentDidMount() {
        appendMapsScript()
        this.initMap()
    }

    componentDidUpdate() {
        this.initMap()
    }

    initMap() {
        if (!this.mapsElement) {
            return
        }
        mapsPromise().then((google) => {
            const postalCode = this.props.postalCodeEntity
            const centerPoint = { lat: postalCode.latitude, lng: postalCode.longitude }
            const distance = postalCode.distance
            this.maps =
                this.maps ||
                (google.maps &&
                    new google.maps.Map(this.mapsElement, {
                        disableDefaultUI: true,
                    }))
            if (!this.maps) {
                return null
            }
            this.maps.setOptions({
                center: centerPoint,
                zoom: distance > 50 ? 6 : distance > 30 ? 7 : 8,
            })
            this.circle =
                this.circle ||
                new google.maps.Circle({
                    strokeColor: '#ff6f00',
                    strokeOpacity: 0.8,
                    strokeWeight: 0.5,
                    fillColor: '#ff6f00',
                    fillOpacity: 0.35,
                    map: this.maps,
                })
            this.circle.setOptions({
                center: centerPoint,
                radius: distance * 1000,
            })
        })
    }

    render() {
        return (
            <div
                style={{ width: '100%', height: '200px' }}
                id="maps"
                ref={(el) => (this.mapsElement = el)}
            />
        )
    }
}

ServiceAreaMap.propTypes = {
    postalCodeEntity: PropTypes.object.isRequired,
}

export default ServiceAreaMap
