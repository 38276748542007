import { Row, Col } from '@advanza/grid'
import { Button, LoadingDots, Divider, Icon, LinearLoader } from '@advanza/ui'
import { useOptionPages } from 'components/services/AddOptionPageButton'
import AddServiceToSanity from 'components/services/AddServiceToSanity'
import SafeButton from 'components/ui/SafeButton'
import React from 'react'

const OptionPageForm = ({ id, serviceId }) => {
    const { entity, deleteOptionPage, isLoading, renderInput, onSaveEntity } = useOptionPages({
        optionPageId: id,
        serviceId,
    })

    return (
        <div>
            {isLoading === id && <LinearLoader absolute />}
            <Divider m />
            <Row middle="xs">
                <Col xs>{renderInput('keyword_singular')}</Col>
                <Col x>
                    <AddServiceToSanity serviceId={serviceId} optionPageId={id} />
                </Col>
            </Row>

            <Divider m />
            {renderInput('keyword_plural')}
            <Divider m />
            {renderInput('project_goal_sentence')}
            <Divider m />
            {renderInput('paragraph')}
            <Divider m />
            {renderInput('paragraph_costs')}
            <Divider m />
            <Row between="xs" center="xs">
                <Col x>
                    <Button onClick={onSaveEntity}>
                        {entity._saving ? <LoadingDots /> : 'Save'}
                    </Button>
                </Col>
                <Col x>
                    <SafeButton
                        action={() => deleteOptionPage(id)}
                        buttonText={<Icon name="delete_forever" />}
                        confirmMessage="Click again to delete"
                    />
                </Col>
            </Row>

            <Divider />
        </div>
    )
}

export default OptionPageForm
