import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import SearchPro from 'components/misc/SearchPro'
import ProfileTabs from 'components/ui/ProfileTabs'
import PropTypes from 'prop-types'
import React from 'react'

const NavigationTop = ({ title }) => {
    const userName = window._user.username
    return (
        <Row between="xs" middle="xs" style={{ flexWrap: 'nowrap' }}>
            <Col x>
                <div className="title-sm">{title || 'Office'}</div>
            </Col>
            <Col x>
                <Divider />
                <SearchPro />
            </Col>
            <Col x>
                <ProfileTabs />
            </Col>
            <Col x>
                <Row>
                    <Col x>{userName}</Col>
                    <Col x>
                        <a href="/api/auth/logout">logout</a>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

NavigationTop.propTypes = {
    title: PropTypes.string,
}

export default NavigationTop
