import { capitalize } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { InputField, SelectCheckbox } from '@advanza/input'
import { Divider, Icon, PreIcon } from '@advanza/ui'
import Pagination from 'components/Pagination'
import Restricted from 'components/Restricted'
import ServicesSelect from 'components/services/ServicesSelect'
import { useDidMount } from 'hooks/miscHooks'
import { DAY_SCHEMES, PAUSE_TYPES, useFilterProvidersDates } from 'hooks/providersDatesHooks'
import { getFlag } from 'misc/flags'
import queryString from 'query-string'
import { useEffect, useState } from 'react'

const ActivePausesFilter = ({ filterId }) => {
    const {
        filter = {},
        countries,
        providersDatesEntities,
        count,
        countOnPage,
        onChangeFilter,
        onInvalidateFilter,
    } = useFilterProvidersDates(filterId)

    const didMount = useDidMount()
    const { pauseReasons = {} } = providersDatesEntities
    const [query, setQuery] = useState('')
    const onServiceIdsChange = (values) => {
        onChangeFilter({ serviceIds: values, page: 0 })
    }
    const onQueryChange = (e) => {
        const { value } = e.target
        setQuery(value)
        onChangeFilter({ query: value, page: 0 })
    }
    const onPageChange = (page, invalidate = false) => {
        onChangeFilter({ page, didInvalidate: invalidate })
    }
    const onCountList = () => {
        onChangeFilter({ countList: true, didInvalidate: true })
    }

    const onPauseReasonIdsChange = (values) => {
        onChangeFilter({ pauseReasonIds: values, didInvalidate: true })
    }

    const onPauseTypesChange = (values) => {
        onChangeFilter({ pauseTypes: values, didInvalidate: true })
    }

    const onDaySchemesChange = (values) => {
        onChangeFilter({ daySchemes: values, didInvalidate: true })
    }

    useEffect(() => {
        onChangeFilter({
            pageSize: 20,
            active: true,
            daySchemes: [0],
            countries,
            onlyPremium: true,
        })
    }, [])

    useEffect(() => {
        if (didMount) {
            onChangeFilter({ countries })
        }
    }, [countries])

    const { protocol, hostname } = window.location

    return (
        <>
            <Row middle="xs">
                <Col x>
                    <h2>Active Pauses</h2>
                </Col>
                <Col x style={{ marginLeft: 'auto' }}>
                    <Restricted location="export" checkWrite={false}>
                        <PreIcon name="exit_to_app">
                            <a
                                target="_blank"
                                href={`${protocol}//${hostname}/api/office/providers/export-providers-dates/?${queryString.stringify(
                                    { ...filter },
                                    { arrayFormat: 'bracket' }
                                )}`}>
                                Export
                            </a>
                        </PreIcon>
                    </Restricted>
                </Col>
            </Row>
            <Divider m />
            <Row middle="xs">
                <Col x>
                    <ServicesSelect
                        large
                        onChangeValue={onServiceIdsChange}
                        value={filter.serviceIds || []}
                    />
                </Col>
                <Col x>
                    <SelectCheckbox
                        large
                        options={Object.keys(pauseReasons)
                            .filter((reasonId) =>
                                countries.includes(pauseReasons[reasonId].country_code)
                            )
                            .map((reasonId) => {
                                const reason = pauseReasons[reasonId]
                                return {
                                    value: reason.pause_reason_id,
                                    name: (
                                        <>
                                            {getFlag(reason.country_code)} {reason.reason}
                                        </>
                                    ),
                                }
                            })}
                        onChangeValue={onPauseReasonIdsChange}
                        value={filter.pauseReasonIds || []}
                        placeholder="all reasons"
                    />
                </Col>
                <Col x>
                    <SelectCheckbox
                        large
                        options={Object.keys(PAUSE_TYPES).map((typeId) => ({
                            value: parseInt(typeId),
                            name: capitalize(PAUSE_TYPES[typeId]),
                        }))}
                        onChangeValue={onPauseTypesChange}
                        value={filter.pauseTypes || []}
                        placeholder="all types"
                    />
                </Col>
                <Col x>
                    <SelectCheckbox
                        large
                        options={Object.keys(DAY_SCHEMES).map((typeId) => ({
                            value: parseInt(typeId),
                            name: capitalize(DAY_SCHEMES[typeId]),
                        }))}
                        onChangeValue={onDaySchemesChange}
                        value={filter.daySchemes || []}
                        placeholder="all schemes"
                    />
                </Col>
                <Col x>
                    <InputField
                        icon="search"
                        placeholder="search"
                        value={query || filter.query}
                        onChange={onQueryChange}
                    />
                </Col>
                <Col x>
                    <button onClick={onInvalidateFilter}>
                        <Icon name="refresh" />
                    </button>
                </Col>
                <Col x style={{ marginLeft: 'auto' }}>
                    <Pagination
                        current={filter.page || 0}
                        pageSize={filter.pageSize}
                        count={count}
                        countOnPage={countOnPage}
                        onCountList={onCountList}
                        onPageChange={onPageChange}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ActivePausesFilter
