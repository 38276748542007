import { call } from '@advanza/api'
import plimit from 'p-limit'
import filesSchema from '../schemes/filesSchema'
import { changeEntity } from './entities'
import { _changeFilter, _fetchItems, selectItems } from './lists'

export const FILES_RECEIVE_LIST = 'FILES_RECEIVE_LIST'
export const FILES_CHANGE_FILTER = 'FILES_CHANGE_FILTER'
export const FILES_REQUEST = 'FILES_REQUEST'

export function fetchfiles(filterId) {
    return _fetchItems(filterId, {
        url: 'office/files/get-list',
        schema: filesSchema,
        requestActionName: FILES_REQUEST,
        receiveActionName: FILES_RECEIVE_LIST,
        reducerKey: 'files',
    })
}

export function changeFilesFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'files',
        fetchFunc: fetchfiles,
        actionType: FILES_CHANGE_FILTER,
    })
}

export function saveFile(fileId, options, doInvalidate = true, providerId = undefined) {
    return function (dispatch, getState) {
        const file = getState().files.entities.files[fileId]
        const isNew = typeof fileId === 'string'
        dispatch(
            changeEntity({ store: 'files', name: 'files', key: fileId, diff: { _saving: true } })
        )
        return call(`office/files/addFile/${isNew ? '' : fileId}`, {
            payload: { ...file, ...(providerId ? { providerId } : {}) },
        }).then((response) => {
            if (isNew && doInvalidate) {
                dispatch({ type: 'INVALIDATE_FILES' })
            }
            dispatch(
                changeEntity({
                    store: 'files',
                    name: 'files',
                    key: fileId,
                    diff: { _saving: false, _isTouched: false },
                })
            )
            return response
        })
    }
}

export function addFile(filterId, options, select = true) {
    return function (dispatch, getState) {
        const newFileId = Math.random().toString(36).substr(2, 5)
        const selection = getState().files.filters[filterId].selection || []
        const diff = {
            file_id: newFileId,
            ...options,
        }

        dispatch(
            changeEntity({
                diff,
                key: newFileId,
                filterId,
                name: 'files',
                store: 'files',
            })
        )

        if (select) {
            dispatch(selectItems('files', selection.concat(newFileId), filterId))
        }

        return diff
    }
}

export function deleteFile(fileId) {
    return function (dispatch) {
        const isNew = typeof fileId === 'string'
        const removeFromStore = () => {
            dispatch(changeEntity({ store: 'files', name: 'files', remove: true, key: fileId }))
        }
        if (isNew) {
            removeFromStore()
            return Promise.resolve()
        }
        return call(`office/files/delete/${fileId}`, { method: 'post' }).then(removeFromStore)
    }
}

export function deleteFileSelection(filterId) {
    return function (dispatch, getState) {
        const selection = getState().files.filters[filterId].selection

        const promises = selection.map((fileId) => dispatch(deleteFile(fileId)))
        return Promise.all(promises).then(() => {
            dispatch(changeFilesFilter(filterId, { selection: [] }))
        })
    }
}

export function saveSelection(filterId, saveOptions) {
    return function (dispatch, getState) {
        const selection = getState().files.filters[filterId].selection
        const limit = plimit(1)
        const promises = selection.map((fileId) =>
            limit(() => dispatch(saveFile(fileId, saveOptions, false)))
        )
        return Promise.all(promises).then(() => {
            dispatch({ type: 'INVALIDATE_FILES' })
        })
    }
}

export function uploadFromUrlAndSave(providerId, url, key, context = 'profile') {
    return function (dispatch) {
        return call(`office/files/add-from-scraped-images/${providerId}/${context}`, {
            json: { key },
        }).then((response) => {
            dispatch({
                type: 'AFTER_UPLOAD_FROM_SCRAPED_IMAGES',
                url,
                filename: response.filename,
            })
            dispatch({ type: 'INVALIDATE_FILES' })
        })
    }
}

export function getImageData(providerId, url, key) {
    return function (dispatch) {
        return call(`office/files/get-image-data-from-scraped-images`, { json: { key } }).then(
            (response) => {
                dispatch({
                    type: 'AFTER_UPLOAD_FROM_SCRAPED_IMAGES',
                    url,
                    filename: response.filename,
                })
                return Promise.resolve(response.data)
            }
        )
    }
}

export function getWebsiteImageUrls(providerId, url) {
    return function (dispatch, getState) {
        if (getState().files.websiteImages[url] === false || getState().files.websiteImages[url]) {
            return Promise.resolve()
        }

        dispatch({ type: 'REQUEST_SCRAPED_IMAGES_URLS', url })

        return call(`office/providers/get-website-images/${providerId}?url=${url}`).then(
            (response) => {
                dispatch({ type: 'RECEIVE_SCRAPED_IMAGES_URLS', ...response, url })
            }
        )
    }
}

/**
 * Sets important on description item if it contains certain words in response url or
 * is the homepage
 */
const _mapImportantPages = (item) => {
    const parts = item.response_url
        .replace('://', '')
        .split('/')
        .filter((str) => str.length > 0)
    const startWords = ['over', 'ons', 'wie']
    const anywhereWords = ['team']
    item.important = parts.length === 1

    if (parts.length === 2) {
        const containsStart = startWords.some((word) => parts[1].indexOf(word) === 0)
        const containsAnywhere = anywhereWords.some((word) => parts[1].indexOf(word) !== -1)
        item.important = containsStart || containsAnywhere
    }

    return item
}

export const getCrawledDescriptions = (url, countryCode) => (dispatch, getState) => {
    if (!url || !countryCode || getState().files.crawledDescriptions[url]) {
        return Promise.resolve()
    }

    dispatch({ type: 'REQUEST_CRAWLED_DESCRIPTIONS', url })

    return call(
        `office/providers/get-crawled-descriptions?url=${url}&country_code=${countryCode}`
    ).then(
        (response) => {
            const result = (response.result || [])
                .filter(
                    function (item) {
                        // Rejects additional snippets if page already has 5
                        const url = item.response_url.replace(/^.+:|\//gi, '')
                        this.maxCounts[url] = this.maxCounts[url] + 1 || 1
                        return this.maxCounts[url] < 6 ? true : false
                    },
                    { maxCounts: [] }
                )
                .map(_mapImportantPages)

            dispatch({ type: 'RECEIVE_CRAWLED_DESCRIPTIONS', url, response: { result } })
        },
        ({ error = 'something went wrong' }) =>
            dispatch({ type: 'RECEIVE_CRAWLED_DESCRIPTIONS', url, response: { error } })
    )
}

export const getAiDescriptions = (providerId) => (dispatch, getState) => {
    if (!providerId || getState().files.aiDescriptions[providerId]) {
        return Promise.resolve()
    }

    dispatch({ type: 'REQUEST_AI_DESCRIPTIONS', providerId })

    return call(`office/providers-builders/get-ai-descriptions/${providerId}`).then(
        (response) => dispatch({ type: 'RECEIVE_AI_DESCRIPTIONS', providerId, response }),
        ({ error = 'something went wrong' }) =>
            dispatch({ type: 'RECEIVE_AI_DESCRIPTIONS', providerId, response: { error } })
    )
}

// components/misc/Toaster.js for old components with no hooks
export const onFilesRejected = (files) => {
    return (dispatch) => {
        files.forEach(({ file, errors }) =>
            errors.forEach((e) =>
                dispatch({
                    type: 'ADD_TOAST',
                    toast: {
                        red: true,
                        timeout: 10000,
                        closeBtn: true,
                        msg: `${file.name} (${Math.round(file.size / 100000) / 10}MB) - ${e.code
                            .replace(/-/g, ' ')
                            .toUpperCase()} - ${e.message}`,
                        id: new Date().getTime() + '-' + Math.random().toString(36).substring(2, 7),
                    },
                })
            )
        )
    }
}
