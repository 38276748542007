import { schema } from 'normalizr'

const testimonial = new schema.Entity(
    'testimonials',
    {},
    {
        idAttribute: 'testimonial_id',
    }
)

export default [testimonial]
