import React from 'react'
import { connect } from 'react-redux'
import { addAdminUser } from '../actions/adminUsers'

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        addAdminUser: () => dispatch(addAdminUser()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
