import AnalyticsListContainer from 'containers/AnalyticsListContainer'
import PropTypes from 'prop-types'
import React from 'react'
import TableList from '../TableList'

class AnalyticsList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { rows } = this.props.entities
        return Object.keys(this.definitions).map((key) => {
            return rows[id][key]
        })
    }

    renderRow(id) {
        return {
            cols: this.renderColumns(id),
            id,
        }
    }

    definitions = {
        business_name: { name: 'name' },
        yearWeek: { name: 'yearWeek' },
        metric: { name: 'name' },
        count: { name: 'count' },
        value: { name: 'value' },
    }

    render() {
        const { selectable, ...rest } = this.props
        if (!rest.entities.rows) {
            return null
        }
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

AnalyticsList.propTypes = {
    filter: PropTypes.object,
    pages: PropTypes.any,
    isFetching: PropTypes.bool,
}

export default AnalyticsListContainer(AnalyticsList)
