import { Icon } from '@advanza/ui'
import Card from 'components/Card'
import LicencesUpdatersEntity from 'components/licencesUpdaters/LicencesUpdatersEntity'
import TableList from 'components/TableList'
import A from 'components/ui/A'
import { useList } from 'hooks/listHooks'
import { schema } from 'normalizr'
import React from 'react'
import { useSelector } from 'react-redux'

const useLicencesUpdatersList = (filterId, defaultOptions = {}) =>
    useList(
        filterId,
        {
            url: 'office/licences-updaters/get-list',
            schema: [
                new schema.Entity('licencesUpdaters', {}, { idAttribute: 'licences_updater_id' }),
            ],
            reducerKey: 'licencesUpdaters',
        },
        { ...defaultOptions, sorting: ['licences_updater_id', 'DESC'] }
    )

const LicencesUpdatersList = ({ filterId = 'all' }) => {
    const {
        entities: { licencesUpdaters = {} } = {},
        extra: { definitionsLicencesUpdaters = {} } = {},
        ...list
    } = useLicencesUpdatersList(filterId)

    const { licences = {} } = useSelector((state) => state.licences.entities)

    const renderRow = (licencesUpdaterId) => {
        const licencesUpdater = licencesUpdaters[licencesUpdaterId] || {}
        return {
            cols: Object.keys(definitionsLicencesUpdaters).map((key) => {
                if (key === 'licence_id') {
                    return (
                        '#' +
                        licencesUpdater.licence_id +
                        ' ' +
                        licences[licencesUpdater.licence_id]?.name
                    )
                }
                if (key === 'active') {
                    let active = licencesUpdater.active ? (
                        <span style={{ color: 'rgb(46, 206, 64)', fontWeight: 'bold' }}>Yes</span>
                    ) : (
                        <span>No</span>
                    )
                    let error = licencesUpdater.result?.error ? (
                        <span style={{ display: 'inline-block', color: 'red', fontWeight: 'bold' }}>
                            <Icon name="error" />
                            Error
                        </span>
                    ) : null
                    return (
                        <>
                            {active} {error}
                        </>
                    )
                }
                if (key === 'rules') {
                    return <pre style={{ whiteSpace: 'normal' }}>{licencesUpdater.rulesText}</pre>
                }
                if (key === 'extractor') {
                    return (
                        <A
                            style={{ textDecoration: 'underline' }}
                            target="_blank"
                            external
                            href={`https://app.import.io/dash/extractors/${licencesUpdater.extractor}`}>
                            <Icon name="link" /> {licencesUpdater.extractor}
                        </A>
                    )
                }
                return '' + licencesUpdater[key]
            }),
            expandable: (
                <Card expandableStyle>
                    <LicencesUpdatersEntity licencesUpdaterId={licencesUpdaterId} />
                </Card>
            ),
            expandOnClick: true,
        }
    }

    return (
        <TableList
            definitions={definitionsLicencesUpdaters}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default LicencesUpdatersList
