import { changeWebsiteClicksFilter } from 'actions/websiteClicks'
import React from 'react'
import { connect } from 'react-redux'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.websiteClicks
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]
    return {
        filter,
        count,
        isFetching,
    }
}

export default connect(mapStateToProps, { changeFilter: changeWebsiteClicksFilter })
