import { connect } from 'react-redux'
import { fetchLabels } from '../actions/labels'
import { selectItems } from '../actions/lists'

function mapStateToProps(state, props) {
    const { entities: labelEntities, result, isFetching, filters } = state.labels
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    const labels = labelEntities.labels || {}

    const labelCategories = Object.keys(labels).map((id) => labels[id].category)
    const uniqueCategories = Array.from(new Set(labelCategories))

    const labelSubcategories = Object.keys(labels).map((id) => labels[id].subcategory)
    const uniqueSubcategories = Array.from(new Set(labelSubcategories))

    const labelTypes = Object.keys(labels).map((id) => labels[id].type)
    const uniqueTypes = Array.from(new Set(labelTypes))

    return {
        labels,
        labelEntities,
        pages: result[searchKey] || {},
        filter,
        labelCategories: uniqueCategories,
        labelSubcategories: uniqueSubcategories,
        labelTypes: uniqueTypes,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchLabels: (filterId) => dispatch(fetchLabels(filterId)),
        select: (ids, filterId) => dispatch(selectItems('labels', ids, filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
