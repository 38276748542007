import { Button, Divider, Icon } from '@advanza/ui'
import { saveService } from 'actions/services'
import ExpandableEntity from 'components/FlexTable/ExpandableEntity'
import OrderButtonArrows from 'components/misc/OrderButtonArrows'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useChangeEntity } from 'hooks/entityHooks'
import { useListShared } from 'hooks/sharedHooks'
import React from 'react'
import CtaEntity from './CtaEntity'

export const SPEC = {
    store: 'services',
    name: 'ctas',
    nameRel: 'services',
}

export const DEFINITIONS = {
    cta_id: { name: '#' },
    order: { name: 'Order' },
    icon: {
        name: (
            <a
                style={{ color: '#0077cc', textTransform: 'none' }}
                target="_blank"
                rel="noreferrer"
                href="https://fonts.google.com/icons?icon.style=Outlined&icon.set=Material+Icons">
                Icon (or &apos;no&apos;)
            </a>
        ),
    },
    cta_listing_long: { name: 'CTA listing long' },
    cta_listing_short: { name: 'CTA listing short' },
    cta_profile: { name: 'CTA profile' },
    type: { name: 'Type' },
    request: { name: 'Request' },
    cta_listing_type: { name: 'Listing CTA' },
    cta_profile_type: { name: 'Profile CTA' },
    name_form_start_sentence: { name: 'Name form / Start sentence' },
    match2more: { name: 'Match2More' },
    contact_screen: { name: 'Contact screen' },
    cta_contact: { name: 'Contact CTA' },
}

export const NON_FORM_FIELDS = ['cta_id', 'order']

export const SELECTS = {
    type: {
        icon: 'insert_drive_file',
        options: [
            'call',
            'quote',
            'contact',
            'contact_toggle',
            'contact_options',
            'availability',
            'brochure',
            'website',
            'profile',
        ],
    },
    cta_listing_type: {
        icon: 'notes',
        options: ['no', 'button', 'inline'],
    },
    cta_profile_type: {
        icon: 'perm_contact_calendar',
        options: ['no', 'button', 'inline', 'list', 'inline & list'],
    },
}

const WIZARD_OPTIONS = ['name_form_start_sentence', 'match2more', 'contact_screen', 'cta_contact']

export const TYPE_EXCLUDES = {
    call: ['request', ...WIZARD_OPTIONS],
    contact: ['contact_screen'],
    contact_options: ['request', ...WIZARD_OPTIONS],
    brochure: ['name_form_start_sentence', 'match2more'],
    website: ['request', ...WIZARD_OPTIONS],
    profile: ['cta_profile', 'request', 'cta_profile_type', ...WIZARD_OPTIONS],
}

const renderRow = (cta, changeOrder) => {
    return {
        cols: Object.keys(DEFINITIONS).map((key) => {
            switch (key) {
                case 'cta_id':
                    return typeof cta.cta_id === 'string' ? (
                        <Icon name="fiber_new" />
                    ) : (
                        '#' + cta.cta_id
                    )
                case 'order':
                    return (
                        <OrderButtonArrows
                            clickUp={() => changeOrder(cta.cta_id, -1)}
                            clickDown={() => changeOrder(cta.cta_id, 1)}
                        />
                    )
                case 'icon':
                    return cta.icon && <Icon name={cta.icon} />
                default:
                    return !(TYPE_EXCLUDES[cta.type] || []).includes(key) ? cta[key] : 'N/A'
            }
        }),
        id: cta.cta_id,
        expandable: (
            <ExpandableEntity>
                <CtaEntity ctaId={cta.cta_id} />
            </ExpandableEntity>
        ),
        expanded: cta._open,
        expandOnClick: false,
        isLoading: cta._saving,
        backgroundColor: typeof cta.cta_id === 'string' ? 'lightgreen' : 'transparent',
    }
}

const FIELDS = {
    enable_cta_experiment: {
        type: 'toggle',
        textTrue: 'A/B test',
        textFalse: 'A/B test',
        _safeAfter: true,
    },
}

const CtasList = ({ serviceId }) => {
    const { isFetching, add, changeOrder, entityArr, keys } = useListShared(SPEC, serviceId)

    const rows = {}
    entityArr.forEach((cta) => (rows[cta.cta_id] = renderRow(cta, changeOrder)))

    const extraDiff = {}
    Object.keys(SELECTS).forEach((key) => (extraDiff[key] = SELECTS[key].options[0]))

    const { renderInput } = useChangeEntity(
        {
            store: 'services',
            name: 'services',
            entityId: serviceId,
            saveFunc: saveService,
        },
        FIELDS
    )

    return (
        <LoadBoxWrapper isLoading={isFetching}>
            {renderInput('enable_cta_experiment')}
            <TableList pages={{ 0: keys }} rows={rows} definitions={DEFINITIONS} />
            <Divider m />
            <Button name="text" onClick={() => add(extraDiff)}>
                <Icon name="add" style={{ marginTop: '-4px' }} /> Add custom CTA
            </Button>
        </LoadBoxWrapper>
    )
}

export default CtasList
