import React, {Component, Fragment} from "react"
import {FormattedMessage, injectIntl} from "react-intl"

class Msg extends Component {
    render() {
        if (this.props.asTitle) {
            return <Fragment>{this.props.intl.formatMessage({id: this.props.id}, this.props.values)}</Fragment>
        }
        return <FormattedMessage {...this.props} />

    }
}

export default injectIntl(Msg)
