import { Icon } from '@advanza/advanza_generic'
import { Row, Col } from '@advanza/grid'
import AggregatedReviewsFilterContainer from 'containers/AggregatedReviewsFilterContainer'
import PropTypes from 'prop-types'
import React from 'react'

class AggregatedReviewsFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.addEntity = this.addEntity.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }
    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    addEntity() {
        const { newEntityOptions, filterId, add } = this.props

        add({ ...newEntityOptions, filterId })
    }

    render() {
        const { filter, count, compact } = this.props
        if (!filter || compact) {
            return null
        }

        const pageNr = filter.page || 0
        return (
            <Row>
                <Col x>
                    <button className="plain" onClick={this.addEntity}>
                        <Icon>add</Icon> Add
                    </button>
                </Col>
            </Row>
        )
    }
}

AggregatedReviewsFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
}

export default AggregatedReviewsFilterContainer(AggregatedReviewsFilter)
