import React from 'react'
import { connect } from 'react-redux'
import { fetchLabels } from '../actions/labels'

function mapStateToProps(state) {
    const { entities } = state.labels
    const { labels } = entities
    const labelIds = labels && Object.keys(labels)
    return {
        labelIds,
        labels,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchLabels: (filterIdOrOptions) => dispatch(fetchLabels(filterIdOrOptions)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
