import { call } from '@advanza/api'
import { LoadingDots, PreIcon } from '@advanza/ui'
import queryString from 'query-string'
import React, { useState } from 'react'

const ProvidersExportButton = ({ filter = {} }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const onExport = () => {
        if (!isLoading) {
            setIsLoading(true)
            call(
                `office/providers/export/?
                ${queryString.stringify({ ...filter, page: 1 }, { arrayFormat: 'bracket' })}`
            )
                .then(setResponse, setResponse)
                .finally(() => setIsLoading(false))
        }
    }
    return (
        <button onClick={onExport}>
            <PreIcon
                name={
                    isLoading
                        ? 'hourglass_full'
                        : response && response.scheduled
                        ? 'sentiment_very_satisfied'
                        : 'file_download'
                }>
                {isLoading ? (
                    <LoadingDots />
                ) : !response ? (
                    'Export sheet'
                ) : response.scheduled ? (
                    'Scheduled export task'
                ) : (
                    'Error'
                )}
            </PreIcon>
        </button>
    )
}

export default ProvidersExportButton
