import React from 'react'
import style from './ProgressBar.module.css'

const ProgressBar = ({ percentage, newStyle = false }) => {
    const _style = {
        width: percentage + '%',
    }
    return (
        <div className={style.progress + (newStyle ? ' ' + style.newStyle : '')}>
            <div>
                <div style={_style} />
            </div>
        </div>
    )
}

export default ProgressBar
