import { schema } from 'normalizr'

export default [
    new schema.Entity(
        'faqTopics',
        {},
        {
            idAttribute: 'faq_topic_id',
        }
    ),
]
