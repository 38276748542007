import { S3_PUBLIC } from '@advanza/constants'
import React from 'react'
import style from './asyncImage.module.css'

const AsyncImage = ({ file, alt, layout = 'none', size = 'l', bgSize = 'contain' }) => {
    const getSource = () => {
        if (file.file_key) {
            const parts = file.file_key.split('/')
            const fileName = parts.pop()
            return S3_PUBLIC() + parts.join('/') + `/${size}_` + fileName
        } else {
            return file.previewUrl
        }
    }
    const image = <img src={getSource()} alt={alt || file.filename} decoding={'async'} />

    if (layout === 'fill') {
        // fill layout style like https://nextjs.org/docs/api-reference/next/image
        return (
            <div
                className={[
                    style.fill,
                    bgSize === 'contain' ? style.contain : '',
                    bgSize === 'cover' ? style.cover : '',
                ].join(' ')}>
                {image}
            </div>
        )
    }

    return image
}

export default AsyncImage
