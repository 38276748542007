import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import Page from 'components/ui/Page'
import React from 'react'
import ApisList from './ApisList'

const ApisPage = ({ filterId = 'all' }) => (
    <Page title="Apis">
        <Divider m />
        <Row middle="xs">
            <Col x>
                <h1>Apis</h1>
            </Col>
        </Row>
        <Divider m />
        <ApisList filterId={filterId} />
    </Page>
)

export default ApisPage
