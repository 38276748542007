import { Icon } from '@advanza/ui'
import SafeButton from 'components/ui/SafeButton'
import { useProvidersDate } from 'hooks/providersDatesHooks'

interface Props {
    providerDateId: number | string
    onSucces?: () => void
    store?: string
}

const SnoozeDateDelete = ({ providerDateId, onSucces, store }: Props) => {
    const { onDelete } = useProvidersDate(providerDateId, onSucces, store)

    return (
        <SafeButton
            action={() => onDelete()}
            cancelText={false}
            // @ts-ignore
            confirmMessage="Confirm delete?"
            buttonText={<Icon name="delete" />}
        />
    )
}

export default SnoozeDateDelete
