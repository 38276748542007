import { LoadingDots } from '@advanza/ui'
import { SQL_DATE_FORMAT_FNS, utcToLocal } from 'date'
import { addDays, subDays } from 'date-fns'
import React from 'react'
import Chart from 'react-google-charts'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'
import LinearLoader from '../LinearLoader'

class ServiceRequestStatsCalendarChart extends React.Component {
    render() {
        const { getStatsAndOptions } = this.props
        const { stats, options } = getStatsAndOptions('requestStats')

        if (!stats || !options) {
            return <LoadingDots />
        }

        const { days = 30, isFetching } = options
        const values = []
        let date = subDays(new Date(), days)

        for (let i = 0; i < days; i++) {
            date = addDays(date, 1)
            const key = utcToLocal(date, SQL_DATE_FORMAT_FNS)
            const nrRequests = parseInt(stats.dates[key] || 0, 10)

            if (nrRequests > 0) {
                values.push([new Date(key), nrRequests])
            }
        }

        return (
            <div>
                <Chart
                    height={320}
                    chartType="Calendar"
                    loader={<LinearLoader inside />}
                    data={[['Option', 'nrPros'], ...values]}
                    options={{
                        colorAxis: { colors: ['#fff', '#5eb933'] },
                        calendar: {
                            monthOutlineColor: {
                                stroke: '#5eb933',
                                strokeOpacity: 0.5,
                                strokeWidth: 1,
                            },
                            cellColor: {
                                stroke: '#fff', // Color the border of the squares.
                                strokeOpacity: 1, // Make the borders half transparent.
                                strokeWidth: 2,
                            },
                        },
                        noDataPattern: {
                            backgroundColor: 'rgba(243, 244, 246, 0.5)',
                            color: '#fff',
                        },
                    }}
                    mapsApiKey="AIzaSyAuOvu_ssjMyygZX7GFSOs7rXW7Obt_wqk"
                />
                {isFetching && <LinearLoader inside />}
            </div>
        )
    }
}

ServiceRequestStatsCalendarChart.propTypes = {}

export default ServiceStatsContainer(ServiceRequestStatsCalendarChart)
