import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import MunicipalitiesMap from 'components/serviceInfo/MunicipalityMap'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { useSelector } from 'react-redux'
import style from './regionalPricingModal.module.css'

const RegionalPricingModal = ({ service, isOpen = false, setIsOpen = (bool) => bool }) => {
    const country = useSelector((state) => state.countries.countries[0])

    if (!service || !service.highMunicipalityIds) {
        return null
    }

    const getHoverContent = ({ id, municipality }) =>
        ReactDOMServer.renderToStaticMarkup(
            <Row center="xs" middle="xs">
                <Col x>{municipality.name}</Col>
                <Col x>
                    <i className="material-symbols-outlined">people</i>{' '}
                    {(municipality.population / 1000).toFixed(2)}k
                </Col>
                <Col x>
                    <i className="material-symbols-outlined">sell</i>{' '}
                    {service.highMunicipalityIds.includes(id) ? 'High rate' : 'Low rate'}
                </Col>
            </Row>
        )

    const getHeatColorVars = ({ id }) => ({
        fillColor: service.highMunicipalityIds.includes(id) ? '#0000ff' : '#327bfe',
    })

    return (
        <Modal className={style.root} classObj={style} open={isOpen} close={() => setIsOpen(false)}>
            <MunicipalitiesMap
                countryCode={country}
                useHeatMap
                height="100%"
                getHoverContent={getHoverContent}
                getHeatColorVars={getHeatColorVars}
                showAudienceSize={false}
            />
            <Row className={style.legend} middle="xs">
                <Col x>
                    <b>Legend:</b>
                </Col>
                <Col className={style.noWrap} x>
                    <span className={style.lowRateColor}></span>
                    Low rate
                </Col>
                <Col className={style.noWrap} x>
                    <span className={style.highRateColor}></span>
                    High rate
                </Col>
            </Row>
        </Modal>
    )
}

export default RegionalPricingModal
