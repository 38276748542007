import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { Button, Divider, PreIcon } from '@advanza/ui'
import { FixedWarningPopup } from '@advanza/ui'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useListShared } from 'hooks/sharedHooks'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProvidersPlatformEntity, { getFields } from './ProvidersPlatformEntity'

export const SPEC = {
    store: 'providers',
    name: 'providersPlatforms',
    nameRel: 'providers',
}

export const cleanPlatformName = (name) => (name || '').toLowerCase().trim()

export const matchPlatformNames = (name1, name2) =>
    cleanPlatformName(name1) === cleanPlatformName(name2)

const ProvidersPlatformsList = ({ providerId }) => {
    const dispatch = useDispatch()
    const {
        isFetching,
        add,
        entityArr: providersPlatforms,
        save,
    } = useListShared(SPEC, providerId, getFields)
    const [toast, setToast] = useState({})
    const someTouched = providersPlatforms.some((providersPlatform) => providersPlatform._isTouched)
    const someSaving = providersPlatforms.some((providersPlatform) => providersPlatform._saving)
    const { isFetchingPlatforms, hasFetchedPlatforms, platforms } = useSelector(
        (state) => state.providers.platforms
    )
    const platformNames = Object.values(platforms).sort()

    useEffect(() => {
        if (isFetchingPlatforms || hasFetchedPlatforms) {
            return
        }

        dispatch({ type: 'PLATFORMS_REQUEST' })
        call('office/providers-platforms/get-platforms').then(
            ({ platforms }) =>
                dispatch({
                    type: 'PLATFORMS_RECEIVE',
                    platforms: Array.isArray(platforms) ? {} : platforms,
                }),
            () => dispatch({ type: 'PLATFORMS_RECEIVE' })
        )
    }, [dispatch, hasFetchedPlatforms, isFetchingPlatforms])

    const onSave = () =>
        save().then(
            () => {
                setToast({ id: Date.now(), message: 'Platforms saved' })

                if (
                    providersPlatforms.some(
                        (providersPlatform) =>
                            !platformNames.some((platformName) =>
                                matchPlatformNames(platformName, providersPlatform.name)
                            )
                    )
                ) {
                    dispatch({ type: 'PLATFORMS_TRIGGER_REQUEST' })
                }
            },
            (response) =>
                setToast({ isError: true, id: Date.now(), message: JSON.stringify(response) })
        )

    return (
        <LoadBoxWrapper isLoading={isFetching}>
            {providersPlatforms.map(({ service_providers_platform_id }) => (
                <Fragment key={service_providers_platform_id}>
                    <ProvidersPlatformEntity providersPlatformId={service_providers_platform_id} />
                    <Divider m />
                </Fragment>
            ))}
            <Button name="text" onClick={() => add({ name: platformNames[0] || null })}>
                <PreIcon fontSize={24} name="add" primColor>
                    Add platform
                </PreIcon>
            </Button>
            <Divider m />
            <Row end="xs">
                <Col x>
                    <Button onClick={onSave} disabled={!someTouched || someSaving}>
                        {someSaving ? '..saving' : 'Save'}
                    </Button>
                </Col>
            </Row>
            {toast.isError && <div style={{ color: 'red' }}>not saved</div>}
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </LoadBoxWrapper>
    )
}

export default ProvidersPlatformsList
