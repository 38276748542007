import { useScrollModal } from '@advanza/modal'
import { changeEntity } from '@advanza/redux_entity'
import { Button, Icon } from '@advanza/ui'
import ProfileProjectsEditModal from 'components/profileProjects/ProfileProjectsEditModal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

const ProfileProjectsAdd = ({ providerId }) => {
    const dispatch = useDispatch()
    const [projectId, setProjectId] = useState(null)
    const { openModal, closeModal, renderModal } = useScrollModal()

    const onOpenNew = () => {
        const newProjectId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'projects',
                key: newProjectId,
                diff: {
                    profile_project_id: newProjectId,
                    service_provider_id: providerId,
                    year: new Date().getFullYear(),
                    active: true,
                    price: null,
                    files: [],
                },
            })
        )
        setProjectId(newProjectId)
        openModal()
    }

    return (
        <>
            <Button name="text" disabled={projectId} onClick={onOpenNew}>
                <Icon primColor name="add" /> Add new project
            </Button>
            {projectId && (
                <ProfileProjectsEditModal
                    projectId={projectId}
                    closeProjectModal={closeModal}
                    renderProjectModal={renderModal}
                    overviewMode={false}
                    onClose={() => setProjectId(null)}
                />
            )}
        </>
    )
}

export default ProfileProjectsAdd
