import React from 'react'
import style from './RequestLogCandidateMatchItem.module.css'
import AutoRow from 'components/misc/AutoRow'
import { Link } from 'react-router-dom'
import { Ellipsis, Icon, PreIcon } from '@advanza/ui'
import Label from 'components/ui/Label'

const RequestLogCandidateMatchItem = ({ data = [] }) => {
    const headers = Object.keys(data[data.length - 1])
    return (
        <div className={style.root}>
            {data.map((item) => {
                return (
                    <Link to={`/service/providers/provider/${item.providerId}`}>
                        <AutoRow
                            key={item.providerId}
                            className={item.skipped ? style.skipped : ''}>
                            <small>#{item.providerId}</small>
                            {item.alreadyMatched && <Icon name="verified_user" fontSize={18} />}
                            <Ellipsis style={{ maxWidth: 150 }}>{item.businessName}</Ellipsis>
                            <Label text={item.scoreDescription} />
                            {item.claimed && <ClaimedIcon />}
                            <b title="trustoo score + quality score">
                                {item.combinedScore && item.combinedScore.toFixed(1)}
                            </b>
                            {item.responseRate != null ? (
                                <PreIcon title="response rate" fontSize={18} icon="reply">
                                    {item.responseRate}%
                                </PreIcon>
                            ) : null}
                            {item.responseTimeP80 != null ? (
                                <PreIcon title="reponse time p80" fontSize={18} icon="schedule">
                                    {item.responseTimeP80}h
                                </PreIcon>
                            ) : null}
                            {item.candidateLimitReached && (
                                <Icon
                                    name="report_problem"
                                    title="Candidate limit reached"
                                    fontSize={18}
                                />
                            )}
                            <b title={`Score, total: ${item.totalScore || item.score}`}>
                                +{item.score}
                            </b>
                        </AutoRow>
                    </Link>
                )
            })}
        </div>
    )
}

const ClaimedIcon = () => (
    <img
        alt="Claimed"
        style={{
            verticalAlign: 'middle',
            paddingLeft: 3,
        }}
        src="https://static.trustoo.nl/icon/claimed.svg"
        width="16"
    />
)
export default RequestLogCandidateMatchItem
