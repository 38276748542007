import { LinearLoader } from '@advanza/ui'
import React from 'react'
import style from './loadBoxWrapper.module.css'

const LoadBoxWrapper = ({ isLoading, children }) => {
    return (
        <div className={[style.root, isLoading ? style.isLoading : ''].join(' ')}>
            {isLoading && <LinearLoader fixed />}
            {children}
        </div>
    )
}

export default LoadBoxWrapper
