import { Icon, PreIcon } from '@advanza/ui'
import ServiceDatePickerModal from 'components/services/ServiceDatePickerModal'
import FormattedTime from 'components/ui/FormattedTime'
import { format } from 'date-fns'
import Service from 'interfaces/Services'

interface Props {
    entity: Service
}

const ReleaseDatePicker = ({ entity }: Props) => {
    const serviceId = entity.service_id
    return (
        <>
            {serviceId && (
                <ServiceDatePickerModal
                    serviceId={serviceId}
                    entityKey="release_date"
                    heading="Select visible date"
                    content={
                        !entity.subscribable && (
                            <div style={{ color: '#f42435', fontSize: '16px' }}>
                                <PreIcon icon="error" className={'red'}>
                                    Service is not subscribable!
                                </PreIcon>
                            </div>
                        )
                    }
                    trigger={
                        entity.release_date ? (
                            <div style={{ color: '#777' }}>
                                Becomes visible on:
                                <FormattedTime
                                    // @ts-ignore
                                    date={entity.release_date}
                                    format="dd-MM-yyyy HH:mm"
                                />{' '}
                                <Icon name="edit" fontSize={18} primColor />
                            </div>
                        ) : (
                            <span style={{ color: '#5788ff' }}>Plan visible date</span>
                        )
                    }
                    formatSelectedDay={(date: Date) => {
                        // release at 9AM NL
                        const nlSummerTime = date.getTimezoneOffset() === -120
                        const releaseTimeUTC = nlSummerTime ? 7 : 8
                        return `${format(date, `yyyy-MM-dd`)} 0${releaseTimeUTC}:00:00`
                    }}
                />
            )}
        </>
    )
}

export default ReleaseDatePicker
