import {
    generateValidateProfileLink,
    generateDashboardLink,
} from 'actions/providers'
import React from 'react'
import { connect } from 'react-redux'
import {
    addPaymentData,
    fetchProvider,
    saveLeadSetting,
    saveLeadSettings,
} from '../actions/providers'

function mapStateToProps(state, props) {
    const { isFetching } = state.providers
    const { providers, ...providerEntities } = state.providers.entities
    const { services } = state.services.entities
    const provider = providers && providers[props.providerId]
    return {
        provider,
        services,
        isFetching,
        providerEntities,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchProvider: (providerId, forceFetch) => dispatch(fetchProvider(providerId, forceFetch)),
        dispatch,
        saveLeadSetting: (settingId) => dispatch(saveLeadSetting(settingId)),
        addPaymentData: (providerId) => dispatch(addPaymentData(providerId)),
        generateValidateProfileLink: (providerId) =>
            dispatch(generateValidateProfileLink(providerId)),
        generateDashboardLink: (providerId) => dispatch(generateDashboardLink(providerId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
