import React from 'react'
import {
    Alert,
    FieldInput,
    Icon,
    Loading,
    Msg,
    Section,
    WithIcon,
    KeyPressHandler,
} from '@advanza/advanza_generic'
import { changePassword, validateToken } from '@advanza/advanza_generic/src/actions/auth'
import { Link } from 'react-router-dom'
import PasswordStrengthBar from './PasswordStrengthBar'
import ResetPassword from './ResetPassword'
import PropTypes from 'prop-types'

class ChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.renderInput = this.renderInput.bind(this)
        this.submit = this.submit.bind(this)
        this.afterTokenValidation = this.afterTokenValidation.bind(this)
        this.afterSuccess = this.afterSuccess.bind(this)
        this.changePasswordError = this.changePasswordError.bind(this)
        this.afterTokenValidationFailed = this.afterTokenValidationFailed.bind(this)
        this.validate = this.validate.bind(this)
        this.state = {
            current_password: {
                value: '',
                type: 'password',
                msgid: 'placeholder.current_password',
            },
            password: {
                value: '',
                type: 'password',
                msgid: 'placeholder.password',
            },
            password_confirm: {
                value: '',
                type: 'password',
                msgid: 'placeholder.password_confirm',
            },
            showCurrent: props.dontAskCurrentPassword ? false : !props.jwtToken,
        }
    }

    validations = [
        ['password', this.isPasswordValid, 'form.error.password'],
        [
            'password_confirm',
            (val, state) => val === state.password.value,
            'login.error.password_no_match',
        ],
    ]

    validationCurrentPassword = [['current_password', this.isPasswordValid, 'form.error.password']]

    componentDidMount() {
        const { token } = this.props

        // import('zxcvbn').then(module => window.zxcvbn = module)
        if (token) {
            this.setState({ loading: true })

            validateToken(token).then(this.afterTokenValidation, this.afterTokenValidationFailed)
        }
    }

    afterTokenValidation(response) {
        this.setState({ loading: false, showCurrent: false })
    }

    afterTokenValidationFailed(response) {
        this.setState({ loading: false })
        const { email } = this.props
        const message =
            response.error === 'token expired' ? (
                <Msg id="login.error.token_expired" />
            ) : (
                <Msg id="login.error.token.fallback" />
            )

        const closePopup = () => this.setState({ popup: false })
        this.setState({
            loading: false,
            popup: (
                <Alert close={closePopup}>
                    {message}
                    <Section n>
                        <ResetPassword email={email || ''} />
                    </Section>
                </Alert>
            ),
        })
    }

    isPasswordValid(password) {
        return password.length > 7
    }

    validate() {
        const { showCurrent } = this.state
        const validations = !showCurrent
            ? this.validations
            : this.validations.concat(this.validationCurrentPassword)

        let isValid = true
        validations.map(([name, validator, error]) => {
            const { value } = this.state[name]
            const valid = value && validator(value, this.state)
            if (!valid) {
                isValid = false
                this.setState({
                    [name]: {
                        ...this.state[name],
                        error: error ? <Msg id={error} /> : true,
                    },
                })
            }
        })
        return isValid
    }

    submit() {
        const { current_password, password, password_confirm } = this.state
        const { jwtToken } = this.props
        if (!this.validate()) {
            return
        }
        this.setState({ loading: true })
        changePassword({
            jwtToken,
            password: password.value,
            password_confirm: password_confirm.value,
            current_password: current_password.value,
        }).then(this.afterSuccess, this.changePasswordError)
    }

    afterSuccess(response) {
        const { afterSuccessAction } = this.props
        if (afterSuccessAction) {
            return afterSuccessAction(response)
        }
        this.setState({ passwordChanged: true })
    }

    changePasswordError({ error, password }) {
        let errorMsg = <Msg id="login.error.incorrect_password" />
        let stateKey = 'current_password'

        if (error === 'same password') {
            errorMsg = <Msg id="login.change_password.err.same" />
            stateKey = 'password'
        }
        if (password && password.valid && password.valid === 'not strong') {
            errorMsg = <Msg id="form.error.password" />
            stateKey = 'password'
        }
        this.setState({
            [stateKey]: {
                ...this.state.current_password,
                error: errorMsg,
            },
            loading: false,
            showCurrent: true,
        })
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: { ...this.state[name], value, error: null } })
    }

    renderInput(name) {
        const currentTypeIsPassword = this.state[name].type === 'password'
        const onIconClick = () =>
            this.setState({
                [name]: {
                    ...this.state[name],
                    type: currentTypeIsPassword ? 'text' : 'password',
                },
            })
        const icon = (
            <div onClick={onIconClick}>
                <Icon>{currentTypeIsPassword ? 'visibility' : 'visibility_off'}</Icon>
            </div>
        )

        return (
            <FieldInput
                {...this.state[name]}
                name={name}
                state={this.state[name]}
                icon={icon}
                newGStyle
                onChange={this.handleChange}
            />
        )
    }

    render() {
        const { loading, showCurrent, passwordChanged, popup, password } = this.state
        const { button, headerMsg } = this.props
        if (passwordChanged) {
            return (
                <div>
                    <Section s="plus">
                        <WithIcon icon="check">
                            <h1>
                                <Msg id="login.password_updated.header" />
                            </h1>
                        </WithIcon>
                    </Section>
                    <Section>
                        <Msg id="login.password_updated.sub" />
                    </Section>
                    <Section n>
                        <Link className="btn col-12" to="/login">
                            <Msg id="login.login_button" />
                        </Link>
                    </Section>
                </div>
            )
        }
        return (
            <div>
                {headerMsg || (
                    <Section s="plus">
                        <h1>
                            <Msg id={'login.change_password_title'} />
                        </h1>
                    </Section>
                )}
                <Section y>
                    {showCurrent && <Section>{this.renderInput('current_password')}</Section>}
                    <Section y="min">
                        {this.renderInput('password')}
                        <PasswordStrengthBar password={password.value} />
                    </Section>
                    <Section>{this.renderInput('password_confirm')}</Section>
                </Section>
                <Section y>
                    <div onClick={this.submit}>
                        {button || (
                            <button className="btn col-12">
                                <Msg id="login.change_password_button" />
                            </button>
                        )}
                    </div>
                </Section>
                {loading && <Loading />}
                {popup}

                <KeyPressHandler onEnter={this.submit} />
            </div>
        )
    }
}

ChangePassword.propTypes = {
    // with one of these tokens the user doesn't have to enter their current password
    jwtToken: PropTypes.string,
    token: PropTypes.string,
    // never ask for current password
    dontAskCurrentPassword: PropTypes.bool,
    // change the header.
    headerMsg: PropTypes.object,
    // override the success action
    afterSuccessAction: PropTypes.func,
}

export default ChangePassword
