import { changeEntity } from 'actions/entities'
import { _fetchItems } from 'actions/lists'
import { invalidateNotes } from 'actions/notes'
import licencesSchema from 'schemes/licencesSchema'
import { fetchLabels, LABELS_CHANGE_FILTER } from 'actions/labels'
import { _changeFilter } from 'actions/lists'
import { call } from '@advanza/api'

export const LICENCES_RECEIVE = 'LICENCES_RECEIVE'
export const LICENCES_REQUEST = 'LICENCES_REQUEST'
export const LICENCES_CHANGE_FILTER = 'LABELS_LICENCES_FILTER'

export function fetchLicences(filterId = 'all') {
    return _fetchItems(filterId, {
        url: 'office/licences/get-list',
        schema: licencesSchema,
        requestActionName: LICENCES_REQUEST,
        receiveActionName: LICENCES_RECEIVE,
        reducerKey: 'licences',
    })
}

export function changeLicencesFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'licences',
        fetchFunc: fetchLicences,
        actionType: LICENCES_CHANGE_FILTER,
    })
}

export function saveLicence(id) {
    return function (dispatch, getState) {
        const isNew = typeof id === 'string'
        const licence = getState().licences.entities.licences[id]

        return call('office/licences/save/' + (isNew ? '' : id), { json: licence }).then(
            (response) => {
                if (isNew) {
                    dispatch({ type: 'INVALIDATE_LICENCES' })
                }
            }
        )
    }
}

export function deleteLicence(id) {
    return function (dispatch) {
        const isNew = typeof id === 'string'

        if (isNew) {
            return dispatch({ type: 'INVALIDATE_LICENCES' })
        } else {
            return call('office/licences/delete/' + id, { method: 'post' }).then((response) => {
                dispatch({ type: 'INVALIDATE_LICENCES' })
            })
        }
    }
}

export function addLicence(filterId) {
    return function (dispatch) {
        dispatch(
            changeEntity({
                store: 'licences',
                name: 'licences',
                key: Math.random().toString(36).substr(2, 5),
                filterId,
                diff: { _open: true },
            })
        )
    }
}

export function toggleActiveLabel(licencesLabel) {
    const licenceId = licencesLabel.licence_id
    const licencesLabelId = licencesLabel.licences_label_id

    return function (dispatch) {
        return call(`office/licences-labels/toggle-active-label/${licencesLabelId}`, {
            method: 'post',
        }).then((response) => {
            dispatch(
                changeEntity({
                    diff: {
                        licences_label: response.label,
                    },
                    key: licenceId,
                    name: 'licences',
                    store: 'licences',
                })
            )
        })
    }
}
