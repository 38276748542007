import { Icon } from '@advanza/ui'
import {
    changeFilesFilter,
    getImageData,
    getWebsiteImageUrls,
    uploadFromUrlAndSave,
} from 'actions/files'
import Divider from 'components/Divider'
import LinearLoader from 'components/LinearLoader'
import Modal from 'components/ui/modal/Modal'
import { sortScrapedImages } from 'hooks/profileBuilderHooks'
import { useProvider } from 'hooks/providerHooks'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './websiteImages.module.css'

const WebsiteImages = ({ providerId, buttonText, onSelectImage, blockedMap, lang = 'EN' }) => {
    const dispatch = useDispatch()
    const { provider = {}, user = {} } = useProvider(providerId)
    const {
        websiteImages = {},
        entities: { files = {} } = {},
        result = {},
        filters = {},
    } = useSelector((state) => state.files)

    const url = provider.website
    const { images = [] } = websiteImages[url] || {}

    const filterId = `p_${providerId}`
    const filter = filters[filterId]
    const searchKey = filter && filter.searchKey
    const pages = result[searchKey] || {}
    const pageNr = (filter && filter.page) || 0
    const fileIds = pages[pageNr] || []
    const imageFiles = fileIds
        .map((fileId) => files[fileId])
        .filter((file) => file && file.type === 'image')

    const hasFetchedImages = websiteImages[provider.website] && result[searchKey]

    const [open, setOpen] = useState(false)
    const [imagesState, setImagesState] = useState({})

    const toggle = () => setOpen((open) => !open)
    const setImageState = (key, state) =>
        setImagesState((imagesState) => ({ ...imagesState, [key]: state }))

    useEffect(() => {
        dispatch(getWebsiteImageUrls(providerId, url))
    }, [dispatch, providerId, url])

    useEffect(() => {
        if (!filter) {
            dispatch(changeFilesFilter(filterId, { providerIds: [providerId], pageSize: 999 }))
        }
    }, [dispatch, filter, filterId, providerId])

    const onClickImage = (key, filename, size) => {
        setImageState(key, 'loading')

        if (onSelectImage) {
            dispatch(getImageData(providerId, url, key)).then((data) => {
                setImageState(key, false)
                onSelectImage(data, filename, size)
                toggle()
            })
        } else {
            dispatch(uploadFromUrlAndSave(providerId, url, key)).then(() =>
                setImageState(key, false)
            )
        }
    }

    return !hasFetchedImages ? null : (
        <div>
            {!images.length ? (
                <>
                    <Icon name="sentiment_dissatisfied" />{' '}
                    {lang === 'NL' ? '0 afbeeldingen gescraped' : '0 images scraped'}
                </>
            ) : (
                <button onClick={toggle} className="plain">
                    {buttonText || (
                        <span>
                            <Icon>photo_library</Icon> Scraped images ({images.length})
                        </span>
                    )}
                </button>
            )}
            <Modal closeOnOutsideClick close={toggle} open={open} maxWidth="90vw">
                <Divider l />
                <div className={style.parent}>
                    {sortScrapedImages(images).map((image) => {
                        if (imagesState[image.key] === 'disabled') {
                            return null
                        }

                        const isAdded = blockedMap
                            ? blockedMap[image.image_name + '-' + image.size]
                            : imageFiles.some(
                                  (file) =>
                                      image.key.includes(file.filename) && image.size === file.size
                              ) || image.image_name + '-' + image.size === user.avatarImageDash
                        const loading = imagesState[image.key] === 'loading'
                        const className = [
                            style.child,
                            isAdded ? style.added : '',
                            loading ? style.loading : '',
                        ].join(' ')

                        return (
                            <div
                                key={image.key}
                                className={style.container}
                                data-priority={image.priority}>
                                <div style={{ wordBreak: 'break-all' }}>
                                    {image.image_name && <div>{image.image_name}</div>}
                                    {/* {image.possible_logo && <div><b>LOGO?</b></div>} */}
                                    {image.duplicate && (
                                        <div>
                                            <b>DUPLICATE?</b>
                                        </div>
                                    )}
                                </div>

                                <div
                                    key={image.key}
                                    className={className}
                                    onClick={
                                        !isAdded && !loading
                                            ? () =>
                                                  onClickImage(
                                                      image.key,
                                                      image.image_name,
                                                      image.size
                                                  )
                                            : null
                                    }>
                                    <img
                                        width="100%"
                                        alt=" "
                                        onError={() => setImageState(image.key, 'disabled')}
                                        style={{ maxHeight: 500 }}
                                        src={image.url}
                                    />
                                    {loading && <LinearLoader />}
                                    {isAdded && (
                                        <div className={style.over}>
                                            <Icon>check</Icon> Toegevoegd
                                        </div>
                                    )}
                                </div>

                                {image.image_response_url && (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={image.image_response_url}>
                                        {/* Only display path after domain */}
                                        {image.image_response_url.replace(/^.+\/\/.+?\//, '') ||
                                            '/'}
                                    </a>
                                )}
                            </div>
                        )
                    })}
                </div>
            </Modal>
        </div>
    )
}

export default WebsiteImages
