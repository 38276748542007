import { Row, Col } from '@advanza/grid'
import React, { useEffect } from 'react'
import Card from 'components/Card'
import CategoryIcon from 'components/services/CategoryIcon'
import QuestionsList from 'components/services/QuestionsList'
import BackButton from 'components/ui/BackButton'
import Page from 'components/ui/Page'
import ServicesViewContainer from 'containers/ServicesViewContainer'
import style from './QuestionnairesPage.module.css'

const QuestionnairesPage = ({ fetchService, match }) => {
    const serviceId = match.params.id
    useEffect(() => {
        fetchService(serviceId)
    }, [])

    return (
        <Page title="Services Questionnaires">
            <Row middle="xs">
                <Col x>
                    <BackButton fallBack={'/settings/services/' + serviceId} />
                </Col>
            </Row>

            <QuestionsList serviceId={serviceId} />
        </Page>
    )
}

export default ServicesViewContainer(QuestionnairesPage)
