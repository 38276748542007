import React from 'react'
import { S3_PUBLIC } from '@advanza/constants'
import { Col, Row } from '@advanza/grid'
import { FileDropZone } from '@advanza/input'
import { Button, Divider, Icon } from '@advanza/ui'
import { deleteFranchisesLicence, saveFranchisesLicence } from 'actions/franchisesLicences'
import { useToast } from 'components/misc/Toaster'
import { useChangeEntity } from 'hooks/entityHooks'

export const getUrl = (franchisesLicence) => {
    if (franchisesLicence.file && franchisesLicence.file.preview) {
        return franchisesLicence.file.preview
    } else {
        return S3_PUBLIC() + `services/franchises_licences/${franchisesLicence.icon_filename}`
    }
}

const fields = {
    name: {
        type: 'text',
        placeholder: 'name',
    },
}

const FranchisesLicence = ({ franchisesLicenceId }) => {
    const {
        entity: franchisesLicence,
        onChangeEntity,
        renderInput,
        onSaveEntity,
        onDeleteEntity,
    } = useChangeEntity(
        {
            store: 'providers',
            name: 'franchisesLicences',
            entityId: franchisesLicenceId,
            saveFunc: saveFranchisesLicence,
            deleteFunc: deleteFranchisesLicence,
        },
        fields
    )
    const { onFilesRejected } = useToast()
    const onDropFiles = (files) => {
        const Reader = new FileReader()
        Reader.onloadend = (e) => {
            const { result } = e.target
            onChangeEntity({
                file: {
                    preview: result,
                    base64: result.substr(result.indexOf(',') + 1),
                    filename: files[0].name,
                },
            })
        }
        Reader.readAsDataURL(files[0])
    }

    return (
        <div>
            <Row middle="xs">
                <Col x>
                    <FileDropZone
                        style={{ width: 100 }}
                        allowedTypes={['image']}
                        multiple={false}
                        maxFiles={1}
                        onFilesAccepted={onDropFiles}
                        onFilesRejected={onFilesRejected}>
                        <div
                            style={{
                                background: `url(${getUrl(
                                    franchisesLicence
                                )}) no-repeat center center /cover`,
                                width: 'auto',
                                height: 50,
                            }}>
                            <Icon name="edit" />
                        </div>
                    </FileDropZone>
                </Col>
                <Col xs>{renderInput('name')}</Col>
            </Row>
            <Divider m />
            <Row between="xs">
                <Col x>
                    <Button
                        disabled={!franchisesLicence._isTouched || franchisesLicence._saving}
                        onClick={onSaveEntity}>
                        {franchisesLicence._saving ? '..busy' : 'Save'}
                    </Button>
                </Col>
                <Col x>
                    <Button name="delete" onClick={onDeleteEntity}>
                        {franchisesLicence._error || 'Delete'}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default FranchisesLicence
