import { call } from '@advanza/api'

export const undoReclaimRequest =
    (leadId, leadType = 'match') =>
    (dispatch) => {
        return call(`office/reclaims/undo-reclaim-request/${leadId}/${leadType}`).then(() => {
            dispatch({ type: 'INVALIDATE_RECLAIMS' })
            dispatch({
                type: leadType === 'match' ? 'INVALIDATE_MATCHES' : 'INVALIDATE_PHONECLICKS',
            })
        })
    }
