import { Col } from '@advanza/grid'
import ScoreBlock from 'components/serviceInfo/ScoreBlock'
import { useEmailStats } from 'hooks/closedLoopHooks'
import React from 'react'

const nameMap = (name) => {
    switch (name) {
        case 'conversionRate':
            return 'clicked / received'
        case 'clickRate':
            return 'clicked / opened'
        case 'openRate':
            return 'opened / received'
        case 'reviewRate':
            return 'reviewed / clicked'
        default:
            return name
    }
}

const EmailStatsBlock = ({ email, stats }) => {
    const { isFetching, emailStats, history } = useEmailStats()
    const isPercentage = stats.indexOf('Rate') >= 0
    const value = isPercentage ? 100 * emailStats[email][stats] : emailStats[email][stats]
    const prevValue = isPercentage ? 100 * history[email][stats] : history[email][stats]

    return (
        <Col xs style={{ maxWidth: '175px' }}>
            <ScoreBlock
                loading={isFetching}
                name={nameMap(stats)}
                prevValue={prevValue}
                value={value}
                altStyle={isPercentage}
                isPercentage={isPercentage}
            />
        </Col>
    )
}

export default EmailStatsBlock
