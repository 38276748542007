import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { finishRegistration } from '@advanza/advanza_generic/src/actions/auth'
import { FieldInput, Icon, Loading, Section, Msg, Alert } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'

class AfterEmailConfirmed extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.submit = this.submit.bind(this)
        this.validate = this.validate.bind(this)
        this.skipRegistration = this.skipRegistration.bind(this)
        this.afterSuccess = this.afterSuccess.bind(this)
        this.afterFailure = this.afterFailure.bind(this)

        this.token = props.token || props.match.params.token
        this.state = {
            loading: false,
            redirect: false,
            password: {
                value: '',
                type: 'password',
                msgid: 'placeholder.password',
            },
            password_confirm: {
                value: '',
                type: 'password',
                msgid: 'placeholder.password_confirm',
            },
        }
    }

    validations = [
        [
            ['password', this.isPasswordValid, 'form.error.password'],
            [
                'password_confirm',
                (val, state) => val === state.password.value,
                'login.error.password_no_match',
            ],
        ],
    ]

    componentWillMount() {
        this.submit()
    }

    renderInput(name) {
        const currentTypeIsPassword = this.state[name].type === 'password'
        const onIconClick = () =>
            this.setState({
                [name]: {
                    ...this.state[name],
                    type: currentTypeIsPassword ? 'text' : 'password',
                },
            })
        const icon = (
            <div onClick={onIconClick}>
                <Icon>{currentTypeIsPassword ? 'visibility' : 'visibility_off'}</Icon>
            </div>
        )

        return (
            <FieldInput
                {...this.state[name]}
                name={name}
                state={this.state[name]}
                icon={icon}
                newGStyle
                onChange={this.handleChange}
            />
        )
    }

    isPasswordValid(password) {
        return window.zxcvbn && window.zxcvbn(password).score >= 2
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: { ...this.state[name], value } })
    }

    submit() {
        if (!this.token || this.state.loading) {
            return
        }

        const { password } = this.state

        this.setState({ loading: true })
        finishRegistration(this.token, password.value).then(this.afterSuccess, this.afterFailure)
    }

    afterSuccess(json) {
        const { requestId } = json
        const { afterSuccessAction, afterSuccessRedirect } = this.props
        if (window._user) {
            window._user.email_confirmed = new Date()
        } else {
            window._user = json.user
        }

        if (afterSuccessAction) {
            return afterSuccessAction(json)
        }
        if (afterSuccessRedirect) {
            return this.setState({ redirect: afterSuccessRedirect({ ...json }) })
        }

        if (requestId) {
            return this.setState({
                redirect: `/complete-request/${requestId}`,
                loading: false,
            })
        }
    }

    afterFailure(json) {
        const dismissPopup = () => this.setState({ popup: false })
        this.setState({ loading: false })
        switch (json.error) {
            case 'expired':
                this.setState({
                    popup: (
                        <Alert
                            title={<Msg id="login.error.token_expired" />}
                            onYes={dismissPopup}
                        />
                    ),
                })
                break
            case 'already confirmed':
                this.setState({
                    popup: (
                        <Alert title={<Msg id="login.linkregistration.error.already" />}>
                            <Section n>
                                <button onClick={(e) => this.setState({ redirect: '/login' })}>
                                    <Msg id="login.login_button" />
                                </button>
                            </Section>
                        </Alert>
                    ),
                })
                break
            default:
                this.setState({
                    popup: <Alert title={<Msg id="error.fallback" />} onBack={dismissPopup} />,
                })
        }
    }

    validate() {
        let isValid = true
        this.validations.map(([name, validator, error]) => {
            const { value } = this.state[name]
            const valid = value && validator(value, this.state)
            if (!valid) {
                isValid = false
                this.setState({
                    [name]: {
                        ...this.state[name],
                        error: error ? <Msg id={error} /> : true,
                    },
                })
            }
        })
        return isValid
    }

    skipRegistration() {
        this.setState({ redirect: '/login' })
    }

    render() {
        const { redirect, loading, popup } = this.state
        if (redirect) {
            return <Redirect to={redirect} />
        }
        return (
            <div className="pos-rel">
                {popup}
                {loading && <Loading inside />}
            </div>
        )
    }
}

AfterEmailConfirmed.propTypes = {
    // token is required, it can be passed through match.params as well.
    token: PropTypes.string,

    // override the success action
    emailConfirmedSuccessAction: PropTypes.func, // Or:
    // a function that returns the redirect location.
    emailConfirmedSuccessRedirect: PropTypes.func, // afterSuccessRedirect({...response})
}

export default AfterEmailConfirmed
