import { schema } from 'normalizr'

const phoneClicksSchema = new schema.Entity(
    'phoneClicks',
    {},
    {
        idAttribute: 'phone_click_id',
    }
)

export default [phoneClicksSchema]
