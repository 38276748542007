import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { Button, Divider, MaxWidth } from '@advanza/ui'
import Card from 'components/Card'
import BannedRequestsList from 'components/country/BannedRequestsList'
import AutoRow from 'components/misc/AutoRow'
import Page from 'components/ui/Page'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const CountryPage = () => {
    const dispatch = useDispatch()
    const countryCode = useSelector((state) => state.countries.countries[0])
    const [newBannedWord, setNewBannedWord] = useState('')
    const [isSaving, setIsSaving] = useState(false)

    return (
        <Page title="Country">
            <MaxWidth>
                <Divider m />
                <AutoRow>
                    <h1>Country</h1>
                </AutoRow>
                <Divider m />
                <Card
                    header="Banned words"
                    icon="🤬"
                    iconStyle={{ overflow: 'visible', maxWidth: 'none', fontFamily: 'sans-serif' }}
                    useToggle>
                    <Divider l />
                    <Row middle="xs">
                        <Col x>
                            <InputField
                                placeholder="New banned word"
                                compact
                                value={newBannedWord}
                                onChange={(e) => setNewBannedWord(e.target.value)}
                            />
                        </Col>
                        <Col x>
                            <Button
                                disabled={!newBannedWord.trim() || isSaving}
                                onClick={() => {
                                    setIsSaving(true)
                                    call('office/banned-requests/save', {
                                        json: {
                                            newId: {
                                                banned_request_id: 'newId',
                                                word: newBannedWord,
                                                country_code: countryCode,
                                            },
                                        },
                                    }).finally(() => {
                                        setIsSaving(false)
                                        dispatch({ type: 'INVALIDATE_BANNEDREQUESTS' })
                                    })
                                }}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                    <Divider l />
                    <BannedRequestsList />
                </Card>
            </MaxWidth>
        </Page>
    )
}

export default CountryPage
