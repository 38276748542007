import { InputField } from '@advanza/input'
import { Button, Icon } from '@advanza/ui'
import { changeLicencesFilter, addLicence } from 'actions/licences'
import AutoRow from 'components/misc/AutoRow'
import ServicesSelect from 'components/services/ServicesSelect'
import { FilterContainer } from 'containers/GenericContainers'
import PropTypes from 'prop-types'
import React from 'react'
import Pagination from '../Pagination'

class LicencesFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.onChangeServices = this.onChangeServices.bind(this)
        this.state = {
            query: null,
        }
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    onChangeServices(services) {
        this._changeFilter({ serviceIds: services })
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    render() {
        const { filter, count, compact, addLicence, filterId } = this.props
        if (!filter || compact) {
            return null
        }

        const pageNr = filter.page || 0
        return (
            <AutoRow>
                <Pagination
                    current={pageNr}
                    pageSize={filter.pageSize}
                    count={count}
                    onPageChange={this.onPageChange}
                />
                <ServicesSelect onChangeValue={this.onChangeServices} value={filter.serviceIds} />
                <InputField
                    placeholder="search"
                    value={this.state.query || ''}
                    onChange={(e) => this.setState({ query: e.target.value })}
                    onKeyPress={(e) =>
                        e.key === 'Enter' &&
                        this._changeFilter({ query: this.state.query, page: 0 })
                    }
                />
                <Button name="text" onClick={() => addLicence(filterId)}>
                    <Icon name="add" />
                    add licence
                </Button>
            </AutoRow>
        )
    }
}

LicencesFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
}

export default FilterContainer('licences', {
    changeFilter: changeLicencesFilter,
    addLicence: addLicence,
})(LicencesFilter)
