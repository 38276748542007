import { connect } from 'react-redux'
import { fetchServices } from 'actions/services'

function mapStateToProps(state) {
    const { entities } = state.services
    const { services } = entities
    const serviceIds = services && Object.keys(services)
    return {
        serviceIds,
        services,
    }
}

export default connect(mapStateToProps, { fetchServices })
