import { LoadingDots } from '@advanza/ui'
import { addDays, endOfWeek, startOfWeek } from 'date-fns'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import style from './dateRangePicker.module.css'

const createStaticRanges = (defaultStaticRanges) =>
    defaultStaticRanges.map((staticRange) =>
        staticRange.label === 'This Week'
            ? createWeek(staticRange)
            : staticRange.label === 'Last Week'
            ? createWeek(staticRange, -7)
            : staticRange
    )

const createWeek = (staticRange, daysToAdd = 0) => ({
    ...staticRange,
    range: () => ({
        startDate: startOfWeek(addDays(new Date(), daysToAdd), { weekStartsOn: 1 }),
        endDate: endOfWeek(addDays(new Date(), daysToAdd), { weekStartsOn: 1 }),
    }),
})

const DateRangePickerInternal = lazy(() =>
    import('react-date-range').then(({ DateRangePicker }) => ({ default: DateRangePicker }))
)

const DateRangePicker = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    compactWithModal = true,
}) => {
    const [staticRanges, setStaticRanges] = useState([])

    useEffect(() => {
        import('react-date-range/dist/styles.css')
        import('react-date-range/dist/theme/default.css')
        import('react-date-range').then(({ defaultStaticRanges }) =>
            setStaticRanges(createStaticRanges(defaultStaticRanges))
        )
    }, [])

    const internal = (
        <Suspense fallback={<LoadingDots />}>
            <DateRangePickerInternal
                onChange={({ selection }) => {
                    setStartDate(selection.startDate)
                    setEndDate(selection.endDate)
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[
                    {
                        startDate,
                        endDate,
                        key: 'selection',
                    },
                ]}
                direction="horizontal"
                className={style.root}
                weekStartsOn={1}
                staticRanges={staticRanges}
            />
        </Suspense>
    )

    return compactWithModal ? <></> : internal
}

export default DateRangePicker
