import { schema } from 'normalizr'

export default [
    new schema.Entity(
        'rows',
        {},
        {
            idAttribute: 'profile_performance_metric_id',
        }
    ),
]
