import { Icon } from '@advanza/advanza_generic'
import { S3_PUBLIC } from '@advanza/constants'
import Card from 'components/Card'
import LicencesEntity from 'components/licences/LicencesEntity'
import LicenceLabelEdit from 'components/licences/LicencesLabelEdit'
import LicencesListContainer from 'containers/LicencesListContainer'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { FormattedRelative } from 'react-intl'
import CheckButton from '../CheckButton'
import TableList from '../TableList'

class LicencesList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { toggleActiveLabel, entities } = this.props
        const { licences } = entities
        const entity = licences[id]
        const url = S3_PUBLIC() + `services/licences/` + entity.icon_filename
        return [
            <small className="c-grey">#{id}</small>,
            entity.name,
            <div
                style={{
                    maxWidth: 50,
                    maxHeight: 500,
                    width: '100%',
                    height: 20,
                    background: `url(${url}) no-repeat center center /cover`,
                }}
            />,
            <small className="c-grey">
                <FormattedRelative value={entity.created || new Date()} />
            </small>,
            <Fragment>
                {entity.licences_label && (
                    <CheckButton
                        checked={entity.licences_label.active}
                        msg="active"
                        inlineBlock
                        onChange={() => toggleActiveLabel(entity.licences_label)}
                    />
                )}{' '}
                <LicenceLabelEdit
                    licenceId={id}
                    officeLabelId={entity.licences_label && entity.licences_label.office_label_id}
                />
            </Fragment>,
        ]
    }

    renderRow(id) {
        const { licences } = this.props.entities
        return {
            id,
            cols: this.renderColumns(id),
            expandable: (
                <Card expandableStyle>
                    <LicencesEntity entityId={id} />
                </Card>
            ),
            expandOnClick: true,
            dontCloseOnClickOutside: true,
            expanded: licences[id]._open,
        }
    }

    definitions = {
        id: { name: '#' },
        name: { name: 'name' },
        thumbnail: { name: <Icon small>image</Icon> },
        created: { name: 'time' },
        label: { name: 'label', preventRedirect: true },
        edit: { name: 'edit' },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

LicencesList.propTypes = {
    filter: PropTypes.object,
    pages: PropTypes.any,
    fileEntities: PropTypes.object,
    isFetching: PropTypes.bool,
}

export default LicencesListContainer(LicencesList)
