import React from 'react'
import { connect } from 'react-redux'
import {
    addFile,
    changeFilesFilter,
    deleteFileSelection,
    saveSelection,
    onFilesRejected,
} from '../actions/files'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.files
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]
    return {
        filter,
        count,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeFilesFilter(id, options)),
        addFile: (filterId, options) => dispatch(addFile(filterId, options)),
        deleteSelection: (filterId) => dispatch(deleteFileSelection(filterId)),
        saveSelection: (filterId, addImageOptions) =>
            dispatch(saveSelection(filterId, addImageOptions)),
        onFilesRejected: (files) => dispatch(onFilesRejected(files)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
