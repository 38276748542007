import { call } from '@advanza/api'
import { normalize } from 'normalizr'
import pauseReasonsSchema from 'schemes/pauseReasonsSchema'
import { _changeFilter, _fetchItems } from './lists'

export const REQUEST_PAUSE_REASONS = 'REQUEST_PAUSE_REASONS'
export const RECEIVE_PAUSE_REASONS = 'RECEIVE_PAUSE_REASONS'

export function fetchPauseReasons() {
    return function (dispatch, getState) {
        const { isFetching, hasFetched } = getState().pauseReasons
        if (isFetching || hasFetched) {
            return Promise.resolve()
        }

        dispatch({ type: REQUEST_PAUSE_REASONS })
        return call('office/providers/get-list-pause-reasons').then((response) => {
            const { entities } = normalize(response.pauseReasons, pauseReasonsSchema)
            dispatch({ type: RECEIVE_PAUSE_REASONS, entities })
        })
    }
}
