import { APP } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import { Button, Divider, Icon } from '@advanza/ui'
import BarImage from 'components/profileBuilderWizard/BarImage'
import { useChangeEntity } from 'hooks/entityHooks'
import { getImageDash, getWeightStart, useProfileBuilderWizard } from 'hooks/profileBuilderHooks'
import React, { useEffect, useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import style from './barAvatar.module.css'

const BarAvatar = ({ providerId, disabled }) => {
    const [cropMode, setCropMode] = useState(false)
    const [pixelCrop, setPixelCrop] = useState(null)
    const imageRef = useRef(null)

    const { user = {}, selectImage } = useProfileBuilderWizard(providerId)
    const { onChangeEntity } = useChangeEntity(
        {
            store: 'providers',
            name: 'users',
            entityId: user.id,
        },
        {}
    )

    useEffect(() => {
        import('react-image-crop/dist/ReactCrop.css')
    }, [])

    useEffect(() => {
        if (user._saving || !user.avatarFile) {
            setCropMode(false)
        }
    }, [user._saving, user.avatarFile])

    const onCrop = (crop, crop2) =>
        onChangeEntity({
            avatarFile: { ...(user.avatarFile || {}), crop: { ...crop, aspect: 1 }, crop2 },
        })

    const onChangeImage = (file) =>
        onChangeEntity({
            avatarFile: { ...(user.avatarFile || {}), ...file },
            ...(file.filename && file.size ? { avatarImageDash: getImageDash(file) } : {}),
        })

    const getCroppedImg = () => {
        const crop = pixelCrop
        const canvas = document.createElement('canvas')
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        if (crop.width < 1 || crop.height < 1) {
            return Promise.reject()
        }

        ctx.drawImage(
            imageRef.current,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), 'image/jpeg'))
    }

    const getUrl = () => {
        if (!cropMode && user.avatarFile && user.avatarFile.croppedPreview) {
            return user.avatarFile.croppedPreview
        } else if (user.avatarFile && user.avatarFile.preview) {
            return user.avatarFile.preview
        } else if (user.avatar_filename) {
            return APP.S3_PUBLIC() + `users/${user.id}/${user.avatar_filename}`
        } else {
            return undefined
        }
    }

    const onDone = () => {
        if (pixelCrop) {
            getCroppedImg().then((blob) => {
                onChangeImage({ croppedPreview: URL.createObjectURL(blob) })
                setCropMode(false)
            })
        } else {
            setCropMode(false)
        }
    }

    const onChange = (crop, pixelCrop) => {
        onCrop(crop)
        setPixelCrop(pixelCrop)
    }

    const onImageLoaded = (image) => {
        imageRef.current = image
    }

    const url = getUrl()

    return (
        <Row className={style.rootRow}>
            <Col className={style.cropButtonCol} x>
                <Modal classObj={style} open={cropMode} close={() => setCropMode(false)}>
                    {user.avatarFile && (
                        <ReactCrop
                            style={{ backgroundColor: '#fff' }}
                            crop={user.avatarFile.crop}
                            onImageLoaded={onImageLoaded}
                            onChange={onChange}
                            src={url}
                        />
                    )}
                    <Divider m />
                    <Row center="xs">
                        <Col x>
                            <Button name="text" onClick={onDone}>
                                <Icon name="check" />
                                Klaar
                            </Button>
                        </Col>
                        <Col x>
                            <Button name="text" onClick={() => onCrop(null)}>
                                <Icon name="crop_free" />
                            </Button>
                        </Col>
                    </Row>
                </Modal>
                {user.avatarFile && (
                    <Button
                        className={style.cropButton}
                        name="text"
                        onClick={() => setCropMode(true)}>
                        <Icon name="crop_free" />
                    </Button>
                )}
            </Col>
            <Col x>
                <BarImage
                    providerId={providerId}
                    disabled={disabled}
                    url={url}
                    context="avatar"
                    weight={getWeightStart('avatar')}
                    onAddFile={(url, file) => {
                        selectImage(
                            {
                                filename: file.name,
                                size: file.size,
                                preview: url,
                                base64: url.substr(url.indexOf(',') + 1),
                                crop: {},
                            },
                            'avatar'
                        )
                        setCropMode(true)
                    }}
                    width={75}
                />
            </Col>
        </Row>
    )
}

export default BarAvatar
