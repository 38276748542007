import { call } from '@advanza/api'
import { Col } from '@advanza/grid'
import { Button, Divider, HoverText, Icon } from '@advanza/ui'
import React, { Fragment, useState } from 'react'
import { InputField } from '@advanza/input'
import { fetchProvider } from 'actions/providers'
import { useDispatch } from 'react-redux'
import AutoRow from 'components/misc/AutoRow'
import { useToast } from 'components/misc/Toaster'
import Label from 'components/ui/Label'

const REQUEST_TYPE_POST = 'POST'
const REQUEST_TYPE_DEL = 'DELETE'
const REQUEST_TYPE_FORCE_NEW = 'FORCE_NEW'

const usePhoneRouting = (entity, change) => {
    const [isFetching, setIsFetching] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isWorking, setisWorking] = useState(false)
    const [areacode, setAreacode] = useState('')
    const dispatch = useDispatch()
    const { addToast } = useToast()

    const makeRouteNr = () => {
        requestPhoneRouteNr(REQUEST_TYPE_POST)
    }

    const deleteRouteNr = () => {
        requestPhoneRouteNr(REQUEST_TYPE_DEL)
    }

    const forceNewRouteNr = () => {
        requestPhoneRouteNr(REQUEST_TYPE_FORCE_NEW)
    }

    const requestPhoneRouteNr = (requestType) => {
        const providerId = entity.service_provider_id
        const endPoints = {
            [REQUEST_TYPE_POST]: 'get-routing-nr',
            [REQUEST_TYPE_FORCE_NEW]: 'force-new-routing-nr',
            [REQUEST_TYPE_DEL]: 'del-routing-nr',
        }
        const isCreateNr = REQUEST_TYPE_POST || REQUEST_TYPE_FORCE_NEW
        const endPoint = endPoints[requestType]

        let url = 'office/phone-routing/' + endPoint + '/' + providerId
        if (isCreateNr && areacode) {
            url += '/' + areacode
        }

        setIsFetching(true)
        call(url,{payload: {useMatelso:entity._useMatelso}}).then(
            (response) => {
                setIsFetching(false)
                setisWorking({
                    msg: 'Qooqie API received call. It may take a few minutes to process. Refresh this page. It may take up to a day for BE.',
                })
                dispatch(fetchProvider(providerId, true))
            },
            (reason) => {
                setIsFetching(false)
                setIsError(reason)
                addToast({
                    msg: JSON.stringify(reason?.error, null, 2),
                    fromTop: true,
                })
            }
        )
    }

    const onAreacodeChange = (e) => {
        setAreacode(e.target.value)
    }

    return {
        isFetching,
        isError,
        isWorking,
        forceNewRouteNr,
        makeRouteNr,
        deleteRouteNr,
        areacode,
        onAreacodeChange,
    }
}

const PhoneRouting = ({ entity, change }) => {
    const {
        isFetching,
        isError,
        forceNewRouteNr,
        makeRouteNr,
        deleteRouteNr,
        isWorking,
        areacode,
        onAreacodeChange,
    } = usePhoneRouting(entity, change)
    const isPending = ['PENDING'].includes(entity.phone_routing_nr)
    const isDeleted = ['DELETED'].includes(entity.phone_routing_nr)
    return (
        <AutoRow>
            {!entity.phone_routing_nr || isPending || isDeleted ? (
                <>
                    <HoverText
                        trigger={
                            <InputField
                                value={areacode}
                                inputPlaceholder="areacode"
                                onChange={onAreacodeChange}
                            />
                        }>
                        <span>
                            Je kan hier een apart areacode gebruiken voor bij het Qooqie nummer
                            aanvragen
                        </span>
                    </HoverText>
                    <button title="Use matelso instead of qooqie" onClick={() => change({ _useMatelso: entity._useMatelso !== true })}>
                        <AutoRow>
                            <small>Use matelso</small>
                        <Icon name={entity._useMatelso ? 'toggle_on' : 'toggle_off'} />
                        </AutoRow>
                    </button>
                    <Button disabled={isFetching || isError || isWorking} onClick={makeRouteNr}>
                        {isFetching ? 'busy...' : isDeleted ? 'restore nr' : 'get nr'}
                    </Button>
                    <Divider />
                    {isDeleted && (
                        <Fragment>
                            <Button
                                disabled={isFetching || isError || isWorking}
                                onClick={forceNewRouteNr}>
                                {isFetching ? 'busy...' : 'new nr'}
                            </Button>
                            <Divider />
                        </Fragment>
                    )}
                </>
            ) : (
                <Button disabled={isFetching || isError || isWorking} onClick={deleteRouteNr}>
                    {isFetching ? 'busy...' : 'del nr'}
                </Button>
            )}
            {isWorking && <Col x>{<p className="">{isWorking.msg}</p>}</Col>}
            {entity.matelso_subscriber && <AutoRow>
                <small>Matelso status:</small>
                <Label text={entity.matelso_subscriber.phone_routing_status}/>
            </AutoRow>}
            {isError && (
                <div>
                    {isError.result && isError.result.length ? (
                        isError.result.errors?.map((errorItem, i) => {
                            return (
                                <p key={i} className="error">
                                    {errorItem['status']}: {errorItem['title']} -{' '}
                                    {errorItem['detail']}
                                </p>
                            )
                        })
                    ) : (
                        <p className="error">{isError.error}</p>
                    )}
                </div>
            )}
        </AutoRow>
    )
}

export default PhoneRouting
