import PercentageCircle from 'components/leadEstimator/PercentageCircle'
import React from 'react'
import style from './StatListCircles.module.css'

const StatListCircles = ({ stats = [], className = '' }) => {
    return (
        <div className={[style.root, className].join(' ')}>
            {stats.map((props, i) => (
                <div key={i}>
                    <PercentageCircle {...props} />
                    {props.name}
                </div>
            ))}
        </div>
    )
}

export default StatListCircles
