import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    EVENTS_CHANGE_FILTER,
    EVENTS_RECEIVE_LIST,
    EVENTS_RECEIVE_SINGLE,
    EVENTS_REQUEST,
} from '../actions/events'
import { changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [EVENTS_RECEIVE_LIST]: receiveList,
    [EVENTS_CHANGE_FILTER]: changeFilter,
    INVALIDATE_EVENTS: invalidateFilters,
    [EVENTS_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [EVENTS_RECEIVE_SINGLE]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            entities: { $deepMerge: action.entities },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
