import { schema } from 'normalizr'

const aggregatedReviewsSchema = new schema.Entity(
    'aggregatedReviews',
    {
        aggregated_reviews_snippets: [
            new schema.Entity(
                'aggregatedReviewsSnippets',
                {},
                { idAttribute: 'aggregated_reviews_snippet_id' }
            ),
        ],
    },
    {
        idAttribute: 'aggregated_review_id',
    }
)

export default [aggregatedReviewsSchema]
