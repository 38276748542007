import React from 'react'
import { connect } from 'react-redux'
import { deleteSubscription, toggleActiveSubscription } from '../actions/subscriptions'

function mapStateToProps(state, props) {
    const { services } = state.services.entities
    const { entities, result, isFetching, filters } = state.subscriptions
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey
    return {
        entities,
        services,
        pages: result[searchKey] || {},
        filter,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleActiveSubscription: (subId) => dispatch(toggleActiveSubscription(subId)),
        deleteSubscription: (subId) => dispatch(deleteSubscription(subId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
