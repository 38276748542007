import Card from 'components/Card'
import TableList from 'components/TableList'
import { useList } from 'hooks/listHooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import providersBansSchema from 'schemes/providersBansSchema'
import ProvidersBanEntity from './ProvidersBanEntity'

export function useProvidersBansList(filterId) {
    const { countries } = useSelector((state) => state.countries)
    return useList(
        filterId,
        {
            url: 'office/providers-bans/get-list',
            schema: providersBansSchema,
            reducerKey: 'providersBans',
        },
        { countries, sorting: ['quarantinedAt', 'DESC'] }
    )
}

const ProvidersBansList = ({ filterId = 'all' }) => {
    const {
        entities: { providersBans = {} } = {},
        extra: { definitionsProvidersBans = {} } = {},
        ...list
    } = useProvidersBansList(filterId)

    const renderRow = (providerId) => {
        const providersBan = providersBans[providerId] || {}
        return {
            cols: Object.keys(definitionsProvidersBans).map((key) => {
                if (key === 'businessName') {
                    return (
                        <Link
                            title="To pro"
                            className="plain"
                            to={`/service/providers/provider/${providersBan.service_provider_id}`}>
                            {providersBan.businessName}
                        </Link>
                    )
                }

                return providersBan[key]
            }),
            expandable: (
                <Card expandableStyle>
                    <ProvidersBanEntity providerId={providerId} />
                </Card>
            ),
            expandOnClick: true,
        }
    }

    return (
        <TableList
            definitions={definitionsProvidersBans}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default ProvidersBansList
