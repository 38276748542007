import React from 'react'
import A from 'components/ui/A'
import { Icon } from '@advanza/ui'


const MolliePaymentLink = ({invoice}) => {
    if (!invoice.transaction_id) {
        return null
    }
    
    const org = {NL:'org_8695931',BE:'org_10337757',DE:'org_13504297'}[invoice.country_code.toUpperCase()] || 'org_8695931'
    return     <A
        title="Mollie link"
        target="_blank"
        external
        href={`https://my.mollie.com/dashboard/${org}/payments/${invoice.transaction_id}`}>
        <Icon name="link" />
    </A>
}

export default MolliePaymentLink