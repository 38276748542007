import React from 'react'
import { FormattedRelative } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Icon } from '@advanza/ui'
import { addFranchisesLicence } from 'actions/franchisesLicences'
import Card from 'components/Card'
import FlexTable from 'components/FlexTable/FlexTable'
import FranchisesLicence, { getUrl } from './FranchisesLicence'

const FranchisesLicencesList = ({ franchiseId }) => {
    const dispatch = useDispatch()
    const { entities: { providers = {}, franchisesLicences = {} } = {} } = useSelector(
        (state) => state.providers
    )
    const franchise = providers[franchiseId] || {}
    const franchisesLicencesIds = franchise.franchises_licences || []

    const renderRow = (franchisesLicenceId) => ({
        id: franchisesLicenceId,
        cols: renderColumns(franchisesLicenceId),
        expandable: (
            <Card>
                <FranchisesLicence franchisesLicenceId={franchisesLicenceId} />
            </Card>
        ),
        expandOnClick: true,
        expanded: franchisesLicences[franchisesLicenceId]._open,
    })

    const renderColumns = (franchisesLicenceId) => {
        const franchisesLicence = franchisesLicences[franchisesLicenceId]
        return [
            <small className="c-grey">#{franchisesLicenceId}</small>,
            franchisesLicence.name,
            <div
                style={{
                    maxWidth: 50,
                    maxHeight: 500,
                    width: '100%',
                    height: 20,
                    background: `url(${getUrl(franchisesLicence)}) no-repeat center center /cover`,
                }}
            />,
            <small className="c-grey">
                <FormattedRelative value={franchisesLicence.created || new Date()} />
            </small>,
        ]
    }

    const definitions = {
        id: { name: '#' },
        name: { name: 'Name' },
        thumbnail: { name: <Icon>image</Icon> },
        created: { name: 'Time' },
        edit: { name: 'Edit' },
    }

    return (
        <>
            {franchisesLicencesIds.length > 0 && (
                <FlexTable definitions={definitions} rows={franchisesLicencesIds.map(renderRow)} />
            )}
            <Divider m />
            <Button name="text" onClick={() => dispatch(addFranchisesLicence(franchiseId))}>
                <Icon name="add" />
                add licence
            </Button>
        </>
    )
}

export default FranchisesLicencesList
