import { SelectSimple } from '@advanza/input'
import { Icon } from '@advanza/ui'
import { useProvidersLabels, useProfileLabelsPicker } from 'hooks/providersLabelsHooks'
import React, { Fragment } from 'react'

const ProfileLabelsPicker = ({ filterId, providerId }) => {
    const { addLabel } = useProvidersLabels()
    const { options } = useProfileLabelsPicker(filterId, providerId)

    return (
        <Fragment>
            {options && options.length > 0 && (
                <SelectSimple
                    placeholder={
                        <small className="c-grey">
                            <Icon name="add" fontSize={12} /> add label
                        </small>
                    }
                    emptyOption="Choose a label to add..."
                    onChange={(e) => addLabel(providerId, e.target.value)}
                    options={options}
                />
            )}
        </Fragment>
    )
}

export default ProfileLabelsPicker
