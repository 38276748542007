import React from 'react'
import style from './legends.module.css'

const Legend = ({ tagName = 'b', children, useMargin, ...rest }) => {
    return React.createElement(
        tagName,
        { className: [style.root, useMargin ? style.margin : ''].join(' '), ...rest },
        children
    )
}

export default Legend
