import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    SYSTEM_LOGS_RECEIVE_LIST: receiveList,
    SYSTEM_LOGS_CHANGE_FILTER: changeFilter,
    INVALIDATE_SYSTEM_LOGS: invalidateFilters,
    SYSTEM_LOGS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    RECEIVE_SYSTEM_LOGS_TYPES: (state, action) =>
        update(state, {
            log_types: {
                [action.relation]: {
                    $set: action.types,
                },
            },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    log_types: {},
    filters: {},
}

export default createReducer(initialState, handlers)
