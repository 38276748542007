import FaqsList from 'components/faqs/FaqsList'
import React from 'react'
import { useSelector } from 'react-redux'
import Card from '../Card'

const ProDashboardView = () => {
    const country = useSelector((state) => state.countries.countries[0])
    return (
        <Card header={'FAQs'} icon={'contact_support'} useToggle>
            {country}
            <FaqsList
                key={country}
                filterId={'pro_' + country}
                options={{ category: 'pro', country_code: country === 'DE' ? 'DE' : null }}
                hasBe={country !== 'DE'}
                showTopics
            />
        </Card>
    )
}

export default ProDashboardView
