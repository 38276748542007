import { call } from '@advanza/api'
import { Row, Col } from '@advanza/grid'
import { Button, Divider, Icon, LinearLoader } from '@advanza/ui'
import SimpleOrderModal from 'components/ui/SimpleOrderModal'
import React, { useState, useEffect } from 'react'
import style from './servicesCrossSelling.module.css'

const MAX_SERVICES = 6

function useCrossSelling(serviceId) {
    const [servicesList, setServicesList] = useState({})
    const [activeIds, setActiveIds] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [error, setError] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const getList = () => {
        setIsLoading(true)
        call('office/sub-categories/get-cross-selling-list/' + serviceId).then(
            ({ list, xsell }) => {
                setServicesList(list)
                setActiveIds(
                    xsell.services ? xsell.services.map((service) => service.service_id) : []
                )
                setIsLoading(false)
            }
        )
    }

    const onSelectService = (serviceId) => {
        error && setError(false)
        if (activeIds.includes(serviceId)) {
            setActiveIds(activeIds.filter((id) => id != serviceId))
        } else {
            if (activeIds.length === MAX_SERVICES) {
                setError(true)
            } else {
                setActiveIds([...activeIds, serviceId])
            }
        }
    }

    const saveList = () => {
        setIsSaving(true)
        call('office/sub-categories/save-cross-selling-list/' + serviceId, {
            json: { activeIds },
        }).then(() => {
            setIsSaving(false)
            setEditMode(false)
        })
    }

    const saveOrder = (newOrder) => {
        setIsSaving(true)
        call('office/sub-categories/save-cross-selling-order/' + serviceId, {
            json: { newOrder },
        }).then(() => {
            setActiveIds(newOrder)
            setIsSaving(false)
        })
    }

    useEffect(() => {
        getList()
    }, [])

    return {
        isLoading,
        servicesList,
        activeIds,
        onSelectService,
        editMode,
        setEditMode,
        saveList,
        saveOrder,
        isSaving,
        error,
    }
}

const ServicesCrossSelling = ({ serviceId }) => {
    const {
        isLoading,
        servicesList,
        activeIds,
        onSelectService,
        editMode,
        setEditMode,
        saveList,
        saveOrder,
        isSaving,
        error,
    } = useCrossSelling(serviceId)

    if (editMode) {
        return (
            <div className={style.edit}>
                <Row>
                    {Object.keys(servicesList)
                        .map((id) => parseInt(id))
                        .map((serviceId, index) => (
                            <Col xs={4} key={index}>
                                <div
                                    className={style.service}
                                    onClick={() => onSelectService(serviceId)}>
                                    <div className={style.checkbox}>
                                        {activeIds.includes(serviceId) && (
                                            <Icon name="checked" primColor fontSize={18} />
                                        )}
                                    </div>
                                    {servicesList[serviceId]}
                                </div>
                            </Col>
                        ))}
                </Row>
                <Row end="xs">
                    {error && (
                        <Col x className={style.warning}>
                            <Divider s />
                            <Icon name="warning" red /> Choose max {MAX_SERVICES} services for cross
                            selling
                        </Col>
                    )}
                    <Col x>
                        <Button onClick={saveList} disabled={isSaving}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className={style.view}>
            {isLoading && <LinearLoader fixed />}
            <Row>
                <Col x>
                    {activeIds.length > 0
                        ? activeIds.map((serviceId, index) => (
                              <span
                                  key={index}
                                  className={[
                                      style.serviceName,
                                      index < 3 ? style.primary : '',
                                  ].join(' ')}>
                                  {index > 0 && (index === activeIds.length - 1 ? ' and ' : ', ')}
                                  {servicesList[serviceId]}
                              </span>
                          ))
                        : 'No cross selling services selected'}
                </Col>
                <Col x>
                    <Button name="text" onClick={() => setEditMode(true)} disabled={isLoading}>
                        <Icon name="edit" className="colorCta" style={{ marginTop: '-4px' }} /> Edit
                    </Button>
                </Col>
                <Col x>
                    <SimpleOrderModal
                        orderedIds={activeIds}
                        disabled={isLoading}
                        text="The first 3 should be the most important cross selling services. Drag the services to adjust the order."
                        renderItem={(value, index) => (
                            <>
                                <span
                                    className={[
                                        style.serviceName,
                                        index < 3 ? style.primary : '',
                                    ].join(' ')}>
                                    {servicesList[value]}
                                </span>
                            </>
                        )}
                        onSave={saveOrder}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ServicesCrossSelling
