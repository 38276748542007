import React from 'react'
import { connect } from 'react-redux'
import { changeEntity } from '../actions/entities'
/**
 * Options:
 * - store,
 * - name,
 * optional:
 *  - deleteFunc,
 *  - saveFunc
 * */
export default (Component, options) => {
    function mapStateToProps(state, props) {
        const { name } = options
        const store = props.useStore || options.store
        const { entityId, entityKey } = props
        const entities = state[store].entities[name]
        const entity = entities && entities[entityKey || entityId]
        const additionalMap = (options.mapStateToProps && options.mapStateToProps(state)) || {}
        return {
            entity,
            entities: state[store].entities,
            ...additionalMap,
        }
    }

    function mapDispatchToProps(dispatch, props) {
        const { name, store, saveFunc, deleteFunc } = options
        const { entityId, entityKey } = props
        const additionalMap =
            (options.mapDispatchToProps && options.mapDispatchToProps(dispatch)) || {}
        return {
            changeEntity: (entity, options = {}) =>
                dispatch(
                    changeEntity({
                        diff: entity,
                        store: props.useStore || store,
                        name,
                        key: entityKey || entityId,
                        ...options,
                    })
                ),
            changeOtherEntity: (options) => dispatch(changeEntity(options)),
            save: (params) => dispatch(saveFunc(entityKey || entityId, params)),
            delete: (params) => dispatch(deleteFunc(entityKey || entityId, params)),
            ...additionalMap,
        }
    }

    return connect(mapStateToProps, mapDispatchToProps, null)(Component)
}
