import { APP } from '@advanza/advanza_generic'
import { changeEntity } from 'actions/entities'
import { addFile, getImageData } from 'actions/files'
import { acChangeSelection, aGetSelection, getFromFilesState } from 'actions/profileBuilders'
import { fetchService } from 'actions/services'
import { useDescription } from 'hooks/descriptionHooks'
import { useProvider } from 'hooks/providerHooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const IMAGE_FILE_CONTEXTS = ['avatar', 'logo', 'profile']

export const NUM_IMAGES = {
    avatar: 1,
    logo: 1,
    profile: 3,
}

export const NOTHING_LABELS = {
    avatar: 'no_good_avatar',
    logo: 'no_good_logo',
    profile: 'no_images_on_website',
}

const STAT_TYPE_IDS = {
    description: 8,
    avatar: 5,
    logo: 6,
    profile: 7,
}

const CONFIDENCE_BASES = {
    avatar: -1,
    logo: -1,
    profile: -1,
}
const CONFIDENCE_THRESHOLDS = {
    avatar: 50,
    logo: 50,
    profile: 50,
}

const TITLE_BASES = {
    avatar: 'Avatar',
    logo: 'Logo',
    profile: 'Bedrijfsfoto',
}

export const getWeightStart = (context) =>
    Object.keys(NUM_IMAGES).reduce(
        (accumulator, currentContext) =>
            currentContext === context || accumulator
                ? accumulator + NUM_IMAGES[currentContext]
                : 0,
        0
    )

export const getTitle = (context, weight = 0) =>
    TITLE_BASES[context] +
    (NUM_IMAGES[context] > 1 ? ` ${getWeightStart(context) - weight + 1}` : '')

export const sortScrapedImages = (images) =>
    [...images].sort((a, b) => {
        // Chrome does not accept true/false in sort
        // Sort on priority (lower is more priority, order to front)
        // and put duplicates in the back
        if ((a.duplicate && !b.duplicate) || (b.duplicate && !a.duplicate)) {
            return a.duplicate ? 1 : -1
        }

        const a_url = a.image_response_url
        const b_url = b.image_response_url

        if (a.priority === b.priority && a_url !== b_url) {
            if (a_url.length === b_url.length) {
                return a_url > b_url ? 1 : -1
            }

            return a_url.length > b_url.length ? -1 : 1
        }

        return a.priority > b.priority ? 1 : -1
    })

export const getImageUrl = (imageFile, scale = 'l') => {
    if (!imageFile) {
        return undefined
    }

    if (imageFile.preview) {
        return imageFile.preview
    }

    const parts = imageFile.file_key.split('/')
    const fileName = parts.pop()

    return APP.S3_PUBLIC() + parts.join('/') + `/${scale}_` + fileName
}

export const getImageDash = (scrapedImageOrImageFile = {}) =>
    ((
        scrapedImageOrImageFile.image_name ||
        scrapedImageOrImageFile.imageName ||
        (scrapedImageOrImageFile.key && scrapedImageOrImageFile.key.replace(/.*\//, '')) ||
        scrapedImageOrImageFile.filename ||
        ''
    ).match(/^([a-z0-9]{40}_)?(.{0,14})/)?.[2] || '') +
    '-' +
    scrapedImageOrImageFile.size

export const useProfileBuilderWizard = (providerId) => {
    const dispatch = useDispatch()
    const { provider = {}, user = {}, websiteWithProtocol } = useProvider(providerId)
    const labels = provider.office_labels || []
    const findLabel = (type) => labels.find((label) => label.type === type)
    const serviceId =
        parseInt(findLabel('profile_builder_wizard')?._joinData?.extra, 10) ||
        ((provider.subscriptions || [])[0] || {}).service_id
    const { services = {} } = useSelector((state) => state.services.entities)
    const service = services[serviceId] || {}

    useEffect(() => {
        if (serviceId) {
            dispatch(fetchService(serviceId))
        }
    }, [dispatch, serviceId])

    const { hasFetchedDescriptions, preselectedDescription, descriptionPreselectMethod } =
        useDescription(provider)

    const filesState = useSelector((state) => state.files)
    const { result = {}, websiteImages = {} } = filesState
    const {
        filterId,
        filter,
        searchKey,
        imageFiles = [],
    } = getFromFilesState(providerId, filesState)

    const sortedScrapedImagesRaw = sortScrapedImages(
        (websiteImages[provider.website] || {}).images || []
    )

    const hasFetchedImages = websiteImages[provider.website] && result[searchKey]

    const confidenceScores = {
        avatar: ((websiteImages[provider.website] || {}).avatarConfidenceScores || {}).Avatar || {},
        logo: ((websiteImages[provider.website] || {}).logoConfidenceScores || {}).Logo || {},
    }
    const getConfidence = (context, key) =>
        confidenceScores[context]?.[key.replace(/.*\//, '')] ?? CONFIDENCE_BASES[context]

    const getSelection = (pageKey) => aGetSelection(provider, pageKey)
    const notFoundContexts = getSelection('images').notFoundContexts || {}

    const getImageDashSelection = (context) =>
        context === 'avatar'
            ? user.avatarImageDash
                ? [user.avatarImageDash]
                : []
            : imageFiles.filter((imageFile) => imageFile.context === context).map(getImageDash)

    const currentContext = IMAGE_FILE_CONTEXTS.find(
        (context) =>
            getImageDashSelection(context).length < NUM_IMAGES[context] &&
            !notFoundContexts[context]
    )
    const currentWeight = currentContext
        ? getWeightStart(currentContext) - getImageDashSelection(currentContext).length
        : 0

    const getScrapedImages = (sortByContext = 'logo') =>
        sortedScrapedImagesRaw
            .map((image) => ({
                key: image.key,
                selected: IMAGE_FILE_CONTEXTS.find((context) =>
                    getImageDashSelection(context).includes(getImageDash(image))
                ),
                imageName: image.image_name,
                size: image.size,
                url: image.url,
                file: imageFiles.find((file) => getImageDash(file) === getImageDash(image)),
                imageResponseUrl: image.image_response_url,
            }))
            .sort(
                (a, b) => getConfidence(sortByContext, b.key) - getConfidence(sortByContext, a.key)
            )

    const getPreselectionScrapedImages = (context) =>
        getScrapedImages(context)
            .filter(
                ({ key }) =>
                    CONFIDENCE_THRESHOLDS[context] <= getConfidence(context, key) &&
                    (context === 'avatar' ||
                        key !== (getPreselectionScrapedImages('avatar')[0] || {}).key) &&
                    (context !== 'profile' ||
                        key !== (getPreselectionScrapedImages('logo')[0] || {}).key)
            )
            .slice(0, NUM_IMAGES[context])

    const getStats = (pageKey) =>
        pageKey === 'description'
            ? {
                  [STAT_TYPE_IDS['description']]: {
                      preselectMethod: descriptionPreselectMethod,
                      preselection: preselectedDescription ? [preselectedDescription] : [],
                      selection: provider.description ? [provider.description] : [],
                  },
              }
            : pageKey === 'images'
            ? IMAGE_FILE_CONTEXTS.filter((context) => context !== 'profile').reduce(
                  (statsObj, context) => {
                      const preselection = getPreselectionScrapedImages(context).map(getImageDash)
                      return {
                          ...statsObj,
                          [STAT_TYPE_IDS[context]]: {
                              preselectMethod: preselection.length ? 'ai' : '',
                              preselection,
                              selection: getImageDashSelection(context),
                              confidenceThreshold: CONFIDENCE_THRESHOLDS[context],
                              confidenceScores: confidenceScores[context],
                          },
                      }
                  },
                  {}
              )
            : {}

    const changeProvider = (diff) =>
        dispatch(changeEntity({ store: 'providers', name: 'providers', key: providerId, diff }))

    const changeUser = (diff) =>
        dispatch(changeEntity({ store: 'providers', name: 'users', key: user.id, diff }))

    const changeFile = (fileId, diff) =>
        dispatch(
            changeEntity({
                store: 'files',
                name: 'files',
                key: fileId,
                diff,
            })
        )

    const changeSelection = (pageKey, newSelection) =>
        dispatch(acChangeSelection(providerId, pageKey, newSelection))

    const selectImage = (scrapedImageOrImageFile, context = currentContext, weight = 0) => {
        if (context === undefined) {
            return
        }

        if (notFoundContexts[context]) {
            const notFoundContextsNew = { ...notFoundContexts }
            delete notFoundContextsNew[context]
            changeSelection('images', {
                notFoundContexts: notFoundContextsNew,
            })
        }

        deselectImage(scrapedImageOrImageFile)
        const imageDash = getImageDash(scrapedImageOrImageFile)
        const isScrapedImage = scrapedImageOrImageFile.key

        if (context === 'avatar') {
            if (isScrapedImage) {
                changeUser({ _saving: true })
                dispatch(getImageData(providerId, provider.website, scrapedImageOrImageFile.key))
                    .then((data) =>
                        changeUser({
                            avatarFile: {
                                filename: scrapedImageOrImageFile.imageName,
                                size: scrapedImageOrImageFile.size,
                                preview: 'data:image/png;base64,' + data,
                                base64: data,
                                crop: {},
                            },
                            avatarImageDash: imageDash,
                        })
                    )
                    .finally(() => changeUser({ _saving: false }))
            } else {
                changeUser({
                    avatarFile: scrapedImageOrImageFile,
                    avatarImageDash: imageDash,
                })
            }
        } else {
            const imageFileFromStore = imageFiles.find(
                (imageFile) => getImageDash(imageFile) === imageDash
            )

            imageFiles.forEach(
                (imageFile) =>
                    imageFile.file_id !== imageFileFromStore?.file_id &&
                    imageFile.context === context &&
                    imageFile.weight === weight &&
                    changeFile(imageFile.file_id, { context: 'hide_pro' })
            )

            if (!imageFileFromStore) {
                dispatch(
                    addFile(
                        filterId,
                        isScrapedImage
                            ? {
                                  filename: scrapedImageOrImageFile.imageName,
                                  size: scrapedImageOrImageFile.size,
                                  type: 'image',
                                  context,
                                  weight,
                                  preview: scrapedImageOrImageFile.url,
                                  scrapedImageKey: scrapedImageOrImageFile.key,
                              }
                            : scrapedImageOrImageFile,
                        false
                    )
                )
            } else if (
                imageFileFromStore.context !== context ||
                imageFileFromStore.weight !== weight
            ) {
                dispatch(
                    changeEntity({
                        store: 'files',
                        name: 'files',
                        key: imageFileFromStore.file_id,
                        diff: { context, weight },
                    })
                )
            }
        }
    }

    const deselectImage = (scrapedImageOrImageFileOrImageDash) => {
        const imageDash =
            typeof scrapedImageOrImageFileOrImageDash === 'string'
                ? scrapedImageOrImageFileOrImageDash
                : getImageDash(scrapedImageOrImageFileOrImageDash)

        imageFiles.forEach(
            (imageFile) =>
                getImageDash(imageFile) === imageDash &&
                imageFile.context !== 'hide_pro' &&
                changeFile(imageFile.file_id, { context: 'hide_pro' })
        )

        if (user.avatarImageDash === imageDash) {
            changeUser({
                avatar_filename: null,
                avatarFile: null,
                avatarImageDash: null,
            })
        }
    }

    return {
        provider,
        user,
        websiteWithProtocol,
        labels,
        findLabel,
        service,
        filterId,
        filter,
        imageFiles,
        hasFetchedImages,
        hasFetchedDescriptions,
        getSelection,
        notFoundContexts,
        getImageDashSelection,
        currentContext,
        currentWeight,
        getScrapedImages,
        getPreselectionScrapedImages,
        getStats,
        changeProvider,
        changeUser,
        changeFile,
        changeSelection,
        selectImage,
        deselectImage,
    }
}
