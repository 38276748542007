import { Col, Row } from '@advanza/grid'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import { FixedWarningPopup } from '@advanza/ui'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useEntityShared } from 'hooks/sharedHooks'
import React, { Fragment, useState } from 'react'
import { NON_FORM_FIELDS, SELECTS, SPEC, TYPE_EXCLUDES, DEFINITIONS } from './CtasList'

const getFields = (cta) => {
    const fields = {}
    Object.keys(DEFINITIONS)
        .filter(
            (key) =>
                !NON_FORM_FIELDS.includes(key) && !(TYPE_EXCLUDES[cta.type] || []).includes(key)
        )
        .forEach(
            (key) =>
                (fields[key] = {
                    ...(SELECTS[key]
                        ? {
                              type: 'selectSimple',
                              placeholder: (
                                  <PreIcon icon={SELECTS[key].icon}>
                                      {DEFINITIONS[key].name}
                                  </PreIcon>
                              ),
                              options: SELECTS[key].options,
                          }
                        : {
                              type: 'text',
                              placeholder: DEFINITIONS[key].name,
                              inputPlaceholder: '<DEFAULT>',
                          }),
                    legend: true,
                })
        )
    return fields
}

const CtaEntity = ({ ctaId }) => {
    const [toast, setToast] = useState({})
    const {
        entity: cta,
        renderInput,
        onSaveEntity,
        onDeleteEntity,
    } = useEntityShared(SPEC, ctaId, getFields)

    const onSave = () =>
        onSaveEntity().then(
            () => setToast({ id: Date.now(), message: 'Cta saved' }),
            (response) =>
                setToast({ isError: true, id: Date.now(), message: JSON.stringify(response) })
        )

    return (
        <LoadBoxWrapper isLoading={false}>
            <div>
                <Row>
                    {Object.keys(getFields(cta))
                        .filter((key) => SELECTS[key])
                        .map((key) => (
                            <Col key={key} x>
                                {renderInput(key)}
                            </Col>
                        ))}
                </Row>
                {Object.keys(getFields(cta))
                    .filter((key) => !SELECTS[key])
                    .map((key) => (
                        <Fragment key={key}>
                            <Divider m />
                            {renderInput(key)}
                        </Fragment>
                    ))}
                <Divider m />
                <Row end="xs" middle="xs">
                    <Col x>
                        <button onClick={onDeleteEntity}>
                            <Icon name={cta._saving ? 'hourglass_empty' : 'delete_forever'} />
                        </button>
                    </Col>
                    <Col x>
                        <Button
                            type="text"
                            onClick={onSave}
                            disabled={!cta._isTouched || cta._saving}>
                            {cta._saving ? '..saving' : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </div>
            {toast.isError && <div style={{ color: 'red' }}>not saved</div>}
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </LoadBoxWrapper>
    )
}

export default CtaEntity
