import { useEffect, useRef } from 'react'

export const removeDups = (arr) => {
    let obj = {}
    arr.forEach((item) => (obj[item] = item))
    return Object.values(obj)
}

export const groupBy = (arr, field) => {
    const obj = {}
    arr.forEach((item) => (obj[item[field]] = [...(obj[item[field]] || []), item]))
    return obj
}

export const shuffle = (arr = []) => {
    const arrCopy = [...arr]
    const arrNew = []

    while (arrCopy.length) {
        const index = Math.floor(Math.random() * arrCopy.length)
        arrNew.push(arrCopy.splice(index, 1)[0])
    }

    return arrNew
}

export const toLowerUnderscore = (str) => str.replace(/(\w)([A-Z])/g, '$1_$2').toLowerCase()

export const toCamelBacked = (str) => str.replace(/_(.)/g, (_match, p1) => p1.toUpperCase())

export const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1)

export const decodeURISafe = (str) => {
    try {
        return decodeURI(str)
    } catch (e) {
        return str
    }
}

// don't store trimmed value on edit, so you can still enter a sentence
export const parseInputFieldString = (value, fallback = '') =>
    (value || '').trim() ? value : fallback

export const parseInputFieldInt = (value, fallback = 0) =>
    !Number.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : fallback

export const parseInputFieldFloat = (value, fallback = 0) =>
    !Number.isNaN(parseFloat(value)) ? parseFloat(value) : fallback

export function scrollToY(scrollTarget, speed = 2000, element = window, x = false) {
    window.requestAnimFrame = (() => {
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60)
            }
        )
    })()

    const hasScrollToSuppoer = element.scrollTo
    const scroll = x
        ? element.scrollX || element.scrollLeft
        : element.scrollY || element.scrollTop || document.documentElement.scrollTop

    let currentTime = 0

    // set min en max duration
    const time = Math.max(0.1, Math.min(Math.abs(scroll - scrollTarget) / speed, 1))
    function doScroll(position) {
        if (hasScrollToSuppoer) {
            element.scrollTo(x ? position : 0, x ? 0 : position)
        } else {
            element.scrollTop = x ? position : 0
        }
    }

    // the animation loop
    function tick() {
        currentTime += 1 / 60

        const p = currentTime / time
        const t = -0.5 * (Math.cos(Math.PI * p) - 1)

        if (p < 1) {
            window.requestAnimFrame(tick)
            const position = scroll + (scrollTarget - scroll) * t
            doScroll(position)
        } else {
            doScroll(scrollTarget)
        }
    }
    tick()
    return time
}
export function scrollToX(scrollTarget, speed, element) {
    return scrollToY(scrollTarget, speed, element, true)
}

// can be used to reduce the amount of redundant data in a POST request
export function stripEntity(entity, exclude = []) {
    return Object.keys(entity).reduce((obj, key) => {
        if (key.startsWith('_') || exclude.includes(key)) {
            // exclude entity._beforeSave, etc
            return obj
        }
        // copy key
        obj[key] = entity[key]
        return obj
    }, {})
}

export function* indexGenerator(initial = 0) {
    let key = initial
    while (key < key + 1) {
        yield key++
    }
}

export const getIndexArray = (num) => {
    const arr = []

    for (let i = 0; i < num; i++) {
        arr.push(i)
    }

    return arr
}

export const useResizeObserver = ({
    onResize = () => {},
    borderBox = true,
    adjustField = 'minHeight',
    adjustOffset = 0,
} = {}) => {
    const observeRef = useRef(null)
    const adjustRef = useRef(null)

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) =>
            entries.forEach((entry) => {
                const boxes = borderBox ? entry.borderBoxSize : entry.contentBoxSize
                const width = boxes?.length ? boxes[0].inlineSize : entry.contentRect.width
                const height = boxes?.length ? boxes[0].blockSize : entry.contentRect.height
                onResize(width, height)
                adjustRef.current &&
                    (adjustRef.current.style[adjustField] = height + adjustOffset + 'px')
            })
        )

        const observeNode = observeRef.current
        observeNode && resizeObserver.observe(observeNode)

        return () => observeNode && resizeObserver.unobserve(observeNode)
    }, [adjustField, adjustOffset, borderBox, onResize])

    return { observeRef, adjustRef }
}

export const getReadableFileSizeString = (fileSizeInBytes) => {
    if (fileSizeInBytes > 1000 * 1000) {
        const size = Math.round(fileSizeInBytes / (1024 * 1024))
        return `${size} MB`
    }
    const size = Math.round(fileSizeInBytes / 1024)
    return `${size} kB`
}
