import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    MATCHES_CHANGE_FILTER,
    MATCHES_INVALIDATE,
    MATCHES_RECEIVE_LIST,
    MATCHES_RECEIVE_SINGLE,
    MATCHES_REQUEST,
} from '../actions/matches'
import { changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [MATCHES_RECEIVE_LIST]: (state, action) => update(receiveList(state, action), {}),
    [MATCHES_CHANGE_FILTER]: (state, action) => update(changeFilter(state, action), {}),
    INVALIDATE_MATCHES: invalidateFilters,
    [MATCHES_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [MATCHES_INVALIDATE]: (state) =>
        update(state, {
            didInvalidate: { $set: true },
        }),
    [MATCHES_RECEIVE_SINGLE]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            entities: { $deepMerge: action.entities },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
