import { call } from '@advanza/api'
import { Icon } from '@advanza/ui'
import BigPicker from 'components/ui/BigPicker'
import React, { useEffect, useRef, useState } from 'react'

function findLocation(query = '', countryCode = false) {
    return call('geo/geo-id-results/', {
        query: {
            query,
            countryCode
        }
    })
}

const SearchLocationInput = ({
                                 initial,
                                 onChangeLocation,
                                 limit,
                                 onChangeQuery,
                                 fixedQuery,
                                 value,
                                 withIcon,
                                 countryCode,
                                 onClose,
                                 ...rest
                             }) => {
    const [options, setOptions] = useState([])
    const [query, setQuery] = useState('')
    const el = useRef(null)

    useEffect(() => {
        if (initial) {
            fetchLocation(initial, false).then(() => {
                if (options.length > 0) {
                    onChangeLocation(options[0])
                }
            })
        }
    }, [initial])

    const fetchLocation = (q = false, saveQuery = true) => {
        const query = fixedQuery ? fixedQuery : q !== false ? q : query
        onChangeQuery && onChangeQuery(q)
        return findLocation(query, countryCode).then(({ results }) => {
            setOptions(
                results.map((result) => {
                    return {
                        name: result.place_name,
                        value: result.url_name,
                        ...result
                    }
                })
            )
            if (saveQuery) {
                setQuery(query)
            }
        })
    }

    const handleOnClose = (hasSelected) => {
        if (!hasSelected && query.length > 2 && options.length > 0) {
            onChangeLocation(options[0])
        }
        onClose && onClose(hasSelected)
    }

    return (
        <BigPicker
            autoFocus
            onOpen={() => fetchLocation(false)}
            hideTriangle
            before={withIcon && <Icon name='place' fontSize={21} />}
            inputPlaceholder='Plaats'
            {...rest}
            onChangeQuery={fetchLocation}
            value={value}
            error={rest.error}
            ref={el}
            onClose={handleOnClose}
            onChangeValue={onChangeLocation}
            readOnly={false}
            withGroups={false}
            options={options}
        />
    )
}

SearchLocationInput.propTypes = {}

export default SearchLocationInput
