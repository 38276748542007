import { addAggregatedReview } from 'actions/aggregatedReviews'
import React from 'react'
import { connect } from 'react-redux'
import { changeAggregatedReviewsFilter } from '../actions/aggregatedReviews'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.aggregatedReviews
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]
    return {
        filter,
        count,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeAggregatedReviewsFilter(id, options)),
        add: (providerId, filterId) => dispatch(addAggregatedReview(providerId, filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
