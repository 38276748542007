import { InputField } from '@advanza/input'
import { Colors } from 'colors'
import { ChangeEvent } from 'react'
import style from './simpleColorPicker.module.scss'

interface Props {
    value: string
    colors?: string[]
    onChange: (event: any) => void
    error?: string
    name: string
}

const SimpleColorPicker = ({ value, colors = Colors, onChange, error, name }: Props) => {
    const onHandleChange = (color: string) => {
        const hex = color.charAt(0) === '#' ? color : `#${color}`

        onChange({ target: { name, value: hex } })
    }

    return (
        <div className={style.root}>
            <div className={style.triangleBg} />
            <div className={style.triangle} />
            <div className={style.grid}>
                {colors.map((color) => (
                    <div
                        key={color}
                        className={style.swatch}
                        onClick={() => onHandleChange(color)}
                        style={{ backgroundColor: color }}
                    />
                ))}
                <InputField
                    classNames={[style.input]}
                    name={name}
                    value={value.substring(1)}
                    error={error}
                    before={'#'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e.target.value)}
                />
            </div>
        </div>
    )
}

export default SimpleColorPicker
