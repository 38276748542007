import { call } from '@advanza/api'
import { invalidateNotes } from 'actions/notes'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

export function useCopyInvalidMatch(matchId) {
    const dispatch = useDispatch()
    const [error, setError] = useState(null)

    const [isPosting, setIsPosting] = useState(false)
    const [result, setResult] = useState(null)
    const copyMatchAndConfirm = () => {
        // copy match to a valid duplicate request
        if (!isPosting) {
            setResult(null)
            setIsPosting(true)
            return call('office/matches/copy-and-confirm-match/' + matchId, {
                method: 'post',
            }).then(
                (response) => {
                    setResult(response)
                    setIsPosting(false)
                    dispatch(invalidateNotes())
                },
                (response) => {
                    setError(response)
                    setIsPosting(false)
                }
            )
        }
    }

    const [isCopying, setIsCopying] = useState(false)
    const [isCopied, setIsCopied] = useState(false)
    const copyRequestDetails = () => {
        // copy request details to a valid duplicate match (i.e. a charged valid match with the same provider)
        if (!isCopying) {
            setIsCopied(null)
            setIsCopying(true)
            return call('office/matches/copy-request-details/' + matchId, { method: 'post' }).then(
                (response) => {
                    setIsCopied(response)
                    setIsCopying(false)
                    dispatch(invalidateNotes())
                },
                (response) => {
                    setError(response)
                    setIsCopying(false)
                }
            )
        }
    }

    return {
        error,
        copyMatchAndConfirm,
        isPosting,
        result,
        copyRequestDetails,
        isCopying,
        isCopied,
    }
}
