import { toLowerUnderscore } from '@advanza/func'
import { add, changeOrder, getPrim, remove, save, singularize } from 'actions/sharedActions'
import { useChangeEntity } from 'hooks/entityHooks'
import { useDispatch, useSelector } from 'react-redux'

/**
 * A relation to a higher level is required here in spec.nameRel and keyRel.
 * For 'ctas' this would be 'services' as spec.nameRel and the service id for keyRel.
 **/
export const useListShared = (spec, keyRel, getFields = {}) => {
    const { store, name, nameRel, singleRel = false } = spec
    const dispatch = useDispatch()
    const {
        isFetching,
        entities: { [nameRel]: entitiesRel = {}, [name]: entitiesMain = {} },
    } = useSelector((state) => state[store])

    const entityRel = entitiesRel[keyRel] || {}
    const fieldRel = toLowerUnderscore(singleRel ? singularize(name) : name)
    const extraReturn = {}

    if (singleRel) {
        const key = entityRel[fieldRel]
        extraReturn.entity = entitiesMain[key]
    } else {
        const keys = entityRel[fieldRel] || []
        extraReturn.entityArr = keys
            .map((key) => entitiesMain[key])
            .filter((entity) => entity)
            .sort((entityA, entityB) => (entityA.order_value || 0) - (entityB.order_value || 0))
        const prim = getPrim(name)
        extraReturn.keys = extraReturn.entityArr.map((entity) => entity[prim])
    }

    const dispatchSave = (key = extraReturn.keys || entityRel[fieldRel]) =>
        dispatch(save(spec, key, getFields))

    return {
        isFetching,
        add: (extraDiff = {}) => dispatch(add(spec, extraDiff, keyRel)),
        changeOrder: (key, modify) => dispatch(changeOrder(spec, key, modify)),
        ...extraReturn,
        save: dispatchSave,
    }
}

export const useEntityShared = (spec, entityId, getFields = {}, options = {}) => {
    const dispatch = useDispatch()
    return {
        ...useChangeEntity(
            {
                store: spec.store,
                name: spec.name,
                entityId,
                deleteFunc: (key) => remove(spec, key),
            },
            getFields
        ),
        onSaveEntity: () => dispatch(save(spec, entityId, getFields, options)),
    }
}
