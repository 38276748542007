import React from 'react'
import { connect } from 'react-redux'
import { unLinkSalesLeadStatus } from '../actions/salesLeads'

function mapStateToProps(state, props) {
    const { entities } = state.statuses

    const { statuses = {}, salesLeadsStatuses = {} } = entities
    const salesLeadStatus = salesLeadsStatuses[props.id]
    const status = salesLeadsStatuses && statuses[salesLeadStatus.status]
    return {
        salesLeadStatus,
        status,
    }
}

function mapDispatchToProps(dispatch, props) {
    return {
        unsetStatus: () => dispatch(unLinkSalesLeadStatus(props.id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
