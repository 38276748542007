import React from 'react'
import PropTypes from 'prop-types'
import UserViewContainer from '../../containers/UserViewContainer'
import UserEntity from './UserEntity'

class UserView extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.fetchNotifications()
    }

    render() {
        const {
            userId,
            useStore,
            permissionLocation,
            notifications,
            notificationIds,
            notificationSettings,
            profileMode,
            hideSave,
        } = this.props

        return (
            <div>
                <UserEntity
                    entityId={userId}
                    useStore={useStore}
                    notifications={notifications}
                    notificationSettings={notificationSettings}
                    permissionLocation={permissionLocation}
                    notificationIds={notificationIds}
                    profileMode={profileMode}
                    hideSave={hideSave}
                />
            </div>
        )
    }
}

UserView.propTypes = {}

export default UserViewContainer(UserView)
