import { Icon, Row } from '@advanza/advanza_generic'
import { PreIcon, Button } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import NotesFilterContainer from '../../containers/NotesFilterContainer'
import CheckButton from '../CheckButton'
import Pagination from '../Pagination'

class NotesFilter extends React.Component {
    constructor(props) {
        super(props)

        this.invalidate = this.invalidate.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    invalidate() {
        this._changeFilter({
            didInvalidate: true,
            page: 0,
        })
    }

    render() {
        const { filter, count, addNote, newEntityOptions } = this.props
        if (!filter) {
            return null
        }
        const pageNr = filter.page || 0

        return (
            <Row className="v-center" s="min" nm>
                <Button name="text" onClick={(e) => addNote(newEntityOptions, filter.filterId)}>
                    <PreIcon primColor icon="add">
                        Add note
                    </PreIcon>
                </Button>

                <div className="to-right">
                    <Pagination
                        current={pageNr}
                        pageSize={filter.pageSize}
                        count={count}
                        onPageChange={this.onPageChange}
                    />
                </div>
            </Row>
        )
    }
}

NotesFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    newEntityOptions: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
}

export default NotesFilterContainer(NotesFilter)
