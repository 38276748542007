import React from "react"
import Section from "../src/Section"

export const Row = (props) => {
    let classString = (props.className ? (props.className + ' row' ):  ' row')
    if(props.nowrap) {
        classString += ' no-wrap'
    }
    if(props.nm) {
        classString += ' nm'
    }

    const sectionProps = {...props,
        className: classString
    }

    return (
        <Section {...sectionProps}>
            {props.children}
        </Section>
    )
}