import { Divider, MaxWidth } from '@advanza/ui'
import ActivePausesFilter from 'components/pauses/ActivePausesFilter'
import ActivePausesList from 'components/pauses/ActivePausesList'
import Page from 'components/ui/Page'

const ActivePausesPage = () => {
    return (
        <Page title="Active Pauses">
            <MaxWidth>
                <ActivePausesFilter filterId="all" />
                <Divider m />
                <ActivePausesList filterId="all" selectable />
            </MaxWidth>
        </Page>
    )
}

export default ActivePausesPage
