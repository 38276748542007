import { fetchAdminUsers } from 'actions/adminUsers'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useAdminUser = (userId) => {
    const dispatch = useDispatch()
    const { entities, isFetching } = useSelector((state) => state.adminUsers)
    const { adminUsers } = entities
    const user = adminUsers && adminUsers[userId]

    useEffect(() => {
        dispatch(fetchAdminUsers())
    }, [dispatch])

    return {
        user,
        isFetching,
    }
}
