import FlexTable from 'components/FlexTable/FlexTable'
import A from 'components/ui/A'
import { useInvoice } from 'hooks/billingHooks'
import React from 'react'
import format from 'date-fns/format'

const definitions = {
    charge_id: { name: '#' },
    request_id: { name: 'request id' },
    description: { name: 'description' },
    requestDate: { name: 'request date' },
    charge_amount: { name: '€' },
}
function createRows(values) {
    return values.map((row) => {
        return {
            cols: Object.keys(definitions).map((id) => {
                switch (id) {
                    case 'charge_amount':
                        return '€' + parseFloat(row[id]).toFixed(2)
                    case 'request_id':
                        return <A to={`/service/requests/request/${row[id]}`}>{row[id]}</A>
                    case 'requestDate':
                        return format(new Date(row[id]), 'dd-MM-yy')
                    default:
                        return row[id]
                }
            }),
        }
    })
}
const InvoiceItem = ({ invoiceId }) => {
    const { invoice, charges } = useInvoice(invoiceId)
    return (
        <div>
            <FlexTable definitions={definitions} rows={createRows(charges)} />
        </div>
    )
}

export default InvoiceItem
