import {
    PROFILE_STATUS_CHURNED,
    PROFILE_STATUS_DISABLED_BAD_PROFILE,
    PROFILE_STATUS_GDPR,
} from '@advanza/constants'
import {
    MANUAL_PAUSE_REASON_OVERDUE_INVOICE,
    MANUAL_PAUSE_REASON_REGULAR,
} from 'components/providers/ProviderManualPause'

export function getActiveStatus(provider) {
    if (provider.status === PROFILE_STATUS_GDPR) {
        return {
            icon: 'delete_forever',
            color: 'purple',
            title: 'GDPR',
            backgroundColor: '#80008055',
        }
    } else if (provider.status === PROFILE_STATUS_DISABLED_BAD_PROFILE) {
        return {
            icon: 'thumb_down_alt',
            color: '#cdcdcd',
            title: 'Disabled bad review score',
        }
    } else if (provider.status === PROFILE_STATUS_CHURNED) {
        return {
            icon: 'stop_circle',
            color: '#f42435',
            title: 'Churned',
            backgroundColor: 'rgba(0,0,0,0.18)',
        }
    }

    if (provider.isUnsubscribed) {
        return {
            icon: 'stop',
            color: 'rgb(255,0,0)',
            title: 'Unsubscribed',
            backgroundColor: 'rgba(0,0,0,0.18)',
        }
    }

    if (provider.manualPause) {
        return {
            icon: 'pause',
            color: 'rgb(238,209,10)',
            title:
                'On manual pause ' +
                (provider.manualPauseReason === MANUAL_PAUSE_REASON_REGULAR
                    ? 'regular'
                    : provider.manualPauseReason === MANUAL_PAUSE_REASON_OVERDUE_INVOICE
                    ? 'overdue invoice'
                    : ''),
            backgroundColor: 'rgba(255,223,12,0.18)',
        }
    }
    if (provider.disabled_overdue_invoices) {
        return {
            icon: 'coronavirus',
            color: 'rgb(255,0,0)',
            title: 'Disabled overdue invoice',
            backgroundColor: 'rgba(167, 167, 167, 0.18)',
        }
    }
    if (provider.isFreeTrial) {
        if (provider.active_free_trial.day_limit_reached) {
            return {
                icon: 'pause',
                color: 'rgb(238,209,10)',
                title: 'Free trial - day limit reached',
                backgroundColor: 'rgba(255,223,12,0.18)',
            }
        } else if (provider.limitReached) {
            return {
                icon: 'pause',
                color: 'rgb(238,209,10)',
                title: 'Free trial - limit reached',
                backgroundColor: 'rgba(255,223,12,0.18)',
            }
        } else if (provider.pausePeriod) {
            return {
                icon: 'pause',
                color: 'rgb(238,209,10)',
                title: 'Free trial - active pause period',
                backgroundColor: 'transparent',
            }
        } else {
            return {
                icon: 'play_circle',
                color: 'rgb(12, 214, 5)',
                title: 'Free trial - active',
                backgroundColor: 'rgba(14,248,6,0.14)',
            }
        }
    }
    if (provider.limitReached) {
        return {
            icon: 'pause',
            color: 'rgb(238,209,10)',
            title: 'Limit reached',
            backgroundColor: 'rgba(255,223,12,0.18)',
        }
    }
    if (provider.pausePeriod) {
        return {
            icon: 'pause',
            color: 'rgb(238,209,10)',
            title: 'Active pause period',
            backgroundColor: provider.isSigned ? 'rgba(255,223,12,0.18)' : 'transparent',
        }
    }
    if (provider.isAdvertiser || provider.isSigned) {
        return {
            icon: 'play_arrow',
            color: 'rgb(12, 214, 5)',
            title: 'Active',
            backgroundColor: 'rgba(14,248,6,0.14)',
        }
    }
    if (provider.freeLimitReached && provider.freeLimitReached !== '0') {
        return {
            icon: 'pause',
            color: 'rgb(238,209,10)',
            title: 'Free Limit reached',
            backgroundColor: 'rgba(255,223,12,0.18)',
        }
    }
    if (!provider.postal_code_id) {
        return {
            icon: 'error',
            color: 'rgb(238, 56, 109)',
            title: 'Incomplete',
            backgroundColor: 'rgb(238, 56, 109, 0.18)',
        }
    }
    return {
        icon: 'stop',
        color: '#cdcdcd',
    }
}
