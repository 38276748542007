import React from 'react'
import Page from './Page'

const ComingSoon = ({ match }) => {
    return <Page>...</Page>
}

ComingSoon.propTypes = {}

export default ComingSoon
