import { call } from '@advanza/api'
import { changeEntity } from 'actions/entities'
import { _fetchItems, _changeFilter } from 'actions/lists'
import aggregatedReviewsSchema from 'schemes/aggregatedReviewsSchema'

export const AGGREGATED_REVIEWS_RECEIVE_LIST = 'AGGREGATED_REVIEWS_RECEIVE_LIST'
export const AGGREGATED_REVIEWS_CHANGE_FILTER = 'AGGREGATED_REVIEWS_CHANGE_FILTER'
export const AGGREGATED_REVIEWS_REQUEST = 'AGGREGATED_REVIEWS_REQUEST'
export const AGGREGATED_REVIEWS_RECEIVE_SOURCES = 'AGGREGATED_REVIEWS_RECEIVE_SOURCES'

export function fetchAggregatedReviews(filterId) {
    return _fetchItems(filterId, {
        url: 'office/aggregated-reviews/get-list',
        schema: aggregatedReviewsSchema,
        requestActionName: AGGREGATED_REVIEWS_REQUEST,
        receiveActionName: AGGREGATED_REVIEWS_RECEIVE_LIST,
        reducerKey: 'aggregatedReviews',
    })
}

export function changeAggregatedReviewsFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'aggregatedReviews',
        fetchFunc: fetchAggregatedReviews,
        actionType: AGGREGATED_REVIEWS_CHANGE_FILTER,
    })
}

export function fetchAllSources(forceFetch) {
    return function (dispatch, getState) {
        if (getState().aggregatedReviews.sources.length > 0 && !forceFetch) {
            return Promise.resolve()
        }
        return call('office/aggregated-reviews/get-all-sources').then((response) => {
            dispatch({ type: AGGREGATED_REVIEWS_RECEIVE_SOURCES, ...response })
        })
    }
}

export function deleteAggregatedReview(id) {
    return function (dispatch) {
        return call(`office/aggregated-reviews/delete/${id}`, { method: 'post' }).then(
            (response) => {
                dispatch({ type: 'INVALIDATE_AGGREGATED_REVIEWS' })
            }
        )
    }
}
export function saveAggregatedReview(id) {
    return function (dispatch, getState) {
        const { aggregatedReviews, aggregatedReviewsSnippets } =
            getState().aggregatedReviews.entities
        const entity = aggregatedReviews[id]
        const isNew = typeof id === 'string'
        return call(`office/aggregated-reviews/save/${isNew ? '' : id}`, {
            json: {
                ...entity,
                aggregated_reviews_snippets: entity.aggregated_reviews_snippets.map(
                    (id) => aggregatedReviewsSnippets[id]
                ),
            },
        }).then((response) => {
            dispatch({ type: 'INVALIDATE_AGGREGATED_REVIEWS' })
        })
    }
}

export function deleteSnippet(id) {
    return function (dispatch, getState) {
        const isNew = typeof id === 'string'
        const { aggregatedReviews, aggregatedReviewsSnippets } =
            getState().aggregatedReviews.entities
        if (isNew) {
            const entity = aggregatedReviewsSnippets[id]
            const aggregatedReview = aggregatedReviews[entity.aggregated_review_id]
            dispatch(
                changeEntity({
                    store: 'aggregatedReviews',
                    name: 'aggregatedReviews',
                    key: aggregatedReview.aggregated_review_id,
                    diff: {
                        aggregated_reviews_snippets:
                            aggregatedReview.aggregated_reviews_snippets.filter(
                                (id1) => id !== id1
                            ),
                    },
                })
            )
            return Promise.resolve()
        }

        return call(`office/aggregated-reviews/delete-snippet/${id}`, { method: 'post' }).then(
            (response) => {
                dispatch({ type: 'INVALIDATE_AGGREGATED_REVIEWS' })
            }
        )
    }
}

export function addSnippet(id) {
    return function (dispatch, getState) {
        const { aggregatedReviews, aggregatedReviewsSnippets } =
            getState().aggregatedReviews.entities
        const entity = aggregatedReviews[id]
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                store: 'aggregatedReviews',
                name: 'aggregatedReviewsSnippets',
                key: newId,
                diff: {
                    snippet: 'test',
                    aggregated_review_id: id,
                    review_date: '2001-01-01',
                    first_name: 'test',
                    _open: true,
                },
            })
        )
        dispatch(
            changeEntity({
                store: 'aggregatedReviews',
                name: 'aggregatedReviews',
                key: id,
                diff: {
                    aggregated_reviews_snippets: entity.aggregated_reviews_snippets.concat(newId),
                },
            })
        )
    }
}

export function addAggregatedReview(options) {
    return function (dispatch, getState) {
        const { providerId, filterId, ...rest } = options
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                store: 'aggregatedReviews',
                name: 'aggregatedReviews',
                filterId,
                key: newId,
                diff: {
                    service_provider_id: providerId,
                    aggregated_reviews_source: {},
                    aggregated_reviews_snippets: [],
                    _open: true,
                    nr_of_5: 0,
                    nr_of_4: 0,
                    nr_of_3: 0,
                    nr_of_2: 0,
                    nr_of_1: 0,
                    ...rest,
                },
            })
        )
    }
}
