import React from 'react'
import { Section } from '@advanza/advanza_generic'
import ProvidersBanRestoreButton from './ProvidersBanRestoreButton'

const ProvidersBanEntity = ({ providerId }) => {
    if (!providerId) {
        return null
    }

    return (
        <Section x y>
            <ProvidersBanRestoreButton providerId={providerId} />
        </Section>
    )
}

export default ProvidersBanEntity
