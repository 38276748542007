import { Divider, MaxWidth } from '@advanza/ui'
import CreateSEOCityPage from 'components/seo/CreateSEOCityPage'
import GoogleIndexingApi from 'components/seo/GoogleIndexingApi'
import Page from 'components/ui/Page'
import React from 'react'
import { Link } from 'react-router-dom'

const SeoPage = ({}) => {
    return (
        <Page title="SEO">
            <Divider xl />
            <MaxWidth>
                <Link to="/settings/seo/services-places-contents/">services-places-contents</Link>
                <Divider />
                <CreateSEOCityPage />
                <Divider xxl />
                <GoogleIndexingApi />
            </MaxWidth>
        </Page>
    )
}

export default SeoPage
