import { ScrollModal } from '@advanza/modal'
import { Button } from '@advanza/ui'
import { useState } from 'react'

interface Props {
    onCancel: () => void
}

const FreeTrialCancelButton = ({ onCancel }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <>
            <Button onClick={() => setIsOpen(true)} name="text">
                Cancel the free trial
            </Button>
            <ScrollModal
                isOpen={isOpen}
                onCloseModal={() => setIsOpen(false)}
                heading={<div>Cancel the free trial</div>}
                buttons={[
                    {
                        text: 'No, go back',
                        name: 'link',
                        func: () => setIsOpen(false),
                    },
                    {
                        text: 'Yes, cancel the free trial',
                        func: onCancel,
                    },
                ]}>
                This will remove the free trial leads and end the free trial. Are you sure?
            </ScrollModal>
        </>
    )
}

export default FreeTrialCancelButton
