import { Icon } from '@advanza/ui'

export const HAPPINESS_UNHAPPY = 1
export const HAPPINESS_NEUTRAL = 2
export const HAPPINESS_HAPPY = 3

type Happiness = typeof HAPPINESS_UNHAPPY | typeof HAPPINESS_NEUTRAL | typeof HAPPINESS_HAPPY

const happinessIconMap = {
    [HAPPINESS_UNHAPPY]: 'sentiment_dissatisfied',
    [HAPPINESS_NEUTRAL]: 'sentiment_neutral',
    [HAPPINESS_HAPPY]: 'sentiment_satisfied',
}

export const happinessColorMap = {
    [HAPPINESS_UNHAPPY]: '#ff0000',
    [HAPPINESS_NEUTRAL]: '#ffaa77',
    [HAPPINESS_HAPPY]: '#0ea66b',
}

const happinessIconMapAlt = {
    [HAPPINESS_UNHAPPY]: '🙀',
    [HAPPINESS_NEUTRAL]: '🐱',
    [HAPPINESS_HAPPY]: '😻',
}

interface Props {
    happiness: Happiness | null | undefined
}

const HappinessIcon = ({ happiness }: Props) => {
    const alt = window._user?.email === 'rick@trustoo.nl'

    return (
        <Icon
            name={
                happiness
                    ? (alt ? happinessIconMapAlt : happinessIconMap)[happiness]
                    : 'question_mark'
            }
            style={{
                color: happiness ? happinessColorMap[happiness] : '#10185a',
                ...(happiness && alt
                    ? { overflow: 'visible', maxWidth: 'none', fontFamily: 'sans-serif' }
                    : {}),
            }}
        />
    )
}

export default HappinessIcon
