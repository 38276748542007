import React, { useState } from 'react'
import { call } from '@advanza/api'
import AutoRow from 'components/misc/AutoRow'
import { Button, Divider, Icon, LoadingDots } from '@advanza/ui'
import Modal from '@advanza/modal'
import { FileDropZone } from '@advanza/input'

function useSetImportFile(platformId) {
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState('')
    const [error, setError] = useState(false)

    const callSetImportFile = (files) => _callSetImportFile(files, false)
    const testSetImportFile = (files) => _callSetImportFile(files, true)

    const _callSetImportFile = (files, test) => {
        setIsLoading(true)
        call('office/providers-platforms/import-platform-urls', {
            payload: {
                test: test,
                file: files[0],
                platform_id: platformId,
            },
        })
            .then(
                (response) => {
                    setIsLoading(false)
                    setResult(JSON.stringify(response))
                    setError(false)
                },
                (response) => {
                    setIsLoading(false)
                    setResult(JSON.stringify(response))
                    setError(true)
                }
            )
            .finally(() => setIsLoading(false))
    }

    return {
        isLoading,
        result,
        error,
        callSetImportFile,
        testSetImportFile,
    }
}

const ImportPlatformUrls = ({}) => {
    const [openModal, setOpenModal] = useState(false)
    const [platformId, setPlatformId] = useState()
    const { isLoading, result, error, callSetImportFile, testSetImportFile } =
        useSetImportFile(platformId)

    return (
        <div>
            <AutoRow>
                <Button onClick={() => setOpenModal(true)}>
                    Import platform urls <Icon name="forum" />
                </Button>
            </AutoRow>
            <Modal close={() => setOpenModal(false)} open={openModal} zIndex={102}>
                <h3>Import platform urls</h3>
                <Divider xxxl />
                <AutoRow>
                    File with rows of data to match and a column `#competitor_links` with the url/s
                    to import. If multiple urls are present they should be separated by a semicolon
                    `;` character.
                </AutoRow>
                <Divider xxxl />
                <AutoRow>
                    <FileDropZone
                        multiple={false}
                        maxFiles={1}
                        onFilesAccepted={testSetImportFile}
                        onFilesRejected={() => {}}>
                        <Button disabled={isLoading}>
                            {isLoading ? <LoadingDots /> : <>test</>}
                        </Button>
                    </FileDropZone>
                    <FileDropZone
                        multiple={false}
                        maxFiles={1}
                        onFilesAccepted={callSetImportFile}
                        onFilesRejected={() => {}}>
                        <Button disabled={isLoading}>
                            {isLoading ? <LoadingDots /> : <>import</>}
                        </Button>
                    </FileDropZone>
                    {error && <div>{error}</div>}
                </AutoRow>
                <AutoRow>
                    {result && (
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            <div style={{ color: error ? 'red' : 'initial' }}>
                                {error} {JSON.stringify(JSON.parse(result), null, 4)}
                            </div>
                        </pre>
                    )}
                </AutoRow>
            </Modal>
        </div>
    )
}

export default ImportPlatformUrls
