import PropTypes from 'prop-types'
import { Icon } from '@advanza/advanza_generic'
import React, { Fragment } from 'react'
import * as ReactDOM from 'react-dom'
import { Redirect } from 'react-router-dom'
import LinearLoader from '../LinearLoader'
import style from './flexTable.module.css'

class FlexTableRow extends React.Component {
    constructor(props) {
        super(props)
        this.toggleExpanded = this.toggleExpanded.bind(this)
        this.expand = this.expand.bind(this)
        this.bodyClick = this.bodyClick.bind(this)
        this.onColumnClick = this.onColumnClick.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.state = {
            inTrans: false,
            redirect: false,
        }
    }

    transitionDuration = 0

    bodyClick(e) {
        if (!this.containerEl) {
            return
        }
        const containerNode = ReactDOM.findDOMNode(this.containerEl)
        if (
            e.target !== containerNode &&
            !containerNode.contains(e.target) &&
            this.state.expanded &&
            e.target.tagName !== 'BUTTON' &&
            !this.props.dontCloseOnClickOutside
        ) {
            this.toggleExpanded()
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.bodyClick)
        document.addEventListener('touchend', this.bodyClick)
        if (this.props.expanded) {
            this.toggleExpanded()
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.bodyClick)
        document.removeEventListener('touchend', this.bodyClick)
    }

    toggleExpanded() {
        if (this.state.inTrans) {
            return
        }
        this.expand(!this.state.expanded)
    }

    onKeyDown(e) {
        if (e.key === 'Escape') {
            this.expand(false)
        }
    }

    expand(open) {
        this.setState({ expanded: open, inTrans: true })
        setTimeout(() => this.setState({ inTrans: false }), this.transitionDuration)
    }

    onColumnClick() {
        const { url, expandOnClick } = this.props
        if (url) {
            return this.setState({ redirect: url })
        } else if (expandOnClick) {
            this.toggleExpanded()
        }
    }

    render() {
        const {
            cols,
            url,
            selector,
            isSelected,
            isLoading,
            expandable,
            expandOnClick,
            backgroundColor,
            showCols,
            style: inlineStyle = {},
            definitions,
            expanded: expandedOnStars,
            ...rest
        } = this.props
        const { expanded = expandedOnStars, inTrans } = this.state
        const { redirect } = this.state
        if (redirect) {
            return <Redirect push to={redirect} />
        }
        const className = [
            style.listItem,
            isSelected ? style.selected : '',
            url || expandOnClick ? style.clickable : '',
            expandOnClick ? style.expandOnClick : '',
            expanded ? style.expanded : '',
            rest.className || '',
        ].join(' ')
        return (
            <Fragment>
                {isLoading && (
                    <tr className={style.rowLoading}>
                        <td colSpan={12}>
                            <LinearLoader />
                        </td>
                    </tr>
                )}
                <tr className={className} style={{ backgroundColor, ...inlineStyle }}>
                    {selector && <td>{selector}</td>}
                    {cols.map((col, i) => {
                        const definitionKey = Object.keys(definitions)[i]
                        const definition = definitions[definitionKey] || null
                        const shouldNotShow =
                            !definition || (showCols && showCols.indexOf(definitionKey) === -1)
                        if (shouldNotShow) {
                            return null
                        }
                        const { isHiddenRow, preventRedirect } = definition
                        if (isHiddenRow) {
                            return <Fragment key={i}>{col}</Fragment>
                        }
                        return (
                            <td key={i} onClick={!preventRedirect ? this.onColumnClick : null}>
                                {col}
                            </td>
                        )
                    })}
                    {expandable && (
                        <td
                            className={[style.expandButton, style.clickable].join(' ')}
                            style={{ cursor: 'pointer' }}
                            onClick={this.toggleExpanded}>
                            <Icon>{expanded ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
                        </td>
                    )}
                </tr>
                {expandable && (
                    <tr className={style.expandable} onKeyDown={this.onKeyDown}>
                        <td
                            className={[
                                style.tableColExpand,
                                expanded ? style.expanded : '',
                                rest.noShadow ? style.noShadow : '',
                            ].join(' ')}
                            ref={(el) => (this.containerEl = el)}
                            colSpan={12}>
                            <div
                                style={{
                                    transition: '0s ease',
                                    height:
                                        expanded && !inTrans
                                            ? 'auto'
                                            : expanded
                                            ? this.bodyEl && this.bodyEl.clientHeight
                                            : '0px',
                                    overflow: expanded && !inTrans ? 'visible' : 'hidden',
                                }}>
                                <div ref={(el) => (this.bodyEl = el)}>
                                    {(expanded && expandable) || <div style={{ height: 50 }} />}
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
            </Fragment>
        )
    }
}

FlexTableRow.propTypes = {
    cols: PropTypes.array.isRequired, // array with columns
    definitions: PropTypes.object.isRequired, // object with column definitions
    id: PropTypes.any.isRequired,

    // optional:
    selector: PropTypes.object, // the checkbox comp
    isSelected: PropTypes.bool,

    showCols: PropTypes.array, // array of columnnames
    url: PropTypes.string, // redirect to url on click
    expandable: PropTypes.object,
}

export default FlexTableRow
