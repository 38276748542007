import { Button, LoadingDots } from '@advanza/ui'
import { call } from '@advanza/api'
import { useState } from 'react'

const ExportPWAStatsButton = () => {
    const [response, setResponse]: [any, any] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const onClick = async () => {
        setIsLoading(true)
        try {
            const data = await call('office/misc/export-pwa-stats')
            setResponse(data)
        } catch (error: any) {
            setResponse(error)
            setIsLoading(false)
        }
        setIsLoading(false)
    }
    return (
        <div className="space-y-4">
            <div className="flex gap-2">
                <Button appearance="primary" onClick={onClick}>
                    {isLoading && <LoadingDots color="white" />} Export PWA Stats
                </Button>
                <a
                    target="_blank"
                    href="https://docs.google.com/spreadsheets/d/17MPGEln2Uxb34vFUFjXl-5ewVLGZRLiwPkFsHrrGOvE/edit#gid=0">
                    <Button iconBefore="open_in_new" appearance="secondary">
                        Google docs Pwa Logs
                    </Button>
                </a>
            </div>
            {response?.data && (
                <div>
                    {response?.data && <span>Click & copy</span>}
                    <pre onClick={handlePreClick} className="max-h-40 max-w-2xl scroll-auto">
                        {response?.data}
                    </pre>
                </div>
            )}
        </div>
    )
}

export default ExportPWAStatsButton

const handlePreClick = (event: React.MouseEvent<HTMLPreElement>) => {
    const preElement = event.target as HTMLPreElement
    const range = document.createRange()
    range.selectNodeContents(preElement)
    const selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
}
