import { Row, Col } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { ManageBodyOverflow } from '@advanza/modal'
import { Icon, Divider, LinearLoader } from '@advanza/ui'
import ListKeyboardNav from 'components/ui/ListKeyboardNav'
import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import style from './bigPicker.module.css'

class BigPicker extends React.Component {
    constructor(props) {
        super(props)
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.onBodyClick = this.onBodyClick.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
        this.getOptions = this.getOptions.bind(this)
        this.renderOptionsWithGroups = this.renderOptionsWithGroups.bind(this)
        this.onShowMore = this.onShowMore.bind(this)
        this.renderOptions = this.renderOptions.bind(this)
        this.setMaxHeight = this.setMaxHeight.bind(this)
        this.state = {
            open: false,
            query: '',
            showMore: false,
        }
    }

    componentDidMount() {
        this.isMobile = window && window.screen.width < 768
        window.addEventListener('click', this.onBodyClick, true)
        if (this.props.mountOpen) {
            setTimeout(() => {
                this.open()
            })
        }

        // this.setMaxHeight() performance problem
    }

    setMaxHeight() {
        const { withGroups = true, limitToParent = false } = this.props
        const { bottom = 0, height = 0 } = (this.ref && this.ref.getBoundingClientRect()) || {}
        const clientBottom = document.documentElement.clientHeight

        if (bottom > 100) {
            if (withGroups) {
                const calculatedMaxHeight = clientBottom - bottom + height
                const maxHeight = Math.max(300, calculatedMaxHeight)
                this.setState({ maxHeight, minHeight: this.ref.clientWidth })
            } else {
                const lowerLimit = 300 - height
                const { bottom: parentBottom = 0 } =
                    (this.ref && this.ref.parentElement.getBoundingClientRect()) || {}
                const refBottom = limitToParent
                    ? Math.min(parentBottom, clientBottom)
                    : clientBottom
                const upperLimit = Math.min(470, refBottom - bottom - 24)
                const maxHeight = Math.max(lowerLimit, upperLimit)
                this.setState({ maxHeight, minHeight: this.ref.clientWidth })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onBodyClick)
    }

    onBodyClick(e) {
        const containerNode = ReactDOM.findDOMNode(this.ref)
        if (
            containerNode &&
            e.target !== containerNode &&
            !containerNode.contains(e.target) &&
            this.state.open
        ) {
            e.preventDefault()
            e.stopPropagation()
            this.close()
        }
    }

    open(e) {
        this.setMaxHeight()
        this.setState({ open: true })
        this.props.onOpen && this.props.onOpen()

        if (this.isMobile) {
            document.documentElement.scrollTop = 0
        }
    }

    close(hasSelected) {
        this.setState({ open: false })
        this.props.onClose && this.props.onClose(hasSelected)
    }

    onInputChange(e) {
        this.setState({ query: e.target.value })
        this.props.onChangeQuery && this.props.onChangeQuery(e.target.value)
    }

    onShowMore() {
        if (this.props.onShowMore) {
            return this.props.onShowMore()
        }
        this.setState({ showMore: true })
    }

    onSelect(option) {
        this.setState({ query: option.stringName || option.name })
        const { onChange, onChangeValue, name } = this.props
        onChange && onChange({ target: { name, value: option.value } })
        onChangeValue && onChangeValue(option)
        this.close(true)
    }

    getOptions() {
        const { options = [] } = this.props
        const groups = {}
        options.forEach((option) => {
            groups[option.group] = groups[option.group] || []
            groups[option.group].push(option)
        })

        return Object.keys(groups).map((groupName) => {
            return {
                groupName,
                options: groups[groupName],
            }
        })
    }

    renderOptionsWithGroups() {
        const {
            readOnlyOptions = false,
            options,
            popularOptionIds,
            maxPopularOptions,
            loading = false,
        } = this.props
        if (popularOptionIds && popularOptionIds.length && !this.state.showMore) {
            const orderedOptions = []
            const hasMore = !this.state.showMore && popularOptionIds && options.length > 5
            popularOptionIds.forEach((id) => {
                if (!maxPopularOptions || orderedOptions.length < maxPopularOptions) {
                    orderedOptions.push(options.filter(({ value }) => value === id)[0])
                }
            })
            return (
                <div className={style.optionsWithGroups}>
                    <Divider l />
                    {orderedOptions.map((option) => {
                        return (
                            <Col key={option.value} xs={12}>
                                <div
                                    onClick={!readOnlyOptions && this.onSelect.bind(this, option)}
                                    className={style.popularItem}>
                                    {option.name}
                                </div>
                            </Col>
                        )
                    })}
                    {hasMore && (
                        <button className={style.moreButton} onClick={this.onShowMore}>
                            <Icon name="add" primColor /> Toon meer diensten
                        </button>
                    )}
                </div>
            )
        }
        return (
            <div className={style.optionsWithGroups}>
                {loading && <LinearLoader absolute />}
                <Row>
                    {this.getOptions().map(({ groupName, options = [] }, i) => {
                        return (
                            <Col key={groupName} xs={12} sm={6}>
                                <Divider m />
                                <b>{groupName}</b>
                                <Divider />
                                {options.map((option, i) => {
                                    return (
                                        <div
                                            key={i}
                                            onClick={
                                                !readOnlyOptions && this.onSelect.bind(this, option)
                                            }
                                            className={style.item}>
                                            {option.name}
                                        </div>
                                    )
                                })}
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }

    renderOptions() {
        const { options, readOnlyOptions = false, loading = false } = this.props
        const { maxHeight } = this.state
        return (
            <ListKeyboardNav className={style.options} style={{ maxHeight }}>
                {loading && <LinearLoader absolute />}
                {options.map((option, i) => {
                    return (
                        <div
                            key={i}
                            tabIndex="-1"
                            className={style.item}
                            onClick={!readOnlyOptions && this.onSelect.bind(this, option)}>
                            {option.name}
                            {option.subName && (
                                <small className={style.subName}>{option.subName}</small>
                            )}
                        </div>
                    )
                })}
            </ListKeyboardNav>
        )
    }

    render() {
        const {
            width,
            maxWidth,
            hideTriangle,
            after = '',
            switchPlaceholder = false,
            options,
            autoFocus,
            minWidth,
            inlineItems = false,
            withGroups = true,
            readOnly = true,
            error = false,
            altStyle = false,
            blueBorderValid = false,
            compact = false,
            ...rest
        } = this.props

        const selected = rest.value
            ? options.filter(
                  ({ value }) => value === (Array.isArray(rest.value) ? rest.value[0] : rest.value)
              )[0]
            : {}
        const value = selected ? selected.stringName || selected.name : ''
        const className = [
            style.root,
            withGroups ? style.withGroups : '',
            options.length === 0 ? style.empty : '',
            rest.before ? style.useLeftPadding : '',
            error ? style.hasError : '',
            inlineItems ? style.inline : '',
            altStyle ? style.altStyle : '',
        ].join(' ')
        return (
            <div ref={(el) => (this.ref = el)} className={className}>
                <InputField
                    {...rest}
                    autoFocus={false}
                    value={value || rest.value || ''}
                    readOnly={readOnly}
                    onChange={this.onInputChange}
                    after={(!hideTriangle && <div className={style.selectTriangle} />) || after}
                    onClick={this.open}
                    error={error}
                    placeholder={switchPlaceholder && this.props.inputPlaceholder}
                    hidePlaceholder={switchPlaceholder && this.state.open}
                    blueBorderValid={blueBorderValid}
                    compact={compact}></InputField>
                <CSSTransition classNames={style} in={this.state.open} timeout={200} unmountOnExit>
                    <div
                        onKeyDown={(e) => e.key === 'Escape' && this.close()}
                        className={style.picker}
                        style={{
                            width: width || '100%',
                            maxWidth: maxWidth || '100%',
                            minWidth: minWidth || this.state.minWidth || 100,
                            maxHeight: withGroups ? this.state.maxHeight : 'none',
                            ...(rest.style || {}),
                        }}>
                        <Row middle="xs">
                            <Col x className={style.backCol}>
                                <div onClick={this.close}>
                                    <Icon name="arrow_back" />
                                </div>
                            </Col>
                            <Col xs>
                                <InputField
                                    {...rest}
                                    autoFocus={autoFocus}
                                    onChange={this.onInputChange}
                                    value={this.state.query || ''}
                                    readOnly={readOnly}
                                    error={null}
                                />
                            </Col>
                        </Row>
                        {withGroups ? this.renderOptionsWithGroups() : this.renderOptions()}
                        {this.isMobile && <ManageBodyOverflow />}
                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default BigPicker
