import { schema } from 'normalizr'

const statusSchema = new schema.Entity(
    'statuses',
    {},
    {
        idAttribute: 'status_id',
    }
)
export const statusesSchema = [statusSchema]

const salesLeadsStatusSchema = new schema.Entity(
    'salesLeadsStatuses',
    {
        status: statusSchema,
    },
    {
        idAttribute: 'sales_lead_status_id',
    }
)
export const salesLeadsStatusesSchema = [salesLeadsStatusSchema]
