import ExpectationSettingEntity from 'components/expectationsettings/ExpectationSettingEntity'
import TableList from 'components/TableList'
import { useListPerCountry } from 'hooks/listHooks'
import { schema } from 'normalizr'

const useExpectationSettingsList = (filterId = 'all', defaultOptions = {}) =>
    useListPerCountry(
        filterId,
        {
            url: 'office/expectation-settings/get-list',
            schema: [
                new schema.Entity(
                    'expectationSettings',
                    {},
                    { idAttribute: 'expectationsetting_id' }
                ),
            ],
            reducerKey: 'expectationSettings',
        },
        {
            ...defaultOptions,
            sorting: ['serviceName', 'ASC'],
        }
    )

const ExpectationSettingsList = ({ filterId = 'all' }) => {
    const {
        entities: { expectationSettings = {} },
        extra: { definitionsExpectationSettings = {} } = {},
        ...list
    } = useExpectationSettingsList(filterId)

    const renderRow = (expectationSettingId: number) => {
        const expectationSetting = expectationSettings[expectationSettingId]
        return {
            cols: Object.keys(definitionsExpectationSettings).map((key) => {
                if (key === 'theSlug') {
                    return (
                        expectationSettingId >= 10000 &&
                        expectationSetting.theSlug &&
                        '<' + expectationSetting.theSlug + '>'
                    )
                }

                return expectationSetting[key]
            }),
            expandable: <ExpectationSettingEntity expectationSettingId={expectationSettingId} />,
        }
    }

    return (
        <TableList
            definitions={definitionsExpectationSettings}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default ExpectationSettingsList
