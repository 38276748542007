import React, { Fragment } from 'react'
import FlexTableRow from './FlexTableRow'
import style from './flexTable.module.css'

class FlexTable extends React.Component {
    constructor(props) {
        super(props)
        this.renderHeader = this.renderHeader.bind(this)
    }

    renderHeader() {
        const { definitions, headerSelector, showCols } = this.props
        const filteredDefinitions = Object.keys(definitions)
            .filter((key) => !showCols || showCols.indexOf(key) !== -1)
            .map((key) => definitions[key])
        return (
            <tr className={style.th}>
                {headerSelector && <th>{headerSelector}</th>}
                {filteredDefinitions.map(({ name }, i) => {
                    return <th key={i}>{name}</th>
                })}
            </tr>
        )
    }

    render() {
        const { rows, definitions, hideHeader, showCols, className = '' } = this.props
        return (
            <table className={[style.flexTable, style.list, className].join(' ')}>
                <tbody>
                    {!hideHeader && this.renderHeader()}
                    {rows.map(({ id, ...rowProps }, i) => {
                        return (
                            <Fragment key={i}>
                                {rowProps.before && (
                                    <tr className={style.beforeRow}>
                                        <td colSpan="12">{rowProps.before}</td>
                                    </tr>
                                )}
                                <FlexTableRow
                                    key={i}
                                    id={id || i}
                                    {...rowProps}
                                    showCols={showCols}
                                    definitions={definitions}
                                />
                            </Fragment>
                        )
                    })}
                </tbody>
            </table>
        )
    }
}

export default FlexTable
