import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import AggregatedReviewsFilter from 'components/aggregatedReviews/AggregatedReviewsFilter'
import AggregatedReviewsList from 'components/aggregatedReviews/AggregatedReviewsList'
import Card from 'components/Card'
import EventFilter from 'components/events/EventFilter'
import EventList from 'components/events/EventList'
import Badges from 'components/providers/Badges'
import ProviderScores from 'components/providers/ProviderScores'
import PublicReviews from 'components/reviews/PublicReviews'
import ReviewFilter from 'components/reviews/ReviewFilter'
import ReviewList from 'components/reviews/ReviewList'
import { useProvider } from 'hooks/providerHooks'
import React from 'react'

const ProviderViewReviews = ({ providerId }) => {
    const { provider } = useProvider(providerId)
    const filterIdAssociations = `p_${providerId}`
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <Card
                        header={
                            <span>
                                Sent review invites{' '}
                                <span style={{ fontSize: '11px', color: '#999' }}>
                                    (since 27-08-20)
                                </span>
                            </span>
                        }
                        icon="mail">
                        <EventFilter
                            hideSelect
                            filterId={filterIdAssociations}
                            defaultOptions={{
                                eventNames: ['ReviewInviteInternal', 'ReviewInviteExternal'],
                                providerIds: [providerId],
                                pageSize: 3,
                            }}
                        />
                        <EventList compact filterId={filterIdAssociations} />
                    </Card>
                </Col>
            </Row>
            <Divider m />
            <Row>
                <Col xs={12} sm={6}>
                    <Card header="Public Reviews Url" icon="link">
                        <PublicReviews providerId={providerId} />
                    </Card>
                    <Divider m />
                    <Card header="Reviews" icon="rate_review">
                        <ReviewFilter
                            filterId={filterIdAssociations}
                            defaultOptions={{
                                providerIds: [providerId],
                                pageSize: 10,
                            }}
                            newEntityOptions={{ service_provider_id: providerId }}
                        />
                        <Divider />
                        <ReviewList compact hideHeader selectable filterId={filterIdAssociations} />
                    </Card>
                    <Divider m />
                    <Card header="Badges" icon="badge">
                        <Badges providerId={providerId} />
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card header="Aggregated Reviews" icon="folder_special">
                        <AggregatedReviewsFilter
                            filterId={filterIdAssociations}
                            newEntityOptions={{ providerId }}
                            defaultOptions={{ providerIds: [providerId] }}
                        />
                        <AggregatedReviewsList filterId={filterIdAssociations} />
                    </Card>
                    <Divider m />
                    <Card header="Scores">
                        <ProviderScores
                            providerId={providerId}
                            entityId={provider.providers_score}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ProviderViewReviews
