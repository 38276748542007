import { call } from '@advanza/api'
import { normalize } from 'normalizr'
import matchesSchema from '../schemes/matchesSchema'
import { _changeFilter, _fetchItems } from './lists'

export const MATCHES_REQUEST = 'MATCHES_REQUEST'
export const MATCHES_RECEIVE_LIST = 'MATCHES_RECEIVE_LIST'
export const MATCHES_RECEIVE_SINGLE = 'MATCHES_RECEIVE_SINGLE'
export const MATCHES_CHANGE_FILTER = 'MATCHES_CHANGE_FILTER'
export const MATCHES_INVALIDATE = 'MATCHES_INVALIDATE'
export const MATCHES_INVALIDATE_FILTER = 'MATCHES_INVALIDATE_FILTER'

export function fetchMatches(filterId) {
    return _fetchItems(filterId, {
        url: 'office/matches/get-list',
        schema: matchesSchema,
        reducerKey: 'matches',
        requestActionName: MATCHES_REQUEST,
        receiveActionName: MATCHES_RECEIVE_LIST,
    })
}

export function changeMatchFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'matches',
        actionType: MATCHES_CHANGE_FILTER,
        fetchFunc: fetchMatches,
    })
}

export function invalidateMatches(filterId) {
    return function (dispatch) {
        if (filterId) {
            dispatch(changeMatchFilter(filterId, { didInvalidate: true }))
        } else {
            dispatch({ TYPE: MATCHES_INVALIDATE })
        }
    }
}

export function fetchMatch(matchId) {
    return function (dispatch, getState) {
        const matchEntities = getState().matches.entities.matches
        if (matchEntities && matchEntities[matchId] && matchEntities[matchId].messages) {
            return Promise.resolve()
        }
        dispatch({ type: MATCHES_REQUEST })
        return call('office/matches/get-one/' + matchId).then((response) => {
            const { entities } = normalize([response.item], matchesSchema)
            dispatch({ type: MATCHES_RECEIVE_SINGLE, entities, matchId })
        })
    }
}

export function reclaimMatch(matchId, date) {
    return function (dispatch, getState) {
        const match = getState().matches.entities.matches[matchId]
        if (match.reclaimed) {
            return Promise.resolve()
        }
        return call(`office/reclaims/reclaim-manually/${matchId}/match`, {
            method: 'post',
            json: { date },
        }).then((response) => {
            dispatch({ type: 'INVALIDATE_MATCHES' })
            return Promise.resolve()
        })
    }
}
