import { differenceInDays } from 'date-fns'
import format from 'date-fns/format'
import { isYesterday } from 'date-fns'
import React, { Fragment } from 'react'

import localeNL from 'date-fns/locale/nl'

const getDate = (string = '') => new Date(string ? string.replace(' ', 'T') : null)
const FormattedTime = (props) => {
    const date = getDate(props.date)
    const daysAgo = differenceInDays(new Date(), date)
    const isRecent = daysAgo < 8
    const isToday = daysAgo === 0

    const dateString = props.format
        ? format(date, props.format)
        : isYesterday(date)
        ? format(date, 'd MMM HH:mm')
        : format(date, isToday ? 'HH:mm' : isRecent ? 'd MMM HH:mm' : 'd MMM yyy', {
              locale: localeNL,
          })

    return <Fragment>{dateString}</Fragment>
}

FormattedTime.propTypes = {}

export default FormattedTime
