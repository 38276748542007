import React from 'react'
import { Row, Section } from '@advanza/advanza_generic'
import Page from '../ui/Page'
import ProDashboardView from './ProDashboardView'

const ProDashboardPage = ({}) => {
    return (
        <Page title="Pro dashboard settings">
            <div className="container">
                <Section y x="mega">
                    <Row y nm>
                        <Section e={'plus'}>
                            <h1>Pro dashboard settings</h1>
                        </Section>
                    </Row>
                    <ProDashboardView />
                </Section>
            </div>
        </Page>
    )
}

export default ProDashboardPage
