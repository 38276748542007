import { Icon, Toggle } from '@advanza/ui'
import Restricted from 'components/Restricted'
import CountryToggle from 'components/ui/CountryToggle'
import OfficeLogo from 'components/ui/OfficeLogo'
import { hasAccess, isProfileBuilder, isSuperUser } from 'misc/user'
import { Link } from 'react-router-dom'
import style from './navigation.module.css'

const Navigation = ({ current, isCollapsed, children }) => {
    const items = [
        {
            title: 'Dashboard',
            url: '/',
        },
        {
            title: 'Statistics',
            url: '/statistics',
            icon: 'assessment',
            sub: [
                {
                    title: 'Closed loop - Emails',
                    url: '/statistics/closed-loop-emails',
                },
                {
                    title: 'Closed loop - Requests',
                    url: '/statistics/closed-loop-requests',
                },
            ],
        },
        {
            title: 'Sales',
            url: '/sales',
            icon: 'how_to_reg',
            sub: [
                { title: 'Leads', url: '/sales/leads' },
                { title: 'Site info', url: '/sales/site-info' },
                { title: 'Opportunities', url: '/sales/opportunities' },
                { title: 'Lead Estimator', url: '/sales/lead-estimator' },
            ],
        },
        {
            title: 'Customer service',
            url: '/service',
            icon: 'domain',
            profileBuilder: true,
            sub: [
                { title: 'Requests', url: '/service/requests' },
                { title: 'Providers', url: '/service/providers', profileBuilder: true },
                {
                    title: 'Demand users',
                    url: '/service/demand-users',
                    restrictedLocation: 'demand_users',
                },
                { title: 'Events/emails', url: '/service/events' },
                { title: 'Matches', url: '/service/matches' },
                { title: 'Analytics', url: '/service/analytics' },
                { title: 'Cancellations', url: '/service/cancellations' },
                { title: 'Limit reached', url: '/service/limit-reached' },
                { title: 'Active pauses', url: '/service/active-pauses' },
                {
                    title: 'Profile builder wizard',
                    url: '/service/profile-builder-wizard',
                    profileBuilder: true,
                },
            ].concat(
                hasAccess('profile_builder_wizard_config')
                    ? {
                          title: 'Profile builder wizard config',
                          url: '/service/providers-builders',
                          profileBuilder: true,
                      }
                    : []
            ),
        },
        {
            title: 'Billing',
            url: '/billing',
            icon: 'payment',
            sub: [
                { title: 'Generate invoices', url: '/billing/open' },
                { title: 'Invoices', url: '/billing/invoices' },
                {
                    title: 'Exact export',
                    url: '/billing/exact-export',
                    restrictedLocation: 'export',
                },
                { title: 'Reclaims', url: '/billing/reclaims' },
                { title: 'Ecurring', url: '/billing/ecurring' },
                { title: 'Debtors', url: '/billing/debtors' },
                {
                    title: 'Quarantined',
                    url: '/billing/quarantined',
                    restrictedLocation: 'providers',
                },
            ],
        },
        {
            title: 'Settings',
            url: '/settings',
            icon: 'settings',
            sub: [
                { title: 'Services', url: '/settings/services' },
                { title: 'Country', url: '/settings/country' },
                { title: 'Regions', url: '/settings/regions' },
                { title: 'Licences', url: '/settings/licences' },
                { title: 'Licences Updaters', url: '/settings/licences-updaters' },
                { title: 'Labels', url: '/settings/labels' },
                { title: 'Statuses', url: '/settings/statuses' },
                { title: 'Admin Users', url: '/settings/admin-users' },
                { title: 'Pro Dashboard', url: '/settings/pro-dashboard' },
                { title: 'Reviews', url: '/settings/reviews' },
                { title: 'Review sources', url: '/settings/review-sources' },
                { title: 'SEO', url: '/settings/seo' },
                { title: 'Matching debugger', url: '/settings/matching-debugger' },
                {
                    title: 'Apis',
                    url: '/settings/apis',
                    restrictedLocation: 'admin',
                },
            ],
        },
        {
            title: 'Ads',
            url: '/ads',
            icon: 'build',
            sub: [
                { title: 'Channable feed', url: '/ads/channable-feed' },
                { title: 'Expectation settings', url: '/ads/expectation-settings' },
                { title: 'Ad sheets', url: '/ads/adsheets' },
                { title: 'Geo targeting', url: '/ads/geo-targeting' },
            ],
        },
        {
            title: 'Dev',
            url: '/dev',
            icon: 'hot_tub',
            isHidden: () => !hasAccess('dev'),
            sub: [{ title: 'Queue', url: '/dev/queue' }],
        },
        {
            title: 'Misc',
            url: '/misc',
            icon: 'set_meal',
            isHidden: () => !hasAccess('export', 'r'),
            sub: [{ title: 'stuff', url: '/misc' }],
        },
    ]
    const isTest = window.location.hostname.indexOf('office') !== 0
    const isAlt = localStorage.getItem('alt_sidebar') === 'true'
    return (
        <div
            className={[
                style.root,
                isCollapsed ? style.collapsed : '',
                isTest ? style.isTest : '',
                isAlt ? style.alt : '',
            ].join(' ')}>
            <div className={style.logo}>
                <OfficeLogo />
                {children}
            </div>
            <div className={style.countryToggle}>
                <CountryToggle inline />
            </div>
            <div className={style.toggles}>
                {items.map(({ title, url, icon, profileBuilder, sub }) => {
                    if (sub && (!isProfileBuilder() || profileBuilder)) {
                        const isCurrent =
                            !isCollapsed && (current || window.location.pathname).indexOf(url) === 0
                        const content = (
                            <div className={[style.content, isCurrent ? style.open : ''].join(' ')}>
                                <Icon fontSize={18} name={icon} />
                                <h5>{title}</h5>
                            </div>
                        )
                        const subs = sub.map(
                            ({ title, url, superUserOnly, restrictedLocation, profileBuilder }) => {
                                const isCurrentSub =
                                    (current || window.location.pathname).indexOf(url) === 0
                                const subLink = (
                                    <Link
                                        className={isCurrentSub ? style.active : ''}
                                        key={url}
                                        to={url}>
                                        {title}
                                    </Link>
                                )

                                return superUserOnly &&
                                    !isSuperUser() ? null : restrictedLocation ? (
                                    <Restricted
                                        key={url}
                                        location={restrictedLocation}
                                        checkWrite={false}>
                                        {subLink}
                                    </Restricted>
                                ) : !isProfileBuilder() || profileBuilder ? (
                                    subLink
                                ) : null
                            }
                        )
                        return (
                            <Toggle key={url} openNow={isCurrent} title={content}>
                                <div className={style.subs}>{subs}</div>
                            </Toggle>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default Navigation
