import { Col, Icon, Row } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedTime } from 'react-intl'

const MailsOutTimeLine = ({ mailsOut }) => {
    const map = {
        processed: {
            icon: 'call_received',
            msg: 'processed',
        },
        delivered: {
            icon: 'check',
            color: 'green',
            msg: 'delivered',
        },
        opened: {
            icon: 'visibility',
            msg: 'opened',
            color: 'cyan',
        },
        clicked: {
            icon: 'mouse',
            msg: 'clicked',
            color: 'orange',
        },
        spamreport: {
            icon: 'report',
            msg: 'spam reported',
            color: 'magenta',
        },
        bounce: {
            icon: 'warning',
            msg: 'Bounce ' + mailsOut.reason,
            color: 'magenta',
        },
        dropped: {
            icon: 'warning',
            msg: 'Dropped',
            color: 'magenta',
        },
        deferred: {
            icon: 'hourglass_empty',
            msg: 'Deferred',
            color: 'magenta',
        },
    }
    const dates = Object.keys(mailsOut)
        .map((key) => map[key] && mailsOut[key] && { date: mailsOut[key], key })
        .filter(Boolean)

    dates.sort((a, b) => {
        a = new Date(a.date)
        b = new Date(b.date)
        return a > b ? 1 : a < b ? -1 : 0
    })

    return (
        <Row nm className="no-wrap">
            {dates.map(({ date, key }) => {
                const { icon, msg, color } = map[key]
                return (
                    <Col c="auto" key={key}>
                        <Icon color={color} small>
                            {icon}
                        </Icon>
                        <br />
                        <small>
                            {msg}
                            <br />
                            <small>
                                <FormattedTime value={date} format="short" />
                            </small>
                        </small>
                    </Col>
                )
            })}
        </Row>
    )
}

MailsOutTimeLine.propTypes = {
    mailsOut: PropTypes.object,
}

export default MailsOutTimeLine
