import { APP } from '@advanza/advanza_generic'
import React from 'react'

class OptionIcon extends React.Component {
    render() {
        const { option, url } = this.props
        const imgSrc =
            url ||
            encodeURI(APP.S3_PUBLIC() + `option_icons/${option.option_id}/${option.icon_name}`)
        const imgStyle = {
            background: `url(${imgSrc}) no-repeat center center /cover`,
            minHeight: 55,
        }
        return (
            <div className="question-options ">
                <div className="options-with-icon">
                    <label style={imgStyle}>
                        <input
                            onChange={null}
                            type="checkbox"
                            value={option.option_id}
                            name="test"
                        />
                        <div className="option-value"></div>
                    </label>
                </div>
            </div>
        )
    }
}

OptionIcon.propTypes = {}

export default OptionIcon
