import { ReactNode, RefObject, useEffect, useRef, useState } from 'react'
import Icon from './Icon'
import style from './toggle.module.scss'

const useResizeObserver = (outerRef: RefObject<any>, innerRef: RefObject<any>) => {
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (!outerRef.current) {
                return
            }

            if (parseInt(outerRef.current.style.maxHeight || '0', 10) === 0) {
                return
            }

            for (let entry of entries) {
                if (entry.contentBoxSize) {
                    // Firefox implements contentBoxSize as a single content rect, rather than an array
                    const contentBoxSize = Array.isArray(entry.contentBoxSize)
                        ? entry.contentBoxSize[0]
                        : entry.contentBoxSize
                    outerRef.current.style.maxHeight = contentBoxSize.blockSize + 18 + 'px'
                } else {
                    outerRef.current.style.maxHeight = entry.contentRect.height + 18 + 'px'
                }
            }
        })

        resizeObserver.observe(innerRef.current)

        return () => {
            resizeObserver.disconnect()
        }
    }, [])
}

function useToggle(
    outerRef: RefObject<any>,
    opened: boolean = false,
    disabled: boolean = false,
    onBeforeOpen?: () => void
) {
    const [open, setOpen] = useState(opened)
    const [isOpened, setIsOpened] = useState(open)
    const setMaxHeight = () => {
        const height = outerRef.current.scrollHeight
        outerRef.current.style.maxHeight = height + 18 + 'px'
    }
    const onClose = () => {
        setMaxHeight() // set max-height back for css-transition
        setTimeout(() => {
            outerRef.current.style.maxHeight = 0
            setIsOpened(false)
            setTimeout(() => {
                outerRef.current.visibility = 'hidden'
            }, 200)
        })
    }
    const onOpen = () => {
        outerRef.current.visibility = 'visible'
        setMaxHeight()
        setTimeout(() => {
            setIsOpened(true)
            if (outerRef.current) {
                outerRef.current.style.maxHeight = 'none' // remove max-height after css-transition
            }
        }, 200)
    }
    const onToggle = () => {
        if (disabled) {
            return
        }

        if (open) {
            setOpen(false)
            onClose()
        } else {
            onBeforeOpen && onBeforeOpen()
            setOpen(true)
            onOpen()
        }
    }

    return { onToggle, open, isOpened }
}

interface Props {
    title: ReactNode | string[]
    children: ReactNode
    opened?: boolean
    openNow?: boolean
    closeNow?: boolean
    onBeforeOpen?: () => void
    dontMountChildrenUntilOpen?: boolean
    showIcon?: boolean
    bottomToggle?: boolean
    extraClasses?: string[]
    extraTitleClasses?: string[]
    extraBodyClasses?: string[]
    disabled?: boolean
}

const Toggle = ({
    title,
    children,
    opened,
    openNow,
    closeNow,
    onBeforeOpen,
    dontMountChildrenUntilOpen,
    showIcon,
    bottomToggle,
    extraClasses = [],
    extraTitleClasses = [],
    extraBodyClasses = [],
    disabled,
}: Props) => {
    const outerRef = useRef<HTMLDivElement>(null)
    const innerRef = useRef<HTMLDivElement>(null)
    const { onToggle, open, isOpened } = useToggle(outerRef, opened, disabled, onBeforeOpen)
    useResizeObserver(outerRef, innerRef)

    useEffect(() => {
        if (openNow && !open || closeNow && open) {
            onToggle()
        }
    }, [openNow, closeNow])

    return (
        <div
            className={[style.root, bottomToggle ? style.bottomToggle : '', ...extraClasses].join(
                ' '
            )}>
            <div
                className={[style.title, disabled ? style.disabled : '', ...extraTitleClasses].join(
                    ' '
                )}
                onClick={() => onToggle()}>
                {Array.isArray(title) ? title[open ? 1 : 0] : title}
                {showIcon && (
                    <Icon classNameIcon={style.icon} name={`expand_${open ? 'less' : 'more'}`} />
                )}
            </div>
            <div className={[style.body, isOpened ? style.isOpened : '', ...extraBodyClasses].join(' ')} ref={outerRef}>
                <div ref={innerRef}>{!dontMountChildrenUntilOpen || open ? children : null}</div>
            </div>
        </div>
    )
}

export default Toggle
