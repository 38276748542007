import { fetchProvider } from 'actions/providers'
import FilteredPauseOverview from 'components/pauses/FilteredPauseOverview'
import { PauseTypeSet } from 'components/pauses/pauseEnums'
import SnoozeDateAdd from 'components/pauses/SnoozeDateAdd'
import SnoozeDateDelete from 'components/pauses/SnoozeDateDelete'
import { differenceInMonths } from 'date-fns'
import { Provider, ProvidersDate } from 'interfaces/Providers'
import { useSelector } from 'react-redux'
import { RootState } from 'types/RootStateType'
import { useAppDispatch } from 'hooks/hooks'

interface Props {
    provider: Provider
}

const SnoozeDatesList = ({ provider }: Props) => {
    const dispatch = useAppDispatch()
    const { pauseReasons } = useSelector((state: RootState) => state.pauseReasons.entities)
    const { providersDates } = useSelector((state: RootState) => state.providers.entities)
    const snoozeDates =
        provider?.providers_dates
            ?.map((id: number) => providersDates[id] as ProvidersDate)
            .filter((providersDate) => providersDate.pause_type === PauseTypeSet.SNOOZE) || []
    const hadSnoozeRecently = snoozeDates.some(
        (providersDate) =>
            !providersDate.pause_deleted &&
            differenceInMonths(new Date(), new Date(providersDate.date_from)) < 6
    )

    return (
        <FilteredPauseOverview
            pauseDates={snoozeDates}
            addButton={
                <SnoozeDateAdd
                    provider={provider}
                    onSucces={() => dispatch(fetchProvider(provider.service_provider_id, true))}
                    store="providers"
                    disabled={hadSnoozeRecently}
                />
            }
            getEditButton={(providerDateId) => (
                <div style={{ textAlign: 'right' }}>
                    <SnoozeDateDelete
                        providerDateId={providerDateId}
                        onSucces={() => dispatch(fetchProvider(provider.service_provider_id, true))}
                        store="providers"
                    />
                </div>
            )}
            pauseReasons={pauseReasons}
            gridStyle={{ gridTemplateColumns: '30px auto 55px 30px 1fr 1fr' }}
        />
    )
}

export default SnoozeDatesList
