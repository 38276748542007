import { Divider } from '@advanza/ui'
import ClosedLoopRequestsFilter from 'components/closedLoop/ClosedLoopRequestsFilter'
import ClosedLoopRequestsList from 'components/closedLoop/ClosedLoopRequestsList'
import Page from 'components/ui/Page'
import React from 'react'

const ClosedLoopRequestsPage = () => {
    return (
        <Page title="Closed loop - Requests" padding={false}>
            <ClosedLoopRequestsFilter
                filterId="all"
                defaultOptions={{
                    source: 'closed-loop',
                    pageSize: 40,
                }}
            />
            <Divider m />
            <ClosedLoopRequestsList filterId="all" />
        </Page>
    )
}

export default ClosedLoopRequestsPage
