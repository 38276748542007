
function getAppConfig(name) {
    return window._appConfig && window._appConfig[name] || ""
}

export const APP = {
    S3_PUBLIC                      : () => getAppConfig('S3_PUBLIC'),
    S3_SERVICES                    : () => getAppConfig('S3_PUBLIC') + 'services/',
    S3_PRIVATE                     : () => getAppConfig('S3_PRIVATE'),
    S3_PRO                         : () => getAppConfig('S3_PRO'),
    S3_REQUESTS                    : () => getAppConfig('S3_REQUESTS'),
    S3_USERS                       : () => getAppConfig('S3_USERS'),
    S3_URL                         : () => getAppConfig('S3_URL'),
    MAX_MATCHES_P_REQUEST          : () => getAppConfig('MAX_MATCHES_P_REQUEST'),
    DASHBOARD_URL_NAME             : () => getAppConfig('DASHBOARD_URL_NAME'),
    SUPPORT_PHONE_NUMBER           : () => getAppConfig('SUPPORT_PHONE_NUMBER'),
    SUPPORT_PHONE_NUMBER_DISPLAY   : () => getAppConfig('SUPPORT_PHONE_NUMBER_DISPLAY'),
    EMAIL_KUNDEN                   : () => getAppConfig('EMAIL_KUNDEN'),
    NR_PROVIDER_RESULTS            : () => getAppConfig('NR_PROVIDER_RESULTS'),
    NR_REVIEWS_PROFILE_PAGE        : () => getAppConfig('NR_REVIEWS_PROFILE_PAGE'),
    FILE_UPLOAD_CONFIG             : () => getAppConfig('FILE_UPLOAD_CONFIG'),
    QUESTION_TYPES                 : () => getAppConfig('QUESTION_TYPES'),
    ALLOW_WIZARD_WHEN_NO_PROS_FOUND: () => getAppConfig('ALLOW_WIZARD_WHEN_NO_PROS_FOUND'),
    DEFAULT_LP_VARIATION           : () => getAppConfig('DEFAULT_LP_VARIATION'),
    SERVICE_URL_TO_VARIATION       : () => getAppConfig('SERVICE_URL_TO_VARIATION'),
    DEFAULT_LOGIN_REDIRECT_DEMAND  : () => getAppConfig('DEFAULT_LOGIN_REDIRECT_DEMAND'),
    DEFAULT_LOGIN_REDIRECT_PRO     : () => getAppConfig('DEFAULT_LOGIN_REDIRECT_PRO'),
    SUBCAT_TO_LP_VARIATION         : () => getAppConfig('SUBCAT_TO_LP_VARIATION'),
    DEFAULT_LOCALE                 : () => getAppConfig('DEFAULT_LOCALE'),
    AFFILIATE_SERVICES             : () => getAppConfig('AFFILIATE_SERVICES'),
    AW_CONVERSION_ID               : () => getAppConfig('AW_CONVERSION_ID'),
    AW_CONVERSION_LABEL            : () => getAppConfig('AW_CONVERSION_LABEL'),
}
