import React from 'react'
import {Icon} from "../src/Icon"
import PropTypes from 'prop-types'


const WithIcon = ({icon, align, sm, lg, children, className,iconWidth, ...rest}) => {
    const iconComp = typeof icon === 'string' ? <Icon {...rest} >{icon}</Icon> : icon

    const classString = `${sm ? 'sm':''} ${lg ? 'lg':''} ${align || ''} ${className || ''}`
    return (
        <div className={"row-with-icon row no-wrap nm " + classString}>
            <div >{iconComp}</div>
            <div style={iconWidth && {width: `calc(100% - ${iconWidth}px)`}}>
                {children}
            </div>
        </div>
    )
}

WithIcon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}

export default WithIcon