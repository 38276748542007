import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { changeEntity, changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    FAQTOPICS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    FAQTOPICS_RECEIVE_LIST: receiveList,
    FAQTOPICS_CHANGE_FILTER: changeFilter,
    CHANGE_ENTITY_FAQTOPICS: changeEntity,
    INVALIDATE_FAQTOPICS: invalidateFilters,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
