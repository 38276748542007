import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Divider, MaxWidth } from '@advanza/ui'
import { fetchServices } from 'actions/services'
import Page from 'components/ui/Page'
import DemandUsersList from './DemandUsersList'

const DemandUsersPage = ({ filterId = 'all' }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchServices())
    }, [])

    return (
        <Page title="Demand Users">
            <MaxWidth>
                <Divider m />
                <h1>Demand Users</h1>
                <Divider m />
                <DemandUsersList filterId={filterId} />
            </MaxWidth>
        </Page>
    )
}

export default DemandUsersPage
