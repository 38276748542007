import { Icon } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import style from './profileLabels.module.css'

const ProfileLabel = ({ label }) => {
    const colorStyle = {
        color: label.text_color,
        backgroundColor: label.color,
    }

    return (
        <div className={style.profileLabelWrapper}>
            <div className={style.profileLabel} style={colorStyle}>
                {label.icon && <Icon name={label.icon} fontSize={12} />}
                <span>{label.description}</span>
            </div>
        </div>
    )
}

ProfileLabel.defaultProps = {
    label: {
        description: 'Gratis kennismakingsgesprek',
        color: '#c3fee3',
        text_color: '#006f44',
        icon: 'local_offer',
    },
}

ProfileLabel.propTypes = {
    label: PropTypes.object,
}

export default ProfileLabel
