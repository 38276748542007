import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'
import { useList } from 'hooks/listHooks'
import { useDispatch } from 'react-redux'
import faqsSchema from 'schemes/faqsSchema'

export function useFaqsList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/faqs/get-list',
            schema: faqsSchema,
            reducerKey: 'faqs',
        },
        defaultOptions
    )
}

export function useAddFaq(filterId, options) {
    const dispatch = useDispatch()
    return () => {
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                diff: {
                    faq_id: newId,
                    visible: true,
                    _open: true,
                    ...options,
                },
                key: newId,
                filterId,
                name: 'faqs',
                store: 'faqs',
            })
        )
    }
}

export function saveFaq(faqId) {
    return function (dispatch, getState) {
        const faq = getState().faqs.entities.faqs[faqId]
        const isNew = typeof faq.faq_id === 'string'

        return call('office/faqs/save/' + (isNew ? '' : faq.faq_id), {
            json: { ...faq, faq_id: isNew ? null : faq.faq_id },
        }).then(
            ({ id }) => {
                dispatch(
                    changeEntity({
                        store: 'faqs',
                        name: 'faqs',
                        key: faqId,
                        diff: {
                            faq_id: id,
                        },
                    })
                )
            },
            (response) => {
                if (response.error === 'fields') {
                    dispatch(
                        changeEntity({
                            store: 'faqs',
                            name: 'faqs',
                            key: faqId,
                            diff: {
                                _errors: response,
                            },
                        })
                    )
                }
                return Promise.reject(response)
            }
        )
    }
}

export function deleteFaq(faqId) {
    return function (dispatch, getState) {
        const faq = getState().faqs.entities.faqs[faqId]
        const isNew = typeof faq.faq_id === 'string'
        const removeLabelFromStore = () => {
            dispatch(changeEntity({ store: 'faqs', name: 'faqs', remove: true, key: faqId }))
        }
        if (isNew) {
            return removeLabelFromStore()
        }
        return call('office/faqs/delete/' + faq.faq_id, { method: 'post' }).then(
            removeLabelFromStore
        )
    }
}

/*
 * Change the order of the faqs.
 *
 * */
export function useChangeFaqOrder(filterId) {
    const dispatch = useDispatch()
    const changeOrder = (ids, id, modify) => {
        const currentPosition = ids.indexOf(id)
        const newPosition = Math.max(currentPosition + modify, 0)
        const newOrder = [...ids].filter((_id) => _id !== id)
        newOrder.splice(newPosition, 0, id)
        newOrder.forEach((id, i) => {
            dispatch(
                changeEntity({
                    diff: {
                        order_value: i,
                    },
                    key: id,
                    filterId,
                    name: 'faqs',
                    store: 'faqs',
                })
            )
        })
        call('office/faqs/save-order', { json: { newOrder } })
    }
    return {
        changeOrder,
    }
}
