import { APP } from '@advanza/advanza_generic'
import { FileDropZone } from '@advanza/input'
import { Icon } from '@advanza/ui'
import { useToast } from 'components/misc/Toaster'
import { useChangeEntity } from 'hooks/entityHooks'
import React, { useState, useEffect } from 'react'
import style from './testimonialImage.module.css'

const TestimonialImage = ({ id }) => {
    const { entity, onChangeEntity } = useChangeEntity(
        {
            store: 'testimonials',
            name: 'testimonials',
            entityId: id,
        },
        {}
    )
    const { onFilesRejected } = useToast()
    const [image, setImage] = useState({})
    const imageStyle = {
        background: image.url ? `url(${image.url}) no-repeat center 25% /cover` : '#16293e',
    }
    const onDropImage = (files) => {
        const Reader = new FileReader()
        Reader.onloadend = (e) =>
            setImage({
                url: e.target.result,
                file: files[0],
            })
        Reader.readAsDataURL(files[0])
    }

    useEffect(() => {
        if (image.file) {
            onChangeEntity({
                file: image.file,
                _errors: null,
            })
        }
    }, [image])

    useEffect(() => {
        setImage({
            url: entity.filename
                ? `${APP.S3_SERVICES()}img/testimonial/${entity.testimonial_id}/${entity.filename}`
                : null,
        })
    }, [entity.testimonial_id])

    return (
        <FileDropZone
            style={{ height: '100%', cursor: image.file && entity._saving ? 'wait' : 'pointer' }}
            allowedTypes={['image']}
            multiple={false}
            maxFiles={1}
            onFilesAccepted={onDropImage}
            onFilesRejected={onFilesRejected}>
            <div className={style.image} style={imageStyle}>
                {image.url ? (
                    <div className={style.edit}>
                        Edit <Icon fontSize={18} name="edit" white />
                    </div>
                ) : (
                    <div className={style.add}>
                        Profile
                        <br />
                        Picture
                    </div>
                )}
            </div>
        </FileDropZone>
    )
}

export default TestimonialImage
