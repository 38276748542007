import React, {Component} from "react"
import PropTypes from "prop-types"

const DIRECTIONS = [
    'n', 's', 'w', 'e', 'x', 'y', ['all', '']
]
const SPACING = [
    'plus', 'mega', 'min', 'none'
]
const FONTSIZE = [
    'mega', 'large', 'bigger', 'normal', 'small', 'min'
]

class Section extends Component {
    getSpacingClasses(classNames) {
        const separator = this.props.separator || '-'
        const prefix = this.props.hasOwnProperty('prefix') ? this.props.prefix : 'off'

        DIRECTIONS.forEach((direction) => {
            const classNameSegments = prefix ? [prefix] : []
            if(Array.isArray(direction)) {
                if(direction.length < 2) {
                    return
                }
                if(direction[1])
                    classNameSegments.push(direction[1])
                direction = direction[0]
            } else if(direction) {
                classNameSegments.push(direction)
            }
            if(this.props[direction] || this.props[direction + 'Margin']) {
                if(this.props[direction + 'Margin']) {
                    classNameSegments.push('margin')
                }
                if(SPACING.indexOf(this.props[direction]) > -1) {
                    classNameSegments.push(this.props[direction])
                }
                if(classNameSegments.length > 0) {
                    classNames.push(classNameSegments.join(separator))
                }
            }
        })
    }

    getFontClasses(classNames) {
        if(this.props.font) {
            const font = this.props.font
            if(font.size && (FONTSIZE.indexOf(font.size) > -1 || !isNaN(font.size))) {
                classNames.push('text-size-' + font.size);
            }
        }
    }

    getBorderClass(classNames) {
        if(this.props.border) {
            if(this.props.border === true || this.props.border === 'all') {
                classNames.push('border')
            } else {
                classNames.push('border-' + this.props.border)
            }
        }
    }

    applyClassModifiers(classNames) {
        this.getSpacingClasses(classNames)
        this.getFontClasses(classNames)
        this.getBorderClass(classNames)
    }

    render() {
        const classNames = [this.props.baseClassName || 'area']
        const props = {}
        this.applyClassModifiers(classNames)
        if(this.props.center) {
            classNames.push('center')
        }
        if(this.props.right) {
            classNames.push('right')
        }
        if(this.props.bg) {
            classNames.push('bg-' + this.props.bg)
        }
        if(this.props.color) {
            classNames.push('c-' + this.props.color)
        }
        if(this.props.root) {
            classNames.push('root')
        }
        if(this.props.className) {
            classNames.push(this.props.className.trim())
        }
        props.className = classNames.join(' ').trim()
        if(this.props.style) {
            props.style = this.props.style
        }
        if(this.props.div) {
            return <div {...props}>{this.props.children}</div>
        }
        if (this.props.onClick) {
            props.onClick = this.props.onClick
        }
        return <div {...props} style={props.style || null}>{this.props.children}</div>
    }
}

Section.propTypes = {
    n        : PropTypes.oneOf([true, 'min', 'plus', 'mega', false]),
    e        : PropTypes.oneOf([true, 'min', 'plus', 'mega', false]),
    s        : PropTypes.oneOf([true, 'min', 'plus', 'mega', false]),
    w        : PropTypes.oneOf([true, 'min', 'plus', 'mega', false]),
    className: PropTypes.string,

}
export default Section
