import { Col, Row } from '@advanza/grid'
import PropTypes from 'prop-types'
import React from 'react'
import SubscriptionsFilterContainer from '../../containers/SubscriptionsFilterContainer'
import Pagination from '../Pagination'
import ServicesSelect from '../services/ServicesSelect'

class SubscriptionsFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this.addSubscription = this.addSubscription.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    addSubscription(services) {
        const service = services[0]
        const { providerId, addSubscription } = this.props
        addSubscription(providerId, service)
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    render() {
        const { filter, count, hidePagination, countryCode = false } = this.props
        if (!filter) {
            return null
        }
        const pageNr = filter.page || 0
        return (
            <Row>
                <Col xs>
                    <ServicesSelect
                        placeholder={null}
                        multiple={false}
                        onChangeValue={this.addSubscription}
                        value={'_hidden_'}
                        hiddenOption={'+ Add filter'}
                        countryCode={countryCode}
                    />
                </Col>
                {count && !hidePagination && (
                    <Col x>
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            onPageChange={this.onPageChange}
                        />
                    </Col>
                )}
            </Row>
        )
    }
}

SubscriptionsFilter.propTypes = {
    filterId: PropTypes.string.isRequired,
}

export default SubscriptionsFilterContainer(SubscriptionsFilter)
