import { SelectSimple } from '@advanza/input'
import React, { useState, useEffect } from 'react'
import { call } from '@advanza/api'

function useCategories() {
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading && !categories.length) {
            setIsLoading(true)
            call('office/services/get-categories')
                .then(({ categories }) => {
                    setCategories(categories)
                })
                .finally(() => setIsLoading(false))
        }
    }, [])

    return { categories, isLoading }
}

const CategoryPicker = ({ value, onChange }) => {
    const { categories, isLoading } = useCategories()

    return (
        <SelectSimple
            value={parseInt(value)}
            onChange={(e) => onChange(e.target.value)}
            placeholder={isLoading ? '...loading' : 'Pick a category'}
            alwaysControlValue
            disabled={isLoading}
            options={categories.map((category) => {
                return {
                    name: category.name,
                    value: parseInt(category.category_id),
                }
            })}
        />
    )
}

export default CategoryPicker
