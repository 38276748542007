import { Button, LoadingDots, PreIcon } from '@advanza/ui'
import RegionalPricingModal from 'components/subscriptions/RegionalPricingModal'
import React, { useState } from 'react'
import ServicePriceContainer from '../../containers/ServicePriceContainer'
import FlexTable from '../FlexTable/FlexTable'

const ServicePricesActive = ({ service, serviceEntities, isFetching }) => {
    const [isOpenRegionalPricing, setIsOpenRegionalPricing] = useState(false)
    const { options, prices } = serviceEntities
    const priceIds = (service && service.prices) || []
    const currentPrices = priceIds.filter(
        (id) =>
            !prices[id].option_id &&
            prices[id].active === 1 &&
            !prices[id].is_website_click &&
            !prices[id].is_phone_click
    )
    const currentPricesOptions = priceIds.filter(
        (id) =>
            prices[id].option_id &&
            prices[id].active === 1 &&
            !prices[id].is_website_click &&
            !prices[id].is_phone_click
    )
    const currentPriceAll = currentPrices.length > 0 && prices[currentPrices[0]]

    const websiteClickPriceId = priceIds.filter(
        (id) => prices[id].active && prices[id].is_website_click
    )[0]
    const websiteClickPrice = websiteClickPriceId && prices[websiteClickPriceId]

    const phoneClickPriceId = priceIds.filter(
        (id) => prices[id].active && prices[id].is_phone_click
    )[0]
    const phoneClickPrice = phoneClickPriceId && prices[phoneClickPriceId]

    return (
        <PreIcon top icon="local_offer">
            <div style={{ maxWidth: 400, width: '100%' }}>
                <FlexTable
                    hideHeader
                    definitions={{
                        name: { name: 'option' },
                        price: { name: 'price' },
                        end_date: { name: 'end date' },
                    }}
                    rows={[
                        {
                            id: 0,
                            cols: [
                                'Base price',
                                isFetching ? (
                                    <LoadingDots />
                                ) : currentPriceAll ? (
                                    <b>
                                        €{currentPriceAll.amount.toFixed(2)}
                                        {currentPriceAll.amount_high !== null && (
                                            <> / {currentPriceAll.amount_high.toFixed(2)}</>
                                        )}
                                    </b>
                                ) : (
                                    'no price'
                                ),
                                currentPriceAll.end_date || '∞',
                            ],
                        },
                        ...currentPricesOptions.map((id) => {
                            const price = prices[id]
                            const option = options[price.option_id] || {}
                            return {
                                id,
                                cols: [
                                    option.value,
                                    <b>
                                        €{price.amount.toFixed(2)}
                                        {price.amount_high !== null && (
                                            <> / {price.amount_high.toFixed(2)}</>
                                        )}
                                    </b>,
                                    price.end_date || '∞',
                                ],
                                style:
                                    (price.end_date &&
                                        new Date() > new Date(price.end_date) && {
                                            textDecoration: 'line-through',
                                        }) ||
                                    {},
                            }
                        }),
                        {
                            id: 0,
                            cols: [
                                'Website click price',
                                isFetching ? (
                                    <LoadingDots />
                                ) : websiteClickPrice ? (
                                    <b>€{websiteClickPrice.amount.toFixed(2)}</b>
                                ) : (
                                    'no price'
                                ),
                                (websiteClickPrice && websiteClickPrice.end_date) || '∞',
                            ],
                        },
                        {
                            id: 0,
                            cols: [
                                'Phone click price',
                                isFetching ? (
                                    <LoadingDots />
                                ) : phoneClickPrice ? (
                                    <b>€{phoneClickPrice.amount.toFixed(2)}</b>
                                ) : (
                                    'no price'
                                ),
                                (phoneClickPrice && phoneClickPrice.end_date) || '∞',
                            ],
                        },
                    ]}
                />
                <Button
                    name="link"
                    style={{ marginLeft: 5 }}
                    onClick={() => setIsOpenRegionalPricing(true)}>
                    Price regions
                </Button>
                <RegionalPricingModal
                    service={service}
                    isOpen={isOpenRegionalPricing}
                    setIsOpen={setIsOpenRegionalPricing}
                />
            </div>
        </PreIcon>
    )
}

export default ServicePriceContainer(ServicePricesActive)
