import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'

export function generateDashboardLink(userId) {
    return function (dispatch, getState) {
        const { _dashboardLink } = getState().demandUsers.entities.demandUsers[userId]

        if (_dashboardLink) {
            return Promise.resolve(_dashboardLink)
        }

        return call('office/demand-users/generate-dashboard-link/' + userId).then((response) => {
            dispatch(
                changeEntity({
                    store: 'demandUsers',
                    name: 'demandUsers',
                    key: userId,
                    diff: {
                        _dashboardLink: response.link,
                    },
                })
            )
            return Promise.resolve(response.link)
        })
    }
}

export function doGdpr(userId) {
    return function () {
        return call('office/demand-users/do-gdpr/' + userId)
    }
}
