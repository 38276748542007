import { call } from '@advanza/api'
import { Row, Col } from '@advanza/grid'
import { FileDropZone, InputField } from '@advanza/input'
import { Button, Divider, LoadingDots, LinearLoader, Icon, PreIcon } from '@advanza/ui'
import { fetchProvider } from 'actions/providers'
import { useToast } from 'components/misc/Toaster'
import { useOutsideClick } from 'hooks/miscHooks'
import React, { useState, Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './brochures.module.css'

function useBrochures(providerId) {
    const dispatch = useDispatch()
    const [entity, setEntity] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const provider = useSelector((state) => state.providers.entities.providers[providerId])
    const { isFetching } = useSelector((state) => state.providers)
    const brochures = provider.brochures || []
    const hasBrochures = brochures && brochures.length > 0
    const showNoActiveWarning =
        hasBrochures &&
        brochures.findIndex((brochure) => {
            return brochure.active === true
        }) === -1

    const reload = () => {
        dispatch(fetchProvider(providerId, true))
        setEntity({})
    }
    const onSave = () => {
        setIsSaving(true)
        call('office/providers/save-brochure/' + providerId, {
            payload: {
                file: entity.file,
                name: entity.name,
            },
        })
            .then(reload)
            .finally(() => setIsSaving(false))
    }
    const onToggle = (id) => {
        const brochure = brochures.find((brochure) => {
            return brochure.brochure_id === id
        })
        patch(id, { active: !brochure.active })
    }
    const onDelete = (id) => {
        setIsSaving(id)
        return call('office/providers/delete-brochure/' + id, { method: 'post' })
            .then(reload)
            .finally(() => setIsSaving(false))
    }
    const patch = (id, patched = {}) => {
        setIsSaving(id)
        return call(`office/providers/patch-brochure/${id}`, {
            json: patched,
        })
            .then(reload)
            .finally(() => {
                setIsSaving(false)
                setShowEdit(false)
            })
    }

    return {
        brochures,
        showNoActiveWarning,
        entity,
        setEntity,
        setShowEdit,
        showEdit,
        isFetching,
        isSaving,
        onSave,
        onToggle,
        onDelete,
        patch,
    }
}

const isTest =
    window.location.host.indexOf('l.') === 0 || window.location.host.indexOf('test') === 0
const staticHostUrl = isTest
    ? 'https://s3-eu-west-1.amazonaws.com/static-test.trustoo.nl'
    : 'https://static.trustoo.nl'
const Brochures = ({ providerId }) => {
    const {
        brochures,
        showNoActiveWarning,
        entity,
        setShowEdit,
        showEdit,
        setEntity,
        isSaving,
        isFetching,
        patch,
        onDelete,
        onSave,
        onToggle,
    } = useBrochures(providerId)
    const { onFilesRejected } = useToast()

    const ref = useRef(null)
    useOutsideClick(ref, (e) => e.target.id !== '_button' && setEntity({ ...entity, _new: false }))
    return (
        <div className={style.root}>
            {isSaving || (isFetching && <LinearLoader absolute />)}
            {entity._new ? (
                <div ref={ref} className={style.new}>
                    Upload pdf brochure
                    <Divider />
                    <FileDropZone
                        className={style.drop}
                        allowedTypes={['doc', 'image']}
                        multiple={false}
                        maxFiles={1}
                        onFilesAccepted={(files) => {
                            setEntity({ ...entity, file: files[0] })
                        }}
                        onFilesRejected={onFilesRejected}>
                        {entity.file ? entity.file.name : <PreIcon icon="add">add file</PreIcon>}
                    </FileDropZone>
                    <Divider m />
                    <InputField
                        name="name"
                        value={entity.name}
                        placeholder="brochure name"
                        onChange={(e) => setEntity({ ...entity, name: e.target.value })}
                    />
                    {entity.file && (
                        <Fragment>
                            <Divider m />
                            <Button onClick={onSave} disabled={isSaving || isFetching}>
                                {isSaving ? <LoadingDots color="#fff" /> : 'Save'}
                            </Button>
                        </Fragment>
                    )}
                </div>
            ) : (
                <Button
                    id="_button"
                    name="text"
                    onClick={() => setEntity({ ...entity, _new: true })}>
                    Add brochure
                </Button>
            )}
            <Divider m />
            {showNoActiveWarning && (
                <Fragment>
                    <PreIcon name="warning" orange className={style.warning}>
                        No brochure is activated yet!
                    </PreIcon>
                    <Divider m />
                </Fragment>
            )}
            {brochures.map((brochure) => {
                const { file, brochure_id, name, active } = brochure
                return (
                    <Row
                        key={brochure_id}
                        middle="xs"
                        className={[style.item, isSaving === brochure_id ? style.saving : ''].join(
                            ' '
                        )}>
                        <Col x>
                            <a target="_blank" href={`${staticHostUrl}/${file.file_key}`}>
                                {file.filename}
                            </a>
                        </Col>
                        <Col x>
                            {showEdit === brochure_id ? (
                                <InputField
                                    value={entity.name}
                                    onChange={(e) => setEntity({ name: e.target.value })}
                                    autoFocus
                                    onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
                                    onBlur={(e) => patch(brochure_id, { name: e.target.value })}
                                />
                            ) : (
                                <button
                                    onClick={() => {
                                        setShowEdit(brochure_id)
                                        // set the current name, so it can be edited
                                        setEntity({ name })
                                    }}
                                    className={style.editNameBtn}>
                                    {name || 'brochure'}
                                </button>
                            )}
                        </Col>
                        <Col x className={style.delete}>
                            <button disabled={isSaving} onClick={() => onToggle(brochure_id)}>
                                <Icon
                                    name={active ? 'toggle_on' : 'toggle_off'}
                                    className={active ? 'green' : 'grey'}
                                />
                            </button>
                            <button disabled={isSaving} onClick={() => onDelete(brochure_id)}>
                                <Icon name="delete_forever" />
                            </button>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default Brochures
