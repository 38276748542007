import AdminUser from 'components/adminUsers/AdminUser'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { formatRelative } from 'date'
import { useLogsList } from 'hooks/logsHook'
import React from 'react'

const definitions = {
    // log_id: { name: '#' },
    message: { name: 'message' },
    log_type: { name: 'type' },
    created: { name: 'created' },
    user_id: { name: 'user_id' },
}

function renderRows(id, entities) {
    const { logs } = entities
    const entity = logs[id]

    return {
        id: id,
        cols: renderCols(entity),
    }
}

function renderCols(entity) {
    return Object.keys(definitions).map((key) => {
        switch (key) {
            case 'created':
                return formatRelative(entity[key])
            case 'user_id':
                return entity[key] ? <AdminUser userId={entity[key]} /> : '-'
            default:
                return entity[key]
        }
    })
}

const LogsList = ({ filterId = 'all', options }) => {
    const { filter = {}, currentPage, ...rest } = useLogsList(filterId, options)
    const rows = {}
    currentPage.forEach((id) => {
        rows[id] = renderRows(id, rest.entities)
    })

    if (currentPage.length === 0) {
        return null
    }
    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <TableList {...rest} filter={filter} rows={rows} definitions={definitions} hideExport />
        </LoadBoxWrapper>
    )
}

export default LogsList
