import { call } from '@advanza/api'
import { changeEntity } from 'actions/entities'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

export function useProvidersLabels() {
    const dispatch = useDispatch()

    const toggleActive = (providersLabelsId) => {
        return call(`office/providers-labels/toggle-active/${providersLabelsId}`, {
            method: 'post',
        }).then((response) => {
            const { labels } = response
            labels.map((label) => {
                dispatch(
                    changeEntity({
                        diff: {
                            active: label.active,
                        },
                        key: label.providers_labels_id,
                        name: 'profileLabels',
                        store: 'profileLabels',
                    })
                )
            })
        })
    }

    const addLabel = (providerId, officeLabelId) => {
        return call(
            `office/providers-labels/add-label/${providerId}/${officeLabelId}/${window._user.id}/0`,
            { method: 'post' }
        ).then(() => {
            dispatch({ type: 'INVALIDATE_PROVIDERS_LABELS' })
        })
    }

    const deleteLabel = (providersLabelsId) => {
        return call(`office/providers-labels/delete/${providersLabelsId}`, { method: 'post' }).then(
            () => {
                dispatch({ type: 'INVALIDATE_PROVIDERS_LABELS' })
            }
        )
    }

    return {
        toggleActive,
        addLabel,
        deleteLabel,
    }
}

export function useProfileLabelsPicker(filterId, providerId) {
    const { isFetching, entities, filters, result } = useSelector((state) => state.profileLabels)
    const { profileLabels } = entities
    const filter = filters[filterId]
    const filterResult = filter && result[filter.searchKey]

    const { providers } = useSelector((state) => state.providers.entities)
    const provider = providers && providers[providerId]

    const { labels } = useSelector((state) => state.labels.entities)

    const [options, setOptions] = useState([])

    useEffect(() => {
        if (labels && provider && filterResult && !isFetching) {
            const labelIds = Object.keys(labels).map((id) => parseInt(id))
            const alreadySelectedIds = filterResult[0].map(
                (id) => profileLabels[id].office_label_id
            )
            setOptions(
                labelIds
                    .filter((id) => !alreadySelectedIds.includes(id))
                    .map((id) => labels[id])
                    .filter((label) => label.category === 'profile' && !label.custom)
                    .filter((label) => (provider.isAdvertiser ? label : !label.premium))
                    .map((label) => ({
                        value: label.office_label_id,
                        name: label.description,
                    }))
            )
        }
    }, [labels, provider, isFetching, filterResult])

    return {
        options,
    }
}

export function useProfileIncentive(providerId) {
    const { isFetching, entities } = useSelector((state) => state.providers)
    const { providers, profileIncentives } = entities
    const provider = providers && providers[providerId]
    const { isSignedOrFreeTrial, profile_incentive_id } = provider
    const profileIncentive =
        profile_incentive_id && profileIncentives && profileIncentives[profile_incentive_id]
    const sentence = profileIncentive && !profileIncentive.is_hidden && profileIncentive.sentence

    return {
        isFetching,
        isSignedOrFreeTrial,
        sentence,
        onPause: provider && (provider.pausePeriod || provider.manualPause),
        limitReached: provider && provider.limitReached,
    }
}
