import React from 'react'
import { Icon } from '@advanza/ui'

const RequestTypeIcon = ({ request }) => {
    const { icon, name } = {
        quote: { icon: 'request_quote', name: 'Quote' },
        contact: { icon: 'perm_contact_calendar', name: 'Contact' },
        call: { icon: 'call', name: 'Call' },
        availability: { icon: 'event_available', name: 'Availability' },
    }[request.request_type] || { icon: 'unknown', name: 'unknown' }

    return (
        <span title={name}>
            <Icon name={icon} />
        </span>
    )
}

export default RequestTypeIcon
