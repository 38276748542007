import React from 'react'
import Label from 'components/ui/Label'

const LimitReachedLabel = ({ leadSetting }) => {
    const limitsReached = [
        'day_limit_reached',
        'week_limit_reached',
        'month_limit_reached',
    ]
        .map((limitType) => {
            if (!leadSetting) {
                return null
            }
            if (leadSetting[limitType]) {
                return (
                    <Label
                        bgColor='rgb(255, 213, 179)'
                        color='#10185a'
                        text={limitType} />
                )
            }
        })
        .filter(Boolean)

    return limitsReached.map((label) => label)
}

export default LimitReachedLabel
