import { toggleActiveLabel } from 'actions/licences'
import { connect } from 'react-redux'

function mapStateToProps(state, props) {
    const { entities, result, isFetching, filters } = state.licences
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    return {
        entities,
        pages: result[searchKey] || {},
        filter,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleActiveLabel: (licencesLabelId) => dispatch(toggleActiveLabel(licencesLabelId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
