import React from 'react'
import { connect } from 'react-redux'
import { fetchEvents } from '../actions/events'

function mapStateToProps(state, props) {
    const { entities: eventEntities, result, isFetching, filters } = state.events
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    return {
        eventEntities,
        pages: result[searchKey] || {},
        filter,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchEvents: (filterId) => dispatch(fetchEvents(filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
