import { useChangeEntity } from 'hooks/entityHooks'

const getFields = (num) => ({
    email: {
        type: 'email',
        placeholder: 'Email Notifications #' + num,
        validator: (val) => !val || val.search(/[^@]+@[^\.]+\..+/) !== -1,
    },
})

const EmailAddressEdit = ({ useStore, entityId, addValidate, num }) => {
    const fields = getFields(num)
    const { renderInput, validate } = useChangeEntity(
        {
            store: useStore,
            name: 'emailAddresses',
            entityId,
        },
        fields
    )
    addValidate(validate)

    return renderInput('email')
}

export default EmailAddressEdit
