import PropTypes from 'prop-types'
import { Section } from '@advanza/advanza_generic'
import React from 'react'

class Restricted extends React.Component {
    constructor(props) {
        super(props)
        this.hasAccess = this.hasAccess.bind(this)
    }

    componentDidMount() {}

    hasAccess(mode = 'r') {
        const { location } = this.props
        const permissions = window._user.permissions
        return permissions[location] && permissions[location][mode]
    }

    render() {
        const { showMessage, children, checkWrite = true } = this.props
        if (!this.hasAccess('r')) {
            if (showMessage) {
                return (
                    <Section x y className="restricted">
                        Restricted
                    </Section>
                )
            } else {
                return null
            }
        }
        const props =
            !checkWrite || this.hasAccess('w')
                ? {}
                : {
                      onClick: (e) => e.preventDefault(),
                      className: 'restricted-write-access',
                  }
        return <div {...props}>{children}</div>
    }
}

Restricted.propTypes = {
    location: PropTypes.string.isRequired,
    showMessage: PropTypes.bool,
    checkWrite: PropTypes.bool,
}

export default Restricted
