import { Loading } from '@advanza/advanza_generic'
import React from 'react'
import Chart from 'react-google-charts'
import { ColorsBright } from '../../colors'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'
import LinearLoader from '../LinearLoader'

class ServiceRequestStatsOptionsPieGraph extends React.Component {
    render() {
        const { getStatsAndOptions, filterableOptions, requestStatsOptions, serviceEntities } =
            this.props
        const { stats, options: statsOptions } = getStatsAndOptions('requestStats')
        const { options } = serviceEntities
        const { isFetching } = statsOptions
        const values = []
        const slices = {}
        const total =
            filterableOptions.map((id) => parseInt(stats.options[id], 10) || 0) ||
            [].reduce((a, b) => a + b)
        filterableOptions.forEach((id, i) => {
            const nrPros = parseInt(stats.options[id], 10)
            const normalized = (1 / total) * nrPros
            values.push([options[id].value, nrPros])
            slices[i] = {
                offset: normalized < 0.1 ? 0.1 : 0,
                color: ColorsBright[i],
            }
        })
        return (
            <div>
                <Chart
                    height={'300px'}
                    chartType="PieChart"
                    loader={<LinearLoader inside />}
                    data={[['Option', 'nrPros'], ...values]}
                    options={{
                        slices,
                    }}
                    mapsApiKey="AIzaSyAuOvu_ssjMyygZX7GFSOs7rXW7Obt_wqk"
                />
                {isFetching && <LinearLoader inside />}
            </div>
        )
    }
}

ServiceRequestStatsOptionsPieGraph.propTypes = {}

export default ServiceStatsContainer(ServiceRequestStatsOptionsPieGraph)
