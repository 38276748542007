import { decodeURISafe } from '@advanza/func'
import { Icon, LinearLoader } from '@advanza/ui'
import { getTitle, useProfileBuilderWizard } from 'hooks/profileBuilderHooks'
import React from 'react'
import style from './scrapedImagesSelectGrid.module.css'

const ScrapedImagesSelectGrid = ({ providerId }) => {
    const { websiteWithProtocol, currentContext, getScrapedImages, selectImage, deselectImage } =
        useProfileBuilderWizard(providerId)
    const scrapedImages = getScrapedImages()

    return (
        <div className={scrapedImages.length ? style.grid : ''}>
            {!scrapedImages.length ? (
                <>
                    Niet automatisch afbeeldingen gevonden, ga naar{' '}
                    <a href={websiteWithProtocol} target="_blank" rel="noreferrer">
                        website
                    </a>
                    .
                </>
            ) : (
                scrapedImages.map((scrapedImage) => {
                    const { key, selected, imageName, url, file, imageResponseUrl, loading } =
                        scrapedImage
                    const clickable = selected || currentContext

                    return (
                        <div
                            key={key}
                            className={[style.item, clickable ? style.clickable : ''].join(' ')}
                            onClick={() =>
                                clickable &&
                                (selected ? deselectImage(scrapedImage) : selectImage(scrapedImage))
                            }
                            draggable
                            onDragStart={(e) => {
                                e.dataTransfer.setData('text/plain', key)
                                e.dataTransfer.dropEffect = 'link'
                                const img = document.getElementById('scraped_' + key)

                                if (img) {
                                    const MAX_SIZE = 200
                                    const ratio = img.naturalHeight
                                        ? img.naturalWidth / img.naturalHeight
                                        : 1
                                    const widthBiggest = img.naturalWidth >= img.naturalHeight
                                    const width = widthBiggest ? MAX_SIZE : ratio * MAX_SIZE
                                    const height = widthBiggest ? MAX_SIZE / ratio : MAX_SIZE

                                    const canvas = document.createElement('canvas')
                                    canvas.width = width
                                    canvas.height = height
                                    canvas.getContext('2d').drawImage(img, 0, 0, width, height)
                                    canvas.style.position = 'absolute'
                                    canvas.style.left = '-100%'
                                    canvas.style.top = '0'
                                    canvas.style.zIndex = '-100'
                                    document.body.append(canvas)
                                    e.dataTransfer.setDragImage(canvas, 10, 10)
                                    setTimeout(() => canvas.remove(), 50)
                                }
                            }}>
                            <div className={style.imageName}>{imageName}</div>
                            <div
                                className={[style.imageBlock, loading ? style.loading : ''].join(
                                    ' '
                                )}>
                                <img id={'scraped_' + key} width="100%" alt=" " src={url} />
                                <div
                                    className={[
                                        style.imgOverlay,
                                        selected ? style.selected : '',
                                    ].join(' ')}>
                                    <div className={style.title}>
                                        {selected && (
                                            <div className={style.titleText}>
                                                {getTitle(selected, file ? file.weight : 0)}
                                            </div>
                                        )}
                                        <Icon name={selected ? 'remove' : 'add'} />
                                    </div>
                                </div>
                                {loading && <LinearLoader />}
                            </div>
                            {imageResponseUrl && (
                                <a
                                    className={style.imageResponseUrl}
                                    href={imageResponseUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={(e) => e.stopPropagation()}>
                                    {/* Only display path after domain */}
                                    {decodeURISafe(
                                        imageResponseUrl.replace(/^.+\/\/.+?\//, '') || '/'
                                    )}
                                </a>
                            )}
                        </div>
                    )
                })
            )}
        </div>
    )
}

export default ScrapedImagesSelectGrid
