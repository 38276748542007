import React from 'react'
import { default as LoginPageGeneric } from 'components/login/LoginPage'

const LoginPage = (props) => {
    const redirectFunc = (user) => {
        if (user) {
            return '/service/requests'
        }
    }

    return <LoginPageGeneric {...props} defaultRedirect={redirectFunc} />
}

export default LoginPage
