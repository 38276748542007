import { Row, Col } from '@advanza/grid'
import { SelectCheckbox } from '@advanza/input'
import Boolean from 'components/ui/Boolean'
import React from 'react'
import AdminUserFilterContainer from '../../containers/AdminUserFilterContainer'
import Pagination from '../Pagination'

class AdminUserFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, fetchPermissions, filter } = this.props
        fetchPermissions()
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            this._changeFilter({})
        }
    }

    render() {
        const { filter, count, compact, permissions } = this.props
        if (!filter || compact) {
            return null
        }
        const pageNr = filter.page || 0
        return (
            <Row middle="xs" between="xs">
                <Col x>
                    <Row>
                        <Col x>
                            <Boolean
                                value={filter.active}
                                name="active"
                                onChange={(active) => this._changeFilter({ active })}
                            />
                        </Col>
                        <Col x>
                            <SelectCheckbox
                                value={filter.access}
                                options={permissions.map(({ description }) => {
                                    return { name: description, value: description }
                                })}
                                placeholder="permission"
                                onChangeValue={(access) => this._changeFilter({ access })}
                            />
                        </Col>
                    </Row>
                </Col>

                {count > filter.pageSize && (
                    <Col x>
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            onPageChange={this.onPageChange}
                        />
                    </Col>
                )}
            </Row>
        )
    }
}

export default AdminUserFilterContainer(AdminUserFilter)
