import { InputField } from '@advanza/input'
import { getDate } from 'date'
import { differenceInCalendarDays } from 'date-fns'
import { useProfileBuilderWizard } from 'hooks/profileBuilderHooks'
import React, { useEffect } from 'react'
import style from './pageNote.module.css'

const PageNote = ({ providerId }) => {
    const {
        provider = {},
        labels = [],
        findLabel,
        getSelection,
        changeSelection,
    } = useProfileBuilderWizard(providerId)

    useEffect(() => {
        if (provider.notes && !getSelection('note').initDone) {
            const savedNote = provider.notes.find(
                ({ user_id, created }) =>
                    user_id === window._user.id &&
                    differenceInCalendarDays(new Date(), getDate(created)) <= 5
            )

            changeSelection('note', {
                initDone: true,
                ...(savedNote ? { other: savedNote.note, otherId: savedNote.note_id } : {}),
            })

            // after full refresh, make firstFailedPageKey correct (which is only used for back button on this page)
            if (getSelection('visual_website_check').choice === undefined) {
                let visualWebsiteCheckChoice = ''

                if (
                    labels.some(
                        (label) =>
                            (label.type || '').startsWith('fail_visual_website_check') &&
                            (label._joinData || {}).user_id === (window._user || {}).id
                    )
                ) {
                    visualWebsiteCheckChoice = 'fail_visual_website_check'
                } else if (findLabel('website_url_invalid')) {
                    visualWebsiteCheckChoice = 'fail_website_url_invalid'
                }

                changeSelection('visual_website_check', { choice: visualWebsiteCheckChoice })
            }
        }
    })

    return (
        <InputField
            classNames={style.noteInputField}
            onChange={(e) => changeSelection('note', { other: e.target.value })}
            onBlur={(e) => changeSelection('note', { other: e.target.value.trim() })}
            inputPlaceholder="Voeg hier je notitie toe (optioneel)"
            value={getSelection('note').other}
            textArea
        />
    )
}

export default PageNote
