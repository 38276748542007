import { Section, WithIcon } from '@advanza/advanza_generic'
import React from 'react'
import { FormattedTime, FormattedRelative } from 'react-intl'
import SalesLeadsListContainer from '../../containers/SalesLeadsListContainer'
import TableList from '../TableList'
import SalesLeadEditLabels from './SalesLeadEditLabels'
import SalesLeadPrioSwitch from './SalesLeadPrioSwitch'

class SalesLeadList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { salesLeadEntities } = this.props
        const { salesLeads } = salesLeadEntities
        const lead = salesLeads[id]
        const { color, typeName, icon } = {
            request: {
                icon: 'assignment_ind',
                color: 'orange',
                typeName: 'Business Lead',
            },
            baf: {
                icon: 'people',
                color: 'blue',
                typeName: 'Bring a friend',
            },
            pro: {
                icon: 'business_center',
                color: 'blue',
                typeName: 'Pro Sign up',
            },
            other: {
                icon: 'rotate_right',
                color: 'magenta',
                typeName: `${lead.source} lead`,
            },
        }[
            lead.request_id
                ? 'request'
                : lead.is_baf
                ? 'baf'
                : lead.service_provider_id
                ? 'pro'
                : 'other'
        ]

        return [
            <small className="text">#{id}</small>,
            lead.name,
            lead.status || '-',
            <Section className="label">
                <WithIcon icon={icon} align="middle" color={color} small>
                    {typeName}
                </WithIcon>
            </Section>,
            <small>{lead.source}</small>,
            <SalesLeadPrioSwitch entityId={id} />,
            lead.serviceName,
            lead.place,
            <div style={{ maxWidth: 200 }}>
                <SalesLeadEditLabels entityId={id} />
            </div>,
            <small className="c-grey">
                <FormattedTime value={lead.created} format="short" />
                <br />(<FormattedRelative value={lead.created} />)
            </small>,
        ]
    }

    renderRow(id) {
        return {
            url: `/sales/leads/lead/${id}`,
            cols: this.renderColumns(id),
            id,
        }
    }

    definitions = {
        id: { name: '#id' },
        name: { name: 'name' },
        status: { name: 'status' },
        type: { name: 'type' },
        source: { name: 'source' },
        prio: { name: 'prio', preventRedirect: true },
        service: { name: 'service' },
        place: { name: 'place' },
        labels: { name: 'labels', preventRedirect: true },
        time: { name: 'time' },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

SalesLeadList.propTypes = {}

export default SalesLeadsListContainer(SalesLeadList)
