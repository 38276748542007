import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import { Button, Divider, LoadingDots } from '@advanza/ui'
import { useChangeEntity } from 'hooks/entityHooks'
import { useOptions } from 'hooks/optionHooks'
import Option from 'interfaces/Options'
import { AppDispatch, RootState } from 'types/RootStateType'

const saveExpectationSetting =
    (expectationSettingId: number) => (_dispatch: AppDispatch, getState: () => RootState) => {
        const expectationSetting =
            getState().expectationSettings.entities.expectationSettings[expectationSettingId]
        return call('office/expectation-settings/save', { json: [expectationSetting] })
    }

interface Props {
    expectationSettingId: number
}

const ExpectationSettingEntity = ({ expectationSettingId }: Props) => {
    const {
        entity: expectationSetting,
        onSaveEntity,
        onChangeEntity,
    } = useChangeEntity(
        {
            store: 'expectationSettings',
            name: 'expectationSettings',
            entityId: expectationSettingId,
            saveFunc: saveExpectationSetting,
        },
        {}
    )
    const options = (useOptions(expectationSetting.site_id, true).options || {}) as {
        [optionId: number]: Option
    }
    const selectOptions = Object.values(options).map((option) => ({
        value: option.option_id,
        name: option.value,
    }))

    const renderSelect = (field: string) => (
        // @ts-ignore
        <SelectSimple
            alwaysControlValue
            emptyOption="(None)"
            options={selectOptions}
            value={expectationSetting[field] || '-'}
            onChange={(e: any) => {
                const optionId = e.target.value === null ? 0 : parseInt(e.target.value, 10)
                onChangeEntity({
                    [field]: optionId,
                    [field.replace('filter', '').replace('_id', 'Name')]: optionId
                        ? options[optionId].value
                        : null,
                })
            }}
        />
    )

    return (
        <div>
            {expectationSettingId < 10000 ? (
                renderSelect('filteroption_id')
            ) : (
                <>
                    {expectationSetting.optionName}
                    <Divider />
                </>
            )}
            {renderSelect('filteroption2_id')}
            {renderSelect('filteroption3_id')}
            <Divider m />
            <Row end="xs">
                <Col x>
                    <Button
                        disabled={expectationSetting._saving}
                        onClick={onSaveEntity || (() => {})}>
                        {expectationSetting._saving ? <LoadingDots color="#fff" /> : 'Save'}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default ExpectationSettingEntity
