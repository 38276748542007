import { useScrollModal } from '@advanza/modal'
import { useChangeEntity } from '@advanza/redux_entity'
import { Divider } from '@advanza/ui'
import { saveService } from 'actions/services'
import { format, isWeekend } from 'date-fns'
import { useUnsavedChangesModal } from 'hooks/modalHooks'
import { ReactNode } from 'react'
import { DayPicker } from 'react-day-picker'
import rdpStyle from './serviceDatePickerModal.module.css'

interface Props {
    className?: string
    serviceId: string
    entityKey: string
    heading: string
    content?: ReactNode
    trigger: ReactNode
    formatSelectedDay?: (date: Date) => string
}

const ServiceDatePickerModal = ({
    className,
    serviceId,
    entityKey,
    heading,
    content,
    trigger,
    formatSelectedDay,
}: Props) => {
    const { openModal, closeModal, renderModal } = useScrollModal()
    const {
        entity = {},
        onChangeEntity,
        onSaveEntity,
    } = useChangeEntity({
        name: 'services',
        store: 'services',
        entityId: serviceId,
        saveFunc: saveService,
    })

    const getSelectedDate = (dateTime: string) => {
        if (!dateTime) {
            return undefined
        }
        return new Date(
            dateTime.replace(' ', 'T') + (dateTime.indexOf('+00:00') === -1 ? '+00:00' : '')
        )
    }

    const onDayClick = (date: Date) => {
        const formattedDay = !!formatSelectedDay
            ? formatSelectedDay(date)
            : format(date, `yyyy-MM-dd 00:00:00`)
        onChangeEntity({
            [entityKey]: formattedDay,
        })
    }

    const onDiscard = () => {
        const oldDate = entity._beforeSave && entity._beforeSave[entityKey]
        oldDate &&
            onChangeEntity({
                [entityKey]: oldDate,
            })
        closeModal()
    }

    const { openModal: openCancelModal, modal: cancelModal } = useUnsavedChangesModal(
        entity._isSaving,
        () => Promise.resolve(() => !!onSaveEntity && onSaveEntity()).then(() => closeModal()),
        onDiscard,
        'date'
    )
    const onCancelChanges = () => {
        if (entity._isTouched) {
            openCancelModal()
        } else {
            closeModal()
        }
    }

    const datePickerModal = renderModal({
        heading,
        body: (
            <div>
                {content}
                <Divider m />
                <DayPicker
                    disabled={(date) => date < new Date() || isWeekend(date)}
                    classNames={{ ...rdpStyle }}
                    selected={getSelectedDate(entity[entityKey])}
                    onDayClick={onDayClick}
                />
            </div>
        ),
        buttons: [
            {
                text: entity._isSaving ? 'Saving...' : 'Save',
                func: () => !!onSaveEntity && onSaveEntity(),
                disabled: !entity._isTouched,
                closeOnSuccess: true,
            },
        ],
        closeButton: {
            func: onCancelChanges,
            closeOnSuccess: false,
        },
        maxWidth: '500px',
    })

    return (
        <>
            <div className={className} onClick={openModal}>
                {trigger}
            </div>
            {datePickerModal}
            {cancelModal}
        </>
    )
}

export default ServiceDatePickerModal
