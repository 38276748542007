import { Divider, MaxWidth } from '@advanza/ui'
import Page from 'components/ui/Page'
import React from 'react'

const AnalyticsPage = ({}) => {
    return (
        <Page>
            <MaxWidth>
                <h1>Analytics</h1>
                <Divider l />
                <img src="https://static.trustoo.nl/wurst.png" alt="wurst" />
            </MaxWidth>
        </Page>
    )
}

AnalyticsPage.propTypes = {}

export default AnalyticsPage
