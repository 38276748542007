import React, { useRef, useState } from 'react'
import { useCurrentCountry } from 'hooks/miscHooks'
import { call } from '@advanza/api'
import AutoRow from 'components/misc/AutoRow'
import { useToast } from 'components/misc/Toaster'
import { Button, LoadingDots } from '@advanza/ui'
import Modal from '@advanza/modal'
import { getFlag } from 'misc/flags'

function useCreateMonthlyMailsJob() {
    const countryCode = useCurrentCountry()
    const { addToast } = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const createJob = (
        options = {
            isDryRun: true,
            sendTest: 0,
        }
    ) => {
        if (isLoading) {
            return Promise.resolve()
        }
        setIsLoading(true)
        return call('office/misc/create-monthly-mail-job', {
            json: {
                ...options,
                countryCode,
                sendTest: options.sendTest || 0,
            },
        })
            .then(
                () => {
                    addToast({ msg: 'Job created', green: true, icon: 'rowing' })
                },
                (err) => alert(JSON.stringify(err, null, 2))
            )
            .finally(() => setIsLoading(false))
    }

    return {
        createJob,
        countryCode,
        isLoading,
    }
}

const SendMonthlyMails = ({}) => {
    const { isLoading, createJob, countryCode } = useCreateMonthlyMailsJob()
    const [openModal, setOpenModal] = useState(false)
    const ref = useRef({})
    return (
        <div>
            <AutoRow>
                <Button
                    disabled={isLoading}
                    onClick={() =>
                        createJob({
                            isDryRun: true,
                        })
                    }>
                    {isLoading ? <LoadingDots /> : <> Get report data {getFlag(countryCode)}</>}
                </Button>
                <Button onClick={() => setOpenModal(true)} disabled={isLoading}>
                    Send monthly mails (premium)
                </Button>
                <Button
                    name="yellowAlt"
                    onClick={() =>
                        createJob({
                            isDryRun: true,
                            sendTest: ref.current.value || 75038,
                        })
                    }
                    disabled={isLoading}>
                    {isLoading ? <LoadingDots /> : <>Send 1 test mail</>}
                </Button>
                <small> to # </small>
                <input type="number" style={{ maxWidth: 50 }} placeholder="75038" ref={ref} />
            </AutoRow>
            <Modal open={openModal}>
                <h3>Send premium monthly mails</h3>
                <p>
                    Have you checked the mail texts in Sanity and are vou sure that vou can send the
                    reports?
                </p>
                <AutoRow>
                    <Button name="borderedPoppins" onClick={() => setOpenModal(false)}>
                        No, go back
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={() =>
                            createJob({ isDryRun: false }).then(() => setOpenModal(false))
                        }>
                        {isLoading ? <LoadingDots /> : <>Yes, send</>}
                    </Button>
                </AutoRow>
            </Modal>
        </div>
    )
}

export default SendMonthlyMails
