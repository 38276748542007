import { Col, Row } from '@advanza/grid'
import { useScrollModal } from '@advanza/modal'
import { changeEntity } from '@advanza/redux_entity'
import { Divider, Icon } from '@advanza/ui'
import React, { Fragment, useState } from 'react'
import { arrayMove, List } from 'react-movable'
import { useDispatch, useSelector } from 'react-redux'
import style from './entityOrderModal.module.css'

const EntityOrderModal = ({
    store = 'providers',
    name,
    text = 'items',
    buttonText = '',
    wider = false,
    disabled = false,
    orderedIds,
    renderItem,
    onSaveItem = undefined,
    afterSubmit = () => {},
}) => {
    const dispatch = useDispatch()
    const { openModal, renderModal } = useScrollModal()
    const [backup, setBackup] = useState([])
    const [items, setItems] = useState([])
    const { entities } = useSelector((state) => state[store])

    const onOpen = () => {
        setBackup(orderedIds)
        setItems(orderedIds)
        openModal()
    }

    const onChangeOrder = ({ oldIndex, newIndex }) => {
        const newOrder = arrayMove(items, oldIndex, newIndex)
        setItems(newOrder)
    }

    const onCancel = () => {
        setItems(backup)
    }

    const onSubmit = () => {
        items.map((id, index) => {
            const newWeight = items.length - index
            if (onSaveItem) {
                onSaveItem(id, newWeight)
            } else {
                dispatch(
                    changeEntity({
                        store: store,
                        name: name,
                        key: id,
                        diff: {
                            weight: newWeight,
                        },
                    })
                )
            }
        })
        afterSubmit()
    }

    const modal = renderModal({
        heading: 'Adjust order',
        sub: `Drag the ${text} to rearrange the order`,
        body: (
            <List
                values={items}
                onChange={onChangeOrder}
                lockVertically={true}
                renderList={({ children, props }) => (
                    <div className={[style.list, wider ? style.wider : ''].join(' ')} {...props}>
                        {children}
                        <Divider m />
                    </div>
                )}
                renderItem={({ value, isDragged, props }) => {
                    const item = entities[name][value]
                    return (
                        <div
                            className={[style.listItem, isDragged ? style.isDragged : ''].join(' ')}
                            {...props}>
                            <Row middle="xs" between="xs" className={style.listItemRow}>
                                <Col x>
                                    <div className={style.number}>
                                        {props.key >= 0 ? props.key + 1 : '-'}
                                    </div>
                                </Col>
                                <Col x>{renderItem(item)}</Col>
                                <Col x>
                                    <div className={style.key}>
                                        <Icon name="import_export" fontSize={28} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                }}
            />
        ),
        buttons: [
            {
                text: 'Cancel',
                name: 'link',
                func: onCancel,
            },
            {
                text: 'Finish',
                func: onSubmit,
            },
        ],
        closeButton: {
            func: onCancel,
        },
        maxWidth: wider ? '600px' : '400px',
    })

    return (
        <Fragment>
            {orderedIds.length > 1 && (
                <button disabled={disabled} onClick={onOpen} className={style.editBtn}>
                    {buttonText || 'Change order'} <Icon fontSize={16} name="edit" primColor />
                </button>
            )}
            {modal}
        </Fragment>
    )
}

export default EntityOrderModal
