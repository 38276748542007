import { Divider } from '@advanza/ui'
import { fetchProvider } from 'actions/providers'
import AvailabilityCalendarDateEdit from 'components/pauses/AvailabilityCalendarDateEdit'
import FilteredPauseOverview from 'components/pauses/FilteredPauseOverview'
import { PauseTypeSet } from 'components/pauses/pauseEnums'
import { Provider } from 'interfaces/Providers'
import { useSelector } from 'react-redux'
import { RootState } from 'types/RootStateType'
import { useAppDispatch } from 'hooks/hooks'

interface Props {
    provider: Provider
}

const AvailabilityCalendarDatesList = ({ provider }: Props) => {
    const dispatch = useAppDispatch()
    const { pauseReasons } = useSelector((state: RootState) => state.pauseReasons.entities)
    const { providersDates } = useSelector((state: RootState) => state.providers.entities)

    return (
        <>
            <Divider s />
            <b>Number of rolling paused days:</b> {provider.nr_blocked_days} day
            {provider.nr_blocked_days !== 1 && 's'}
            <Divider m name="border" />
            <FilteredPauseOverview
                pauseDates={
                    provider?.providers_dates
                        ?.map((id: number) => providersDates[id])
                        .filter(
                            (providersDate) => providersDate.pause_type === PauseTypeSet.WORK
                        ) || []
                }
                addButton={
                    <AvailabilityCalendarDateEdit
                        key="add_new_blocked_date"
                        addMode
                        provider={provider}
                        providerDateId={'new'}
                        onSucces={() => dispatch(fetchProvider(provider.service_provider_id, true))}
                        store="providers"
                    />
                }
                getEditButton={(id) => (
                    <AvailabilityCalendarDateEdit
                        key={`edit_blocked_date_${id}`}
                        provider={provider}
                        providerDateId={id}
                        onSucces={() => dispatch(fetchProvider(provider.service_provider_id, true))}
                        store="providers"
                    />
                )}
                pauseReasons={pauseReasons}
            />
        </>
    )
}

export default AvailabilityCalendarDatesList
