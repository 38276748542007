import { ManageBodyOverflow } from '@advanza/modal'
import { Button, Icon } from '@advanza/ui'
import { Fragment, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import style from './../selectCheckbox.module.css'

function useOutsideClick(ref, onOutsideClick) {
    const onClick = (e) => {
        const containerNode = ref && ref.current

        if (
            containerNode &&
            e.target &&
            e.target.isConnected &&
            e.target !== containerNode &&
            !containerNode.contains(e.target)
        ) {
            onOutsideClick(e)
        }
    }

    useEffect(() => {
        window.addEventListener('click', onClick)
        return () => window.removeEventListener('click', onClick)
    }, [])
}

let isMobile = false
const SelectCheckbox = ({
    options,
    large,
    wide,
    value,
    error,
    bottom,
    question,
    placeholder,
    legend = false,
    onChangeValue,
    multiple = true,
    rememberQuery = false,
    onChangeQuery,
    stickyHeaderAndSearch = false,
    customButton,
    ...rest
}) => {
    const values = Array.isArray(value) ? value : value ? [value] : []
    const ref = useRef(null)
    const inputRef = useRef(null)
    const listEl = useRef(null)
    const [maxHeight, setMaxHeight] = useState(0)
    const [minWidth, setMinWidth] = useState(200)
    const [open, setOpen] = useState(false)
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (open) {
            document.addEventListener('keydown', handleKeyPress)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }
    }, [open])

    const calcMaxHeight = () => {
        const { bottom = 0, height = 0 } =
            (ref && ref.current && ref.current.getBoundingClientRect()) || {}
        if (bottom > 1) {
            const calculatedMaxHeight = document.documentElement.clientHeight - bottom + height
            const maxHeight = Math.max(300, calculatedMaxHeight)
            setMaxHeight(maxHeight - 30)
            setMinWidth(ref.current.clientWidth)
        }
    }

    const onClose = () => {
        setOpen(false)
        rest.onCloseCheckbox && rest.onCloseCheckbox()
        document.removeEventListener('keydown', handleKeyPress)
        if (!rememberQuery) {
            setTimeout(() => setQuery(''), 100)
        }
    }

    useOutsideClick(ref, onClose)

    const activeItem = () => {
        if (!listEl.current) {
            return false
        }
        for (let i = 0; i < listEl.current.childElementCount; i++) {
            if (listEl.current.children[i] === document.activeElement) {
                return i
            }
        }
        return false
    }
    const focusItem = (index) => {
        if (!listEl.current) {
            return
        }
        const i = Math.max(0, Math.min(index, filteredOptions.length - 1))
        listEl.current.children[i] && listEl.current.children[i].focus()
    }

    const handleKeyPress = (e) => {
        const activeIndex = activeItem()
        switch (e.key) {
            case 'ArrowDown':
                focusItem(activeIndex === false ? 0 : (activeIndex || 0) + 1)
                e.preventDefault()
                break
            case 'ArrowUp':
                focusItem((activeIndex || 0) - 1)
                e.preventDefault()
                break
            case 'Enter':
                break
            case 'Escape':
                onClose()
                break
            default:
                inputRef.current.focus()
        }
    }
    const onOpen = () => {
        setOpen(true)
        calcMaxHeight()
    }
    const onChange = (value, i) => {
        const typedValue = isNaN(value) ? value : parseInt(value, 10)
        if (multiple === false) {
            onChangeValue([typedValue])
            onClose()
        } else {
            const newValues =
                values.indexOf(typedValue) !== -1
                    ? values.filter((id) => id !== typedValue)
                    : values.concat([typedValue])
            onChangeValue(newValues)
        }

        focusItem(i)
        setTimeout(calcMaxHeight)
    }

    const className = [
        style.root,
        bottom ? style.bottom : '',
        large ? style.large : '',
        wide ? style.wide : '',
        values.length === 0 ? style.empty : '',
    ].join(' ')

    const filterByQuery = ({ name }) => {
        if (!query || query.length < 1 || typeof name !== 'string') {
            return true
        }
        return name.toLowerCase().includes(query.toLowerCase())
    }

    const filteredOptions = options.filter(filterByQuery)
    const optionValues = options.map(({ value }) => value)
    const relevantValues = values.filter((val) => optionValues.indexOf(val) !== -1)
    const firstOption = options.filter(({ value }) => value === relevantValues[0])[0] || {}

    const picker = (
        <div className={style.picker} style={{ maxHeight, minWidth: minWidth + 12 }}>
            <div className={stickyHeaderAndSearch ? style.stickyHeaderAndSearch : undefined}>
                <div className={style.header}>
                    <div>{question || placeholder}</div>
                    <button onClick={onClose}>
                        <Icon name="close" />
                    </button>
                </div>
                <input
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value)
                        onChangeQuery && onChangeQuery(e.target.value)
                        if (activeItem() === false) {
                            focusItem(0)
                        }
                    }}
                    ref={inputRef}
                />
            </div>
            <div ref={listEl}>
                {filteredOptions.map(({ value, name, after, disabled }, i) => {
                    const isChecked = values.includes(value) || disabled
                    const icon =
                        multiple !== false
                            ? `check_box${isChecked ? '' : '_outline_blank'}`
                            : `radio_button_${isChecked ? 'checked' : 'unchecked'}`
                    return (
                        <div
                            onClick={() => onChange(value, i, name)}
                            tabIndex="-1"
                            onKeyDown={(e) => e.key === 'Enter' && onChange(value, i)}
                            key={value}
                            className={[
                                style.option,
                                isChecked ? style.checked : '',
                                disabled ? style.disabled : '',
                            ].join(' ')}>
                            <Icon name={icon} />
                            <div>{name}</div>
                            {after && <div>{after}</div>}
                        </div>
                    )
                })}
            </div>
            <div className={style.done} onClick={onClose}>
                <Button name="text">Done</Button>
            </div>
            {isMobile && <ManageBodyOverflow />}
        </div>
    )

    return (
        <div ref={ref} className={className}>
            {legend && <div className={style.legend}>{placeholder}</div>}
            <div onClick={onOpen}>
                {customButton ? (
                    <>{customButton}</>
                ) : (
                    <div className={[style.input, error ? style.hasError : ''].join(' ')}>
                        {values.length === 0 ? (
                            <div className={style.placeholder}>{placeholder}</div>
                        ) : (
                            <div className={style.selectedItems}>
                                <span>
                                    {firstOption.name}{' '}
                                    {relevantValues.length > 1 && (
                                        <span>(+{relevantValues.length - 1} selected)</span>
                                    )}
                                </span>
                            </div>
                        )}
                        <div className={style.selectTriangle} />
                    </div>
                )}
            </div>
            {error && <div className={style.error}>Select option</div>}
            <CSSTransition classNames={style} in={open} timeout={200} unmountOnExit>
                <Fragment>{picker}</Fragment>
            </CSSTransition>
        </div>
    )
}

export default SelectCheckbox
