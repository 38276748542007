import { Col, Row } from '@advanza/grid'
import { Button, Divider, MaxWidth } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import A from 'components/ui/A'
import Page from 'components/ui/Page'
import React from 'react'
import AddBanButton from './AddBanButton'
import ProvidersBansList from './ProvidersBansList'

const ProvidersBansPage = ({ filterId = 'all' }) => (
    <Page title="Quarantined">
        <MaxWidth>
            <Divider m />
            <AutoRow>
                <h1>Quarantined</h1>
                <AddBanButton />
                <A to="/billing/bans">
                    <Button name="yellowAlt">View bans</Button>
                </A>
            </AutoRow>
            <Divider m />
            <ProvidersBansList filterId={filterId} />
        </MaxWidth>
    </Page>
)

export default ProvidersBansPage
