import { Section } from '@advanza/advanza_generic'
import { formatDistanceStrictWrap } from 'date'
import { differenceInMilliseconds } from 'date-fns'
import React from 'react'
import { FormattedTime } from 'react-intl'
import { createSelector } from 'reselect'

const getMatch = (state, props) => {
    const { matches, messages } = state.matches.entities
    const match = matches && matches[props.matchId]
    if (!match) {
        return null
    }
    return {
        ...match,
        messages: (match.messages || []).map((id) => {
            return messages[id]
        }),
    }
}

export const matchTimeLine = createSelector([getMatch], (match) => {
    if (!match) {
        return []
    }

    const EventsMap = {
        confirmed: {
            icon: 'grade',
            is_bot: true,
            msg: (
                <span>
                    confirmed <FormattedTime format="short" value={match.confirmed} />
                </span>
            ),
        },
        is_visited: {
            label: (
                <Section className="label c-white" bg="blue">
                    user visit
                </Section>
            ),
            is_pro: false,
            msg: <FormattedTime format="short" value={match.is_visited} />,
        },
        is_read: {
            label: (
                <Section className="label c-white" bg="black">
                    pro visit
                </Section>
            ),
            is_pro: true,
            msg: <FormattedTime format="short" value={match.is_read} />,
        },
        hired: {
            icon: 'cake',
            is_pro: false,
            color: 'green',
            msg: (
                <span>
                    hired <FormattedTime format="short" value={match.hired} />
                </span>
            ),
        },
        finished: {
            icon: 'done_all',
            color: 'green',
            is_pro: false,
            msg: (
                <span>
                    finished <FormattedTime format="short" value={match.hired} />
                </span>
            ),
        },
        reclaimed: {
            icon: 'redo',
            is_pro: true,
            color: 'red',
            msg: (
                <span>
                    reclaim <FormattedTime format="short" value={match.hired} />
                </span>
            ),
        },
        rejected: {
            label: (
                <Section className="label c-white" bg="orange">
                    rejected
                </Section>
            ),
            color: 'cyan',
            is_pro: false,
            msg: <FormattedTime format="short" value={match.rejected} />,
        },
    }
    const items = Object.keys(match)
        .map((key) => EventsMap[key] && match[key] && { ...EventsMap[key], created: match[key] })
        .filter(Boolean)
        .concat(match.messages)
        .concat(match.pro_email_viewed_log || [])
        .concat(match.pro_email_clicked_log || [])

    items.sort((a, b) => {
        a = new Date(a.created)
        b = new Date(b.created)
        return a > b ? 1 : a < b ? -1 : 0
    })
    if (items.length === 0) {
        return []
    }

    // calculate normalized time differences.
    let deltaMaxMs = 0
    let deltaMinMs = false

    let previous = null
    items.forEach((item, i) => {
        const itemCreated = new Date(item.created)
        const previousCreated = new Date(previous ? previous.created : match.created)
        const deltaMs = differenceInMilliseconds(itemCreated, previousCreated)

        if (deltaMs > deltaMaxMs) {
            deltaMaxMs = deltaMs
        }

        if ((!deltaMinMs && deltaMs > 1) || deltaMs < deltaMinMs) {
            deltaMinMs = deltaMs
        }

        items[i].delta = deltaMs
        items[i].deltaReadable = formatDistanceStrictWrap(itemCreated, previousCreated, {
            addSuffix: false,
        })
        previous = item
    })
    return items.map((item) => {
        return {
            ...item,
            normalizedDelta: Math.abs((item.delta - deltaMinMs) / (deltaMaxMs - deltaMinMs)) * 100,
        }
    })
})
