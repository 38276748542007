import ManualReclaimButton from 'components/matches/ManualReclaimButton'
import FormattedTime from 'components/ui/FormattedTime'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import WebsiteClicksListContainer from 'containers/WebsiteClicksListContainer'
import { useList } from 'hooks/listHooks'
import React from 'react'
import websiteClicksSchema from 'schemes/websiteClicksSchema'
import TableList from '../TableList'

const definitions = {
    website_click_id: { name: '#' },
    created: { name: 'date' },
    chargeAmount: { name: '€' },
    reclaim: { name: 'reclaim' },
}

function renderRow(id, entities) {
    const { websiteClicks } = entities
    const websiteClick = websiteClicks[id]

    return {
        cols: Object.keys(definitions).map((key) => {
            switch (key) {
                case 'website_click_id':
                    return <small>{websiteClick[key]}</small>
                case 'created':
                    return <FormattedTime date={websiteClick[key] || ''} />
                case 'chargeAmount':
                    return websiteClick[key] ? (
                        <small>
                            <b>€{parseFloat(websiteClick[key]).toFixed(2)},-</b>
                        </small>
                    ) : (
                        '-'
                    )
                case 'reclaim':
                    return (
                        <ManualReclaimButton
                            lead={websiteClick}
                            id={websiteClick.website_click_id}
                            leadType="websiteClicks"
                        />
                    )
                default:
                    return websiteClick[key]
            }
        }),
    }
}

const WebsiteClicksList = ({ filterId = 'all' }) => {
    const {
        filter = {},
        currentPage,
        ...rest
    } = useList(
        filterId,
        {
            url: 'office/website-clicks/get-list',
            schema: websiteClicksSchema,
            reducerKey: 'websiteClicks',
        },
        {}
    )
    const rows = {}

    currentPage.forEach((id) => {
        rows[id] = renderRow(id, rest.entities)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <TableList
                {...rest}
                selectable={rest.selectable}
                filter={filter}
                rows={rows}
                definitions={definitions}
            />
        </LoadBoxWrapper>
    )
}

export default WebsiteClicksListContainer(WebsiteClicksList)
