import React, {useEffect, useState} from 'react'
import {call} from '@advanza/api'
import AutoRow from 'components/misc/AutoRow'
import {Button, Icon, LoadingDots} from '@advanza/ui'
import Modal from '@advanza/modal'
import {useRegionsList} from 'hooks/regionHooks'
import Checkbox from 'components/input/Checkbox'
import {useSelector} from 'react-redux'
import ServicesSelect from 'components/services/ServicesSelect'

function useExportProvidersByRegion() {
    const [isLoading, setIsLoading] = useState(false)

    const callExportProvidersByRegion = (options = {region_ids: [1]}) => {
        setIsLoading(true)
        return call('office/providers/export-providers-by-region', {query: {...options}})
            .finally(() => setIsLoading(false))
    }

    return {
        isLoading,
        callExportProvidersByRegion,
    }
}

const ExportProvidersByRegion = ({}) => {
    const {isLoading, callExportProvidersByRegion} = useExportProvidersByRegion()
    const [openModal, setOpenModal] = useState(false)

    const [selectRegions, setSelectRegions] = useState([])
    const [selectServices, setSelectServices] = useState([])
    const {countries} = useSelector((state) => state.countries)
    const {currentPage, entities, changeFilter} = useRegionsList('all')
    useEffect(() => {
        changeFilter({countries})
    }, [countries])
    const regions = []
    currentPage.forEach((id) => {
        regions.push(entities.regions[id])
    })

    return (
        <div>
            <AutoRow>
                <Button onClick={() => setOpenModal(true)}>
                    Create export for specific regions <Icon name="forum"/>
                </Button>
            </AutoRow>
            <Modal close={() => setOpenModal(false)} open={openModal} zIndex={102}>
                <h3>Export providers by region</h3>
                <p>
                    <ServicesSelect onChangeValue={setSelectServices} value={selectServices}/>
                </p>
                <p>
                    Select regions to export providers for
                    <div>
                        <Checkbox
                            options={regions.map(e => {
                                return {value: e.region_id, name: e.name}
                            })}
                            onChangeValue={setSelectRegions}
                            value={selectRegions}
                        />
                    </div>
                </p>
                <AutoRow>
                    <Button
                        disabled={isLoading}
                        onClick={() =>
                            callExportProvidersByRegion({region_ids: selectRegions, service_ids: selectServices}).then(() => setOpenModal(false))
                        }>
                        {isLoading ? <LoadingDots/> : <>Export</>}
                    </Button>
                </AutoRow>
            </Modal>
        </div>
    )
}

export default ExportProvidersByRegion
