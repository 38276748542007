import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'
import { addMinutes, format } from 'date-fns'
import { normalize } from 'normalizr'

import providersSchema from '../schemes/providersSchema'
import { _changeFilter, _fetchItems } from './lists'
import { preLoadSubscriptions } from './subscriptions'

export const PROVIDERS_REQUEST = 'PROVIDERS_REQUEST'
export const PROVIDERS_RECEIVE_LIST = 'PROVIDERS_RECEIVE_LIST'
export const PROVIDERS_RECEIVE_SINGLE = 'PROVIDERS_RECEIVE_SINGLE'
export const PROVIDERS_CHANGE_FILTER = 'PROVIDERS_CHANGE_FILTER'
export const PROVIDERS_INVALIDATE = 'PROVIDERS_INVALIDATE'
export const CHANGE_LEAD_SETTING = 'CHANGE_LEAD_SETTING'

export const VAT_STATE_UNKNOWN = 0
export const VAT_STATE_YES = 1
export const VAT_STATE_NO = 2

export const VAT_STATES = ['Unknown', 'Yes', 'No']

export function fetchProviders(filterId) {
    return _fetchItems(filterId, {
        url: 'office/providers/get-list',
        schema: providersSchema,
        requestActionName: PROVIDERS_REQUEST,
        receiveActionName: PROVIDERS_RECEIVE_LIST,
        reducerKey: 'providers',
    })
}

export function changeProviderFilter(filterId, filterOptions, options = {}) {
    return _changeFilter(filterId, filterOptions, {
        reducerKey: 'providers',
        fetchFunc: fetchProviders,
        actionType: PROVIDERS_CHANGE_FILTER,
        replace: options.replace,
    })
}

export function changeLeadSetting(setting) {
    return { type: CHANGE_LEAD_SETTING, setting }
}

export function saveLeadSetting(settingId) {
    return function (dispatch, getState) {
        const { leadSettings } = getState().providers.entities
        const setting = leadSettings && leadSettings[settingId]

        return call('office/providers/save-lead-settings/' + settingId, { json: setting }).then(
            (response) => {
                dispatch(fetchProvider(setting.service_provider_id, true))
                return Promise.resolve(response)
            }
        )
    }
}

export const CANCEL_MODE_NEVER = 'never'
export const CANCEL_MODE_SILENT = 'silent'
export const CANCEL_MODE_COMPLETE = 'complete'

export const fetchCancelReasons = () => async (dispatch, getState) => {
    if (getState().cancellations.extra.cancelReasons) {
        return Promise.resolve()
    }

    const { cancelReasons } = await call('office/cancellations/get-cancel-reasons')
    dispatch({ type: 'CANCELLATIONS_CHANGE_EXTRA', extra: { cancelReasons } })
}

export const unsubscribe = (providerId, mode, reason) => (dispatch) =>
    call(`office/providers/unsubscribe/${providerId}/${mode}/${reason ? reason : ''}`, {
        method: 'post',
    }).then(() => dispatch(fetchProvider(providerId, true)))

export function addPaymentData(providerId) {
    return function (dispatch, getState) {
        const key = 'new_' + Math.random().toString(36).substr(2, 5)
        const provider = getState().providers.entities.providers[providerId]
        const user = getState().providers.entities.users[provider.user]
        const valuesFromPro = {
            house_number: provider.street_number,
            city: provider.sublocality,
            postal_code: provider.postalcode,
            street: provider.street,
            first_name: user.first_name,
            last_name: user.last_name,
            email: provider.email || user.email,
            business_name: provider.business_name,
            gender: user.gender || provider.gender || 1,
            country_code: provider.country_code || 'NL',
            vat: provider.providers_vat ? provider.providers_vat.vat : '',
        }
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'paymentInfo',
                key,
                diff: {
                    payment_info_id: key,
                    ...valuesFromPro,
                    service_provider_id: providerId,
                    vat_state: provider.country_code === 'DE' ? VAT_STATE_NO : VAT_STATE_UNKNOWN,
                },
            })
        )
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'providers',
                key: providerId,
                diff: {
                    payment_info: key,
                },
            })
        )
    }
}

export function savePaymentData(paymentDataId) {
    return function (dispatch, getState) {
        const paymentData = getState().providers.entities.paymentInfo[paymentDataId]
        const isNew = typeof paymentDataId === 'string'
        return call(`office/providers/save-payment-data/${isNew ? 0 : paymentDataId}`, {
            json: { ...paymentData },
        })
    }
}

export function fetchProvider(providerId, forceFetch) {
    return function (dispatch, getState) {
        const providerEntities = getState().providers.entities.providers

        if (
            !forceFetch &&
            providerEntities &&
            providerEntities[providerId] &&
            providerEntities[providerId].qualifications
        ) {
            return Promise.resolve(providerEntities[providerId])
        }

        dispatch({ type: PROVIDERS_REQUEST })
        return call('office/providers/get-one/' + providerId).then((response) => {
            dispatch(
                preLoadSubscriptions(
                    `p_${providerId}`,
                    { providerIds: [providerId] },
                    { items: response.subscriptions }
                )
            )

            const { entities } = normalize([response.item], providersSchema)
            dispatch({ type: PROVIDERS_RECEIVE_SINGLE, entities, providerId })
            return entities.providers[providerId]
        })
    }
}

// eslint-disable-next-line no-unused-vars
export function saveProvider(providerId, _params = {}) {
    return function (dispatch, getState) {
        const { providers, qualifications } = getState().providers.entities
        const provider = { ...providers[providerId] }

        provider.qualifications =
            provider.qualifications && provider.qualifications.map((id) => qualifications[id])

        if (
            provider._beforeSave &&
            provider.claimed !== provider._beforeSave.claimed &&
            provider.claimed.includes(':')
        ) {
            // if claimed date is changed and the new claimed date string has also time
            // then correct time zone before saving
            const date = new Date(provider.claimed)
            provider.claimed = format(
                addMinutes(date, date.getTimezoneOffset()),
                'yyyy-MM-dd HH:mm:ss'
            )
        }

        return call(`office/providers/save/${providerId}`, { json: provider }).then(
            ({ payment_info_id, vat_state }) => {
                if (payment_info_id) {
                    dispatch(
                        changeEntity({
                            store: 'providers',
                            name: 'paymentInfo',
                            key: payment_info_id,
                            diff: { vat_state },
                        })
                    )
                }
                if (provider.dirtyLicences) {
                    dispatch(
                        changeEntity({
                            store: 'providers',
                            name: 'providers',
                            key: providerId,
                            diff: { dirtyLicences: false, _isTouched: false },
                        })
                    )
                    dispatch({ type: 'INVALIDATE_PROVIDERS_LABELS' })
                }
            }
        )
    }
}

export function saveScores(scoreId, providerId) {
    return function (dispatch, getState) {
        const score = getState().providers.entities.scores[scoreId]
        return call(`office/providers/saveScore/${providerId}`, { json: score })
    }
}

export function setProStatus(filterId, status) {
    return function (dispatch, getState) {
        const ids = getState().providers.filters[filterId].selection
        const promises = []
        ids.forEach((key) => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key,
                    diff: {
                        status,
                    },
                })
            )
            promises.push(dispatch(saveProvider(key)))
        })
        return promises
    }
}

export function doAction(filterId, action) {
    return function (dispatch, getState) {
        const ids = getState().providers.filters[filterId].selection
        return call('office/providers/advanced-batch-action/' + action, { payload: { ids } }).then(
            (response) => {
                dispatch({ type: 'INVALIDATE_PROVIDERS' })
                return Promise.resolve(response)
            }
        )
    }
}

export function generateValidateProfileLink(providerId) {
    return function (dispatch, getState) {
        const { _validateProfileLink } = getState().providers.entities.providers[providerId]
        if (_validateProfileLink) {
            return Promise.resolve(_validateProfileLink)
        }
        return call(`office/providers/generate-validation-link/${providerId}`).then((response) => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: providerId,
                    diff: {
                        _validateProfileLink: response.link,
                    },
                })
            )
            return Promise.resolve(response.link)
        })
    }
}

export function generateDashboardLink(providerId) {
    return function (dispatch, getState) {
        const { _dashboardLink } = getState().providers.entities.providers[providerId]
        if (_dashboardLink) {
            return Promise.resolve(_dashboardLink)
        }
        return call('office/providers/generate-dashboard-link/' + providerId).then((response) => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: providerId,
                    diff: {
                        _dashboardLink: response.link,
                    },
                })
            )
            return Promise.resolve(response.link)
        })
    }
}

export function saveFileEntity(id, options = {}) {
    return function (dispatch, getState) {
        const file = getState().providers.entities.files[id]
        const fileId = file.file_id
        const isNew = typeof fileId === 'string'
        return call(`office/files/addFile/${isNew ? 0 : fileId}`, {
            payload: { ...file, ...options },
        }).then(
            (response) => {
                if (isNew) {
                    // swap temporary string fileId for real database fileId
                    const swapFileId = (fileIds = []) =>
                        fileIds.map((idOther) => (idOther === id ? response.id : idOther))

                    dispatch(
                        changeEntity({
                            store: 'providers',
                            name: 'files',
                            dontTouch: true,
                            key: id,
                            newKey: response.id,
                            diff: {
                                file_id: response.id,
                            },
                        })
                    )

                    const projectId = options['projectId'] || 0
                    if (projectId > 0) {
                        dispatch(
                            changeEntity({
                                store: 'providers',
                                name: 'projects',
                                key: projectId,
                                dontTouch: true,
                                diff: {
                                    files: swapFileId(
                                        getState().providers.entities.projects[projectId].files
                                    ),
                                },
                            })
                        )
                    }
                }

                return Promise.resolve()
            },
            (response) => {
                dispatch(
                    changeEntity({
                        store: 'providers',
                        name: 'files',
                        dontTouch: true,
                        key: fileId,
                        diff: {
                            _errors: { ...file._errors, error: response.error },
                        },
                    })
                )
                return Promise.reject(response.errorMsg || response.error)
            }
        )
    }
}

export const saveApi = (apiId) => (_dispatch, getState) => {
    const { apis = {} } = getState().providers.entities
    const api = apis[apiId]
    return call('office/providers/save-api/' + apiId, { json: api })
}

export const addCbr = (providerId) => (dispatch, getState) => {
    const key = Math.random().toString(36).substr(2, 5)
    const provider = getState().providers.entities.providers[providerId]

    if (provider.cbr) {
        return
    }

    dispatch(
        changeEntity({
            store: 'providers',
            name: 'cbrs',
            key,
            diff: {
                cbr_id: key,
                service_provider_id: providerId,
                pass_perc: null,
                num_exams: null,
                url: null,
            },
        })
    )

    dispatch(
        changeEntity({
            store: 'providers',
            name: 'providers',
            key: providerId,
            dontTouch: true,
            diff: { cbr: key },
        })
    )
}

export const saveCbr = (cbrId) => (dispatch, getState) => {
    const cbr = getState().providers.entities.cbrs[cbrId]
    return call('office/providers/save-cbr', { json: cbr }).then(({ id }) => {
        if (typeof cbrId === 'string') {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'cbrs',
                    key: cbrId,
                    newKey: id,
                    dontTouch: true,
                    diff: {
                        cbr_id: id,
                        _saving: false,
                    },
                })
            )

            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: cbr.service_provider_id,
                    dontTouch: true,
                    diff: { cbr: id },
                })
            )
        }
    })
}

export function addProjectPhoto(projectId, options = {}) {
    return function (dispatch, getState) {
        const newId = Math.random().toString(36).substr(2, 5)
        const { projects = {} } = getState().providers.entities
        const project = projects[projectId]

        dispatch(
            changeEntity({
                store: 'providers',
                name: 'files',
                key: newId,
                diff: {
                    file_id: newId,
                    ...options,
                },
            })
        )
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'projects',
                key: projectId,
                diff: {
                    files: (project.files || []).concat([newId]),
                    _errors: {
                        ...(project._errors || {}),
                        files: false,
                    },
                },
            })
        )
    }
}

export const getProvider = (providerId) => (_dispatch, getState) =>
    getState().providers.entities.providers[providerId]
