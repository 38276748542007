import Modal from './src/Modal'
export * from './ScrollModal'
export { default as ManageBodyOverflow } from './src/ManageBodyOverflow'
export { default as Modal } from './src/Modal'
export {
    ActionModal as ActionModal,
    default as SimpleModal,
    useActionModal as useActionModal,
    useSimpleModal as useSimpleModal
} from './src/SimpleModal'

export default Modal
