import { call } from '@advanza/api'
import { Row, Col } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { Button, Divider, Icon, LinearLoader } from '@advanza/ui'
import React, { Fragment, useState, useEffect } from 'react'
import style from './servicesSbis.module.css'

function useSbis(serviceId) {
    const [sbis, setSbis] = useState(null)
    const [newSbi, setNewSbi] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isInvalidated, setIsInvalidated] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (isInvalidated || (!isLoading && !sbis)) {
            getSbis()
        }
    }, [isInvalidated])

    const getSbis = () => {
        setIsLoading(true)
        call('office/services-sbis/get-list/' + serviceId)
            .then(({ sbis }) => setSbis(sbis))
            .then(() => {
                setIsInvalidated(false)
                setIsLoading(false)
            })
    }

    const addSbi = () => {
        setNewSbi({
            service_id: serviceId,
        })
    }

    const editSbi = (e) => {
        setNewSbi({
            ...newSbi,
            sbi: e.target.value,
        })
    }

    const saveSbi = () => {
        setError(null)
        setIsLoading(true)
        return call('office/services-sbis/save', { json: { ...newSbi } })
            .then(
                () => {},
                (error) => setError(error)
            )
            .finally(() => {
                setNewSbi(null)
                setIsInvalidated(true)
            })
    }

    const deleteSbi = (sbiId) => {
        setError(null)
        setIsLoading(true)
        return call(`office/services-sbis/delete/${sbiId}`, { method: 'post' })
            .then(
                () => {},
                (error) => setError(error)
            )
            .finally(() => {
                setIsInvalidated(true)
            })
    }

    return {
        sbis,
        newSbi,
        addSbi,
        editSbi,
        deleteSbi,
        saveSbi,
        isLoading,
        error,
    }
}

const ServicesSbis = ({ serviceId }) => {
    const { sbis, newSbi, addSbi, editSbi, deleteSbi, saveSbi, isLoading, error } =
        useSbis(serviceId)

    return (
        <div className={style.root}>
            {isLoading && <LinearLoader />}
            <div className={style.header}>
                {['#', 'sbi', 'delete'].map((header, index) => (
                    <div key={index}>{header}</div>
                ))}
            </div>
            <div className={style.items}>
                {sbis &&
                    sbis.map((sbi, index) => {
                        return (
                            <div className={style.item} key={index}>
                                <div>{sbi.services_sbi_id}</div>
                                <div>{sbi.sbi}</div>
                                <div>
                                    <button
                                        onClick={() => deleteSbi(sbi.services_sbi_id)}
                                        disabled={isLoading}>
                                        <Icon
                                            name={isLoading ? 'hourglass_empty' : 'delete_forever'}
                                        />
                                    </button>
                                </div>
                            </div>
                        )
                    })}
            </div>
            <Divider m />
            <Row end="xs">
                {newSbi ? (
                    <Fragment>
                        <Col xs>
                            <InputField type="number" value={newSbi.sbi} onChange={editSbi} />
                        </Col>
                        <Col x>
                            <Button onClick={saveSbi} disabled={isLoading}>
                                Save
                            </Button>
                        </Col>
                    </Fragment>
                ) : (
                    <Col x>
                        <Button name="text" onClick={addSbi} disabled={isLoading}>
                            <Icon name="add" className="colorCta" style={{ marginTop: '-4px' }} />{' '}
                            Add Sbi
                        </Button>
                    </Col>
                )}
            </Row>
            {error && <div style={{ color: '#f00' }}>Error: {error.error}</div>}
        </div>
    )
}

export default ServicesSbis
