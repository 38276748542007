import { saveProvider } from 'actions/providers'
import React from 'react'
import { saveSalesLead } from '../../actions/salesLeads'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import LabelPicker from '../labels/LabelPicker'
import EntityComponent from '../services/EntityComponent'

class ProviderLabelPicker extends EntityComponent {
    constructor(props) {
        super(props)
        this.onChangeLabels = this.onChangeLabels.bind(this)
    }

    editFields = {
        status: {
            type: 'text',
        },
    }

    onChangeLabels(values) {
        if (this.props.entity._saving) {
            return
        }
        this.onChangeEntity(
            {
                office_labels: values.map((value) => {
                    const userId = value._joinData ? value._joinData.user_id : window._user.id
                    return {
                        ...value,
                        _joinData: {
                            user_id: userId,
                        },
                    }
                }),
                _isTouched: false,
            },
            { dontTouch: true }
        )
        this.save()
    }

    render() {
        const {
            entity,
            categories = ['serviceProviders', 'badges', 'profileBuilderWizard'],
            disabledCategories = [],
            classNames,
            wrapValues,
            hideTypes = [],
        } = this.props

        return (
            <LabelPicker
                onValueChange={this.onChangeLabels}
                categories={categories}
                disabledCategories={disabledCategories.concat(['badges', 'profileBuilderWizard'])}
                wrapValues={wrapValues}
                classNames={classNames}
                value={
                    (entity &&
                        entity.office_labels.filter(({ type }) => !hideTypes.includes(type))) ||
                    []
                }
            />
        )
    }
}

export default EntityComponentContainer(ProviderLabelPicker, {
    store: 'providers',
    name: 'providers',
    saveFunc: saveProvider,
})
