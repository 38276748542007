import { Row, Col } from '@advanza/grid'
import { fetchProvider } from 'actions/providers'
import Card from 'components/Card'
import QualityScores from 'components/providers/QualityScores'
import ServiceConfigPrices from 'components/services/ServiceConfigPrices'
import ServiceAreaListUltimate from 'components/subscriptions/ServiceAreaListUltimate'
import ServiceAreaMapUltimate from 'components/subscriptions/ServiceAreaMapUltimate'
import ServiceAreaRadius from 'components/subscriptions/ServiceAreaRadius'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './test.module.css'

const TestPage = () => {
    const id = 1

    return (
        <div>
            <ServiceConfigPrices serviceId={1} />
        </div>
    )
}

export default TestPage
