import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    PHONECLICKS_CHANGE_FILTER,
    PHONECLICKS_RECEIVE_LIST,
    PHONECLICKS_REQUEST,
} from '../actions/phoneClicks'
import { changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [PHONECLICKS_RECEIVE_LIST]: receiveList,
    [PHONECLICKS_CHANGE_FILTER]: changeFilter,
    INVALIDATE_PHONECLICKS: invalidateFilters,
    [PHONECLICKS_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
