import { Col, Row } from '@advanza/grid'
import { Divider, PreIcon } from '@advanza/ui'
import AnalyticsFilter from 'components/analytics/AnalyticsFilter'
import AnalyticsList from 'components/analytics/AnalyticsList'
import InvoicesList from 'components/billing/InvoicesList'
import Card from 'components/Card'
import MatchFilter from 'components/matches/MatchFilter'
import MatchList from 'components/matches/MatchList'
import PhoneClicksFilter from 'components/phoneClicks/PhoneClicksFilter'
import PhoneClicksList from 'components/phoneClicks/PhoneClicksList'
import MatchMailData from 'components/providers/MatchMailData'
import MonthlyReportData from 'components/providers/MonthlyReportData'
import OtherCharges from 'components/providers/OtherCharges'
import ReclaimsList from 'components/reclaims/ReclaimsList'
import ReclaimStats from 'components/reclaims/ReclaimStats'
import WebsiteClicksFilter from 'components/websiteClicks/WebsiteClicksFilter'
import WebsiteClicksList from 'components/websiteClicks/WebsiteClicksList'
import { useProvider } from 'hooks/providerHooks'
import { Helmet } from 'react-helmet-async'
import React from 'react'
import GenerateInterimInvoiceButton from 'components/billing/GenerateInterimInvoiceButton'
import UpcomingInvoiceCard from 'components/providers/UpcomingInvoiceCard'

const ProviderViewLeads = ({ providerId }) => {
    const { provider, useFranchiseBilling } = useProvider(providerId)
    const filterIdAssociations = `p_${providerId}`
    return (
        <div>
            <Helmet>
                <title>Office - {provider.business_name}</title>
            </Helmet>
            <Row>
                <Col xs>
                    <Card header="Matches" icon="compare_arrows">
                        {provider.notes && (
                            <MatchFilter
                                filterId={filterIdAssociations}
                                compact
                                hidePagination
                                defaultOptions={{ providerIds: [providerId], pageSize: 10 }}
                            />
                        )}
                        <MatchList
                            filterId={filterIdAssociations}
                            compact
                            showCols={[
                                'charge',
                                'firstChoice',
                                'nrMsgs',
                                'created',
                                'userName',
                                'stripes',
                                'reclaim',
                                'partialMatch',
                            ]}
                            hideExport={false}
                        />
                    </Card>
                    <Divider m />
                    <Card header="Reclaims">
                        <ReclaimsList
                            filterId="all"
                            defaultOptions={{ providerId: providerId, pageSize: 10 }}
                            showCols={[
                                'reclaim_id',
                                'status',
                                'created',
                                'phoneNumber',
                                'leadName',
                                'action',
                                'notes',
                            ]}
                            showFilters={['leadType', 'status', 'search']}
                            compact
                        />
                        <ReclaimStats
                            filterId={'all'}
                            defaultOptions={{ providerId: providerId }}
                        />
                    </Card>
                    <Divider m />
                    <UpcomingInvoiceCard provider={provider} />
                </Col>
                <Col xs>
                    <Card icon="phone" header="Phone clicks">
                        <PhoneClicksFilter
                            filterId={filterIdAssociations}
                            defaultOptions={{
                                providerIds: [providerId],
                                pageSize: 10,
                            }}
                        />
                        <PhoneClicksList filterId={filterIdAssociations} />
                    </Card>
                    <Divider m />
                    <Card icon="public" header="Website clicks">
                        <WebsiteClicksFilter
                            filterId={filterIdAssociations}
                            defaultOptions={{
                                providerIds: [providerId],
                                pageSize: 10,
                            }}
                        />
                        <WebsiteClicksList filterId={filterIdAssociations} />
                    </Card>
                    <Divider m />
                    <Card header="Analytics" useToggle>
                        <AnalyticsFilter
                            filterId={filterIdAssociations}
                            defaultOptions={{ providerId: providerId }}
                        />
                        <AnalyticsList
                            filterId={filterIdAssociations}
                            showCols={['yearWeek', 'metric', 'count', 'value']}
                        />
                    </Card>
                    <Divider m />
                    <Card header="Match mail data">
                        <MatchMailData providerId={providerId} />
                    </Card>
                    <Divider m />
                    <Card header="Monthly report">
                        <MonthlyReportData providerId={providerId} />
                    </Card>
                </Col>
            </Row>
            <Divider m />
            <Card
                header={
                    <>
                        Invoices{' '}
                        {useFranchiseBilling && (
                            <span
                                style={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    paddingLeft: 10,
                                }}>
                                <PreIcon green icon="groups">
                                    <b style={{ color: 'green' }}>Invoiced via franchise</b>
                                </PreIcon>
                            </span>
                        )}
                    </>
                }>
                <InvoicesList
                    defaultOptions={{
                        providerId,
                        aboutMonth: undefined,
                        order: 'Invoices.invoice_id DESC',
                    }}
                    filterId={filterIdAssociations}
                    showIncassoLink
                />
            </Card>
            <Divider m />
            <Card>
                <GenerateInterimInvoiceButton providerId={providerId} />
            </Card>
            <Divider m />
            <Card icon="euro" header="Other charges">
                <Divider m />
                <OtherCharges providerId={providerId} />
            </Card>
            <Divider xl />
        </div>
    )
}

export default ProviderViewLeads
