import { Divider } from '@advanza/ui'
import ChannableFeedList from 'components/channableFeed/ChannableFeedList'
import Page from 'components/ui/Page'

const ChannableFeedPage = ({ filterId = 'all' }) => (
    // @ts-ignore
    <Page title="Channable feed">
        <Divider m />
        <h1>Channable feed</h1>
        <Divider m />
        <ChannableFeedList filterId={filterId} />
    </Page>
)

export default ChannableFeedPage
