import { Icon } from '@advanza/ui'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import style from './../hoverText.module.css'

const ReactDOM = require('react-dom')

class HoverText extends React.Component {
    constructor(props) {
        super(props)
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onClick = this.onClick.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
        this.onBodyTap = this.onBodyTap.bind(this)
        this.onResize = this.onResize.bind(this)
        this.onScroll = this.onScroll.bind(this)
        this.setBubblePosition = this.setBubblePosition.bind(this)
        this.state = {
            showBubble: false,
            bubbleStyleX: {},
            bubbleStyleY: {},
            positionY: 'top',
        }
    }

    onBodyTap(e) {
        const containerNode = ReactDOM.findDOMNode(this.wrapperEl)
        const isOutside =
            containerNode && e.target !== containerNode && !containerNode.contains(e.target)

        if ((isOutside || this.props.closeOnInsideClick) && this.state.showBubble) {
            this.setState({ showBubble: false })
        }
    }

    componentDidMount() {
        document.body.addEventListener('touchend', this.onBodyTap)
        document.body.addEventListener('click', this.onBodyTap)
        window.addEventListener('resize', this.onResize)
        window.addEventListener('scroll', this.onScroll)

        if (this.props.useTimeoutOnMount) {
            setTimeout(this.setBubblePosition)
        } else {
            this.setBubblePosition()
        }
        if (this.props.doOpen) {
            this.setState({ showBubble: true })
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('touchend', this.onBodyTap)
        document.body.removeEventListener('click', this.onBodyTap)
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('scroll', this.onScroll)
    }

    componentDidUpdate(prevProps) {
        if (this.props.doOpen && !prevProps.doOpen && !this.state.showBubble) {
            this.timeoutEnter = setTimeout(() => {
                this.setBubblePosition()
                this.setState({ showBubble: true })
                this.props.onShow && this.props.onShow()
            }, 300)
        }
    }

    onMouseLeave() {
        window.clearTimeout(this.timeoutEnter)
        this.timeoutLeave = setTimeout(() => {
            this.setState({ showBubble: false })
        }, 300)
    }

    onClick() {
        const { onShow } = this.props
        this.timeoutEnter = setTimeout(() => {
            if (this.state.showBubble) {
                return
            }
            this.setBubblePosition()
            this.setState({ showBubble: true })
            onShow && onShow()
        }, 300)
    }

    onMouseEnter(e) {
        const { onShow, enableClick } = this.props
        !enableClick && e.preventDefault()
        !enableClick && e.stopPropagation()
        window.clearTimeout(this.timeoutLeave)
        this.timeoutEnter = setTimeout(() => {
            this.setBubblePosition()
            this.setState({ showBubble: true })
            onShow && onShow()
        }, 300)
    }

    onResize() {
        window.clearTimeout(this.timeoutResize)
        this.timeoutResize = setTimeout(() => {
            this.setBubblePosition()
        }, 300)
    }

    onScroll() {
        window.clearTimeout(this.timeoutScroll)
        this.timeoutScroll = setTimeout(() => {
            this.setBubblePosition(true)
        }, 500)
    }

    setBubblePosition(skipX = false) {
        if (!this.trigger) {
            return
        }

        const {
            positionRightThreshold = 360,
            positionTopThreshold = 360,
            alt = false,
            referenceFrameId = '',
        } = this.props
        const rect = this.trigger.getBoundingClientRect()
        const tailHeight = 10
        const tailMargin = 5

        let positionY = ''
        let bubbleStyleY = {}
        if (rect.top - positionTopThreshold < 0) {
            // bottom
            positionY = 'bottom'
            bubbleStyleY = { top: `${rect.height + tailHeight + tailMargin}px` }
        } else {
            // top
            positionY = 'top'
            bubbleStyleY = { bottom: `${rect.height + tailHeight + tailMargin}px` }
        }

        if (skipX) {
            return this.setState({ positionY, bubbleStyleY })
        }

        const bubbleWidth = 320

        let bubbleStyleX = {}
        let leftSpace = rect.left
        let rightSpace = window.innerWidth - rect.right
        let leftSpaceNeeded = positionRightThreshold || bubbleWidth
        let rightSpaceNeeded = bubbleWidth

        if (referenceFrameId) {
            const refComponent = document.getElementById(referenceFrameId)
            if (refComponent) {
                // use component as frame of reference for the calculation (instead of the window)
                const refFrame = refComponent.getBoundingClientRect()
                leftSpace = rect.left - refFrame.left
                rightSpace = refFrame.right - rect.right
                leftSpaceNeeded = bubbleWidth
            }
        }

        if (leftSpace > leftSpaceNeeded) {
            // enough space to left side of the trigger to expand the bubble
            bubbleStyleX = { right: alt ? '0px' : '-15px' }
        } else if (rightSpace > rightSpaceNeeded) {
            // enough space to the right side of the trigger to expand the bubble
            bubbleStyleX = { left: alt ? '0px' : '-15px' }
        } else {
            // try to center the bubble as much as possible without crossing the bounderies
            const smallestSide = leftSpace > rightSpace ? 'right' : 'left'
            const sideSpace = Math.min((bubbleWidth - rect.width) / 2, rightSpace, leftSpace)
            bubbleStyleX = { [smallestSide]: `-${sideSpace - 16}px` }
        }

        this.setState({ positionY, bubbleStyleY, bubbleStyleX })
    }

    render() {
        const {
            trigger = <Icon name="info" fontSize={21} />,
            children,
            text,
            className = '',
            noTail = false,
            useDisplay = true,
            bubbleStyle = {},
            extraBubbleClassNames = [],
            textClass = '',
            defaultFont = false,
            whiteBg = false,
        } = this.props
        const { showBubble, positionY, bubbleStyleX, bubbleStyleY } = this.state
        const classNams = [style.hoverText, className, showBubble ? style.showBubble : ''].join(' ')
        const tailClassNames = [
            style.tail,
            positionY === 'top' ? style.top : style.bottom,
            whiteBg ? style.whiteBg : '',
        ].join(' ')
        const bubbleClassNames = [
            style.bubble,
            ...extraBubbleClassNames,
            defaultFont ? style.defaultFont : '',
            whiteBg ? style.whiteBg : '',
        ].join(' ')
        const bubbleStyles = {
            ...bubbleStyle,
            ...bubbleStyleX,
            ...bubbleStyleY,
        }
        const bubbleText = children || text
        const shouldShowBubble = showBubble && bubbleText

        return (
            <div
                className={classNams}
                onMouseEnter={this.onMouseEnter}
                onClick={this.onClick}
                ref={(el) => (this.wrapperEl = el)}
                onMouseLeave={this.onMouseLeave}>
                <div className={style.wrapper} ref={(el) => (this.trigger = el)}>
                    <div className={[style.text, textClass].join(' ')}>{trigger}</div>
                    {shouldShowBubble && (
                        <Fragment>
                            {!noTail && <div className={tailClassNames} />}
                            <div className={bubbleClassNames} style={bubbleStyles}>
                                {useDisplay ? bubbleText : showBubble && bubbleText}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        )
    }
}

HoverText.propTypes = {
    bubble: PropTypes.any,
    trigger: PropTypes.any,
}

export default HoverText
