/**
 * @description function to get the numeric values of numeric enum. Note that for string enums you don't need this function
 * 
 * @param {Record<string, number|string>} enumObject
 * 
 * @example
 * 
 * enum MyNumericEnum {
 *  'A' = 1,
 *  'B' = 2,
 * }
 * 
 *  getValuesOfNumericEnum(MyNumericEnum) // [1, 2]
 * // Object.values(MyNumericEnum) returns ['A', 'B', 1, 2]
 * 
 * @return {number[]} values of numeric enum
 */

export function getValuesOfNumericEnum(enumObject: Record<string, number|string>): number[] {
    const values = Object.values(enumObject)
    return values.filter((value) => typeof value === 'number') as number[]
}
