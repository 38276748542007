import { connect } from 'react-redux'
import { changeEntity } from '../actions/entities'

function mapStateToProps(state, props) {
    const { entities: notesEntities, result, isFetching, filters, touched } = state.notes
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey
    return {
        notesEntities,
        touched,
        pages: result[searchKey] || {},
        filter,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        touchNote: (noteId, touch) =>
            dispatch(
                changeEntity({
                    diff: { _isTouched: touch },
                    store: 'notes',
                    name: 'notes',
                    key: noteId,
                })
            ),
        setIsPinned: (noteId, isPinned) =>
            dispatch(
                changeEntity({
                    diff: { pinned:isPinned  },
                    store: 'notes',
                    name: 'notes',
                    key: noteId,
                })
            ),
        removeNote: (noteId, filterIds) =>
            dispatch(
                changeEntity({
                    remove: true,
                    store: 'notes',
                    filterIds,
                    name: 'notes',
                    key: noteId,
                })
            ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
