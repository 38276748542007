import { Col, Row } from '@advanza/grid'
import { Divider, MaxWidth } from '@advanza/ui'
import ProvidersExportButton from 'components/providers/ProvidersExportButton'
import Restricted from 'components/Restricted'
import React from 'react'
import { useSelector } from 'react-redux'
import Page from '../ui/Page'
import ProvidersFilter from './ProvidersFilter'
import ProvidersList from './ProvidersList'

const ProvidersPage = () => {
    const filter = useSelector((state) => state.providers.filters)['all']
    return (
        <Page title="Providers">
            <MaxWidth>
                <Divider m />
                <Row middle="xs" between="xs">
                    <Col x>
                        <h1>Service Providers</h1>
                    </Col>
                    <Col x>
                        <Restricted location="export" checkWrite={false}>
                            <ProvidersExportButton filter={filter} />
                        </Restricted>
                    </Col>
                </Row>
                <Divider />
                <ProvidersFilter filterId="all" defaultOptions={{ pageSize: 20 }} />
                <Divider />
                <ProvidersList selectable filterId="all" />
            </MaxWidth>
        </Page>
    )
}

export default ProvidersPage
