import { SelectCheckbox } from '@advanza/input'
import { changeEntity } from 'actions/entities'
import { useEntityShared } from 'hooks/sharedHooks'
import React from 'react'
import { useDispatch } from 'react-redux'

const SPEC = {
    store: 'geoTargeting',
    name: 'geos',
}

const GeoTargetingSelect = ({ targets = {}, geos = {}, geoId, tableAlias }) => {
    const dispatch = useDispatch()
    const {
        entity: geo = {},
        onChangeEntity,
        onSaveEntity,
    } = useEntityShared(SPEC, geoId, {}, { controller: 'GeoTargeting', query: { tableAlias } })

    return (
        <SelectCheckbox
            multiple={false}
            wide
            options={[{ value: 0, name: ' NONE' }].concat(
                Object.values(targets)
                    .map(({ criteria_id, canonical_name, usedAtIds }) => ({
                        value: criteria_id,
                        name:
                            canonical_name +
                            (usedAtIds.length > 1
                                ? ' (OVERUSED)'
                                : usedAtIds.length
                                ? ' (USED)'
                                : ''),
                    }))
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase()
                        const nameB = b.name.toUpperCase()
                        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
                    })
            )}
            value={[geo.geoTargetId]}
            onChangeValue={(values) => {
                const geoTargetIdNew = values[0]

                if (geo.geoTargetId === geoTargetIdNew) {
                    return
                }

                if (tableAlias !== 'PostalCodes' && geoTargetIdNew) {
                    Object.values(geos).forEach(
                        (geo) =>
                            geo.geoTargetId === geoTargetIdNew &&
                            dispatch(
                                changeEntity({
                                    ...SPEC,
                                    key: geo.geoId,
                                    diff: {
                                        geoTargetId: 0,
                                        geoTargetName: ' NONE',
                                    },
                                })
                            )
                    )
                }

                onChangeEntity({
                    geoTargetId: geoTargetIdNew,
                    geoTargetName: (targets[geoTargetIdNew] || {}).canonical_name || ' NONE',
                })
                onSaveEntity()

                if (geo.geoTargetId) {
                    dispatch({
                        type: 'GEOTARGETING_UPDATE_USED_AT_IDS',
                        criteriaId: geo.geoTargetId,
                        op: 'remove',
                        geoId,
                    })
                }

                if (geoTargetIdNew) {
                    dispatch({
                        type: 'GEOTARGETING_UPDATE_USED_AT_IDS',
                        criteriaId: geoTargetIdNew,
                        op: tableAlias === 'PostalCodes' ? 'add' : 'set',
                        geoId,
                    })
                }
            }}
        />
    )
}

export default GeoTargetingSelect
