import React from 'react'
import NotesListContainer from '../../containers/NotesListContainer'
import LinearLoader from '../LinearLoader'
import NoteListItem from './NoteListItem'
import NoteListItemEdit from './NoteListItemEdit'

class NotesList extends React.Component {
    render() {
        const {
            pages = [[]],
            notesEntities = {},
            setIsPinned,
            filter,
            touchNote,
            removeNote,
            isFetching,
        } = this.props
        const pageNr = (filter && filter.page) || 0
        const result = pages[pageNr] || []
        return (
            <div className="list">
                {result.map((id, i) => {
                    const note = notesEntities.notes[id]
                    const isNew = typeof note.note_id === 'string'
                    const nextNote = notesEntities.notes[result[i + 1]] || null
                    const onTouch = () => touchNote(note.note_id, true)
                    const onCancel = () =>
                        isNew
                            ? removeNote(note.note_id, [filter.filterId])
                            : touchNote(note.note_id, false)

                    if (note._isTouched) {
                        return (
                            <NoteListItemEdit
                                touch={onTouch}
                                cancel={onCancel}
                                entityId={note.note_id}
                                filterId={filter.filterId}
                                key={id}
                            />
                        )
                    } else {
                        return (
                            <NoteListItem
                                touch={onTouch}
                                key={id}
                                setIsPinned={setIsPinned}
                                nextNote={nextNote}
                                onChangeEntity={this.props.onChangeEntity}
                                entities={notesEntities}
                                id={note.note_id}
                            />
                        )
                    }
                })}
                {isFetching && <LinearLoader fixed />}
            </div>
        )
    }
}


export default NotesListContainer(NotesList)
