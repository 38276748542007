import { call } from '@advanza/api'
import { LoadingDots, Divider } from '@advanza/ui'
import Page from 'components/ui/Page'
import style from './QueuePage.module.css'
import React, { useEffect, useState } from 'react'

let intervalid, timeoutid

function useSqs() {
    const [stats, setStats] = useState(null)
    const [loading, setLoading] = useState(false)

    function getQueueStats() {
        setLoading(true)
        call('office/misc/get-sqs-queue-stats')
            .then(({ stats }) => {
                setStats(stats)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getQueueStats()
        intervalid = setInterval(getQueueStats, 3000)
        timeoutid = setTimeout(() => {
            clearInterval(intervalid)
        }, 60000)

        return () => {
            clearInterval(intervalid)
            clearTimeout(timeoutid)
        }
    }, [])

    return {
        stats,
        loading,
    }
}

const QueuePage = () => {
    const { loading, stats } = useSqs()
    return (
        <Page title="Queue">
            <Divider m />
            <h2>Queue {loading && <LoadingDots />}</h2>
            <Divider m />
            <table className={style.table}>
                <thead>
                    <tr>
                        <td>queue</td>
                        <td>messages</td>
                        <td>invisible</td>
                        <td>delayed</td>
                    </tr>
                </thead>
                <tbody>
                    {stats &&
                        Object.keys(stats).map((queue) => {
                            const {
                                ApproximateNumberOfMessages,
                                ApproximateNumberOfMessagesNotVisible,
                                ApproximateNumberOfMessagesDelayed,
                            } = stats[queue].Attributes
                            return (
                                <tr key={queue}>
                                    <td>
                                        <a
                                            target="_blank"
                                            href={`https://eu-central-1.console.aws.amazon.com/sqs/v2/home?region=eu-central-1#/queues/https%3A%2F%2Fsqs.eu-central-1.amazonaws.com%2F685314094115%2F${queue}`}>
                                            {queue}
                                        </a>
                                    </td>
                                    <td>{ApproximateNumberOfMessages}</td>
                                    <td>{ApproximateNumberOfMessagesNotVisible}</td>
                                    <td>{ApproximateNumberOfMessagesDelayed}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </Page>
    )
}

export default QueuePage
