import { indexGenerator } from '@advanza/func'
import React, { Fragment } from 'react'
import style from './../basicMdParser.module.css'

// Regular expressions for Markdown (a bit strict, but they work)
const linkRegex = /\[([^\[]+)\]\(([^\)]+)\)/g
const boldRegex = /\*\*(.*?)\*\*/gm
const italicRegex = /\*(.*?)\*/gm

let replacementsMap = {}
const idGenerator = indexGenerator()
const replaceRegex = function (regex, replacement) {
    return function (str) {
        let replacedStr = str
        let matches = regex.exec(str)
        const allMatches = []
        while (matches != null) {
            allMatches.push(matches)
            matches = regex.exec(str)
        }

        allMatches.forEach((match) => {
            if (match) {
                const replacementId = idGenerator.next().value
                replacementsMap[replacementId] = replacement(match)
                replacedStr = replacedStr.replace(match[0], `@@@${replacementId}|||`)
            }
        })

        return replacedStr
    }
}

// Warning: according to the markdown spec, it should be [title](https://www.example.com) but we use [https://www.example.com](title)
const linkReplacer = function ([fullMatch, tagURL, tagTitle]) {
    return <a href={tagURL}>{tagTitle}</a>
}
const boldReplacer = function ([fullMatch, inner]) {
    return <b>{inner}</b>
}
const italicReplacer = function ([fullMatch, inner]) {
    return <i>{inner}</i>
}

function replaceBreaks(str) {
    const lines = str.split('\n')
    return lines.map((str, i) => {
        const isLast = lines.length === i + 1
        if (str.length === 0 && (isLast || lines[i + 1].length === 0)) {
            return null
        }
        return (
            <Fragment key={i}>
                {str}
                {!isLast && <br />}
            </Fragment>
        )
    })
}

const replaceMarkdown = function (str, disallowLinks = false) {
    replacementsMap = {}
    let replacedStr = disallowLinks ? str : replaceRegex(linkRegex, linkReplacer)(str)
    replacedStr = replaceRegex(boldRegex, boldReplacer)(replacedStr)
    replacedStr = replaceRegex(italicRegex, italicReplacer)(replacedStr)
    return replacedStr.split('|||').map((part, i) => {
        const [text, replacementId] = part.split('@@@')
        return (
            <Fragment key={i}>
                {replaceBreaks(text)}
                {replacementId && <> {replacementsMap[replacementId]}</>}
            </Fragment>
        )
    })
}

const BasicMdParser = ({ input = 'markdownstring', tagName = 'div', disallowLinks }) => {
    return React.createElement(
        tagName,
        { className: style.root },
        replaceMarkdown(input, disallowLinks)
    )
}

export default BasicMdParser
