import sharedStyle from '@advanza/css/shared.module.scss'
import { ChangeEvent, ReactNode } from 'react'
import inputFieldStyle from './inputField.module.css'
import style from './radio.module.scss'

export interface RadioChangeEvent {
    target: {
        name: string
        value: string | number
    }
}

export interface RadioOption {
    id?: number | string
    value: string | number
    title?: string
    name?: ReactNode
    onClick?: () => void
}

interface Props {
    className?: string
    classNames?: {
        label?: string
        checked?: string
    }
    appearance?: 'basic' | 'bold' | 'blocks'
    name?: string
    options: RadioOption[]
    value?: string | number | null
    onChange: (e: RadioChangeEvent) => void
    afterChange?: () => void
    placeholder?: string
    error?: string | boolean
    defaultErrorMsg?: string
    disabled?: boolean
    legend?: boolean
    inline?: boolean
    colorPicker?: boolean
    inBlocks?: boolean
    nameClass?: string
}

const Radio = ({
    className,
    classNames = {},
    appearance = 'basic',
    name: fieldName,
    options,
    value: selected,
    onChange,
    afterChange,
    placeholder,
    error,
    defaultErrorMsg = 'Kies een antwoord', // todo: translate
    disabled = false,
    legend = false,
    inline = false,
    colorPicker = false,
    inBlocks = false,
    nameClass,
}: Props) => {
    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        onChange({
            target: {
                name,
                value: Number.isNaN(Number(value)) ? value : parseInt(value),
            },
        })
        afterChange && afterChange()
    }

    return (
        <>
            {legend && <div className={inputFieldStyle.legend}>{placeholder}</div>}
            <div
                className={[
                    style.radios,
                    style[`appearance_${appearance}`],
                    sharedStyle[`appearance_${appearance}`],
                    inline ? style.inline : '',
                    colorPicker ? style.colorPicker : '',
                    inBlocks ? style.inBlocks : '',
                ].join(' ')}>
                {options.map(({ id, value, title, name, onClick }, i: number) => {
                    const isChecked =
                        selected === value ||
                        (Array.isArray(selected) && selected.indexOf(value) !== -1)
                    return (
                        <label
                            key={i}
                            onClick={onClick}
                            title={title}
                            className={[
                                style.label,
                                appearance === 'blocks' ? sharedStyle.block : '',
                                appearance === 'blocks' && isChecked ? sharedStyle.selected : '',
                                (className && style[className]) || '',
                                isChecked ? style.checked : '',
                                (isChecked && classNames.checked) || '',
                                classNames.label,
                                disabled ? style.disabled : '',
                            ].join(' ')}>
                            <div
                                className={isChecked ? style.iconChecked : style.icon}
                                style={colorPicker ? { backgroundColor: value.toString() } : {}}
                            />
                            <input
                                type="radio"
                                name={fieldName}
                                id={id?.toString() || name?.toString()} // name is not a good fallback, because it can be a ReactNode
                                value={value}
                                checked={isChecked}
                                className={style.radio}
                                onChange={onChangeInput}
                                onClick={(e) => e.stopPropagation()} // stop second click event with input as target
                                disabled={disabled}
                            />
                            <span className={nameClass}>{name}</span>
                        </label>
                    )
                })}
                {error && (
                    <div className={style.error}>
                        {(typeof error === 'string' && error) || defaultErrorMsg || ''}
                    </div>
                )}
            </div>
        </>
    )
}

export default Radio
