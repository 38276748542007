import React from 'react'
import { connect } from 'react-redux'
import {
    declareValid,
    deleteRequest,
    fetchRequest,
    generateDemandDashboardLink,
    changeRequestTypeToInfoMessage,
} from '../actions/requests'
import { fetchService } from '../actions/services'

function mapStateToProps(state, props) {
    const { requests, ...requestEntities } = state.requests.entities
    const { services, ...serviceEntities } = state.services.entities
    const request = requests && requests[props.requestId]
    const service = request && services && services[request.service_id]
    const isFetching = state.services.isFetching || state.requests.isFetching
    const userId = request && request.user
    const user = userId && state.requests.entities.users[userId]
    return {
        requestEntities,
        serviceEntities,
        request,
        service,
        isFetching,
        user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRequest: (requestId, filterSource) => dispatch(fetchRequest(requestId, filterSource)),
        fetchService: (serviceId) => dispatch(fetchService(serviceId)),
        declareValid: (requestId) => dispatch(declareValid(requestId)),
        deleteRequest: (requestId) => dispatch(deleteRequest(requestId)),
        changeRequestTypeToInfoMessage: (requestId) =>
            dispatch(changeRequestTypeToInfoMessage(requestId)),
        generateDemandDashboardLink: (requestId) =>
            dispatch(generateDemandDashboardLink(requestId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
