import { Ellipsis } from '@advanza/ui'
import { Icon } from '@advanza/ui'
import React from 'react'
import style from './scoreBlock.module.css'

const ScoreBlock = ({
    name,
    value,
    lowerIsBetter,
    loading,
    highlight,
    altStyle,
    isPercentage,
    prevValue = null,
}) => {
    const previousValue = prevValue !== null && parseFloat(prevValue)
    const isBetter = lowerIsBetter ? value < previousValue : value > previousValue
    const noDiff = previousValue == value
    const diff = Math.round(value - previousValue)
    const displayValue = isPercentage ? Math.round(value) + '%' : value
    const className = [
        style.root,
        highlight ? style.highlight : '',
        altStyle ? style.alt : '',
        loading ? style.loading : '',
    ].join(' ')
    return (
        <div className={className}>
            <div className={style.name}>
                <Ellipsis>{name}</Ellipsis>
            </div>
            <b>{loading ? '...' : displayValue}</b>
            {prevValue !== null && (
                <div className={style.prev}>
                    <div className={isBetter ? style.better : noDiff ? style.noDiff : style.worse}>
                        {!noDiff && (
                            <Icon
                                fontSize={18}
                                name={isBetter ? 'arrow_upward' : 'arrow_downward'}
                            />
                        )}
                        <span>{diff}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

ScoreBlock.propTypes = {}

export default ScoreBlock
