import {
    appendMapsScript,
    mapsPromise,
} from '@advanza/advanza_generic/src/services/googleMapsLoader'
import { InputField } from '@advanza/input'
import React, { Component } from 'react'
import { FieldInput } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'

class GeoAutoComplete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mapsLoaded: false,
        }
        this.textInput = null

        this.autoCompleteInit = this.autoCompleteInit.bind(this)
        this.placeChanged = this.placeChanged.bind(this)
    }
    componentWillMount() {
        appendMapsScript()
    }

    componentDidMount() {
        mapsPromise().then(this.autoCompleteInit)
    }

    autoCompleteInit(maps) {
        const options = this.props.options || {}
        if (!maps) {
            return
        }
        this.autocomplete = new maps.maps.places.Autocomplete(this.textInput, {
            ...options,
            placeholder: null,
        })
        this.autocomplete.addListener('place_changed', this.placeChanged)
    }

    placeChanged() {
        const place = this.autocomplete.getPlace()
        const { onChange, name, onPlaceChange, defaultResultKey } = this.props
        onChange &&
            onChange({
                target: {
                    name,
                    value: place[defaultResultKey] || place.formatted_address,
                },
            })
        onPlaceChange && onPlaceChange(place)
    }

    render() {
        const { icon, onPlaceChange, defaultResultKey, ...rest } = this.props
        return (
            <div>
                <InputField
                    ref={(input) => (this.textInput = input && input.ref)}
                    icon={icon === false ? false : 'place'}
                    value={rest.state.value}
                    inputPlaceholder=""
                    errors={rest.state.error}
                    placeholder={rest.msg}
                    {...rest}
                />
            </div>
        )
    }
}

GeoAutoComplete.propTypes = {
    defaultResultKey: PropTypes.string,
    icon: PropTypes.any,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onPlaceChange: PropTypes.func,
    options: PropTypes.object,
    state: PropTypes.object,
}

export default GeoAutoComplete
