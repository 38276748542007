import { APP, Icon } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import { FileDropZone } from '@advanza/input'
import PropTypes from 'prop-types'
import React from 'react'
import ReactCrop from 'react-image-crop'
import style from './avatarEdit.module.css'

class AvatarEdit extends React.Component {
    constructor(props) {
        super(props)
        this.onDropFiles = this.onDropFiles.bind(this)
        this.getUrl = this.getUrl.bind(this)
        this.onDone = this.onDone.bind(this)
        this.getCroppedImg = this.getCroppedImg.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onImageLoaded = this.onImageLoaded.bind(this)
        this.state = {
            cropMode: false,
            pixelCrop: false,
        }
    }

    componentDidMount() {
        import('react-image-crop/dist/ReactCrop.css').then(() => {})
    }

    onDropFiles(files) {
        const { onChangeImage, blockedMap = {}, lang = 'EN' } = this.props
        const filename = files[0].name
        const size = files[0].size
        const key = filename + '-' + size
        if (blockedMap[key]) {
            alert(
                lang === 'NL'
                    ? `afbeelding '${filename}' al gebruikt als ${blockedMap[key]}`
                    : `image '${filename}' already used as ${blockedMap[key]}`
            )
            return
        }

        const Reader = new FileReader()
        Reader.onloadend = (e) => {
            const { result } = e.target
            onChangeImage({
                preview: result,
                base64: result.substr(result.indexOf(',') + 1),
                filename,
                size,
            })
            this.setState({ cropMode: true })
        }
        Reader.readAsDataURL(files[0])
    }

    getCroppedImg() {
        const { pixelCrop: crop } = this.state
        const canvas = document.createElement('canvas')
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')
        if (crop.width < 1 || crop.height < 1) {
            return Promise.reject()
        }
        ctx.drawImage(
            this.Image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                blob.name = '123'
                resolve(blob)
            }, 'image/jpeg')
        })
    }

    getUrl() {
        const { user, file } = this.props
        const { cropMode } = this.state
        if (!cropMode && file && file.croppedPreview) {
            return file.croppedPreview
        }
        if (file && file.preview) {
            return file.preview
        } else if (user.avatar_filename) {
            return APP.S3_PUBLIC() + `users/${user.id}/${user.avatar_filename}`
        } else {
            return false
        }
    }

    onDone() {
        const { onChangeImage } = this.props
        const { pixelCrop } = this.state
        if (pixelCrop) {
            this.getCroppedImg().then((blob) => {
                onChangeImage({ croppedPreview: URL.createObjectURL(blob) })
                this.setState({ cropMode: false })
            })
        } else {
            this.setState({ cropMode: false })
        }
    }

    onChange(crop, pixelCrop) {
        const { onCrop } = this.props
        onCrop(crop)
        this.setState({ pixelCrop })
    }

    onImageLoaded(image) {
        this.Image = image
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user && prevProps.user._saving && !this.props.user._saving) {
            this.setState({ cropMode: false })
        } else if (
            prevProps.file !== this.props.file &&
            prevProps.user.avatar_filename === this.props.user.avatar_filename
        ) {
            this.setState({ cropMode: true })
        }
    }

    render() {
        const { file, crop, size = 75, onFilesRejected } = this.props
        const url = this.getUrl()
        const { cropMode } = this.state
        const imgStyle = {
            background: `url(${url}) no-repeat center center /cover`,
            width: size,
            height: size,
        }

        return (
            <div className={style.root}>
                {file && cropMode && (
                    <ReactCrop
                        style={{ backgroundColor: '#fff' }}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onChange={this.onChange}
                        src={url}
                    />
                )}
                {!cropMode && (
                    <FileDropZone
                        allowedTypes={['image']}
                        multiple={false}
                        maxFiles={1}
                        onFilesAccepted={this.onDropFiles}
                        onFilesRejected={onFilesRejected}>
                        <div className={style.bgImg} style={imgStyle}>
                            {url ? (
                                <div className={style.image}>
                                    <b>
                                        <u>Wijzig</u> <Icon fontSize={18} name="edit" />
                                    </b>
                                </div>
                            ) : (
                                <button className="plain">+ Upload avatar</button>
                            )}
                        </div>
                    </FileDropZone>
                )}

                {file && (
                    <div className={style.cropButton}>
                        <Row end="xs">
                            <Col x>
                                <button
                                    name="text"
                                    onClick={
                                        cropMode
                                            ? this.onDone
                                            : () => this.setState({ cropMode: true })
                                    }>
                                    <Icon> {cropMode ? 'check' : 'crop'}</Icon>
                                    {cropMode ? 'Klaar' : 'Uitsnijden'}
                                </button>
                            </Col>
                            {cropMode && (
                                <Col x>
                                    <button name="text" onClick={() => this.props.onCrop(null)}>
                                        <Icon>crop_free</Icon>
                                    </button>
                                </Col>
                            )}
                        </Row>
                    </div>
                )}
            </div>
        )
    }
}

AvatarEdit.propTypes = {
    file: PropTypes.object,
    onChangeImage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default AvatarEdit
