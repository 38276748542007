import { schema } from 'normalizr'

const reviewsSchema = new schema.Entity(
    'reviews',
    {},
    {
        idAttribute: 'review_id',
    }
)

export default [reviewsSchema]
