import React from 'react'
import { connect } from 'react-redux'
import {
    addOption,
    addOptionImage,
    addQuestion,
    changeQuestionOrder,
    deleteOption,
    deleteQuestion,
    fetchService,
    saveOption,
    saveQuestion,
    saveService,
    saveServiceBanner,
} from '../actions/services'
import { onFilesRejected } from '../actions/files'

function mapStateToProps(state, props) {
    const { services, ...serviceEntities } = state.services.entities
    const service = services && services[props.serviceId]
    const optionPages = []
    service &&
        service.questions &&
        service.questions.forEach((id) => {
            const question = serviceEntities.questions[id]
            question.options &&
                question.options.forEach((id) => {
                    const option = serviceEntities.options[id]
                    if (option.option_page) {
                        optionPages.push(serviceEntities.option_pages[option.option_page])
                    }
                })
        })
    return {
        serviceEntities,
        optionPages,
        service,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchService: (serviceId) => dispatch(fetchService(serviceId)),
        saveServiceImage: (serviceId, file, context) =>
            dispatch(saveServiceBanner(serviceId, file, context)),
        saveService: (serviceId) => dispatch(saveService(serviceId)),
        saveQuestion: (questionId) => dispatch(saveQuestion(questionId)),
        changeQuestionOrder: (question, upOrDown) =>
            dispatch(changeQuestionOrder(question, upOrDown)),
        addOptionImage: (optionId, file) => dispatch(addOptionImage(optionId, file)),
        saveOption: (optionId) => dispatch(saveOption(optionId)),
        addQuestion: (serviceId, data) => dispatch(addQuestion(serviceId, data)),
        addOption: (questionId, data) => dispatch(addOption(questionId, data)),
        deleteQuestion: (questionId) => dispatch(deleteQuestion(questionId)),
        deleteOption: (optionId) => dispatch(deleteOption(optionId)),
        onFilesRejected: (files) => dispatch(onFilesRejected(files)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
