import { fetchAllMunicipalities } from 'actions/subscriptions'
import React from 'react'
import { connect } from 'react-redux'
import { fetchService } from '../actions/services'
import { changeServiceStatsOptions, fetchServiceStats } from '../actions/serviceStats'

function getFilterableOptions(service, entities) {
    const { questions } = entities
    const filterableOptions = []
    if (!service || !questions || !service.questions) {
        return filterableOptions
    }
    service.questions.forEach((id) => {
        const question = questions[id]
        if (question.filterable && question.options) {
            question.options.map((id) => filterableOptions.push(id))
        }
    })

    return filterableOptions
}

function getRequestCounts(stats, optionIds) {
    const requestIdsUsed = {}
    const municipalityCounts = {}
    const optionCountsAll = {}
    stats.requestData.map(({ municipality_id, request_id, option_id }) => {
        optionCountsAll[option_id] = optionCountsAll[option_id] || 0
        optionCountsAll[option_id]++
        if (!requestIdsUsed[request_id]) {
            if (optionIds && optionIds.length > 0) {
                if (optionIds.includes(option_id)) {
                    requestIdsUsed[request_id] = true
                    municipalityCounts[municipality_id] = municipalityCounts[municipality_id] || 0
                    municipalityCounts[municipality_id]++
                }
            } else {
                requestIdsUsed[request_id] = true
                municipalityCounts[municipality_id] = municipalityCounts[municipality_id] || 0
                municipalityCounts[municipality_id]++
            }
        }
    })

    return {
        municipalityCounts,
        optionCountsAll,
    }
}

function mapStateToProps(state, props) {
    const { services, ...serviceEntities } = state.services.entities
    const { options: statsOptions, isFetching, ...stats } = state.serviceStats
    const service = services && services[props.serviceId]
    const filterableOptions = getFilterableOptions(service, serviceEntities)
    const getStatsAndOptions = (type) => {
        const _stats = stats[type][props.serviceId]
        const _options = statsOptions[type][props.serviceId]
        return {
            stats: _stats,
            options: _options,
            counts:
                _stats &&
                _options &&
                type === 'requestStats' &&
                getRequestCounts(_stats, _options.optionIds),
        }
    }
    const { municipalities: allMunicipalities } = state.subscriptions
    return {
        service,
        filterableOptions,
        statsOptions,
        stats,
        serviceEntities,
        allMunicipalities,
        isFetching,
        getStatsAndOptions,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchStats: (serviceId, type) => dispatch(fetchServiceStats(serviceId, type)),
        fetchService: (serviceId) => dispatch(fetchService(serviceId)),
        changeOptions: (serviceId, type, options) =>
            dispatch(changeServiceStatsOptions(serviceId, type, options)),
        fetchAllMunicipalities: () => dispatch(fetchAllMunicipalities()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
