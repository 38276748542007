import { Col, Row, Section } from '@advanza/advanza_generic'
import { OBJECT_TYPE_MAP, STATUS_MAP } from '@advanza/constants'
import { Icon, PreIcon } from '@advanza/ui'
import RequestsMoments from 'components/requests/RequestsMoments'
import RequestsMonths from 'components/requests/RequestsMonths'
import RequestDetailsContainer from 'containers/RequestDetailsContainer'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const RequestCard = ({ request = {}, service = {}, requestEntities, serviceEntities }) => {
    if (!service.questions || !request.requests_options) {
        return null
    }
    const user = requestEntities.users[request.user_id]
    const tableMap = [
        { key: 'first_name', name: 'First name' },
        { key: 'last_name', name: 'Last name' },
        { key: 'email', name: 'Email', item: <a href={`mailto:${user.email}`}>{user.email}</a> },
        { key: 'phone', name: 'Phone' },
        {
            name: 'Place',
            item: [
                request.street_name,
                request.house_number,
                request.postal_code && request.postal_code.place_name,
                request.postal_code && request.postal_code.postal_code,
            ].join(' '),
        },
        { key: 'subject', name: 'subject' },
        { key: 'contact_preference', name: 'Contact preference' },
        { key: 'project_time', name: 'Time' },
        { key: 'is_business', name: 'Is business', item: request.is_business ? 'yes' : 'no' },
        { key: 'request_type', name: 'Type' },
        {
            key: 'cta_id',
            name: 'Custom CTA',
            item: request.cta_id ? (
                <a
                    style={{ color: '#0077cc' }}
                    target="_blank"
                    rel="noreferrer"
                    href={`/settings/services/service/${request.service_id}`}>
                    {'#' + request.cta_id}
                </a>
            ) : (
                ''
            ),
        },
        {
            key: 'mail_only',
            name: 'Voorkeur: alleen per mail',
            item: request.preference_mail_only && <Icon name="check" />,
        },
        {
            key: 'call_only_during_dday',
            name: 'Voorkeur: niet bellen overdag',
            item: request.preference_call_not_during_day && <Icon name="check" />,
        },
    ]

    // group requests_dates by question_id
    const dateQuestions =
        request.requests_dates &&
        request.requests_dates.length > 0 &&
        request.requests_dates.reduce((allDateQuestions, requestsDateId) => {
            const { question_id, exec_date } = requestEntities.requestsDates[requestsDateId]
            return {
                ...allDateQuestions,
                [question_id]: [...(allDateQuestions[question_id] ?? []), exec_date],
            }
        }, {})

    return (
        <div>
            <Section>
                {tableMap.map(({ key, name, item }) => {
                    const answer = item || request[key] || user[key]
                    if (answer) {
                        return (
                            <Row y="min" key={name}>
                                <Col style={{ maxWidth: 150 }}>
                                    <div className="col-h">{name}</div>
                                </Col>
                                <Col>
                                    <div>{answer}</div>
                                </Col>
                            </Row>
                        )
                    }
                })}
            </Section>
            <p>{request.details}</p>
            {service.questions.map((id, i) => {
                const question = serviceEntities.questions[id]
                const checkedOptions =
                    question.options.length > 0 &&
                    question.options
                        .map((id) => {
                            const requestsOption = request.requests_options.find(
                                (item) => item.option_id === id
                            )
                            return (
                                !!requestsOption && (
                                    <div key={id}>
                                        <span>{requestsOption.value}</span>{' '}
                                        {requestsOption.is_pro && (
                                            <Link to={`/sales/leads`}>
                                                <Section bg="purp-grey" className="label">
                                                    Is Business
                                                </Section>
                                            </Link>
                                        )}
                                    </div>
                                )
                            )
                        })
                        .filter(Boolean)
                let extraAnswers = {}
                const answer = request.requests_questions
                    .map((question) => {
                        if (question.question_id === id) {
                            const bagInfo = question.bag_info || {}
                            bagInfo.construction_date &&
                                (extraAnswers['Bouwjaar'] = bagInfo.construction_date)
                            bagInfo.status && (extraAnswers['Status'] = STATUS_MAP[bagInfo.status])
                            bagInfo.object_type &&
                                (extraAnswers['Type object'] = OBJECT_TYPE_MAP[bagInfo.object_type])

                            return (
                                <div key={id}>
                                    <span>{question.value}</span>
                                </div>
                            )
                        }
                    })
                    .filter(Boolean)
                const hasAnswer = answer.length > 0 || checkedOptions.length > 0
                if (hasAnswer) {
                    const renderQuestionAnswer = (questionText, answerText) => (
                        <Row y="min" key={questionText}>
                            <Col style={{ maxWidth: 150 }}>
                                <div className="col-h">{questionText}</div>
                            </Col>
                            <Col>{answerText}</Col>
                        </Row>
                    )

                    return (
                        <Fragment key={i}>
                            {renderQuestionAnswer(
                                question.question_name_filter,
                                checkedOptions.length > 0 ? checkedOptions : answer[0]
                            )}
                            {Object.keys(extraAnswers).map((key) =>
                                renderQuestionAnswer(key, extraAnswers[key])
                            )}
                        </Fragment>
                    )
                }
                return null
            })}
            {dateQuestions &&
                Object.keys(dateQuestions).map((questionId) => {
                    return (
                        <Row y="min" key={`requests_dates_${request.request_id}`}>
                            <Col style={{ maxWidth: 150 }}>
                                <div className="col-h">
                                    {requestEntities.questions[questionId].question_name_filter}
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    {dateQuestions[questionId]
                                        .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
                                        .map((date) => format(new Date(date), 'dd MMM yyyy'))
                                        .join(', ')}
                                </div>
                            </Col>
                        </Row>
                    )
                })}
            {request.requests_months?.length > 0 && (
                <RequestsMonths requestsMonthsids={request.requests_months} />
            )}
            {request.requests_moments?.length > 0 && (
                <RequestsMoments momentIds={request.requests_moments} />
            )}
            {Boolean(request.requests_keyword) && (
                <Row y="min" key={'requests_keywords'}>
                    <Col style={{ maxWidth: 150 }}>
                        <div className="col-h">Keyword</div>
                    </Col>
                    <Col>
                        <div>{request.requests_keyword.keyword}</div>
                    </Col>
                </Row>
            )}
            {request.files.length > 0 && (
                <div className="quote-list-files">
                    <div className="question">
                        <PreIcon icon="image">{request.files.length} images</PreIcon>
                    </div>
                </div>
            )}
        </div>
    )
}

RequestCard.propTypes = {
    request: PropTypes.object,
    service: PropTypes.object,
    requestEntities: PropTypes.object,
    serviceEntities: PropTypes.object,
}

export default RequestDetailsContainer(RequestCard)
