import DescriptionSelectionTool from 'components/providers/DescriptionSelectionTool'
import { useDescription } from 'hooks/descriptionHooks'
import { useProfileBuilderWizard } from 'hooks/profileBuilderHooks'
import React, { useEffect } from 'react'

const PageDescription = ({ providerId }) => {
    const {
        provider = {},
        findLabel,
        getSelection,
        changeProvider,
        changeSelection,
    } = useProfileBuilderWizard(providerId)
    const { hasFetchedDescriptions, fetchDescriptions, preselectedDescription } =
        useDescription(provider)

    useEffect(() => {
        fetchDescriptions()
    }, [fetchDescriptions])

    useEffect(() => {
        if (hasFetchedDescriptions && !getSelection('description').initDone) {
            changeSelection('description', { initDone: true })

            if (
                !(provider.description || '').trim() &&
                !findLabel('no_text_on_website') &&
                preselectedDescription
            ) {
                changeProvider({ description: preselectedDescription })
            }
        }
    })

    return <DescriptionSelectionTool providerId={providerId} inWizard />
}

export default PageDescription
