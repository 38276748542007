import React from 'react'
import getClass from '../classNames'
import createProps from '../createProps'

const rowKeys = ['start', 'center', 'end', 'top', 'middle', 'bottom', 'around', 'between']

function getRowClassNames(props) {
    const modificators = [props.className, getClass('row')]

    for (let i = 0; i < rowKeys.length; ++i) {
        const key = rowKeys[i]
        const value = props[key]
        if (value) {
            modificators.push(getClass(`${key}-${value}`))
        }
    }

    if (props.reverse) {
        modificators.push(getClass('reverse'))
    }

    return modificators
}

export function getRowProps(props) {
    return createProps({}, props, getRowClassNames(props))
}

export default function Row(props) {
    return React.createElement(props.tagName || 'div', getRowProps(props))
}
