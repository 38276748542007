import { FixedWarningPopup } from '@advanza/ui'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './toaster.module.css'

export function useToast() {
    const dispatch = useDispatch()
    const toasts = useSelector((state) => state.toasts)

    const addToast = (options = {}) =>
        dispatch({
            type: 'ADD_TOAST',
            toast: {
                red: !(options.green || options.orange),
                timeout: 10000,
                closeBtn: true,
                ...options,
                id: options['id'] || new Date().getTime() + '-' + Math.random().toString(36).substring(2, 7),
            },
        })

    const deleteToast = (id) =>
        dispatch({
            type: 'DELETE_TOAST',
            id,
        })

    const deleteAllToasts = () => dispatch({ type: 'DELETE_ALL' })

    const onFilesRejected = (files) =>
        files.forEach(({ file, errors }) =>
            errors.forEach((e) =>
                addToast({
                    msg: `${file.name} (${Math.round(file.size / 100000) / 10}MB) - ${e.code
                        .replace(/-/g, ' ')
                        .toUpperCase()} - ${e.message}`,
                })
            )
        )

    return {
        toasts,
        addToast,
        deleteToast,
        deleteAllToasts,
        onFilesRejected,
    }
}

const Toaster = () => {
    const { toasts, deleteToast, deleteAllToasts } = useToast()

    useEffect(() => {
        // cleanup toasts when Page.js unmounts
        return deleteAllToasts
    }, [])

    return (
        <div className={style.root}>
            {toasts &&
                Object.values(toasts)
                    .reverse()
                    .map(({ id, msg, ...options }) => {
                        return (
                            <FixedWarningPopup
                                key={id}
                                className={style.toast}
                                show
                                onExited={() => deleteToast(id)}
                                {...options}>
                                {msg}
                            </FixedWarningPopup>
                        )
                    })}
        </div>
    )
}

export default Toaster
