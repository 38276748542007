import { Row, Section } from '@advanza/advanza_generic'
import React from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { saveAdminUser } from '../../actions/adminUsers'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import EntityComponent from '../services/EntityComponent'
import AdminUserPermissions from './AdminUserPermissions'

class NewAdminUserEntity extends EntityComponent {
    editFields() {
        return {
            email: { type: 'email', validator: isEmail, msg: 'Email', autoFocus: true },
            first_name: { type: 'text', msg: 'First name' },
            last_name: { type: 'text', msg: 'Last name' },
            username: { type: 'text', msg: 'username' },
            password: { type: 'text', msg: 'password *optional*' },
        }
    }

    afterSaveSuccess() {
        const { afterSave } = this.props
        afterSave && afterSave()
    }

    render() {
        const { entity } = this.props
        return (
            <div>
                <Section s>{this.renderInput('email')}</Section>
                <Row nm s className="no-wrap">
                    {this.renderInput('first_name')}
                    {this.renderInput('last_name')}
                </Row>
                <Section s>{this.renderInput('username')}</Section>
                <Section s>{this.renderInput('password')}</Section>
                <AdminUserPermissions entity={entity} onChangeEntity={this.onChangeEntity} />
                <Row n>
                    <button
                        onClick={this.save}
                        disabled={!entity._isTouched}
                        className="btn cta to-right">
                        {entity._saving ? 'saving..' : 'Save'}
                    </button>
                </Row>
            </div>
        )
    }
}

NewAdminUserEntity.propTypes = {}

export default EntityComponentContainer(NewAdminUserEntity, {
    store: 'adminUsers',
    name: 'adminUsers',
    saveFunc: saveAdminUser,
})
