import { call } from '@advanza/api'
import { InputField, SelectSimple } from '@advanza/input'
import { Button, Divider, Icon, LinearLoader, LoadingDots, MaxWidth, PreIcon } from '@advanza/ui'
import Avatar from 'components/adminUsers/Avatar'
import { getActiveStatus } from 'components/providers/_providerActiveStatus'
import ServicesSelect from 'components/services/ServicesSelect'
import Page from 'components/ui/Page'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { useCurrentCountry, useUrlQuery } from 'hooks/miscHooks'
import React, { useEffect, useState } from 'react'
import style from './MatchingDebugger.module.css'
import auroraStyle from 'css/aurora.module.css'

function getDataStats(providers) {
    let labels = []
    let projects = []
    let licences = []
    let brochures = []
    const uniqueLicences = []

    for (const key in providers) {
        const pro = providers[key]
        labels = labels.concat(pro.providers_labels)
        projects = labels.concat(pro.profile_projects)
        brochures = brochures.concat(pro.brochures)
        pro.licences.forEach((licence) => {
            if (licences.filter((l) => l.licence_id === licence.licence_id).length === 0) {
                uniqueLicences.push(licence)
            }
        })
        licences = licences.concat(pro.licences)
    }
    return {
        nrLabels: labels.filter(Boolean).length,
        nrLicences: licences.filter(Boolean).length,
        nrBrochures: brochures.filter(Boolean).length,
        nrProjects: projects.filter(Boolean).length,
        uniqueLicences: uniqueLicences.length,
        size:
            ((encodeURI(JSON.stringify(providers)).split(/%..|./).length - 1) / 1024).toFixed(0) +
            'kb',
    }
    // return providers
}

function getLastOrderByPart(sqlQuery) {
    const orderByRegex = /.*\bORDER BY\b(.+?)LIMIT/
    const matches = sqlQuery.match(orderByRegex)
    if (matches && matches.length > 1) {
        return matches[1].trim()
    } else {
        return ''
    }
}
function useMatchingDebugger(query) {
    const [results, setResults] = useState(false)
    const [timer, setTimer] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const countryCode = useCurrentCountry()

    useEffect(() => {
        if (query.service) {
            setIsLoading(true)
            const start = Date.now()
            const options = { ...query }
            const filters = query.filters || {}
            if (query._mode === 'wizard') {
                options.isRequestForm = 1
            } else if (query._mode === 'results') {
                filters._simulateMatches = true
            }
            call(`services/find-providers/${query.service}/${query.city || 'nederland'}/1/`, {
                query: {
                    ...options,
                    countryCode,
                    filters: btoa(JSON.stringify(filters)),
                },
            })
                .then((response) => {
                    setTimer(Date.now() - start)
                    const sql = response.sql
                    const order =
                        sql &&
                        getLastOrderByPart(sql)
                            .split(',')
                            .map((part) => part.trim())
                    setResults({ ...response, order })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [query])

    const dataStats = getDataStats(results.providers)
    return {
        results,
        dataStats,
        timer,
        isLoading,
    }
}

const MatchingDebugger = () => {
    const { query = {}, onSetQuery } = useUrlQuery()
    const [customOrder, setCustomOrder] = useState(false)
    const { isLoading, results, timer, dataStats } = useMatchingDebugger(query)
    const pros = (results && results.providers) || []
    const totalQueries = results.queries && results.queries.length
    const countryCode = useCurrentCountry()
    const totalQueryTime =
        results.queries &&
        results.queries.reduce((a = 0, b) => {
            return a + b.took
        }, 0)
    return (
        <Page title="🐴">
            {isLoading && <LinearLoader fixed />}
            <Divider xxl />
            <MaxWidth maxWidth={1000} className={style.root}>
                <h2>Matching debugger 9000</h2>
                <Divider l />
                <div className={style.settingsRow}>
                    <ServicesSelect
                        multiple={false}
                        returnObject
                        value={query.serviceId}
                        onChangeValue={(values) => {
                            onSetQuery({
                                serviceId: values[0].service_id,
                                service: values[0].url,
                            })
                        }}
                    />
                    <SearchLocationInput
                        value={query.city}
                        placeholder="City"
                        countryCode={countryCode}
                        onChange={(e) => onSetQuery({ city: e.target.value })}
                    />
                    <SelectSimple
                        options={[
                            { name: 'Landing page', value: 'landingpage' },
                            { name: '/results page', value: 'results' },
                            { name: 'Wizard', value: 'wizard' },
                        ]}
                        value={query._mode || 'landingpage'}
                        onChange={(e) => onSetQuery({ _mode: e.target.value })}
                    />
                    <SelectSimple
                        options={[
                            { name: '10', value: 10 },
                            { name: '15', value: 15 },
                            { name: '20', value: 20 },
                            { name: '50', value: 50 },
                            { name: '999 🙀', value: 999 },
                        ]}
                        value={query.limit || 10}
                        onChange={(e) => onSetQuery({ limit: e.target.value })}
                    />
                    <Button
                        name="text"
                        onClick={() => {
                            setCustomOrder(customOrder === false ? results.order.join('\n') : false)
                            if (customOrder !== false) {
                                onSetQuery({ _order: '' })
                            }
                        }}>
                        custom order
                    </Button>
                </div>
                <Divider l />
                {isLoading ? (
                    <LoadingDots />
                ) : (
                    <div>
                        {results.count} results in {(timer / 1000).toFixed(2)} seconds
                        <Divider m />
                    </div>
                )}
                <div className={[style.resultsRow, isLoading ? style.loading : ''].join(' ')}>
                    <table>
                        <tbody>
                            {pros.map((provider, i) => {
                                const activeStatus = getActiveStatus({
                                    ...provider,
                                    postal_code_id: 1,
                                })
                                let bgColor = 'transparent'
                                if (provider.quality_score_total <= 0.75) {
                                    bgColor = 'rgba(250,0,0,0.12)'
                                }
                                return (
                                    <tr
                                        className={provider.activeBooster ? auroraStyle.aurora : ''}
                                        style={{ backgroundColor: bgColor }}
                                        key={provider.service_provider_id}>
                                        <td>
                                            <h6>{i + 1}.</h6>
                                        </td>
                                        <td title={activeStatus.title}>
                                            {provider.slow_response ? (
                                                <Icon name="elderly_woman" fontSize={12} />
                                            ) : (
                                                <Icon
                                                    fontSize={20}
                                                    name={activeStatus.icon}
                                                    style={{ color: activeStatus.color }}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            <Avatar
                                                sizePx={25}
                                                useLetterColor
                                                user={provider.user}
                                            />
                                        </td>
                                        <td>
                                            {provider.business_name}{' '}
                                            {provider.isAdvertiser && (
                                                <img
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        paddingLeft: 3,
                                                    }}
                                                    src="https://static.trustoo.nl/icon/premium.svg"
                                                    width="16"
                                                />
                                            )}
                                            {provider.claimed && (
                                                <img
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        paddingLeft: 3,
                                                    }}
                                                    src="https://static.trustoo.nl/icon/claimed.svg"
                                                    width="16"
                                                />
                                            )}
                                            {provider.freeLimitReached && (
                                                <Icon
                                                    name="cruelty_free"
                                                    color="pink"
                                                    fontSize={16}
                                                />
                                            )}
                                            {provider._goodResponse === 1 && (
                                                <Icon name="sports_gymnastics" fontSize={16} />
                                            )}
                                        </td>
                                        <td title="Is local">
                                            {provider.isLocal && (
                                                <Icon name="near_me" fontSize={21} />
                                            )}
                                        </td>
                                        <td title="Trustoo score">
                                            <b>{(provider.total_score * 10).toFixed(1)}</b>
                                        </td>
                                        <td title="response rate">
                                            <span>
                                                {provider._responseRate}
                                                {provider._responseRate ? '%' : ''}
                                            </span>
                                        </td>
                                        {provider._qs && (
                                            <td
                                                title={`Quality score (${provider.quality_score_total}) + Trustoo score ${provider.total_score}`}>
                                                <span>
                                                    (
                                                    {(
                                                        ((parseFloat(provider.quality_score_total) +
                                                            parseFloat(provider.total_score)) /
                                                            2) *
                                                        10
                                                    ).toFixed(1)}
                                                    )
                                                </span>
                                            </td>
                                        )}
                                        {provider.distance !== undefined && (
                                            <td title="distance">
                                                {parseFloat(provider.distance).toFixed(0)}km
                                            </td>
                                        )}
                                        {provider._isExtra && (
                                            <td title="Extra local result">+ local</td>
                                        )}
                                        {provider.isSlow === 1 && (
                                            <td title="Elderly woman">
                                                <Icon name="elderly_woman" />
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div>
                        {customOrder !== false && (
                            <InputField
                                textArea
                                value={customOrder}
                                onChange={(e) => setCustomOrder(e.target.value)}
                                inputStyle={{ fontSize: 12 }}
                                onKeyDown={(e) =>
                                    (e.ctrlKey || e.metaKey) &&
                                    e.key === 'Enter' &&
                                    onSetQuery({ _order: customOrder })
                                }
                            />
                        )}
                        {results.shouldDoSecondSort && (
                            <PreIcon icon="sort">
                                <b title="Sorted results again in PHP">Second sort</b>
                            </PreIcon>
                        )}
                        {results.notEnoughLocalPros && (
                            <PreIcon icon="rule">
                                <b title="Less than 2 kicak results">Not enough local pros</b>
                            </PreIcon>
                        )}
                        {pros.filter((pro) => pro._isExtra).length > 0 && (
                            <PreIcon icon="add">
                                <b>
                                    <em>{pros.filter((pro) => pro._isExtra).length}</em> Extra
                                    locals added
                                </b>
                            </PreIcon>
                        )}
                        <Divider xxl name="border" />
                        <pre>
                            Total query time: {totalQueryTime}
                            <br />
                            nr queries = {totalQueries}
                            <br />
                            {JSON.stringify(dataStats, null, 2)}
                        </pre>
                        <b>Order:</b>
                        <pre className={style.order}>
                            {results.order &&
                                results.order.map((part) => {
                                    return <div key={part}>{part}</div>
                                })}
                        </pre>
                        <Divider m />
                        <b>Options:</b>
                        <pre>
                            {results &&
                                JSON.stringify(
                                    {
                                        ...results,
                                        // providers: '',
                                        sql: null,
                                        queries: null,
                                    },
                                    null,
                                    2
                                )}
                        </pre>
                    </div>
                </div>
                <Divider l />
                <b>Raw sql</b>
                <Divider m />
                <code>
                    <pre>
                        {results.queries &&
                            JSON.stringify(
                                results.queries.map((query) => {
                                    return {
                                        ...query,
                                        params: JSON.stringify(query.params, null, 2),
                                    }
                                }),
                                null,
                                2
                            )}
                    </pre>
                </code>
            </MaxWidth>
            <Divider xxl />
        </Page>
    )
}

export default MatchingDebugger
