import React from 'react'
import Loadable from 'react-loadable'
import LinearLoader from './LinearLoader'

const ReactCrop = Loadable({
    loading: () => <LinearLoader fixed />,
    loader: () => import('react-image-crop'),
})
const reactCropCss = Loadable({
    loading: () => <LinearLoader fixed />,
    loader: () => import('../css/reactCrop.css'),
})

class ReactCropLoader extends React.Component {
    componentDidMount() {
        reactCropCss.preload()
    }

    render() {
        return <ReactCrop {...this.props} />
    }
}

ReactCropLoader.propTypes = {}

export default ReactCropLoader
