import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { call } from '@advanza/api'

export function useEmailStats() {
    const dispatch = useDispatch()
    const { isFetching, emailStats, history } = useSelector((state) => state.closedLoop)
    const [days, setDays] = useState(3)

    const fetchEmailStats = (fetchDays) => {
        const nrOfDays = fetchDays || days
        if (isFetching) {
            return Promise.resolve()
        }
        dispatch({ type: 'EMAIL_STATS_REQUEST' })

        return call(`office/events/get-closed-loop-email-stats/${nrOfDays}`).then((response) => {
            dispatch({ type: 'EMAIL_STATS_RECEIVE', ...response })
            return Promise.resolve()
        })
    }

    const onChangeDays = (e) => {
        setDays(e.target.value)
        fetchEmailStats(e.target.value)
    }

    return {
        days,
        isFetching,
        fetchEmailStats,
        emailStats,
        history,
        onChangeDays,
    }
}
