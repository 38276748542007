import { call } from '@advanza/api'
import { PROFILE_STATUS_ENABLED } from '@advanza/constants'
import { capitalize } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { changeEntity } from '@advanza/redux_entity'
import { Divider, Toggle } from '@advanza/ui'
import HappinessIcon, { happinessColorMap } from 'components/ui/HappinessIcon'
import { useProvider } from 'hooks/providerHooks'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './customerSatisfaction.module.css'

const categoryTitleMap = {
    profile: 'Profile',
    leads: 'Leads received',
    response: 'Response %',
    contact: 'Chosen & reclaims',
    invoices: 'Invoices',
}

// noinspection JSUnusedGlobalSymbols
const customFieldValueDisplay = {
    profile_total_score: (val: any) => val * 10,
    profile_status: (val: any) => (val === PROFILE_STATUS_ENABLED ? 'Enabled' : 'Other'),
}

export const useCustomerSatisfaction = (providerId: number) => {
    const dispatch = useDispatch()
    const { provider = {} } = useProvider(providerId)
    const { satisfactionRuleCategories: { [1]: satisfactionRuleCategories = undefined } = {} } =
        useSelector(
            /*@ts-ignore*/
            (state) => state.providers.entities
        )

    const setIsLoading = (isLoading: boolean) =>
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'providers',
                key: providerId,
                diff: { _isLoadingSatisfaction: isLoading },
            })
        )

    const setScore = (score: any) =>
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'providers',
                key: providerId,
                diff: { last_satisfaction_score: score },
            })
        )

    const getSatisfactionRuleCategories = () => {
        if (satisfactionRuleCategories) {
            return Promise.resolve()
        }

        return call('office/providers/get-satisfaction-rule-categories').then(
            ({ satisfactionRuleCategories }: { satisfactionRuleCategories: any }) =>
                dispatch(
                    changeEntity({
                        store: 'providers',
                        name: 'satisfactionRuleCategories',
                        key: 1,
                        diff: satisfactionRuleCategories,
                    })
                )
        )
    }

    const getHappinessCalculation = () => {
        getSatisfactionRuleCategories()

        if (!provider.last_satisfaction_score) {
            return recalculateHappiness()
        } else {
            if (!provider.last_satisfaction_score.resultsObj) {
                setIsLoading(true)
                return call('office/providers/get-happiness-calculation', {
                    json: provider.last_satisfaction_score,
                }).then(({ score }: { score: any }) => {
                    setScore(score)
                    setIsLoading(false)
                })
            } else {
                return Promise.resolve()
            }
        }
    }

    const recalculateHappiness = () => {
        setIsLoading(true)
        return call('office/providers/recalculate-happiness/' + providerId).then(
            ({ score }: { score: any }) => {
                setScore(score)
                setIsLoading(false)
            }
        )
    }

    return {
        satisfactionRuleCategories,
        getHappinessCalculation,
        recalculateHappiness,
    }
}

interface Props {
    providerId: number
}

const CustomerSatisfaction = ({ providerId }: Props) => {
    const { provider = {} } = useProvider(providerId)
    const { satisfactionRuleCategories = {}, getHappinessCalculation } =
        useCustomerSatisfaction(providerId)

    return Object.keys(categoryTitleMap).map((categoryName, i) => (
        <Fragment key={categoryName}>
            {0 < i && <Divider sm />}
            <Toggle
                extraClasses={[style.toggle]}
                extraTitleClasses={[style.toggleTitle]}
                disabled={provider._isLoadingSatisfaction}
                onBeforeOpen={() => getHappinessCalculation()}
                showIcon
                title={
                    <Row between="xs" middle="xs">
                        <Col x>
                            {categoryTitleMap[categoryName as keyof typeof categoryTitleMap]}
                        </Col>
                        <Col>
                            <HappinessIcon
                                happiness={
                                    provider.last_satisfaction_score?.[categoryName + '_happiness']
                                }
                            />
                        </Col>
                    </Row>
                }>
                <Divider sm />
                {Object.entries(satisfactionRuleCategories[categoryName] || {}).map(
                    ([ruleName, rule]: [string, any]) => {
                        let hasExplicitOtherCase = false
                        let fields = [ruleName]
                        let boolCompareValues: boolean[] = []

                        const makeReadable = (str: string = '') =>
                            capitalize(
                                str
                                    .replaceAll(categoryName + '_', '')
                                    .replaceAll('_', ' ')
                                    .replaceAll('perc', '%')
                            )

                        const displayBool = (bl: boolean) => (bl ? 'Yes' : 'No')

                        const displayOtherCase = (index: number, caseScore: number) =>
                            fields.length === 1 && boolCompareValues.length === 2
                                ? null
                                : displayCaseCol(
                                      index,
                                      caseScore,
                                      fields.length === 1 && boolCompareValues.length === 1
                                          ? displayBool(!boolCompareValues[0])
                                          : 'Other'
                                  )

                        const displayCaseCol = (
                            index: number,
                            caseScore: number,
                            content: React.ReactNode
                        ) => {
                            const selected =
                                provider.last_satisfaction_score?.resultsObj?.[categoryName]?.[
                                    ruleName
                                ] === index
                            const color = happinessColorMap[(Math.sign(caseScore) + 2) as 1 | 2 | 3]

                            return (
                                <Col
                                    key={index}
                                    className={[style.caseCol, selected ? style.selected : ''].join(
                                        ' '
                                    )}
                                    /*@ts-ignore*/
                                    style={selected ? { color } : undefined}
                                    title={'score = ' + caseScore}
                                    x>
                                    {/*// @ts-ignore*/}
                                    {content}
                                </Col>
                            )
                        }

                        return (
                            <Row key={ruleName} className={style.ruleRow}>
                                <Col xs={3}>{makeReadable(ruleName)}</Col>
                                <Col className={style.grow} x>
                                    <Row className={style.casesRow}>
                                        {rule.map((caseArr: any, i: number) => {
                                            const field = caseArr.at(-4)
                                            const operator = caseArr.at(-3)
                                            const compareValue = caseArr.at(-2)
                                            const caseScore = caseArr.at(-1)
                                            const fieldOrRuleName = field || ruleName

                                            if (field && !fields.includes(field)) {
                                                fields.push(field)
                                            }

                                            const displayOperator =
                                                !field && operator === '=' ? '' : operator
                                            const isExplicitOtherCase = compareValue === undefined

                                            if (isExplicitOtherCase) {
                                                hasExplicitOtherCase = true
                                            }

                                            let displayCompareValue = compareValue

                                            if (typeof compareValue === 'boolean') {
                                                displayCompareValue = displayBool(compareValue)

                                                if (!boolCompareValues.includes(compareValue)) {
                                                    boolCompareValues.push(compareValue)
                                                }
                                            } else if (compareValue === null) {
                                                displayCompareValue = '?'
                                            } else if (fieldOrRuleName in customFieldValueDisplay) {
                                                displayCompareValue =
                                                    customFieldValueDisplay[
                                                        fieldOrRuleName as keyof typeof customFieldValueDisplay
                                                    ](compareValue)
                                            }

                                            return isExplicitOtherCase
                                                ? displayOtherCase(i, caseScore)
                                                : displayCaseCol(
                                                      i,
                                                      caseScore,
                                                      <>
                                                          {makeReadable(field)} {displayOperator}{' '}
                                                          {displayCompareValue}
                                                      </>
                                                  )
                                        })}
                                        {!hasExplicitOtherCase && displayOtherCase(-1, 0)}
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                )}
            </Toggle>
        </Fragment>
    ))
}

export default CustomerSatisfaction
