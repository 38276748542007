import { Button, Divider, Icon, LoadingDots } from '@advanza/ui'
import React from 'react'
import { saveNote } from '../../actions/notes'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import EntityComponent from '../services/EntityComponent'
import style from './noteListItem.module.css'
import AutoRow from 'components/misc/AutoRow'

class NoteListItemEdit extends EntityComponent {
    editFields() {
        const { entity: note } = this.props
        const icons = [
            'sentiment_satisfied_alt',
            'sentiment_very_dissatisfied',
            'sentiment_neutral',
            'warning',
        ]
        return {
            note: {
                textArea: true,
                onKeyPress: ({ key, ctrlKey }) => {
                    ctrlKey && key === 'Enter' && this.save()
                },
                validator: (val) => val.length > 1,
                msg: 'Your comment',
                autoFocus: true,
            },
            class: {
                type: 'select',
                className: 'select-min select-stealth',
                options: icons.map((icon) => ({
                    value: icon,
                    title: <Icon name={icon} />,
                })),
                placeholder: <Icon>sentiment_satisfied</Icon>,
                value: note.class || ' ',
            },
        }
    }

    render() {
        const { entity: note, cancel } = this.props
        const loading = note._saving
        return (
            <div>
                <div className={style.textArea}>{this.renderInput('note')}</div>
                <Divider />
                <AutoRow>
                    {this.renderInput('class')}
                    <div className="to-right">
                        <button className={style.cancelButton} onClick={cancel}>
                            <Icon name="cancel" />
                        </button>
                        <Button onClick={this.save}>{loading ? <LoadingDots /> : 'Save'}</Button>
                    </div>
                </AutoRow>
                <Divider l />
            </div>
        )
    }
}


export default EntityComponentContainer(NoteListItemEdit, {
    store: 'notes',
    name: 'notes',
    saveFunc: saveNote
})
