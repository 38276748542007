import React from "react"
import Section from "../src/Section"
import PropTypes from "prop-types"


const Col = (props) => {
    const {np} = props
    let classString = ""

    if (props.c) {
        classString += ' col-' + props.c
        if (props.c === 'auto') {
            classString += " col"
        }
    } else {
        classString += ' col'
    }

    if (np) {
        classString += ' np'
    }
    const sectionProps = {...props, className: (props.className || '') + ' ' + classString}
    return (
        <Section {...sectionProps}>
            {props.children}
        </Section>
    )
}

Col.propTypes = {
    np       : PropTypes.bool,
    c        : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    className: PropTypes.string
}

export {Col}

