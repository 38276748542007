import { call } from '@advanza/api'
import { InputField } from '@advanza/input'
import { LinearLoader, LoadingDots, Divider } from '@advanza/ui'
import React, { useState, useEffect } from 'react'
import style from './listMailTemplates.module.css'

function useTemplates() {
    const [templates, setTemplates] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (!templates.length && !isLoading) {
            setIsLoading(true)
            call('office/mails/get-templates')
                .then(({ templates }) => setTemplates(templates))
                .finally(() => setIsLoading(false))
        }
    }, [])

    const filtered = query
        ? templates.filter(({ name, versions }) => {
              const q = query && query.toLowerCase()
              const activeVersion = versions.filter(({ active }) => active === 1)[0]
              if (!activeVersion) {
                  return false
              }
              if (q) {
                  const string = name + activeVersion.subject
                  return string.toLowerCase().indexOf(q) !== -1
              }
          })
        : templates

    return { templates: filtered, query, setQuery, isLoading }
}

export default ({ onSelect }) => {
    const { templates, isLoading, setQuery, query } = useTemplates()

    return (
        <div className={style.root}>
            {isLoading && <LinearLoader fixed />}
            {isLoading && <LoadingDots />}
            <div className={style.overview}>
                <InputField
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="search"
                    hidePlaceholder
                    autoFocus
                    value={query || ''}
                />
                <Divider l />
                {templates.map((template, i) => {
                    const activeVersion =
                        template.versions.filter(({ active }) => active === 1)[0] || {}
                    return (
                        <div
                            key={i}
                            className={style.item}
                            onClick={() => onSelect([activeVersion, template])}>
                            <img src={activeVersion.thumbnail_url} width={100} />
                            <div>
                                <b>{template.name}</b>
                                <br />
                                {activeVersion.subject}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
