import { Col, Row } from '@advanza/grid'
import { InputField, SelectCheckbox } from '@advanza/input'
import { Divider, Icon, PreIcon } from '@advanza/ui'
import DatePicker from 'components/DatePicker'
import Pagination from 'components/Pagination'
import RequestStatusSelect from 'components/requests/RequestStatusSelect'
import Restricted from 'components/Restricted'
import ServicesSelect from 'components/services/ServicesSelect'
import RequestFilterContainer from 'containers/RequestFilterContainer'
import { localToUTC, SQL_DATE_TIME_FORMAT_FNS, utcToLocal } from 'date'
import { useDidMount } from 'hooks/miscHooks'
import PropTypes from 'prop-types'
import queryString from 'querystring'
import React, { Fragment, useEffect, useState } from 'react'

const ClosedLoopRequestsFilter = ({
    countries,
    filterId,
    changeFilter,
    defaultOptions = {},
    filter,
    count,
    countOnPage,
    compact,
}) => {
    const didMount = useDidMount()
    const [query, setQuery] = useState('')
    const _changeFilter = (options) => {
        changeFilter(filterId, options)
    }
    const onServiceIdsChange = (values) => {
        _changeFilter({ serviceIds: values, page: 0 })
    }
    const onFlagsChange = (values) => {
        _changeFilter({ flags: values, page: 0 })
    }
    const onRequestStatusChange = (values) => {
        _changeFilter({ statusArray: values, page: 0 })
    }
    const onQueryChange = (e) => {
        const { value } = e.target
        setQuery(value)
        _changeFilter({ query: value, page: 0 })
    }
    const onDateChange = (e) => {
        const { name, value } = e.target
        const dateTimeLocalStr =
            value.split(' ')[0] + ' ' + (name == 'startDate' ? '00:00:00' : '23:59:59')
        _changeFilter({ [name]: localToUTC(dateTimeLocalStr, SQL_DATE_TIME_FORMAT_FNS), page: 0 })
    }
    const onPageChange = (page, invalidate = false) => {
        _changeFilter({ page, didInvalidate: invalidate })
    }
    const onCountList = () => {
        _changeFilter({ countList: true, didInvalidate: true })
    }
    const invalidate = () => {
        _changeFilter({ didInvalidate: true, page: 0 })
    }

    useEffect(() => {
        if (!filter) {
            _changeFilter({
                ...defaultOptions,
                countries,
            })
        } else if (filter.source !== 'closed-loop') {
            _changeFilter({
                ...filter,
                countries,
                source: 'closed-loop',
                pageSize: defaultOptions.pageSize,
                didInvalidate: true,
                page: 0,
            })
        }
    }, [])

    useEffect(() => {
        if (didMount) {
            _changeFilter({ countries })
        }
    }, [countries])

    const { protocol, hostname } = window.location

    return (
        <Fragment>
            {!filter || compact ? null : (
                <Fragment>
                    <Row middle="xs">
                        <Col x>
                            <h2>Closed loop - Requests</h2>
                        </Col>
                        <Col x style={{ marginLeft: 'auto' }}>
                            <Restricted location="export" checkWrite={false}>
                                <PreIcon name="exit_to_app">
                                    <a
                                        target="_blank"
                                        href={`${protocol}//${hostname}/api/office/requests/export-closed-loop/?${queryString.stringify(
                                            filter
                                        )}`}>
                                        Export
                                        <br />
                                        <small>(light version)</small>
                                    </a>
                                </PreIcon>
                            </Restricted>
                        </Col>
                    </Row>
                    <Divider m />
                    <Row middle="xs">
                        <Col x>
                            <ServicesSelect
                                large
                                onChangeValue={onServiceIdsChange}
                                value={filter.serviceIds || []}
                            />
                        </Col>
                        <Col x>
                            <SelectCheckbox
                                large
                                options={[
                                    { value: 'valid', name: 'Valid requests' },
                                    { value: 'organic', name: 'Organic requests' },
                                    { value: 'adwords', name: 'Google paid requests' },
                                    { value: 'all', name: 'All Requests' },
                                    { value: 'zeros', name: 'Without matches' },
                                    { value: 'invalid', name: 'Invalid Requests' },
                                    { value: 'demandShouldConfirm', name: 'Demand should confirm' },
                                    { value: 'reclaims', name: 'Reclaims' },
                                    { value: 'affiliate', name: 'Affiliate requests' },
                                    { value: 'deleted', name: 'Deleted requests' },
                                ]}
                                onChangeValue={onFlagsChange}
                                value={filter.flags || []}
                                placeholder="all requests"
                            />
                        </Col>
                        <Col x>
                            <RequestStatusSelect
                                large
                                onChangeValue={onRequestStatusChange}
                                value={filter.statusArray || []}
                            />
                        </Col>
                        <Col x>
                            <InputField
                                icon="search"
                                placeholder="search"
                                value={query || filter.query}
                                onChange={onQueryChange}
                            />
                        </Col>
                        <Col>
                            <DatePicker
                                onChange={onDateChange}
                                dayPickerProps={{
                                    disabled: (date) => date > new Date(),
                                    showOutsideDays: false,
                                }}
                                value={utcToLocal(filter.startDate, SQL_DATE_TIME_FORMAT_FNS)}
                                name="startDate"
                            />
                        </Col>
                        <Col x>
                            <DatePicker
                                onChange={onDateChange}
                                dayPickerProps={{ disabled: (date) => date > new Date() }}
                                value={utcToLocal(filter.endDate, SQL_DATE_TIME_FORMAT_FNS)}
                                name="endDate"
                            />
                        </Col>
                        <Col x>
                            <button onClick={invalidate}>
                                <Icon name="refresh" />
                            </button>
                        </Col>
                        <Col x style={{ marginLeft: 'auto' }}>
                            <Pagination
                                current={filter.page || 0}
                                pageSize={filter.pageSize}
                                count={count}
                                countOnPage={countOnPage}
                                onCountList={onCountList}
                                onPageChange={onPageChange}
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    )
}

ClosedLoopRequestsFilter.propTypes = {
    countries: PropTypes.array,
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    compact: PropTypes.bool,
    changeFilter: PropTypes.func,
    count: PropTypes.number,
    countOnPage: PropTypes.number,
}

export default RequestFilterContainer(ClosedLoopRequestsFilter)
