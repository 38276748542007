import { call } from '@advanza/api'
import { Button, LoadingDots, PreIcon } from '@advanza/ui'
import React, { useState } from 'react'

const MonthlyReportData = ({ providerId }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [debugData, setDebugData] = useState({})

    const getMonthlyReportData = () => {
        if (isFetching) {
            return false
        }
        setIsFetching(true)
        return call(`office/providers/get-monthly-report-data/${providerId}`).then((response) => {
            setIsFetching(false)
            setHasFetched(true)
            setDebugData(response.data)
        })
    }

    return (
        <div>
            <Button name="text" onClick={() => getMonthlyReportData(providerId)}>
                {isFetching ? (
                    <LoadingDots />
                ) : (
                    <PreIcon primColor icon="search">
                        View Monthly Report Data (basic profile only)
                    </PreIcon>
                )}
            </Button>
            {hasFetched && (
                <div>
                    <pre>{JSON.stringify(debugData, null, 2)}</pre>
                </div>
            )}
        </div>
    )
}

export default MonthlyReportData
