import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { changeEntity, changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    CANCELLATIONS_RECEIVE_LIST: receiveList,
    CANCELLATIONS_CHANGE_FILTER: changeFilter,
    CHANGE_ENTITY_CANCELLATIONS: changeEntity,
    INVALIDATE_CANCELLATIONS: invalidateFilters,
    CANCELLATIONS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    CANCELLATIONS_CHANGE_EXTRA: (state, action) =>
        update(state, {
            extra: { $vivMerge: action.extra },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    counts: {},
    entities: {},
    result: {},
    filters: {},
    extra: {},
}

export default createReducer(initialState, handlers)
