import { schema } from 'normalizr'

const options = new schema.Entity('options', {}, { idAttribute: 'option_id' })
const geoIds = new schema.Entity('geoIds', {}, { idAttribute: 'geo_id' })
const municipalities = new schema.Entity('municipalities', {}, { idAttribute: 'municipality_id' })

const subscriptions = new schema.Entity(
    'subscriptions',
    {
        subscriptions_options: [options],
        subscriptions_geoids: [geoIds],
        subscriptions_municipalities: [municipalities],
        service: new schema.Entity(
            'services',
            {
                questions: [
                    new schema.Entity(
                        'questions',
                        {
                            options: [
                                new schema.Entity('options', {}, { idAttribute: 'option_id' }),
                            ],
                        },
                        { idAttribute: 'question_id' }
                    ),
                ],
            },
            { idAttribute: 'service_id' }
        ),
    },
    { idAttribute: 'subscription_id' }
)

export default [subscriptions]
