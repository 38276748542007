import { Divider } from '@advanza/ui'
import RegionsList from 'components/regions/RegionsList'
import Page from 'components/ui/Page'
import React from 'react'

const RegionsPage = () => {
    return (
        <Page title="Regions">
            <Divider />
            <RegionsList />
        </Page>
    )
}

export default RegionsPage
