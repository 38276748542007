import { Col, Row, APP } from '@advanza/advanza_generic'
import { Divider, Icon } from '@advanza/ui'
import React from 'react'
import style from './MatchViewStyle.module.css'

const MatchTimeLine = ({ timeLine }) => {
    const renderItem = (item) => {
        if (item.message_id || item.log_id) {
            if (item.type || item.log_id) {
                return (
                    <div className={style.message}>
                        {item.log_id ? item.log_type : `${item.type} 'Message'`}
                    </div>
                )
            } else if (item.files && item.files.length) {
                return item.files.map((file) => {
                    const urlBase =
                        file.bucket.indexOf('private') !== -1 ? APP.S3_PRIVATE() : APP.S3_PUBLIC()
                    return (
                        <div key={file.file_id} className={style.message}>
                            <p>
                                {' '}
                                {item.body.split('\n').map((item, key) => {
                                    if (item.length === 0) {
                                        return <br key={key} />
                                    } else {
                                        return (
                                            <span key={key}>
                                                {item}
                                                <br />
                                            </span>
                                        )
                                    }
                                })}
                            </p>
                            <small>Attachment</small>
                            <br />
                            <a target="_blank" href={`${urlBase}${file.file_key}`}>
                                <Icon name="cloud_download" /> {file.filename}
                            </a>
                        </div>
                    )
                })
            }
            return (
                <div className={[style.message, item.is_pro ? style.mine : ''].join(' ')}>
                    {item.body.split('\n').map((item, key) => {
                        if (item.length === 0) {
                            return <br key={key} />
                        } else {
                            return (
                                <span key={key}>
                                    {item}
                                    <br />
                                </span>
                            )
                        }
                    })}
                </div>
            )
        } else {
            return <div className={style.checkpoint}>{item.label || item.msg}</div>
        }
    }
    return (
        <div>
            {timeLine.map((item, i) => {
                return (
                    <Row
                        key={i}
                        className={
                            item.is_bot || item.log_id
                                ? 'j-center center'
                                : item.is_pro
                                ? 'tright'
                                : ''
                        }>
                        <Col c={6}>
                            <div>
                                <small className={style.msgTime}>{item.deltaReadable} later</small>
                            </div>
                            {renderItem(item)}
                            <Divider m />
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default MatchTimeLine
