import { call } from '@advanza/api'
import notesSchema from '../schemes/notesSchema'
import { changeEntity } from './entities'
import { _changeFilter, _fetchItems, _preLoadFilter } from './lists'

export const NOTES_REQUEST = 'NOTES_REQUEST'
export const SENDING_NOTE = 'NOTES_SENDING_NOTE'
export const NOTE_SENT = 'NOTE_SENT'
export const NOTE_ADD = 'NOTE_ADD'
export const NOTES_RECEIVE_LIST = 'NOTES_RECEIVE_LIST'
export const NOTES_CHANGE_FILTER = 'NOTES_CHANGE_FILTER'
export const NOTES_INVALIDATE = 'NOTES_INVALIDATE'
export const NOTES_DISCARD_TOUCHED = 'NOTES_DISCARD_TOUCHED'
export const NOTE_TOUCH = 'NOTE_TOUCH'

export function fetchNotes(filterId) {
    return _fetchItems(filterId, {
        url: 'office/notes/get-list',
        schema: notesSchema,
        requestActionName: NOTES_REQUEST,
        receiveActionName: NOTES_RECEIVE_LIST,
        reducerKey: 'notes',
    })
}

export function changeNoteFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'notes',
        fetchFunc: fetchNotes,
        actionType: NOTES_CHANGE_FILTER,
    })
}

export function preLoadNotes(filterId, options, response) {
    return _preLoadFilter(filterId, options, response, {
        receiveActionName: NOTES_RECEIVE_LIST,
        reducerKey: 'notes',
        schema: notesSchema,
    })
}

export function saveNote(noteId) {
    return function (dispatch, getState) {
        const note = getState().notes.entities.notes && getState().notes.entities.notes[noteId]
        const isNew = typeof noteId === 'string'
        dispatch({ type: SENDING_NOTE })
        const data = {
            ...note,
            user: null,
        }
        return call(`office/notes/add/${isNew ? '' : noteId}`, {
            payload: data,
        }).then((response) => {
            dispatch(invalidateNotes())
        })
    }
}

export function addNote(options, filterId) {
    return function (dispatch) {
        const newNoteId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                diff: {
                    note_id: newNoteId,
                    _open: true,
                    user: window._user,
                    note: '',
                    class: ' ',
                    ...options,
                },
                key: newNoteId,
                filterId,
                name: 'notes',
                store: 'notes',
            })
        )
    }
}

export function invalidateNotes(filterId) {
    return function (dispatch) {
        if (filterId) {
            dispatch(changeNoteFilter(filterId, { didInvalidate: true }))
        } else {
            dispatch({ type: 'INVALIDATE_NOTES' })
        }
    }
}
