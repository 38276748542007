import React from 'react'
import { connect } from 'react-redux'
import { fetchAdminUsers } from '../actions/adminUsers'

function mapStateToProps(state) {
    const { entities } = state.adminUsers
    const { adminUsers } = entities
    const userIds = adminUsers && Object.keys(adminUsers)
    return {
        userIds,
        adminUsers,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAdminUsers: () => dispatch(fetchAdminUsers()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
