import React from 'react'
import { connect } from 'react-redux'
import {
    addStatus,
    changeSalesLeadStatusesFilter,
    changeStatusesFilter,
    deleteStatusesSelection,
} from '../actions/statuses'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts, entities } = state.statuses
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]

    const { statuses = {} } = entities
    const categories = Object.keys(statuses).map((id) => statuses[id].category)
    const uniqueCategories = Array.from(new Set(categories))
    return {
        filter,
        count,
        isFetching,
        categories: uniqueCategories,
    }
}

function mapDispatchToProps(dispatch, props) {
    const statusType = props.statusType || 'default'
    return {
        changeFilter: (id, options) => dispatch(changeStatusesFilter(statusType, id, options)),
        add: (options, filterId) => dispatch(addStatus(options, filterId)),
        deleteSelection: (filterId) => dispatch(deleteStatusesSelection(filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
