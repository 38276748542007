import { Col, Row } from '@advanza/grid'
import Pagination from 'components/Pagination'
import TableList from 'components/TableList'
import FormattedTime from 'components/ui/FormattedTime'
import RequestsStatusUpdate from 'interfaces/RequestsStatusUpdate'
import { useState } from 'react'
import { objectKeysSpecific } from 'types/helpers'

const PAGE_SIZE = 5

const definitions = {
    created: { name: 'created' },
    status: { name: 'status' },
}

const renderRow = (update: RequestsStatusUpdate) => ({
    id: update.requests_status_update_id,
    cols: renderCols(update),
})

const renderCols = (update: RequestsStatusUpdate) =>
    objectKeysSpecific(definitions).map((key) => {
        switch (key) {
            case 'created':
                return (
                    // @ts-ignore
                    <FormattedTime date={update[key]} format="dd-MM-yyyy HH:mm:ss" />
                )
            default:
                return update[key]
        }
    })

interface Props {
    request: { requests_status_updates: RequestsStatusUpdate[] }
}

const RequestStatusUpdateList = ({ request }: Props) => {
    const [pageNr, setPageNr] = useState(0)
    const pageUpdates = request.requests_status_updates.slice(
        pageNr * PAGE_SIZE,
        (pageNr + 1) * PAGE_SIZE
    )
    const pageRows: { [id: string]: any } = {}
    pageUpdates.forEach((update) => {
        pageRows[update.requests_status_update_id] = renderRow(update)
    })

    return (
        <>
            <Row end="xs" style={{ marginTop: -30 }}>
                <Col x>
                    <Pagination
                        onPageChange={(pageNr) => setPageNr(pageNr)}
                        current={pageNr}
                        count={request.requests_status_updates.length}
                        pageSize={PAGE_SIZE}
                    />
                </Col>
            </Row>
            <TableList
                pages={{ 0: pageUpdates.map((update) => update.requests_status_update_id) }}
                rows={pageRows}
                definitions={definitions}
            />
        </>
    )
}

export default RequestStatusUpdateList
