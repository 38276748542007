import { Row } from '@advanza/grid'
import AnalyticsFilterContainer from 'containers/AnalyticsFilterContainer'
import PropTypes from 'prop-types'
import React from 'react'
import Pagination from '../Pagination'

class AnalyticsFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onCountList = this.onCountList.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    onCountList() {
        this._changeFilter({ countList: true, didInvalidate: true })
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    render() {
        const { filter, count, compact } = this.props
        if (!filter || compact) {
            return null
        }

        const pageNr = filter.page || 0
        return (
            <Row middle="xs" end="xs">
                {(count === null || count > filter.pageSize) && (
                    <div>
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            onCountList={this.onCountList}
                            onPageChange={this.onPageChange}
                        />
                    </div>
                )}
            </Row>
        )
    }
}

AnalyticsFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
}

export default AnalyticsFilterContainer(AnalyticsFilter)
