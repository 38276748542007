import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { Modal } from '@advanza/modal'
import { Button, Divider, HoverText, LoadingDots, PreIcon } from '@advanza/ui'
import { fetchProvider, savePaymentData, VAT_STATES } from 'actions/providers'
import Label from 'components/ui/Label'
import { useChangeEntity } from 'hooks/entityHooks'
import { useProvider } from 'hooks/providerHooks'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import SafeButton from 'components/ui/SafeButton'

const bics = [
    { name: 'ABN Amro - ABNANL2A', value: 'ABNANL2A' },
    { name: 'ABN Amro2 - ABNANL2R', value: 'ABNANL2R' },
    { name: 'Aegon bank - AEGONL2U', value: 'AEGONL2U' },
    { name: 'Argenta Spaarbank - ARSNNL21', value: 'ARSNNL21' },
    { name: 'Allianz Nederland Asset Management - ANAANL21', value: 'ANAANL21' },
    { name: 'Anadolubank - ANDLNL2A', value: 'ANDLNL2A' },
    { name: 'Achmea Bank - ARBNNL22', value: 'ARBNNL22' },
    { name: 'Argenta Spaarbank - ARSNNL21', value: 'ARSNNL21' },
    { name: 'GE Artesia Bank - ARTENL2A', value: 'ARTENL2A' },
    { name: 'ASN BANK - ASNBNL21', value: 'ASNBNL21' },
    { name: 'ASR BANK - ASRBNL2R', value: 'ASRBNL2R' },
    { name: 'Amsterdam Trade Bank - ATBANL2A', value: 'ATBANL2A' },
    { name: 'ING Belgie - BBRUNL2X', value: 'BBRUNL2X' },
    { name: 'Banque Chaabi Du Maroc - BCDMNL22', value: 'BCDMNL22' },
    { name: 'Intesa Sanpaolo - BCITNL2A', value: 'BCITNL2A' },
    { name: 'BinckBank - BICKNL2A', value: 'BICKNL2A' },
    { name: 'Bank of China - BKCHNL2R', value: 'BKCHNL2R' },
    { name: 'Bank Mendes Gans - BKMGNL2A', value: 'BKMGNL2A' },
    { name: 'Bmce Euroservice - BMEUNL21', value: 'BMEUNL21' },
    { name: 'Bank Nederlandse Gemeenten (BNG - BNGHNL2G)', value: 'BNGHNL2G' },
    { name: 'BNP Paribas Fortis - BNPANL2A', value: 'BNPANL2A' },
    { name: 'Bank of America - BOFANLNX', value: 'BOFANLNX' },
    { name: 'Bank of Scotland: , Amsterdam - BOFSNL21002', value: 'BOFSNL21002' },
    { name: 'bunq - BUNQNL2A', value: 'BUNQNL2A' },
    { name: 'C & E Bankiers - CEBUNL2U', value: 'CEBUNL2U' },
    { name: 'Citybank International - CITINL2X', value: 'CITINL2X' },
    { name: 'JPMorgan Chase - CHASNL2X', value: 'CHASNL2X' },
    { name: 'Citco Bank Nederland  - CITCNL2A', value: 'CITCNL2A' },
    { name: 'Commerzbank - COBANL2X', value: 'COBANL2X' },
    { name: 'Deutsche Bank - DEUTNL2N', value: 'DEUTNL2N' },
    { name: 'Demir-Halk Bank  - DHBNNL2R', value: 'DHBNNL2R' },
    { name: 'Delta Lloyd Bank - DLBKNL2A', value: 'DLBKNL2A' },
    { name: 'F. van Lanshot Bankiers - FVLBNL22', value: 'FVLBNL22' },
    { name: 'NIBC Bank - DNIBNL2G', value: 'DNIBNL2G' },
    { name: 'Credit Europe Bank - FBHLNL2A', value: 'FBHLNL2A' },
    { name: 'De Nederlandsche Bank - FLORNL2A', value: 'FLORNL2A' },
    { name: 'Friesland Bank - FRBKNL2L', value: 'FRBKNL2L' },
    { name: 'FGH Bank - FRGHNL21', value: 'FRGHNL21' },
    { name: 'Theodoor Gilissen Bankiers - GILLNL2A', value: 'GILLNL2A' },
    { name: 'Svenska Handelsbanken AB - HANDNL2A', value: 'HANDNL2A' },
    { name: 'Hof Hoorneman Bankiers - HHBANL22', value: 'HHBANL22' },
    { name: 'HSBC BANK - HSBCNL2A', value: 'HSBCNL2A' },
    { name: 'Industrial and Commercial Bank of China - ICBKNL2A', value: 'ICBKNL2A' },
    { name: 'ING bank - INGBNL2A', value: 'INGBNL2A' },
    { name: 'Insinger de Beaufort - INSINL2A', value: 'INSINL2A' },
    { name: 'Yapi Kredi Bank - KABANL2A', value: 'KABANL2A' },
    { name: 'KAS BANK - KASANL2A', value: 'KASANL2A' },
    { name: 'KNAB - KNABNL2H', value: 'KNABNL2H' },
    { name: 'Korea Exchange Bank - KOEXNL2A', value: 'KOEXNL2A' },
    { name: 'KBC Bank - KREDNL2X', value: 'KREDNL2X' },
    { name: 'Lloyds Bank - LOYDNL2A', value: 'LOYDNL2A' },
    { name: 'LeasePlan Corporation - LPLNNL2F', value: 'LPLNNL2F' },
    { name: 'Mizuho Corporate Bank - MHCBNL2A', value: 'MHCBNL2A' },
    { name: 'Nationale-Nederlanden Bank - NNBANL2G', value: 'NNBANL2G' },
    { name: 'Nederlandse Waterschapsbank - NWABNL2G', value: 'NWABNL2G' },
    { name: 'Levob Bank - OVBNNL22', value: 'OVBNNL22' },
    { name: 'Rabobank - RABONL2U', value: 'RABONL2U' },
    { name: 'Royal Bank of Scotland - RBOSNL2A', value: 'RBOSNL2A' },
    { name: 'RegioBank - RBRBNL21', value: 'RBRBNL21' },
    { name: 'SNS Bank - SNSBNL2A', value: 'SNSBNL2A' },
    { name: 'Staalbankiers - STALNL2G', value: 'STALNL2G' },
    { name: 'Triodos Bank - TRIONL2U', value: 'TRIONL2U' },
    { name: 'Garantibank - UGBINL2A', value: 'UGBINL2A' },
    { name: 'Volkswagen Bank - VOWANL21', value: 'VOWANL21' },
    { name: 'ZwitserlevenBank - ZWLBNL21', value: 'ZWLBNL21' },
]

function addValuesFromAutocomplete(changeEntity, autocompleteResult) {
    const components = autocompleteResult.address_components || []
    const addressMap = {
        street_number: { name: 'house_number', key: 'long_name' },
        locality: { name: 'city', key: 'long_name' },
        website: { name: 'city', key: 'long_name' },
        postal_code: { name: 'postal_code', key: 'long_name' },
        route: { name: 'street', key: 'long_name' },
    }
    components.map((component) => {
        const nameAndKey = component.types && addressMap[component.types[0]]
        if (nameAndKey) {
            changeEntity({
                [nameAndKey.name]: component[nameAndKey.key],
            })
        }
    })
    changeEntity({ business_name: autocompleteResult.name })
}

const textValidator = (val) => val && val.length > 0

const vatBEValidator = (val) => !val || (val && val.search(/BE[0-9]{10}$/) !== -1)
const vatDEValidator = (val) => !val || (val && val.search(/DE[0-9]{5,12}$/) !== -1)

function getFields(entity, onChangeEntity) {
    return {
        gender: {
            type: 'select',
            className: 'select-min fsize lg',
            options: [
                { value: 1, title: 'Mr' },
                { value: 2, title: 'Mrs' },
            ],
            validator: (val) => val === 1 || val === 2,
        },
        first_name: { type: 'text', placeholder: 'First Name', validator: textValidator },
        last_name: { type: 'text', placeholder: 'Last name', validator: textValidator },
        business_name: {
            type: 'geo',
            placeholder: 'Business name',
            onPlaceChange: (place) => addValuesFromAutocomplete(onChangeEntity, place),
            onChange: null,
            validator: textValidator,
        },
        street: { type: 'text', placeholder: 'Street', validator: textValidator },
        email: {
            type: 'text',
            placeholder: 'email',
            validator: (val) =>
                !val ||
                val.search(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) !== -1,
        },
        postal_code: { type: 'text', placeholder: 'Postal code', validator: textValidator },
        city: { type: 'text', placeholder: 'City', validator: textValidator },
        country_code: {
            type: 'select',
            className: 'select-min fsize lg',
            placeholder: 'Country',
            options: [
                { value: 'NL', title: 'NL' },
                { value: 'BE', title: 'BE' },
                { value: 'DE', title: 'DE' },
            ],
        },
        house_number: { type: 'text', placeholder: 'House number', validator: textValidator },
        house_number_add: { type: 'text', placeholder: 'Addition' },
        bank_tnv: {
            type: 'text',
            placeholder: 'bank_tnv',
            validator: entity.payment_method === 2 ? () => true : textValidator,
        },
        iban: {
            type: 'text',
            placeholder: 'iban',
            validator: entity.payment_method === 2 ? () => true : textValidator,
        },
        bic: {
            type: 'selectSimple',
            options: bics,
            useSearch: true,
            emptyOption:'-',
            optionKeys: { queryKey: 'title' },
            placeholder: 'Bic',
        },
        vat: {
            type: 'text',
            placeholder: 'vat nr',
            disabled: entity.country_code === 'BE',
            title: entity.country_code === 'BE' ? "Use the kvk field! 🪃" : 'VAT',
            validator: entity.country_code === 'DE' ? vatDEValidator : vatBEValidator,
        },
        vat_state: {
            type: 'select',
            msg: 'VAT',
            options: VAT_STATES.map((title, value) => ({ value, title })),
        },
        mandate_id: { type: 'text', readOnly: true, placeholder: 'Mandate id' },
        sepa_status: { type: 'boolean', msg: 'sepa' },
        payment_method: {
            type: 'select',
            placeholder: 'Payment method',
            options: [
                { value: 1, title: 'Direct debit' },
                { value: 2, title: 'Manual transfer' },
            ],
        },
        use_franchise_billing: {
            type: 'boolean',
            msg: 'Invoice via franchise',
        },
    }
}

const useImportFromAdvanza = (paymentInfo, setErrorModal) => {
    const dispatch = useDispatch()
    const [isImporting, setIsImporting] = useState(false)
    const providerId = paymentInfo.service_provider_id
    const reSendPremiumConfirmationMail = () => {
        setIsImporting('ecurring')
        return call(
            'office/providers/re-send-premium-confirmation-mail/' + paymentInfo.service_provider_id,
            { method: 'post' }
        )
            .then(() => {
                dispatch(fetchProvider(providerId, true))
            }, setErrorModal)
            .finally(() => setIsImporting(false))
    }
    const sendMandateVerificationEmail = () => {
        setIsImporting('send_verification_mail')
        return call(
            'office/providers/re-send-mandate-verification-mail/' + paymentInfo.payment_info_id,
            { method: 'post' }
        ).finally(() => {
            setIsImporting(false)
        })
    }
    const createMandate = (isPreApproved) => {
        setIsImporting('ecurring_' + (isPreApproved ? '1' : '0'))
        return call(
            `office/providers/create-mandate-mollie/${paymentInfo.payment_info_id}/${
                isPreApproved ? 1 : 0
            }`,
            {
                method: 'post',
            }
        )
            .then(() => {
                dispatch(fetchProvider(providerId, true))
            }, setErrorModal)
            .finally(() => setIsImporting(false))
    }
    const createMandatePreApproved = () => createMandate(true)
    const mollieUnsubscribe = (isMarketPlace) => {
        setIsImporting('ecurringCancel')
        return call('office/providers/revoke-mandate-mollie/' + paymentInfo.payment_info_id, {
            method: 'post',
            payload: { isMarketPlace },
        })
            .then(() => {
                dispatch(fetchProvider(providerId, true))
            }, setErrorModal)
            .finally(() => setIsImporting(false))
    }

    return {
        isImporting,
        createMandatePreApproved: createMandatePreApproved,
        createMandate: () => createMandate(false),
        mollieUnsubscribe: mollieUnsubscribe,
        sendMandateVerificationEmail,
        reSendPremiumConfirmationMail,
    }
}

const PaymentInfoEntity = ({ entityId }) => {
    const { validate, entity, onChangeEntity, renderInput, onSaveEntity } = useChangeEntity(
        {
            store: 'providers',
            name: 'paymentInfo',
            entityId,
            saveFunc: savePaymentData,
        },
        getFields
    )
    const [errorModal, setErrorModal] = useState(null)
    const [cancelConfirmModal, setCancelConfirmModal] = useState(null)
    const {
        isImporting,
        mollieUnsubscribe,
        createMandatePreApproved,
        createMandate,
        sendMandateVerificationEmail,
        reSendPremiumConfirmationMail,
    } = useImportFromAdvanza(entity, setErrorModal)
    const saveEntity = () =>
        onSaveEntity().then(
            ({ id, vat_state, vat }) => {
                onChangeEntity({ payment_info_id: id, vat_state, vat })
            },
            (error) => {
                setErrorModal(error)
            }
        )
    const onSave = () => {
        validate() && saveEntity()
    }

    const isDirectDebit = entity.payment_method === 1
    const isNew = `${entity.payment_info_id}`.indexOf('new') === 0
    const { isFranchiseMain, useFranchiseBilling } = useProvider(entity.service_provider_id)
    return (
        <div>
            <Row middle="xs">
                {entity.subscription_status && (
                    <Col x>
                        Status:{' '}
                        <Label
                            text={entity.subscription_status}
                            color={entity.subscription_status !== 'active' ? '#fff' : '#333'}
                            bgColor={
                                entity.subscription_status !== 'active' ? '#d22f2f' : '#d9d3d3'
                            }
                        />
                    </Col>
                )}{' '}
                {entity.subscription_status === 'unverified' && (
                    <Col x>
                        <SafeButton
                            textButton
                            disabled={isImporting === 'send_verification_mail'}
                            action={sendMandateVerificationEmail}
                            buttonText="sendMandateVerificationEmail"
                        />
                    </Col>
                )}
                {entity.external_mandate_id && (
                    <Col x>
                        <button name="delete" onClick={() => setCancelConfirmModal(true)}>
                            <PreIcon icon="delete_forever">
                                <b>Revoke mandate</b>
                            </PreIcon>
                        </button>
                    </Col>
                )}
                {(!entity.external_mandate_id || entity.subscription_status === 'cancelled') && (
                    <Col x>
                        <SafeButton
                            textButton
                            action={createMandatePreApproved}
                            disabled={isImporting}
                            buttonText={
                                isImporting === 'ecurring_1' ? (
                                    <LoadingDots />
                                ) : (
                                    <span>
                                        Create pre-approved mandate (Mollie){' '}
                                        <HoverText>
                                            Create pre-confirmed mandate, no confirmation mail will
                                            be sent.
                                        </HoverText>
                                    </span>
                                )
                            }
                        />
                    </Col>
                )}
                {!entity.external_mandate_id && (
                    <Col x>
                        <SafeButton
                            textButton
                            action={createMandate}
                            disabled={isImporting}
                            buttonText={
                                isImporting === 'ecurring_0' ? (
                                    <LoadingDots />
                                ) : (
                                    'Create mandate (mollie)'
                                )
                            }
                        />
                    </Col>
                )}
                {entity.external_mandate_id && entity.subscription_status === 'active' && (
                    <Col x>
                        <SafeButton
                            textButton
                            action={reSendPremiumConfirmationMail}
                            disabled={isImporting}
                            buttonText={
                                isImporting === 'ecurring' ? (
                                    <LoadingDots />
                                ) : (
                                    '(Re)send premium confirmation mail'
                                )
                            }
                        />
                    </Col>
                )}
            </Row>
            <Divider m />
            <Row middle="xs">
                <Col xs>{renderInput('gender')}</Col>
                <Col xs>{renderInput('first_name')}</Col>
                <Col xs>{renderInput('last_name')}</Col>
            </Row>
            <Divider m />
            {renderInput('business_name')}
            <Divider m />
            {renderInput('email')}
            <Divider m />
            <Row middle="xs">
                <Col xs>{renderInput('street')}</Col>
                <Col xs>{renderInput('house_number')}</Col>
                <Col xs>{renderInput('house_number_add')}</Col>
            </Row>
            <Divider m />
            <Row>
                <Col xs>{renderInput('postal_code')}</Col>
                <Col xs>{renderInput('city')}</Col>
                <Col xs>{renderInput('country_code')}</Col>
            </Row>
            <Divider m />
            {renderInput('iban')}
            <Divider m />
            {renderInput('bank_tnv')}
            <Divider m />
            {renderInput('bic')}
            <Divider m />
            {entity.country_code !== 'NL' && (
                <div>
                    {renderInput('vat_state')}
                    <Divider m />
                    {renderInput('vat')}
                    <Divider m />
                </div>
            )}
            <Row>
                <Col xs>{renderInput('payment_method')}</Col>
                <Col x> {renderInput('sepa_status')}</Col>
            </Row>
            <Divider m />
            {isDirectDebit && renderInput('mandate_id')}
            <Divider m />
            {(isFranchiseMain || useFranchiseBilling) && (
                <Fragment>
                    <div>
                        {isFranchiseMain ? (
                            <span style={{ display: 'inline-block' }}>
                                {renderInput('use_franchise_billing')}
                            </span>
                        ) : useFranchiseBilling ? (
                            <PreIcon green icon="groups">
                                <b style={{ color: 'green' }}>Invoiced via franchise</b>
                            </PreIcon>
                        ) : null}
                    </div>
                    <Divider m />
                </Fragment>
            )}
            <Row end="xs">
                {!entity.mandate_id && !isNew && isDirectDebit && (
                    <Col x>
                        <Button onClick={() => createMandate(entity, onChangeEntity)}>
                            Create mandate
                        </Button>
                    </Col>
                )}
                <Col x>
                    <Button disabled={!entity._isTouched || entity._saving} onClick={onSave}>
                        {entity._saving ? '..saving' : 'Save'}
                    </Button>
                </Col>
            </Row>
            <Modal open={errorModal} close={() => setErrorModal(null)}>
                <pre>{JSON.stringify(errorModal, null, 2)}</pre>
            </Modal>
            <Modal open={cancelConfirmModal} close={() => setCancelConfirmModal(false)}>
                <h3>Cancel & unlink Mollie subscription</h3>
                <p style={{ color: 'red' }}>This action can't be undone</p>
                <Divider m />
                <Button
                    name="delete"
                    onClick={() =>
                        mollieUnsubscribe(cancelConfirmModal === 'marketPlace').then(() =>
                            setCancelConfirmModal(false)
                        )
                    }>
                    <PreIcon
                        red
                        icon={isImporting === 'ecurringCancel' ? 'blender' : 'coronavirus'}>
                        {isImporting === 'ecurringCancel' ? (
                            <LoadingDots color="red" />
                        ) : (
                            'Cancel subscription '
                        )}
                    </PreIcon>
                </Button>
            </Modal>
        </div>
    )
}

export default PaymentInfoEntity
