export enum PauseTypeSet {
    RECEIVE = 0,
    WORK = 1,
    BOTH = 2, // @deprecated
    SNOOZE = 3,
}

export enum DaySchemeSet {
    NO_REPEAT = 0,
    WEEKLY = 1,
    BIWEEKLY = 2,
    MONTHLY = 3,
    YEARLY = 4,
}

export enum PauseErrorSet {
    NONE = 'NONE',
    INVALID_DATE_RANGE = 'INVALID_DATE_RANGE',
    RANGE_LONGER_THAN_MAX_PERIOD = 'RANGE_LONGER_THAN_MAX_PERIOD',
}
