import { SelectCheckbox } from '@advanza/input'
import { getRequestStatusInfo } from 'hooks/requestsHooks'
import React from 'react'

const RequestStatusSelect = ({ filter, value, ...rest }) => {
    const { statusArray } = getRequestStatusInfo()
    const statuses = filter ? statusArray.filter(filter) : statusArray
    const items = statuses.map((status) => {
        return {
            name: status.replace(/_/g, ' '),
            value: status,
        }
    })

    return <SelectCheckbox placeholder="all statuses" {...rest} options={items} value={value} />
}

export default RequestStatusSelect
