import React from 'react'

export function getFlag(countryCode = '') {
    const flags = {
        nl: (
            <svg
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 9 6">
                <path fill="#21468B" d="M0 0h9v6H0z" />
                <path fill="#FFF" d="M0 0h9v4H0z" />
                <path fill="#AE1C28" d="M0 0h9v2H0z" />
            </svg>
        ),
        be: (
            <svg
                preserveAspectRatio="none"
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 45 39">
                <path fill="#ED2939" d="M0 0h45v39H0z" />
                <path fill="#FAE042" d="M0 0h30v39H0z" />
                <path d="M0 0h15v39H0z" />
            </svg>
        ),
        az: (
            <svg
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 1200 600">
                <path fill="#3f9c35" d="M0 0h1200v600H0z" />
                <path fill="#ed2939" d="M0 0h1200v400H0z" />
                <path fill="#00b9e4" d="M0 0h1200v200H0z" />
                <circle cx="580" cy="300" r="90" fill="#fff" />
                <circle cx="600" cy="300" r="75" fill="#ed2939" />
                <path
                    d="M680 250l9.567 26.903 25.788-12.258-12.258 25.788L730 300l-26.903 9.567 12.258 25.788-25.788-12.258L680 350l-9.567-26.903-25.788 12.258 12.258-25.788L630 300l26.903-9.567-12.258-25.788 25.788 12.258L680 250z"
                    fill="#fff"
                />
            </svg>
        ),
        de: (
            <svg
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 5 3">
                <path d="M0 0h5v3H0z" />
                <path fill="#D00" d="M0 1h5v2H0z" />
                <path fill="#FFCE00" d="M0 2h5v1H0z" />
            </svg>
        ),
    }
    return flags[countryCode.toLowerCase()] || flags.az
}
