import React from 'react'
import { connect } from 'react-redux'
import { changeAdminUsersFilter, fetchPermissions } from '../actions/adminUsers'
import { changeEventFilter } from '../actions/events'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.adminUsers
    const filter = filters[props.filterId]
    const { permissions } = state.adminUsers
    const count = filter && counts[filter.searchKey]
    return {
        filter,
        count,
        isFetching,
        permissions,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeAdminUsersFilter(id, options)),
        fetchPermissions: () => dispatch(fetchPermissions()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
