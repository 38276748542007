import TableList from 'components/TableList'
import { useList } from 'hooks/listHooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import cancellationsSchema from 'schemes/cancellationsSchema'

export function useCancellationsList(filterId, defaultOptions) {
    const { countries } = useSelector((state) => state.countries)
    return useList(
        filterId,
        {
            url: 'office/cancellations/get-list',
            schema: cancellationsSchema,
            reducerKey: 'cancellations',
        },
        { ...defaultOptions, countries, sorting: ['premium_cancel_request_id', 'DESC'] }
    )
}

const CancellationsList = ({ filterId = 'all' }) => {
    const {
        entities: { cancellations = {} } = {},
        extra: { definitionsCancellations = {} } = {},
        ...list
    } = useCancellationsList(filterId)

    const renderRow = (premiumCancelRequestId) => {
        const cancellation = cancellations[premiumCancelRequestId] || {}
        return {
            cols: Object.keys(definitionsCancellations).map((key) => {
                if (key === 'businessName') {
                    return (
                        <Link
                            title="To pro"
                            className="plain"
                            to={`/service/providers/provider/${cancellation.service_provider_id}`}>
                            {cancellation.businessName}
                        </Link>
                    )
                } else if (key === 'explanation') {
                    return (
                        <span
                            style={{
                                overflowWrap: 'break-word',
                                overflow: 'hidden',
                                maxWidth: 500,
                                display: 'inline-block',
                            }}>
                            {cancellation.explanation}
                        </span>
                    )
                }

                return cancellation[key]
            }),
        }
    }

    return (
        <TableList
            definitions={definitionsCancellations}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default CancellationsList
