import { Col, Row } from '@advanza/grid'
import { Divider, Icon } from '@advanza/ui'
import Card from 'components/Card'
import AutoRow from 'components/misc/AutoRow'
import React from 'react'
import { Link } from 'react-router-dom'
import MatchViewContainer from '../../containers/MatchViewContainer'
import LinearLoader from '../LinearLoader'
import EventFilter from '../events/EventFilter'
import EventList from '../events/EventList'
import BackButton from '../ui/BackButton'
import MatchTimeLine from './MatchTimeLine'
import MatchTimeOverview from './MatchTimeOverview'
import { ConnectedProps } from 'react-redux'

class MatchView extends React.Component<
    ConnectedProps<typeof MatchViewContainer> & { matchId: number }
> {
    componentDidMount() {
        const { fetchMatch, matchId } = this.props
        fetchMatch(matchId)
    }

    render() {
        const { match = {}, timeLine, matchId } = this.props
        if (!match) {
            return <LinearLoader fixed />
        }
        return (
            <div>
                <AutoRow>
                    <BackButton fallBack="/service/matches" />
                    <Link
                        className="btn filter"
                        to={`/service/requests/request/${match.request_id}`}>
                        To request
                    </Link>
                    <Link
                        className="btn filter"
                        to={`/service/providers/provider/${match.service_provider_id}`}>
                        To Provider
                    </Link>
                </AutoRow>
                <Divider m />
                <Row>
                    <Col xs={6}>
                        <Row>
                            <Col x>
                                <small>#{matchId}</small>
                            </Col>
                            <Col x>
                                <Icon name="person" /> <b>{match.userName}</b>
                            </Col>
                            <Col x>
                                <Icon name="business" /> <b>{match.business_name}</b>{' '}
                            </Col>
                        </Row>
                        <Divider m />
                        <MatchTimeLine timeLine={timeLine} />
                    </Col>
                    <Col xs={6}>
                        <Card header="Events" icon="event_note">
                            <Divider sm />
                            <EventFilter
                                filterId={`mr${matchId}`}
                                defaultOptions={{
                                    matchIds: [matchId],
                                    pageSize: 7,
                                }}
                            />
                            <EventList filterId={`mr${matchId}`} />
                        </Card>
                        <Divider m />
                        <Card header="Time overview" icon="schedule">
                            <Divider sm />
                            <MatchTimeOverview match={match} />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default MatchViewContainer(MatchView)
