import { Col, Row } from '@advanza/grid'
import { FileDropZone } from '@advanza/input'
import { Divider, Icon } from '@advanza/ui'
import ProfileProjectsPhotosEntity from 'components/profileProjects/ProfileProjectsPhotosEntity'
import { useToast } from 'components/misc/Toaster'
import AsyncImage from 'components/ui/AsyncImage'
import EntityOrderModal from 'components/ui/EntityOrderModal'
import { useToggle, useWindowSize } from 'hooks/miscHooks'
import { useProfileProjectsItem } from 'hooks/profileProjectsHooks'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import style from './profileProjectsPhotos.module.css'

const ProfileProjectsPhotos = ({ projectId, providerId, disableButtons, missingPhotoError }) => {
    const { orderedFileIds, nrPhotos, addNewFile, onChangeFileOrder } =
        useProfileProjectsItem(projectId)
    const { onFilesRejected } = useToast()
    const photosRowRef = useRef()
    const width = useWindowSize().width
    const isDesktopStyle = width >= 1024
    const size = isDesktopStyle ? 160 : 108
    const [showExpand, setShowExpand] = useState(false)
    const [showAll, onToggle] = useToggle(false)
    const [limit, setLimit] = useState(0)

    const onDrop = (files) => {
        files.forEach((file) => {
            const Reader = new FileReader()
            Reader.onloadend = (e) => {
                addNewFile({
                    previewUrl: e.target.result,
                    file,
                    filename: file.name,
                    context: 'projects',
                    providerId,
                })
                !showAll && onToggle()
            }
            Reader.readAsDataURL(file)
        })
    }

    useEffect(() => {
        const node = photosRowRef && photosRowRef.current
        const maxSpace = (node && node.clientWidth) - size
        const margin = isDesktopStyle ? 12 : 8
        const photoWidth = size + margin
        const limit = node ? Math.trunc(maxSpace / photoWidth) : 0

        if (showAll) {
            setLimit(nrPhotos)
        } else {
            setLimit(limit)
            setShowExpand(nrPhotos > limit)
        }
    }, [orderedFileIds, showAll, width])

    return (
        <div>
            <Row between="xs" middle="xs" className={style.smallPaddingRow}>
                <Col xs>
                    <div style={{ fontWeight: 800 }}>Images of the project ({nrPhotos})</div>
                </Col>
                <Col x>
                    <EntityOrderModal
                        store="providers"
                        name="files"
                        text="photos"
                        buttonText="Change photo order"
                        disabled={disableButtons}
                        orderedIds={orderedFileIds}
                        renderItem={(item) => {
                            return (
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '50px',
                                        width: '50px',
                                    }}>
                                    <AsyncImage
                                        file={item}
                                        alt={'project foto'}
                                        layout="fill"
                                        size="s"
                                        bgSize="cover"
                                    />
                                </div>
                            )
                        }}
                        afterSubmit={onChangeFileOrder}
                    />
                </Col>
            </Row>
            <Divider s />
            <div ref={photosRowRef}>
                <Row start="xs" className={style.smallPaddingRow}>
                    {orderedFileIds.slice(0, limit).map((fileId, index) => {
                        return (
                            <Col x key={index}>
                                <ProfileProjectsPhotosEntity
                                    fileId={fileId}
                                    projectId={projectId}
                                    hideEditModal
                                />
                                <Divider sm mobile="s" />
                            </Col>
                        )
                    })}
                    <Col x>
                        <div className={style.dropZoneWrapper}>
                            <FileDropZone
                                allowedTypes={['image']}
                                onFilesAccepted={onDrop}
                                onFilesRejected={onFilesRejected}
                                style={{
                                    height: size,
                                    width: size,
                                }}>
                                <div
                                    className={[
                                        style.dropZoneInput,
                                        missingPhotoError ? style.error : '',
                                    ].join(' ')}>
                                    <Icon fontSize={32} name="add" primColor />
                                    <Divider xs />
                                    <span>Add photo</span>
                                </div>
                            </FileDropZone>
                        </div>
                        {missingPhotoError && (
                            <span className={style.missingPhotoError}>Upload at least 1 photo</span>
                        )}
                        <Divider m mobile="s" />
                    </Col>
                </Row>
            </div>
            {showExpand && (
                <Fragment>
                    <Row center="xs">
                        <Col x>
                            <button onClick={onToggle} className={style.expandBtn}>
                                <Icon
                                    name={showAll ? 'expand_less' : 'expand_more'}
                                    fontSize={16}
                                    primColor
                                />
                                {showAll ? 'Show fewer photos' : `Show all ${nrPhotos} photos`}
                            </button>
                        </Col>
                    </Row>
                    <Divider m mobile="s" />
                </Fragment>
            )}
        </div>
    )
}

export default ProfileProjectsPhotos
