import { Flag, Icon } from '@advanza/ui'
import React from 'react'
import { Link } from 'react-router-dom'
import SubscriptionsListContainer from '../../containers/SubscriptionsListContainer'
import TableList from '../TableList'

class SubscriptionList extends React.Component {
    definitions = {
        id: { name: '#' },
        active: { name: ' ' },
        service: { name: 'service' },
        name: { name: 'name' },
        coverage: { name: 'coverage' },
        estimator: { name: '' }
    }

    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { entities } = this.props
        const { subscriptions } = entities
        const subscription = subscriptions[id]
        const title = subscription.name || subscription.serviceName || id

        const totalPopulation = subscription.country_code === 'BE' ? 11000000 : 17000000
        const coverage = subscription.use_municipalities
            ? Math.round((subscription.sumPopulation / totalPopulation) * 100)
            : subscription.coverage

        const coverageStyle = (percentage) => {
            return {
                color: `hsl(${percentage}, 100%, 40%, 1)`
            }
        }
        return [
            <small className="text">#{id}</small>,
            <Icon>
                {subscription.deleted
                    ? 'delete_forever'
                    : subscription.active
                        ? 'play_arrow'
                        : 'pause'}
            </Icon>,
            <>
                <Flag countryCode={subscription.country_code} /> {subscription.serviceName}
            </>,
            subscription.deleted ? <strike>{title}</strike> : title,
            <span style={coverageStyle(coverage)}>{coverage}%</span>,
            <Link
                title={subscription?.subscriptions_expectation ? JSON.stringify(subscription?.subscriptions_expectation, null, 2) : 'Lead Estimator'}
                to={`/sales/lead-estimator?subscriptionId=${id}&serviceId=${subscription.service_id}&city=&previous=${window.location.pathname}`}>
                <Icon name='online_prediction' />
            </Link>
        ]
    }

    renderRow(id) {
        return {
            url: `/service/providers/subscription/${id}`,
            cols: this.renderColumns(id),
            id
        }
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

SubscriptionList.propTypes = {}

export default SubscriptionsListContainer(SubscriptionList)
