import { Col, Row } from '@advanza/grid'
import { deleteSnippet } from 'actions/aggregatedReviews'
import Divider from 'components/Divider'
import EntityComponent from 'components/services/EntityComponent'
import SafeButton from 'components/ui/SafeButton'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import React from 'react'

class AggregatedReviewSnippetEntity extends EntityComponent {
    editFields() {
        return {
            snippet: { type: 'text', textArea: true, msg: 'snippet' },
            first_name: { type: 'text', msg: 'Name' },
            review_date: { type: 'date', msg: 'Date', format: 'YYYY-MM-DD' },
            source_url: { type: 'text', msg: 'Source' },
        }
    }

    render() {
        const {} = this.props

        return (
            <div>
                {this.renderInput('snippet')}
                <Divider m />
                <Row>
                    <Col xs>{this.renderInput('first_name')}</Col>
                    <Col xs>{this.renderInput('review_date')}</Col>
                </Row>
                <Divider m />
                <SafeButton
                    action={this.delete}
                    confirmMessage="Delete snippet + hide scraped snippets from author in future"
                    buttonText={<i className="material-symbols-outlined">delete_forever</i>}
                />
            </div>
        )
    }
}

AggregatedReviewSnippetEntity.propTypes = {}

export default EntityComponentContainer(AggregatedReviewSnippetEntity, {
    store: 'aggregatedReviews',
    name: 'aggregatedReviewsSnippets',
    deleteFunc: deleteSnippet,
})
