import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {isProfileBuilderAllowedLocation, PROFILE_BUILDER_REDIRECT} from 'misc/user'

export const PrivateRoute = ({ component: Component, render, ...otherProps }) => {
    return (
        <Route
            {...otherProps}
            render={(props) =>
                window._user && window._user.role === 'admin' ? (
                    isProfileBuilderAllowedLocation() ? (
                        render ? (
                            render(props)
                        ) : (
                            <Component {...props} />
                        )
                    ) : (
                        <Redirect to={PROFILE_BUILDER_REDIRECT} />
                    )
                ) : (
                    <Redirect to={'/login?redirect=' + window.location.pathname} />
                )
            }
        />
    )
}
