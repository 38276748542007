import ProviderEditView from 'components/providers/ProviderEditView'
import React from 'react'
import Page from '../ui/Page'
import ProviderView from './ProviderView'

const ProviderEditViewPage = ({ match }) => {
    const { id } = match.params
    return (
        <Page title="Edit Pro Profile">
            <ProviderEditView providerId={id} />
        </Page>
    )
}

ProviderEditViewPage.propTypes = {}

export default ProviderEditViewPage
