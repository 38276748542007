import { Row, Col } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import { Icon } from '@advanza/ui'
import { changeSubscriptionFilter } from 'actions/subscriptions'
import ServicesSelect from 'components/services/ServicesSelect'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function useGetSubscriptions(providerId) {
    const dispatch = useDispatch()
    const filterId = 'p_' + providerId
    useEffect(() => {
        dispatch(
            changeSubscriptionFilter(filterId, { defaultOptions: { providerIds: [providerId] } })
        )
    }, [])
    const { entities, result, filters } = useSelector((state) => state.subscriptions)
    const results = result[filters[filterId].searchKey][0] || []
    const subscriptions = results.map((id) => entities.subscriptions[id])
    const { questions, services, options } = entities
    return {
        subscriptions,
        questions,
        services,
        options,
    }
}

const ServicePicker = ({ providerId, serviceId, optionId, changeEntity, hideOptions }) => {
    const { subscriptions, services, questions, options } = useGetSubscriptions(providerId)
    const serviceIds = subscriptions.map((subscription) => parseInt(subscription.service_id))
    const service = services[serviceId]
    let optionIds = []
    if (service) {
        service.questions.forEach((id) => (optionIds = optionIds.concat(questions[id].options)))
    }
    useEffect(() => {
        changeEntity({ service_id: serviceIds[0] })
    }, [])
    return (
        <Row>
            <Col xs>
                <ServicesSelect
                    filter={(id) => serviceIds.indexOf(parseInt(id)) !== -1}
                    value={serviceId}
                    multiple={false}
                    placeholder="Service"
                    onChangeValue={(services) =>
                        changeEntity({
                            service_id: services.length > 0 ? services[0] : null,
                        })
                    }
                />
            </Col>
            {!hideOptions && (
                <Col xs>
                    <SelectSimple
                        onChange={(e) =>
                            changeEntity({
                                option_id: parseInt(e.target.value),
                            })
                        }
                        value={optionId || '_hidden_'}
                        hiddenOption="All options"
                        placeholder="Option"
                        options={optionIds.map((id) => {
                            return {
                                value: id,
                                name: options[id].value,
                            }
                        })}
                    />
                </Col>
            )}
            {optionId && (
                <Col x>
                    <button
                        onClick={(e) => {
                            changeEntity({ option_id: null })
                            e.preventDefault()
                            e.stopPropagation()
                        }}>
                        <Icon name="close" />
                    </button>
                </Col>
            )}
        </Row>
    )
}

export default ServicePicker
