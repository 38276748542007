import React from 'react'
import { Ellipsis, Icon } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'

const UnpaidInvoiceStatus = ({ unpaidInvoiceFollowUp }) => {
    if (!unpaidInvoiceFollowUp) {
        return null
    }
    const { external_collector_invoice_id, failed_reason } = unpaidInvoiceFollowUp
    const title = JSON.stringify(unpaidInvoiceFollowUp, null, 2)

    const statusDots = {
        second_payment_announcement_sent: {
            icon: 'forward_to_inbox',
            color: 'blue',
        },
        second_payment_created: {
            icon: 'looks_two',
            color: 'green',
        },
        second_payment_failed: {
            icon: 'credit_card_off',
            color: 'red',
        },
        disabled: {
            icon: 'no_accounts',
            color: 'red',
        },
        follow_up_cancelled: {
            icon: 'error_med',
            color: 'orange',
        },
    }
    return (
        <AutoRow>
            <span title={title}>
                <a
                    title={title}
                    target="_blank"
                    href={
                        !external_collector_invoice_id
                            ? '#'
                            : `https://notify.cm/facturen/${external_collector_invoice_id}`
                    }>
                    <Icon
                        orange={external_collector_invoice_id}
                        name={
                            external_collector_invoice_id ? 'monetization_on' : 'currency_exchange'
                        }
                        title={title}
                    />
                </a>{' '}
            </span>
            <div>
                <small title={failed_reason}>
                    <Ellipsis style={{ maxWidth: '5em' }}>{failed_reason}</Ellipsis>
                </small>
                <div>
                    {Object.keys(statusDots).map((key) => {
                        if (unpaidInvoiceFollowUp[key]) {
                            const { color, icon } = statusDots[key]
                            return (
                                <span
                                    style={{ cursor: 'pointer' }}
                                    key={key}
                                    title={key + ' ' + unpaidInvoiceFollowUp[key]}>
                                    <Icon fontSize={16} name={icon} style={{ color }} />
                                </span>
                            )
                        }
                    })}
                </div>
            </div>
        </AutoRow>
    )
}

export default UnpaidInvoiceStatus
