import { call } from '@advanza/api'
import { normalize } from 'normalizr'
import servicesSchema from '../schemes/servicesSchema'
import subscriptionsSchema from '../schemes/subscriptionsSchema'
import { _changeFilter, _fetchItems, _preLoadFilter } from './lists'
import { SERVICES_RECEIVE_SINGLE } from './services'

export const SUBSCRIPTION_STATUS_UPDATED = 'SUBSCRIPTION_STATUS_UPDATED'
export const RECEIVE_ALL_GEOIDS = 'RECEIVE_ALL_GEOIDS'
export const REQUEST_GEOIDS = 'REQUEST_GEOIDS'
export const SUBSCRIPTIONS_REQUEST = 'SUBSCRIPTIONS_REQUEST'
export const SUBSCRIPTIONS_RECEIVE_SINGLE = 'SUBSCRIPTIONS_RECEIVE_SINGLE'
export const SUBSCRIPTIONS_RECEIVE_LIST = 'SUBSCRIPTIONS_RECEIVE_LIST'
export const SUBSCRIPTIONS_CHANGE_FILTER = 'SUBSCRIPTIONS_CHANGE_FILTER'
export const CHANGE_SUBSCRIPTION_TRAVEL_RANGE = 'CHANGE_SUBSCRIPTION_TRAVEL_RANGE'
export const RECEIVE_GEO_IDS = 'RECEIVE_GEO_IDS'
export const REPLACE_SUBSCRIPTION_GEOIDS = 'REPLACE_SUBSCRIPTION_GEOIDS'
export const REPLACE_SUBSCRIPTION_MUNICIPALITIES = 'REPLACE_SUBSCRIPTION_MUNICIPALITIES'
export const REPLACE_SUBSCRIPTION_OPTIONS = 'REPLACE_SUBSCRIPTION_OPTIONS'
export const SUBSCRIPTION_DELETED = 'SUBSCRIPTION_DELETED'

export function fetchSubscription(subscriptionId, options = {}) {
    return function (dispatch, getState) {
        const { subscriptions } = getState().subscriptions.entities
        if (
            subscriptions &&
            subscriptions[subscriptionId] &&
            subscriptions[subscriptionId].subscriptions_options
        ) {
            return Promise.resolve()
        }
        if (getState().subscriptions.isFetching) {
            return Promise.resolve()
        }
        dispatch({ type: SUBSCRIPTIONS_REQUEST })
        return call('office/subscriptions/get-one/' + subscriptionId, {
            query: options,
        }).then((response) => {
            const { entities, result } = normalize([response.item], subscriptionsSchema)
            const { entities: serviceEntities } = normalize([response.service], servicesSchema)
            dispatch({ type: SERVICES_RECEIVE_SINGLE, entities: serviceEntities })
            dispatch({ type: SUBSCRIPTIONS_RECEIVE_SINGLE, entities, result })
        })
    }
}

export function getLeadEstimation(options = {}) {
    const { subKey } = options
    return function (dispatch) {
        if (!options.serviceId && !options.subscriptionId) {
            return Promise.resolve({})
        }
        return call('office/subscriptions/get-lead-estimation/', {
            query: options,
        }).then((response) => {
            response.item.subscription_id = subKey
            const { entities, result } = normalize([response.item], subscriptionsSchema)
            const { entities: serviceEntities } = normalize([response.service], servicesSchema)
            dispatch({ type: SERVICES_RECEIVE_SINGLE, entities: serviceEntities })
            dispatch({
                type: SUBSCRIPTIONS_RECEIVE_SINGLE,
                entities,
                result,
                estimations: { [subKey]: response.stats },
            })
            return Promise.resolve(response)
        })
    }
}

export function fetchSubscriptionList(filterId) {
    return _fetchItems(filterId, {
        url: 'office/subscriptions/get-list',
        schema: subscriptionsSchema,
        requestActionName: SUBSCRIPTIONS_REQUEST,
        receiveActionName: SUBSCRIPTIONS_RECEIVE_LIST,
        reducerKey: 'subscriptions',
    })
}

export function changeSubscriptionFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'subscriptions',
        fetchFunc: fetchSubscriptionList,
        actionType: SUBSCRIPTIONS_CHANGE_FILTER,
    })
}

export function addSubscription(providerId, serviceId, options) {
    return function (dispatch, getState) {
        return call(`office/subscriptions/add/${providerId}/${serviceId}`, {
            method: 'post',
        }).then(() => {
            dispatch({ type: 'INVALIDATE_SUBSCRIPTIONS' })
        })
    }
}

export function preLoadSubscriptions(filterId, options, response) {
    return _preLoadFilter(filterId, options, response, {
        receiveActionName: SUBSCRIPTIONS_RECEIVE_LIST,
        reducerKey: 'subscriptions',
        schema: subscriptionsSchema,
    })
}

function subscriptionStatusUpdated(subscriptionId, active) {
    return {
        type: SUBSCRIPTION_STATUS_UPDATED,
        subscriptionId,
        active,
    }
}

export function replaceSubscriptionOptions(subscriptionId, options) {
    return {
        type: REPLACE_SUBSCRIPTION_OPTIONS,
        options,
        subscriptionId,
    }
}

function shouldFetchAllGeoIds(state) {
    if (state.subscriptions.geoIds.result) {
        return false
    } else {
        return !state.subscriptions.geoIds.isFetching
    }
}

function shouldFetchAllMunicipalities(state, countryCode) {
    if (
        state.subscriptions.municipalities.result &&
        state.subscriptions.municipalities.result[countryCode]
    ) {
        return false
    } else {
        return !state.subscriptions.municipalities.isFetching
    }
}

export function replaceSubscriptionGeoids(subscriptionId, geoIds) {
    return {
        type: REPLACE_SUBSCRIPTION_GEOIDS,
        geoIds,
        subscriptionId,
    }
}

export function replaceSubscriptionMunicipalities(subscriptionId, municipalities) {
    return {
        type: REPLACE_SUBSCRIPTION_MUNICIPALITIES,
        municipalities,
        subscriptionId,
    }
}

export function toggleSubscriptionMunicipalityIds(subscriptionId, id) {
    return function (dispatch, getState) {
        const { entities } = getState().subscriptions
        const subscriptionMunicipalities =
            entities.subscriptions[subscriptionId].subscriptions_municipalities
        subscriptionMunicipalities.indexOf(id) !== -1
            ? dispatch(
                  replaceSubscriptionMunicipalities(
                      subscriptionId,
                      subscriptionMunicipalities.filter((_id) => _id !== id)
                  )
              )
            : dispatch(
                  replaceSubscriptionMunicipalities(
                      subscriptionId,
                      [...subscriptionMunicipalities].concat([id])
                  )
              )
    }
}

export function toggleSubscriptionMunicipalityIdsMany(subscriptionId, ids, hasAll) {
    return function (dispatch, getState) {
        const { entities } = getState().subscriptions
        const subscriptionMunicipalities =
            entities.subscriptions[subscriptionId].subscriptions_municipalities
        const turnOff =
            hasAll === true || ids.every((id) => subscriptionMunicipalities.includes(id))
        if (turnOff) {
            dispatch(
                replaceSubscriptionMunicipalities(
                    subscriptionId,
                    subscriptionMunicipalities.filter((id) => !ids.includes(id))
                )
            )
        } else {
            const newIds = [...subscriptionMunicipalities].concat(ids)
            const unique = newIds.filter((value, index, self) => self.indexOf(value) === index)
            dispatch(replaceSubscriptionMunicipalities(subscriptionId, unique))
        }
    }
}

export function fetchAllMunicipalities(countryCode = null) {
    return function (dispatch, getState) {
        const countries = countryCode ? [countryCode] : ['NL']
        if (!shouldFetchAllMunicipalities(getState(), countries[0])) {
            return Promise.resolve()
        }
        dispatch({ type: 'REQUEST_MUNICIPALITIES' })

        return call('geo/get-all-municipalities', {
            query: { countries },
        }).then((response) => {
            dispatch({
                type: RECEIVE_ALL_GEOIDS,
                ...response,
            })
        })
    }
}

export function toggleActiveSubscription(subscriptionId) {
    return function (dispatch, getState) {
        const subscription = getState().subscriptions.entities.subscriptions[subscriptionId]
        const active = !subscription.active
        dispatch(subscriptionStatusUpdated(subscriptionId, active))

        return call(
            `subscriptions/activate-or-pause-subscription/${subscriptionId}/${active ? 1 : 0}`,
            { method: 'post' }
        ).then(
            (json) => {
                dispatch(
                    subscriptionStatusUpdated(
                        subscription.subscription_id,
                        json.subscription.active ? 1 : 0
                    )
                )
            },
            (error) => {
                dispatch(subscriptionStatusUpdated(subscriptionId, active ? 0 : 1))
            }
        )
    }
}

export function saveSubscription(subscriptionId) {
    return function (dispatch, getState) {
        const subscription = getState().subscriptions.entities.subscriptions[subscriptionId]

        return call('office/subscriptions/save/' + subscriptionId, {
            json: subscription,
        })
    }
}

export function deleteSubscription(subscriptionId) {
    return function (dispatch, getState) {
        return call(`subscriptions/delete/${subscriptionId}`, {
            method: 'post',
        }).then((json) => {
            dispatch({ type: 'SUBSCRIPTION_DELETED', subscriptionId })
        })
    }
}

/**
 * Save the options for the subscription with the values from state.
 *
 */
export function saveSubscriptionOptions(subscriptionId) {
    return function (dispatch, getState) {
        const { subscriptions } = getState().subscriptions.entities
        const subscription = subscriptions && subscriptions[subscriptionId]
        if (!subscription) {
            return Promise.reject(subscription)
        }
        return call('subscriptions/edit-options/' + subscriptionId, {
            payload: {
                options: subscription.subscriptions_options,
            },
        })
    }
}

/**
 * Save the geoIds for the subscription with the values from state.
 *
 */
export function saveSubscriptionGeoids(subscriptionId) {
    return function (dispatch, getState) {
        const state = getState()
        const { subscriptions } = state.subscriptions.entities
        const subscription = subscriptions && subscriptions[subscriptionId]
        if (!subscription) {
            return Promise.reject()
        }
        const postalCode = subscription.postal_codes.length > 0 && subscription.postal_codes[0]
        const geoIds = subscription.subscriptions_geoids
        if (subscription.use_municipalities && !subscription.subscriptions_municipalities) {
            return Promise.reject('empty')
        }
        return call('subscriptions/edit-geo-ids/' + subscriptionId, {
            json: {
                geoIds: geoIds,
                municipalities: subscription.subscriptions_municipalities,
                postalCodeId: postalCode && postalCode.postal_code_id,
                distance: postalCode && postalCode.distance,
                hide_log: 1,
            },
        })
    }
}

export function changeSubscriptionTravelRange(subscriptionId, postalCode) {
    return {
        type: CHANGE_SUBSCRIPTION_TRAVEL_RANGE,
        subscriptionId,
        postalCode,
    }
}

export function findGeoIdsByRange(lat, lon, range, countryCode, municipalities) {
    return call(
        `geo/latlon/${lat}/${lon}/${range}/-1/0/${
            municipalities ? 'municipality_id' : ''
        }?countryCode=${countryCode}`
    ).then(({ result = [] }) => {
        return Promise.resolve(
            result.map(({ matching_city_id, municipality_id }) =>
                municipalities ? parseInt(municipality_id) : parseInt(matching_city_id)
            )
        )
    })
}
