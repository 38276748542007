import { Row, Col } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import { LoadingDots } from '@advanza/ui'
import { Icon, Button, Divider } from '@advanza/ui'
import { saveCustomPrice, deleteCustomPrice } from 'actions/customPrices'
import ServicePicker from 'components/customPrices/ServicePicker'
import React from 'react'
import { useChangeEntity } from 'hooks/entityHooks'

const fields = {
    amount: {
        type: 'number',
        autoFocus: true,
        placeholder: 'Price',
        step: 1,
    },
}

const CustomPriceEntity = ({ entityId }) => {
    const {
        entity = {},
        renderInput,
        onChangeEntity,
        onSaveEntity,
        onDeleteEntity,
    } = useChangeEntity(
        {
            store: 'providers',
            name: 'customPrices',
            entityId: entityId,
            saveFunc: saveCustomPrice,
            deleteFunc: deleteCustomPrice,
        },
        fields
    )
    const leadType = entity.is_website_click ? 'website' : entity.is_phone_click ? 'phone' : 'email'

    return (
        <div>
            <Row>
                <Col x> {renderInput('amount')}</Col>
                <Col x>
                    <SelectSimple
                        placeholder="Lead type"
                        value={leadType}
                        onChange={(e) => {
                            switch (e.target.value) {
                                case 'phone':
                                    onChangeEntity({
                                        is_phone_click: true,
                                        is_website_click: false,
                                    })
                                    break
                                case 'website':
                                    onChangeEntity({
                                        is_website_click: true,
                                        is_phone_click: false,
                                    })
                                    break
                                default:
                                    onChangeEntity({
                                        is_website_click: false,
                                        is_phone_click: false,
                                    })
                            }
                        }}
                        options={[
                            { name: 'Email', value: 'all' },
                            { name: 'Phone', value: 'phone' },
                            { name: 'Website', value: 'website' },
                        ]}
                    />
                </Col>
            </Row>
            <Divider ml />
            <ServicePicker
                providerId={entity.service_provider_id}
                serviceId={entity.service_id}
                optionId={entity.option_id}
                hideOptions={leadType !== 'email'}
                changeEntity={onChangeEntity}
            />
            <Divider l />
            <Row between="xs">
                <Col x>
                    <Button disabled={!entity._isTouched && !entity._saving} onClick={onSaveEntity}>
                        {entity._saving ? <LoadingDots color="#fff" /> : 'Save'}
                    </Button>
                </Col>
                <Col x>
                    <Button onClick={onDeleteEntity} name="text">
                        <Icon name="delete_forever" />
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default CustomPriceEntity
