import React from 'react'
import Icon from "../Icon"
import style from './../slider.module.scss'

class Slider extends React.Component {
    constructor(props) {
        super(props)
        this.move = this.move.bind(this)
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.onResize = this.onResize.bind(this)
        this.onScroll = this.onScroll.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.stopScroll = this.stopScroll.bind(this)
        this.onTouch = this.onTouch.bind(this)
        this._touchStartX = 0
        this.state = {
            index: props.index || 0,
            inTrans: false,
            screenWidth: null,
        }
        this.transitionDuration = props.transitionDuration || 200
        this.screenWidth = null
    }

    onResize() {
        this.setState({ screenWidth: window.screen.width })
    }

    componentDidMount() {
        this.setState({ screenWidth: window.screen.width })
        window.addEventListener('resize', this.onResize)
        window.addEventListener('keydown', this.onKeyDown)
        window.addEventListener('touchend', this.onTouch)
        window.addEventListener('touchstart', this.onTouch)
        this.containerEl && this.containerEl.addEventListener('scroll', this.onScroll)
        if (this.props.index > 0) {
            this.scrollToItem(this.props.index, true)
        }
    }

    componentDidUpdate(prevProps) {
        const { index } = this.props
        if (index !== undefined && index !== prevProps.index && index !== this.state.index) {
            if (this.props.useScroll) {
                this.scrollToItem(index, Math.abs(index - this.state.index) > 2)
            } else {
                this.move(index >= this.state.index ? 'next' : 'prev', index)
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('keydown', this.onKeyDown)
        window.removeEventListener('touchend', this.onTouch)
        window.removeEventListener('touchstart', this.onTouch)
        clearTimeout(this.stopScrollTimeout)
        this.containerEl && this.containerEl.removeEventListener('scroll', this.onScroll)
    }

    onScroll() {
        if (
            this.wait ||
            !this.containerEl ||
            !this.props.setIndexOnScroll ||
            this.isScrollingAnimation
        ) {
            return
        }
        this.wait = true
        setTimeout(() => {
            for (let i = 0; i < this.containerEl.childElementCount; i++) {
                const { offsetLeft, clientWidth } = this.containerEl.childNodes[i]
                const inView =
                    this.containerEl.scrollLeft + clientWidth / 2 > offsetLeft &&
                    offsetLeft - this.containerEl.scrollLeft + clientWidth / 2 > 0
                if (inView && this.state.index !== i) {
                    // setting the state index first will prevent scrolling after setIndex()
                    this.setState({ index: i })
                    this.props.setIndex && this.props.setIndex(i)
                    break
                }
            }
            this.wait = false
        }, 100)
    }

    stopScroll() {
        if (!this.containerEl || !this.containerEl.style) {
            return
        }
        this.containerEl.style.overflow = 'hidden'
        this.stopScrollTimeout = setTimeout(() => {
            this.containerEl.style.overflow = ''
        }, 50)
    }

    onTouch(e) {
        const { offsetLeft, clientWidth } =
        (this.containerEl && this.containerEl.childNodes[this.props.index]) || {}
        if (!clientWidth) {
            return
        }
        const threshold = 20
        const touch = e.changedTouches[0]
        if (e.type === 'touchstart') {
            if (e.touches.length === 2) {
                this._isPinching = true
            }
            this._touchStartTime = Date.now()
            this._touchStartX = touch.pageX
        } else if (!this._isPinching) {
            const scrollLeft = this.containerEl.scrollLeft
            const distance = this._touchStartX - touch.pageX
            const direction = distance < 0 ? 'left' : 'right'
            const duration = Date.now() - this._touchStartTime
            if (duration < 50) {
                this.scrollToItem(this.state.index)
                return
            }
            if (direction === 'right') {
                if (scrollLeft > offsetLeft + threshold) {
                    this.next()
                } else {
                    this.scrollToItem(this.state.index)
                }
            } else if (direction === 'left') {
                if (offsetLeft > scrollLeft + threshold) {
                    this.prev()
                } else {
                    this.scrollToItem(this.state.index)
                }
            }
        } else {
            this._isPinching = false
        }
    }

    onKeyDown(e) {
        if (document.activeElement.tagName !== 'BODY' || !this.props.enableArrowKeys) {
            return
        }
        if (e.key === 'ArrowLeft') {
            this.prev()
        } else if (e.key === 'ArrowRight') {
            this.next()
        }
    }

    move(direction = 'next', index) {
        const { items, onClickMore } = this.props
        const newIndex =
            index || (direction === 'next' ? this.state.index + 1 : this.state.index - 1)
        const skipAnimation = Math.abs(newIndex - this.state.index) > 1
        if (!items[newIndex] || this.state.inTrans) {
            return
        }

        if (!skipAnimation) {
            this.setState({ inTrans: direction })
            setTimeout(() => {
                this.setState({ inTrans: false, index: newIndex })
            }, this.transitionDuration)
        } else {
            this.setState({ inTrans: false, index: newIndex })
        }

        onClickMore && onClickMore()
    }

    // This is used when overflow mode = auto (on mobile devices)
    scrollToItem(index, noAnimation) {
        const el = this.containerEl.children[index]
        if (!el) {
            return
        }

        const margin = (this.containerEl.clientWidth - el.clientWidth) / 2

        if (noAnimation) {
            this.containerEl.scrollTo(el.offsetLeft - margin, 0)
        } else {
            this.isScrollingAnimation = true
            this.stopScroll()
            // scrollToX(el.offsetLeft - margin, 2300, this.containerEl)
            this.containerEl.scrollTo({
                left: el.offsetLeft - margin,
                behavior: 'auto',
            })
            setTimeout(() => {
                this.isScrollingAnimation = false
                this.setState({ index })
                this.stopScroll()
            }, 40)
        }
    }

    next() {
        if (this.props.setIndex) {
            this.props.setIndex(Math.min(this.props.items.length, this.props.index + 1))
        } else {
            this.move('next')
        }
    }

    prev() {
        if (this.props.setIndex) {
            this.props.setIndex(Math.max(0, this.props.index - 1))
        } else {
            this.move('prev')
        }
    }

    arrOf(nr) {
        const arr = []
        for (let i = 0; i < nr; i++) {
            arr.push(i)
        }
        return arr
    }

    render() {
        const {
            items,
            classNames = {},
            onClick,
            useTouchMobile = true,
            useLargeOverflow = false,
            minWidth = 1,
            smallWidth = 45,
            mediumWidth = 30.7,
            useBubbles,
            itemWidth = 30.7,
            padding = 16,
            setIndex,
            disableRightClick,
            blueButtons = false,
            bigScreenNavigation = false,
            newStyle,
            ...rest
        } = this.props
        const { index, inTrans, screenWidth } = this.state
        const nrItems = items.length
        const width = screenWidth
            ? screenWidth < 768
                ? smallWidth
                : screenWidth < 1024
                    ? mediumWidth
                    : itemWidth
            : itemWidth
        const hasMore = nrItems * width > 100 && index < nrItems - Math.floor(100 / width)
        const listStyle = inTrans
            ? {
                transform: `translateX(${inTrans === 'next' ? '-' : ''}${width}%)`,
                transitionDuration: this.transitionDuration + 'ms',
                marginLeft: `calc(-${width}% + -3px)`,
            }
            : { marginLeft: `calc(-${width}% + -3px)` }
        // const useScroll = rest.useScroll || (useTouchMobile && screenWidth < 768)
        const useScroll = rest.useScroll
        const isMobile = screenWidth < 768
        const className = [
            style.relative,
            useTouchMobile ? style.useTouchMobile : '',
            useScroll ? style.useTouchScroll : '',
            blueButtons ? style.blueButtons : '',
            bigScreenNavigation ? style.bigScreenNavigation : '',
        ].join(' ')
        return (
            <div className={[className, newStyle ? style.newStyle : ''].join(' ')} style={rest.style || {}}>
                {index > 0 && (
                    <div
                        onClick={this.prev}
                        className={[style.prev, classNames.prev || ''].join(' ')}>
                        <Icon name="arrow_back_ios" />
                    </div>
                )}
                {hasMore && (
                    <div onClick={this.next} className={[style.next, classNames.next].join(' ')}>
                        <Icon name="arrow_forward_ios" />
                    </div>
                )}
                {useBubbles && (
                    <div className={[style.bubbles, classNames.bubbles || ''].join(' ')}>
                        {this.arrOf(Math.min(20, nrItems)).map((i) => {
                            return (
                                <div
                                    key={i}
                                    onClick={() => setIndex && setIndex(i)}
                                    className={index === i ? style.current : ''}
                                />
                            )
                        })}
                    </div>
                )}
                <div
                    style={{ overflow: 'hidden' }}
                    className={[
                        style.wrapper,
                        useLargeOverflow ? style.useLargeOverflow : style.useSmallOverflow,
                        this.props.className,
                    ].join(' ')}>
                    <ul
                        className={style.list}
                        ref={(el) => (this.containerEl = el)}
                        style={listStyle}>
                        {(useScroll ? items : [<div key="a"></div>].concat(items)).map(
                            (item, i) => {
                                // Don't use flex order on mobile because it uses native overflow scrolling
                                const order =
                                    !(useScroll || isMobile) && i < index
                                        ? nrItems - index + 1
                                        : i - index
                                return (
                                    <li
                                        key={i}
                                        onContextMenu={
                                            disableRightClick ? (e) => e.preventDefault() : null
                                        }
                                        style={{
                                            width: `calc(${width}% - ${padding}px)`,
                                            order,
                                            minWidth,
                                            marginRight: padding,
                                        }}
                                        className={style.item}
                                        onClick={() => onClick && onClick(i - 1)}>
                                        {item}
                                    </li>
                                )
                            }
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Slider
