import { Row, Section } from '@advanza/advanza_generic'
import React from 'react'
import Page from '../ui/Page'
import ServiceFilter from './ServiceFilter'
import ServicesList from './ServicesList'

const ServicesPage = ({}) => {
    return (
        <Page title="Services">
            <div className="container">
                <Section y x="mega">
                    <Row y nm>
                        <Section e="plus">
                            <h1>Services</h1>
                        </Section>
                        <ServiceFilter filterId="all" />
                    </Row>
                    <ServicesList selectable filterId="all" />
                </Section>
            </div>
        </Page>
    )
}

export default ServicesPage
