import { call } from '@advanza/api'
import { normalize } from 'normalizr'

export function _preLoadFilter(
    filterId,
    options,
    response,
    { schema, reducerKey, receiveActionName }
) {
    return function (dispatch) {
        const filter = { ...options, searchKey: createFilterKey(filterId, options), page: 0 }

        const searchKey = filter.searchKey
        const page = filter.page || 0

        const { result, entities } = normalize(response.items, schema)
        dispatch({
            type: receiveActionName,
            result,
            entities,
            page,
            filterId,
            count: '?',
            searchKey,
        })
    }
}

export function _fetchItems(
    filterIdOrOptions,
    {
        url,
        schema,
        reducerKey,
        requestActionName = reducerKey.toUpperCase() + '_REQUEST',
        receiveActionName = reducerKey.toUpperCase() + '_RECEIVE_LIST',
    }
) {
    return function (dispatch, getState) {
        const { didInvalidate, isFetching, result, filters } = getState()[reducerKey]

        const filter = (typeof filterIdOrOptions === 'object' && filterIdOrOptions) ||
            filters[filterIdOrOptions] || { searchKey: 'all', noLimit: true }
        const filterId =
            typeof filterIdOrOptions === 'object' ? filterIdOrOptions.filterId : filterIdOrOptions

        const searchKey = filter.searchKey
        const page = filter.page || 0
        const alreadyFetched =
            result[searchKey] &&
            (page === 'all' || result[searchKey][page]) &&
            !filter.didInvalidate

        const invalidated = filter.didInvalidate || didInvalidate
        const isFetchingFilter = isFetching === true || isFetching === filterId
        if (!invalidated && (isFetchingFilter || alreadyFetched)) {
            return Promise.resolve()
        }

        const queryPayload = {
            pageSize: 20,
            ...filter,
            pageNr: page,
        }
        delete queryPayload.page
        dispatch({ type: requestActionName, filterId })

        const afterFetch = (response) => {
            const { result, entities } = schema
                ? normalize(response.items, schema)
                : { ...response }
            dispatch({
                type: receiveActionName,
                result,
                entities,
                page,
                filterId,
                count: response.count,
                searchKey,
                stats: response.stats,
                extra: response.extra,
            })
        }

        return call(url, { query: { ...queryPayload } })
            .then(afterFetch)
            .catch((err) => {
                dispatch({
                    type: receiveActionName,
                    result: [],
                    entities: {},
                    page,
                    filterId,
                    searchKey,
                    error: err,
                })
            })
    }
}

function createFilterKey(filterId = 'all', items) {
    const skipFields = [
        'searchKey',
        'page',
        'pageNr',
        'filterId',
        'pageSize',
        'skip',
        'didInvalidate',
    ]
    const itemsAsString = Object.keys(items)
        .map((key) => skipFields.indexOf(key) === -1 && items[key] && items[key].toString())
        .filter(Boolean)
        .join('_')

    return `${filterId}${itemsAsString}`
}

export function _changeFilter(
    filterId,
    options,
    {
        reducerKey,
        ignoreIsFetching,
        fetchFunc,
        actionType = `${reducerKey.toUpperCase()}_CHANGE_FILTER`,
        replace = false,
    }
) {
    return function (dispatch, getState) {
        const { isFetching, filters } = getState()[reducerKey]
        if (!ignoreIsFetching && (isFetching === true || isFetching === filterId)) {
            return
        }
        const filter = (!replace && filters[filterId]) || {}
        const pageSize = options.pageSize || filter.pageSize || 20

        const searchKey = createFilterKey(filterId, { ...filter, ...options })

        const newFilter = {
            ...filter,
            ...options,
            searchKey,
            pageSize,
            filterId,
        }

        dispatch(fetchFunc(newFilter)).then(() => {
            dispatch({
                type: actionType,
                filterId,
                filter: { ...newFilter, didInvalidate: false },
            })
        })
    }
}

export function selectItems(store, ids, filterId) {
    return function (dispatch) {
        dispatch({
            type: `${store.toUpperCase()}_SELECT_ITEMS`,
            ids,
            filterId,
        })
    }
}
