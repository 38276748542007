import { Col, Row, Section } from '@advanza/advanza_generic'
import AggregatedReviewsFilter from 'components/aggregatedReviews/AggregatedReviewsFilter'
import AggregatedReviewsList from 'components/aggregatedReviews/AggregatedReviewsList'
import SnippetSelectionTool from 'components/aggregatedReviews/SnippetSelectionTool'
import Divider from 'components/Divider'
import NotesFilter from 'components/notes/NotesFilter'
import NotesList from 'components/notes/NotesList'
import DescriptionSelectionTool from 'components/providers/DescriptionSelectionTool'
import ProAvatarEdit from 'components/providers/ProAvatarEdit'
import ProviderScores from 'components/providers/ProviderScores'
import ProvidersLicencesDropdown from 'components/providers/ProvidersLicencesDropdown'
import WebsiteImages from 'components/providers/WebsiteImages'
import PropTypes from 'prop-types'
import React from 'react'
import ProvidersViewContainer from '../../containers/ProvidersViewContainer'
import Avatar from '../adminUsers/Avatar'
import Card from '../Card'
import FilesFilter from '../files/FilesFilter'
import FilesList from '../files/FilesList'
import LinearLoader from '../LinearLoader'
import BackButton from '../ui/BackButton'
import UserView from '../users/UserView'
import ProviderBusinessInfoEntity from './ProviderBusinessInfoEntity'

class ProviderEditView extends React.Component {
    componentDidMount() {
        const { fetchProvider, providerId } = this.props
        fetchProvider(providerId)
        window.scrollTo(0, 0)
    }

    copyLinkToClipboard(ref) {
        if (ref) {
            ref.select()
            setTimeout(ref.select, 10)
            setTimeout(() => document.execCommand('copy'), 100)
        }
    }

    render() {
        const {
            provider,
            providerId,
            providerEntities,
            generateValidateProfileLink,
            services,
        } = this.props
        const filterIdAssociations = `p_${providerId}`
        if (!provider) {
            return <LinearLoader fixed />
        }
        const service = (services && services[provider.serviceId]) || {}
        const { users } = providerEntities
        const user = users[provider.user]
        const isLoaded = provider.notes
        const baseUrl = {
            nl: 'trustoo.nl',
            be: 'trustlocal.be',
            de: 'trustlocal.de',
        }[provider.country_code.toLowerCase()]
        const isTest = window.location.hostname.includes('test.')
        return (
            <div className="container">
                <Row y nm className="v-center">
                    <BackButton fallBack="/service/providers" />
                    <Col c="auto">
                        <Avatar className="md" user={user} />
                    </Col>
                    <Col c="auto">
                        <h1>{provider.business_name}</h1>
                    </Col>
                    {provider.profile_url && (
                        <Col w c="auto">
                            <a
                                className="btn"
                                target="_blank"
                                href={`https://${isTest ? 'test.' : ''}/${baseUrl}/${service.url}/${
                                    provider.cityUrl
                                }/${provider.profile_url}`}>
                                To Profile
                            </a>
                        </Col>
                    )}
                    {provider.profile_url && (
                        <Col w c="auto">
                            {provider._validateProfileLink ? (
                                <input
                                    style={{ textOverflow: 'ellipsis' }}
                                    ref={this.copyLinkToClipboard}
                                    value={provider._validateProfileLink}
                                />
                            ) : (
                                <button
                                    className="btn"
                                    onClick={(e) => generateValidateProfileLink(providerId)}>
                                    Validate Profile
                                </button>
                            )}
                        </Col>
                    )}
                </Row>
                <Row n="min">
                    <Col c={6}>
                        <Card header="Business info" icon="business">
                            <ProAvatarEdit providerId={providerId} entityId={user.id} />
                            <Section s />
                            <ProviderBusinessInfoEntity entityId={providerId} />
                            <Section s />
                            <ProvidersLicencesDropdown entityId={providerId} />
                        </Card>
                        <Section s />
                        <Card header="Scores">
                            <Divider l />
                            <ProviderScores
                                providerId={providerId}
                                entityId={provider.providers_score}
                            />
                        </Card>
                    </Col>
                    <Col c={6}>
                        <Card header="Description" icon="description">
                            <Divider m />
                            <DescriptionSelectionTool providerId={provider.service_provider_id} />
                        </Card>
                        <Divider l />
                        <Card header="Files" icon="insert_drive_file">
                            <FilesFilter
                                filterId={filterIdAssociations}
                                defaultOptions={{
                                    providerIds: [providerId],
                                    pageSize: 10,
                                }}
                                addImageOptions={{ mode: 'providerProfile', providerId }}
                                newEntityOptions={{ context: 'profile', providerId }}
                            />
                            <FilesList
                                compact
                                hideHeader
                                selectable
                                filterId={filterIdAssociations}
                            />
                            <Divider m />
                            <WebsiteImages providerId={providerId} url={provider.website} />
                        </Card>
                        <Section s />
                        <Card header="Aggregated Reviews" icon="folder_special">
                            <AggregatedReviewsFilter
                                filterId={filterIdAssociations}
                                newEntityOptions={{ providerId }}
                                defaultOptions={{ providerIds: [providerId] }}
                            />
                            <AggregatedReviewsList filterId={filterIdAssociations} />
                            {isLoaded && <SnippetSelectionTool provider={provider} />}
                        </Card>
                        <Section s />
                        <Card header="notes" icon="speaker_notes">
                            {isLoaded && (
                                <NotesFilter
                                    filterId={filterIdAssociations}
                                    defaultOptions={{ providerIds: [providerId], pageSize: 5 }}
                                    newEntityOptions={{
                                        type: 'serviceProviders',
                                        foreign_key: providerId,
                                    }}
                                />
                            )}
                            <NotesList filterId={filterIdAssociations} />
                        </Card>
                        <Section s />
                        <Card header={`User #${user.id}`} icon="person">
                            <UserView userId={user.id} useStore="providers" />
                        </Card>
                        <Section s />
                    </Col>
                </Row>
                <Divider l />
                <Divider l />
                <Divider l />
            </div>
        )
    }
}

ProviderEditView.propTypes = {
    fetchProvider: PropTypes.func,
    provider: PropTypes.object,
    providerId: PropTypes.any.isRequired,
}

export default ProvidersViewContainer(ProviderEditView)
