import { Row, Col } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import { PreIcon, LoadingDots } from '@advanza/ui'
import CheckButton from 'components/CheckButton'
import Divider from 'components/Divider'
import CoverageServiceOptions from 'components/serviceInfo/CoverageServiceOptions'
import React from 'react'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'
import ServiceRequestsStatsGeoGraph from './ServiceRequestsStatsGeoGraph'
import style from './ServiceRequestStats.module.css'

class ServiceRequestStats extends React.Component {
    constructor(props) {
        super(props)
        this._changeOptions = this._changeOptions.bind(this)
        this.onChangeDays = this.onChangeDays.bind(this)
    }

    componentDidMount() {
        const { fetchService, fetchStats, serviceId } = this.props
        fetchStats(serviceId, 'requestStats')
        fetchService(serviceId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.serviceId !== this.props.serviceId) {
            const { fetchService, fetchStats } = this.props
            fetchStats(this.props.serviceId, 'requestStats')
            fetchService(this.props.serviceId)
        }
    }

    _changeOptions(options, dontFetch = false) {
        const { serviceId, changeOptions, fetchStats } = this.props
        changeOptions(serviceId, 'requestStats', { ...options, didInvalidate: !dontFetch })
        fetchStats(serviceId, 'requestStats')
    }

    onChangeDays(e) {
        this._changeOptions({ days: e.target.value })
    }

    render() {
        const { serviceId, service, getStatsAndOptions } = this.props
        const { stats, options } = getStatsAndOptions('requestStats')
        if (!service || !service.questions || !stats) {
            return <div className={style.skeleton}> </div>
        }
        const { isFetching } = options
        return (
            <div className={isFetching ? style.loading : ''}>
                <h3>{service && service.name} Request stats</h3>
                <Divider />
                <Row className="v-center">
                    <Col x>
                        <PreIcon icon={isFetching ? <LoadingDots /> : <span />}>
                            <span className="score">{stats.nrReq}</span> requests last&nbsp;
                        </PreIcon>
                    </Col>
                    <Col x>
                        <SelectSimple
                            value={options.days || 30}
                            onChange={this.onChangeDays}
                            options={[
                                { value: 7, name: '7 days' },
                                { value: 10, name: '10 days' },
                                { value: 30, name: '30 days' },
                                { value: 90, name: '3 months' },
                                { value: 180, name: '6 months' },
                                { value: 365, name: '1 year' },
                            ]}
                        />
                    </Col>
                    <Col x>
                        <SelectSimple
                            placeholder="Source"
                            emptyOption="All"
                            options={[
                                { value: 'organic', name: 'Organic' },
                                { value: 'paid', name: 'Paid' },
                            ]}
                            onChange={(e) => this._changeOptions({ source: e.target.value })}
                        />
                    </Col>
                    <Col x>
                        <CheckButton
                            onChange={() =>
                                this._changeOptions(
                                    { allQuestions: options.allQuestions !== true },
                                    true
                                )
                            }
                            msg={<PreIcon icon="check">All questions</PreIcon>}
                            checked={options.allQuestions}
                        />
                    </Col>
                </Row>
                <Divider m />
                <Row>
                    <Col x>
                        <ServiceRequestsStatsGeoGraph serviceId={serviceId} />
                    </Col>
                    <Col x>
                        <CoverageServiceOptions
                            type={'requestStats'}
                            serviceId={serviceId}
                            onChangeValue={(optionIds) =>
                                this._changeOptions(
                                    {
                                        optionIds,
                                    },
                                    true
                                )
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ServiceStatsContainer(ServiceRequestStats)
