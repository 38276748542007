import queryString from 'query-string'

const fetch = require('isomorphic-fetch')

export class Api {
    constructor({ baseEndpoint, isLocal, localBaseEndpoint }) {
        this.baseEndpoint = baseEndpoint
        this.isLocal = isLocal
        this.localBaseEndpoint = localBaseEndpoint
    }

    async call(actionUrl, options = null, apiOptions = {}) {
        const requestOptions = options || {}
        requestOptions.headers = requestOptions.headers || {}
        const isPOST =
            (requestOptions.method && requestOptions.method.toUpperCase() === 'POST') ||
            (requestOptions.method && requestOptions.method.toUpperCase() === 'DELETE') ||
            requestOptions.payload ||
            requestOptions.json

        if (isPOST && !this._getCsrfTokenFromCookie()) {
            await this._requestCsrfToken()
        }
        requestOptions.credentials = 'include'
        if (requestOptions.payload) {
            const formData = new FormData()
            Object.keys(requestOptions.payload).forEach((key) => {
                const data = requestOptions.payload[key]
                if (data instanceof Array) {
                    data.forEach((item) => {
                        formData.append(`${key}[]`, item)
                    })
                } else if (data) {
                    formData.append(key, data)
                }
            })
            requestOptions.body = formData
            requestOptions.method = 'POST'
        }
        if (requestOptions.json) {
            requestOptions.body = JSON.stringify(requestOptions.json)
            requestOptions.method = 'POST'
            requestOptions.headers['Content-Type'] = 'application/json'
            requestOptions.headers['Accept'] = 'application/json'
        }

        if (isPOST) {
            const { body: returnBody, headers: returnHeaders } = this._addCsrfToken(
                requestOptions.body,
                requestOptions.headers
            )
            requestOptions.body = returnBody
            requestOptions.headers = returnHeaders
        }

        if (requestOptions.query) {
            actionUrl += `?${queryString.stringify(options.query, { arrayFormat: 'bracket' })}`
        }

        if (typeof window !== 'undefined' && window._user) {
            requestOptions.headers['X-User-Id'] = window._user.id
        }

        const apiHost = this.getAddress()

        return fetch(apiHost + actionUrl, requestOptions)
            .then(
                (response) => {
                    if (!response.ok) {
                        apiOptions.handleBadResponse &&
                            apiOptions.handleBadResponse({
                                statusText: response.statusText,
                                status: response.status,
                                type: response.type,
                            })

                        if (apiOptions.includeStatusInBadResponse) {
                            return Promise.reject({
                                status: response.status,
                                statusText: response.statusText,
                            })
                        }

                        return Promise.reject(response.statusText)
                    }
                    return response.json()
                },
                (error) => {
                    return Promise.reject(error)
                }
            )
            .then((json) => {
                if (json && json.status === 'success') {
                    return Promise.resolve(json)
                } else {
                    return Promise.reject(json)
                }
            })
    }

    getAddress() {
        if (this.isLocal) {
            return this.localBaseEndpoint
        } else {
            return this.baseEndpoint
        }
    }

    _requestCsrfToken() {
        return call('services/touch', { method: 'GET' })
    }

    _getCsrfTokenFromCookie() {
        const cookieName = 'csrfToken'
        const cookieString = `; ${document.cookie}`
        const prefix = `; ${cookieName}=`
        const start = cookieString.indexOf(prefix) + prefix.length

        if (start !== prefix.length - 1) {
            let end = cookieString.indexOf(';', start)
            if (end === -1) {
                end = cookieString.length
            }
            return decodeURIComponent(cookieString.substring(start, end))
        }

        return null
    }

    _addCsrfToken(requestBody, requestHeaders) {
        const csrfToken = this._getCsrfTokenFromCookie()
        let body = requestBody || ''
        const headers = requestHeaders || {}

        headers['X-CSRF-Token'] = csrfToken

        return { body, headers }
    }
}

export const ApiInstance = new Api({
    baseEndpoint: '/api/',
    isLocal: typeof window !== 'undefined' && window.location.port !== '',
    localBaseEndpoint: `//${window.location.hostname}/api/`,
})

export function call(url, options, apiOptions) {
    return ApiInstance.call(url, options, apiOptions)
}
