import { Col, Row } from '@advanza/grid'
import Toaster from 'components/misc/Toaster'
import { Icon } from '@advanza/ui'
import style from 'components/ui/navigation.module.css'
import stylePage from './Page.module.css'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Navigation from './Navigation'
import NavigationTop from './NavigationTop'
import { Helmet } from 'react-helmet-async'

const Page = ({ title = ' ', children, padding = true, current, hideTop, className = '' }) => {
    const [isCollapsed, setIsCollapsed] = useState(document.documentElement.clientWidth < 1024)
    useEffect(() => {
        if (isCollapsed === 1) {
            setIsCollapsed(false)
        }
    }, [current])

    return (
        <div className={[stylePage.root, className].join(' ')}>
            <Helmet>
                <title>{'Office' + (title ? ' - ' + title : '')}</title>
            </Helmet>
            <Row style={{ maxWidth: '100%' }}>
                <Col
                    xs
                    style={{ maxWidth: isCollapsed ? 50 : 280 }}
                    onClick={() => isCollapsed && setIsCollapsed(false)}>
                    <Navigation isCollapsed={isCollapsed} current={current}>
                        <button
                            className={style.toggle}
                            onClick={() => setIsCollapsed(isCollapsed !== true)}>
                            <Icon
                                name={isCollapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
                            />
                        </button>
                    </Navigation>
                </Col>
                <Col xs style={{ maxWidth: `calc(100% - ${isCollapsed ? 50 : 240}px)` }}>
                    {!hideTop && <NavigationTop title={title} />}
                    <div className={padding ? style.padding : ''}>{children}</div>
                </Col>
            </Row>
            <Toaster />
        </div>
    )
}

Page.propTypes = {
    match: PropTypes.object,
}

export default Page
