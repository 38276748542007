import { toCamelBacked } from '@advanza/func'
import { newContext } from 'immutability-helper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

export const getUpdater = () => {
    const update = newContext()
    update.extend('$deepMerge', function (values, original) {
        const clone = { ...original }
        Object.keys(values).forEach((key) => {
            if (!clone[key]) {
                clone[key] = values[key]
            } else {
                clone[key] = { ...original[key], ...values[key] }
            }
        })

        return clone
    })

    return update
}

/**
 * Change an entity
 *
 * Specify the entity with the reducer store, name of entities and the key e.g.: 'services',
 * 'questions', 1 Merge the entity with the difference object
 *
 * This action will add some values to the entity prefixed with an underscore:
 *  _isTouched: bool whether the entity is different from before the last save. Is set
 * automatically
 *  _beforeSave: object of the entire entity before the last save. Is set automatically
 *  _errors: object e.g.: {name: true|msgId, description: false}. Is set by the EntityComponent
 *  _saving: bool whether: Is set by the EntityComponent
 *
 * these values can be used in your components to show a loading animation for example
 *
 * This function is used by the EntityComponentContainer with the EntityComponent
 * Use this function also in your actions for deleting and adding entities for example
 *
 * @param {Object} options
 * @param options.store {string} the name of the reducer key e.g. 'requests'
 * @param options.name {string} the common name of the entities e.g. 'requests'
 * @param options.diff {object} the changes to be made in the entity e.g: {name: test2}
 * @param [options.key] {number|string} the id of the entity
 * @param [options.replace] {boolean} whether to replace the entity, uses merge by default
 * @param [options.filterId] {any} the ids of the filters that contain this entity in the result.
 * @param [options.remove] {boolean} if true, removes the entity from filterIds
 * @param [options.newKey] {any} change the key
 * @param [options.dontTouch] {boolean} prevents setting the entity to '_isTouched'
 * @param [options.preservePreviousTouch] {boolean} preserve '_isTouched' from previous state (only touch entity is already touched before)
 * @param [options.historyCleanUp] {boolean} prevents setting the '_beforeSave' & '_isTouched' (usefull if changes are already saved)
 * @param [options.simpleEntity] {any} save directly in the main state (no entity key needed)
 * @return {{newKey, preservePreviousTouch, replace, diff, store, type: string, remove, filterId, simpleEntity, entityName, key, dontTouch, historyCleanUp}}
 */
export function changeEntity({
    store,
    name: entityName,
    key,
    replace,
    diff,
    filterId,
    remove,
    newKey,
    dontTouch,
    preservePreviousTouch,
    historyCleanUp,
    simpleEntity,
}) {
    return {
        type: `CHANGE_ENTITY_${store.toUpperCase()}`,
        diff,
        entityName,
        key,
        replace,
        dontTouch,
        preservePreviousTouch,
        newKey,
        store,
        filterId,
        remove,
        historyCleanUp,
        simpleEntity,
    }
}

function objectsAreDifferent(objectA, objectB) {
    const cloneA = { ...objectA }
    const cloneB = { ...objectB }

    const deleteIgnoredKeys = (object) => {
        const keysToDelete = Object.keys(object).filter((key) => key.indexOf('_') === 0)
        keysToDelete.forEach((key) => delete object[key])
    }
    deleteIgnoredKeys(cloneA)
    deleteIgnoredKeys(cloneB)
    return JSON.stringify(cloneA) !== JSON.stringify(cloneB)
}

export function reducerChangeEntity(state, action) {
    const update = getUpdater()
    const {
        key,
        entityName,
        diff = {},
        dontTouch,
        preservePreviousTouch,
        newKey,
        replace,
        historyCleanUp,
        simpleEntity,
    } = action
    const noEntityKey = simpleEntity || entityName === 'result'
    const previousState = noEntityKey
        ? state[entityName]
        : state.entities[entityName] && state.entities[entityName][key]
    const isNew = !previousState
    const changedEntity = isNew || replace ? diff : { ...previousState, ...diff }
    const isTouched = diff._isTouched
        ? true
        : diff._isTouched === false
        ? false
        : dontTouch || historyCleanUp
        ? false
        : preservePreviousTouch
        ? previousState && previousState.hasOwnProperty('_isTouched')
            ? previousState._isTouched
            : false
        : !isNew && previousState._beforeSave
        ? objectsAreDifferent(changedEntity, changedEntity._beforeSave)
        : objectsAreDifferent(changedEntity, previousState)
    const newEntity = {
        ...changedEntity,
        _isTouched: isTouched,
        _beforeSave: historyCleanUp ? undefined : changedEntity._beforeSave || previousState,
        _errors: changedEntity._errors || {},
        _saving: changedEntity._saving || false,
    }

    if (noEntityKey) {
        return update(state, {
            [entityName]: { $set: newEntity },
        })
    }
    const entityKey = newKey || key
    const merge = { [entityName]: { [entityKey]: newEntity } }

    return update(state, {
        entities: {
            $deepMerge: merge,
        },
    })
}

/**
 * Options:
 * - store,
 * - name,
 * optional:
 *  - deleteFunc,
 *  - saveFunc
 * */
export const EntityComponentContainer = (Component, options, connectFunc) => {
    function mapStateToProps(state, props) {
        const { name, simpleEntity } = options
        const store = props.useStore || options.store
        const { entityId = 1, entityKey } = props
        const entities = state[store].entities[name]
        const entity =
            simpleEntity || name === 'result'
                ? state[store][name]
                : entities && entities[entityKey || entityId]
        const additionalMap =
            (options.mapStateToProps && options.mapStateToProps(state, props)) || {}
        return {
            entity,
            ...additionalMap,
        }
    }

    function mapDispatchToProps(dispatch, props) {
        const { name, store, saveFunc, deleteFunc } = options
        const { entityId = 1, entityKey } = props
        const additionalMap =
            (options.mapDispatchToProps && options.mapDispatchToProps(dispatch)) || {}
        return {
            changeEntity: (entity, replace) =>
                dispatch(
                    changeEntity({
                        diff: entity,
                        store: props.useStore || store,
                        name,
                        key: entityKey || entityId,
                        replace,
                    })
                ),
            save: (params) => dispatch(saveFunc(entityKey || entityId, params)),
            delete: (params) => dispatch(deleteFunc(entityKey || entityId, params)),
            ...additionalMap,
        }
    }

    if (connectFunc) {
        return connectFunc(mapStateToProps, mapDispatchToProps)(Component)
    }
    return connect(mapStateToProps, mapDispatchToProps)(Component)
}

function _validate(entity, fields = {}, onChangeEntity) {
    const errors = entity._errors || {}
    let isValid = true
    const newErrors = {}
    Object.keys(fields).forEach((field) => {
        const { validator, errorMsg } = fields[field]

        const value = entity[field]
        const valid = validator ? validator(value, entity) : true

        if (!valid) {
            isValid = false
            newErrors[field] = errorMsg || true
        }
    })

    if (!isValid) {
        onChangeEntity && onChangeEntity({ _errors: { ...errors, ...newErrors } })
    }

    return isValid
}

export function useChangeEntity(options, getFields, renderInputFunc) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const mainState = useSelector((state) => state[options.store])
    const { entities } = mainState
    const entity =
        options.simpleEntity || options.name === 'result'
            ? mainState[options.name]
            : (entities[options.name] &&
                  entities[options.name][options.entityId || options.entityKey]) ||
              {}

    const onChangeEntity = (diff, additional) => {
        dispatch(
            changeEntity({
                ...options,
                key: options.entityId,
                diff,
                ...additional,
            })
        )
        options.afterChange && options.afterChange(diff, additional)
    }

    const fields = typeof getFields === 'function' ? getFields(entity, onChangeEntity) : getFields

    const onSaveEntity = options.saveFunc
        ? () => {
              if (!validate()) {
                  return Promise.reject()
              }
              onChangeEntity({ _saving: true, _beforeSave: entity })

              // also update _saving, _beforeSave for relations
              if (options.relations) {
                  options.relations.forEach(({ relationField, name: _name }) => {
                      const name = _name || toCamelBacked(relationField)
                      const relationValue = entity[relationField]
                      const relationArr = Array.isArray(relationValue)
                          ? relationValue
                          : relationValue
                          ? [relationValue]
                          : []

                      relationArr.forEach((key) =>
                          dispatch(
                              changeEntity({
                                  store: options.store,
                                  name,
                                  key,
                                  diff: {
                                      _saving: true,
                                      _beforeSave: (entities[name] || {})[key] || {},
                                  },
                              })
                          )
                      )
                  })
              }

              return dispatch(options.saveFunc(options.key || options.entityId)).then(
                  (response) => {
                      onChangeEntity({ _saving: false }, { dontTouch: true })

                      // for relations set the primary key and other changes from the server (only send these changed fields back)
                      // update the _beforeSave because these changes don't need saving, update _saving
                      if (options.relations) {
                          options.relations.forEach(({ relationField, name: _name }) => {
                              const name = _name || toCamelBacked(relationField)
                              const relationValue = entity[relationField]
                              const relationArr = Array.isArray(relationValue)
                                  ? relationValue
                                  : relationValue
                                  ? [relationValue]
                                  : []
                              const changes =
                                  (response &&
                                      response.relationChanges &&
                                      response.relationChanges[relationField]) ||
                                  {}

                              relationArr.forEach((key) => {
                                  const change = changes[key] || {}

                                  dispatch((dispatch, getState) => {
                                      const { entities } = getState()[options.store]

                                      dispatch(
                                          changeEntity({
                                              store: options.store,
                                              name,
                                              key,
                                              dontTouch: true,
                                              diff: {
                                                  ...change,
                                                  _beforeSave: {
                                                      ...(((entities[name] || {})[key] || {})
                                                          ._beforeSave || {}),
                                                      ...change,
                                                  },
                                                  _saving: false,
                                              },
                                          })
                                      )
                                  })
                              })
                          })
                      }

                      return Promise.resolve(response)
                  },
                  (response) => {
                      const { error, ...fields } = response || {}
                      if (error === 'fields') {
                          const errors = entity._errors || {}
                          Object.keys(fields).forEach((field) => {
                              let errorValue =
                                  typeof fields[field] === 'string' ? fields[field] : true
                              if (
                                  options.customError &&
                                  fields[field] &&
                                  Object.keys(fields[field]).length > 0
                              ) {
                                  errorValue = fields[field]
                              }
                              errors[field] = errorValue
                          })
                          onChangeEntity({ _errors: errors, _saving: false })
                      } else {
                          onChangeEntity({ _saving: false, _errors: { _response: error } })
                      }
                      return Promise.reject(response)
                  }
              )
          }
        : null

    const onChangeInput = (e) => {
        const errors = { ...(entity._errors || {}), [e.target.name]: false }
        const fieldOptions = fields[e.target.name]
        if (fieldOptions._safeAfter && onSaveEntity) {
            onChangeEntity({
                [e.target.name]: e.target.value,
                _errors: errors,
            })
            return onSaveEntity()
        }
        return onChangeEntity({
            [e.target.name]: fieldOptions.modifier
                ? fieldOptions.modifier(e.target.value)
                : e.target.value,
            _errors: fieldOptions.errorModifier ? fieldOptions.errorModifier(errors) : errors,
        })
    }

    const validate = (fieldsFilter, dontSetErrors) => {
        const validateFields = fieldsFilter ? {} : fields
        if (fieldsFilter) {
            fieldsFilter.forEach((key) => (validateFields[key] = fields[key]))
        }
        return _validate(entity, validateFields, dontSetErrors ? null : onChangeEntity)
    }

    const isVisible = (name) => !fields[name].isHidden

    const onDeleteEntity = options.deleteFunc
        ? () => {
              onChangeEntity({ _saving: true, _beforeSave: entity })
              dispatch(options.deleteFunc(options.key || options.entityId)).then(() => {
                  onChangeEntity({ _saving: false })
              })
          }
        : null
    const renderInput = (name, props = {}) =>
        renderInputFunc(name, entity, fields, onChangeInput, onChangeEntity, props, t)
    const renderError = (name, Component, props = {}) =>
        entity._errors && entity._errors[name] && <Component {...props} />

    const addToRelation = (relationField, diff) => {
        const name =
            (options.relations &&
                (
                    options.relations.find(
                        (relation) => relation.relationField === relationField
                    ) || {}
                ).name) ||
            toCamelBacked(relationField)
        const newId = Math.random().toString(36).substr(2, 5)

        dispatch(
            changeEntity({
                store: options.store,
                name,
                key: newId,
                diff: {
                    ...diff,
                    newId,
                },
            })
        )

        const relationValue = entity[relationField]
        onChangeEntity({
            [relationField]: Array.isArray(relationValue) ? relationValue.concat(newId) : newId,
        })
    }

    return {
        entity,
        onChangeEntity,
        renderError,
        onSaveEntity,
        renderInput,
        onDeleteEntity,
        validate,
        isVisible,
        addToRelation,
    }
}

/**
 * To use the change entity functionality for a generic object directly in the main state (no entity key needed)
 *
 * Options:
 * - store
 * - name
 * - validatorFunc (optional)
 * - saveFunc (optional)
 */
export function useSimpleEntity(options = {}) {
    const dispatch = useDispatch()
    const mainState = useSelector((state) => state[options.store])
    const entity = mainState[options.name] || {}

    const validate = () => {
        if (!options.validatorFunc) {
            return true
        }

        const { isValid, errors = {} } = options.validatorFunc(entity)
        onChangeEntity({
            _isValid: isValid,
            _errors: errors,
        })

        return isValid
    }

    const onChangeEntity = (diff, additional = {}) => {
        dispatch(
            changeEntity({
                ...options,
                diff,
                ...additional,
                simpleEntity: true,
            })
        )
    }

    const onSaveEntity = () => {
        if (!validate()) {
            return Promise.reject()
        }

        if (!options.saveFunc) {
            return Promise.resolve()
        }

        onChangeEntity({ _saving: true, _beforeSave: entity })

        return dispatch(() => options.saveFunc(entity)).then(
            (response) => {
                onChangeEntity({ _saving: false }, { dontTouch: true })
                return Promise.resolve(response)
            },
            (response) => {
                onChangeEntity({ _saving: false, _errors: { _response: response.error } })
                return Promise.reject(response)
            }
        )
    }

    return {
        entity,
        onChangeEntity,
        onSaveEntity,
    }
}
