import { Row, Col } from '@advanza/grid'
import { Icon } from '@advanza/ui'
import { useChangeEntity } from 'hooks/entityHooks'
import React from 'react'

const KeywordVariationEntity = ({ id, keywordId, onDelete, onEnter }) => {
    const isNew = `${id}`.indexOf('new') === 0
    const fields = {
        variation: {
            type: 'text',
            placeholder: 'variatie',
            hidePlaceholder: true,
            autoFocus: isNew,
            validator: (val) => val && val.length > 0,
            onKeyDown: (e) => e.key === 'Enter' && onEnter(),
        },
    }

    const { renderInput } = useChangeEntity(
        {
            store: 'keywords',
            name: 'services_keywords_variations',
            entityId: id,
        },
        fields
    )

    return (
        <Row middle="xs">
            <Col xs>{renderInput('variation')}</Col>
            <Col x>
                <button onClick={() => onDelete(id)}>
                    <Icon name="delete_forever" />
                </button>
            </Col>
        </Row>
    )
}

export default KeywordVariationEntity
