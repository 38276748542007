import React from 'react'
import { call } from '@advanza/api'
import { Button, LoadingDots } from '@advanza/ui'
import { useToast } from 'components/misc/Toaster'

function useRemoveProFromBounceList() {
    const [loading, setLoading] = React.useState(false)
    const { addToast } = useToast()

    const removeProFromBounceList = async (userId: number) => {
        setLoading(true)
        try {
            const {
                notInList,
                emails
            } = await call('office/users/remove-from-sendgrid-bounce-list/' + userId, { method: 'POST' })
            if (notInList) {
                addToast({
                    msg: 'User is not in bounce list' + '\n' + JSON.stringify(emails),
                    orange: true,
                    icon: 'no_accounts'
                })
            } else {
                addToast({
                    msg: `User removed from bounce list`,
                    icon: 'person_remove',
                    green: true
                })
            }
        } catch (e) {
            addToast({ msg: 'Failed to remove pro from bounce list:' + '\n' + e, red: true })
        } finally {
            setLoading(false)
        }
    }

    return { removeProFromBounceList, loading }
}

const RemoveFromBounceListButton = ({ userId = 0 }) => {
    const { removeProFromBounceList, loading } = useRemoveProFromBounceList()
    return <Button name='textReadMore' onClick={() => removeProFromBounceList(userId)} disabled={loading}>
        <small>Remove email from bounce list {loading && <LoadingDots />}</small>
    </Button>
}

export default RemoveFromBounceListButton