import { Icon } from '@advanza/ui'
import FormattedTime from 'components/ui/FormattedTime'
import PropTypes from 'prop-types'
import React from 'react'
import EventListContainer from '../../containers/EventListContainer'
import Card from '../Card'
import TableList from '../TableList'
import Balloon from '../ui/Balloon'
import EventView from './EventView'
import MailsOutTimeLine from './MailsOutTimeLine'

class EventList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { events } = this.props.eventEntities
        const event = events[id]
        const name = event.businessName
        const matchId = event.match_id || ''
        return [
            <small className="text">#{id}</small>,
            event.name,
            name,
            matchId,
            (event.mails_out_id && (
                <Balloon content={<MailsOutTimeLine mailsOut={event.mails_out} />}>
                    <Icon fontSize={18} style={{ color: event.mails_out.delivered && 'limegreen' }}>
                        mail
                    </Icon>
                </Balloon>
            )) ||
                'no mail',
            <Icon
                fontSize={18}
                style={{ color: event.mails_out && event.mails_out.opened && 'blueviolet' }}>
                visibility
            </Icon>,
            <Icon
                fontSize={18}
                style={{ color: event.mails_out && event.mails_out.clicked && 'orange' }}>
                mouse
            </Icon>,
            <FormattedTime date={event.created} />,
        ]
    }

    renderRow(id) {
        return {
            cols: this.renderColumns(id),
            expandOnClick: true,
            expandable: (
                <Card expandableStyle header="Email timeline" icon="email">
                    <EventView eventId={id} />
                </Card>
            ),
            id,
        }
    }

    definitions = {
        id: { name: '#' },
        name: { name: 'name' },
        extra: { name: 'extra' },
        match_id: { name: 'matchId' },
        mail: { name: 'email' },
        opened: { name: 'opened' },
        clicked: { name: 'clicked' },
        // email: { name: 'address' },
        time: { name: 'time' },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

EventList.propTypes = {
    filter: PropTypes.object,
    pages: PropTypes.any,
    eventEntities: PropTypes.object,
    isFetching: PropTypes.bool,
}

export default EventListContainer(EventList)
