import React from 'react'

export const Icon = (props) => {
    const classes = [
        'material-symbols-outlined',
        `${props.color ? `c-${props.color}` : ''}`,
        ` ${props.bg ? 'bg-' + props.bg : ''}`,
        ` ${props.round ? 'round' : ''}`,
    ]

    const sizeClassMap = {
        small: 'sm',
        medium: 'md',
        xs: 'xs',
        large: 'lg',
        xl: 'xl',
    }

    for (const sizeKey in sizeClassMap) {
        if (props[sizeKey]) {
            classes.push(sizeClassMap[sizeKey])
        }
    }

    const classString = classes.join(' ')
    return (
        <i onClick={props.onClick} className={classString}>
            {props.children}
        </i>
    )
}
