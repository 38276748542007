import { changeEntity } from '@advanza/redux_entity'
import { useList } from 'hooks/listHooks'
import { useDispatch } from 'react-redux'
import testimonialsSchema from 'schemes/testimonialsSchema'
import { call } from '@advanza/api'

export function useTestimonialsList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/testimonials/get-list',
            schema: testimonialsSchema,
            reducerKey: 'testimonials',
        },
        defaultOptions
    )
}

export function useAddTestimonial(filterId, options) {
    const dispatch = useDispatch()
    return () => {
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                diff: {
                    testimonial_id: newId,
                    _open: true,
                    ...options,
                },
                key: newId,
                filterId,
                name: 'testimonials',
                store: 'testimonials',
            })
        )
    }
}

export function saveTestimonial(testimonialId) {
    return function (dispatch, getState) {
        const testimonial = getState().testimonials.entities.testimonials[testimonialId]
        const isNew = typeof testimonial.testimonial_id === 'string'

        return call('office/testimonials/save/' + (isNew ? '' : testimonial.testimonial_id), {
            payload: {
                file: testimonial.file || {},
                json: JSON.stringify({
                    ...testimonial,
                    testimonial_id: isNew ? null : testimonial.testimonial_id,
                }),
            },
        }).then(
            ({ id, filename }) => {
                dispatch(
                    changeEntity({
                        store: 'testimonials',
                        name: 'testimonials',
                        key: testimonialId,
                        diff: {
                            testimonial_id: id,
                            filename: filename,
                        },
                    })
                )
            },
            (response) => {
                dispatch(
                    changeEntity({
                        store: 'testimonials',
                        name: 'testimonials',
                        key: testimonialId,
                        diff: {
                            testimonial_id: response.id,
                            _errors: { ...response },
                        },
                    })
                )
            }
        )
    }
}

export function deleteTestimonial(testimonialId) {
    return function (dispatch, getState) {
        const testimonial = getState().testimonials.entities.testimonials[testimonialId]
        const isNew = typeof testimonial.testimonial_id === 'string'
        const removeLabelFromStore = () => {
            dispatch(
                changeEntity({
                    store: 'testimonials',
                    name: 'testimonials',
                    remove: true,
                    key: testimonialId,
                })
            )
        }
        if (isNew) {
            return removeLabelFromStore()
        }
        return call('office/testimonials/delete/' + testimonial.testimonial_id, {
            method: 'post',
        }).then(removeLabelFromStore)
    }
}
