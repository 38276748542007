import { Col, Row } from '@advanza/grid'
import { fetchServices } from 'actions/services'
import { changeOrderPaged } from 'actions/sharedActions'
import ApiEntity, { getApiIconUrl, SPEC } from 'components/apis/ApiEntity'
import Card from 'components/Card'
import OrderButtonArrows from 'components/misc/OrderButtonArrows'
import TableList from 'components/TableList'
import { useList } from 'hooks/listHooks'
import { schema } from 'normalizr'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useApisList = (filterId, defaultOptions = {}) =>
    useList(
        filterId,
        {
            url: 'office/apis/get-list',
            schema: [new schema.Entity('apis', {}, { idAttribute: 'api_id' })],
            reducerKey: 'apis',
        },
        { ...defaultOptions, sorting: ['api_id', 'DESC'] }
    )

const ApisList = ({ filterId = 'all' }) => {
    const dispatch = useDispatch()
    const {
        entities: { apis = {} } = {},
        extra: { definitionsApis = {} } = {},
        ...list
    } = useApisList(filterId)

    const { services = {} } = useSelector((state) => state.services.entities)

    useEffect(() => {
        dispatch(fetchServices())
    }, [dispatch])

    const renderRow = (apiId) => {
        const api = apis[apiId] || {}
        return {
            cols: Object.keys(definitionsApis).map((key) => {
                if (key === 'icon_filename') {
                    return (
                        <div
                            key={key}
                            style={{
                                maxWidth: 50,
                                maxHeight: 500,
                                width: '100%',
                                height: 20,
                                background: `url(${getApiIconUrl(
                                    api
                                )}) no-repeat center center /contain`,
                            }}
                        />
                    )
                } else if (key === 'always_mail_lead') {
                    return api.always_mail_lead ? 'Yes' : 'No'
                } else if (key === 'serviceNames') {
                    return api.all_services
                        ? 'All'
                        : (api.services || [])
                              .map((serviceId) =>
                                  services[serviceId]
                                      ? services[serviceId].country_code +
                                        ' ' +
                                        services[serviceId].name
                                      : ''
                              )
                              .filter((serviceName) => serviceName)
                              .sort()
                              .join(', ')
                } else if (key === 'order_value') {
                    return (
                        <Row key={key} middle="xs">
                            <Col x>{api.order_value}</Col>
                            <Col x>
                                <OrderButtonArrows
                                    clickUp={() =>
                                        changeOrderPaged(SPEC, list.changeFilter, apiId, true)
                                    }
                                    clickDown={() =>
                                        changeOrderPaged(SPEC, list.changeFilter, apiId, false)
                                    }
                                />
                            </Col>
                        </Row>
                    )
                }

                return api[key]
            }),
            expandable: (
                <Card expandableStyle>
                    <ApiEntity apiId={apiId} />
                </Card>
            ),
            expandOnClick: true,
        }
    }

    return (
        <TableList definitions={definitionsApis} renderRow={renderRow} addListFeatures {...list} />
    )
}

export default ApisList
