import { Section } from '@advanza/advanza_generic'
import React from 'react'
import Page from '../ui/Page'
import MatchFilter from './MatchFilter'
import MatchList from './MatchList'

class MatchesPage extends React.Component {
    render() {
        return (
            <Page title="Matches">
                <div className="container">
                    <Section w>
                        <h1>Matches</h1>
                    </Section>
                    <Section y>
                        <MatchFilter
                            filterId="all"
                            defaultOptions={{ pageSize: 40 }}
                            hidePagination
                        />
                    </Section>
                    <MatchList filterId="all" />
                </div>
            </Page>
        )
    }
}

MatchesPage.propTypes = {}

export default MatchesPage
