import React from 'react'
import { connect } from 'react-redux'
import { changeEventFilter } from '../actions/events'

function mapStateToProps(state, props) {
    const { filters, isFetching, result, counts } = state.events
    const filter = filters[props.filterId]
    const filterResult = filter && result[filter.searchKey]
    const count = filter && counts[filter.searchKey]
    const countOnPage = filter && filterResult[filter.page || 0].length
    return {
        filter,
        count,
        countOnPage,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeEventFilter(id, options)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
