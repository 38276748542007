import React from 'react'
import { connect } from 'react-redux'

export const ListContainer = (reducer, additional = () => {}) => {
    function mapStateToProps(state, props) {
        const { entities, result, isFetching, filters } = state[reducer]
        const filter = filters[props.filterId]
        const searchKey = filter && filter.searchKey

        return {
            entities,
            pages: result[searchKey] || {},
            filter,
            isFetching,
            ...additional(state),
        }
    }

    return connect(mapStateToProps)
}

export const FilterContainer = (reducer, dispatchFuncs = {}, additional = () => {}) => {
    function mapStateToProps(state, props) {
        const { filters, isFetching, counts } = state[reducer]
        const filter = filters[props.filterId]
        const count = filter && counts[filter.searchKey]

        return {
            filter,
            count,
            isFetching,
            ...additional(state, props),
        }
    }

    return connect(mapStateToProps, dispatchFuncs)
}
