import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { useChangeEntity } from '@advanza/redux_entity'
import { Divider, Icon } from '@advanza/ui'
import { invalidateNotes } from 'actions/notes'
import { fetchProvider } from 'actions/providers'
import AdminUser from 'components/adminUsers/AdminUser'
import FreeLeadsButton from 'components/providers/FreeLeadsButton'
import FreeTrialButton, { FREE_TRIAL_LEAD_TYPE } from 'components/providers/FreeTrialButton'
import FreeTrialCancelButton from 'components/providers/FreeTrialCancelButton'
import FormattedTime from 'components/ui/FormattedTime'
import SafeButton from 'components/ui/SafeButton'
import { Provider } from 'interfaces/Providers'
import { useAppDispatch } from 'hooks/hooks'
import style from './freeLeads.module.scss'

interface Props {
    providerId: number
}

const FreeLeads = ({ providerId }: Props) => {
    const dispatch = useAppDispatch()
    const { entity = {} } = useChangeEntity({
        store: 'providers',
        name: 'providers',
        entityId: providerId,
    })
    const freeLeads = (entity as Provider).free_leads || []

    const onRevoke = (id: number) => {
        return call('office/providers/revoke-free-leads/' + id, { method: 'post' }).then(
            () => {
                dispatch(fetchProvider(providerId, true))
                dispatch(invalidateNotes())
            },
            () => {
                return Promise.reject()
            }
        )
    }

    return (
        <>
            {freeLeads.map(
                (
                    {
                        nr_leads,
                        nr_leads_left,
                        active,
                        free_lead_id,
                        user_id,
                        comment,
                        created,
                        enable_after_nr_leads_payed,
                        free_lead_type,
                    },
                    i
                ) => {
                    const isFreeTrial = free_lead_type === FREE_TRIAL_LEAD_TYPE

                    return (
                        <Row key={i} className={!active ? style.inActive : ''} between="xs">
                            <Col xs>
                                <b>
                                    {nr_leads_left}/{nr_leads}
                                </b>{' '}
                                leads left from <AdminUser userId={user_id} />{' '}
                                <small>
                                    {/* @ts-ignore */}
                                    <FormattedTime format="dd-MM" date={created} /> {comment}{' '}
                                </small>
                                {typeof enable_after_nr_leads_payed === 'number' &&
                                    enable_after_nr_leads_payed > 0 && (
                                        <>
                                            {' '}
                                            <small>
                                                [require {enable_after_nr_leads_payed} payed leads]
                                            </small>
                                        </>
                                    )}
                                {isFreeTrial && (
                                    <b style={{ color: '#0ea66b' }}>
                                        {' '}
                                        <Icon
                                            name="explosion"
                                            library="symbols"
                                            green
                                            fontSize={16}
                                            style={{
                                                verticalAlign: 'middle',
                                                paddingLeft: 3,
                                            }}
                                        />{' '}
                                        Free trial
                                    </b>
                                )}
                            </Col>
                            <Col x>
                                <SafeButton
                                    buttonText={<Icon name="undo" />}
                                    action={() => onRevoke(free_lead_id)}
                                    // @ts-ignore
                                    confirmMessage={
                                        isFreeTrial ? 'delete free trial' : 'delete free leads'
                                    }
                                    cancelText={false}
                                />
                            </Col>
                        </Row>
                    )
                }
            )}
            <Divider m />
            <FreeLeadsButton providerId={providerId} />
            {!entity.isSigned &&
                !entity.isCancelSigningDate &&
                !entity.isUnsubscribed &&
                entity.subscriptionStatus !== 'unverified' &&
                !entity.isFreeTrial && (
                    <>
                        <Divider m />
                        <FreeTrialButton providerId={providerId} />
                    </>
                )}
            {entity.active_free_trial && (
                <>
                    <Divider m />
                    <FreeTrialCancelButton
                        onCancel={() => onRevoke(entity.active_free_trial.free_lead_id)}
                    />
                </>
            )}
        </>
    )
}

export default FreeLeads
