import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
    selectItems,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    RECLAIMS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    RECLAIMS_RECEIVE_LIST: receiveList,
    RECLAIMS_CHANGE_FILTER: changeFilter,
    RECLAIMS_SELECT_ITEMS: selectItems,
    CHANGE_ENTITY_RECLAIMS: changeEntity,
    INVALIDATE_RECLAIMS: invalidateFilters,
    SET_RECLAIMS: (state, action) => {
        return update(state, action.action)
    },
    REQUEST_RECLAIM_REASONS: (state) =>
        update(state, {
            isFetchingReclaimReasons: { $set: true },
        }),
    RECEIVE_RECLAIM_REASONS: (state, action) =>
        update(state, {
            isFetchingReclaimReasons: { $set: false },
            reclaimReasons: { $set: action.reclaimReasons },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    stats: {},
    filters: {},
    isFetchingReclaimReasons: false,
    reclaimReasons: {},
}

export default createReducer(initialState, handlers)
