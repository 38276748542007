import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import ProfileProjectsAdd from 'components/profileProjects/ProfileProjectsAdd'
import ProfileProjectsEdit from 'components/profileProjects/ProfileProjectsEdit'
import EntityOrderModal from 'components/ui/EntityOrderModal'
import { useProfileProjects } from 'hooks/profileProjectsHooks'
import React from 'react'
import style from './profileProjects.module.css'

const ProfileProjects = ({ providerId }) => {
    const { orderedProjectIds, isFetching, onSaveProjectOrder } = useProfileProjects(providerId)

    return (
        <div className={style.root}>
            {orderedProjectIds.length === 0 ? (
                <div>No projects found</div>
            ) : (
                <div>
                    <div className={style.projectOrder}>
                        <EntityOrderModal
                            store="providers"
                            name="projects"
                            text="projects"
                            buttonText="Change project order"
                            wider
                            disabled={isFetching}
                            orderedIds={orderedProjectIds}
                            renderItem={(item) => {
                                return (
                                    <Row center="xs">
                                        <Col x>
                                            <div>
                                                <strong>
                                                    {item.title} (#{item.profile_project_id})
                                                </strong>
                                            </div>
                                            <div>{item.year}</div>
                                        </Col>
                                    </Row>
                                )
                            }}
                            onSaveItem={onSaveProjectOrder}
                        />
                    </div>
                    {orderedProjectIds.map((projectId) => (
                        <ProfileProjectsEdit projectId={projectId} key={projectId} />
                    ))}
                </div>
            )}
            <Divider m />
            {!isFetching && <ProfileProjectsAdd providerId={providerId} />}
        </div>
    )
}

export default ProfileProjects
