import style from './divider.module.css'

interface Props {
    name?: 'border'
    className?: string
    mobile?: 'xs' | 's' | 'sm' | 'm' | 'ml' | 'l' | 'xl' | 'nxxl' | 'nxxl2'
    nop?: boolean
    one?: boolean
    xxs?: boolean
    xs?: boolean
    s?: boolean
    sm?: boolean
    m?: boolean
    ml?: boolean
    l?: boolean
    xl?: boolean
    xxl?: boolean
    xxxl?: boolean
    nxxl?: boolean
    nxxl2?: boolean
}

const sizes = {
    nop: 0,
    one: 1,
    xxs: 2,
    xs: 4,
    s: 8,
    sm: 12,
    m: 16,
    ml: 24,
    l: 32,
    xl: 48,
    xxl: 60,
    xxxl: 96,
    nxxl: 40,
    nxxl2: 64,
}
const sizeNames = Object.keys(sizes)

function getSizeClassName(props: Props, mobile = false) {
    const propsKeys = Object.keys(props)

    // loop over all sizes to find the first one that is set
    for (let i = 0; i < sizeNames.length; i++) {
        if (!mobile && propsKeys.includes(sizeNames[i]) && style[sizeNames[i]]) {
            return style[sizeNames[i]]
        }
        if (mobile && props.mobile === sizeNames[i] && style['mob_' + sizeNames[i]]) {
            return style['mob_' + sizeNames[i]]
        }
    }

    // if no size is set, return the default size
    return mobile ? '' : style.s
}

/**
 * @param name 'border'
 * @param className additonal classnames
 * @param mobile 'xs' | 's' | 'sm' | 'm' | 'ml' | 'l' | 'xl' | 'nxxl' | 'nxxl2'
 * @param nop 0px
 * @param one 1px
 * @param xxs 2px
 * @param xs 4px
 * @param s 8px
 * @param sm 12px
 * @param m 16px
 * @param ml 24px
 * @param l 32px
 * @param xl 48px
 * @param xxl 60px
 * @param xxxl 96px
 * @param nxxl 40px
 * @param nxxl2 64px
 */

const Divider = ({ name, className, ...rest }: Props) => {
    const sizeClassName = getSizeClassName(rest)
    const sizeClassNameMobile = getSizeClassName(rest, true)
    const inlineStyle = {
        height: 0.01,
    }
    return (
        <div
            style={inlineStyle}
            className={[
                style.root,
                sizeClassName,
                sizeClassNameMobile,
                (name && style[name]) || '',
                className || '',
            ].join(' ')}
        />
    )
}

export default Divider
