import { call } from '@advanza/api'
import { Row, Col } from '@advanza/grid'

import { LinearLoader, Icon, PreIcon, Divider, Button } from '@advanza/ui'
import { saveSubscription } from 'actions/subscriptions'
import ServiceAreaListUltimate from 'components/subscriptions/ServiceAreaListUltimate'
import ServiceAreaMapUltimate from 'components/subscriptions/ServiceAreaMapUltimate'
import ServiceAreaRadius from 'components/subscriptions/ServiceAreaRadius'
import { useChangeEntity } from 'hooks/entityHooks'
import React, { useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import SubscriptionViewContainer from '../../containers/SubscriptionViewContainer'
import Card from '../Card'
import BackButton from '../ui/BackButton'
import SafeButton from '../ui/SafeButton'
import SubscriptionArea from './SubscriptionArea'
import SubscriptionOptions from './SubscriptionOptions'

const SubscriptionView = (props) => {
    const {
        fetchSubscription,
        subscriptionId,
        subscription,
        toggleActiveSubscription,
        deleteSubscription,
        isFetching,
    } = props
    const { renderInput, entity, onChangeEntity } = useChangeEntity(
        {
            store: 'subscriptions',
            name: 'subscriptions',
            entityId: parseInt(subscriptionId),
            saveFunc: saveSubscription,
        },
        {
            exclude_from_listing: {
                type: 'boolean',
                msg: <PreIcon icon="format_list_numbered">Exclude from top10 pages</PreIcon>,
                _safeAfter: true,
            },
        }
    )
    useEffect(() => {
        fetchSubscription(subscriptionId)
    }, [])
    const isLoaded = subscription && subscription.subscriptions_options
    if (!subscription) {
        return <LinearLoader fixed />
    }
    return (
        <div>
            <Row middle="xs">
                <Col x>
                    <BackButton
                        fallBack={`/service/providers/provider/${subscription.service_provider_id}`}
                    />
                </Col>
                <Col x>
                    <h1>Filter #{subscriptionId}</h1>
                </Col>
                <Col x>
                    <button onClick={() => toggleActiveSubscription(subscriptionId)}>
                        <Icon color={!subscription.active && 'green'}>
                            {subscription.active ? 'pause' : 'play_arrow'}
                        </Icon>
                    </button>
                </Col>
                <Col x>
                    <SafeButton
                        action={() => deleteSubscription(subscriptionId)}
                        hidden={subscription.deleted}
                        buttonText={<Icon>delete_forever</Icon>}
                        confirmMessage="CONFIRM DELETE"
                    />
                </Col>
                <Col x>
                    <Link
                        to={`/sales/lead-estimator?subscriptionId=${subscriptionId}&serviceId=${subscription.service_id}&city=`}>
                        Lead estimator
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={subscription.use_municipalities ? 9 : 6}>
                    {!subscription.active && (
                        <Fragment>
                            <PreIcon icon="warning" red>
                                <b style={{ color: 'red' }}>Subscription paused</b>
                            </PreIcon>
                            <Divider m />
                        </Fragment>
                    )}
                    {isLoaded && !subscription.use_municipalities && (
                        <Card header="Service area" icon="maps">
                            <SubscriptionArea {...props} />
                        </Card>
                    )}
                    {subscription.use_municipalities && (
                        <div>
                            <ServiceAreaRadius subscriptionId={subscriptionId} />
                            <Divider m />
                            <Row>
                                <Col xs={5}>
                                    <ServiceAreaListUltimate subscriptionId={subscriptionId} />
                                </Col>
                                <Col xs={7}>
                                    <ServiceAreaMapUltimate
                                        height={500}
                                        subscriptionId={subscriptionId}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
                <Col xs={subscription.use_municipalities ? 3 : 6}>
                    {renderInput('exclude_from_listing', true)}
                    <Divider m />
                    {isLoaded && (
                        <Card header="Options" icon="radio_button_unchecked">
                            <Divider m />
                            <SubscriptionOptions {...props} />
                        </Card>
                    )}
                </Col>
            </Row>
            {isFetching && <LinearLoader fixed />}
        </div>
    )
}

export default SubscriptionViewContainer(SubscriptionView)
