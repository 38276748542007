import { Col, Row, Section } from '@advanza/advanza_generic'
import { Divider } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { saveLabel } from '../../actions/labels'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import EntityComponent from '../services/EntityComponent'

const subcategoryMap = {
    profile: ['platform', 'qualification'],
}

const colorMap = {
    platform: {
        color: '#c4dcff',
        text_color: '#18418e',
    },
    qualification: {
        color: '#ffedd7',
        text_color: '#ff7e00',
    },
}

class LabelEntity extends EntityComponent {
    constructor(props) {
        super(props)
    }

    componentDidUpdate(prevProps) {
        const { entity: label } = this.props

        if (prevProps.entity.subcategory !== label.subcategory && colorMap[label.subcategory]) {
            this.onChangeEntity(colorMap[label.subcategory])
        }
    }

    editFields() {
        const {
            entity: label,
            labelCategories,
            labelSubcategories: _labelSubcategories,
            labelTypes,
        } = this.props
        const labelSubcategories = subcategoryMap[label.category] || _labelSubcategories

        return {
            description: { type: 'text', msg: 'description' },
            category: {
                type: 'select',
                placeholder: 'Category',
                className: 'select-wide',
                options: labelCategories.map((category) => ({
                    value: category,
                    title: category,
                })),
                isHidden: label._customCategory,
            },
            _customCategory: {
                type: 'boolean',
                msg: label._customCategory ? 'new' : '+ new',
            },
            customCategoryField: {
                type: 'text',
                msg: 'Category',
                name: 'category',
                jump: false,
                isHidden: !label._customCategory,
            },
            subcategory: {
                type: 'select',
                placeholder: 'Subcategory',
                className: 'select-wide',
                options: labelSubcategories.map((subcategory) => ({
                    value: subcategory,
                    title: subcategory,
                })),
                isHidden: label._customSubcategory,
            },
            _customSubcategory: {
                type: 'boolean',
                msg: label._customSubcategory ? 'new' : '+ new',
                isHidden: subcategoryMap[label.category],
            },
            customSubcategoryField: {
                type: 'text',
                msg: 'Subcategory',
                name: 'subcategory',
                jump: false,
                isHidden: !label._customSubcategory,
            },
            type: {
                type: 'select',
                placeholder: 'type',
                className: 'select-wide',
                options: labelTypes.map((type) => ({
                    value: type,
                    title: type,
                })),
                isHidden: label._customType,
            },
            _customType: {
                type: 'boolean',
                msg: label._customType ? 'new' : '+ new',
            },
            customTypeField: {
                type: 'text',
                msg: 'Type',
                name: 'type',
                jump: false,
                isHidden: !label._customType,
            },
            icon: {
                type: 'text',
                placeholder: 'icon (optional)',
            },
            color: {
                type: 'color',
            },
            text_color: {
                type: 'color',
            },
            custom: {
                type: 'boolean',
                msg: 'Custom',
            },
            premium: {
                type: 'boolean',
                msg: 'Premium only',
            },
        }
    }

    render() {
        const { entity: label, excludeCustom = false } = this.props

        return (
            <div onKeyPress={({ key, ctrlKey }) => ctrlKey && key === 'Enter' && this.save()}>
                <Row className="v-center between">
                    <Col className="grow"> {this.renderInput('description')}</Col>
                </Row>
                <Section n>
                    <Row className="no-wrap v-center">
                        <Col c="auto">Category:</Col>
                        <Col c="auto">{this.renderInput('category')}</Col>
                        <Col c="auto">{this.renderInput('_customCategory')}</Col>
                        <Col c="auto">{this.renderInput('customCategoryField')}</Col>
                    </Row>
                    <Row className="no-wrap v-center">
                        <Col c="auto">Subcategory:</Col>
                        <Col c="auto">{this.renderInput('subcategory')}</Col>
                        <Col c="auto">{this.renderInput('_customSubcategory')}</Col>
                        <Col c="auto">{this.renderInput('customSubcategoryField')}</Col>
                    </Row>
                    <Row className="no-wrap v-center" n="min">
                        <Col c="auto">Type:</Col>
                        <Col c="auto">{this.renderInput('type')}</Col>
                        <Col c="auto">{this.renderInput('_customType')}</Col>
                        <Col c="auto">{this.renderInput('customTypeField')}</Col>
                    </Row>
                    <Row className="no-wrap v-center" n="min">
                        <Col c="auto">Icon:</Col>
                        <Col c="auto">{this.renderInput('icon')}</Col>
                        <Col c="auto">
                            <small>
                                <em>
                                    icon name from{' '}
                                    <a
                                        href="https://material.io/resources/icons/?style=outline"
                                        target="_blank">
                                        <u>material.io</u>
                                    </a>
                                </em>
                            </small>
                        </Col>
                    </Row>
                    {!colorMap[label.subcategory] && (
                        <Row className="v-center" n="min">
                            <Col c="auto">
                                Background color
                                <Divider m />
                                {this.renderInput('color')}
                            </Col>
                            <Col c="auto">
                                Text color
                                <Divider m />
                                {this.renderInput('text_color')}
                            </Col>
                        </Row>
                    )}
                    {label.category === 'profile' && label.subcategory === 'platform' && (
                        <Row className="v-center" n="min">
                            <Col c="auto">{this.renderInput('premium')}</Col>
                        </Row>
                    )}
                    {!excludeCustom && (
                        <Row className="v-center" n="min">
                            <Col c="auto">{this.renderInput('custom')}</Col>
                        </Row>
                    )}
                </Section>
                <Section n>
                    <button
                        className="btn cta"
                        disabled={label._saving || !label._isTouched}
                        onClick={this.save}>
                        {label._saving ? 'saving..' : 'save'}
                    </button>
                </Section>
            </div>
        )
    }
}

LabelEntity.propTypes = {
    entityId: PropTypes.any.isRequired,
}

export default EntityComponentContainer(LabelEntity, {
    store: 'labels',
    name: 'labels',
    saveFunc: saveLabel,
})
