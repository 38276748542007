import { Col, Row } from '@advanza/grid'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import { addPaymentData } from 'actions/providers'
import Card from 'components/Card'
import CustomPricesList from 'components/customPrices/CustomPricesList'
import EventFilter from 'components/events/EventFilter'
import EventList from 'components/events/EventList'
import LogsFilter from 'components/logs/LogsFilter'
import LogsList from 'components/logs/LogsList'
import WhatsappLogsList from 'components/logs/WhatsappLogsList'
import HubooOrderStatusButton from 'components/misc/HubooOrderStatusButton'
import NotesFilter from 'components/notes/NotesFilter'
import NotesList from 'components/notes/NotesList'
import AvailabilityCalendarDatesList from 'components/pauses/AvailabilityCalendarDatesList'
import PauseDatesList from 'components/pauses/PauseDatesList'
import SnoozeDatesList from 'components/pauses/SnoozeDatesList'
import Brochures from 'components/providers/Brochures'
import CustomerSatisfaction, {
    useCustomerSatisfaction,
} from 'components/providers/CustomerSatisfaction'
import FranchiseSelect from 'components/providers/FranchiseSelect'
import FranchiseSettings from 'components/providers/FranchiseSettings'
import FreeLeads from 'components/providers/FreeLeads'
import LeadBoostersOffice from 'components/providers/LeadBoostersOffice'
import LeadSettings from 'components/providers/LeadSettings'
import LinkExchangeWidget from 'components/providers/LinkExchangeWidget'
import PaymentInfoEntity from 'components/providers/PaymentInfoEntity'
import ProviderActiveCampaign from 'components/providers/ProviderActiveCampaign'
import ProviderBusinessInfoEntity from 'components/providers/ProviderBusinessInfoEntity'
import ProviderLabelPicker from 'components/providers/ProviderLabelPicker'
import ProviderStatus from 'components/providers/ProviderStatus'
import ProvidersPlatformsList from 'components/providers/ProvidersPlatformsList'
import QualityScores from 'components/providers/QualityScores'
import RemoveFromBounceListButton from 'components/providers/RemoveFromBounceListButton'
import SalesPromos from 'components/providers/SalesPromos'
import SubscriptionList from 'components/subscriptions/SubscriptionList'
import SubscriptionsFilter from 'components/subscriptions/SubscriptionsFilter'
import HappinessIcon from 'components/ui/HappinessIcon'
import UserView from 'components/users/UserView'
import auroraStyle from 'css/aurora.module.css'
import { useProvider } from 'hooks/providerHooks'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'

const ProviderViewOverview = ({ providerId }) => {
    const { provider, user = {}, entities } = useProvider(providerId)
    const { recalculateHappiness } = useCustomerSatisfaction(providerId)
    const dispatch = useDispatch()
    const filterIdAssociations = `p_${providerId}`
    const whatsappLogsFilterId = `pro_${providerId}_whatsapp_logs`
    const whatsappLogsOptions = {
        providerIds: [providerId],
        logTypes: ['whatsapp_usage_send', 'whatsapp_usage_received'],
        pageSize: 10,
    }
    return (
        <div>
            <Helmet>
                <title>Office - {provider.business_name}</title>
            </Helmet>
            <Row>
                <Col sm={6}>
                    <Card>
                        <ProviderStatus providerId={providerId} glowing={provider.activeBooster} />
                    </Card>
                    <Divider m />
                    <Card icon="settings" header="Lead settings">
                        <Divider />
                        <LeadSettings
                            entityId={provider.lead_setting}
                            provider={provider}
                            logs={entities.logs}
                        />
                    </Card>
                    <Divider m />
                    <Card icon="money_off" header="Free leads & Free trial">
                        <Divider />
                        <FreeLeads providerId={providerId} />
                    </Card>
                    {!provider.isSignedOrFreeTrial && (
                        <>
                            <Divider m />
                            <Card
                                icon="😴"
                                iconStyle={{
                                    overflow: 'visible',
                                    maxWidth: 'none',
                                    fontFamily: 'sans-serif',
                                }}
                                header="Snooze">
                                <SnoozeDatesList provider={provider} />
                            </Card>
                        </>
                    )}
                    {provider.availabilityCalendarEnabled && (
                        <>
                            <Divider m />
                            <Card icon="calendar_month" header="Availability calendar">
                                <AvailabilityCalendarDatesList provider={provider} />
                            </Card>
                        </>
                    )}
                    <Divider m />
                    <Card icon="pause_circle" header="Pause dates">
                        <PauseDatesList provider={provider} />
                    </Card>
                    <Divider m />
                    {provider.isSigned && (
                        <>
                            <Card
                                className={provider.activeBooster && auroraStyle.aurora}
                                icon="insights"
                                header="Lead booster"
                                infoHover="This will increase the amount of leads that the pro will receive. Requests received during the lead booster period are charged 50%. A pro will not go over the weekly limit with this setting.">
                                <Divider />
                                <LeadBoostersOffice providerId={providerId} />
                            </Card>
                            <Divider m />
                        </>
                    )}
                    <Card icon="local_offer" header="Promos">
                        <Divider />
                        <SalesPromos providerId={providerId} />
                    </Card>
                    <Divider m />
                    <Card icon="preview" header="Link exchange">
                        <Divider />
                        <LinkExchangeWidget providerId={providerId} />
                    </Card>
                    {provider.isSignedOrFreeTrial && (
                        <>
                            <Divider m />
                            <Card icon="menu_book" header="Brochure">
                                <Divider />
                                <Brochures providerId={providerId} />
                            </Card>
                        </>
                    )}
                    <Divider m />
                    <Card header="Business info" icon="business">
                        <ProviderBusinessInfoEntity entityId={providerId} />
                    </Card>
                    <Divider m />
                    {provider.use_active_campaign && (
                        <ProviderActiveCampaign providerId={providerId} />
                    )}
                    <Divider m />
                </Col>
                <Col sm={6}>
                    <Card icon="filter_list" header="Filters">
                        <Divider s />
                        {provider.notes && (
                            <SubscriptionsFilter
                                defaultOptions={{ providerIds: [providerId] }}
                                compact
                                hidePagination
                                providerId={providerId}
                                filterId={filterIdAssociations}
                            />
                        )}
                        <Divider />
                        <SubscriptionList compact hideHeader filterId={filterIdAssociations} />
                    </Card>
                    <Divider m />
                    <Card header="Office labels" icon="label">
                        <ProviderLabelPicker entityId={providerId} wrapValues />
                    </Card>
                    <Divider m />
                    <Card
                        header={
                            <>
                                Notes
                                {provider.freshsalesSalesOwner && (
                                    <span style={{ fontSize: 'smaller' }}>
                                        {' - '} Accountmanager: {provider.freshsalesSalesOwner}
                                    </span>
                                )}
                            </>
                        }
                        icon="speaker_notes">
                        {provider.notes && (
                            <NotesFilter
                                filterId={filterIdAssociations}
                                defaultOptions={{ providerIds: [providerId], pageSize: 10 }}
                                newEntityOptions={{
                                    type: 'serviceProviders',
                                    foreign_key: providerId,
                                }}
                            />
                        )}
                        <NotesList filterId={filterIdAssociations} />
                    </Card>
                    {provider.isSigned && (
                        <>
                            <Divider m />
                            <Card
                                header={
                                    <Row middle="xs">
                                        <Col x>Customer satisfaction</Col>
                                        <Col x style={{ lineHeight: 0 }}>
                                            <Button
                                                name="text"
                                                disabled={provider._isLoadingSatisfaction}
                                                onClick={() => recalculateHappiness()}>
                                                <PreIcon icon="refresh">Recalculate</PreIcon>
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                topRight={
                                    <>
                                        Current mood:{' '}
                                        <HappinessIcon
                                            happiness={provider.last_satisfaction_score?.happiness}
                                        />
                                    </>
                                }
                                icon="sentiment_satisfied">
                                <Divider m />
                                <CustomerSatisfaction providerId={providerId} />
                            </Card>
                        </>
                    )}
                    <Divider m />
                    <Card header="Quality score" icon="thumbs_up_down">
                        <QualityScores providerId={providerId} />
                    </Card>
                    <Divider m />
                    <Card header="Payment" icon="euro_symbol">
                        <Divider />
                        {provider.payment_info && (
                            <PaymentInfoEntity entityId={provider.payment_info} />
                        )}
                        {!provider.payment_info && (
                            <button
                                className="plain"
                                onClick={() => dispatch(addPaymentData(providerId))}>
                                <Icon>add</Icon> Add payment data
                            </button>
                        )}
                        {provider.payment_info && (
                            <>
                                <HubooOrderStatusButton providerId={providerId} />
                            </>
                        )}
                    </Card>
                    <Divider m />
                    <Card header="Custom Prices" icon="euro_symbol">
                        <CustomPricesList providerId={providerId} />
                    </Card>
                    <Divider m />
                    <Card header={`User #${user.id}`} icon="person">
                        <UserView userId={user.id} useStore="providers" />
                    </Card>
                    <Divider m />
                    <Card header="Franchise" icon="layers">
                        <Divider m />
                        {provider.franchise_status ? (
                            <FranchiseSettings providerId={providerId} />
                        ) : (
                            <FranchiseSelect providerId={providerId} />
                        )}
                    </Card>
                    <Divider m />
                    <Card header="Active on" icon="sports_mma">
                        <Divider m />
                        <ProvidersPlatformsList providerId={providerId} />
                    </Card>
                    <Divider m />
                </Col>
            </Row>
            <Card header="Events" icon="event_note" useToggle>
                <EventFilter
                    filterId={filterIdAssociations}
                    defaultOptions={{
                        providerIds: [providerId],
                        pageSize: 10,
                    }}
                />
                <EventList compact filterId={filterIdAssociations} />
                <Divider m />
                <RemoveFromBounceListButton userId={provider.user} />
            </Card>
            <Divider m />
            <Card header="Logs" icon="text_snippet" useToggle>
                <LogsList
                    filterId={`pro_${providerId}_link_exchange`}
                    options={{
                        providerIds: [providerId],
                        excludeLogTypes: ['whatsapp_usage_send', 'whatsapp_usage_received'],
                        pageSize: 100,
                    }}
                />
            </Card>
            <Divider m />
            <Card header="Whatsapp logs" icon="whatsapp_green" s3 useToggle>
                <Divider m />
                <LogsFilter
                    filterId={whatsappLogsFilterId}
                    options={whatsappLogsOptions}
                    selectOptions={[
                        { value: 'whatsapp_usage_send', name: 'Send' },
                        { value: 'whatsapp_usage_received', name: 'Received' },
                    ]}
                />
                <Divider m />
                <WhatsappLogsList filterId={whatsappLogsFilterId} options={whatsappLogsOptions} />
            </Card>
            <div style={{ height: '50vh' }} />
            <Divider xl />
        </div>
    )
}

export default ProviderViewOverview
