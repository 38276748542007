import { capitalize } from '@advanza/func'
import { useScrollModal } from '@advanza/modal'
import { Divider } from '@advanza/ui'
import React from 'react'

export function useDeleteModal(isDeleting = false, onDelete = () => {}, subject = 'item') {
    const { openModal, renderModal } = useScrollModal()
    const modal = renderModal({
        heading: `Delete ${subject}`,
        body: (
            <>
                <span>{`Are you sure you want to delete this ${subject}?`}</span>
                <Divider ml mobile="s" />
            </>
        ),
        buttons: [
            {
                text: isDeleting ? 'Deleting...' : 'Delete',
                name: 'discard',
                func: onDelete,
            },
            {
                text: 'Cancel',
            },
        ],
        maxWidth: '500px',
        classNames: '',
        closeOnOutsideClick: true,
        justifyButtons: 'end',
    })

    return {
        openModal,
        modal,
    }
}

export function useUnsavedChangesModal(
    isSaving = false,
    onSave = () => {},
    onDiscard = () => {},
    subject = 'item'
) {
    const { openModal, renderModal } = useScrollModal()
    const modal = renderModal({
        heading: `${capitalize(subject)} is not saved!`,
        body: (
            <>
                <span>{`The changes have not been saved yet. Do you want to save this ${subject}?`}</span>
                <Divider ml mobile="s" />
            </>
        ),
        buttons: [
            {
                text: 'Cancel',
                name: 'bordered',
            },
            {
                text: 'Discard',
                name: 'discard',
                func: onDiscard,
            },
            {
                text: isSaving ? 'Saving...' : 'Save',
                func: onSave,
            },
        ],
        maxWidth: '500px',
        classNames: '',
        closeOnOutsideClick: true,
    })

    return {
        openModal,
        modal,
    }
}
