import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { receiveList, invalidateFilters, changeFilter } from 'reducers/sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    CUSTOMPRICES_RECEIVE_LIST: receiveList,
    CUSTOMPRICES_CHANGE_FILTER: changeFilter,
    INVALIDATE_CUSTOMPRICES: invalidateFilters,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
