import { Section } from '@advanza/advanza_generic'
import React from 'react'
import Page from '../ui/Page'
import AdminUserFilter from './AdminUserFilter'
import AdminUserList from './AdminUserList'
import CreateAdminUserButton from './CreateAdminUserButton'

class AdminUsersPage extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {}

    render() {
        const {} = this.props

        return (
            <Page title="Admin Users">
                <div className="container">
                    <Section x y>
                        <h1>Admin users</h1>
                        <Section n s="min">
                            <CreateAdminUserButton />
                        </Section>
                        <AdminUserFilter filterId="all" defaultOptions={{ active: true }} />
                        <AdminUserList filterId="all" />
                    </Section>
                </div>
            </Page>
        )
    }
}

AdminUsersPage.propTypes = {}

export default AdminUsersPage
