import { Select } from '@advanza/advanza_generic'
import AutoGrowField from '@advanza/auto_grow_field'
import { parseInputFieldFloat, parseInputFieldInt, parseInputFieldString } from '@advanza/func'
import { InputField, SelectSimple, Toggle } from '@advanza/input'
import { useChangeEntity as _useChangeEntity } from '@advanza/redux_entity'
import { Icon } from '@advanza/ui'
import CheckButton from 'components/CheckButton'
import GeoAutoComplete from 'components/GeoAutoComplete'
import React from 'react'

function onGeoPlaceChange(onChangeEntity, place, map) {
    const components = place.address_components || []
    components.map((component) => {
        const mapToState = component.types && map[component.types[0]]
        if (mapToState) {
            onChangeEntity({ [map[component.types[0]]]: component.long_name })
        }
    })
    Object.keys(map).forEach((key) => {
        if (place[key]) {
            onChangeEntity({ [map[key]]: place[key] })
        }
    })

    const location = place.geometry && place.geometry.location
    if (location && map.lat && map.lng) {
        onChangeEntity({
            [map.lat]: location.lat(),
            [map.lng]: location.lng(),
        })
    }
}

function _renderInput(name, entity, fields, onChangeInput, onChangeEntity) {
    const fieldOptions = fields[name]
    if (fieldOptions.isHidden) {
        return null
    }
    const errors = entity._errors || {}
    const entityKey = fieldOptions.name || name
    const extraProps = {}
    let isValid = true
    let error = errors[entityKey]
    if (fieldOptions.showCheck) {
        isValid = fieldOptions.validator && fieldOptions.validator(entity[entityKey], entity)
        extraProps.after = isValid && !errors[name] && <Icon name="check" />
        extraProps.blueBorderValid = isValid
    }
    if (fieldOptions.maxLength) {
        const fieldLength = (entity[entityKey] && entity[entityKey].length) || 0
        const maxLength = fieldOptions.maxLength
        extraProps.after = (
            <small style={{ color: fieldLength > maxLength && 'red' }}>
                {fieldLength}/{maxLength}
            </small>
        )

        if (fieldLength > maxLength) {
            error = `Maximum ${maxLength} characters`
        }
    }
    if (fieldOptions.customError && error) {
        error = fieldOptions.customError(errors[entityKey])
    }

    const inputFieldSharedProps = {
        value: fieldOptions.valueFormatter
            ? fieldOptions.valueFormatter(entity[entityKey])
            : entity[entityKey],
        ...fieldOptions,
        name: entityKey,
        error,
        ...extraProps,
    }

    switch (fieldOptions.type) {
        case 'boolean':
            const isChecked =
                entity[entityKey] === true || entity[entityKey] === '1' || entity[entityKey] === 1
            return (
                <CheckButton
                    onChange={onChangeInput}
                    value={!isChecked}
                    checked={isChecked}
                    name={entityKey}
                    {...fieldOptions}
                />
            )
        case 'select':
            return (
                <Select
                    value={entity[entityKey]}
                    onChange={fieldOptions.multiple ? null : onChangeInput}
                    error={error}
                    {...fieldOptions}
                    name={entityKey}
                />
            )
        case 'selectSimple':
            return (
                <SelectSimple
                    value={entity[entityKey]}
                    onChange={onChangeInput}
                    error={error}
                    {...fieldOptions}
                    name={entityKey}
                />
            )
        case 'autoGrow':
            return (
                <AutoGrowField
                    value={entity[entityKey]}
                    {...fieldOptions}
                    name={entityKey}
                    onChange={onChangeInput}
                    type={fieldOptions.type}
                    error={error}
                />
            )
        case 'geo':
            return (
                <GeoAutoComplete
                    name={entityKey}
                    icon={false}
                    state={{
                        value: entity[entityKey],
                        error: error,
                    }}
                    options={{
                        types: fieldOptions.types || ['establishment'],
                        componentRestrictions: { country: fieldOptions.country || 'NL' },
                    }}
                    onPlaceChange={(place) =>
                        onGeoPlaceChange(onChangeEntity, place, fieldOptions.map || {})
                    }
                    {...fieldOptions}
                    onChange={onChangeInput}
                />
            )
        case 'toggle':
            return (
                <Toggle
                    name={entityKey}
                    value={entity[entityKey]}
                    onChange={onChangeInput}
                    {...fieldOptions}
                />
            )
        case 'string':
            return (
                <InputField
                    legend
                    onChange={(e) =>
                        onChangeInput({
                            target: {
                                value: parseInputFieldString(e.target.value, fieldOptions.fallback),
                                name: entityKey,
                            },
                        })
                    }
                    onBlur={(e) =>
                        onChangeInput({
                            target: {
                                value: parseInputFieldString(
                                    e.target.value.trim(),
                                    fieldOptions.fallback
                                ),
                                name: entityKey,
                            },
                        })
                    }
                    {...inputFieldSharedProps}
                    type="text"
                />
            )
        case 'int':
        case 'float':
            return (
                <InputField
                    legend
                    step={fieldOptions.type === 'int' ? 1 : 0.01}
                    min="0"
                    inputStyle={{ appearance: 'auto' }}
                    onChange={(e) =>
                        onChangeInput({
                            target: {
                                value:
                                    fieldOptions.type === 'int'
                                        ? parseInputFieldInt(e.target.value, fieldOptions.fallback)
                                        : parseInputFieldFloat(
                                              e.target.value,
                                              fieldOptions.fallback
                                          ),
                                name: entityKey,
                            },
                        })
                    }
                    {...inputFieldSharedProps}
                    type="number"
                />
            )
        default:
            return <InputField {...inputFieldSharedProps} onChange={onChangeInput} />
    }
}

export function useChangeEntity(options, fields = {}) {
    return _useChangeEntity(options, fields, _renderInput)
}

export const validateDetailed = (name, validate, fields = {}, entity = {}, id = null) => {
    const errors = []

    if (!validate()) {
        Object.keys(fields).forEach((field) => {
            const { validator, errorMsg } = fields[field]
            validator && !validator(entity[field], entity) && errors.push(errorMsg)
        })
    }

    return {
        name,
        id,
        isValid: !errors.length,
        errors,
    }
}
