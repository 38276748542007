import { Section, Icon } from '@advanza/advanza_generic'
import Card from 'components/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedTime } from 'react-intl'
import AdminUserListContainer from '../../containers/AdminUserListContainer'
import TableList from '../TableList'
import AdminUserEntity from './AdminUserEntity'

class AdminUserList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { adminUsers } = this.props.entities
        const user = adminUsers[id]
        const name = user.first_name + ' ' + user.last_name
        return [
            <small className="c-grey">#{id}</small>,
            user.active ? user.email : <strike>{user.email}</strike>,
            user.active ? name : <strike>{name}</strike>,
            <Icon small color={user.active ? 'green' : 'magenta'}>
                {user.active ? 'verified_user' : 'power_off'}
            </Icon>,
            <FormattedTime value={user.created} format="short" />,
        ]
    }

    renderRow(id) {
        const { permissions, sendPasswordMail } = this.props
        const { adminUsers } = this.props.entities
        const user = adminUsers[id]
        return {
            cols: this.renderColumns(id),
            expandOnClick: true,
            isLoading: user._saving,
            expandable: (
                <Card expandableStyle>
                    <AdminUserEntity
                        permissions={permissions}
                        sendPasswordMail={sendPasswordMail}
                        entityId={id}
                    />
                </Card>
            ),
            id,
        }
    }

    definitions = {
        id: { name: '#' },
        email: { name: 'email' },
        name: { name: 'name' },
        active: { name: 'active' },
        time: { name: 'time' },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

AdminUserList.propTypes = {
    filter: PropTypes.object,
    pages: PropTypes.any,
    isFetching: PropTypes.bool,
}

export default AdminUserListContainer(AdminUserList)
