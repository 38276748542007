import { Col, Row } from '@advanza/grid'
import { Button, Divider, PreIcon, Toggle } from '@advanza/ui'
import { onFilesRejected } from 'actions/files'
import { saveUser } from 'actions/users'
import AvatarEdit from 'components/AvatarEdit'
import CheckButton from 'components/CheckButton'
import LinearLoader from 'components/LinearLoader'
import EntityComponent from 'components/services/EntityComponent'
import FormattedTime from 'components/ui/FormattedTime'
import EmailAddressEdit from 'components/users/EmailAddressEdit'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import { Fragment } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import style from './userEntity.module.css'

class UserEntity extends EntityComponent {
    constructor(props) {
        super(props)
        this.getSetting = this.getSetting.bind(this)
        this.mobilePhoneIsValid = true
        this.onChangeAvatar = this.onChangeAvatar.bind(this)
        this.onCrop = this.onCrop.bind(this)
        this.options = {
            store: props.useStore,
            relations: [{ relationField: 'email_addresses' }],
        }
        this.emailAddressValidates = {}
    }

    mobilePhoneValidator = (val) => !val || this.mobilePhoneIsValid
    textValidator = (val) => val && val.length > 0

    editFields() {
        const { country_code } = this.props.entity
        return {
            gender: {
                type: 'select',
                className: 'select-min select-stealth fsize lg',
                options: [
                    { value: 1, title: 'Mr' },
                    { value: 2, title: 'Mrs' },
                    { value: 9, title: 'Mx' },
                ],
                validator: (val) => val === 1 || val === 2 || val === 0 || val === 9,
            },
            first_name: { type: 'text', msg: 'First Name' },
            last_name: { type: 'text', msg: 'Last name' },
            email: { type: 'email', validator: isEmail, msg: 'Email' },
            email_notifications: {
                type: 'email',
                validator: (val) => !val || isEmail(val),
                msg: 'Email Notifications',
            },
            phone: { type: 'phone', msg: 'Phone' },
            phone_notifications_display: {
                type: 'mobilePhone',
                defaultCountry: country_code.toString().toLowerCase() || 'nl',
                msg: 'Phone notifications display',
                validationCallback: (isValid) => {
                    this.mobilePhoneIsValid = isValid
                },
                validator: this.mobilePhoneValidator,
            },
            fc_whatsapp_enabled: {
                type: 'boolean',
                msg: 'WhatsApp',
            },
            country_code: {
                type: 'select',
                className: 'select-min fsize lg',
                placeholder: 'Country',
                options: [
                    { value: 'NL', title: 'NL' },
                    { value: 'BE', title: 'BE' },
                    { value: 'AT', title: 'AT' },
                ],
            },
            active: {
                type: 'boolean',
                reverse: true,
                msg: 'Disabled',
                className: 'negative',
            },
            username: {
                type: 'text',
                placeholder: 'username',
            },
            password_changed: {
                type: 'text',
                placeholder: 'password changed',
                disabled: true,
            },
            modified: {
                type: 'text',
                placeholder: 'last modified',
                disabled: true,
            },
            created: {
                type: 'text',
                placeholder: 'created',
                disabled: true,
            },
            token_expires: {
                type: 'text',
                placeholder: 'Token expires',
                disabled: true,
            },
        }
    }

    save() {
        return super
            .save({ store: this.props.useStore, withEmailAddresses: true })
            .then(null, (response) => {
                alert(JSON.stringify(response))
            })
    }

    onChangeNotificationSetting(setting, e) {
        const { value, name } = e.target
        const { entity } = this.props
        this.onChangeEntity({
            notification_settings: entity.notification_settings.map((oldSetting) => {
                if (oldSetting.notification_id === setting.notification_id) {
                    return { ...oldSetting, [name]: value }
                }
                return oldSetting
            }),
        })
    }

    getSetting(notificationId) {
        const { entity } = this.props
        let setting =
            entity.notification_settings.filter(
                (setting) => setting.notification_id === notificationId
            )[0] || null
        if (!setting) {
            setting = {
                user_id: entity.id,
                notification_id: notificationId,
                email: true, // if no email settings, consider them as subscribed (opt-out model)
            }
            this.onChangeEntity({
                notification_settings: entity.notification_settings.concat([setting]),
            })
        }

        return setting
    }

    onChangeAvatar(file) {
        const { avatarFile = {} } = this.props.entity
        this.onChangeEntity({
            avatarFile: { ...avatarFile, ...file },
        })
    }

    onCrop(crop, crop2) {
        const { avatarFile = {} } = this.props.entity
        this.onChangeEntity({
            avatarFile: { ...avatarFile, crop: { ...crop, aspect: 1 }, crop2 },
        })
    }

    validate() {
        const { entity } = this.props
        const emailAddressIds = entity.email_addresses || []
        let valid = super.validate()
        emailAddressIds.forEach(
            (emailAddressId) => (valid = this.emailAddressValidates[emailAddressId]() && valid)
        )
        return valid
    }

    render() {
        const {
            useStore,
            entity,
            entities,
            notifications,
            notificationIds,
            profileMode,
            hideSave,
            onFilesRejected,
        } = this.props
        const emailAddressIds = entity.email_addresses || []
        const { emailAddresses = {} } = entities

        const isTouched = () =>
            entity._isTouched ||
            emailAddressIds.some(
                (emailAddressId) => (emailAddresses[emailAddressId] || {})._isTouched
            )

        if (profileMode) {
            return (
                <div>
                    {entity._saving && <LinearLoader />}
                    <Divider m />
                    <Row middle="xs">
                        <Col x>{this.renderInput('email')}</Col>
                    </Row>
                    <Divider m />
                    <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                        <Col x>{this.renderInput('first_name')}</Col>
                        <Col x>{this.renderInput('last_name')}</Col>
                    </Row>
                    <Divider m />
                    {!hideSave && (
                        <Row end="xs">
                            <Col x>
                                <Button disabled={!isTouched()} onClick={this.save}>
                                    {entity._saving ? 'saving..' : 'Save'}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>
            )
        }

        return (
            <div>
                {entity._saving && <LinearLoader />}
                {entity.fc_restore_id && (
                    <div>
                        <PreIcon icon="chat" green>
                            Has chat history in FreshChat
                        </PreIcon>
                        <Divider s />
                    </div>
                )}
                <Row middle="xs">
                    <Col x>
                        <AvatarEdit
                            onChangeImage={this.onChangeAvatar}
                            onCrop={this.onCrop}
                            size={50}
                            user={entity}
                            crop={(entity.avatarFile && entity.avatarFile.crop) || {}}
                            file={entity.avatarFile}
                            onFilesRejected={onFilesRejected}
                        />
                    </Col>
                    <Col x>{this.renderInput('email')}</Col>
                    <Col x>{this.renderInput('active')} </Col>
                    <Col x> {this.renderInput('country_code')} </Col>
                </Row>
                <Divider m />
                <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                    <Col x>{this.renderInput('gender')}</Col>
                    <Col x>{this.renderInput('first_name')} </Col>
                    <Col x>{this.renderInput('last_name')} </Col>
                </Row>
                <Divider m />
                <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                    <Col x>{this.renderInput('username')}</Col>
                    <Col x>{this.renderInput('phone')}</Col>
                </Row>
                <Divider m />
                <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                    <Col x>{this.renderInput('fc_whatsapp_enabled')}</Col>
                    <Col x>{this.renderInput('phone_notifications_display')}</Col>
                    <Col x>phone_notifications: {entity.phone_notifications}</Col>
                </Row>
                <Divider m />
                {this.renderInput('email_notifications')}
                {emailAddressIds.map((emailAddressId, i) => (
                    <Fragment key={emailAddressId}>
                        <Divider m />
                        <EmailAddressEdit
                            useStore={useStore}
                            entityId={emailAddressId}
                            addValidate={(validate) =>
                                (this.emailAddressValidates[emailAddressId] = validate)
                            }
                            num={i + 2}
                        />
                    </Fragment>
                ))}
                <Divider m />
                <Button
                    name="text"
                    onClick={() =>
                        this.addToRelation('email_addresses', { user_id: entity.id, email: '' })
                    }>
                    <PreIcon fontSize={24} name="add" primColor>
                        Add email notifications address
                    </PreIcon>
                </Button>
                <Divider m />
                <div className={style.notificationSettings}>
                    {notificationIds &&
                        entity.notification_settings &&
                        notificationIds.map((id) => {
                            const notification = notifications[id]
                            if (
                                notification.for_type ===
                                (entity.service_provider_id ? 'pro' : 'demand')
                            ) {
                                const setting = this.getSetting(id)
                                return (
                                    <Fragment key={id}>
                                        <span className="cap">
                                            {notification.name.replace(/\_/g, ' ')}
                                        </span>
                                        <CheckButton
                                            name="email"
                                            msg="email"
                                            checked={setting.email}
                                            value={!setting.email}
                                            onChange={this.onChangeNotificationSetting.bind(
                                                this,
                                                setting
                                            )}
                                        />
                                    </Fragment>
                                )
                            }
                        })}
                </div>
                <Divider m />
                <Row>
                    <Col x>Last login:</Col>
                    <Col x>
                        {entity.last_login ? (
                            <FormattedTime date={entity.last_login} format="dd-MM-yyyy HH:mm:ss" />
                        ) : (
                            'Unknown'
                        )}
                    </Col>
                </Row>
                <Toggle
                    title="Show more fields"
                    showIcon
                    bottomToggle
                    extraTitleClasses={[style.showMoreFields]}>
                    <Divider m />
                    <Row>
                        <Col x>{this.renderInput('created')}</Col>
                        <Col x>{this.renderInput('token_expires')}</Col>
                    </Row>
                    <Divider m />
                    <Row>
                        <Col x>{this.renderInput('password_changed')}</Col>
                        <Col x>{this.renderInput('modified')}</Col>
                    </Row>
                </Toggle>
                <Divider m />
                {!hideSave && (
                    <Row end="xs">
                        <Col x>
                            <Button disabled={!isTouched()} onClick={this.save}>
                                {entity._saving ? 'saving..' : 'Save'}
                            </Button>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }
}

UserEntity.propTypes = {}

export default EntityComponentContainer(UserEntity, {
    name: 'users',
    saveFunc: saveUser,
    mapDispatchToProps: (dispatch) => {
        return {
            onFilesRejected: (files) => dispatch(onFilesRejected(files)),
        }
    },
})
