import React from 'react'
import PropTypes from 'prop-types'
import style from './a.module.css'
import { Link } from 'react-router-dom'

const A = ({ className, children, greyLink, external, ...rest }) => {
    const props = {
        className: style[className] || className || '',
        ...rest,
    }
    if (external) {
        return <a {...props}>{children}</a>
    } else {
        return <Link {...props}>{children}</Link>
    }
}

A.propTypes = {}

export default A
