import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchService } from '../actions/services'

export function useOptions(serviceId, onlyFilterable = false) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchService(serviceId))
    }, [serviceId])

    const {
        services = {},
        options: _options = {},
        questions: _questions = {},
    } = useSelector((state) => state.services.entities)
    const questionIds = (services[serviceId] || {}).questions || []
    const questions = {}
    const options = {}

    questionIds.forEach((questionId) => {
        if (!onlyFilterable || _questions[questionId].filterable) {
            questions[questionId] = _questions[questionId]
        }
    })

    Object.values(_options).forEach((option) => {
        if (questions[option.question_id]) {
            options[option.option_id] = option
        }
    })

    return {
        questions,
        options,
    }
}
