import React from "react"
import PropTypes from "prop-types"

class KeyPressHandler extends React.Component {
    constructor(props) {
        super(props)

        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress)
    }

    handleKeyPress(e) {
        if (!e.key) {
            return
        }

        const {handler, onEnter} = this.props
        handler && handler(e.key)
        onEnter && e.key === 'Enter' && onEnter()
    }

    render() {
        return null
    }

}

KeyPressHandler.propTypes = {
    handler: PropTypes.func,
    onEnter: PropTypes.func
}

export default KeyPressHandler