import { ReactNode } from 'react'

interface Props {
    children: ReactNode
    className?: string
    maxWidth?: number
    left?: boolean
    right?: boolean
}

const MaxWidth = ({ children, className, maxWidth = 1300, left = false, right = false }: Props) => {
    return (
        <div
            className={className}
            style={{
                maxWidth,
                ...(left
                    ? {
                          marginLeft: 0,
                          marginRight: 'auto',
                      }
                    : right
                    ? {
                          marginLeft: 'auto',
                          marginRight: 0,
                      }
                    : {
                          marginRight: 'auto',
                          marginLeft: 'auto',
                      }),
            }}>
            {children}
        </div>
    )
}

export default MaxWidth
