import { APP } from '@advanza/advanza_generic'
import React from 'react'
import { useSelector } from 'react-redux'

const OfficeLogo = () => {
    const { countryCode } = useSelector((state) => state.countries)[0] || {}
    const logoName =
        {
            NL: 'trustoologo_2+.svg',
            BE: 'trustlocal_logo_be_2+.svg',
            DE: 'trustlocal_logo_de_2+.svg',
        }[countryCode] || 'trustoologo_2+.svg'

    return <img width={100} src={`${APP.S3_PUBLIC()}${logoName}`} />
}

export default OfficeLogo
