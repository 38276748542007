import React, { useState } from 'react'
import { Row, Col } from '@advanza/grid'
import { useChangeEntity } from 'hooks/entityHooks'
import { saveKeyword } from 'hooks/keywordHooks'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useOptions } from 'hooks/optionHooks'
import { Divider, Button, Icon, BasicMdParser } from '@advanza/ui'
import KeywordVariationEntity from './KeywordVariationEntity'
import { changeEntity } from '@advanza/redux_entity'
import { useSelector, useDispatch } from 'react-redux'
import Card from 'components/Card'

const fieldsFunc = (_options, questions) => {
    return {
        keyword: { type: 'text', placeholder: 'Name', msg: 'Name' },
        option_id: {
            type: 'select',
            msg: 'filter',
            className: 'select-min select-stealth',
            options: Object.keys(_options).map((key) => {
                return {
                    value: _options[key].option_id,
                    title:
                        questions[_options[key].question_id].question + ' | ' + _options[key].value,
                }
            }),
        },
        active: { type: 'boolean', msg: 'Active' },
        category: { type: 'boolean', msg: 'Category' },
    }
}

const _isTouched = (entity, keywordVariations) => {
    const entityHasChanged =
        entity.services_keywords_variations &&
        entity.services_keywords_variations.filter(
            (id) => keywordVariations[id] && keywordVariations[id]._isTouched
        ).length

    return entityHasChanged || entity._isTouched
}

const KeywordEntity = ({ id, serviceId }) => {
    const dispatch = useDispatch()
    const { options: _options = {}, questions = {} } = useOptions(serviceId, true)
    const fields = fieldsFunc(_options, questions)
    const { services_keywords_variations = {} } = useSelector((state) => state.keywords.entities)
    const { onChangeEntity, entity, onSaveEntity, renderInput } = useChangeEntity(
        {
            store: 'keywords',
            name: 'keywords',
            entityId: id,
            saveFunc: saveKeyword,
        },
        fields
    )

    const untouchEntities = () => {
        entity.services_keywords_variations.forEach((id) => {
            dispatch(
                changeEntity({
                    store: 'keywords',
                    name: 'services_keywords_variations',
                    key: id,
                    diff: { _beforeSave: services_keywords_variations[id] },
                    dontTouch: true,
                })
            )
        })
    }

    const isTouched = _isTouched(entity, services_keywords_variations)

    const onSave = () => onSaveEntity().then(untouchEntities)
    const addNew = () => {
        onChangeEntity({
            services_keywords_variations: entity.services_keywords_variations.concat([
                `new_${Date.now()}`,
            ]),
        })
    }

    const onEnter = () => onSave() && addNew()
    const onDelete = (key) => {
        onChangeEntity({
            services_keywords_variations: entity.services_keywords_variations.filter(
                (id) => id !== key
            ),
        })
        onSave()
    }
    return (
        <Card>
            <Row>
                <Col xs>
                    <h4>Keyword</h4>
                    <Divider m />
                    <Row middle={'xs'}>
                        <Col xs={8}>{renderInput('keyword')}</Col>
                        <Col xs={2}>{renderInput('active')}</Col>
                        <Col xs={2}>{renderInput('category')}</Col>
                    </Row>
                    <Divider m />
                    {renderInput('option_id')}
                </Col>
                <Col xs>
                    <h4>Keyword variations</h4>
                    <Divider m />
                    {entity.services_keywords_variations &&
                        entity.services_keywords_variations.map((key) => (
                            <KeywordVariationEntity
                                id={key}
                                keywordId={id}
                                onDelete={onDelete}
                                onEnter={onEnter}
                            />
                        ))}
                    <Button onClick={addNew} name="text">
                        Add variation
                    </Button>
                </Col>
            </Row>
            <Divider m />
            <Button type="text" onClick={onSaveEntity} disabled={!isTouched || entity._saving}>
                {entity._saving ? '...saving' : 'Save'}
            </Button>
        </Card>
    )
}

export default KeywordEntity
