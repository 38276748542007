import { call } from '@advanza/api'
import { HoverText, PreIcon, Divider, LoadingDots } from '@advanza/ui'
import { fetchNotes } from 'actions/notes'
import { fetchProvider } from 'actions/providers'
import CalendlyModal from 'components/providers/CalendlyModal'
import FormattedTime from 'components/ui/FormattedTime'
import { useInterval } from 'hooks/miscHooks'
import { useProvider } from 'hooks/providerHooks'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ONBOARDING_SLUGS = ['onboarding-trustoo', 'onboarding-trustlocal']

function useCalendlyOnboardingOverview(providerId) {
    const dispatch = useDispatch()
    const { checkForUpdates } = useSelector((state) => state.providers.calendly)
    const { provider = {}, entities } = useProvider(providerId)
    const { calendlyEvents = {}, calendlyEventTypes = {} } = entities

    // filter for onboarding & active appointment
    const appointmentsIdsList =
        (provider.calendly_events &&
            provider.calendly_events.filter((eventId) => {
                const event = calendlyEvents[eventId]
                const eventType = event && calendlyEventTypes[event.calendly_event_type_id]
                return (
                    eventType &&
                    ONBOARDING_SLUGS.includes(eventType.slug) &&
                    event.status === 'active'
                )
            })) ||
        []

    // only signed providers (since calendly webhook is live)
    const appointmentNeeded =
        appointmentsIdsList.length === 0 &&
        provider.isSigned &&
        new Date(provider.isSigned) > new Date('2022-07-01') && 
        !provider.ob_done

    const onCheckForUpdates = () => {
        call(
            `calendly/check-new-onboarding-events/${providerId}/${Math.max(
                0,
                ...appointmentsIdsList
            )}`
        ).then((response) => {
            const { hasNewEvents, activeEvents } = response
            if (hasNewEvents) {
                // stop check for updates
                dispatch({ type: 'CALENDLY_UPDATES_COMPLETED' })

                // update provider data
                dispatch(fetchProvider(providerId, true))

                // invalidate notes
                dispatch({ type: 'INVALIDATE_NOTES' })
                dispatch(fetchNotes())
            }
        })
    }

    useInterval(
        () => {
            onCheckForUpdates()
        },
        checkForUpdates ? 10000 : null
    )

    return {
        calendlyEvents,
        appointmentsIdsList,
        appointmentNeeded,
        checkForUpdates,
    }
}

const CalendlyOverview = ({ providerId }) => {
    const { calendlyEvents, appointmentsIdsList, appointmentNeeded, checkForUpdates } =
        useCalendlyOnboardingOverview(providerId)

    return (
        <>
            {appointmentNeeded ? (
                <CalendlyModal providerId={providerId} calendlyEvent={null} />
            ) : (
                appointmentsIdsList.map((eventId, index) => {
                    const event = calendlyEvents[eventId]
                    const status = event.rescheduled ? 'rescheduled' : event.status
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                columnGap: '24px',
                            }}>
                            <HoverText
                                key={index}
                                trigger={
                                    <PreIcon icon="phone_forwarded" orange fontSize={18}>
                                        <span
                                            style={{
                                                color: '#f46511',
                                                textDecoration:
                                                    status !== 'active' ? 'line-through' : 'none',
                                            }}>
                                            <FormattedTime
                                                date={event.start_time}
                                                format="dd-MM-yyyy HH:mm"
                                            />
                                        </span>
                                    </PreIcon>
                                }>
                                <div>
                                    <strong>Scheduled onboarding call</strong>
                                </div>
                                <Divider m />
                                <ul>
                                    <li>Name: {event.name}</li>
                                    <li>Email: {event.email}</li>
                                    {event.phone && <li>Phone: {event.phone}</li>}
                                    <li>
                                        Start time call:{' '}
                                        <FormattedTime
                                            date={event.start_time}
                                            format="dd-MM-yyyy HH:mm"
                                        />
                                    </li>
                                    <li>Call with: {event.agent}</li>
                                    <li>Status: {status}</li>
                                </ul>
                            </HoverText>
                            {status == 'active' && (
                                <>
                                    {' '}
                                    <CalendlyModal providerId={providerId} calendlyEvent={event} />
                                </>
                            )}
                        </div>
                    )
                })
            )}
            {checkForUpdates && (
                <PreIcon name="edit_calendar" fontSize={18} orange>
                    <span
                        style={{
                            color: '#f46511',
                        }}>
                        Calendly update is being processed{' '}
                        <LoadingDots
                            size={3}
                            style={{ verticalAlign: 'text-bottom' }}
                            background="#f46511"
                        />
                    </span>
                </PreIcon>
            )}
        </>
    )
}

export default CalendlyOverview
