import A from 'components/ui/A'
import React from 'react'
import style from './menuRow.module.css'

const StepsRow = ({ items, selected, onChange }) => {
    return (
        <div className={style.root}>
            <div className={style.wrapper}>
                {items.map((item, i) => {
                    const isSelected = item.id === selected
                    const midIndex = Math.round(items.length / 2)
                    const position =
                        i + 1 < midIndex ? 'right' : i + 1 === midIndex ? 'center' : 'left'
                    const classNames = [
                        isSelected ? style.itemSelected : style.item,
                        style[position],
                    ].join(' ')
                    if (item.url) {
                        return (
                            <A replace className={classNames} to={item.url} key={i}>
                                {item.name}
                            </A>
                        )
                    } else {
                        return (
                            <div
                                style={{ cursor: onChange ? 'pointer' : 'default' }}
                                onClick={() => onChange && onChange(item.id)}
                                key={i}
                                className={classNames}>
                                {item.name}
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default StepsRow
