import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Col, Row, gridStyle } from '../grid'
import { Button, Divider, LinearLoader } from '../ui'
import style from './scrollModal.module.css'
import Modal from './src/Modal'

/**
 * SCROLL MODAL
 * if the modal content is too long to fit on the screen:
 * only the body section will be scrollable (instead of the whole screen)
 * the footer with the buttons will always be visible
 */

export function useScrollModal() {
    const [isOpen, setIsOpen] = useState(false)
    const closeModal = () => setIsOpen(false)
    const renderModal = (props: ScrollModalProps) => (
        <ScrollModal isOpen={isOpen} onCloseModal={closeModal} {...props} />
    )
    return { openModal: () => setIsOpen(true), closeModal, renderModal }
}

export interface ScrollModalProps {
    heading?: ReactElement | string
    headingBgColor?: 'dark' | 'light' | 'transparent'
    sub?: ReactElement
    body?: ReactElement
    children?: ReactNode
    buttons?: {
        name?: string
        text: string
        func: () => void
        closeOnSuccess?: boolean
        className?: string
        hidden?: boolean
        disabled?: boolean
    }[]
    closeButton?: {
        func: () => void
        closeOnSuccess?: boolean
    }
    isOpen?: boolean
    onCloseModal?: () => void
    closeOnOutsideClick?: boolean
    maxWidth?: number | string
    className?: string
    classNames?: string
    hideScrollBar?: boolean
    hideBodyPadding?: boolean
    justifyButtons?: 'start' | 'center' | 'end' | 'around' | 'between'
    afterExit?: () => void
}

export const ScrollModal = ({
    heading,
    headingBgColor,
    sub,
    body,
    children,
    buttons = [],
    closeButton = {
        func: () => {},
        closeOnSuccess: true,
    },
    isOpen = false,
    onCloseModal = () => {},
    closeOnOutsideClick = false,
    maxWidth = '674px',
    className = '',
    classNames = 'fullScreen',
    hideScrollBar = false,
    hideBodyPadding = false,
    justifyButtons,
    afterExit,
}: ScrollModalProps) => {
    const [hasScrollBar, setHasScrollBar] = useState(false)
    const bodyRef = useRef<HTMLDivElement>(null)
    const onHandleResize = () => {
        const node = bodyRef.current
        if (node) {
            setHasScrollBar(node.scrollHeight > node.clientHeight)
        }
    }
    useEffect(onHandleResize, [bodyRef, isOpen])
    useEffect(() => {
        window.addEventListener('resize', onHandleResize)
        return () => window.removeEventListener('resize', onHandleResize)
    }, [])

    const [isBusyInternal, setIsBusyInternal] = useState(false)
    const onClick = (func = () => {}, closeOnSuccess = true) => {
        setIsBusyInternal(true)
        Promise.resolve(func()).then(
            () => {
                setIsBusyInternal(false)
                closeOnSuccess && onCloseModal()
            },
            () => setIsBusyInternal(false)
        )
    }

    const activeButtons = buttons.filter((button) => !button.hidden)
    const justifyContent = ['start', 'center', 'end', 'around', 'between']
    const headingBgColors = ['dark', 'light', 'transparent']

    return (
        <Modal
            maxWidth={maxWidth}
            className={className}
            classNames={classNames}
            open={isOpen}
            close={() => !isBusyInternal && onClick(closeButton.func, closeButton.closeOnSuccess)}
            closeOnOutsideClick={closeOnOutsideClick}
            afterExit={afterExit}
            wrapContent={false}
            modalInsideStyle={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100%',
                fontSize: '14px',
            }}>
            {isBusyInternal && <LinearLoader absolute />}
            {heading && (
                <>
                    {headingBgColor && headingBgColors.includes(headingBgColor) ? (
                        <div
                            className={[style.header, style[headingBgColor], style.padding].join(
                                ' '
                            )}>
                            <Divider ml />
                            <div className={style.heading}>{heading}</div>
                            {sub && (
                                <>
                                    <Divider s />
                                    {sub}
                                </>
                            )}
                            <Divider ml />
                        </div>
                    ) : (
                        <div className={[style.header, style.padding].join(' ')}>
                            <Divider l />
                            <Divider s className={gridStyle['invisible-xs']} />
                            <div className={style.heading}>{heading}</div>
                            {sub && (
                                <>
                                    <Divider m />
                                    {sub}
                                </>
                            )}
                            <Divider m />
                        </div>
                    )}
                </>
            )}
            <div
                ref={bodyRef}
                className={[
                    style.scrollable,
                    hideScrollBar ? style.hideScrollBar : '',
                    !hideBodyPadding ? style.padding : '',
                    hasScrollBar ? style.hasScrollBar : '',
                ].join(' ')}>
                {children || body}
                {activeButtons.length > 0 && <Divider m />}
            </div>
            {activeButtons.length > 0 && (
                <div className={[style.footer, style.padding].join(' ')}>
                    <Divider sm />
                    <Divider s className={gridStyle['invisible-xs']} />
                    <Row
                        {...(justifyButtons && justifyContent.includes(justifyButtons)
                            ? { [justifyButtons]: 'xs' }
                            : activeButtons.length === 1
                            ? { end: 'xs' }
                            : { between: 'xs' })}
                        middle="xs">
                        {activeButtons.map(
                            (
                                {
                                    text,
                                    func,
                                    closeOnSuccess,
                                    name,
                                    className = '',
                                    disabled = false,
                                },
                                i
                            ) => (
                                <Col x key={i}>
                                    <Button
                                        name={name}
                                        className={className}
                                        disabled={disabled || isBusyInternal}
                                        onClick={() => onClick(func, closeOnSuccess)}>
                                        {text}
                                    </Button>
                                    <Divider sm />
                                </Col>
                            )
                        )}
                    </Row>
                    <Divider s className={gridStyle['invisible-xs']} />
                </div>
            )}
        </Modal>
    )
}
