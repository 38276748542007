import React from 'react'
import { connect } from 'react-redux'
import { fetchAdminUsers } from '../actions/adminUsers'

function mapStateToProps(state, props) {
    const { entities, isFetching } = state.adminUsers
    const { adminUsers } = entities
    const user = adminUsers && adminUsers[props.userId]
    return {
        user,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAdminUsers: () => dispatch(fetchAdminUsers()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
