import { Divider } from '@advanza/ui'
import React from 'react'
import Page from '../ui/Page'
import RequestFilter from './RequestFilter'
import RequestList from './RequestList'

class RequestsPage extends React.Component {
    render() {
        return (
            <Page title="Requests">
                <RequestFilter
                    filterId="all"
                    defaultOptions={{
                        source: 'default',
                        pageSize: 20,
                    }}
                />
                <Divider l />
                <RequestList selectable filterId="all" />
            </Page>
        )
    }
}

export default RequestsPage
