import React from 'react'
import { connect } from 'react-redux'
import { fetchService } from '../actions/services'

function mapStateToProps(state, props) {
    const { services, ...serviceEntities } = state.services.entities
    const service = services && services[props.serviceId]
    return {
        serviceEntities,
        service,
        isFetching: state.services.isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchService: (serviceId) => dispatch(fetchService(serviceId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
