import { Section } from '@advanza/advanza_generic'
import React from 'react'
import PropTypes from 'prop-types'
import LabelAdminList from '../labels/LabelAdminList'
import LabelFilter from '../labels/LabelFilter'
import Page from '../ui/Page'
import StatusesFilter from './StatusesFilter'
import StatusesList from './StatusesList'

class StatusesPage extends React.Component {
    render() {
        return (
            <Page title="Statuses">
                <div className="container">
                    <Section y>
                        <Section s>
                            <h1>Statuses</h1>
                        </Section>
                        <StatusesFilter
                            filterId="admin"
                            defaultOptions={{ pageSize: 40, order: 'status_id DESC' }}
                        />
                        <Section n>
                            <StatusesList selectable filterId="admin" />
                        </Section>
                    </Section>
                </div>
            </Page>
        )
    }
}

StatusesPage.propTypes = {}

export default StatusesPage
