import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    SERVICES_STATS_CHANGE_OPTIONS,
    SERVICES_STATS_INVALIDATE,
    SERVICES_STATS_RECEIVE,
    SERVICES_STATS_REQUEST,
} from '../actions/serviceStats'

const update = getUpdater()

const handlers = {
    [SERVICES_STATS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: action.statsType },
        }),
    [SERVICES_STATS_RECEIVE]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            didInvalidate: { $set: false },
            [action.statsType]: {
                [action.serviceId]: { $set: action.stats },
            },
        }),
    [SERVICES_STATS_CHANGE_OPTIONS]: (state, action) =>
        update(state, {
            options: {
                [action.statsType]: {
                    [action.serviceId]: { $vivMerge: action.options },
                },
            },
        }),
    [SERVICES_STATS_INVALIDATE]: (state, action) =>
        update(state, {
            didInvalidate: { $set: action.statsType || true },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    requestStats: {},
    competitionStats: {},
    matchStats: {},
    options: {
        requestStats: {},
        competitionStats: {},
        matchStats: {},
    },
    result: {},
}

export default createReducer(initialState, handlers)
