import React from 'react'
import { useDispatch } from 'react-redux'
import { call } from '@advanza/api'
import { useActionModal } from '@advanza/modal'
import { changeEntity } from '@advanza/redux_entity'
import { Button, Icon } from '@advanza/ui'
import Restricted from 'components/Restricted'

const ProvidersBanRestoreButton = ({ providerId }) => {
    const dispatch = useDispatch()
    const { openModal, renderModal } = useActionModal()

    if (!providerId) {
        return null
    }

    const onRestore = () =>
        call(`office/providers-bans/restore/${providerId}`).then(() => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: providerId,
                    diff: {
                        bans: [],
                        status: 1,
                    },
                })
            )

            dispatch({ type: 'INVALIDATE_PROVIDERSBANS' })
        })

    return (
        <Restricted location="providers">
            <Button onClick={() => openModal()}>
                <Icon name="done" /> Restore provider
            </Button>
            {renderModal({
                cancelText: 'No',
                text: 'Yes',
                func: onRestore,
                header: 'Restore provider, are you sure?',
            })}
        </Restricted>
    )
}

export default ProvidersBanRestoreButton
