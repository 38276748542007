import { Icon } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import { addSnippet, deleteAggregatedReview, saveAggregatedReview } from 'actions/aggregatedReviews'
import AggregatedReviewSnippetEntity from 'components/aggregatedReviews/AggregatedReviewSnippetEntity'
import AggregatedReviewsSourceSelect from 'components/aggregatedReviews/AggregatedReviewsSourceSelect'
import Card from 'components/Card'
import Divider from 'components/Divider'
import EntityComponent from 'components/services/EntityComponent'
import SafeButton from 'components/ui/SafeButton'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import React from 'react'
import FormattedTime from 'components/ui/FormattedTime'

class AggregatedReviewEntity extends EntityComponent {
    constructor(props) {
        super(props)
        this.onChangeSource = this.onChangeSource.bind(this)
        this.isTouched = this.isTouched.bind(this)
        this.addSnippet = this.addSnippet.bind(this)
        this.generateDistribution = this.generateDistribution.bind(this)
    }

    editFields() {
        const {
            aggregated_reviews_source,
            nr_ratings,
            nr_of_5,
            nr_of_4,
            nr_of_3,
            nr_of_2,
            nr_of_1,
        } = this.props.entity
        const max =
            nr_ratings -
            ((nr_of_5 || 0) + (nr_of_4 || 0) + (nr_of_3 || 0) + (nr_of_2 || 0) + (nr_of_1 || 0))
        return {
            nr_ratings: { type: 'number', msg: 'Nr ratings' },
            rating: {
                type: 'text',
                msg: 'rating',
                validator: (val) => val >= 0 && val <= 5,
                defaultErrorMsg: 'Rating must be between 0 and 5',
            },
            nr_of_5: { type: 'number', msg: '5', max: nr_of_5 + max, min: 0 },
            nr_of_4: { type: 'number', msg: '4', max: nr_of_4 + max, min: 0 },
            nr_of_3: { type: 'number', msg: '3', max: nr_of_3 + max, min: 0 },
            nr_of_2: { type: 'number', msg: '2', max: nr_of_2 + max, min: 0 },
            nr_of_1: { type: 'number', msg: '1', max: nr_of_1 + max, min: 0 },
            last_review_date: { type: 'date', msg: 'last review date', format: 'YYYY-MM-DD' },
            source_url: {
                type: 'text',
                msg: 'source url',
                validator: (val) =>
                    val.includes(
                        aggregated_reviews_source.url.replace(/^(https?:\/\/)?(www\.)?/, '')
                    ),
                defaultErrorMsg: 'Wrong site? Should match: ' + aggregated_reviews_source.url,
            },
        }
    }

    onChangeSource(values) {
        this.onChangeEntity({
            aggregated_reviews_source: values[0],
            aggregated_reviews_source_id: values[0].aggregated_reviews_source_id,
        })
    }

    isTouched() {
        const { entity, entities } = this.props
        if (entity._isTouched) {
            return true
        }
        if (
            entity.aggregated_reviews_snippets.filter(
                (id) => entities.aggregatedReviewsSnippets[id]._isTouched
            ).length > 0
        ) {
            return true
        }

        return false
    }

    addSnippet() {
        const { addSnippet, entityId } = this.props
        addSnippet(entityId)
    }

    generateDistribution(e, iteration = 1) {
        const { nr_ratings, rating } = this.props.entity
        let distribution = [0, 0, 0, 0, 0]
        const weightedList = []
        const floatRating = parseFloat(rating)
        const weightFactor = [3, 5, 6, 2, 8, 9][Math.floor(Math.random() * Math.floor(6 - 1))]
        for (let i = 1; i < 6; i++) {
            let weight = 6 - Math.abs(floatRating - i)
            let modifier = weight ** weightFactor / 1000
            if (Math.round(floatRating) === 5 || Math.round(floatRating) === 1) {
                modifier = modifier ** 2
            }
            for (let ii = 0; ii < modifier; ii++) {
                weightedList.push(i)
            }
        }

        for (let i = 0; i < nr_ratings; i++) {
            const nr = Math.floor(Math.random() * Math.floor(weightedList.length - 1))
            distribution[5 - weightedList[nr]]++
        }

        if (floatRating === 5.0) {
            distribution = [nr_ratings, 0, 0, 0, 0]
        }
        if (floatRating === 1.0) {
            distribution = [0, 0, 0, 0, nr_ratings]
        }
        const check =
            (distribution[4] +
                distribution[3] * 2 +
                distribution[2] * 3 +
                distribution[1] * 4 +
                distribution[0] * 5) /
            nr_ratings
        if (check.toFixed(1) === floatRating.toFixed(1)) {
            this.onChangeEntity({
                nr_of_5: distribution[0],
                nr_of_4: distribution[1],
                nr_of_3: distribution[2],
                nr_of_2: distribution[3],
                nr_of_1: distribution[4],
            })
        } else if (iteration < 100) {
            this.generateDistribution(e, iteration + 1)
        } else {
            this.onChangeEntity({
                nr_of_5: 0,
                nr_of_4: 0,
                nr_of_3: 0,
                nr_of_2: 0,
                nr_of_1: 0,
            })
        }
    }

    render() {
        const { entity } = this.props
        return (
            <div style={{ padding: '5px 15px' }}>
                <Row end="xs" middle="xs">
                    <Col xs>
                        <AggregatedReviewsSourceSelect
                            onValueChange={this.onChangeSource}
                            value={entity.aggregated_reviews_source.aggregated_reviews_source_id}
                        />
                    </Col>
                    <Col x>
                        {' '}
                        <SafeButton
                            action={this.delete}
                            buttonText={<i className="material-symbols-outlined">delete_forever</i>}
                            confirmMessage="Delete & blacklist source_url"
                        />
                    </Col>
                </Row>
                <Divider m />
                <Row>
                    <Col xs> {this.renderInput('nr_ratings')}</Col>
                    <Col xs> {this.renderInput('rating')}</Col>
                </Row>
                <Divider m />
                <Row>
                    <Col xs>{this.renderInput('source_url')}</Col>
                    <Col x>
                        <a href={entity.source_url} target="_blank">
                            <Icon>link</Icon>
                        </a>
                    </Col>
                </Row>
                <Divider m />
                {this.renderInput('last_review_date')}
                <Divider m />
                <Row middle="xs">
                    <Col xs> {this.renderInput('nr_of_5')}</Col>
                    <Col xs> {this.renderInput('nr_of_4')}</Col>
                    <Col xs> {this.renderInput('nr_of_3')}</Col>
                    <Col xs> {this.renderInput('nr_of_2')}</Col>
                    <Col xs> {this.renderInput('nr_of_1')}</Col>
                    <Col xs>
                        {entity.nr_ratings > 2 && entity.rating > 0 && (
                            <button onClick={this.generateDistribution} className="plain">
                                <Icon>refresh</Icon>
                            </button>
                        )}
                    </Col>
                </Row>
                <Divider m />
                <Row between="xs">
                    <Col x>
                        <button
                            onClick={this.save}
                            className="btn cta"
                            disabled={!this.isTouched() || entity._saving}>
                            {entity._saving ? 'saving..' : 'Save'}
                        </button>
                    </Col>
                </Row>
                <Divider />
                {entity.aggregated_reviews_snippets.map((id) => {
                    return (
                        <div>
                            <Card>
                                <AggregatedReviewSnippetEntity entityId={id} />
                            </Card>
                            <Divider />
                        </div>
                    )
                })}
                <Divider l />
                {entity.last_updated && (
                    <>
                        last_updated:{' '}
                        <FormattedTime date={entity.last_updated} format={'yyyy-MM-dd'} />
                        <Divider m />
                    </>
                )}
                <Divider l />
            </div>
        )
    }
}

AggregatedReviewEntity.propTypes = {}

function mapStateToProps(state) {
    return {
        entities: state.aggregatedReviews.entities,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addSnippet: (id) => dispatch(addSnippet(id)),
    }
}

export default EntityComponentContainer(AggregatedReviewEntity, {
    store: 'aggregatedReviews',
    name: 'aggregatedReviews',
    saveFunc: saveAggregatedReview,
    deleteFunc: deleteAggregatedReview,
    mapStateToProps,
    mapDispatchToProps,
})
