import React, { CSSProperties, ReactNode } from 'react'
import css from './ellipsis.module.css'

interface Props {
    children: ReactNode
    tagName?: string
    className?: string
    style?: CSSProperties
    title?: string
    [key: string]: any
}

const Ellipsis = ({ children, tagName = 'div', className, style = {}, title, ...rest }: Props) => {
    const props = {
        style,
        title,
        className: [css.ellipsis, className || ''].join(' '),
        ...rest,
    }

    return React.createElement(tagName, props, children)
}

export default Ellipsis
