import { call } from '@advanza/api'
import { useList } from 'hooks/listHooks'
import { schema } from 'normalizr'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useLogsList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/providers/get-logs-list',
            schema: [new schema.Entity('logs', {}, { idAttribute: 'log_id' })],
            reducerKey: 'logs',
        },
        defaultOptions
    )
}

export function useSystemLogsList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/services/get-system-logs-list',
            schema: [new schema.Entity('system_logs', {}, { idAttribute: 'system_log_id' })],
            reducerKey: 'system_logs',
        },
        defaultOptions
    )
}

export function useSystemLogTypes(relation) {
    const { log_types = {} } = useSelector((state) => state.system_logs)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!log_types[relation]) {
            call('office/services/get-system-logs-types/' + relation).then(({ types }) => {
                dispatch({ type: 'RECEIVE_SYSTEM_LOGS_TYPES', relation, types })
            })
        }
    }, [])

    return log_types[relation] || []
}
