import { Icon } from '@advanza/ui'
import { getRequestStatusInfo } from 'hooks/requestsHooks'
import React from 'react'

const RequestStatus = ({ status, compact }) => {
    const { statusInfo, hexColor } = getRequestStatusInfo(status)

    if (compact) {
        return (
            <div>
                <Icon
                    style={{ color: statusInfo.color }}
                    title={statusInfo.label}
                    name={statusInfo.icon}
                />
            </div>
        )
    }
    return (
        <div>
            Status: <span style={{ color: `${hexColor()}` }}>{statusInfo.label}</span>
        </div>
    )
}

export default RequestStatus
