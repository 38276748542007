import React from 'react'
import style from './PercentageCircle.module.css'

const PercentageCircle = ({ percentage = 0, color = '#5788ff', useHeat, customText = null }) => {
    const rounded = Math.round(percentage)
    const strokeColor = useHeat ? `hsl(${20 + percentage},91%,47%)` : color
    return (
        <div className={style.root}>
            <svg viewBox="0 0 36 36" className={style.circularChart}>
                <path
                    className={style.circleBg}
                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                    className={style.circle}
                    d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke={strokeColor}
                    strokeWidth="1"
                    strokeDasharray={`${rounded}, 100`}
                />
                <text x="18" y="20.35" className={style.percentage}>
                    {customText || rounded + '%'}
                </text>
            </svg>
        </div>
    )
}
export default PercentageCircle
