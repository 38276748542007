import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    WEBSITECLICKS_CHANGE_FILTER,
    WEBSITECLICKS_RECEIVE_LIST,
    WEBSITECLICKS_REQUEST,
} from '../actions/websiteClicks'
import { changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [WEBSITECLICKS_RECEIVE_LIST]: receiveList,
    [WEBSITECLICKS_CHANGE_FILTER]: changeFilter,
    INVALIDATE_WEBSITECLICKS: invalidateFilters,
    [WEBSITECLICKS_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
