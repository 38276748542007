import { call } from '@advanza/api'

export const SERVICES_STATS_RECEIVE = 'SERVICES_STATS_RECEIVE'
export const SERVICES_STATS_REQUEST = 'SERVICES_STATS_REQUEST'
export const SERVICES_STATS_CHANGE_OPTIONS = 'SERVICES_STATS_CHANGE_OPTIONS'
export const SERVICES_STATS_INVALIDATE = 'SERVICES_STATS_INVALIDATE'

export function fetchServiceStats(serviceId, statsType) {
    return function (dispatch, getState) {
        const { options, isFetching, didInvalidate } = getState().serviceStats
        const queryOptions = options[statsType][serviceId] || {}
        const entities = getState().serviceStats[statsType]
        const stats = entities[serviceId]
        const isInvalidated = didInvalidate || queryOptions.didInvalidate
        if (!isInvalidated && (isFetching === statsType || stats)) {
            return Promise.resolve()
        }

        dispatch({ type: SERVICES_STATS_REQUEST, statsType })
        dispatch(changeServiceStatsOptions(serviceId, statsType, { isFetching: true }))

        return call(`office/services/get-stats/${statsType}/${serviceId}`, {
            query: { ...queryOptions, includePros: true },
        }).then((response) => {
            dispatch({
                type: SERVICES_STATS_RECEIVE,
                serviceId,
                statsType,
                stats: response.stats,
            })
            dispatch(
                changeServiceStatsOptions(serviceId, statsType, {
                    didInvalidate: false,
                    isFetching: false,
                })
            )
        })
    }
}

export function changeServiceStatsOptions(serviceId, statsType, options) {
    return function (dispatch) {
        dispatch({ type: SERVICES_STATS_CHANGE_OPTIONS, serviceId, options, statsType })
    }
}
