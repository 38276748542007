import { Row, Col } from '@advanza/grid'
import { changeEntity } from '@advanza/redux_entity'
import { Button, Divider } from '@advanza/ui'
import { saveProvider } from 'actions/providers'
import QualificationEntity from 'components/providers/QualificationEntity'
import { useChangeEntity } from 'hooks/entityHooks'
import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

function validate(entity = {}, qualifications = {}) {
    const ids = entity.qualifications || []
    const names = ids.map((id) => qualifications[id] && qualifications[id].name)
    const hasDuplicates = names.filter((name, index) => names.indexOf(name) != index).length
    const hasEmpty = names.filter((name) => !name || name.length === 0).length

    return !hasDuplicates && !hasEmpty
}

function _isTouched(entity, qualifications) {
    const entitiesHasChanged =
        entity.qualifications &&
        entity.qualifications.filter((id) => qualifications[id] && qualifications[id]._isTouched)
            .length

    return entitiesHasChanged
}

const QualificationsEdit = ({ id }) => {
    const dispatch = useDispatch()
    const { qualifications = {} } = useSelector((state) => state.providers.entities)
    const {
        onChangeEntity,
        entity = {},
        onSaveEntity,
    } = useChangeEntity({
        store: 'providers',
        name: 'providers',
        entityId: id,
        saveFunc: saveProvider,
    })
    const untouchEntities = () => {
        entity.qualifications.forEach((id) => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'qualifications',
                    key: id,
                    diff: { _beforeSave: qualifications[id] },
                    dontTouch: true,
                })
            )
        })
    }
    const isValid = validate(entity, qualifications)
    const isTouched = _isTouched(entity, qualifications)
    const onSave = () => isValid && onSaveEntity().then(untouchEntities)
    const addNew = () => {
        onChangeEntity({ qualifications: entity.qualifications.concat([`new_${Date.now()}`]) })
    }
    const onDelete = (key) => {
        onChangeEntity({ qualifications: entity.qualifications.filter((id) => id !== key) })
        onSave()
    }

    const onEnter = () => isValid && onSave() && addNew()

    return (
        <div>
            {entity.qualifications &&
                entity.qualifications.map((id) => {
                    return (
                        <Fragment key={id}>
                            <QualificationEntity id={id} onDelete={onDelete} onEnter={onEnter} />
                            <Divider />
                        </Fragment>
                    )
                })}
            <Divider />
            <Row between="xs" middle="xs">
                <Col x>
                    <Button onClick={addNew} name="text">
                        opleiding toevoegen
                    </Button>
                </Col>
                <Col x>
                    <Button disabled={entity._saving || !isValid || !isTouched} onClick={onSave}>
                        {entity._saving ? '..bezig' : 'Opslaan'}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default QualificationsEdit
