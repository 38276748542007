import React from 'react'
import style from './InputSlider.module.css'

const InputSlider = ({ value = 0, onChange, options = [] }) => {
    return (
        <div className={style.root}>
            <div className={style.row}>
                {options.map(({ name }, i) => {
                    const isSelected = value === i + 1
                    return (
                        <div
                            className={isSelected ? style.selected : ''}
                            key={i}
                            onClick={() => onChange({ target: { value: i + 1 } })}>
                            {name}
                        </div>
                    )
                })}
            </div>
            <input
                className={style.slider}
                type="range"
                min="1"
                max={options.length}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default InputSlider
