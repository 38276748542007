import { call } from '@advanza/api'
import { Modal } from '@advanza/modal'
import { Divider, Icon, LoadingDots } from '@advanza/ui'
import InvoicesList from 'components/billing/InvoicesList'
import NotesFilter from 'components/notes/NotesFilter'
import NotesList from 'components/notes/NotesList'
import Page from 'components/ui/Page'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import style from './DebtorsPage.module.css'

function useDebtors() {
    const [debtors, setDebtors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState(false)
    const country = useSelector((state) => state.countries.countries[0])
    const fetchDebtors = () => {
        if (isLoading) {
            return Promise.resolve()
        }
        setIsLoading(true)
        call('office/invoices/get-debtors', { query: { country, ...options } }).then((response) => {
            setDebtors(response.result)
            setIsLoading(false)
        })
    }
    useEffect(() => {
        fetchDebtors()
    }, [country, options])

    return {
        debtors,
        isLoading,
        options,
        setOptions,
    }
}

function useDebtorsOverview() {
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const country = useSelector((state) => state.countries.countries[0])
    useEffect(() => {
        if (isLoading) {
            return Promise.resolve()
        }
        setIsLoading(true)
        call('office/invoices/get-debtors-overview', { query: { country } }).then(
            (response) => {
                setData(response)
                setIsLoading(false)
            },
            (response) => setIsLoading('error')
        )
    }, [country])

    const table2 = {
        headers: [
            'month',
            'Nr. invoices',
            'Invoiced sum',
            'Nr. invoices paid',
            'Paid sum',
            'Nr. reminders',
            'Reminders sum',
            '% reminders',
            'Nr. overdue',
            'Overdue sum',
            '% overdue',
            'Nr. debt collection',
            'Debt collection sum',
            '% debt collection',
        ],
    }
    return {
        data: data.result || {},
        headers: data.headers || [],
        months: data.result ? Object.keys(data.result) : [],
        isLoading,
        table2,
    }
}

const numberFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

function valueBackgroundColor(value) {
    return { color: `hsl(${100 - value},100%,35%)`, fontWeight: 'bold' }
}

const DebtorsPage = () => {
    const { data, isLoading, headers, months, table2 } = useDebtorsOverview()
    const { isLoading: debtorsIsLoading, setOptions, options, debtors } = useDebtors()
    const [notesModal, setNotesModal] = useState(false)
    const [invoicesModal, setInvoicesModal] = useState(false)
    const [rowsOpen, setRowsOpen] = useState({ table1: {}, table2: {} })

    const sortable = ['days_since_youngest', 'days_since_oldest', 'amountOpen']
    function clickHeader(key) {
        if (sortable.includes(key)) {
            setOptions({
                ...options,
                orderField: key,
                orderDesc: options.orderDesc !== true,
            })
        }
    }
    const renderStatRow = (month, serviceId, table2) => {
        let items = data[month][serviceId] && Object.keys(data[month][serviceId])
        if (table2) {
            items =
                items &&
                items.filter((status) => {
                    return [
                        'all',
                        'fulfilled',
                        'paid',
                        'payment_reminder_sent',
                        'payment_reminder_overdue',
                        'debt_collection',
                    ].includes(status)
                })
        }
        const _rowsOpen = rowsOpen[table2 ? 'table2' : 'table1'] || {}
        const _setRowsOpen = () =>
            setRowsOpen({
                ...rowsOpen,
                [table2 ? 'table2' : 'table1']: { ..._rowsOpen, [month]: !_rowsOpen[month] },
            })
        return (
            <tr
                key={month + '_' + serviceId + (table2 ? 'table2' : '')}
                className={
                    serviceId !== -1 ? style.subRow : _rowsOpen[month] ? style.openedRow : ''
                }
                onClick={serviceId === -1 ? () => _setRowsOpen() : undefined}>
                <td>
                    {serviceId === -1
                        ? month
                        : data[month][serviceId] && data[month][serviceId]['all'].serviceName}
                </td>
                {items &&
                    items.map((statusName, i) => {
                        const item = data[month][serviceId][statusName]
                        const className = i % 2 !== 0 ? style.even : ''
                        const cols = {
                            sum: (
                                <td className={className}>
                                    {numberFormatter.format(item.amountTotal || 0)}
                                </td>
                            ),
                            sumExc: (
                                <td className={className}>
                                    {numberFormatter.format(item.sumTotalExc || 0)}
                                </td>
                            ),
                            count: <td className={className}>{item.nrInvoices}</td>,
                            percentage: statusName !== 'all' && (
                                <td className={className}>
                                    <span className={item.percentage > 0 ? style.posPercent : ''}>
                                        {item.percentage}%
                                    </span>
                                </td>
                            ),
                        }
                        if (table2) {
                            if (['all', 'fulfilled'].includes(statusName)) {
                                return (
                                    <>
                                        {cols.count}
                                        {cols.sum}
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        {cols.count}
                                        {cols.sum}
                                        {cols.percentage}
                                    </>
                                )
                            }
                        }
                        return (
                            <>
                                {cols.sum}
                                {statusName === 'all' && cols.sumExc}
                                {cols.count}
                                {cols.percentage}
                            </>
                        )
                    })}
            </tr>
        )
    }

    return (
        <Page>
            <Divider l />
            <div className="overflow-auto">
                <h3>
                    {isLoading ? (
                        <b>
                            Fetching debtors overview <LoadingDots />
                        </b>
                    ) : isLoading === 'error' ? (
                        'Failed loading debtors'
                    ) : (
                        'Debtors overview'
                    )}
                </h3>
                <table className={style.table}>
                    <thead>
                        <tr>
                            {headers.map((name, i) => (
                                <td title={name} key={i}>
                                    {name.replace(/_/g, ' ')}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    {months.map((month) => (
                        <Fragment key={month}>
                            {renderStatRow(month, -1)}
                            {rowsOpen.table1[month] &&
                                Object.keys(data[month]).map(
                                    (serviceId) =>
                                        serviceId !== '-1' &&
                                        renderStatRow(month, parseInt(serviceId, 10))
                                )}
                        </Fragment>
                    ))}
                </table>
                <Divider l />
                <table className={[style.table, style.table2].join(' ')}>
                    <thead>
                        <tr>
                            {table2.headers.map((name, i) => (
                                <td title={name} key={i}>
                                    {name.replace(/_/g, ' ')}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    {months.map((month) => (
                        <Fragment key={month}>
                            {renderStatRow(month, -1, true)}
                            {rowsOpen.table2[month] &&
                                Object.keys(data[month]).map(
                                    (serviceId) =>
                                        serviceId !== '-1' &&
                                        renderStatRow(month, parseInt(serviceId, 10), true)
                                )}
                        </Fragment>
                    ))}
                </table>
                <Divider l />
                <h3>
                    {debtorsIsLoading ? (
                        <b>
                            Loading debtors list <LoadingDots />
                        </b>
                    ) : (
                        'Debtors list'
                    )}
                </h3>
                {options.orderField && (
                    <p>
                        <button onClick={() => setOptions({ orderField: null })}>
                            clear sorting
                        </button>
                    </p>
                )}
                <table className={style.table}>
                    <thead>
                        <tr>
                            {Object.keys(debtors[0] || {}).map((key, i) => (
                                <td
                                    className={sortable.includes(key) ? style.sortable : ''}
                                    onClick={() => clickHeader(key)}
                                    key={i}>
                                    {key.replaceAll('_', ' ')}
                                    {options.orderField === key && (
                                        <Icon
                                            fontSize={18}
                                            name={options.orderDesc ? 'expand_more' : 'expand_less'}
                                        />
                                    )}
                                </td>
                            ))}
                        </tr>
                    </thead>

                    {debtors.map((data, i) => (
                        <tr key={i}>
                            {Object.keys(data).map((key) => {
                                return (
                                    <td tabIndex="-1">
                                        {key === 'business_name' || key === 'id' ? (
                                            <Link
                                                to={`/service/providers/provider/${data.id}/company/`}>
                                                {data[key]}
                                            </Link>
                                        ) : key === 'amountOpen' ? (
                                            numberFormatter.format(data[key])
                                        ) : (
                                            data[key]
                                        )}
                                    </td>
                                )
                            })}
                            <td onClick={() => setNotesModal(data)}>
                                <a href="#">view notes</a>
                            </td>
                            <td onClick={() => setInvoicesModal(data)}>
                                <a href="#">view invoices</a>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
            <Modal maxWidth={674} open={notesModal} close={() => setNotesModal(false)}>
                {notesModal && (
                    <Fragment>
                        <Divider m />
                        <h3>
                            Notes for {notesModal.business_name} (
                            {numberFormatter.format(notesModal.amountOpen)} open)
                        </h3>
                        <Divider />
                        <NotesFilter
                            filterId={`notes_${notesModal.id}`}
                            defaultOptions={{ providerIds: [notesModal.id] }}
                        />
                        <NotesList filterId={`notes_${notesModal.id}`} />
                    </Fragment>
                )}
            </Modal>
            <Modal maxWidth={1000} open={invoicesModal} close={() => setInvoicesModal(false)}>
                {invoicesModal && (
                    <Fragment>
                        <Divider m />
                        <h3>
                            Invoices for {invoicesModal.business_name} (
                            {numberFormatter.format(invoicesModal.amountOpen)} open)
                        </h3>
                        <Divider />
                        <InvoicesList
                            filterId={`invoices_${invoicesModal.id}`}
                            defaultOptions={{ providerId: invoicesModal.id }}
                        />
                    </Fragment>
                )}
            </Modal>
            <Divider l />
        </Page>
    )
}

export default DebtorsPage
