import { schema } from 'normalizr'

const profileLabelsSchema = new schema.Entity(
    'profileLabels',
    {},
    {
        idAttribute: 'providers_labels_id',
    }
)

export default [profileLabelsSchema]
