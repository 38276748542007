import { Row, Col } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import Card from 'components/Card'
import FilesFilter from 'components/files/FilesFilter'
import FilesList from 'components/files/FilesList'
import FranchisesLicencesList from 'components/franchisesLicences/FranchisesLicencesList'
import NotesFilter from 'components/notes/NotesFilter'
import NotesList from 'components/notes/NotesList'
import ProfileLabels from 'components/profileLabels/ProfileLabels'
import ProfileProjects from 'components/profileProjects/ProfileProjects'
import Cbr from 'components/providers/Cbr'
import DescriptionSelectionTool from 'components/providers/DescriptionSelectionTool'
import ProAvatarEdit from 'components/providers/ProAvatarEdit'
import ProviderBusinessInfoEntity from 'components/providers/ProviderBusinessInfoEntity'
import ProviderLabelPicker from 'components/providers/ProviderLabelPicker'
import ProvidersLicencesDropdown from 'components/providers/ProvidersLicencesDropdown'
import QualificationsEdit from 'components/providers/QualificationsEdit'
import WebsiteImages from 'components/providers/WebsiteImages'
import SubscriptionList from 'components/subscriptions/SubscriptionList'
import SubscriptionsFilter from 'components/subscriptions/SubscriptionsFilter'
import UserView from 'components/users/UserView'
import { useGetSubscriptions } from 'hooks/providerHooks'
import React from 'react'

const RIJSCHOOL_NL_SERVICE_ID = 37

const ProviderViewProfile = ({ providerId, user, provider }) => {
    const filterIdAssociations = `p_${providerId}`
    const { subscriptionArr = [] } = useGetSubscriptions(providerId)
    const hasRijschoolNL = subscriptionArr.some(
        (subscription) =>
            !subscription.deleted && subscription.service_id === RIJSCHOOL_NL_SERVICE_ID
    )

    return (
        <div>
            <Row>
                <Col xs>
                    <Card header="avatar" icon="person">
                        <ProAvatarEdit providerId={providerId} entityId={user.id} />
                    </Card>
                    <Divider m />
                    <Card header="Business info" icon="business">
                        <ProviderBusinessInfoEntity entityId={providerId} />
                        <Divider m />
                        <ProvidersLicencesDropdown entityId={providerId} />
                    </Card>
                    {hasRijschoolNL && (
                        <>
                            <Divider m />
                            <Card icon="agriculture" header="Examination">
                                <Divider m />
                                <Cbr providerId={providerId} />
                            </Card>
                        </>
                    )}
                    <Divider m />
                    <Card icon="auto_awesome_mosaic" header="Projects">
                        <Divider />
                        <ProfileProjects providerId={providerId} />
                    </Card>
                    <Divider xl />
                </Col>
                <Col xs>
                    <Card icon="filter_list" header="Filters">
                        <Divider s />
                        {provider.notes && (
                            <SubscriptionsFilter
                                defaultOptions={{ providerIds: [providerId] }}
                                compact
                                hidePagination
                                providerId={providerId}
                                filterId={filterIdAssociations}
                            />
                        )}
                        <Divider />
                        <SubscriptionList compact hideHeader filterId={filterIdAssociations} />
                    </Card>
                    {!!provider.franchise_status && (
                        <>
                            <Divider m />
                            <Card header="Franchise licence" icon="people">
                                <Divider m />
                                <FranchisesLicencesList franchiseId={providerId} />
                            </Card>
                        </>
                    )}
                    <Divider m />
                    <Card header="Profile labels" icon="label">
                        <ProfileLabels providerId={providerId} />
                    </Card>
                    <Divider m />
                    <Card header="Description" icon="description">
                        <Divider m />
                        <DescriptionSelectionTool providerId={providerId} />
                    </Card>
                    <Divider m />
                    <Card header="Files" icon="insert_drive_file">
                        <FilesFilter
                            filterId={filterIdAssociations}
                            defaultOptions={{ providerIds: [providerId] }}
                            addImageOptions={{ mode: 'providerProfile', providerId }}
                            newEntityOptions={{ context: 'profile', providerId }}
                        />
                        <Divider />
                        <FilesList compact hideHeader selectable filterId={filterIdAssociations} />
                        <Divider m />
                        <WebsiteImages providerId={providerId} url={provider.website} />
                    </Card>
                    <Divider m />
                    <Card header="notes" icon="speaker_notes">
                        <NotesFilter
                            filterId={filterIdAssociations}
                            defaultOptions={{ providerIds: [providerId], pageSize: 5 }}
                            newEntityOptions={{ type: 'serviceProviders', foreign_key: providerId }}
                        />
                        <NotesList filterId={filterIdAssociations} />
                    </Card>
                    <Divider m />
                    <Card header="Office labels" icon="label">
                        <ProviderLabelPicker entityId={providerId} wrapValues />
                    </Card>
                    <Divider m />
                    <Card icon="school" header="Qualifications">
                        <Divider />
                        <QualificationsEdit id={providerId} />
                    </Card>
                    <Divider m />
                    <Card header={`User #${user.id}`} icon="person">
                        <UserView userId={user.id} useStore="providers" profileMode />
                    </Card>
                    <Divider m />
                </Col>
            </Row>
        </div>
    )
}

export default ProviderViewProfile
