import { Col, Row } from '@advanza/grid'
import { Button, Divider, MaxWidth } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import AddBanButton from 'components/providersBans/AddBanButton'
import A from 'components/ui/A'
import Page from 'components/ui/Page'
import React from 'react'
import BansList from './BansList'

const BansPage = ({ filterId = 'all' }) => (
    <Page title="Bans">
        <MaxWidth>
            <Divider m />
            <AutoRow>
                <h1>Bans</h1>
                <AddBanButton />
                <A to="/billing/quarantined">
                    <Button name="yellowAlt">Back to Quarantined</Button>
                </A>
            </AutoRow>
            <Divider m />
            <BansList filterId={filterId} />
        </MaxWidth>
    </Page>
)

export default BansPage
