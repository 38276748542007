import { getUpdater } from '@advanza/advanza_generic'

const update = getUpdater()

export function receiveList(state, action) {
    return update(state, {
        isFetching: { $set: false },
        didInvalidate: { $set: false },
        result: {
            [action.searchKey]: { $vivMerge: { [action.page]: action.result } },
        },
        counts: {
            [action.searchKey]: { $set: action.count },
        },
        filters: state.filters[action.filterId]
            ? {
                  [action.filterId]: { didInvalidate: { $set: false } },
              }
            : {},
        entities: { $deepMerge: action.entities },
        stats: action.stats ? { $set: action.stats } : { $set: false },
        extra: action.extra ? { $set: action.extra } : { $set: false },
        error: action.error ? { $set: action.error } : { $set: false },
    })
}

export function changeFilter(state, action) {
    return update(state, {
        filters: {
            [action.filterId]: {
                $set: action.filter,
            },
        },
    })
}

export const swapIdInPage = (state, action) => {
    const { result = {}, filters = {} } = state
    const filter = filters[action.filterId]
    const searchKey = filter && filter.searchKey
    const pages = result[searchKey] || {}
    const pageNr = (filter && filter.page) || 0
    const ids = pages[pageNr] || []

    return !searchKey
        ? state
        : update(state, {
              result: {
                  [searchKey]: {
                      [pageNr]: {
                          $set: ids.map((id) => (id === action.oldId ? action.newId : id)),
                      },
                  },
              },
          })
}

export const changePage = (state, action) => {
    const filter = state.filters[action.filterId]
    const searchKey = filter && filter.searchKey
    const pageNr = (filter && filter.page) || 0

    return !searchKey
        ? state
        : update(state, {
              result: {
                  [searchKey]: {
                      [pageNr]: {
                          $set: action.ids,
                      },
                  },
              },
          })
}

function objectsAreDifferent(objectA, objectB) {
    const cloneA = { ...objectA }
    const cloneB = { ...objectB }

    const deleteIgnoredKeys = (object) => {
        const keysToDelete = Object.keys(object).filter((key) => key.indexOf('_') === 0)
        keysToDelete.forEach((key) => delete object[key])
    }
    deleteIgnoredKeys(cloneA)
    deleteIgnoredKeys(cloneB)

    return JSON.stringify(cloneA) !== JSON.stringify(cloneB)
}

export function invalidateFilters(state, action) {
    const updateObj = {}
    Object.keys(state.filters).forEach((filterId) => {
        updateObj.filters = {
            ...updateObj.filters,
            [filterId]: {
                didInvalidate: { $set: true },
                selection: action.keepSelection ? {} : { $set: [] },
            },
        }
    })

    return update(state, updateObj)
}

export function changeEntity(state, action) {
    const {
        key,
        entityName,
        diff = {},
        dontTouch,
        preservePreviousTouch,
        newKey,
        filterId,
        remove,
        historyCleanUp,
    } = action
    const previousState = state.entities[entityName] && state.entities[entityName][key]
    const isNew = !previousState
    const changedEntity = isNew ? diff : { ...previousState, ...diff }
    const isTouched = diff._isTouched
        ? true
        : diff._isTouched === false
        ? false
        : dontTouch || historyCleanUp
        ? false
        : preservePreviousTouch
        ? previousState && previousState.hasOwnProperty('_isTouched')
            ? previousState._isTouched
            : false
        : !isNew && previousState._beforeSave
        ? objectsAreDifferent(changedEntity, changedEntity._beforeSave)
        : objectsAreDifferent(changedEntity, previousState)
    const newEntity = {
        ...changedEntity,
        _isTouched: isTouched,
        _beforeSave: historyCleanUp ? undefined : changedEntity._beforeSave || previousState,
        _errors: changedEntity._errors || {},
        _saving: changedEntity._saving || false,
    }

    const entityKey = newKey || key
    const merge = { [entityName]: { [entityKey]: newEntity } }
    const updateObj = {
        entities: {
            $deepMerge: merge,
        },
    }

    if (filterId) {
        if (state.filters[filterId]) {
            const searchKey = state.filters[filterId].searchKey
            if (state.result[searchKey][0]) {
                const exists = state.result[searchKey][0].indexOf(entityKey) !== -1
                if (!exists) {
                    updateObj.result = {
                        ...updateObj.result,
                        [searchKey]: { [0]: { $unshift: [entityKey] } },
                    }
                }
            }
        }
    }

    if (remove) {
        Object.keys(state.filters).forEach((filterId) => {
            const searchKey = state.filters[filterId] && state.filters[filterId].searchKey
            if (searchKey && state.result[searchKey][0]) {
                updateObj.result = {
                    ...updateObj.result,
                    [searchKey]: { [0]: { $arrDel: entityKey } },
                }
            }
        })
    }
    return update(state, updateObj)
}

export function selectItems(state, action) {
    const { ids, filterId } = action
    const filter = state.filters[filterId]
    const selection = filter.selection || []
    const isMultiple = Array.isArray(ids)

    let newSelection = selection
    if (isMultiple) {
        newSelection = ids
    } else {
        const alreadySelected = selection.indexOf(ids) !== -1
        if (alreadySelected) {
            newSelection = newSelection.filter((id) => id !== ids)
        } else {
            newSelection = newSelection.concat([ids])
        }
    }
    return update(state, {
        filters: {
            [filterId]: {
                selection: { $set: newSelection },
            },
        },
    })
}
