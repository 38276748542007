import Page from 'components/ui/Page'
import ListMailTemplates from 'mails/ListMailTemplates'
import React from 'react'

const SendgridMailsPage = () => {
    return (
        <Page>
            <ListMailTemplates />
        </Page>
    )
}

export default SendgridMailsPage
