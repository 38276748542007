import { call } from '@advanza/api'
import Modal from '@advanza/modal'
import { LoadingDots, Button, PreIcon } from '@advanza/ui'
import SafeButton from 'components/ui/SafeButton'
import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import EventViewContainer from '../../containers/EventViewContainer'
import LinearLoader from '../LinearLoader'
import MailsOutTimeLine from './MailsOutTimeLine'

function useResendEventMail(event) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const isEligible = [
        'AfterMatchMailProNew',
        'AfterMatchPremium',
        'AfterMatchBasic',
        'MiddleManReplyMail',
    ].includes(event.name)
    const dispatch = useDispatch()
    function resend() {
        if (isLoading || !isEligible) {
            return Promise.reject('no')
        }
        setIsLoading(true)
        return call('office/events/resend-mail/' + event.event_id, { method: 'post' })
            .then(
                (response) => {
                    dispatch({ type: 'INVALIDATE_EVENTS' })
                },
                (response) => {
                    setError(response)
                }
            )
            .finally(() => setIsLoading(false))
    }

    return {
        isLoading,
        error,
        isEligible,
        resend,
        clearError: () => setError(false),
    }
}
const EventView = ({ fetchEvent, event, eventId }) => {
    useEffect(() => {
        fetchEvent(eventId)
    }, [])
    const { isLoading, isEligible, resend, error, clearError } = useResendEventMail(event)
    if (!event) {
        return <LinearLoader fixed />
    }
    return (
        <div>
            {event.mails_out && <MailsOutTimeLine mailsOut={event.mails_out} />}
            <pre>{event.mails_out && event.mails_out.response}</pre>
            <p>
                email: <b>{event.mails_out && event.mails_out.email}</b>
                <br />
                id: <b>#{eventId}</b>
                mailsOutId: <b>#{event.mails_out_id}</b>
            </p>
            {isEligible && (
                <Fragment>
                    <SafeButton
                        action={resend}
                        cancelText="X"
                        confirmMessage={<Button name="delete">Confirm resend</Button>}
                        buttonText={
                            <Button>
                                <PreIcon icon="replay">Resend mail / API</PreIcon>
                            </Button>
                        }
                    />
                </Fragment>
            )}
            <Modal open={error} close={clearError}>
                <pre>{error && JSON.stringify(error, null, 2)}</pre>
            </Modal>
        </div>
    )
}

export default EventViewContainer(EventView)
