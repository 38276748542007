import { Divider, MaxWidth } from '@advanza/ui'
import Page from 'components/ui/Page'
import React from 'react'
import ReclaimsList from './ReclaimsList'
import AutoRow from 'components/misc/AutoRow'

const ReclaimsPage = ({ filterId = 'all' }) => {
    return (
        <Page title='Reclaims'>
            <MaxWidth>
                <Divider m />
                <h1>Reclaims</h1>
                <Divider l />
                <ReclaimsList filterId={filterId} defaultOptions={{lastXMonths:1}} />
            </MaxWidth>
        </Page>
    )
}

export default ReclaimsPage
