import { call } from '@advanza/api'
import Page from 'components/ui/Page'
import React, { useState, useEffect } from 'react'

function parseNote(note) {
    return note.note
}

const useFrontEndErrorNotes = () => {
    const [items, setItems] = useState(null)
    const [date, setDate] = useState(null)
    const [daysAgo, setDaysAgo] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        call('office/notes/get-front-end-error-notes', {
            json: {
                date,
                daysAgo,
            },
        })
            .then(({ items }) => setItems(items.map(parseNote)))
            .finally(() => setIsLoading(false))
    }, [date])

    return {
        items,
        isLoading,
        daysAgo,
        date,
        setDate,
    }
}

const ErIsIetsMisGegaan = ({}) => {
    const { items, isLoading, daysAgo, date, setDate } = useFrontEndErrorNotes()
    return (
        <Page title="😅😬">
            <pre>{JSON.stringify({ items, daysAgo, isLoading, date }, null, 2)}</pre>
        </Page>
    )
}

export default ErIsIetsMisGegaan
