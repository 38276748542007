import { Section } from '@advanza/advanza_generic'
import { S3_PUBLIC } from '@advanza/constants'
import { Col, Row } from '@advanza/grid'
import { FileDropZone } from '@advanza/input'
import { Button, Divider, FixedWarningPopup } from '@advanza/ui'
import { useToast } from 'components/misc/Toaster'
import ServicesSelect from 'components/services/ServicesSelect'
import { useEntityShared } from 'hooks/sharedHooks'
import React, { useState } from 'react'

export const SPEC = {
    store: 'apis',
    name: 'apis',
}

const fields = {
    display_name: { type: 'text', placeholder: 'Display name' },
    description: { type: 'text', placeholder: 'Description' },
    always_mail_lead: { type: 'boolean', msg: 'Always mail lead' },
    all_services: { type: 'boolean', msg: 'All services' },
}

export const getApiIconUrl = (entity = {}) =>
    entity.file && entity.file.preview
        ? entity.file.preview
        : `${S3_PUBLIC()}services/apis/${entity.icon_filename}`

const ApiEntity = ({ apiId }) => {
    const { onFilesRejected } = useToast()
    const [toast, setToast] = useState({})
    const { entity, onSaveEntity, renderInput, onChangeEntity } = useEntityShared(
        SPEC,
        apiId,
        fields
    )

    if (!apiId) {
        return null
    }

    const onDropFiles = (files) => {
        const Reader = new FileReader()
        Reader.onloadend = (e) => {
            const { result } = e.target
            onChangeEntity({
                file: {
                    preview: result,
                    base64: result.substring(result.indexOf(',') + 1),
                    filename: files[0].name,
                },
            })
        }
        Reader.readAsDataURL(files[0])
    }

    const onSave = () =>
        onSaveEntity().then(
            () => setToast({ id: Date.now(), message: 'Api saved' }),
            (response) =>
                setToast({ isError: true, id: Date.now(), message: JSON.stringify(response) })
        )

    return (
        <Section x y>
            <Row middle="xs">
                <Col x>
                    <FileDropZone
                        style={{ width: 100 }}
                        allowedTypes={['image']}
                        allowSvg
                        multiple={false}
                        maxFiles={1}
                        onFilesAccepted={onDropFiles}
                        onFilesRejected={onFilesRejected}>
                        <div
                            style={{
                                background: `url(${getApiIconUrl(
                                    entity
                                )}) no-repeat center center /contain`,
                                width: 100,
                                border: '3px dotted #dadada',
                                padding: 6,
                                height: 50,
                            }}
                        />
                    </FileDropZone>
                </Col>
                <Col x>{renderInput('display_name')}</Col>
                <Col x>{renderInput('description')}</Col>
                <Col x>{renderInput('always_mail_lead')}</Col>
                {!entity.all_services && (
                    <Col x>
                        <ServicesSelect
                            placeholder="Select services"
                            useSelectedCountry={false}
                            multiple={true}
                            value={entity.services}
                            onChangeValue={(services) =>
                                onChangeEntity({
                                    services,
                                })
                            }
                        />
                    </Col>
                )}
                <Col x>{renderInput('all_services')}</Col>
            </Row>
            <Divider m />
            <Row>
                <Col x>
                    <Button onClick={onSave} disabled={!entity._isTouched || entity._saving}>
                        {entity._saving ? '..saving' : 'Save'}
                    </Button>
                    {toast.isError && <div style={{ color: 'red' }}>not saved</div>}
                </Col>
            </Row>
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </Section>
    )
}

export default ApiEntity
