import { Row, Select } from '@advanza/advanza_generic'
import React from 'react'
import SalesLeadsFilterContainer from '../../containers/SalesLeadsFilterContainer'
import Pagination from '../Pagination'
import SafeButton from '../ui/SafeButton'

class SalesLeadFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.onTypeChange = this.onTypeChange.bind(this)
        this.deleteSelection = this.deleteSelection.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId, countries } = this.props
        changeFilter(filterId, { ...options, countries })
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    onTypeChange(types) {
        this._changeFilter({ types: types.map((type) => type.value), page: 0 })
    }

    deleteSelection() {
        const { filter, deleteSelection } = this.props
        return deleteSelection(filter.filterId)
    }

    render() {
        const { filter, count, compact } = this.props
        if (!filter || compact) {
            return null
        }

        const pageNr = filter.page || 0
        const selection = filter.selection
        return [
            <Row className="v-center" nm>
                {count > filter.pageSize && (
                    <div className="to-right">
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            onPageChange={this.onPageChange}
                        />
                    </div>
                )}
            </Row>,
            <Row n className="col-12 np">
                <Select
                    options={[
                        { value: 'all', title: 'All sales leads' },
                        { value: 'businessLeads', title: 'All business leads' },
                        { value: 'proSignup', title: 'Pro singup leads' },
                        { value: 'baf', title: 'Bring a friend leads' },
                        { value: 'external', title: 'External leads' },
                    ]}
                    onValueChange={this.onTypeChange}
                    className="select-stealth select-min upper c-blue"
                    value={filter.types || 'all'}
                />
                {selection && selection.length > 0 && (
                    <SafeButton
                        action={this.deleteSelection}
                        className="btn warning"
                        confirmMessage={`delete ${selection.length} labels`}
                        buttonText="delete"
                    />
                )}
            </Row>,
        ]
    }
}

SalesLeadFilter.propTypes = {}

export default SalesLeadsFilterContainer(SalesLeadFilter)
