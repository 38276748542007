export function appendMapsScript(idPostFix = '') {
    const id = `maps_sevice_area_already_loaded_${idPostFix}`
    if (document.getElementById(id)) {
        return
    }
    // Insert the google maps API script
    const el = document.createElement('script')
    el.id = id
    el.setAttribute(
        'src',
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDyxgz5RiInIBI9A3lj9vsw5stYVzyxeKU&libraries=places'
    )
    document.getElementsByTagName('html')[0].appendChild(el)
}

export function mapsPromise(id = '') {
    return new Promise(function (resolve, reject) {
        if (window['google'] && window['google']['maps']) {
            resolve(window['google'])
        } else {
            appendMapsScript(id)
            const intervalId = setInterval(() => {
                if (window['google']) {
                    clearInterval(intervalId)
                    resolve(window['google'])
                }
            }, 30)
        }
    })
}
