import { de, nl, nlBE } from 'date-fns/locale'

export function formatNumber(number: bigint | number) {
    return new Intl.NumberFormat(getLocale(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number)
}

export const formatPhoneDigitsOnly = (phone: string) => (phone || '').replace(/\D+/g, '')

/**
 * format with dash if price has 2 zeros: 12,00 => 12,-
 * no currency (psychological better for conversion)
 */
export function formatPriceWithoutCurrency(number: bigint | number) {
    return formatNumber(number).replace(/0{2}$/, '-')
}

export function formatPriceWithCurrency(number: number) {
    return `${number < 0 ? '-' : ''}€${formatPriceWithoutCurrency(Math.abs(number))}`
}

export function getLocale() {
    // locale format: <languageCode>-<countryCode>
    if (window.location.hostname.indexOf('trustlocal.be') !== -1) {
        return 'nl-BE'
    } else if (window.location.hostname.indexOf('trustlocal.de') !== -1) {
        return 'de-DE'
    } else {
        return 'nl'
    }
}

export function getCountryCode() {
    const locale = getLocale()
    if (locale.length > 2) {
        return locale.split('-')[1] as 'BE' | 'DE'
    }
    return locale.toUpperCase() as 'NL'
}

export const staticUrls: Record<string, string> = {
    NL: 'https://static.trustoo.nl',
    BE: 'https://static.trustlocal.be',
    DE: 'https://static.trustlocal.de',
}

export function getDateFnsLocale() {
    return {
        BE: nlBE,
        DE: de,
        NL: nl,
    }[getCountryCode()]
}

// https://en.wikipedia.org/wiki/Regional_Indicator_Symbol
// https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
export const getFlagEmoji = (countryCode: string): string => {
    const transCharCode = (charCode: number) => {
        const codePoint = 0x1f1e6 + charCode - 'A'.charCodeAt(0)
        return [
            Math.floor((codePoint - 0x10000) / 0x400) + 0xd800,
            ((codePoint - 0x10000) % 0x400) + 0xdc00,
        ]
    }

    countryCode = countryCode.toUpperCase()
    return String.fromCharCode(
        ...transCharCode(countryCode.charCodeAt(0)),
        ...transCharCode(countryCode.charCodeAt(1))
    )
}
