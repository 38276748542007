import React from 'react'
import { saveSalesLead } from '../../actions/salesLeads'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import LabelPicker from '../labels/LabelPicker'
import EntityComponent from '../services/EntityComponent'

class SalesLeadEditLabels extends EntityComponent {
    constructor(props) {
        super(props)
        this.onChangeLabels = this.onChangeLabels.bind(this)
    }

    editFields = {
        status: {
            type: 'text',
        },
    }

    onChangeLabels(values) {
        this.onChangeEntity({ office_labels: values })

        this.save()
    }

    render() {
        const { entity, category, classNames } = this.props

        return (
            <LabelPicker
                onValueChange={this.onChangeLabels}
                categories={['salesLeads']}
                classNames={classNames}
                value={(entity && entity.office_labels) || []}
            />
        )
    }
}

SalesLeadEditLabels.propTypes = {}

export default EntityComponentContainer(SalesLeadEditLabels, {
    store: 'salesLeads',
    name: 'salesLeads',
    saveFunc: saveSalesLead,
})
