import { Col, Row } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'

const SalesLeadExternal = ({ salesLeadContact }) => {
    const tableMap = [
        { key: 'first_name', name: 'First name' },
        { key: 'last_name', name: 'Last name' },
        {
            key: 'email',
            name: 'Email',
            item: <a href={`mailto:${salesLeadContact.email}`}>{salesLeadContact.email}</a>,
        },
        { key: 'phone', name: 'Phone' },
        { key: 'website', name: 'Website' },
    ]
    return (
        <div>
            {tableMap.map(({ key, name, item }) => {
                const answer = item || salesLeadContact[key]
                if (answer) {
                    return (
                        <Row key={name} y="min">
                            <Col c={4}>{name}</Col>
                            <Col c={8}>
                                <b>{answer}</b>
                            </Col>
                        </Row>
                    )
                }
            })}
        </div>
    )
}

SalesLeadExternal.propTypes = {
    salesLeadContact: PropTypes.object,
}

export default SalesLeadExternal
