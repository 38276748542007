import { schema } from 'normalizr'

const files = new schema.Entity('files', {}, { idAttribute: 'filename' })
const emailAddresses = new schema.Entity('emailAddresses', {}, { idAttribute: 'email_address_id' })
const user = new schema.Entity(
    'users',
    { email_addresses: [emailAddresses] },
    { idAttribute: 'id' }
)
const leadSetting = new schema.Entity('leadSettings', {}, { idAttribute: 'setting_id' })
const paymentData = new schema.Entity('paymentInfo', {}, { idAttribute: 'payment_info_id' })
const score = new schema.Entity('scores', {}, { idAttribute: 'score_id' })
const customPrices = new schema.Entity('customPrices', {}, { idAttribute: 'custom_price_id' })
const qualifications = new schema.Entity('qualifications', {}, { idAttribute: 'qualification_id' })
const profileIcentives = new schema.Entity(
    'profileIncentives',
    {},
    { idAttribute: 'profile_incentive_id' }
)
const logs = new schema.Entity('logs', { user }, { idAttribute: 'log_id' })
const photoProfilePackages = new schema.Entity(
    'photoProfilePackages',
    { logs: [logs] },
    { idAttribute: 'photo_profile_package_id' }
)
const franchisesLicences = new schema.Entity(
    'franchisesLicences',
    {},
    { idAttribute: 'franchises_licence_id' }
)

const bans = new schema.Entity('bans', {}, { idAttribute: 'ban_id' })

const projects = new schema.Entity(
    'projects',
    {
        files: [new schema.Entity('files', {}, { idAttribute: 'file_id' })],
    },
    { idAttribute: 'profile_project_id' }
)

const pauseReason = new schema.Entity('pauseReasons', {}, { idAttribute: 'pause_reason_id' })

const providersDates = new schema.Entity(
    'providersDates',
    {
        pause_reason: pauseReason,
        user: user,
    },
    {
        idAttribute: 'provider_date_id',
    }
)

const apis = new schema.Entity('apis', {}, { idAttribute: 'api_id' })

const providersApis = new schema.Entity(
    'providersApis',
    { api: apis },
    { idAttribute: 'provider_api_id' }
)

const calendlyEventTypes = new schema.Entity(
    'calendlyEventTypes',
    {},
    { idAttribute: 'calendly_event_type_id' }
)

const calendlyEvents = new schema.Entity(
    'calendlyEvents',
    {
        calendly_event_type: calendlyEventTypes,
    },
    { idAttribute: 'calendly_event_id' }
)

const cbr = new schema.Entity('cbrs', {}, { idAttribute: 'cbr_id' })

const providersPlatforms = new schema.Entity(
    'providersPlatforms',
    {},
    { idAttribute: 'service_providers_platform_id' }
)

const providersSchema = new schema.Entity(
    'providers',
    {
        files: [files],
        lead_setting: leadSetting,
        payment_info: paymentData,
        providers_score: score,
        custom_prices: [customPrices],
        qualifications: [qualifications],
        profile_incentive: profileIcentives,
        photo_profile_packages: [photoProfilePackages],
        franchises_licences: [franchisesLicences],
        bans: [bans],
        logs: [logs],
        user,
        profile_projects: [projects],
        providers_dates: [providersDates],
        service_providers_apis: [providersApis],
        active_providers_apis: [providersApis],
        calendly_events: [calendlyEvents],
        cbr,
        providers_platforms: [providersPlatforms],
    },
    {
        idAttribute: 'service_provider_id',
    }
)

export default [providersSchema]
