import { schema } from 'normalizr'

const options = new schema.Entity('options', {}, { idAttribute: 'option_id' })

const questions = new schema.Entity(
    'questions',
    {
        options: [options],
    },
    { idAttribute: 'question_id' }
)

const files = new schema.Entity('files', {}, { idAttribute: 'filename' })
const emailAddresses = new schema.Entity('emailAddresses', {}, { idAttribute: 'email_address_id' })
const user = new schema.Entity(
    'users',
    { email_addresses: [emailAddresses] },
    { idAttribute: 'id' }
)

const requestsSchema = new schema.Entity(
    'requests',
    {
        options: [options],
        questions: [questions],
        files: [files],
        user,
        requests_dates: [
            new schema.Entity(
                'requestsDates',
                {
                    question: questions,
                },
                { idAttribute: 'requests_dates_id' }
            ),
        ],
        requests_months: [
            new schema.Entity(
                'requestsMonths',
                {
                    question: questions,
                },
                { idAttribute: 'requests_month_id' }
            ),
        ],
        requests_moments: [
            new schema.Entity(
                'requestsMoments',
                {
                    question: questions,
                },
                { idAttribute: 'requests_moment_id' }
            ),
        ],
    },
    {
        idAttribute: 'request_id',
    }
)

export default [requestsSchema]
