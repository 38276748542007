import { Col, Icon, Row } from '@advanza/advanza_generic'
import React from 'react'
import { FormattedRelative } from 'react-intl'
import SalesLeadStatusContainer from '../../containers/SalesLeadStatusContainer'
import AdminUser from '../adminUsers/AdminUser'
import OfficeLabel from '../OfficeLabel'
import SafeButton from '../ui/SafeButton'

class SalesLeadStatus extends React.Component {
    render() {
        const { salesLeadStatus, status, unsetStatus } = this.props
        return (
            <Row className="v-center">
                <Col c="auto">
                    <AdminUser userId={salesLeadStatus.user_id} avatarOnly />
                </Col>
                <Col c="auto">
                    <OfficeLabel label={status} textOnly />
                </Col>
                <div className="to-right">
                    <SafeButton
                        action={unsetStatus}
                        buttonText={<Icon>undo</Icon>}
                        confirmMessage="Unset status"
                    />
                </div>
                <Col c={12}>
                    <small className="c-grey">
                        <FormattedRelative value={salesLeadStatus.created} /> by{' '}
                        <AdminUser userId={salesLeadStatus.user_id} />
                    </small>
                </Col>
            </Row>
        )
    }
}

SalesLeadStatus.propTypes = {}

export default SalesLeadStatusContainer(SalesLeadStatus)
