import { schema } from 'normalizr'

const matchesPrices = new schema.Entity('matches_prices', {}, { idAttribute: 'matches_prices_id' })
const matchesHired = new schema.Entity('matches_hired', {}, { idAttribute: 'hired_id' })

const messages = new schema.Entity('messages', {}, { idAttribute: 'message_id' })

const matchesSchema = new schema.Entity(
    'matches',
    {
        messages: [messages],
        matches_hired: [matchesHired],
        matches_prices: [matchesPrices],
    },
    {
        idAttribute: 'match_id',
    }
)

export default [matchesSchema]
