import ManageBodyOverflow from 'components/ui/modal/ManageBodyOverflow'
import PropTypes from 'prop-types'
import React from 'react'
import * as ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import style from './style.module.css'

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.onBodyClick = this.onBodyClick.bind(this)
        this.onEnter = this.onEnter.bind(this)
        this.onExit = this.onExit.bind(this)
        this.clientWidth = document.documentElement.clientWidth
    }

    onEnter() {
        const { closeOnOutsideClick } = this.props
        if (closeOnOutsideClick) {
            window.addEventListener('click', this.onBodyClick)
            window.addEventListener('touchend', this.onBodyClick)
        }
    }
    onExit() {
        const { closeOnOutsideClick } = this.props
        if (closeOnOutsideClick) {
            window.removeEventListener('click', this.onBodyClick)
            window.removeEventListener('touchend', this.onBodyClick)
        }
    }

    onBodyClick(e) {
        const containerNode = ReactDOM.findDOMNode(this.ref)
        if (
            containerNode &&
            e.target !== containerNode &&
            !containerNode.contains(e.target) &&
            this.props.open
        ) {
            this.props.close()
        }
    }

    render() {
        const {
            children,
            open,
            close,
            maxWidth,
            wrapContent = true,
            allowOverflow = false,
        } = this.props
        return ReactDOM.createPortal(
            <div>
                <CSSTransition
                    in={open}
                    onEntered={this.onEnter}
                    onExited={this.onExit}
                    classNames={{
                        enter: style.in,
                        enterActive: style.inActive,
                        exitActive: style.outActive,
                        exit: style.out,
                    }}
                    timeout={300}
                    unmountOnExit>
                    <div>
                        {!allowOverflow && <ManageBodyOverflow />}
                        <div className={style.modal} id="_modal">
                            <div
                                style={{ maxWidth }}
                                ref={(ref) => (this.ref = ref)}
                                className={style.modalInside}>
                                <div className={style.closeButton}>
                                    <button onClick={close}>
                                        <div />
                                        <div />
                                    </button>
                                </div>
                                {wrapContent ? (
                                    <div className={style.content}>{children}</div>
                                ) : (
                                    children
                                )}
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>,
            document.getElementById('portal_1')
        )
    }
}

Modal.propTypes = {
    name: PropTypes.string,
}

export default Modal
