import React, {Component} from 'react'

export default class Loading extends Component {
    render() {
        const classNames = ['loading']
        let extraStyle = {}
        if (this.props.style) {
            extraStyle = {...extraStyle, ...this.props.style}
        }
        if (this.props.inside) {
            classNames.push('inside')
        }
        return <div {...(Object.keys(extraStyle).length > 0 ? {style: extraStyle} : {})}>
            <div>
                <div className={classNames.join(' ')}/>
            </div>
        </div>
    }
}