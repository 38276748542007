import React from "react"
import PropTypes from "prop-types"

export const Tab = ({value, onChange, options, className}) => {
    return (
        <ul className={'tab row no-gutters ' + (className || '')}>
            {options.map((option, i) => (
                <li className={(option.id === value ? "active" : '')}
                    onClick={() => onChange(option.id)}
                    key={option.id}>

                    <div>
                        {option.name}
                    </div>
                    <div className="line"/>
                </li>
            ))}
        </ul>
    )
}

Tab.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    onChange: PropTypes.func.isRequired
}