import { call } from '@advanza/api'
import matchesSchema from '../schemes/matchesSchema'
import { salesLeadsStatusesSchema, statusesSchema } from '../schemes/statusesSchema'
import { changeEntity } from './entities'
import { _changeFilter, _fetchItems, _preLoadFilter } from './lists'
import { MATCHES_RECEIVE_LIST } from './matches'

export const STATUSES_RECEIVE_LIST = 'STATUSES_RECEIVE_LIST'
export const STATUSES_CHANGE_FILTER = 'STATUSES_CHANGE_FILTER'
export const STATUSES_REQUEST = 'STATUSES_REQUEST'

function getFetchFunc(type) {
    switch (type) {
        case 'salesLeads':
            return fetchSalesLeadsStatuses
        default:
            return fetchStatuses
    }
}
export function fetchStatuses(filterId = 'all') {
    return _fetchItems(filterId, {
        url: 'office/statuses/get-list',
        schema: statusesSchema,
        requestActionName: STATUSES_REQUEST,
        receiveActionName: STATUSES_RECEIVE_LIST,
        reducerKey: 'statuses',
    })
}

export function fetchSalesLeadsStatuses(filterId = 'all_sales_leads') {
    return _fetchItems(filterId, {
        url: 'office/statuses/get-list',
        schema: salesLeadsStatusesSchema,
        requestActionName: STATUSES_REQUEST,
        receiveActionName: STATUSES_RECEIVE_LIST,
        reducerKey: 'statuses',
    })
}

export function changeStatusesFilter(type, filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'statuses',
        fetchFunc: getFetchFunc(type),
        actionType: STATUSES_CHANGE_FILTER,
    })
}

export function addStatus(options, filterId) {
    return function (dispatch) {
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                diff: {
                    status_id: newId,
                    ...options,
                    color: '#DCE9FE',
                    is_unique: false,
                },
                key: newId,
                filterId,
                name: 'statuses',
                store: 'statuses',
            })
        )
    }
}

export function saveStatus(statusId) {
    return function (dispatch, getState) {
        const status = getState().statuses.entities.statuses[statusId]
        const isNew = typeof statusId === 'string'
        return call(`office/statuses/save/${isNew ? '' : statusId}`, { json: { ...status } }).then(
            (response) => {
                if (isNew) {
                    dispatch({ type: 'INVALIDATE_STATUSES' })
                }
            }
        )
    }
}

function deleteStatus(statusId) {
    return function (dispatch) {
        const isNew = typeof statusId === 'string'
        const removeFromStore = () => {
            dispatch(
                changeEntity({ store: 'statuses', name: 'statuses', remove: true, key: statusId })
            )
        }
        if (isNew) {
            return removeFromStore()
        }
        return call(`office/statuses/delete/${statusId}`, { method: 'post' }).then(removeFromStore)
    }
}

export function deleteStatusesSelection(filterId) {
    return function (dispatch, getState) {
        const selection = getState().statuses.filters[filterId].selection
        const promises = selection.map((id) => dispatch(deleteStatus(id)))
        return Promise.all(promises).then(() => {
            dispatch(changeStatusesFilter(filterId, { selection: [] }))
        })
    }
}
