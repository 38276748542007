import { Section } from '@advanza/advanza_generic'
import { Divider, MaxWidth } from '@advanza/ui'
import React from 'react'
import Page from '../ui/Page'
import EventFilter from './EventFilter'
import EventList from './EventList'

class EventsPage extends React.Component {
    render() {
        return (
            <Page title="Events">
                <MaxWidth>
                    <h1>Events</h1>
                    <Divider l />
                    <EventFilter filterId="all" defaultOptions={{ pageSize: 40 }} />
                    <EventList filterId="all" />
                </MaxWidth>
            </Page>
        )
    }
}

export default EventsPage
