import React, { useEffect } from 'react'
import style from './Dialog.module.css'

type DialogProps = {
    open: boolean
    onClose: () => void
    children: React.ReactNode
    closeButton?: boolean
    groovy?: boolean
}

/**
 * Simple native html dialog wrapper
 *
 * Lighter than Modal and easier to maintain, but less flexible
 *
 * */
const Dialog = ({ children, open, onClose, closeButton=true, groovy = true }: DialogProps) => {
    const dialogRef = React.useRef<HTMLDialogElement>(null)

    // Handle open & close
    useEffect(() => {
        const isOpen = dialogRef.current?.open
        open && !isOpen && dialogRef.current?.showModal()
        !open && isOpen && dialogRef.current?.close()

        dialogRef.current?.addEventListener('close', onClose)
        return () => {
            dialogRef.current?.removeEventListener('close', onClose)
        }
    }, [open])

    return (
        <dialog ref={dialogRef} className={`${style.root} ${groovy && style.groovy}`}>
            {closeButton && <CloseButton onClick={() => dialogRef.current?.close()} />}
            {children}
        </dialog>
    )
}

const CloseButton = ({ onClick }: { onClick: () => void }) => (
    <button className={style.close} autoFocus onClick={onClick}>
        ✕
    </button>
)

export default Dialog
