import providersDatesSchema from 'schemes/providersDatesSchema'
import { _changeFilter, _fetchItems } from './lists'

export const PROVIDERS_DATES_REQUEST = 'PROVIDERS_DATES_REQUEST'
export const PROVIDERS_DATES_RECEIVE_LIST = 'PROVIDERS_DATES_RECEIVE_LIST'
export const PROVIDERS_DATES_CHANGE_FILTER = 'PROVIDERS_DATES_CHANGE_FILTER'

export function fetchProvidersDates(filterId) {
    return _fetchItems(filterId, {
        url: 'office/providers/get-list-providers-dates',
        schema: providersDatesSchema,
        requestActionName: PROVIDERS_DATES_REQUEST,
        receiveActionName: PROVIDERS_DATES_RECEIVE_LIST,
        reducerKey: 'providersDates',
    })
}

export function changeProviderDatesFilter(filterId, filterOptions, options = {}) {
    return _changeFilter(filterId, filterOptions, {
        reducerKey: 'providersDates',
        fetchFunc: fetchProvidersDates,
        actionType: PROVIDERS_DATES_CHANGE_FILTER,
        replace: options.replace,
    })
}
