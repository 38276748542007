import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, LoadingDots } from '@advanza/ui'
import A from 'components/ui/A'
import { getDate } from 'date'
import format from 'date-fns/format'
import { answerReclaim } from 'hooks/reclaimHooks'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import DatePicker from 'components/DatePicker'

const ReclaimButtons = ({ reclaim }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [date, setDate] = useState(null)
    const [acceptAlt, setAcceptAlt] = useState(false)
    const [rejectReason, setRejectReason] = useState(reclaim.admin_comment || '')
    const onAccept = () => {
        setAnswer(true)
        setAcceptAlt(false)
        setOpen(true)
    }
    const onReject = () => {
        setAnswer(false)
        setAcceptAlt(false)
        setOpen(true)
    }
    const onAcceptAlt = () => {
        setAnswer(true)
        setAcceptAlt(true)
        setOpen(true)
    }
    const onReclaim = (answer) => {
        setLoading(true)
        const options = {
            date: date,
            alt: acceptAlt,
        }
        dispatch(answerReclaim(reclaim.reclaim_id, answer, rejectReason, options)).then(
            () => {
                setLoading(false)
                setOpen(false)
            },
            ({ error }) => {
                setLoading(false)
                setError(error)
            }
        )
    }

    const providerUrl = `/service/providers/provider/${reclaim.providerId}/profile`
    const requestUrl = `/service/requests/request/${reclaim.requestId}`
    const reclaimMap = [
        { key: 'reclaim_id', name: '#' },
        { key: 'businessName', name: 'Naam' },
        {
            key: 'matchDate',
            name: 'AanvraagDatum',
            item: reclaim.matchDate ? format(getDate(reclaim.matchDate), 'd-MMM-yyyy') : '',
        },
        { key: 'reason', name: 'Reden' },
        {
            key: 'leadName',
            name: 'Betreft aanvraag',
            item: (
                <A className="classic" to={requestUrl}>
                    {(reclaim.firstName ? reclaim.firstName + ' ' : '') +
                        (reclaim.lastName ? reclaim.lastName : '')}
                </A>
            ),
        },
        {
            key: 'admin_comment',
            item: (
                <InputField
                    textArea
                    onChange={({ target }) => setRejectReason(target.value)}
                    autoFocus
                    value={rejectReason}
                    placeholder={'Geef een reden op' + (answer ? ' (optioneel)' : '')}
                    style={{ marginTop: 20 }}
                />
            ),
        },
        {
            key: 'date',
            item: (
                <div>
                    {!date && (
                        <Button name="text" onClick={() => setDate(new Date())}>
                            Set custom date
                        </Button>
                    )}
                    {date && (
                        <Fragment>
                            <Divider m />
                            <DatePicker
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                opened
                            />
                        </Fragment>
                    )}
                </div>
            ),
        },
    ]

    return (
        <div>
            {!reclaim.accepted && !reclaim.denied ? (
                <Row inline>
                    <Col xs-4>
                        <button onClick={onAccept}>
                            <Icon name="check_circle" green />
                        </button>
                    </Col>
                    <Col xs-4>
                        <button onClick={onAcceptAlt}>
                            <Icon name={'offline_pin'} orange />
                        </button>
                    </Col>
                    <Col xs-4>
                        <button onClick={onReject}>
                            <Icon name="cancel" red />
                        </button>
                    </Col>
                </Row>
            ) : (
                'Reclaimed'
            )}

            <Modal open={open} close={() => setOpen(false)}>
                <h3>
                    {loading ? (
                        <Fragment>
                            Reclaiming
                            <LoadingDots />
                        </Fragment>
                    ) : error ? (
                        'Error'
                    ) : answer && acceptAlt ? (
                        <Fragment>
                            Accept reclaim <Icon name="offline_pin" orange />
                        </Fragment>
                    ) : answer ? (
                        <Fragment>
                            Accept reclaim <Icon name="check" green />
                        </Fragment>
                    ) : (
                        <Fragment>
                            Reject reclaim <Icon name="cancel" red />
                        </Fragment>
                    )}
                </h3>
                {acceptAlt && (
                    <span>
                        Opgegeven reden valt niet binnen het reclaimbeleid, maar reclaim toch
                        toegekend
                    </span>
                )}
                <Divider m />
                {reclaimMap.map(({ key, name, item, rejectOnly }, i) => {
                    const value = item || reclaim[key]
                    const rowKey = reclaim.reclaim_id + '_' + name
                    if (value) {
                        if (answer && rejectOnly) {
                            return
                        }
                        return name ? (
                            <Row key={i}>
                                <Col xs>
                                    <div className="col-h">{name}</div>
                                </Col>
                                <Col xs>
                                    <div>{value}</div>
                                </Col>
                            </Row>
                        ) : (
                            <Row key={i}>
                                <Col xs={12}>
                                    <div>{value}</div>
                                </Col>
                            </Row>
                        )
                    }
                })}
                {reclaim.advanzaId && (
                    <a
                        style={{ color: 'red', fontWeight: 'bold', textDecoration: 'underline' }}
                        href={`https://leads.advanza.nl/Company/wrapper?company_id=${reclaim.advanzaId}`}>
                        {' '}
                        Let op! dit bedrijf is ook aanwezig op advanza.
                    </a>
                )}
                <Divider m />
                <Row middle="xs">
                    <Col x>
                        {error || (
                            <Button
                                className="danger"
                                onClick={() =>
                                    answer ? onReclaim('accept') : onReclaim('reject', rejectReason)
                                }
                                disabled={rejectReason.length < 1 && !answer}>
                                {loading ? <LoadingDots color="#dadada" /> : 'Confirm'}
                            </Button>
                        )}
                    </Col>
                    <Col x>
                        <button onClick={() => setOpen(false)}>Cancel</button>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default ReclaimButtons
