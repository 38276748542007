import AggregatedReviewEntity from 'components/aggregatedReviews/AggregatedReviewEntity'
import Card from 'components/Card'
import AggregatedReviewsListContainer from 'containers/AggregatedReviewsListContainer'
import PropTypes from 'prop-types'
import React from 'react'
import TableList from '../TableList'

class AggregatedReviewsList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { aggregatedReviews } = this.props.entities
        const item = aggregatedReviews[id]
        return [
            item.aggregated_review_id,
            item.aggregated_reviews_source.source_name,
            item.nr_ratings,
            item.rating,
        ]
    }

    renderRow(id) {
        const { aggregatedReviews } = this.props.entities
        const item = aggregatedReviews[id]
        return {
            cols: this.renderColumns(id),
            id,
            expandOnClick: true,
            expanded: item._open,
            expandable: (
                <Card expandableStyle>
                    <AggregatedReviewEntity entityId={id} />
                </Card>
            ),
        }
    }

    definitions = {
        id: { name: '#' },
        source: { name: 'source' },
        nrRatings: { name: 'nr' },
        averageScore: { name: 'avg' },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

AggregatedReviewsList.propTypes = {
    filter: PropTypes.object,
    pages: PropTypes.any,
    eventEntities: PropTypes.object,
    isFetching: PropTypes.any,
}

export default AggregatedReviewsListContainer(AggregatedReviewsList)
