import { Icon } from '@advanza/ui'
import style from './questionsListWarning.module.css'

interface Props {
    text?: string
}

const QuestionsListWarning = ({ text = 'Too many price questions' }: Props) => {
    return (
        <div className={style.root}>
            <div className={style.animatedWarning}>
                <Icon name="warning" />
                {text}
                <Icon name="warning" />
            </div>
            <div>
                There should only be 1 active price question. Steps for switching the price
                question:
            </div>
            <ol>
                <li>set the status of all the options of the new price question to active</li>
                <li>set the status of the new price question active</li>
                <li>save the changes to the new price question</li>
                <li>set the status of the old price question to hidden</li>
                <li>save the old price question</li>
            </ol>
        </div>
    )
}

export default QuestionsListWarning
