import { call } from '@advanza/api'
import { normalize } from 'normalizr'
import requestsSchema from '../schemes/requestsSchema'
import servicesSchema from '../schemes/servicesSchema'
import { changeEntity } from '@advanza/redux_entity'
import { invalidateEvents } from './events'
import { _changeFilter, _fetchItems, _preLoadFilter } from './lists'
import { invalidateMatches } from './matches'
import { invalidateNotes, preLoadNotes } from './notes'
import { SERVICES_RECEIVE_SINGLE } from './services'

export const REQUESTS_REQUEST = 'REQUESTS_REQUEST'
export const REQUESTS_RECEIVE_LIST = 'REQUESTS_RECEIVE_LIST'
export const REQUESTS_RECEIVE_SINGLE = 'REQUESTS_RECEIVE_SINGLE'
export const REQUESTS_CHANGE_FILTER = 'REQUESTS_CHANGE_FILTER'
export const REQUESTS_DECLARE_VALID = 'REQUESTS_DECLARE_VALID'
export const REQUESTS_DELETED = 'REQUESTS_DELETED'

export function fetchRequests(filterId) {
    return _fetchItems(filterId, {
        reducerKey: 'requests',
        receiveActionName: REQUESTS_RECEIVE_LIST,
        requestActionName: REQUESTS_REQUEST,
        url: 'office/requests/get-list',
        schema: requestsSchema,
    })
}

export function changeRequestFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'requests',
        actionType: REQUESTS_CHANGE_FILTER,
        fetchFunc: fetchRequests,
    })
}

export function preLoadRequests(filterId, options, response) {
    return _preLoadFilter(filterId, options, response, {
        schema: requestsSchema,
        reducerKey: 'requests',
        receiveActionName: REQUESTS_RECEIVE_LIST,
    })
}

export function fetchRequest(requestId, filterSource = 'default', force = false) {
    return function (dispatch, getState) {
        const requestEntities = getState().requests.entities.requests
        if (
            !force &&
            requestEntities &&
            requestEntities[requestId] &&
            requestEntities[requestId].requests_options
        ) {
            return Promise.resolve()
        }
        dispatch({ type: REQUESTS_REQUEST })

        return call('office/requests/get-one/' + requestId, {
            query: { source: filterSource },
        }).then((response) => {
            dispatch(
                preLoadNotes(
                    `r_${requestId}`,
                    { requestIds: [requestId] },
                    { items: response.notes }
                )
            )
            const { entities } = normalize([response.item], requestsSchema)
            const { entities: serviceEntities } = normalize([response.service], servicesSchema)
            dispatch({ type: REQUESTS_RECEIVE_SINGLE, entities, requestId })
            dispatch({ type: SERVICES_RECEIVE_SINGLE, entities: serviceEntities })
        })
    }
}

export function declareValid(requestId) {
    return function (dispatch) {
        return call('office/requests/declare-valid/' + requestId, { method: 'post' }).then(
            (response) => {
                dispatch({ type: REQUESTS_DECLARE_VALID, requestId })
                dispatch({ type: 'INVALIDATE_MATCHES' })
                dispatch({ type: 'INVALIDATE_NOTES' })
                dispatch({ type: 'INVALIDATE_MATCHES' })
            }
        )
    }
}

export function deleteRequest(requestId) {
    return function (dispatch) {
        return call('office/requests/delete-request/' + requestId, { method: 'post' }).then(
            (response) => {
                dispatch({ type: REQUESTS_DELETED, requestId })
                dispatch({ type: 'INVALIDATE_MATCHES' })
                dispatch({ type: 'INVALIDATE_NOTES' })
                dispatch({ type: 'INVALIDATE_MATCHES' })
            }
        )
    }
}
export function changeRequestTypeToInfoMessage(requestId) {
    return function (dispatch) {
        return call('office/requests/change-request-type-to-info-message/' + requestId, {
            method: 'post',
        }).then((response) => {
            dispatch(fetchRequest(requestId, null, true))
            dispatch({ type: 'INVALIDATE_MATCHES' })
            dispatch({ type: 'INVALIDATE_NOTES' })
            dispatch({ type: 'INVALIDATE_MATCHES' })
        })
    }
}

export function generateDemandDashboardLink(requestId) {
    return function (dispatch, getState) {
        const userId = getState().requests.entities.requests[requestId].user
        const { _dashboardLink } = getState().requests.entities.users[userId]
        if (_dashboardLink) {
            return Promise.resolve(_dashboardLink)
        }
        return call('office/requests/generate-demand-dashboard-link/' + requestId).then(
            (response) => {
                dispatch(
                    changeEntity({
                        store: 'requests',
                        name: 'users',
                        key: userId,
                        diff: {
                            _dashboardLink: response.link,
                        },
                    })
                )
                return Promise.resolve(response.link)
            }
        )
    }
}
