import { schema } from 'normalizr'

const services = new schema.Entity('services', {}, { idAttribute: 'service_id' })

const licencesSchema = new schema.Entity(
    'licences',
    {
        services: [services],
    },
    {
        idAttribute: 'licence_id',
    }
)

export default [licencesSchema]
