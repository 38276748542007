import PropTypes from 'prop-types'
import React from "react"
import {injectIntl} from "react-intl"
import {bodyClickOutside} from "./services/bodyClick"

class AutoGrowField extends React.Component {



    constructor(props) {
        super(props)
        this.el = undefined
        this.handleChange = this.handleChange.bind(this)
        this.calcHeight = this.calcHeight.bind(this)
        this.handleFocus = this.handleFocus.bind(this)
        this.bodyClick = this.bodyClick.bind(this)
        this.preventFocus = true
    }

    bodyClick(e) {
        bodyClickOutside(e, {
            className: 'autogrow',
            action   : () => this.el && this.el.blur()
        })
    }

    componentDidMount() {
        document.body.addEventListener('click', this.bodyClick)
        document.body.addEventListener('touchend', this.bodyClick)
        const {autoFocus} = this.props

        this.calcHeight()
        autoFocus && this.handleAutoFocus()

    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClick)
        document.body.removeEventListener('touchend', this.bodyClick)
    }

    handleChange(e) {
        const {onChange} = this.props
        onChange && onChange(e)

    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.calcHeight()
        }

        const {autoFocus, intl} = this.props

        if (prevProps.intl !== intl && autoFocus) {
            this.handleAutoFocus()
        }
    }

    handleFocus(e) {
        const {onFocus, cursorToEnd} = this.props
        onFocus && onFocus(e)
        if (this.el && cursorToEnd) {
            const value = this.el.value
            this.el.value = ""
            this.el.value = value
        }

    }

    handleAutoFocus() {
        let success = false
        const intervals = [50, 100, 200]
        const doAutoFocus = () => {
            if (success) {
                return
            }
            if (this.el) {
                success = true
                this.el.focus()
            }
        }
        for (const i in intervals) {
            setTimeout(doAutoFocus, intervals[i])
        }
    }

    calcHeight() {
        if (!this.el) {
            return
        }
        const {maxRows, defaultMinHeight=10} = this.props
        const isFocused = document.activeElement.tagName === 'TEXTAREA'
        // set the minHeight to default for a short while so the real scrollHeight can be calculated.
        this.el.style.minHeight = defaultMinHeight
        const scrollHeight = this.el.scrollHeight

        // calculate the line-height in pixels by inserting a line break
        const inputValue = this.el.value
        const originalSelectionStart = this.el.selectionStart
        this.el.value = inputValue + '\n'
        const lineHeight = this.el.scrollHeight - scrollHeight
        this.el.value = inputValue

        // prevent focusing the input on IOS mobile
        if (!isFocused) {
            this.el.readOnly = true
        }
        this.el.setSelectionRange(originalSelectionStart, originalSelectionStart)
        if (!isFocused) {
            this.el.readOnly = false
        }
        const nrLines = Math.floor(scrollHeight / lineHeight - 1)
        if (nrLines <= maxRows) {
            this.el.style.minHeight = scrollHeight + 'px'
        } else {
            this.el.style.minHeight = (lineHeight * maxRows) + scrollHeight - (nrLines * lineHeight) + 'px'
        }

    }


    render() {
        const {maxRows, intl, msgid,style={}, ...rest} = this.props
        return <textarea {...rest}
                         style={{resize: 'none', ...style}}
                         className={"autogrow " + (rest.className || '')}
                         onChange={this.handleChange}
                         onFocus={this.handleFocus}
                         placeholder={msgid ? intl.formatMessage({id: msgid}) : ''}
                         ref={el => this.el = el}/>

    }
}

AutoGrowField.propTypes = {
    maxRows    : PropTypes.number,
    msgid      : PropTypes.string,
    onFocus    : PropTypes.func,
    onChange   : PropTypes.func,
    value      : PropTypes.any,
    // move the cursor to the end after focus.
    cursorToEnd: PropTypes.bool,

    autoFocus: PropTypes.bool
}

export default injectIntl(AutoGrowField)