import { call } from '@advanza/api'
import { Button, Icon } from '@advanza/ui'
import TableList from 'components/TableList'
import { useList } from 'hooks/listHooks'
import { schema } from 'normalizr'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useBansList = (filterId = 'all') => {
    const { countries } = useSelector((state) => state.countries)
    const result = useList(
        filterId,
        {
            url: 'office/banned-requests/get-list',
            schema: [new schema.Entity('bannedRequests', {}, { idAttribute: 'banned_request_id' })],
            reducerKey: 'bannedRequests',
        },
        { countries, sorting: ['word', 'ASC'], pageSize: 100 }
    )

    useEffect(() => {
        result.changeFilter({ countries })
    }, [countries])

    return result
}

const BannedRequestsList = ({ filterId = 'all' }) => {
    const dispatch = useDispatch()
    const {
        entities: { bannedRequests = {} } = {},
        extra: { definitionsBannedRequests = {} } = {},
        ...list
    } = useBansList(filterId)
    const [deletingIds, setDeletingIds] = useState({})

    const definitions = {
        ...definitionsBannedRequests,
        deleteButton: { name: '' },
    }

    const renderRow = (bannedRequestId) => {
        const bannedRequest = bannedRequests[bannedRequestId] || {}
        return {
            cols: Object.keys(definitions).map((key) => {
                if (key === 'deleteButton') {
                    return (
                        <Button
                            key={key}
                            name="text"
                            disabled={deletingIds[bannedRequestId]}
                            onClick={() => {
                                setDeletingIds((deletingIds) => ({
                                    ...deletingIds,
                                    [bannedRequestId]: true,
                                }))
                                call('office/banned-requests/delete/' + bannedRequestId, {
                                    method: 'post',
                                }).finally(() => {
                                    setDeletingIds((deletingIds) => ({
                                        ...deletingIds,
                                        [bannedRequestId]: false,
                                    }))
                                    dispatch({ type: 'INVALIDATE_BANNEDREQUESTS' })
                                })
                            }}>
                            <Icon name="delete_forever" />
                        </Button>
                    )
                }

                return bannedRequest[key]
            }),
        }
    }

    return <TableList definitions={definitions} renderRow={renderRow} addListFeatures {...list} />
}

export default BannedRequestsList
