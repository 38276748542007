import { Button, PreIcon } from '@advanza/ui'
import React from 'react'
import { useSelector } from 'react-redux'

const ServiceStatsExportButton = ({ serviceId }) => {
    const { competitionStats, requestStats } = useSelector((state) => state.serviceStats.options)
    const { protocol, hostname } = window.location
    const url =
        `${protocol}//${hostname}/api/office/services/export-service-stats-csv?encoded=` +
        btoa(
            JSON.stringify({
                competitionOptions: competitionStats[serviceId],
                requestOptions: requestStats[serviceId],
                serviceId,
            })
        )

    return (
        <Button name="text">
            <PreIcon primColor icon="download">
                <a href={url} target="_blank">
                    Export
                </a>
            </PreIcon>
        </Button>
    )
}

export default ServiceStatsExportButton
