import { call } from '@advanza/api'
import { SelectSimple } from '@advanza/input'
import { useCurrentCountry } from 'hooks/miscHooks'
import { useEffect, useState } from 'react'

const OptionPicker = ({ onChange, value, hideService = 0 }) => {
    const [services, setServices] = useState([])
    const country = useCurrentCountry()
    useEffect(() => {
        call('office/services/get-list', {
            query: { includeQuestionnaires: true, countries: [country], pageSize: 1000 },
        }).then(({ items }) => setServices(items))
    }, [])

    const options = []
    services
        .filter(({ service_id }) => service_id !== hideService)
        .forEach((service) => {
            service.questions.forEach((question) => {
                if (question.filterable) {
                    question.options.forEach((option) => {
                        options.push({
                            value: option.option_id,
                            name: `${service.name} - ${option.value} (${question.question_name_filter})`,
                        })
                    })
                }
            })
        })
    return (
        <SelectSimple
            value={value}
            alwaysControlValue
            emptyOption
            placeholder="Main service option"
            onChange={(e) => onChange(e.target.value)}
            options={options}
        />
    )
}

export default OptionPicker
