import React from 'react'
import { connect } from 'react-redux'

function mapStateToProps(state, props) {
    const { entities, result, isFetching, filters } = state.aggregatedReviews
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    return {
        entities,
        pages: result[searchKey] || {},
        filter,
        isFetching,
    }
}

export default connect(mapStateToProps)
