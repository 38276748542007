import { Col, Row, Section } from '@advanza/advanza_generic'
import { call } from '@advanza/api'
import { Button, Divider, PreIcon } from '@advanza/ui'
import React from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { saveAdminUser } from '../../actions/adminUsers'
import { onFilesRejected } from '../../actions/files'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import AvatarEdit from '../AvatarEdit'
import Restricted from '../Restricted'
import EntityComponent from '../services/EntityComponent'
import SafeButton from '../ui/SafeButton'
import AdminUserPermissions from './AdminUserPermissions'

class AdminUserEntity extends EntityComponent {
    constructor(props) {
        super(props)
        this.onChangePermission = this.onChangePermission.bind(this)
        this.getPermission = this.getPermission.bind(this)
        this.onCrop = this.onCrop.bind(this)
        this.onChangeAvatar = this.onChangeAvatar.bind(this)
    }

    editFields() {
        return {
            email: { type: 'email', validator: isEmail, msg: 'Email' },
            first_name: { type: 'text', msg: 'First name' },
            last_name: { type: 'text', msg: 'Last name' },
            active: {
                type: 'boolean',
                reverse: true,
                msg: 'Disabled',
                className: 'negative',
            },
            two_factor_enabled: {
                type: 'boolean',
                msg: 'Two-factor enabled',
            },
        }
    }

    onChangePermission(permission, e) {
        const { value, name } = e.target
        const { entity } = this.props
        this.onChangeEntity({
            permissions: entity.permissions.map((oldPermission) => {
                if (oldPermission.permission_id === permission.permission_id) {
                    return {
                        ...oldPermission,
                        _joinData: { ...oldPermission._joinData, [name]: value },
                    }
                }
                return oldPermission
            }),
        })
    }

    getPermission(permissionId) {
        const { entity } = this.props
        let permission =
            entity.permissions.filter(
                (permission) => permission.permission_id === permissionId
            )[0] || null
        if (!permission) {
            permission = {
                user_id: entity.id,
                permission_id: permissionId,
                _joinData: {
                    r: false,
                    w: false,
                },
            }
            this.onChangeEntity({
                permissions: entity.permissions.concat([permission]),
            })
        }

        return permission
    }

    onChangeAvatar(file) {
        const { avatarFile = {} } = this.props.entity
        this.onChangeEntity({
            avatarFile: { ...avatarFile, ...file },
        })
    }

    onCrop(crop, crop2) {
        const { avatarFile = {} } = this.props.entity
        this.onChangeEntity({
            avatarFile: { ...avatarFile, crop: { ...crop, aspect: 1 }, crop2 },
        })
    }

    render() {
        const { entity, sendPasswordMail, onFilesRejected, addToast } = this.props

        return (
            <div>
                <Row n>
                    <Col>
                        <h2>Permissions</h2>
                        <AdminUserPermissions
                            entity={entity}
                            onChangeEntity={this.onChangeEntity}
                        />
                    </Col>
                    <Col>
                        <h2>User details</h2>
                        <Section y>
                            <AvatarEdit
                                onChangeImage={this.onChangeAvatar}
                                onCrop={this.onCrop}
                                user={entity}
                                crop={(entity.avatarFile && entity.avatarFile.crop) || {}}
                                file={entity.avatarFile}
                                onFilesRejected={onFilesRejected}
                            />
                        </Section>
                        <Section s>{this.renderInput('email')}</Section>
                        <Section s>{this.renderInput('first_name')}</Section>
                        <Section s>{this.renderInput('last_name')}</Section>
                        <Restricted location="admin">
                            <Row>
                                <Col c="auto">{this.renderInput('active')}</Col>
                            </Row>
                            <Divider m />
                            <Row>
                                <Col c="auto">{this.renderInput('two_factor_enabled')}</Col>
                                <Col c="auto">
                                    <Button
                                        onClick={() =>
                                            call(
                                                `office/users/reset-two-factor-secret/${entity.id}`
                                            ).then(() =>
                                                addToast({
                                                    msg: 'Two-factor secret has been reset',
                                                    icon: 'lock_reset',
                                                })
                                            )
                                        }>
                                        <PreIcon icon="lock_reset">Reset two-factor secret</PreIcon>
                                    </Button>
                                </Col>
                            </Row>
                        </Restricted>
                    </Col>
                </Row>
                <Row n>
                    <Col c="auto">
                        <SafeButton
                            action={sendPasswordMail.bind(this, entity.id)}
                            buttonText="Send password mail"
                            className="btn cta"
                            successMessage="Mail sent!"
                            confirmMessage="Confirm send mail"
                        />
                    </Col>
                    <button
                        onClick={this.save}
                        disabled={!entity._isTouched}
                        className="btn cta to-right">
                        {entity._saving ? 'saving..' : 'Save'}
                    </button>
                </Row>
            </div>
        )
    }
}

AdminUserEntity.propTypes = {}

export default EntityComponentContainer(AdminUserEntity, {
    store: 'adminUsers',
    name: 'adminUsers',
    saveFunc: saveAdminUser,
    mapDispatchToProps: (dispatch) => {
        return {
            onFilesRejected: (files) => dispatch(onFilesRejected(files)),
            addToast: (data = {}) =>
                dispatch({
                    type: 'ADD_TOAST',
                    toast: {
                        green: true,
                        timeout: 3000,
                        closeBtn: true,
                        id: new Date().getTime() + '-' + Math.random().toString(36).substring(2, 7),
                        ...data,
                    },
                }),
        }
    },
})
