import { QuestionStatus } from '@advanza/types/Question'
import { Button, Divider, Icon } from '@advanza/ui'
import CheckButton from 'components/CheckButton'
import FlexTable from 'components/FlexTable/FlexTable'
import AutoRow from 'components/misc/AutoRow'
import OrderButtonArrows from 'components/misc/OrderButtonArrows'
import QuestionEntity from 'components/services/QuestionEntity'
import QuestionsListWarning from 'components/services/QuestionsListWarning'
import ServiceDatePickerModal from 'components/services/ServiceDatePickerModal'
import Balloon from 'components/ui/Balloon'
import FormattedTime from 'components/ui/FormattedTime'
import QuestionsListContainer from 'containers/QuestionsListContainer'
import LoadingDotsTeam from 'misc/LoadingDotsTeam'
import { getWebsiteHostnameByCountryCode } from 'misc/country'
import React from 'react'
import style from './questionsList.module.css'

class QuestionsList extends React.Component {
    constructor(props) {
        super(props)
        this.toggleShowHiddenQuestions = this.toggleShowHiddenQuestions.bind(this)
        this.renderRows = this.renderRows.bind(this)
        this.renderCols = this.renderCols.bind(this)
        this.addQuestion = this.addQuestion.bind(this)
        this.state = {
            showHiddenQuestions: false,
        }
    }

    toggleShowHiddenQuestions(show = null) {
        this.setState({
            showHiddenQuestions: show !== null ? show : this.state.showHiddenQuestions !== true,
        })
    }

    statusMap = {
        0: 'fiber_new',
        1: 'visibility',
        2: 'visibility_off',
        3: 'archived',
    }

    typeMap = {
        1: {
            name: 'checkbox',
            icon: 'check_box_outline_blank',
        },
        2: {
            name: 'radio',
            icon: 'radio_button_unchecked',
        },
        /*         3: {
            name: 'select',
            icon: 'select',
        }, */
        5: {
            name: 'text',
            icon: 'format_size',
        },
        6: {
            name: 'textarea',
            icon: 'notes',
        },
        o: {
            name: 'custom details',
            icon: 'notes',
            deprecated: true, // TRUS-3311
        },
        7: {
            name: 'number',
            icon: 'filter_2',
        },
        /*         9: {
            name: 'date',
            icon: 'date_range',
        }, */
        d: {
            name: 'day picker',
            icon: 'calendar_month',
            required: 'enable_calendar',
        },
        f: {
            name: 'file',
            icon: 'insert_drive_file',
        },
        l: {
            name: 'location',
            icon: 'place',
        },
        m: {
            name: 'month picker',
            icon: 'calendar_month',
            required: 'enable_month_picker',
        },
        p: {
            name: 'postal code, house number, street name',
            icon: 'house',
        },
        s: {
            name: 'Story',
            icon: 'coronavirus',
            deprecated: true, // TRUS-1609
        },
        v: {
            name: 'moment picker',
            icon: 'calendar_month',
            required: 'enable_moment_picker',
        },
    }

    definitions = {
        id: { name: '#' },
        status: { name: 'status' },
        type: { name: 'type' },
        is_availability: { name: <Icon name="event" fontSize={17} /> },
        filterable: { name: <Icon name="star" fontSize={17} /> },
        question: { name: 'question' },
        something: { name: 'conditions' },
        order: { name: 'order', preventRedirect: true },
    }

    renderCols(id) {
        const { serviceEntities } = this.props
        const { prices = {}, questions = {} } = serviceEntities
        const question = questions[id]
        const hasRules = question.question_rules.length > 0

        const optionIds = question?.options || []
        const optionPrices =
            Object.keys(prices).filter((priceId) =>
                optionIds.includes(prices[priceId].option_id)
            ) || []

        return [
            <small>#{id}</small>,
            <>
                <Icon name={this.statusMap[question.status]} />
                {optionPrices.length > 0 && (
                    <>
                        {' '}
                        <Icon name="euro" />
                    </>
                )}
            </>,
            <Icon>{(this.typeMap[question.type] || {}).icon}</Icon>,
            question.is_availability && <Icon name="event" fontSize={17} />,
            question.filterable && <Icon name="star" fontSize={17} />,
            question.question,
            hasRules ? (
                <Balloon
                    content={'Question is not visible unless specific options are selected'}
                    onHover={this.highlightQuestionsSubjectToRules}
                    onLeave={this.highlightQuestionsSubjectToRules}>
                    <Icon color="orange">shuffle</Icon>
                </Balloon>
            ) : (
                '-'
            ),
            <OrderButtonArrows
                clickUp={this.changeOrder.bind(this, id, 'up')}
                clickDown={this.changeOrder.bind(this, id, 'down')}
            />,
        ]
    }

    changeOrder(questionId, upOrDown, e) {
        const { changeQuestionOrder } = this.props
        changeQuestionOrder(questionId, upOrDown)
    }

    addQuestion() {
        const { service, addQuestion } = this.props
        this.toggleShowHiddenQuestions(true)
        addQuestion(service.service_id)
        // setTimeout(() => {
        //     scrollToY(document.body.scrollHeight)
        // }, 300)
    }

    renderRows() {
        const { serviceEntities, service, serviceIsFetching } = this.props
        const { showHiddenQuestions } = this.state
        const questionIds = (service && service.questions) || []
        return questionIds
            .map((id, i) => {
                const question = serviceEntities.questions[id]
                if (question.status !== QuestionStatus.ACTIVE && !showHiddenQuestions) {
                    return null
                }
                return {
                    id,
                    cols: this.renderCols(id),
                    expandOnClick: true,
                    isLoading: question._saving || serviceIsFetching,
                    isSelected: question._isTouched,
                    expanded: question._openToggle,
                    expandable: (
                        <QuestionEntity
                            key={id}
                            typeMap={this.typeMap}
                            entityId={id}
                            index={i}
                            toggleShowHiddenQuestions={this.toggleShowHiddenQuestions}
                            {...this.props}
                        />
                    ),
                }
            })
            .filter(Boolean)
    }

    render() {
        const { serviceEntities } = this.props
        const { prices = {}, options = {}, questions } = serviceEntities

        // as replacing a price question is a manual job
        // we want to warn the user if there are more than 2 price questions active
        const priceQuestionsIds = Object.values(prices)
            .filter((price) => price.option_id && !price.deleted && !price.end_date)
            .reduce((acc, price) => {
                const option = options[price.option_id]
                if (option && acc.indexOf(option.question_id) === -1) {
                    acc.push(option.question_id)
                }
                return acc
            }, [])

        if (!this.props.service) {
            return <LoadingDotsTeam name="pieter" />
        }
        return (
            <>
                {priceQuestionsIds.length > 2 && <QuestionsListWarning />}
                {priceQuestionsIds.length === 2 &&
                    questions[priceQuestionsIds[0]].status === QuestionStatus.ACTIVE &&
                    questions[priceQuestionsIds[1]].status === QuestionStatus.ACTIVE && (
                        <QuestionsListWarning text="Two active price questions" />
                    )}
                <AutoRow>
                    <CheckButton
                        onChange={this.toggleShowHiddenQuestions.bind(this, null)}
                        checked={this.state.showHiddenQuestions}
                        msg="Show hidden"
                    />
                    <button onClick={this.addQuestion} className="plain">
                        <Icon>add</Icon> Add question
                    </button>
                    <a
                        target="_blank"
                        href={`//${getWebsiteHostnameByCountryCode(
                            this.props.countryCode
                        )}/search/${this.props.service.url}/`}>
                        <Button name="borderedPoppins">
                            <Icon name="open_in_new" /> View wizard
                        </Button>
                    </a>
                    <ServiceDatePickerModal
                        className={style.showHiddenFilterLink}
                        serviceId={this.props.service.service_id}
                        entityKey="show_hidden_filters"
                        heading="Display new filters until"
                        trigger={
                            <Button name="link">
                                Display new filters
                                {this.props.service.show_hidden_filters && (
                                    <>
                                        {' until '}
                                        <FormattedTime
                                            date={this.props.service.show_hidden_filters}
                                            format="dd-MM-yyyy"
                                        />
                                    </>
                                )}
                            </Button>
                        }
                    />
                </AutoRow>
                <Divider m />
                <FlexTable
                    definitions={this.definitions}
                    className={style.table}
                    rows={this.renderRows()}
                />
            </>
        )
    }
}

export default QuestionsListContainer(QuestionsList)
