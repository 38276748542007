import { Row } from '@advanza/advanza_generic'
import { Select } from '@advanza/advanza_generic'
import { Col } from '@advanza/grid'
import { Icon } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import EventFilterContainer from '../../containers/EventFilterContainer'
import Pagination from '../Pagination'

class EventFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onCountList = this.onCountList.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.onChangeType = this.onChangeType.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter || filter.filterId.indexOf('p_') !== -1) {
            this._changeFilter(defaultOptions)
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onCountList() {
        this._changeFilter({ countList: true, didInvalidate: true })
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    onChangeType(types) {
        this._changeFilter({ eventNames: types.map((item) => item.value) })
    }

    render() {
        const { filter, count, countOnPage, compact, hideSelect = false } = this.props
        if (!filter || compact) {
            return null
        }

        const pageNr = filter.page || 0
        const showPagination = count
            ? count > filter.pageSize
            : !(filter.current === 0 && countOnPage < filter.pageSize)
        return (
            <Row className="v-center" nm>
                {!hideSelect && (
                    <Col x>
                        <Select
                            multiple
                            className="select-wide"
                            value={filter.eventNames}
                            placeholder="select event"
                            onValueChange={this.onChangeType}
                            options={[
                                { value: 'MiddleManReplyMail', title: 'Middleman reply mail' },
                                { value: 'AfterMatchMailProNew', title: 'After match mail' },
                                { value: 'AfterMatchPremium', title: 'After match mail premium' },
                                { value: 'AfterMatchBasic', title: 'After match mail basic profile' },
                                {
                                    value: 'OnboardingConfirmedMail',
                                    title: 'Onboarding  Confirmed Mail',
                                },
                                { value: 'ReviewInviteInternal', title: 'Review invite Internal' },
                                { value: 'ReviewInviteExternal', title: 'Review invite External' },
                                {
                                    value: 'WidgetInviteWebmaster',
                                    title: 'Review install webmaster',
                                },
                                {
                                    value: 'NewMarketPlaceRequests',
                                    title: 'Market place new requests',
                                },
                                { value: 'DeleteProfileSupport', title: 'Delete profile support' },
                                {
                                    value: 'DeleteProfileVerification',
                                    title: 'Delete profile verification',
                                },
                                { value: 'PremiumCancel', title: 'Premium cancel' },
                                { value: 'DailyLogsMail', title: 'Daily logs mail' },
                                { value: 'RestorePremium', title: 'Restore premium' },
                                { value: 'MonthlyOverviewMail', title: 'Monthly overview mail' },
                                { value: 'PauseAboutToEnd', title: 'End of pause announcement' },
                                { value: 'PauseEnded', title: 'Pause ended' },
                                { value: 'PremiumConfirmation', title: 'Premium confirmation' },
                                { value: 'InvoiceMail', title: 'Invoice' },
                                { value: 'InvoiceReminderMail', title: 'Invoice reminder' },
                            ]}
                        />
                    </Col>
                )}

                {showPagination && (
                    <div className="to-right">
                        <Row>
                            <Col x>
                                <button onClick={() => this._changeFilter({ didInvalidate: true })}>
                                    <Icon name="refresh" />
                                </button>
                            </Col>
                            <Col x>
                                <Pagination
                                    current={pageNr}
                                    pageSize={filter.pageSize}
                                    count={count}
                                    countOnPage={countOnPage}
                                    onCountList={this.onCountList}
                                    onPageChange={this.onPageChange}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Row>
        )
    }
}

EventFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
    count: PropTypes.number,
    countOnPage: PropTypes.number,
}

export default EventFilterContainer(EventFilter)
