import { Col, Row } from '@advanza/advanza_generic'
import React from 'react'
import { useSelector } from 'react-redux'

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const PARTS = ['morning', 'afternoon', 'evening']

const RequestsMoments = ({ momentIds = [] }) => {
    const { requestsMoments = {}, questions = {} } = useSelector((state) => state.requests.entities)
    const questionsIds = [
        ...new Set(momentIds.map((momentId) => requestsMoments[momentId].question_id)),
    ]
    const getReadableName = (dayNr, partNr) => `${DAYS[dayNr - 1]} ${PARTS[partNr - 1]}`

    return (
        <div>
            {questionsIds.map((questionId) => (
                <Row y="min" key={`requests_moments_${questionId}`}>
                    <Col style={{ maxWidth: 150 }}>
                        <div className="col-h">{questions[questionId].question_name_filter}</div>
                    </Col>
                    <Col>
                        <div>
                            {momentIds
                                .map((momentId) => requestsMoments[momentId])
                                .filter((moment) => (moment.question_id = questionId))
                                .sort((a, b) =>
                                    a.day_of_the_week > b.day_of_the_week
                                        ? 1
                                        : a.day_of_the_week < b.day_of_the_week
                                        ? -1
                                        : a.part_of_the_day > b.part_of_the_day
                                        ? 1
                                        : a.part_of_the_day < b.part_of_the_day
                                        ? -1
                                        : 0
                                )
                                .map((moment, index) => (
                                    <div key={index}>
                                        {getReadableName(
                                            moment.day_of_the_week,
                                            moment.part_of_the_day
                                        )}
                                    </div>
                                ))}
                        </div>
                    </Col>
                </Row>
            ))}
        </div>
    )
}

export default RequestsMoments
