import { Col, FieldInput, Row } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import ServicesFilterContainer from '../../containers/ServicesFilterContainer'
import Pagination from '../Pagination'

class ServiceFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this.onQueryChange = this.onQueryChange.bind(this)
        this.updateQuery = this.updateQuery.bind(this)
        this._changeFilter = this._changeFilter.bind(this)

        this.state = {
            query: '',
        }
    }

    _changeFilter(options = {}) {
        const { changeFilter, filterId, countries } = this.props
        changeFilter(filterId, { ...options, countries })
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (prevProps.countries !== this.props.countries) {
            this._changeFilter({ countries: this.props.countries })
        }
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    updateQuery() {
        this._changeFilter({
            query: this.state.query,
            page: 0,
        })
    }

    onQueryChange(e) {
        this.setState({ query: e.target.value }, this.updateQuery)
    }

    render() {
        const { filter, count } = this.props
        const { query } = this.state
        if (!filter) {
            return null
        }
        const pageNr = filter.page || 0
        return (
            <Row className="v-center">
                <Col c="auto">
                    <FieldInput
                        newGStyle
                        icon="search"
                        msgid="lists.filter.search"
                        state={{ value: query || filter.query }}
                        onChange={this.onQueryChange}
                        onKeyPress={(e) => e.key === 'Enter' && this.updateQuery()}
                    />
                </Col>

                {count && (
                    <Pagination
                        current={pageNr}
                        pageSize={filter.pageSize}
                        count={count}
                        onPageChange={this.onPageChange}
                    />
                )}
            </Row>
        )
    }
}

ServiceFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
}

export default ServicesFilterContainer(ServiceFilter)
