import { call } from '@advanza/api'
import AutoGrowField from '@advanza/auto_grow_field'
import { Button, Divider, LoadingDots, PreIcon } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import style from './GoogleIndexingApi.module.css'
import React, { useState } from 'react'

const GoogleIndexingApi = ({}) => {
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const nrUrls = value.split('\n').filter(Boolean).length
    const isValid =
        value
            .split('\n')
            .filter(Boolean)
            .filter((url) => {
                return url.match(/https:\/\/(trustoo\.nl|trustlocal\.(de|be))/)
            }).length === nrUrls && nrUrls <= 200
    const submit = (debug = false) => {
        if (loading || !isValid) {
            return Promise.resolve()
        }
        setLoading(true)
        return call('office/misc/send-google-indexing-api-batch/', {
            payload: {
                urls: value.split('\n'),
                debug
            }
        })
            .then(setResponse, setResponse)
            .finally(() => setLoading(false))
    }
    const ph = `
    Add urls here: max 200 p/day

    e.g.:
    https://trustlocal.de/wurst/
    https://trustlocal.de/wurst/wurst/
    https://trustlocal.de/wurst/wurst/wurst/
    `
    return (
        <div className={style.root}>
            <h5>Indexing API</h5>
            <Divider m />
            <div className={isValid ? style.valid : style.invalid}>
                {nrUrls}/200 {isValid ? '' : 'INVALID'}
            </div>
            <AutoRow>
                <AutoGrowField
                    maxRows={200}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    placeholder={ph}
                />
                {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
            </AutoRow>
            <Divider />
            <AutoRow>
                <Button larger onClick={() => submit()} disabled={loading}>
                    {loading ? <LoadingDots color='#fff' /> : 'Submit'}
                </Button>

                <Button
                    larger
                    onClick={() => submit(true)}
                    name='borderedPoppins'
                    disabled={loading}>
                    {loading ? <LoadingDots color='#fff' /> : <PreIcon icon=''>Debug</PreIcon>}
                </Button>
            </AutoRow>
        </div>
    )
}

export default GoogleIndexingApi
