import { Col, Row } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import { Divider } from '@advanza/ui'
import CheckButton from 'components/CheckButton'
import ScoreBlock from 'components/serviceInfo/ScoreBlock'
import PropTypes from 'prop-types'
import React from 'react'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'
import LinearLoader from '../LinearLoader'

class ServiceMatchStats extends React.Component {
    constructor(props) {
        super(props)
        this._changeOptions = this._changeOptions.bind(this)
        this.onChangeDays = this.onChangeDays.bind(this)
    }

    componentDidMount() {
        const { fetchService, fetchStats, serviceId, changeOptions } = this.props
        changeOptions(serviceId, 'matchStats', { historyFactor: serviceId ? 2 : null })
        fetchStats(serviceId, 'matchStats')
        if (serviceId) {
            fetchService(serviceId)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.serviceId !== this.props.serviceId) {
            const { fetchService, fetchStats } = this.props
            fetchStats(this.props.serviceId, 'matchStats')
            fetchService(this.props.serviceId)
        }
    }

    _changeOptions(options) {
        const { serviceId, changeOptions, fetchStats } = this.props
        changeOptions(serviceId, 'matchStats', { ...options, didInvalidate: true })
        fetchStats(serviceId, 'matchStats')
    }

    onChangeDays(e) {
        this._changeOptions({ days: e.target.value })
    }

    render() {
        const { getStatsAndOptions } = this.props
        const { stats, options } = getStatsAndOptions('matchStats')
        if (!stats) {
            return null
        }
        const { matchesPerRequest, checkPointPercentages, history } = stats

        let checkPointStatsRows = Object.keys(checkPointPercentages).map((key) => {
            return [key, Math.round(parseFloat(checkPointPercentages[key] || 0))]
        })
        if (history.length > 0) {
            history.forEach(({ checkPointPercentages }) => {
                Object.keys(checkPointPercentages).map((key, i) => {
                    checkPointStatsRows[i] = [
                        ...checkPointStatsRows[i],
                        Math.round(parseFloat(checkPointPercentages[key] || 0)),
                    ]
                })
            })
        }
        return (
            <div>
                {options.isFetching && <LinearLoader />}
                <Divider />
                <Row middle="xs">
                    <Col x>
                        <h3>Matches</h3>
                    </Col>
                    <Col x>
                        <SelectSimple
                            value={options.days || 30}
                            onChange={this.onChangeDays}
                            className="select-stealth select-min select-wide"
                            options={[
                                { value: 1, name: '1 days' },
                                { value: 7, name: '7 days' },
                                { value: 10, name: '10 days' },
                                { value: 30, name: '30 days' },
                                { value: 90, name: '3 months' },
                                { value: 180, name: '6 months' },
                                { value: 365, name: '1 year' },
                            ]}
                        />
                    </Col>
                    <Col>
                        <CheckButton
                            checked={options.subscribed}
                            msg="Premium"
                            onChange={() =>
                                this._changeOptions({ subscribed: options.subscribed ? '' : '1' })
                            }
                        />
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col x>
                        <ScoreBlock
                            value={matchesPerRequest.avgMatchPerRequest}
                            prevValue={
                                history[0] && history[0].matchesPerRequest.avgMatchPerRequest
                            }
                            name="Average matches p/request"
                        />
                    </Col>
                    <Col x>
                        <ScoreBlock
                            value={matchesPerRequest.nrMatches}
                            prevValue={history[0] && history[0].matchesPerRequest.nrMatches}
                            name="Total nr of matches"
                        />
                    </Col>
                </Row>
                <Row>
                    {checkPointStatsRows.map(([name, val]) => {
                        return (
                            <Col xs key={name}>
                                <ScoreBlock
                                    loading={options.isFetching}
                                    name={name}
                                    prevValue={history[0] && history[0].checkPointPercentages[name]}
                                    value={val}
                                    isPercentage
                                />
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }
}

ServiceMatchStats.propTypes = {
    serviceId: PropTypes.any.isRequired,
    changeOptions: PropTypes.any,
    fetchRequestStats: PropTypes.any,
    fetchService: PropTypes.any,
    requestStats: PropTypes.any,
    requestStatsOptions: PropTypes.any,
    service: PropTypes.any,
}

export default ServiceStatsContainer(ServiceMatchStats)
