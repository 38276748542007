import { call } from '@advanza/api'
import { InputField } from '@advanza/input'
import { Icon, LoadingDots } from '@advanza/ui'
import ListKeyboardNav from 'components/misc/ListKeyboardNav'
import { getActiveStatus } from 'components/providers/_providerActiveStatus'
import { useOutsideClick } from 'hooks/miscHooks'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import style from './searchPro.module.css'
import ProSearchResult from 'components/misc/ProSearchResult'

function useSearch({ serviceId }) {
    const [query, setQuery] = useState('')
    const [isLoading, setIsLoading] = useState('')
    const [results, setResults] = useState([])
    const [enableSearchOnChange, setEnableSearchOnChange] = useState(false)

    const clear = () => {
        setResults([])
        setEnableSearchOnChange(false)
    }
    const onSearch = (e = false) => {
        if (query.length < 2) {
            clear()
            return Promise.resolve()
        }
        if (e !== false && e.key !== 'Enter' && !enableSearchOnChange) {
            return Promise.resolve()
        }
        setIsLoading(true)
        call('office/providers/search/' + query, {
            query: {
                serviceId,
            },
        }).then(({ results }) => {
            setResults(results)
            setIsLoading(false)
            setEnableSearchOnChange(true)
        })
    }
    const onChange = (e) => {
        setQuery(e.target.value)
        if (e.target.value.length < 2) {
            clear()
        }
    }

    return {
        query,
        onChange,
        results,
        onSearch,
        isLoading,
        clear,
    }
}

const SearchPro = ({ onSelect = null, serviceId = null }) => {
    const { query, onChange, clear, isLoading, onSearch, results } = useSearch({ serviceId })
    const ref = useRef(null)
    const inputRef = useRef(null)
    useOutsideClick(ref, clear)
    const onClick = (e, pro) => {
        if (onSelect) {
            onSelect(pro)
            e.preventDefault()
        }
        clear()
    }

    return (
        <div ref={ref} className={[style.root, results.length ? style.hasResults : ''].join(' ')}>
            <InputField
                value={query}
                ref={inputRef}
                onFocus={() => query.length > 2 && onSearch}
                inputPlaceholder="Enter query & press enter"
                before={isLoading ? <LoadingDots color="grey" /> : <Icon name="search" grey />}
                onChange={onChange}
                onKeyDown={onSearch}
            />
            {(results.length > 0 || isLoading) && (
                <div className={[style.results, isLoading ? style.loading : ''].join(' ')}>
                    <ListKeyboardNav onOtherKey={() => inputRef.current.doFocus()}>
                        {results.map((result, i) => {
                            const status = getActiveStatus(result)
                            return (
                                <Link
                                    to={`/service/providers/provider/${result.service_provider_id}/company`}
                                    key={i}
                                    onClick={(e) => onClick(e, result)}
                                    className={style.result}>
                                    <ProSearchResult
                                        status={status}
                                        providerId={result.service_provider_id}
                                        businessName={result.business_name}
                                        city={result.city}
                                        avatarFileName={result.avatarFileName}
                                        userId={result.userId}
                                    />
                                </Link>
                            )
                        })}
                    </ListKeyboardNav>
                </div>
            )}
        </div>
    )
}

export default SearchPro
