import { schema } from 'normalizr'

const user = new schema.Entity('users', {}, { idAttribute: 'id' })

const pauseReason = new schema.Entity('pauseReasons', {}, { idAttribute: 'pause_reason_id' })

const provider = new schema.Entity(
    'providers',
    {
        user,
    },
    {
        idAttribute: 'service_provider_id',
    }
)

const providersDatesSchema = new schema.Entity(
    'providersDates',
    {
        provider: provider,
        pause_reason: pauseReason,
        user: user,
    },
    {
        idAttribute: 'provider_date_id',
    }
)

export default [providersDatesSchema]
