import PropTypes from 'prop-types'
import React from 'react'
import Page from '../ui/Page'
import EventView from './EventView'

const EventViewPage = ({ match }) => {
    const { id } = match.params
    return (
        <Page title="Events">
            <EventView eventId={id} />
        </Page>
    )
}

EventViewPage.propTypes = {
    eventId: PropTypes.any,
}

export default EventViewPage
