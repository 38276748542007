import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    SALES_LEADS_CHANGE_FILTER,
    SALES_LEADS_RECEIVE_LIST,
    SALES_LEADS_RECEIVE_SINGLE,
    SALES_LEADS_REQUEST,
} from '../actions/salesLeads'

import { changeEntity, changeFilter, receiveList, selectItems } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [SALES_LEADS_RECEIVE_LIST]: receiveList,
    [SALES_LEADS_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_SALESLEADS: changeEntity,
    SALESLEADS_SELECT_ITEMS: selectItems,
    [SALES_LEADS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: action.id || true },
        }),
    [SALES_LEADS_RECEIVE_SINGLE]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            entities: { $deepMerge: action.entities },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
