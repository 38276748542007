import { getRangeFromObject } from 'components/misc/misc'
import MunicipalitiesMap from 'components/serviceInfo/MunicipalityMap'
import React from 'react'
import { useSelector } from 'react-redux'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'

const ServiceRequestsStatsGeoGraph = ({ getStatsAndOptions }) => {
    const { stats, counts = {} } = getStatsAndOptions('requestStats')
    const country = useSelector((state) => state.countries.countries[0])
    const { municipalityCounts } = counts
    const { range, min } = getRangeFromObject(municipalityCounts)
    const getHoverContent = ({ id, municipality }) => {
        if (!municipalityCounts[id]) {
            return `<div>${municipality.name}</div>`
        }
        return `
        <div>${municipality.name}</div>
        ${municipalityCounts[id]} (${((municipalityCounts[id] / stats.nrReq) * 100).toFixed(2)}%)
        `
    }
    const getHeatColor = ({ id }) => {
        if (!municipalityCounts[id]) {
            return {
                fillColor: 'rgba(0,0,0,0)',
            }
        }
        const amount = municipalityCounts[id] || 1
        const total = stats.nrReq || 1
        const percentage = ((amount / (range + min)) * 100).toFixed(2)
        const fillColor = `hsl(${80 - percentage},89%,46%)`
        return {
            fillColor,
        }
    }

    return (
        <div>
            <MunicipalitiesMap
                countryCode={country}
                useHeatMap
                height={country === 'NL' ? 600 : 375}
                width={country === 'NL' ? 500 : 600}
                key="requests"
                getHoverContent={getHoverContent}
                getHeatColorVars={getHeatColor}
            />
        </div>
    )
}

export default ServiceStatsContainer(ServiceRequestsStatsGeoGraph)
