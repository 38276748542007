import { APP } from '@advanza/advanza_generic'
import TwoFactorOneTimeCode from 'components/login/TwoFactorOneTimeCode'
import TwoFactorSecret from 'components/login/TwoFactorSecret'
import PropTypes from 'prop-types'
import React from 'react'
import AfterEmailConfirmed from './AfterEmailConfirmed'
import EmailChanged from './ChangeEmail'
import ChangePassword from './ChangePassword'
import Login from './Login'
import Logout from './Logout'
import RequestChangeEmail from './RequestChangeEmail'
import ResetPassword from './ResetPassword'

class LoginPage extends React.Component {
    renderBody() {
        const { page, basename, defaultRedirect } = this.props
        const DASHBOARD_BASE_NAME = basename || 'xxx'

        if (!page || page === 'login') {
            return (
                <Login
                    defaultRedirect={defaultRedirect}
                    basename={DASHBOARD_BASE_NAME}
                    {...this.props}
                />
            )
        }

        if (page === 'twoFactorSecret') {
            return <TwoFactorSecret {...this.props} />
        }

        if (page === 'twoFactorOneTimeCode') {
            return <TwoFactorOneTimeCode {...this.props} />
        }

        if (page === 'passwordReset') {
            return <ResetPassword {...this.props} />
        }

        if (page === 'afterEmailConfirmed') {
            return <AfterEmailConfirmed {...this.props} />
        }

        if (page === 'changePassword') {
            return <ChangePassword {...this.props} />
        }

        if (page === 'emailChanged') {
            return <EmailChanged {...this.props} />
        }

        if (page === 'requestChangeEmail') {
            return <RequestChangeEmail {...this.props} />
        }

        if (page === 'logout') {
            return <Logout {...this.props} />
        }
    }

    render() {
        const { bgUrl } = this.props
        const S3_PUBLIC = APP.S3_PUBLIC()
        const url = bgUrl || `url(${S3_PUBLIC}loginbg2.jpg) no-repeat 33% center /cover`
        const bgStyle = {
            background: url,
            overFlow: 'hidden',
            height: '100%',
        }
        return (
            <div style={bgStyle}>
                <div
                    style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
                    className="login-page-wrapper">
                    <div className="login-page-inner">{this.renderBody.bind(this)()}</div>
                </div>
            </div>
        )
    }
}

LoginPage.propTypes = {
    // the background image url for this page.
    bgUrl: PropTypes.string,

    // the basename url of the app. e.g: `pro` for the pro-app
    basename: PropTypes.string,

    // returns the redirect location after login, param is user
    defaultRedirect: PropTypes.func,
    appName: PropTypes.oneOf(['pro', 'demand', 'office']),
    // Also, all the props for this component are passed down to all the auth components:
    // override the success action
    emailConfirmedSuccessAction: PropTypes.func, // Or:
    // a function that returns the redirect location.
    emailConfirmedSuccessRedirect: PropTypes.func, // afterSuccessRedirect({...response})
}

export default LoginPage
