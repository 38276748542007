import { call } from '@advanza/api'
import { normalize, schema } from 'normalizr'
import adminUsersSchema from '../schemes/adminUsersSchema'
import { changeEntity } from './entities'
import { EVENTS_CHANGE_FILTER, fetchEvents } from './events'
import { _changeFilter, _fetchItems } from './lists'

export const ADMINUSERS_RECEIVE_LIST = 'ADMINUSERS_RECEIVE_LIST'
export const ADMINUSERS_CHANGE_FILTER = 'ADMINUSERS_CHANGE_FILTER'
export const ADMINUSERS_REQUEST = 'ADMINUSERS_REQUEST'
export const ADMINUSERS_INVALIDATE = 'ADMINUSERS_INVALIDATE'
export const RECEIVE_PERMISSIONS = 'RECEIVE_PERMISSIONS'

export function fetchAdminUsers(filterId) {
    return _fetchItems(filterId, {
        url: 'office/admin-users/get-list',
        schema: adminUsersSchema,
        requestActionName: ADMINUSERS_REQUEST,
        receiveActionName: ADMINUSERS_RECEIVE_LIST,
        reducerKey: 'adminUsers',
    })
}

export function fetchPermissions() {
    return function (dispatch, getState) {
        if (getState().adminUsers.permissions.length > 0) {
            return Promise.resolve()
        }
        return call('office/admin-users/get-permissions').then((response) => {
            const { permissions } = response
            dispatch({ type: RECEIVE_PERMISSIONS, permissions })
        })
    }
}

export function changeAdminUsersFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'adminUsers',
        fetchFunc: fetchAdminUsers,
        actionType: ADMINUSERS_CHANGE_FILTER,
    })
}

export function saveAdminUser(userId) {
    return function (dispatch, getState) {
        const adminUser = getState().adminUsers.entities.adminUsers[userId]
        const isNew = typeof userId === 'string'
        return call(`office/admin-users/save/${isNew ? '' : userId}`, {
            json: { ...adminUser },
        }).then((response) => {
            if (isNew) {
                dispatch({ type: 'INVALIDATE_ADMINUSERS' })
            }
        })
    }
}

export function sendPasswordMail(id) {
    return function () {
        return call(`office/admin-users/send-password-mail/${id}`, { method: 'post' })
    }
}

export function addAdminUser() {
    return function (dispatch) {
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                store: 'adminUsers',
                name: 'adminUsers',
                key: newId,
                diff: {
                    permissions: [],
                    active: true,
                },
            })
        )

        return Promise.resolve(newId)
    }
}
