export function getRequestStatusInfo(status) {
    const statusColors = {
        default: '#5788ff',
        green: '#1aa225',
        orange: '#f1612b',
        red: '#f00',
    }
    const statusInfoMap = {
        SEND_CONFIRMATION: {
            label: 'Waiting for the request confirmation',
            color: 'orange',
            icon: 'schedule',
        },
        SEND_CONFIRM_VERIFY: {
            label: 'Waiting for the request confirmation (with phone verification)',
            color: 'orange',
            icon: 'schedule',
        },
        AWAIT_EMAIL: {
            label: 'Waiting for the request confirmation',
            color: 'orange',
            icon: 'schedule',
        },
        MAIL_CONFIRMED: {
            label: 'Waiting for request validation',
            color: 'orange',
            icon: 'schedule',
        },
        BROCHURE_CONFIRMED: {
            label: 'Waiting for request validation',
            color: 'orange',
            icon: 'schedule',
        },
        VALIDATED: {
            label: 'Waiting for the after match mails',
            color: 'orange',
            icon: 'schedule',
        },
        INVALID: {
            label: 'Invalid request',
            color: 'red',
            icon: 'priority_high',
        },
        SENT: {
            label: 'Ongoing request',
            color: 'default',
            icon: 'pending',
        },
        SENT_PREMIUM: {
            label: 'Ongoing request (within 2h premium advantage; free matches will be queued)',
            color: 'default',
            icon: 'schedule',
        },
        SENT_PREMIUM_NIGHTLY: {
            label: "Ongoing request (within nightly premium advantage; free matches will be queued)",
            color: 'default',
            icon: 'schedule',
        },
        DECIDING: {
            label: 'Ongoing request (demand is still deciding)',
            color: 'default',
            icon: 'rule',
        },
        ON_HOLD: {
            label: 'Demand has put the request on hold',
            color: 'orange',
            icon: 'pause',
        },
        CHOSEN: {
            label: 'Demand has chosen a provider',
            color: 'green',
            icon: 'check_box',
        },
        DONE: {
            label: 'Demand made a choice outside of the platform',
            color: 'green',
            icon: 'indeterminate_check_box',
        },
        CANCELED: {
            label: 'Demand canceled the request',
            color: 'red',
            icon: 'undo',
        },
        UNSUBSCRIBED: {
            label: 'Demand unsubscribed',
            color: 'red',
            icon: 'block',
        },
        DELETED: {
            label: 'Deleted request',
            color: 'red',
            icon: 'deleted',
        },
        FALL_BACK: {
            /*error or outdated status (OK, EMPTY, INCOMPLETE, CANCELLED) */
            label: 'Unknown status',
            color: 'red',
            icon: 'contact_support',
        },
    }
    const statusArray = Object.keys(statusInfoMap)
    const statusInfo = (status && statusInfoMap[status]) || statusInfoMap.FALL_BACK
    const hexColor = () => {
        const color = (statusInfo && statusInfo.color) || 'red'
        return statusColors[color]
    }

    return { statusArray, statusInfo, hexColor }
}
