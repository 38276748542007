import { call } from '@advanza/api'
import { changeEntity } from 'actions/entities'
import { deleteFile, saveFile } from 'actions/files'
import { IMAGE_FILE_CONTEXTS } from 'hooks/profileBuilderHooks'
import plimit from 'p-limit'

export const aGetProvider = (providerId, getState) => {
    const { providers = {} } = getState().providers.entities
    return providers[providerId] || {}
}

export const aGetSelection = (provider, pageKey) => provider?.wizardSelection?.[pageKey] || {}

export const acChangeSelection = (providerId, pageKey, newSelection) => ({
    type: 'PROFILE_BUILDER_WIZARD_CHANGE_SELECTION',
    providerId,
    pageKey,
    newSelection,
})

export const autoSavePreselectedDescription =
    (providerId, preselectedDescription, descriptionPreselectMethod) => (dispatch, getState) => {
        const provider = aGetProvider(providerId, getState)

        if (preselectedDescription && descriptionPreselectMethod === 'ai') {
            if (!(provider.description || '').trim()) {
                dispatch(
                    changeEntity({
                        store: 'providers',
                        name: 'providers',
                        key: providerId,
                        diff: { description: preselectedDescription },
                    })
                )
                dispatch(
                    acChangeSelection(providerId, 'description', {
                        labels: ['no_text_on_website'],
                        choice: '',
                        other: preselectedDescription,
                    })
                )
                dispatch(acSaveProfileBuilderWizard(providerId, 'description', false))
                return true
            } else {
                return provider.description === preselectedDescription
            }
        } else {
            return false
        }
    }

export const acSaveProfileBuilderWizard =
    (providerId, pageKey, saveTiming = true, extraData = {}) =>
    (_dispatch, getState) =>
        call(
            `office/providers-builders/save-wizard/${providerId}/${pageKey}/${saveTiming ? 1 : 0}`,
            {
                json: {
                    ...extraData,
                    selection: aGetSelection(aGetProvider(providerId, getState), pageKey),
                },
            }
        )

export const getFromFilesState = (providerId, filesState = {}) => {
    const { entities: { files = {} } = {}, result = {}, filters = {} } = filesState
    const filterId = `p_${providerId}`
    const filter = filters[filterId]
    const searchKey = filter && filter.searchKey
    const pages = result[searchKey] || {}
    const pageNr = (filter && filter.page) || 0
    const fileIds = pages[pageNr] || []
    const imageFiles = fileIds
        .map((fileId) => files[fileId])
        .filter((file) => file && file.type === 'image')
        .sort((imageFileA, imageFileB) => imageFileB.weight - imageFileA.weight)

    return { filterId, filter, searchKey, fileIds, imageFiles }
}

export const saveNonAvatarImages = (providerId) => (dispatch, getState) => {
    // Saving many files at the same time can crash the server because of the high memory usage of modifying images
    const limit = plimit(4)
    const { filterId, imageFiles = [] } = getFromFilesState(providerId, getState().files)

    return Promise.all(
        imageFiles.map(({ file_id: fileId, context, _isTouched }) =>
            limit(() =>
                !IMAGE_FILE_CONTEXTS.includes(context)
                    ? dispatch(deleteFile(fileId))
                    : typeof fileId === 'string' || _isTouched
                    ? dispatch(saveFile(fileId, {}, false, providerId)).then((response) => {
                          if (typeof fileId === 'string') {
                              dispatch(
                                  changeEntity({
                                      store: 'files',
                                      name: 'files',
                                      key: fileId,
                                      newKey: response.id,
                                      diff: { file_id: response.id, _isTouched: false },
                                  })
                              )

                              dispatch({
                                  type: 'FILES_SWAP_ID_IN_PAGE',
                                  filterId,
                                  oldId: fileId,
                                  newId: response.id,
                              })
                          }
                      })
                    : Promise.resolve()
            )
        )
    )
}
