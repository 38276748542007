import { schema } from 'normalizr'

export default [
    new schema.Entity(
        'regions',
        {
            regions_content: new schema.Entity(
                'regionContent',
                {},
                { idAttribute: 'region_content_id' }
            ),
        },
        { idAttribute: 'region_id' }
    ),
]
