import AutoGrowField from '@advanza/auto_grow_field'
import { decodeURISafe } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, Loading } from '@advanza/ui'
import { saveProvider } from 'actions/providers'
import A from 'components/ui/A'
import { useDescription } from 'hooks/descriptionHooks'
import { useChangeEntity } from 'hooks/entityHooks'
import React, { useState } from 'react'
import style from './descriptionSelectionTool.module.css'

const DescriptionSelectionTool = ({ providerId, inWizard = false }) => {
    const [open, setOpenModal] = useState(false)
    const {
        entity: provider,
        onChangeEntity,
        onSaveEntity,
    } = useChangeEntity(
        {
            store: 'providers',
            name: 'providers',
            entityId: providerId,
            saveFunc: saveProvider,
        },
        {}
    )
    const {
        isFetchingDescriptions,
        hasFetchedDescriptions,
        fetchDescriptions,
        sortedDescriptions,
    } = useDescription(provider)
    const noResults = hasFetchedDescriptions && sortedDescriptions.length === 0

    const onSelectSnippet = (snippet, remove = false) => {
        const newDescription = remove
            ? provider.description.replace(snippet, '').replace('\n\n\n\n', '\n\n').trim()
            : provider.description.length === 0
            ? snippet
            : provider.description + '\n\n' + snippet
        onChangeEntity({ description: newDescription })

        if (!inWizard) {
            onSaveEntity()
        }
    }

    const websiteLink =
        provider.website.indexOf('//') !== -1 ? provider.website : `http://${provider.website}`

    const inner = (
        <div className={style.root}>
            {isFetchingDescriptions && <Loading />}
            {sortedDescriptions.map(({ text_snippet, response_url, priority, rulesFlagged }, i) => {
                const isSelected = provider.description.indexOf(text_snippet) !== -1
                return (
                    <section
                        key={i}
                        className={[style.snippet, isSelected ? style.isSelected : ''].join(' ')}
                        data-priority={priority}
                        onClick={() => onSelectSnippet(text_snippet, isSelected)}>
                        <div key={i}>
                            <div className={style.snippetMeta}>
                                <A
                                    external
                                    href={response_url}
                                    target="_blank"
                                    onClick={(e) => e.stopPropagation()}>
                                    <small>{decodeURISafe(response_url)}</small>
                                </A>
                                {rulesFlagged && (
                                    <span className={style.snippetMetaExtra}>
                                        {rulesFlagged.map((rule) => (
                                            <small key={rule}>
                                                <b>{rule}</b>
                                            </small>
                                        ))}
                                    </span>
                                )}
                            </div>
                            <hr></hr>
                            <div>{text_snippet}</div>
                        </div>
                    </section>
                )
            })}
            {noResults && (
                <div>
                    <p>
                        <Icon name="sentiment_dissatisfied" /> 0 snippets gevonden
                    </p>
                </div>
            )}
            {!inWizard && noResults && (
                <div>
                    <p>
                        <A external href={websiteLink} target="_blank">
                            <Button onClick={() => setOpenModal(false)}>
                                <Icon className="colorWhite" name="open_in_new" /> ga naar website
                            </Button>
                        </A>
                    </p>
                </div>
            )}
        </div>
    )

    return (
        <div className={inWizard ? style.inWizard : undefined}>
            <div className={style.editField}>
                {inWizard ? (
                    <InputField
                        onChange={(e) => onChangeEntity({ description: e.target.value })}
                        onBlur={(e) => onChangeEntity({ description: e.target.value.trim() })}
                        value={provider.description}
                        textArea
                    />
                ) : (
                    <AutoGrowField
                        onChange={(e) => onChangeEntity({ description: e.target.value })}
                        value={provider.description}
                        maxRows={inWizard ? 9999 : 10}
                    />
                )}
            </div>
            <Divider m />
            {inWizard ? (
                inner
            ) : (
                <>
                    <Row between="xs" middle="xs">
                        <Col x>
                            <button
                                onClick={() => {
                                    fetchDescriptions()
                                    setOpenModal(true)
                                }}>
                                <Icon name="search" /> search snippets
                            </button>
                        </Col>
                        <Col x>
                            <Button
                                name="text"
                                disabled={provider._saving || !provider._isTouched}
                                onClick={onSaveEntity}>
                                {provider._saving ? '...busy' : 'Save'}
                            </Button>
                        </Col>
                    </Row>
                    <Modal
                        open={open}
                        close={() => setOpenModal(false)}
                        closeOnOutsideClick
                        maxWidth={740}>
                        {inner}
                    </Modal>
                </>
            )}
        </div>
    )
}

DescriptionSelectionTool.propTypes = {}

export default DescriptionSelectionTool
