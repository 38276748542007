import React from 'react'
import Page from '../ui/Page'
import ServiceView from './ServiceView'

const ServiceViewPage = ({ match }) => {
    const { id } = match.params
    return (
        <Page title="services">
            <ServiceView serviceId={id} />
        </Page>
    )
}

ServiceViewPage.propTypes = {}

export default ServiceViewPage
