import { Row, Section } from '@advanza/advanza_generic'
import React from 'react'
import Page from '../ui/Page'
import SalesLeadFilter from './SalesLeadFilter'
import SalesLeadList from './SalesLeadList'

const SalesLeadsPage = ({}) => {
    return (
        <Page title="SalesLeads">
            <div className="container">
                <Section y x="mega">
                    <Row y nm>
                        <Section e="plus">
                            <h1>Sales leads</h1>
                        </Section>
                        <SalesLeadFilter filterId="all" />
                    </Row>
                    <SalesLeadList selectable filterId="all" />
                </Section>
            </div>
        </Page>
    )
}

SalesLeadsPage.propTypes = {}

export default SalesLeadsPage
