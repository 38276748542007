import { useList } from 'hooks/listHooks'
import faqTopicsSchema from 'schemes/faqTopicsSchema'

export function useFaqTopicsList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/faq-topics/get-list',
            schema: faqTopicsSchema,
            reducerKey: 'faqTopics',
        },
        defaultOptions
    )
}
