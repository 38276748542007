import { Row, Col } from '@advanza/grid'
import { Divider, Button, Icon, PreIcon } from '@advanza/ui'
import React, { Fragment } from 'react'
import TestimonialImage from 'components/testimonials/TestimonialImage'
import { useChangeEntity } from 'hooks/entityHooks'
import { saveTestimonial, deleteTestimonial } from 'hooks/testimonialsHooks'

const fields = {
    name: {
        type: 'text',
        placeholder: 'Full name',
        validator: (val) => val,
        defaultErrorMsg: 'Vul een naam in',
    },
    title: {
        type: 'text',
        placeholder: 'Company',
        validator: (val) => val,
        defaultErrorMsg: 'Vul een bedrijfsnaam in',
    },
    body: {
        type: 'text',
        placeholder: 'Quote',
        validator: (val) => val,
        defaultErrorMsg: 'Vul een quote in',
    },
}

const TestimonialEntity = ({ id }) => {
    const { entity, onSaveEntity, renderInput, onDeleteEntity } = useChangeEntity(
        {
            store: 'testimonials',
            name: 'testimonials',
            entityId: id,
            saveFunc: saveTestimonial,
            deleteFunc: deleteTestimonial,
        },
        fields
    )

    const getErrorMsg = () => {
        const { error, maxSize = '' } = entity._errors
        if (error === 'filetype not supported') {
            return 'Dit bestandstype wordt niet ondersteund.'
        } else if (error === 'too large') {
            return `De afbeelding is te groot (max: ${maxSize}).`
        } else {
            return `Error: ${error}`
        }
    }

    return (
        <div className={entity._saving ? 'busy' : ''}>
            <Divider m />
            <Row>
                <Col xs={3}>
                    <TestimonialImage id={id} />
                    {entity._errors && entity._errors.error && (
                        <Fragment>
                            <Divider s />
                            <PreIcon icon="error" red>
                                <span style={{ color: '#f00' }}>{getErrorMsg()}</span>
                            </PreIcon>
                        </Fragment>
                    )}
                </Col>
                <Col xs={9}>
                    {renderInput('name')}
                    <Divider m />
                    {renderInput('title')}
                    <Divider m />
                    {renderInput('body')}
                </Col>
            </Row>
            <Divider m />
            <Row end="xs" middle="xs">
                <Col x>
                    <button onClick={onDeleteEntity}>
                        <Icon name={entity._saving ? 'hourglass_empty' : 'delete_forever'} />
                    </button>
                </Col>
                <Col x>
                    <Button
                        type="text"
                        onClick={onSaveEntity}
                        disabled={!entity._isTouched || entity._saving}>
                        {entity._saving ? '..saving' : 'Save'}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

TestimonialEntity.propTypes = {}

export default TestimonialEntity
