import React from 'react'
import { connect } from 'react-redux'
import { fetchStatuses } from '../actions/statuses'

function mapStateToProps(state, props) {
    const { entities, isFetching } = state.statuses

    const { statuses = {} } = entities
    const categories = Object.keys(statuses).map((id) => statuses[id].category)
    const uniqueCategories = Array.from(new Set(categories))

    return {
        statuses,
        statusIds: Object.keys(statuses),
        categories: uniqueCategories,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchStatuses: () => dispatch(fetchStatuses()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
