import { schema } from 'normalizr'

const notesSchema = new schema.Entity(
    'notes',
    {},
    {
        idAttribute: 'note_id',
    }
)

export default [notesSchema]
