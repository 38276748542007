import { PreIcon, LoadingDots } from '@advanza/ui'
import React from 'react'
import AdminUserContainer from '../../containers/AdminUserContainer'
import Avatar from './Avatar'

class AdminUser extends React.Component {
    componentDidMount() {
        const { fetchAdminUsers } = this.props
        fetchAdminUsers()
    }

    render() {
        const { user = {}, isFetching, avatarOnly, tiny, ...rest } = this.props
        if (tiny) {
            return (
                <PreIcon icon={<Avatar user={user} sizePx={30} />}>
                    {isFetching ? <LoadingDots /> : user.first_name}
                </PreIcon>
            )
        }
        if (avatarOnly) {
            return <Avatar {...rest} user={user} />
        }
        return <span>{isFetching ? <LoadingDots /> : user.first_name}</span>
    }
}

export default AdminUserContainer(AdminUser)
