import { call } from '@advanza/api'
import { InputField } from '@advanza/input'
import { Button, Divider, LinearLoader, PreIcon } from '@advanza/ui'
import { afterLoginShared } from 'components/login/Login'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

const TwoFactorOneTimeCode = (props) => {
    const [code, setCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [redirect, setRedirect] = useState('')
    const [error, setError] = useState('')

    const checkTwoFactorCode = () => {
        if (isLoading) {
            return Promise.resolve()
        }

        setIsLoading(true)
        return call('auth/check-two-factor-code', { json: { code } })
            .then(
                ({ redirect }) =>
                    redirect
                        ? setRedirect(redirect)
                        : call('auth/login', { method: 'post' }).then(
                              (response) => setRedirect(afterLoginShared(response, props)),
                              ({ error }) => setError(error)
                          ),
                ({ error }) => setError(error)
            )
            .finally(() => setIsLoading(false))
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            {isLoading && <LinearLoader fixed />}
            <h1>Two-factor authentication</h1>
            <Divider s />
            <h2>One-time code</h2>
            <Divider m />
            <Divider m />
            Generate and enter code from Google Authenticator (or similar app like FreeOTP):
            <Divider m />
            <InputField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && code && !isLoading && checkTwoFactorCode()}
            />
            <Divider m />
            <Button disabled={!code || isLoading} onClick={() => checkTwoFactorCode()}>
                Submit
            </Button>
            {error && (
                <>
                    <Divider m />
                    <PreIcon icon="warning" style={{ color: 'red' }} outerStyle={{ color: 'red' }}>
                        {error}
                    </PreIcon>
                </>
            )}
        </>
    )
}

export default TwoFactorOneTimeCode
