import { Icon, Section } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import { Redirect } from 'react-router-dom'

class BackButton extends React.Component {
    constructor(props) {
        super(props)
        this.goToPreviousPage = this.goToPreviousPage.bind(this)
        this.mustUseFallback = this.mustUseFallback.bind(this)
        this.state = { redirect: false }
        this.previousLocation = window.previousRoute
        this.currentLocation = window.location.pathname
    }

    mustUseFallback(url) {
        const forbiddenUrls = ['login', 'logout']
        if (this.currentLocation === this.previousLocation) {
            return true
        }

        return (
            forbiddenUrls.filter((forbidden) => this.previousLocation.indexOf(forbidden) !== -1)
                .length > 0
        )
    }

    goToPreviousPage() {
        const { fallBack } = this.props

        if (this.mustUseFallback()) {
            return this.setState({ redirect: fallBack || '/' })
        } else {
            window.history.go(-1)
        }
    }

    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect to={redirect} push={true} />
        }
        return (
            <button className="back-button" onClick={this.goToPreviousPage}>
                <Section y="min" x="min">
                    <Icon large>arrow_back</Icon>
                </Section>
            </button>
        )
    }
}

BackButton.propTypes = {
    fallBack: PropTypes.string,
}

export default BackButton
