import { schema } from 'normalizr'

const optionPage = new schema.Entity(
    'option_pages',
    {},
    {
        idAttribute: 'option_page_id',
    }
)
const options = new schema.Entity(
    'options',
    { option_page: optionPage },
    {
        idAttribute: 'option_id',
    }
)
const questionRules = new schema.Entity(
    'question_rules',
    {},
    {
        idAttribute: 'option_id',
    }
)
const bagUses = new schema.Entity(
    'bag_uses',
    {},
    {
        idAttribute: 'bag_use_id',
    }
)

const categories = new schema.Entity(
    'categories',
    {},
    {
        idAttribute: 'category_id',
    }
)

const prices = new schema.Entity(
    'prices',
    {},
    {
        idAttribute: 'price_id',
    }
)

const questions = new schema.Entity(
    'questions',
    {
        options: [options],
        question_rules: [questionRules],
        bag_use: bagUses,
    },
    {
        idAttribute: 'question_id',
    }
)
const files = new schema.Entity(
    'files',
    {},
    {
        idAttribute: 'files_id',
    }
)
const faqs = new schema.Entity(
    'faqs',
    {},
    {
        idAttribute: 'faq_id',
    }
)

const ctas = new schema.Entity(
    'ctas',
    {},
    {
        idAttribute: 'cta_id',
    }
)

const servicesSchema = new schema.Entity(
    'services',
    {
        questions: [questions],
        category: categories,
        files: [files],
        prices: [prices],
        faqs: [faqs],
        ctas: [ctas],
    },
    {
        idAttribute: 'service_id',
    }
)

export default [servicesSchema]
