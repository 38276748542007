import { Row, Col } from '@advanza/grid'
import { Divider, Button, Icon, MaxWidth } from '@advanza/ui'
import ExactExportBlock from 'components/billing/ExactExportBlock'
import Card from 'components/Card'
import A from 'components/ui/A'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import Page from 'components/ui/Page'
import { useInvoiceExport } from 'hooks/billingHooks'
import React, { Fragment } from 'react'

const ExactImportPage = ({}) => {
    const {
        isExporting,
        exportInvoices,
        exportRelations,
        exportMandates,
        loadPreviousInvoicesBatch,
        loadPreviousRelationsBatch,
        loadPreviousMandateBatch,
        invoices,
        relations,
        mandates,
    } = useInvoiceExport()
    return (
        <Page>
            <h1>Exact exports</h1>
            <Divider l />
            <MaxWidth maxWidth={500}>
                <LoadBoxWrapper isLoading={isExporting === 'invoices'}>
                    <Card header="Export invoices" isLoading={isExporting === 'invoices'}>
                        <Divider m />
                        <ExactExportBlock
                            items={invoices}
                            type="invoices"
                            downloadUrl="downloadExactInvoices"
                            isExporting={isExporting}
                            loadBatchesFunc={loadPreviousInvoicesBatch}
                            exportFunc={exportInvoices}
                        />
                    </Card>
                </LoadBoxWrapper>
                <Divider m />
                <LoadBoxWrapper isLoading={isExporting === 'relations'}>
                    <Card header="Export relations" isLoading={isExporting === 'relations'}>
                        <Divider m />
                        <ExactExportBlock
                            items={relations}
                            type="relations"
                            downloadUrl="downloadExactRelations"
                            isExporting={isExporting}
                            loadBatchesFunc={loadPreviousRelationsBatch}
                            exportFunc={exportRelations}
                        />
                    </Card>
                </LoadBoxWrapper>
                <Divider m />
                <LoadBoxWrapper isLoading={isExporting === 'mandates'}>
                    <Card header="Export Mandates" isLoading={isExporting === 'mandates'}>
                        <Divider m />
                        <ExactExportBlock
                            items={mandates}
                            type="mandates"
                            downloadUrl="downloadExactMandates"
                            isExporting={isExporting}
                            loadBatchesFunc={loadPreviousMandateBatch}
                            exportFunc={exportMandates}
                        />
                    </Card>
                </LoadBoxWrapper>
            </MaxWidth>
        </Page>
    )
}

export default ExactImportPage
