import React from 'react'
import { connect } from 'react-redux'
import { sendPasswordMail } from '../actions/adminUsers'

function mapStateToProps(state, props) {
    const { entities, result, isFetching, filters, permissions } = state.adminUsers
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    return {
        entities,
        pages: result[searchKey] || {},
        filter,
        isFetching,
        permissions,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendPasswordMail: (id) => dispatch(sendPasswordMail(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
