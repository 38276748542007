import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import Card from 'components/Card'
import ServiceRequestStatsCalendarChart from 'components/serviceInfo/ServiceRequestStatsCalendarChart'
import ServiceStatsExportButton from 'components/serviceInfo/ServiceStatsExportButton'
import React from 'react'
import { Redirect } from 'react-router-dom'
import ServicesSelect from '../services/ServicesSelect'
import Page from '../ui/Page'
import ServiceCompetitionStats from './ServiceCompetitionStats'
import ServiceMatchStats from './ServiceMatchStats'
import ServicePricesActive from './ServicePricesActive'
import ServiceRequestStats from './ServiceRequestStats'
import ServiceRequestStatsAll from './ServiceRequestStatsAll'

class ServiceInfoPage extends React.Component {
    constructor(props) {
        super(props)
        this.onServiceChange = this.onServiceChange.bind(this)
        this.state = {
            redirect: false,
        }
    }

    onServiceChange(services) {
        this.setState({ redirect: `/sales/site-info/${services[0]}` }, () => {
            this.setState({ redirect: false })
        })
    }

    render() {
        const id = this.props.match && this.props.match.params.id
        const { redirect } = this.state
        if (redirect) {
            return <Redirect to={redirect} push />
        }
        const serviceId = parseInt(id, 10)
        return (
            <Page title="Service Info">
                <Divider l />
                <Row>
                    <Col x>
                        <ServicesSelect
                            value={serviceId.toString()}
                            onChangeValue={this.onServiceChange}
                            multiple={false}
                            emptyOption="-"
                            placeholder="select a service"
                        />
                    </Col>
                    <Col x>
                        <Divider />
                        <ServicePricesActive serviceId={serviceId} />
                        <Divider m />
                    </Col>
                    <Col x>{serviceId && <ServiceStatsExportButton serviceId={serviceId} />}</Col>
                </Row>
                {id && (
                    <div>
                        <Row>
                            <Col x>
                                <Card>
                                    <ServiceCompetitionStats serviceId={serviceId} />
                                </Card>
                                <Divider l />
                            </Col>
                            <Col x>
                                <Card>
                                    <ServiceRequestStats serviceId={serviceId} />
                                </Card>
                            </Col>
                        </Row>
                        <Divider l />
                        <ServiceRequestStatsCalendarChart serviceId={serviceId} />
                        <Card useToggle header="Match stats" icon="check">
                            <Divider />
                            <ServiceMatchStats serviceId={serviceId} />
                            <Divider />
                        </Card>
                    </div>
                )}
                {!id && (
                    <div>
                        <Divider l />
                        <ServiceMatchStats serviceId={0} />
                        <Divider l />
                        <ServiceRequestStatsAll serviceId={0} />
                    </div>
                )}
                <Divider xl />
            </Page>
        )
    }
}

export default ServiceInfoPage
