import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
    selectItems,
} from './sharedReducerHandlers'
import format from 'date-fns/format'

const update = getUpdater()

const handlers = {
    INVOICES_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    INVOICES_RECEIVE_LIST: receiveList,
    INVOICES_CHANGE_FILTER: changeFilter,
    INVOICES_SELECT_ITEMS: selectItems,
    CHANGE_ENTITY_BILLING: changeEntity,
    CHANGE_ENTITY_INVOICES: changeEntity,
    INVALIDATE_INVOICES: invalidateFilters,
    SET_INVOICES: (state, action) => {
        return update(state, action.action)
    },
    SET_LOADING_INVOICE: (state, action) => {
        return update(state, {
            entities: {
                invoices: {
                    [action.id]: {
                        _isLoading: { $set: action.isLoading },
                    },
                },
            },
        })
    },
    SET_INVOICE_ACTION_STATUS: (state, action) => {
        return update(state, {
            entities: {
                invoices: {
                    [action.id]: {
                        _actionStatus: { $set: action.status },
                    },
                },
            },
        })
    },
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    openCharges: {
        aboutMonth: format(new Date(), 'yyyy-MM'),
        isFetching: false,
        result: [],
    },
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
