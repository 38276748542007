import React from 'react'
import { connect } from 'react-redux'
import { addLabel, changeLabelsFilter, deleteLabelsSelection } from '../actions/labels'

function mapStateToProps(state, props) {
    const { filters, isFetching, result, counts } = state.labels
    const filter = filters[props.filterId]
    const filterResult = filter && result[filter.searchKey]
    const count = filter && counts[filter.searchKey]
    const countOnPage = filter && filterResult[filter.page || 0].length

    return {
        filter,
        count,
        countOnPage,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeLabelsFilter(id, options)),
        addLabel: (options, filterId) => dispatch(addLabel(options, filterId)),
        deleteSelection: (filterId) => dispatch(deleteLabelsSelection(filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
