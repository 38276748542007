import { Section } from '@advanza/advanza_generic'
import React from 'react'
import RequestCard from '../requests/RequestCard'

const SalesLeadRequest = ({ lead }) => {
    return (
        <Section>
            <RequestCard requestId={lead.request_id} />
        </Section>
    )
}

SalesLeadRequest.propTypes = {}

export default SalesLeadRequest
