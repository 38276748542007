import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { changeEntity, changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    GEOTARGETING_RECEIVE_LIST: receiveList,
    GEOTARGETING_CHANGE_FILTER: changeFilter,
    CHANGE_ENTITY_GEOTARGETING: changeEntity,
    INVALIDATE_GEOTARGETING: invalidateFilters,
    GEOTARGETING_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    GEOTARGETING_RESET: (state) =>
        update(state, {
            counts: { $set: {} },
            entities: { $set: {} },
            result: { $set: {} },
        }),
    GEOTARGETING_UPDATE_USED_AT_IDS: (state, action) =>
        update(state, {
            extra: {
                targets: {
                    [action.criteriaId]: {
                        usedAtIds:
                            action.op === 'add'
                                ? { $vivPushDistinct: [action.geoId] }
                                : action.op === 'remove'
                                ? { $arrDel: action.geoId }
                                : { $set: [action.geoId] },
                    },
                },
            },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    counts: {},
    entities: {},
    result: {},
    filters: {},
}

export default createReducer(initialState, handlers)
