import { PreIcon } from '@advanza/ui'
import React from 'react'
import { Select } from '@advanza/advanza_generic'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const CalendarPicker = ({ entity: service = {}, onChangeEntity = () => {} }) => {
    const { isFetching } = useSelector((state) => state.services)
    const [currentCalendar, setCurrentCalendar] = useState('')
    const [showWarning, setShowWarning] = useState(false)
    const loadData = service.service_id && !isFetching && !service._saving

    const getSelected = () =>
        service.enable_calendar
            ? 'enable_calendar'
            : service.enable_moment_picker
            ? 'enable_moment_picker'
            : service.enable_month_picker
            ? 'enable_month_picker'
            : 'no_calendar'

    const onChangeCalendar = (e) => {
        const { value: newCalendar } = e.target

        // changing an existing calendar type likely cause issues in the wizard and supply dashboard
        if (currentCalendar !== newCalendar && currentCalendar !== 'no_calendar') {
            setShowWarning(true)
        } else {
            setShowWarning(false)
        }

        // reset all calendars
        const calendars = {
            enable_calendar: false,
            enable_moment_picker: false,
            enable_month_picker: false,
        }

        if (newCalendar !== 'no_calendar') {
            // set the one we want to true
            calendars[newCalendar] = true
        }

        onChangeEntity(calendars)
    }

    useEffect(() => {
        if (loadData) {
            // after fetching or saving the service, we need to update the current calendar
            setCurrentCalendar(getSelected())

            // remove the warning if needed
            showWarning && setShowWarning(false)
        }
    }, [loadData])

    return (
        <div>
            <Select
                msg="Enable calendar type"
                value={getSelected()}
                options={[
                    { value: 'no_calendar', title: 'None' },
                    { value: 'enable_calendar', title: 'Day picker' },
                    { value: 'enable_moment_picker', title: 'Moment picker' },
                    { value: 'enable_month_picker', title: 'Month picker' },
                ]}
                onChange={onChangeCalendar}
            />
            {service.enable_moment_picker && !service.enable_opening_hours && (
                <PreIcon name="warning" red overflowFix>
                    <strong style={{ color: '#cf3131', padding: '4px' }}>
                        The opening hours are required for the moment picker. Please enable them.
                    </strong>
                </PreIcon>
            )}
            {showWarning && (
                <PreIcon name="warning" red overflowFix>
                    <strong style={{ color: '#cf3131', padding: '4px' }}>
                        Are you sure you want to change the type of calendar? Changing it will
                        likely cause issues in the wizard and supply dashboard.
                    </strong>
                </PreIcon>
            )}
        </div>
    )
}

export default CalendarPicker
