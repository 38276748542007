import { Section } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import { HoverText, Icon, LinearLoader, PreIcon } from '@advanza/ui'

import React from 'react'
import style from './Card.module.css'

class Card extends React.Component {
    constructor(props) {
        super(props)
        this.state = { open: !props.useToggle }
    }

    render() {
        const {
            children,
            className,
            classNameIcon,
            s3 = false,
            isLoading,
            header,
            topRight,
            icon,
            iconStyle = {},
            useToggle,
            expandableStyle,
            infoHover = undefined,
        } = this.props

        const { open } = this.state
        return (
            <Section
                bg="white"
                className={[
                    style.root,
                    expandableStyle ? style.expandableStyle : '',
                    open ? 'open' : '',
                    className,
                ].join(' ')}
                onClick={(e) =>
                    useToggle &&
                    e.target.parentNode.id !== '_toggle_button' &&
                    this.setState({ open: true })
                }
                x
                y="plus"
                style={{ position: 'relative', cursor: !open ? 'pointer' : 'default' }}>
                {isLoading && <LinearLoader absolute />}
                {header && (
                    <Row between="xs" middle="xs">
                        <Col x>
                            {icon ? (
                                <PreIcon
                                    fontSize={21}
                                    primColor
                                    icon={icon}
                                    style={iconStyle}
                                    classNameIcon={classNameIcon}
                                    s3={s3}>
                                    <h5 className="card-h">{header}</h5>
                                    {infoHover && (
                                        <HoverText
                                            text={infoHover}
                                            trigger={
                                                <Icon
                                                    style={{ marginLeft: 4 }}
                                                    fontSize={18}
                                                    name="info"
                                                />
                                            }
                                        />
                                    )}
                                </PreIcon>
                            ) : (
                                <div className="card-h">
                                    <h5 className={style.h}>{header}</h5>
                                </div>
                            )}
                        </Col>
                        {topRight && <Col x>{topRight}</Col>}
                        {useToggle && (
                            <Col x>
                                <button
                                    id="_toggle_button"
                                    onClick={(e) => this.setState({ open: open !== true })}>
                                    <Icon
                                        name={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                    />
                                </button>
                            </Col>
                        )}
                    </Row>
                )}

                {open && children}
            </Section>
        )
    }
}

export default Card
