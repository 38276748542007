import { call } from '@advanza/api'
import { Radio } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, PreIcon, LinearLoader, LoadingDots } from '@advanza/ui'
import { invalidateService } from 'actions/services'
import { useChangeEntity } from 'hooks/entityHooks'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

export function useOptionPages({ serviceId, optionPageId }) {
    const [modalOpen, setModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [eligibleOptions, setOptions] = useState(false)
    const dispatch = useDispatch()
    const invalidate = () => dispatch(invalidateService(serviceId))

    const onClick = () => {
        setIsLoading(true)
        setModalOpen(true)
        call('office/services/get-options-for-option-pages/' + serviceId)
            .then(({ options }) => {
                setOptions(options)
            })
            .finally(() => setIsLoading(false))
    }

    const onSelectOption = (optionId) => {
        setIsLoading(optionId)
        call('office/services/save-option-page/' + optionId, { method: 'post' }).then(() => {
            setIsLoading(false)
            invalidate()
            setModalOpen(false)
        })
    }

    const onSave = (id) => {
        return function (dispatch, getState) {
            const entity = getState().services.entities.option_pages[id]
            setIsLoading(id)
            return call('office/services/save-option-page/', { json: entity }).then(
                () => {
                    invalidate()
                    setIsLoading(false)
                },
                (response) => {
                    if (response.error === 'fields') {
                        dispatch(
                            changeEntity({
                                store: 'services',
                                name: 'option_pages',
                                key: id,
                                diff: {
                                    _errors: response,
                                },
                            })
                        )
                    }
                }
            )
        }
    }
    const inputFields = {
        keyword_singular: { type: 'text', placeholder: 'Keyword singular' },
        keyword_plural: { type: 'text', placeholder: 'Keyword plural' },
        project_goal_sentence: {
            type: 'text',
            placeholder: 'Title sub text e.g.: Om je vogel te genezen',
        },
        paragraph: {
            type: 'text',
            textArea: true,
            placeholder:
                'Landingpage paragraph | {{aantal}} {{count}} {{city}} {{Trustoo_score}}',
        },
        paragraph_costs: {
            type: 'text',
            textArea: true,
            placeholder:
                'Costs section paragraph | {{price}} {{priceUnit}} | **bold text** | [https](link tekst)',
        },
    }

    const changeEntity = useChangeEntity(
        {
            name: 'option_pages',
            store: 'services',
            entityId: optionPageId,
            saveFunc: onSave,
        },
        inputFields
    )

    const deleteOptionPage = (optionPageId) => {
        setIsLoading(optionPageId)
        return call('office/services/delete-option-page/' + optionPageId, { method: 'post' }).then(
            () => {
                invalidate()
                setIsLoading(false)
            }
        )
    }

    const selectOptions = eligibleOptions
        ? eligibleOptions.map(({ option_id, value }) => {
              return {
                  name: value,
                  value: option_id,
              }
          })
        : []

    return {
        selectOptions,
        isLoading,
        closeModal: () => setModalOpen(false),
        onSelectOption,
        deleteOptionPage,
        onSave,
        modalOpen,
        onClick,
        ...changeEntity,
    }
}

const AddOptionPageButton = ({ serviceId }) => {
    const { modalOpen, onClick, selectOptions, closeModal, onSelectOption, isLoading } =
        useOptionPages({ serviceId })
    return (
        <div>
            <Button name="text" onClick={onClick}>
                <PreIcon icon="add" primColor>
                    Add option page
                </PreIcon>
            </Button>
            <Modal open={modalOpen} close={closeModal}>
                {isLoading && <LinearLoader absolute />}
                {isLoading && <LoadingDots />}
                <Radio options={selectOptions} onChange={(e) => onSelectOption(e.target.value)} />
            </Modal>
        </div>
    )
}

export default AddOptionPageButton
