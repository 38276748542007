import React from 'react'
import { connect } from 'react-redux'
import {
    changeSubscriptionTravelRange,
    deleteSubscription,
    fetchSubscription,
    replaceSubscriptionGeoids,
    replaceSubscriptionOptions,
    saveSubscriptionGeoids,
    saveSubscriptionOptions,
    toggleActiveSubscription,
} from '../actions/subscriptions'

function mapStateToProps(state, props) {
    const { entities, geoIds, isFetching } = state.subscriptions
    const { entities: serviceEntities } = state.services
    const subscription = entities.subscriptions && entities.subscriptions[props.subscriptionId]

    return {
        subscription,
        isFetching,
        geoIds,
        serviceEntities,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchSubscription: (subscriptionId) => dispatch(fetchSubscription(subscriptionId)),
        replaceSubscriptionOptions: (subscriptionId, options) =>
            dispatch(replaceSubscriptionOptions(subscriptionId, options)),
        changeSubscriptionTravelRange: (id, postalCode, range) =>
            dispatch(changeSubscriptionTravelRange(id, postalCode, range)),
        saveGeoIds: (id) => dispatch(saveSubscriptionGeoids(id)),
        saveOptions: (id) => dispatch(saveSubscriptionOptions(id)),
        replaceSubscriptionGeoids: (subscriptionId, geoIds) =>
            dispatch(replaceSubscriptionGeoids(subscriptionId, geoIds)),
        toggleActiveSubscription: (subId) => dispatch(toggleActiveSubscription(subId)),
        deleteSubscription: (subId) => dispatch(deleteSubscription(subId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
