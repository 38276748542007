import React from 'react'
import AutoRow from 'components/misc/AutoRow'
import { S3_PUBLIC } from '@advanza/constants'
import { Icon } from '@advanza/ui'

const ProSearchResult = ({
    userId = 0,
    avatarFileName = '',
    status = undefined,
    providerId = 0,
    businessName = '',
    city = '',
}) => {
    return (
        <AutoRow gridGap="11px 6px">
            <img
                width={30}
                height={30}
                alt="avatar"
                style={{ borderRadius: 4 }}
                src={S3_PUBLIC() + `users/${userId}/${avatarFileName}`}
            />
            {status && (
                <Icon
                    name={status.icon}
                    style={{
                        backgroundColor: status.backgroundColor,
                        color: status.color,
                        borderRadius: 7,
                    }}
                />
            )}
            <small style={{ opacity: 0.7, fontSize: 10 }}>#{providerId}</small>
            <span>
                {businessName} - {city}
            </span>
        </AutoRow>
    )
}

export default ProSearchResult
