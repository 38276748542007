import { Section } from '@advanza/advanza_generic'
import { Icon } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedRelative } from 'react-intl'
import ReviewListContainer from '../../containers/ReviewListContainer'
import TableList from '../TableList'
import ReviewEntity from './ReviewEntity'
import ReviewStars from './ReviewStars'

class ReviewList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { reviews } = this.props.reviewEntities
        const review = reviews[id]
        const name = review.user
            ? review.user.first_name + ' ' + review.user.last_name
            : review.name || 'anonymous'
        const deleted = review.status === 0
        const reviewStatusIcon = {
            0: {
                // deleted
                name: 'deleted',
                red: true,
                color: 'red',
            },
            1: {
                // accepted
                name: 'done',
                green: true,
                color: '#4BB545',
            },
            2: {
                // rejected
                name: 'thumb_down',
                style: { color: '#555' },
                color: '#555',
            },
            3: {
                // not validated
                name: 'schedule',
                orange: true,
                color: '#f46511',
            },
        }

        return [
            <small className="c-grey">#{id}</small>,
            <span
                className="ellipsis"
                style={{ maxWidth: '9em', color: reviewStatusIcon[review.status || 0].color }}>
                <Icon {...reviewStatusIcon[review.status || 0]} />{' '}
                {!deleted ? name : <strike>{name}</strike>}
            </span>,
            <ReviewStars className="ellipsis" avg={review.rating / 2} />,
            <small className="c-grey">
                <FormattedRelative value={review.created} />
            </small>,
        ]
    }

    renderRow(id) {
        const { reviews } = this.props.reviewEntities
        return {
            cols: this.renderColumns(id),
            expandOnClick: true,
            expandable: (
                <Section x y>
                    <ReviewEntity entityId={id} />
                </Section>
            ),
            expanded: reviews[id]._open,
            id,
        }
    }

    definitions = {
        id: { name: '#' },
        name: { name: 'name' },
        stars: { name: 'rating' },
        time: { name: 'time' },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

ReviewList.propTypes = {
    filter: PropTypes.object,
    pages: PropTypes.any,
    reviewEntities: PropTypes.object,
    isFetching: PropTypes.any,
}

export default ReviewListContainer(ReviewList)
