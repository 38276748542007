import { Row, Col } from '@advanza/grid'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, LinearLoader, PreIcon } from '@advanza/ui'
import LicencesLabelEntity from 'components/licences/LicencesLabelEntity'
import LabelListContainer from 'containers/LabelListContainer'
import { useLicencesLabel } from 'hooks/licencesHooks'
import React, { Fragment, useState } from 'react'

const LicenceLabelEdit = ({ licenceId, officeLabelId, labels, fetchLabels }) => {
    const { addLabel, isAssigning, assignLabel, assigned, isActivating, activateLabel, activated } =
        useLicencesLabel(licenceId, officeLabelId)
    const [open, setOpen] = useState(false)
    const [entityId, setEntityId] = useState(null)
    const openEntity = () => {
        if (!officeLabelId) {
            addLabel()
                .then((id) => setEntityId(id))
                .then(() => setOpen(true))
        } else {
            setEntityId(officeLabelId)
            setOpen(true)
        }
    }
    const onOpen = () => {
        if (Object.keys(labels).length === 0) {
            fetchLabels('all').then(() => openEntity())
        } else {
            openEntity()
        }
    }

    return (
        <Fragment>
            {
                <button onClick={onOpen}>
                    {officeLabelId ? (
                        <Icon name="edit" fontSize={16} />
                    ) : (
                        <small>
                            <PreIcon name="add" fontSize={12}>
                                add label
                            </PreIcon>
                        </small>
                    )}
                </button>
            }

            <Modal open={open} close={() => setOpen(false)} wrapContent={false} maxWidth={1000}>
                {isAssigning && <LinearLoader />}
                <div style={{ padding: '24px' }}>
                    <h3>Licence label editor</h3>
                    <Divider m />
                    <LicencesLabelEntity entityId={entityId} onClose={() => setOpen(false)} />
                    <Divider m />
                    {officeLabelId && (
                        <Row>
                            <Col xs={12} sm={6}>
                                <strong>Assign</strong>
                                <Divider xs />
                                <div>Assign this label to all licenced providers</div>
                                <Divider m />
                                {assigned > 0 ? (
                                    <em>Succesful assigned to {assigned} providers!</em>
                                ) : assigned === 0 ? (
                                    <em>No provider with this licence found</em>
                                ) : (
                                    <Button onClick={assignLabel}>
                                        {isAssigning ? 'Assigning labels...' : 'Assign'}
                                    </Button>
                                )}
                                <Divider m />
                            </Col>
                            <Col xs={12} sm={6}>
                                <strong>Activate</strong>
                                <Divider xs />
                                <div>
                                    Activate this label if pro has no active qualification labels
                                </div>
                                <Divider m />
                                {activated > 0 ? (
                                    <em>Succesful {activated} labels activated!</em>
                                ) : activated === 0 ? (
                                    <em>
                                        All providers with this licence have already active
                                        qualification labels
                                    </em>
                                ) : (
                                    <Button onClick={activateLabel}>
                                        {isActivating ? 'Activating labels...' : 'Activate'}
                                    </Button>
                                )}
                                <Divider m />
                            </Col>
                        </Row>
                    )}
                </div>
            </Modal>
        </Fragment>
    )
}

export default LabelListContainer(LicenceLabelEdit)
