import PropTypes from 'prop-types'
import React from 'react'

class Balloon extends React.Component {
    constructor(props) {
        super(props)
        this.onMouseLeave = this.onMouseLeave.bind(this)
        this.onHover = this.onHover.bind(this)
        this.state = {
            open: false,
            inTrans: false,
        }
        this.mouseOnElement = true
        this.useTimeout = !props.noTimeout
    }

    onHover(e) {
        this.mouseOnElement = true
        const { onHover } = this.props
        setTimeout(
            () => {
                if (this.mouseOnElement && !this.state.open) {
                    this.setState({ open: true, inTrans: false })
                    onHover && onHover(true)
                }
            },
            this.useTimeout ? 500 : 0
        )
    }

    onMouseLeave(e) {
        this.mouseOnElement = false
        const { onLeave } = this.props
        setTimeout(
            () => {
                this.setState({ inTrans: true })
                onLeave && onLeave(false)
            },
            this.useTimeout ? 550 : 0
        )
        setTimeout(
            () => {
                this.setState({ open: false, inTrans: false })
            },
            this.useTimeout ? 600 : 0
        )
    }

    render() {
        const { children, content, style = {} } = this.props
        const { open, inTrans } = this.state
        return (
            <div
                className="with-balloon"
                style={{ cursor: 'help', ...style }}
                ref={(el) => (this.el = el)}
                onMouseOver={this.onHover}
                onMouseLeave={this.onMouseLeave}>
                {children}
                {open && (
                    <div className={`balloon ${inTrans ? 'animate-out' : 'animate-in'}`}>
                        {content}
                    </div>
                )}
            </div>
        )
    }
}

Balloon.propTypes = {
    content: PropTypes.any,
}

export default Balloon
