import { ALLOWED_MIMES, MAX_FILE_SIZE } from '@advanza/constants'
import React, { forwardRef, useCallback } from 'react'
import { useDropzone } from 'react-dropzone' // using v14.2.3

/**
 * FileDropZone ERROR CODES
 *
 * code: "file-too-large"
 * if file is larger than maxSize
 *
 * code: 'file-invalid-type'
 * if not file mime not accepted
 *
 * code: 'too-many-files'
 * if multiple = true && maxFiles > 0 && number of dropped files > maxFiles
 *
 * Optional: add more error codes with the onValidate function
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly children?: *, readonly className?: *, readonly disabled?: boolean, readonly style?: {}, readonly mime?: string, readonly allowedTypes?: string[], readonly allowSvg?: boolean, readonly multiple?: boolean, readonly maxFiles?: number, readonly onValidate?: *, readonly onBeforeDrop?: *, readonly onFilesAccepted?: *, readonly onFilesRejected?: *, readonly noClick?: boolean}> & React.RefAttributes<unknown>>}
 */
const FileDropZone = forwardRef(function FileDropZone(
    {
        children,
        className,
        disabled = false,
        style = {},
        mime = '',
        allowedTypes = ['doc', 'image', 'video'],
        allowSvg = false,
        multiple = true,
        maxFiles = 0,
        onValidate,
        onBeforeDrop,
        onFilesAccepted,
        onFilesRejected,
        noClick = false,
    },
    ref
) {
    const allowedMimes = ALLOWED_MIMES()

    // accept one specific mime or all mimes from specific types
    const accept = mime
        ? {
              [mime]: [],
          }
        : Object.keys(allowedMimes).reduce((accumulator, currentType) => {
              let allowed = {}
              if (allowedTypes.includes(currentType)) {
                  allowedMimes[currentType].forEach((mime) => (allowed[mime] = []))
                  if (allowSvg && currentType === 'image') {
                      allowed['image/svg+xml'] = []
                  }
              }
              return { ...accumulator, ...allowed }
          }, {})

    const onDropAccepted = useCallback(
        (acceptedFiles) => onFilesAccepted(acceptedFiles),
        [onFilesAccepted]
    )
    const onDropRejected = useCallback(
        (fileRejections) => onFilesRejected(fileRejections),
        [onFilesRejected]
    )

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        disabled,
        multiple,
        maxFiles,
        maxSize: MAX_FILE_SIZE * 1000 * 1000,
        onDragEnter: onBeforeDrop,
        onFileDialogOpen: onBeforeDrop,
        onDropAccepted,
        onDropRejected,
        noClick,
        validator: onValidate ? (file) => onValidate(file) : null,
    })

    return (
        <div {...getRootProps()} className={className} style={style} ref={ref}>
            <input {...getInputProps()} />
            {children}
        </div>
    )
})

export default FileDropZone
