import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { changeFilter, invalidateFilters, receiveList, changeEntity } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    REGIONS_RECEIVE_LIST: receiveList,
    REGIONS_CHANGE_FILTER: changeFilter,
    CHANGE_ENTITY_REGIONS: changeEntity,
    INVALIDATE_REGIONS: invalidateFilters,
    REGIONS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    counts: {},
    entities: {},
    result: {},
    filters: {},
}

export default createReducer(initialState, handlers)
