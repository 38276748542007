import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    REQUESTS_CHANGE_FILTER,
    REQUESTS_DECLARE_VALID,
    REQUESTS_DELETED,
    REQUESTS_RECEIVE_LIST,
    REQUESTS_RECEIVE_SINGLE,
    REQUESTS_REQUEST,
} from 'actions/requests'
import { changeEntity, changeFilter, receiveList, selectItems } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [REQUESTS_RECEIVE_LIST]: receiveList,
    [REQUESTS_CHANGE_FILTER]: changeFilter,
    REQUESTS_SELECT_ITEMS: selectItems,
    CHANGE_ENTITY_REQUESTS: changeEntity,
    [REQUESTS_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [REQUESTS_RECEIVE_SINGLE]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            didInvalidate: { $set: false },
            entities: { $deepMerge: action.entities },
        }),
    [REQUESTS_DECLARE_VALID]: (state, action) =>
        update(state, {
            entities: {
                requests: {
                    [action.requestId]: {
                        valid: { $set: 1 },
                        status: { $set: 'SENT' },
                    },
                },
            },
        }),
    [REQUESTS_DELETED]: (state, action) =>
        update(state, {
            entities: {
                requests: {
                    [action.requestId]: {
                        status: { $set: 'DELETED' },
                    },
                },
            },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
