import profileLabelsSchema from '../schemes/profileLabelsSchema'
import { _changeFilter, _fetchItems } from './lists'

export const PROFILE_LABELS_REQUEST = 'PROFILE_LABELS_REQUEST'
export const PROFILE_LABELS_RECEIVE_LIST = 'PROFILE_LABELS_RECEIVE_LIST'
export const PROFILE_LABELS_CHANGE_FILTER = 'PROFILE_LABELS_CHANGE_FILTER'

export function fetchLabels(filterId) {
    return _fetchItems(filterId, {
        url: 'office/providers-labels/get-profile-labels-list',
        schema: profileLabelsSchema,
        requestActionName: PROFILE_LABELS_REQUEST,
        receiveActionName: PROFILE_LABELS_RECEIVE_LIST,
        reducerKey: 'profileLabels',
    })
}

export function changeLabelsFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'profileLabels',
        fetchFunc: fetchLabels,
        actionType: PROFILE_LABELS_CHANGE_FILTER,
    })
}
