import PropTypes from "prop-types"
import React from "react"
import {injectIntl} from "react-intl"

const Banner = ({
                    src, srcSmall, alt, children, className, imgStyle,
                    intl, size = 'md', opacity, bgImg, bgImgPosition,
                    backgroundColor, maxImgWidth
                }) => {

    const img = bgImg ?
        <div className="bg-image" style={
            {
                background: `url(${src}) no-repeat ${bgImgPosition || 'center center /cover'}`,
                opacity   : opacity || 1
            }
        }/> :
        <picture style={{opacity: opacity || 1}}>
            <source media="(max-width: 640px)"
                    srcSet={srcSmall || src}/>
            <source media="(min-width: 641px)"
                    srcSet={src}/>
            <img src={src}
                 style={imgStyle || (maxImgWidth ? {maxWidth: maxImgWidth} : null)}
                 alt={alt ? intl.formatMessage({id: alt}) : 'image.jpg'}/>
        </picture>

    const style = typeof size === 'number' ? {height: size} : {}
    return <div className={`banner ${className || ''} size-${size}`} style={style}>
        <div className={`background ${size === 'auto' ? 'auto-height' : ''}`} style={{backgroundColor}}>
            {img}
            <div className='inside'>
                {children}
            </div>
        </div>
    </div>
}

Banner.propTypes = {
    src: PropTypes.string.isRequired,

    srcSmall       : PropTypes.string,
    alt            : PropTypes.string,
    className      : PropTypes.string,
    backgroundColor: PropTypes.any,
    opacity        : PropTypes.string,
    bgImg          : PropTypes.bool,
    maxImgWidth    : PropTypes.any,
    bgImgPosition  : PropTypes.string, // e.g. center center / cover
    size           : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    intl: PropTypes.object,
}

export default injectIntl(Banner)