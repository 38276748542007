import { Row, Col } from '@advanza/grid'
import { SelectCheckbox } from '@advanza/input'
import { Ellipsis } from '@advanza/ui'
import Avatar from 'components/adminUsers/Avatar'
import React from 'react'
import AdminUsersSelectContainer from '../../containers/AdminUsersSelectContainer'

class AdminUsersSelect extends React.Component {
    componentDidMount() {
        const { fetchAdminUsers } = this.props
        fetchAdminUsers()
    }

    render() {
        const { userIds = [], adminUsers, ...rest } = this.props
        const options = userIds.flatMap((id) => {
            const user = adminUsers[id]
            return user.active ? {
                value: user.id,
                name: (
                    <Row middle="xs">
                        <Col x>
                            <Avatar user={user} sm />
                        </Col>
                        <Col x>
                            <Ellipsis>{user.username}</Ellipsis>
                        </Col>
                    </Row>
                ),
            } : []
        })
        return <SelectCheckbox options={options} {...rest} />
    }
}

AdminUsersSelect.propTypes = {}

export default AdminUsersSelectContainer(AdminUsersSelect)
