import { schema } from 'normalizr'

const eventsSchema = new schema.Entity(
    'events',
    {},
    {
        idAttribute: 'event_id',
    }
)

export default [eventsSchema]
