import { call } from '@advanza/api'
import { Button, Divider, LoadingDots, PreIcon, Icon, Loading } from '@advanza/ui'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { getWebsiteHostnameByCountryCode } from 'misc/country'
import React, { useState, Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import style from './CreateSEOCityPage.module.css'

function useCreatePlacePage(countryCode) {
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const [place, setPlace] = useState([])
    const [allPages, setAllPages] = useState(null)

    const getList = () => {
        if (loading !== 'all') {
            setLoading('all')
            return call('office/services/get-seo-place-pages', {
                query: { countryCode },
            })
                .then((res) => {
                    setAllPages(res.pages || [])
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    const create = (postalCodeId, isNew) => {
        if (loading !== postalCodeId) {
            setLoading(postalCodeId)
            return call('office/services/create-seo-place-page', {
                payload: { postalCodeId },
            })
                .then((res) => {
                    setResponse(res)
                    if (isNew) {
                        getList()
                    }
                    setPlace(null)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    useEffect(() => {
        getList()
    }, [countryCode])
    return {
        loading,
        create,
        response,
        place,
        setPlace,
        allPages,
        close: () => setResponse(null),
    }
}

const CreateSEOCityPage = () => {
    const country = useSelector((state) => state.countries.countries[0])
    const {
        loading,
        allPages = [],
        response,
        close,
        create,
        place,
        setPlace,
    } = useCreatePlacePage(country)
    const hostName = getWebsiteHostnameByCountryCode(country)
    return (
        <Fragment>
            <h5>Create SEO place pages.</h5>
            <Divider m />
            <div className={style.root}>
                <SearchLocationInput
                    onChangeLocation={(location) => setPlace(location)}
                    countryCode={country}
                    style={{ maxWidth: 200 }}
                    value={place && place.place_name}
                />
                {place && (
                    <Button onClick={() => create(place.postal_code_id, true)}>
                        {loading === place.postal_code_id ? (
                            <LoadingDots />
                        ) : (
                            <>Create or update {place.place_name}</>
                        )}
                    </Button>
                )}
                {response && response.status === 'success' && (
                    <PreIcon icon="check" green>
                        <span>
                            Request submitted{' '}
                            <Button
                                name="text"
                                onClick={() => {
                                    close()
                                    setPlace(null)
                                }}>
                                OK
                            </Button>
                        </span>
                    </PreIcon>
                )}
            </div>
            <Divider />
            <div style={{ maxHeight: '75vh', overflow: 'auto', display: 'inline-block' }}>
                {allPages &&
                    allPages.map(({ url_name, regionUrl, postal_code_id }) => {
                        const isLoading = loading === postal_code_id
                        return (
                            <div
                                key={postal_code_id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gridGap: '10px',
                                }}>
                                <a
                                    style={{ minWidth: 200 }}
                                    href={`//${hostName}/${regionUrl}/${url_name}/`}
                                    target="_blank">
                                    {url_name} {isLoading ? <LoadingDots fontSize={12} /> : ''}
                                </a>
                                <Button name="text" onClick={() => create(postal_code_id)}>
                                    <Icon name={isLoading ? 'hourglass_full' : 'refresh'} />
                                </Button>
                            </div>
                        )
                    })}
            </div>
        </Fragment>
    )
}

export default CreateSEOCityPage
