import { schema } from 'normalizr'

export default [
    new schema.Entity(
        'faqs',
        {},
        {
            idAttribute: 'faq_id',
        }
    ),
]
