import React from 'react'
import { connect } from 'react-redux'
import { addSubscription, changeSubscriptionFilter } from '../actions/subscriptions'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.subscriptions
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]
    return {
        filter,
        count,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeSubscriptionFilter(id, options)),
        addSubscription: (providerId, serviceId, options) =>
            dispatch(addSubscription(providerId, serviceId, options)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
