import { Col, Row } from '@advanza/grid'
import { Divider, PreIcon } from '@advanza/ui'
import ProfileLabel from 'components/profileLabels/ProfileLabel'
import { useProfileIncentive } from 'hooks/providersLabelsHooks'
import React, { Fragment } from 'react'
import style from './profileLabels.module.css'

const ProfileIncentiveLabel = ({ providerId }) => {
    const { isFetching, isSignedOrFreeTrial, sentence, onPause, limitReached } =
        useProfileIncentive(providerId)

    const getWarning = (warning) => {
        return (
            <div className={style.warning}>
                <PreIcon icon="info" red fontSize={15}>
                    {' '}
                    Pro {warning}
                </PreIcon>
            </div>
        )
    }

    return (
        <Fragment>
            {isSignedOrFreeTrial && (
                <Fragment>
                    <Divider s />
                    <Row style={{ lineHeight: '36px' }}>
                        <Col x>
                            <div>Profile Incentive label:</div>
                        </Col>
                        {!isFetching && (
                            <Col x>
                                {sentence ? (
                                    <ProfileLabel
                                        label={{
                                            description: sentence,
                                            color: '#c3fee3',
                                            text_color: '#006f44',
                                            icon: 'local_offer',
                                        }}
                                    />
                                ) : (
                                    <PreIcon name="local_offer" fontSize={15}>
                                        none
                                    </PreIcon>
                                )}
                            </Col>
                        )}
                        <Col x>
                            {onPause && getWarning('on pause')}
                            {limitReached && getWarning('limit reached')}
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ProfileIncentiveLabel
