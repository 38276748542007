import { schema } from 'normalizr'

const agent = new schema.Entity('agent', {}, { idAttribute: 'agent_id' })
const contact = new schema.Entity(
    'salesLeadsContacts',
    {},
    { idAttribute: 'sales_leads_contact_id' }
)

const salesLeadsSchema = new schema.Entity(
    'salesLeads',
    {
        sales_leads_contact: contact,
        agent,
    },
    {
        idAttribute: 'sales_lead_id',
    }
)

export default [salesLeadsSchema]
