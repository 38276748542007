import PropTypes from 'prop-types'
import React, {Component} from "react"
import {FormattedMessage} from "react-intl"
import BodyPortal from "../src/BodyPortal"
import {Icon} from "../src/Icon"
import Section from "../src/Section"
import {Row} from "./Row"
import {bodyClickOutside} from "./services/bodyClick"

export class Alert extends Component {
    popupClassName = `popup ${window.isMobile ? 'popup-mobile mobile' : 'desktop'}`

    constructor(props) {
        super(props)
        this.animateClose = this.animateClose.bind(this)
        this.state = {
            inTrans: false,
            closed: false
        }
    }

    componentDidMount() {
        document.body.className += (' ' + this.popupClassName)

        if (this.props.closeOnOutsideClick) {
            document.addEventListener('click', this.bodyClick.bind(this))
            document.addEventListener('touchend', this.bodyClick.bind(this))
        }
    }

    componentWillUnmount() {
        // remove the body class after a short timeout.
        setTimeout(() => {
            const className = document.body.className
            document.body.className = className.replace(this.popupClassName.trim(), '')
        }, 100)

        if (this.props.closeOnOutsideClick) {
            document.removeEventListener('click', this.bodyClick.bind(this))
            document.removeEventListener('touchend', this.bodyClick.bind(this))
        }
    }

    close(e) {
        const {shouldAnimateOnClose} = this.props
        if (shouldAnimateOnClose || !this.props.close) {
            this.animateClose()
        } else {
            this.props.close()
        }
    }

    animateClose() {
        const {close} = this.props
        this.setState({inTrans: true})
        setTimeout(() => {
            const className = document.body.className
            document.body.className = className.replace(this.popupClassName.trim(), '')
        }, 100)
        setTimeout(() => {
            close && close()
            this.setState({closed: true})
        }, 200)
    }

    bodyClick(e) {
        bodyClickOutside(e, {className: 'alert-inner', action: this.close.bind(this)})
    }

    render() {
        const {onYes, onCancel, topBar, fullScreen, onBack, onOk, button, buttonMsg, title, className, children, onNo} = this.props
        const {inTrans, closed} = this.state
        if (closed) {
            return null
        }
        return <BodyPortal>
            <div className="alert-root">
                <div
                    className={`alert-wrapper ${className || ''} ${fullScreen ? 'full-screen' : ''} ${inTrans ? 'fade-to-bottom' : ''}`}>
                    <div className="alert-inner animate-in">
                        <div className="alert-top">
                            <Row nm className="v-center">
                                {topBar}
                                <div onClick={this.close.bind(this)} className="close to-right">
                                    <Icon small color="grey">close</Icon>
                                </div>
                            </Row>
                        </div>
                        <div className="alert-body">
                            {title && <div><h3 className="title">{title}</h3></div>}

                            <div>{children}</div>

                            <Section n="plus" className="buttons">
                                {onYes &&
                                <button className="btn cta" onClick={onYes}>
                                    <FormattedMessage id="alerts.yes"/>
                                </button>}
                                {onNo &&
                                <button className="btn black" onClick={onNo}>
                                    <FormattedMessage id="alerts.no"/>
                                </button>}

                                {onCancel &&
                                <button className="btn sub" onClick={onCancel}>
                                    <FormattedMessage id="alerts.cancel"/>
                                </button>
                                }

                                {onBack &&
                                <button className="btn black" onClick={onBack}>
                                    <FormattedMessage id="alerts.goback"/>
                                </button>
                                }
                                {onOk &&
                                <button className="btn cta" onClick={onOk}>
                                    <FormattedMessage id="alerts.ok"/>
                                </button>
                                }
                                {button &&
                                <button className="btn cta" onClick={button}>
                                    <FormattedMessage id={buttonMsg}/>
                                </button>
                                }
                            </Section>
                        </div>
                    </div>
                </div>
                {!fullScreen && <div className="overlay">
                    <div className="fade-in"/>
                </div>}
            </div>
        </BodyPortal>

    }
}

Alert.propTypes = {
    button              : PropTypes.any,
    buttonMsg           : PropTypes.any,
    className           : PropTypes.string,
    close               : PropTypes.func,
    closeOnOutsideClick : PropTypes.bool,
    fullScreen          : PropTypes.bool,
    onBack              : PropTypes.func,
    onCancel            : PropTypes.func,
    onNo                : PropTypes.func,
    onOk                : PropTypes.func,
    onYes               : PropTypes.func,
    shouldAnimateOnClose: PropTypes.bool,
    title               : PropTypes.any
}