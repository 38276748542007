import Card from 'components/Card'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { formatRelative } from 'date'
import { useLogsList } from 'hooks/logsHook'
import React from 'react'

const definitions = {
    log_id: { name: '#' },
    message: { name: 'message' },
    log_type: { name: 'type' },
    created: { name: 'created' },
}

const renderRows = (id, entities) => {
    const { logs } = entities
    const log = logs[id]
    const error = log.message_json?.error

    return {
        id: id,
        cols: renderCols(log),
        expandOnClick: Boolean(error),
        expandable: error ? <Card expandableStyle>{error}</Card> : undefined,
    }
}

const renderCols = (log) =>
    Object.keys(definitions).map((key) => {
        switch (key) {
            case 'created':
                return formatRelative(log[key])
            default:
                return log[key]
        }
    })

const WhatsappLogsList = ({ filterId = 'all', options }) => {
    const { filter = {}, currentPage, ...rest } = useLogsList(filterId, options)
    const rows = {}
    currentPage.forEach((id) => {
        rows[id] = renderRows(id, rest.entities)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <TableList {...rest} filter={filter} rows={rows} definitions={definitions} hideExport />
        </LoadBoxWrapper>
    )
}

export default WhatsappLogsList
