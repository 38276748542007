import React from 'react'

class ManageBodyOverflow extends React.Component {
    constructor(props) {
        super(props)
        this.initialScrollY = 0
    }

    bodyHasOverflow() {
        const cStyle = document.body.currentStyle || window.getComputedStyle(document.body, '')

        const hasVScroll = cStyle.overflow == 'visible' || cStyle.overflowY == 'visible'

        return hasVScroll
    }

    getScrollbarWidth() {
        const scrollDiv = document.createElement('div')
        scrollDiv.style.position = 'absolute'
        scrollDiv.style.top = '-9999px'
        scrollDiv.style.width = '50px'
        scrollDiv.style.height = '50px'
        scrollDiv.style.overflow = 'scroll'
        document.body.appendChild(scrollDiv)
        const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth
        document.body.removeChild(scrollDiv)
        return scrollbarWidth
    }

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0]
        this.initialScrollY =
            window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
        if (this.bodyHasOverflow()) {
            body.style.paddingRight = this.getScrollbarWidth() + 'px'
        }

        body.style.overflow = 'hidden'
        body.style.position = 'fixed'
        body.style.top = `-${this.initialScrollY}px`
        body.style.left = 0
        body.style.width = '100%'
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0]
        body.style.overflow = ''
        body.style.paddingRight = 0
        body.style.position = 'static'
        body.style.top = 'auto'
        body.style.left = 0
        body.style.width = 'auto'
        window.scrollTo(0, this.initialScrollY)
    }

    render() {
        return null
    }
}

ManageBodyOverflow.propTypes = {}

export default ManageBodyOverflow
