import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { changeEntity, changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    APIS_RECEIVE_LIST: receiveList,
    APIS_CHANGE_FILTER: changeFilter,
    CHANGE_ENTITY_APIS: changeEntity,
    INVALIDATE_APIS: invalidateFilters,
    APIS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    counts: {},
    entities: {},
    result: {},
    filters: {},
}

export default createReducer(initialState, handlers)
