import { useScrollModal } from '@advanza/modal'
import { Ellipsis, Icon } from '@advanza/ui'
import ProfileProjectsEditModal from 'components/profileProjects/ProfileProjectsEditModal'
import { useDeleteModal } from 'hooks/modalHooks'
import { useProfileProjectsItem } from 'hooks/profileProjectsHooks'
import React from 'react'
import style from './profileProjects.module.css'

const ProfileProjectsEdit = ({ projectId }) => {
    const { project = {}, isDeleting, onDelete, disableButtons } = useProfileProjectsItem(projectId)
    const { openModal, closeModal, renderModal } = useScrollModal()
    const { openModal: openDeleteModal, modal: deleteModal } = useDeleteModal(
        isDeleting,
        () => onDelete(projectId),
        'project'
    )

    return (
        <div className={[style.projectRow, !project.active ? style.hidden : ''].join(' ')}>
            <div className={style.grid} onClick={openModal}>
                <div className={style.projectId}>#{project.profile_project_id}</div>
                <div className={style.projectName}>
                    <Ellipsis>{project.title}</Ellipsis>
                </div>
            </div>
            <ProfileProjectsEditModal
                projectId={projectId}
                closeProjectModal={closeModal}
                renderProjectModal={renderModal}
            />
            <div>
                <button disabled={disableButtons} onClick={openDeleteModal}>
                    <Icon name="delete_forever" />
                </button>
                {deleteModal}
            </div>
        </div>
    )
}

export default ProfileProjectsEdit
