import { fetchAllSources } from 'actions/aggregatedReviews'
import React from 'react'
import { connect } from 'react-redux'

function mapStateToProps(state) {
    const { sources } = state.aggregatedReviews
    return { sources }
}

export default connect(mapStateToProps, { fetchAllSources })
