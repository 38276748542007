import { Icon, Msg } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'

const ReviewStars = ({ avg, nrReviews, inline, className, alt, showOnlyStars }) => {
    const isInt = (nr) => nr % 1 === 0
    const floor = (int) => Math.floor(int)
    const adjusted = Math.ceil(avg * 2) / 2
    const hasHalf = !isInt(adjusted) && adjusted > 0
    const nrWholeStars = hasHalf ? floor(adjusted) : adjusted
    const nrEmptyStars = hasHalf ? 5 - nrWholeStars : 5 - nrWholeStars

    function arrOf(nr) {
        const arr = []
        for (let i = 0; i < nr; i++) {
            arr.push(i)
        }
        return arr
    }

    return (
        <div className={'review-stars ' + (className || '')}>
            {arrOf(nrWholeStars).map((i) => (
                <Icon key={i} color="full">
                    star
                </Icon>
            ))}
            {arrOf(nrEmptyStars).map((i) => (
                <Icon color="empty" key={i}>
                    star
                </Icon>
            ))}

            {nrReviews > 0 && adjusted > 0 && !showOnlyStars && (
                <div className={`nr-reviews ${inline || alt ? 'inline' : ''}`}>
                    {alt ? (
                        <span>{nrReviews}</span>
                    ) : (
                        <span>
                            {avg.toFixed(1)} | <Msg id="nr_reviews" values={{ nr: nrReviews }} />
                        </span>
                    )}
                </div>
            )}
        </div>
    )
}

ReviewStars.propTypes = {
    avg: PropTypes.number,
    nrReviews: PropTypes.number,
    inline: PropTypes.bool,
    className: PropTypes.string,

    // show only nr of reviews
    alt: PropTypes.bool,
}

export default ReviewStars
