import { Col, Row, Section } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import { saveLabel } from '../../actions/labels'
import { saveStatus } from '../../actions/statuses'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import EntityComponent from '../services/EntityComponent'

class StatusEntity extends EntityComponent {
    constructor(props) {
        super(props)
    }

    editFields() {
        const { entity: status, categories } = this.props
        return {
            description: { type: 'text', msg: 'description' },
            category: {
                type: 'select',
                placeholder: 'Category',
                className: 'select-wide',
                options: categories.map((category) => {
                    return {
                        value: category,
                        title: category,
                    }
                }),
                isHidden: status._customCategory,
            },
            _customCategory: {
                type: 'boolean',
                msg: status._customCategory ? 'new' : '+ new',
            },
            customCategoryField: {
                type: 'text',
                msg: 'Category',
                name: 'category',
                jump: false,
                isHidden: !status._customCategory,
            },
            customTypeField: {
                type: 'text',
                msg: 'Type',
                name: 'type',
                jump: false,
                isHidden: !status._customType,
            },
            color: {
                type: 'color',
            },
            is_unique: {
                type: 'boolean',
                msg: 'Unique',
            },
            type: {
                msg: 'type',
            },
        }
    }

    render() {
        const { entity: label } = this.props

        return (
            <div onKeyPress={({ key, ctrlKey }) => ctrlKey && key === 'Enter' && this.save()}>
                <Row className="v-center between">
                    <Col className="grow"> {this.renderInput('description')}</Col>
                    <Col className="grow"> {this.renderInput('type')}</Col>
                    <Col className="grow"> {this.renderInput('is_unique')}</Col>
                </Row>

                <Section n>
                    <Row className="no-wrap v-center">
                        <Col c="auto">Category:</Col>
                        <Col c="auto">{this.renderInput('category')}</Col>
                        <Col c="auto">{this.renderInput('_customCategory')}</Col>
                        <Col c="auto">{this.renderInput('customCategoryField')}</Col>
                    </Row>
                    <Section n>{this.renderInput('color')}</Section>
                </Section>
                <Section n>
                    <button
                        className="btn cta"
                        disabled={label._saving || !label._isTouched}
                        onClick={this.save}>
                        {label._saving ? 'saving..' : 'save'}
                    </button>
                </Section>
            </div>
        )
    }
}

StatusEntity.propTypes = {
    entityId: PropTypes.any.isRequired,
}

export default EntityComponentContainer(StatusEntity, {
    store: 'statuses',
    name: 'statuses',
    saveFunc: saveStatus,
})
