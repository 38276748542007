import { Icon } from '@advanza/ui'
import React from 'react'
import style from './OrderButtonArrows.module.css'

const OrderButtonArrows = ({ clickUp, clickDown }) => {
    return (
        <div className={style.root}>
            <button onClick={clickUp}>
                <Icon name="keyboard_arrow_up" />
            </button>
            <button onClick={clickDown}>
                <Icon name="keyboard_arrow_down" />
            </button>
        </div>
    )
}

export default OrderButtonArrows
