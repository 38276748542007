import { fetchProvider } from 'actions/providers'
import FilteredPauseOverview from 'components/pauses/FilteredPauseOverview'
import PauseDateEdit from 'components/pauses/PauseDateEdit'
import { PauseTypeSet } from 'components/pauses/pauseEnums'
import { Provider } from 'interfaces/Providers'
import { useSelector } from 'react-redux'
import { RootState } from 'types/RootStateType'
import { useAppDispatch } from 'hooks/hooks'

interface Props {
    provider: Provider
}

const PauseDatesList = ({ provider }: Props) => {
    const dispatch = useAppDispatch()
    const { pauseReasons } = useSelector((state: RootState) => state.pauseReasons.entities)
    const { providersDates } = useSelector((state: RootState) => state.providers.entities)

    return (
        <FilteredPauseOverview
            pauseDates={
                provider?.providers_dates
                    ?.map((id: number) => providersDates[id])
                    .filter((providersDate) =>
                        [PauseTypeSet.RECEIVE, PauseTypeSet.BOTH].includes(providersDate.pause_type)
                    ) || []
            }
            addButton={
                <PauseDateEdit
                    key="add_new_providers_date"
                    addMode
                    provider={provider}
                    providerDateId={'new'}
                    onSucces={() => dispatch(fetchProvider(provider.service_provider_id, true))}
                    store="providers"
                />
            }
            getEditButton={(id) => (
                <PauseDateEdit
                    key={`edit_providers_date_${id}`}
                    provider={provider}
                    providerDateId={id}
                    onSucces={() => dispatch(fetchProvider(provider.service_provider_id, true))}
                    store="providers"
                />
            )}
            pauseReasons={pauseReasons}
        />
    )
}

export default PauseDatesList
