import React from 'react'
import { connect } from 'react-redux'
import { selectItems } from '../actions/lists'

function mapStateToProps(state, props) {
    const { entities: serviceEntities, isFetching, filters, result } = state.services
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey
    return {
        serviceEntities,
        pages: result[searchKey] || {},
        isFetching,
        filter,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        select: (ids, filterId) => dispatch(selectItems('services', ids, filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
