export function arrOf(nr) {
    const arr = []
    for (let i = 0; i < nr; i++) {
        arr.push(i)
    }
    return arr
}

export function formatMoney(amount) {
    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
    })
    return moneyFormatter.format(amount)
}

export const standardDeviation = (arr, usePopulation = false) => {
    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length
    return Math.sqrt(
        arr
            .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
            .reduce((acc, val) => acc + val, 0) /
            (arr.length - (usePopulation ? 0 : 1))
    )
}

export function getRangeFromObject(obj) {
    const keys = Object.keys(obj)
    let sum = 0
    let min = 100
    let max = 0
    for (let i = 0; i < keys.length; i++) {
        const value = parseInt(obj[keys[i]], 10)
        if (value < min) {
            min = value
        }
        if (value > max) {
            max = value
        }
        sum += value //don't forget to add the base
    }
    const range = max - min
    return {
        range,
        min,
        max,
    }
}
