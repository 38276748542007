import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { ScrollModal } from '@advanza/modal'
import { Button, Divider } from '@advanza/ui'
import { invalidateNotes } from 'actions/notes'
import { fetchProvider } from 'actions/providers'
import CheckButton from 'components/CheckButton'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { useAppDispatch } from 'hooks/hooks'

interface NewEntity {
    nr_leads?: number
    after_payed?: boolean
    enable_after_nr_leads_payed?: number
    comment?: string
    free_lead_type?: string | null
}

interface ErrorResponse {
    status: 'error'
    error: 'fields'
    nr_leads?: Record<string, string>
    comment?: Record<string, string>
    enable_after_nr_leads_payed?: Record<string, string>
}

export function useAddFreeLeads(providerId: number, initialNewEntity: NewEntity) {
    const dispatch = useAppDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [newEntity, setNewEntity] = useState<NewEntity>(initialNewEntity)
    const [errors, setErrors] = useState<ErrorResponse | null>(null)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const onChangeNewEntity = (changes: NewEntity) => setNewEntity({ ...newEntity, ...changes })

    const onOpen = () => {
        onChangeNewEntity(initialNewEntity)
        setIsOpen(true)
    }

    const onClose = () => setIsOpen(false)

    const onSave = () => {
        setIsSaving(true)
        return call('office/providers/add-free-leads/', {
            json: {
                service_provider_id: providerId,
                ...newEntity,
            },
        })
            .then(
                () => {
                    dispatch(fetchProvider(providerId, true))
                    dispatch(invalidateNotes())
                },
                (response) => {
                    setErrors(response)
                    return Promise.reject()
                }
            )
            .finally(() => {
                setIsSaving(false)
                setIsOpen(false)
            })
    }

    return { newEntity, onChangeNewEntity, isSaving, isOpen, onOpen, onClose, onSave, errors }
}

interface Props {
    providerId: number
}

const FreeLeadsButton = ({ providerId }: Props) => {
    const initialNewEntity = {
        nr_leads: 3,
        after_payed: false,
    }

    const { newEntity, onChangeNewEntity, isSaving, isOpen, onOpen, onClose, onSave, errors } =
        useAddFreeLeads(providerId, initialNewEntity)

    const onHandleChange = (e: ChangeEvent<HTMLInputElement>) =>
        onChangeNewEntity({ [e.target.name]: e.target.value })

    return (
        <>
            <Button onClick={onOpen} name="text">
                Give away free leads
            </Button>
            {/* @ts-ignore */}
            <ScrollModal
                isOpen={isOpen}
                onCloseModal={onClose}
                heading="Give away free leads"
                buttons={[
                    {
                        text: 'Cancel',
                        name: 'link',
                        func: onClose,
                    },
                    {
                        text: 'Save',
                        func: onSave,
                        disabled: isSaving,
                    },
                ]}>
                <InputField
                    name="nr_leads"
                    value={newEntity.nr_leads}
                    min={0}
                    max={100}
                    type="number"
                    error={errors?.nr_leads}
                    inputStyle={{ fontWeight: 900, fontSize: 20 }}
                    legend
                    placeholder="Nr of free leads"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                        e.key === 'Enter' && onSave()
                    }
                    onChange={onHandleChange}
                />
                <Divider m />
                <Row>
                    <Col xs={4}>
                        <CheckButton
                            onChange={onHandleChange}
                            value={!newEntity.after_payed}
                            checked={newEntity.after_payed}
                            name="after_payed"
                            msg="require x payed leads"
                        />
                    </Col>
                    <Col xs>
                        {newEntity.after_payed && (
                            <>
                                <InputField
                                    name="enable_after_nr_leads_payed"
                                    value={newEntity.enable_after_nr_leads_payed}
                                    min={0}
                                    max={100}
                                    autoFocus
                                    error={errors?.enable_after_nr_leads_payed}
                                    legend
                                    placeholder="nr of payed leads before using free leads"
                                    onChange={onHandleChange}
                                />
                            </>
                        )}
                    </Col>
                </Row>
                <Divider m />
                <InputField
                    name="comment"
                    value={newEntity.comment}
                    min={0}
                    max={100}
                    error={errors?.comment}
                    legend
                    placeholder="Comment (optional)"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                        e.key === 'Enter' && onSave()
                    }
                    onChange={onHandleChange}
                />
            </ScrollModal>
        </>
    )
}

export default FreeLeadsButton
