import React from 'react'
import * as ReactDOM from "react-dom"

export default class BodyPortal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.getElementById(props.elementId || 'portal_1');
    }


    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el,
        );
    }
}