import { Icon } from '@advanza/ui'
import { ChangeEvent, FocusEvent, MouseEvent, ReactNode, useRef, useState } from 'react'
import style from './inputField.module.css'
import { SelectSimpleChangeEvent } from './interfaces/SelectSimpleChangeEvent'

interface Option {
    name?: string
    value: string | number
}

interface Props {
    options: (string | number | Option)[]
    value?: string | number | null
    icon?: ReactNode
    hidePlaceholder?: boolean
    defaultErrorMsg?: string
    error?: string | boolean
    name?: string
    placeholder?: string
    legend?: boolean
    newStyle?: boolean
    disabled?: boolean
    alwaysControlValue?: boolean
    alwaysDropdown?: boolean
    emptyOption?: string
    hiddenOption?: string
    noBorder?: boolean
    selectTriangleFixZ?: boolean
    className?: string
    onChange: (event: SelectSimpleChangeEvent) => void
}

const SelectSimple = ({
    options,
    value: selected,
    icon,
    hidePlaceholder,
    defaultErrorMsg = 'Select an option',
    error,
    name,
    placeholder,
    legend = false,
    newStyle = false,
    disabled,
    alwaysControlValue = false,
    alwaysDropdown = true,
    emptyOption,
    hiddenOption = '',
    noBorder,
    selectTriangleFixZ = false,
    className: classNameProp = '',
    onChange,
}: Props) => {
    const [showingDropdown, setShowingDropdown] = useState(false)
    const selectRef = useRef<HTMLSelectElement>(null)

    const onHandleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value, name } = e.target
        if (value === '-') {
            onChange({ target: { name, value: null } })
        } else {
            onChange({
                target: {
                    name,
                    value: isNaN(Number(value)) ? value : parseInt(value),
                },
            })
        }
    }

    const onClick = (e: MouseEvent<HTMLSelectElement>) => {
        setShowingDropdown(!showingDropdown)
    }

    const onBlur = (e: FocusEvent<HTMLSelectElement>) => {
        setShowingDropdown(false)
    }

    const className = [
        selected ? style.focused : '',
        style.select,
        error ? style.hasError : '',
        hidePlaceholder ? style.hidePlaceholder : '',
        noBorder ? style.noBorder : '',
        legend ? style.hasLegend : '',
        newStyle ? style.newStyle : '',
        classNameProp,
    ].join(' ')

    const selectProps = {
        onChange: onHandleChange,
        onClick,
        onBlur,
        name,
        className: style.input,
        ref: selectRef,
        disabled,
        [alwaysControlValue ? 'value' : 'defaultValue']: selected ? selected : '-',
    }

    const selectTriangleClassName = [
        style.selectTriangle,
        selectTriangleFixZ ? style.selectTriangleFixZ : '',
    ].join(' ')

    const unpackOption = (
        option: string | number | Option
    ): { name: string | number; value: string | number } => {
        if (typeof option === 'object' && option !== null) {
            const { name, value } = option
            return { name: name ?? value, value }
        }
        return { name: option, value: option }
    }

    return (
        <div className={className}>
            {legend && <div className={style.legend}>{placeholder}</div>}
            {alwaysDropdown || options.length > 1 ? (
                <label className={style.label}>
                    {icon}
                    <select {...selectProps}>
                        {emptyOption && <option value="-">{emptyOption}</option>}
                        {hiddenOption && (
                            <option disabled hidden value="_hidden_">
                                {hiddenOption}
                            </option>
                        )}
                        {options.map((option, i) => (
                            <option key={i} value={unpackOption(option).value}>
                                {unpackOption(option).name}
                            </option>
                        ))}
                    </select>
                    {!legend && <div className={style.placeholder}>{placeholder}</div>}
                    {newStyle ? (
                        <Icon
                            classNameIcon={selectTriangleClassName}
                            name={showingDropdown ? 'expand_less' : 'expand_more'}
                        />
                    ) : (
                        <div className={selectTriangleClassName} />
                    )}
                </label>
            ) : options.length === 1 ? (
                <label className={[style.label, style.noDropdown].join(' ')}>
                    {unpackOption(options[0]).name}
                </label>
            ) : (
                ''
            )}
            {error && (
                <div className={style.error}>
                    {(typeof error === 'string' && error) || defaultErrorMsg || ''}
                </div>
            )}
        </div>
    )
}

export default SelectSimple
