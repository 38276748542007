import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'
import { normalize, schema } from 'normalizr'

export function fetchNotifications() {
    return function (dispatch, getState) {
        // if (getState().notifications.result.length > 0) {
        //     return Promise.resolve()
        // }
        return call('office/users/get-notifications').then((response) => {
            dispatch({ type: 'FETCH_NOTIFICATIONS' })
            const { result, entities } = normalize(response.items, [
                new schema.Entity(
                    'notifications',
                    {},
                    {
                        idAttribute: 'notification_id',
                    }
                ),
            ])
            dispatch({ type: 'RECEIVE_NOTIFICATIONS', entities, result })
        })
    }
}

export function saveUser(userId, params, name = 'users') {
    const { store, withEmailAddresses } = typeof params === 'string' ? { store: params } : params

    return function (dispatch, getState) {
        const entities = getState()[store].entities
        const user = { ...entities[name][userId] }

        if (withEmailAddresses) {
            const { emailAddresses = {} } = entities
            const email_addresses = (user.email_addresses || []).filter(
                (emailAddressId) => emailAddresses[emailAddressId].email
            )

            if (!user.email_notifications && email_addresses.length) {
                user.email_notifications = emailAddresses[email_addresses.shift()].email
            }

            user.email_addresses = email_addresses.map(
                (emailAddressId) => emailAddresses[emailAddressId]
            )

            dispatch(
                changeEntity({
                    store,
                    name,
                    key: user.id,
                    diff: {
                        email_notifications: user.email_notifications,
                        email_addresses,
                    },
                })
            )
        } else {
            delete user.email_addresses
        }

        return call(`office/users/save/${userId}`, { json: user })
    }
}

export function sendPasswordMail(email) {
    return function () {
        return call(`auth/password-reset`, { payload: { email } })
    }
}
