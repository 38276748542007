import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    ADMINUSERS_CHANGE_FILTER,
    ADMINUSERS_INVALIDATE,
    ADMINUSERS_RECEIVE_LIST,
    ADMINUSERS_REQUEST,
    RECEIVE_PERMISSIONS,
} from '../actions/adminUsers'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
    selectItems,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    CHANGE_ENTITY_ADMINUSERS: changeEntity,
    INVALIDATE_ADMINUSERS: invalidateFilters,
    ADMINUSERS_SELECT_ITEMS: selectItems,
    [ADMINUSERS_RECEIVE_LIST]: receiveList,
    [RECEIVE_PERMISSIONS]: (state, action) =>
        update(state, {
            permissions: { $set: action.permissions },
        }),
    [ADMINUSERS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [ADMINUSERS_INVALIDATE]: (state, action) =>
        update(state, {
            didInvalidate: { $set: true },
        }),
    [ADMINUSERS_CHANGE_FILTER]: changeFilter,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    permissions: [],
    filters: {},
}

export default createReducer(initialState, handlers)
