import { call } from '@advanza/api'
import { Row, Col } from '@advanza/grid'
import Modal from '@advanza/modal'
import { changeEntity } from '@advanza/redux_entity'
import { LinearLoader, Icon, Divider, Button } from '@advanza/ui'
import {
    changeAggregatedReviewsFilter,
    saveAggregatedReview,
    deleteSnippet,
} from 'actions/aggregatedReviews'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './snippetSelectionTool.module.css'

function useFetchSnippets(url) {
    const [snippets, setSnippets] = useState({})
    const [isFetching, setIsFetching] = useState(true)
    useEffect(() => {
        call('office/aggregated-reviews/get-scraped-snippets?url=' + url).then(
            ({ snippets }) => {
                setSnippets(snippets)
                setIsFetching(false)
            },
            () => setIsFetching(false)
        )
    }, [])

    const nrSnippets = Object.keys(snippets).reduce(
        (a, b) => ((snippets[b] && snippets[b].length) || 0) + a,
        0
    )
    return { isFetching, snippets, nrSnippets }
}

function useGetReviews(providerId) {
    const dispatch = useDispatch()
    const filterId = 'p_' + providerId
    useEffect(() => {
        dispatch(
            changeAggregatedReviewsFilter(filterId, {
                defaultOptions: { providerIds: [providerId] },
            })
        )
    }, [])
    const { entities, result, filters } = useSelector((state) => state.aggregatedReviews)
    const { aggregatedReviews, aggregatedReviewsSnippets } = entities
    const ids = (filters[filterId] && result[filters[filterId].searchKey][0]) || []
    let savedSnippets = []
    ids.forEach(
        (id) =>
            (savedSnippets = savedSnippets.concat(
                aggregatedReviews[id].aggregated_reviews_snippets.map(
                    (id) => aggregatedReviewsSnippets[id]
                )
            ))
    )
    return {
        ids,
        aggregatedReviews: ids.map((id) => aggregatedReviews[id]),
        savedSnippets,
    }
}

function _domainFromUrl(url) {
    const parts = url.split('.')
    let last = '.' + parts[parts.length - 1]
    let host = parts[parts.length - 2]
    if (host.indexOf('/') === host.length) {
        host = host.substr(0, host.length - 1)
    }
    if (last.indexOf('/') !== -1) {
        last = last.substr(0, last.indexOf('/'))
    }

    return host + last
}

function addSnippet(aggregatedReview, snippet) {
    return function (dispatch) {
        const entity = {
            aggregated_review_id: aggregatedReview.aggregated_review_id,
            snippet: snippet.review_description,
            pros: snippet.review_description_positive,
            cons: snippet.review_description_negative,
            first_name: snippet.review_author,
            source_url: snippet.response_url,
            review_date: snippet.review_publish_date,
            rating: parseFloat(snippet.review_rating.replace(',', '.')),
            _saving: true,
        }
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                store: 'aggregatedReviews',
                name: 'aggregatedReviewsSnippets',
                key: newId,
                diff: entity,
            })
        )
        dispatch(
            changeEntity({
                store: 'aggregatedReviews',
                name: 'aggregatedReviews',
                key: aggregatedReview.aggregated_review_id,
                diff: {
                    aggregated_reviews_snippets:
                        aggregatedReview.aggregated_reviews_snippets.concat(newId),
                },
            })
        )
        dispatch(saveAggregatedReview(aggregatedReview.aggregated_review_id))
    }
}

const SnippetSelectionTool = ({ provider }) => {
    const dispatch = useDispatch()
    const { snippets, nrSnippets } = useFetchSnippets(provider.website)
    const { isFetching, aggregatedReviews, savedSnippets } = useGetReviews(
        provider.service_provider_id
    )
    const [open, setOpenModal] = useState(false)
    const [moreSnippets, setMoreSnippets] = useState({})
    return (
        <div>
            <Button name="text" onClick={() => setOpenModal(true)}>
                {nrSnippets} snippets
            </Button>{' '}
            | {savedSnippets.length} saved
            <Modal closeOnOutsideClick open={open} close={() => setOpenModal(false)}>
                {Object.keys(snippets).map((sourceName) => {
                    const selection = moreSnippets[sourceName]
                        ? snippets[sourceName]
                        : [...snippets[sourceName]].splice(0, 5)
                    const onShowMore = () =>
                        setMoreSnippets({
                            ...moreSnippets,
                            [sourceName]: moreSnippets[sourceName] !== true,
                        })

                    return (
                        <div key={sourceName} className={style.root}>
                            {isFetching && <LinearLoader />}
                            <Divider m />
                            <h2>{sourceName}</h2>
                            <Divider m />
                            {selection.map((item, i) => {
                                const matchedSnippets = savedSnippets.filter(
                                    ({ snippet }) => snippet === item.review_description
                                )
                                const saved = matchedSnippets.length > 0 && matchedSnippets[0]
                                const aggregatedReview = aggregatedReviews.filter(
                                    ({ aggregated_reviews_source }) => {
                                        return (
                                            aggregated_reviews_source.url &&
                                            _domainFromUrl(aggregated_reviews_source.url) ===
                                                _domainFromUrl(sourceName)
                                        )
                                    }
                                )[0]

                                return (
                                    <section key={i} className={saved ? style.saved : ''}>
                                        <div>
                                            <b>{item.review_rating}</b> - {item.review_author} |{' '}
                                            {item.review_publish_date}
                                        </div>
                                        <p>{item.review_description}</p>
                                        <Row end="xs">
                                            <Col x>
                                                <Button
                                                    disabled={saved}
                                                    name="text"
                                                    onClick={() =>
                                                        dispatch(addSnippet(aggregatedReview, item))
                                                    }>
                                                    <Icon name={saved ? 'check' : 'add'} />
                                                    {saved._saving
                                                        ? '..saving'
                                                        : saved
                                                        ? 'added'
                                                        : 'add'}
                                                </Button>
                                                {saved && (
                                                    <button
                                                        onClick={() =>
                                                            dispatch(
                                                                deleteSnippet(
                                                                    saved.aggregated_reviews_snippet_id
                                                                )
                                                            )
                                                        }>
                                                        <Icon name="delete_forever" /> delete
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                    </section>
                                )
                            })}
                            <Button name="text" onClick={onShowMore}>
                                {moreSnippets[sourceName]
                                    ? 'less'
                                    : `more(${snippets[sourceName].length - 5})`}
                            </Button>
                        </div>
                    )
                })}
            </Modal>
        </div>
    )
}

SnippetSelectionTool.propTypes = {}

export default SnippetSelectionTool
