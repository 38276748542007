import queryString from "query-string"
import {call} from "./Api"

global.fetch = require("isomorphic-fetch")

export function authorize(request) {
    return call('Auth/login', {
        method : 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body   : queryString.stringify({...request}),
    })
}

/**
 * Login with token instead of credentials.
 */
export function tokenAuthorize(token) {
    const requestOptions = {
        method : 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body   : queryString.stringify({remember_me: '1'}),
    }
    return call('Auth/token-auth/' + token, requestOptions)
}

export function logout() {
    return call('Auth/logout')
}

export function passwordReset(email) {
    return call('Auth/password-reset', {
        method : 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body   : queryString.stringify({email: email}),
    })
}

export function finishRegistration(token, password) {
    const requestOptions = {
        method : 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body   : queryString.stringify({token, password}),
    }
    return call('auth/finish-registration', requestOptions)
}

export function changePassword(vars) {
    let data = {
        password        : vars.password,
        password_confirm: vars.password_confirm,
    }
    let url = 'Auth/change-password'
    if (vars.current_password) {
        data.current_password = vars.current_password
    }
    if (vars.jwtToken) {
        data.token = vars.jwtToken
        url = 'Auth/change-password-jwt'
    }
    const requestOptions = {
        method : 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body   : queryString.stringify(data),
    }
    return call(url, requestOptions)
}

export function validateToken(token) {
    return call('Auth/validate-token/' + token, {method: 'post'})
}

export function requestNewEmail(values) {
    const requestOptions = {
        method : 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body   : queryString.stringify(values)
    }
    return call(`Auth/request-new-email`, requestOptions)

}

export function validateNewEmail(token) {
    return call(`Auth/change-email/${token}`, {method: 'post'})
}