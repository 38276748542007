import { createReducer, getUpdater } from '@advanza/advanza_generic'

const update = getUpdater()

const handlers = {
    EMAIL_STATS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    EMAIL_STATS_RECEIVE: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            emailStats: { $set: action.emailStats },
            history: { $set: action.emailStatsHistory },
        }),
}

const initialState = {
    isFetching: false,
    emailStats: {},
}

export default createReducer(initialState, handlers)
