import React, { Fragment, useEffect, useState } from 'react'
import { call } from '@advanza/api'
import { Button, Divider, Icon, LoadingDots, PreIcon } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import { InputField } from '@advanza/input'
import Legend from 'components/misc/Legend'

function useServicesPlacesContents(service, place) {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)
    const fetch = async () => {
        const { items } = await call(
            `office/services/services-places-seo-contents/${service}/${place}`
        )
        setItems(items)
        setLoading(false)
    }
    useEffect(() => {
        setLoading(true)
        fetch()
    }, [service, place])

    function edit(index, changes) {
        const newItems = [...items]
        newItems[index] = { ...newItems[index], ...changes }
        setItems(newItems)
    }

    function save(index) {
        const item = items[index]
        setLoading(true)
        call(`office/services/services-places-seo-contents/${service}/${place}`, {
            method: 'POST',
            json: { items: [item] },
        }).then(() => {
            setLoading(false)
            fetch()
        })
    }

    function createNew(values = {}) {
        const newItems = [...items]
        newItems.push({
            content_key: '',
            content_value_text: 'tekst',
            content_value_int: 1,
            ...values,
        })
        setItems(newItems)
    }

    function deleteItem(index) {
        const newItems = [...items]
        const item = newItems[index]
        if (item.seo_content_id) {
            call(`office/services/delete-services-places-seo-content-item/`, {
                payload: { seo_content_id: item.seo_content_id },
            }).then(() => {
                setLoading(false)
                fetch()
            })
        } else {
            newItems.splice(index, 1)
            setItems(newItems)
        }
    }

    return { items, loading, edit, save, createNew, deleteItem }
}

const ServicesPLacesContentBlock = ({
    content_key,
    isNew = false,
    content_value_text,
    content_value_int,
    onChange,
    save,
    loading = false,
    deleteItem,
}) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onChange({ [name]: value })
    }
    const bothEmpty = !content_value_text && !content_value_int
    return (
        <div>
            <AutoRow>
                {isNew ? (
                    <>
                        <b>Key:</b>
                        <InputField
                            name="content_key"
                            inputPlaceholder="Key"
                            onChange={handleInputChange}
                            value={content_key}
                        />
                    </>
                ) : (
                    <Legend>{content_key}</Legend>
                )}
            </AutoRow>
            <Divider />
            {content_value_text && content_key === 'top10_p' && (
                <i>
                    You can use limited html markup: {'<h2>header</h2>'}
                    {' or <a href="/makelaar">Makelaar</a>'}
                </i>
            )}
            <AutoRow alignBottom>
                {(content_value_text || bothEmpty) && (
                    <InputField
                        style={{ minWidth: '80%' }}
                        inputStyle={{
                            minHeight:
                                content_key === 'title'
                                    ? 40
                                    : content_key === 'top10_p'
                                    ? 350
                                    : 150,
                        }}
                        inputPlaceholder="Content text"
                        onChange={handleInputChange}
                        autoFocus
                        value={content_value_text}
                        name="content_value_text"
                        textArea
                        type="text"
                        // handle enter + cntrl
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
                                save()
                            }
                        }}
                    />
                )}
                {(content_value_int || bothEmpty) && (
                    <InputField
                        name="content_value_int"
                        inputPlaceholder="Content number"
                        onChange={handleInputChange}
                        value={content_value_int}
                        type="number"
                    />
                )}
                <AutoRow>
                    <Button onClick={save}>
                        {loading ? <LoadingDots color="#ffffff" /> : 'Save'}
                    </Button>
                    <Button name="text" onClick={deleteItem}>
                        <Icon name="delete_forever" />
                    </Button>
                </AutoRow>
            </AutoRow>
        </div>
    )
}
const ServicesPlacesContentBlocks = ({ service, place }) => {
    const { items, loading, edit, createNew, save, deleteItem } = useServicesPlacesContents(
        service,
        place
    )
    return (
        <div>
            {items.map((item, i) => (
                <Fragment key={i}>
                    <ServicesPLacesContentBlock
                        {...item}
                        loading={loading}
                        isNew={!item.seo_content_id}
                        save={() => save(i)}
                        deleteItem={() => deleteItem(i)}
                        onChange={(changes) => edit(i, changes)}
                    />
                    <Divider xl />
                </Fragment>
            ))}
            <Divider m />
            <AutoRow>
                <Button
                    disabled={items.filter((item) => item.content_key === 'top10_p').length > 0}
                    name="text"
                    onClick={() =>
                        createNew({
                            content_key: 'top10_p',
                            content_value_text: '-',
                            content_value_int: null,
                        })
                    }>
                    <PreIcon primColor icon="add">
                        Top 10 paragraph
                    </PreIcon>
                </Button>
                <Button
                    disabled={items.filter((item) => item.content_key === 'title').length > 0}
                    name="text"
                    onClick={() =>
                        createNew({
                            content_key: 'title',
                            content_value_text: '-',
                            content_value_int: null,
                        })
                    }>
                    <PreIcon primColor icon="add">
                        Title
                    </PreIcon>
                </Button>
                <Button
                    disabled={
                        items.filter((item) => item.content_key === 'seo_description').length > 0
                    }
                    name="text"
                    onClick={() =>
                        createNew({
                            content_key: 'seo_description',
                            content_value_text: '-',
                            content_value_int: null,
                        })
                    }>
                    <PreIcon primColor icon="add">
                        Seo description
                    </PreIcon>
                </Button>
                <Button name="text" onClick={() => createNew()}>
                    <PreIcon primColor icon="add">
                        Custom
                    </PreIcon>
                </Button>
            </AutoRow>
        </div>
    )
}

export default ServicesPlacesContentBlocks
