import { S3_PUBLIC } from '@advanza/constants'
import { Col, Row } from '@advanza/grid'
import { FileDropZone } from '@advanza/input'
import { Button, Divider, FixedWarningPopup, Icon } from '@advanza/ui'
import { onFilesRejected } from 'actions/files'
import { deleteLicence, saveLicence } from 'actions/licences'
import EntityComponent from 'components/services/EntityComponent'
import ServicesSelect from 'components/services/ServicesSelect'
import React from 'react'
import { EntityComponentContainer } from '@advanza/redux_entity'
import { connect } from 'react-redux'

class LicencesEntity extends EntityComponent {
    constructor(props) {
        super(props)
        this.onChangeService = this.onChangeService.bind(this)
        this.onDropFiles = this.onDropFiles.bind(this)
        this.getUrl = this.getUrl.bind(this)
        this.state = {}
    }

    onDropFiles(files) {
        const Reader = new FileReader()
        Reader.onloadend = (e) => {
            const { result } = e.target
            this.onChangeEntity({
                file: {
                    preview: result,
                    base64: result.substr(result.indexOf(',') + 1),
                    filename: files[0].name,
                },
            })
        }
        Reader.readAsDataURL(files[0])
    }

    editFields() {
        return {
            description: {
                type: 'text',
                placeholder: 'description',
            },
            name: {
                type: 'text',
                placeholder: 'name',
            },
            url: {
                type: 'text',
                placeholder: 'url',
            },
            abbreviation: {
                type: 'text',
                placeholder: 'abbrev.',
            },
            is_filter: {
                type: 'boolean',
                msg: 'Is filter',
            },
            seo_text: {
                type: 'text',
                textArea: true,
                placeholder: 'SEO text',
            },
            score_points: {
                type: 'number',
                placeholder: 'Score (default=0.5)',
            },
        }
    }

    getUrl() {
        const { entity } = this.props
        if (entity.file && entity.file.preview) {
            return entity.file.preview
        } else {
            return S3_PUBLIC() + `services/licences/${entity.icon_filename}`
        }
    }

    onChangeService(services) {
        this.onChangeEntity({ services })
    }

    render() {
        const { entity } = this.props
        return (
            <div>
                <Row middle="xs">
                    <Col x>
                        <FileDropZone
                            style={{ width: 100 }}
                            allowedTypes={['image']}
                            multiple={false}
                            maxFiles={1}
                            onFilesAccepted={this.onDropFiles}
                            onFilesRejected={onFilesRejected}>
                            <div
                                style={{
                                    background: `url(${this.getUrl()}) no-repeat center center /cover`,
                                    width: 100,
                                    border: '3px dotted #dadada',
                                    padding: 6,
                                    height: 50,
                                }}>
                                <Icon name="dirty_lens" />
                            </div>
                        </FileDropZone>
                    </Col>
                    <Col xs>{this.renderInput('name')}</Col>
                    <Col xs>{this.renderInput('abbreviation')}</Col>
                    <Col xs>
                        <ServicesSelect
                            multiple={true}
                            value={entity.services}
                            onChangeValue={this.onChangeService}
                        />
                    </Col>
                </Row>
                <Divider m />
                <Row>
                    <Col xs>{this.renderInput('description')}</Col>
                    <Col xs>{this.renderInput('url')}</Col>
                    <Col xs>{this.renderInput('score_points')}</Col>
                    <Col x>{this.renderInput('is_filter')}</Col>
                </Row>
                <Divider m />

                {this.renderInput('seo_text')}

                <Divider m />

                <Row between="xs">
                    <Col x>
                        <Button
                            disabled={!entity._isTouched}
                            onClick={() =>
                                this.save().then(
                                    () => {},
                                    (response) => {
                                        this.setState({
                                            showError: Date.now(),
                                            errorMsg: JSON.stringify(response, null, 2),
                                        })
                                    }
                                )
                            }>
                            {entity._saving ? '..bezig' : 'Opslaan'}
                        </Button>
                    </Col>
                    <Col x>
                        <Button name="delete" onClick={this.delete}>
                            {entity._error || 'delete'}
                        </Button>
                    </Col>
                </Row>
                <FixedWarningPopup red show={this.state.showError}>
                    {this.state.errorMsg}
                </FixedWarningPopup>
            </div>
        )
    }
}

LicencesEntity.propTypes = {}

export default EntityComponentContainer(
    LicencesEntity,
    {
        store: 'licences',
        name: 'licences',
        saveFunc: saveLicence,
        deleteFunc: deleteLicence,
        mapDispatchToProps: (dispatch) => {
            return {
                onFilesRejected: (files) => dispatch(onFilesRejected(files)),
            }
        },
    },
    connect
)
