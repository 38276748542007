import React from "react"
import PropTypes from "prop-types"
import {APP} from "../src/services/constants"
import {Icon} from "./Icon"
const Avatar = ({user, imgSrc, className, placeholder, cnt, children, useLetterColor, sizePx}) => {
    const S3_HER_USERS = APP.S3_USERS()
    const firstLetter = !placeholder && user && user.username && user.username.charAt(0)
    const getLetterColor = char => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
        for (let i = 0; i < 26; i++) {
            if (char && char.toUpperCase() === alphabet[i]) {
                const sat = i < 11 ? '51' : '42'
                return `hsl(${13.846 + (Math.round(13.846 * (i+1)))}, ${sat}%, 58%)`
            }
        }
        return 'rgb(211, 219, 223)'
    }
    const imgSrcUser = imgSrc || (user && user.avatar_filename ? `${S3_HER_USERS}${user.id}/${user.avatar_filename}` : false)
    const alt = !imgSrcUser && (firstLetter || <Icon color="grey">person</Icon>)
    const color = getLetterColor(firstLetter)
    const style = alt && useLetterColor && {
        backgroundColor: color,
        color: 'rgba(255,255,255,0.9)',
        fontWeight:'bold'
    } || {}
    if (sizePx) {
        style.width = sizePx
        style.height = sizePx
    }
    return <div className="pos-rel">
        <div className={`avatar ${className || ''}`} style={style}>
            {imgSrcUser ? <img src={imgSrcUser}/> : alt}
            {children && <div className="children">{children}</div>}
        </div>
        {cnt > 0 && <span className="cnt">{cnt}</span>}
    </div>

}

Avatar.propTypes = {
    user       : PropTypes.object,
    imgSrc     : PropTypes.string,
    className  : PropTypes.string,
    placeholder: PropTypes.any,
    cnt        : PropTypes.any,
    useLetterColor        : PropTypes.bool,
}

export default Avatar