import { call } from '@advanza/api'
import { changeEntity } from 'actions/entities'

export const addFranchisesLicence = (franchiseId) => (dispatch) => {
    const franchisesLicenceIdNew = Math.random().toString(36).substr(2, 5)

    dispatch(
        changeEntity({
            store: 'providers',
            name: 'franchisesLicences',
            key: franchisesLicenceIdNew,
            diff: { franchise_id: franchiseId, _open: true },
        })
    )

    dispatch(changeFranchisesLicenceId(null, franchisesLicenceIdNew))
    return Promise.resolve()
}

export const saveFranchisesLicence = (franchisesLicenceId) => (dispatch, getState) => {
    const isNew = typeof franchisesLicenceId === 'string'
    const franchisesLicence = getState().providers.entities.franchisesLicences[franchisesLicenceId]
    return call('office/franchises-licences/save/' + (isNew ? '' : franchisesLicenceId), {
        json: franchisesLicence,
    }).then(
        ({ franchisesLicenceIdNew }) =>
            isNew &&
            dispatch(changeFranchisesLicenceId(franchisesLicenceId, franchisesLicenceIdNew))
    )
}

export const deleteFranchisesLicence = (franchisesLicenceId) => (dispatch) => {
    const isNew = typeof franchisesLicenceId === 'string'
    return isNew
        ? dispatch(changeFranchisesLicenceId(franchisesLicenceId, null))
        : call('office/franchises-licences/delete/' + franchisesLicenceId, { method: 'post' }).then(
              () => dispatch(changeFranchisesLicenceId(franchisesLicenceId, null))
          )
}

export const changeFranchisesLicenceId =
    (franchisesLicenceId, franchisesLicenceIdNew) => (dispatch, getState) => {
        const franchisesLicence =
            getState().providers.entities.franchisesLicences[
                franchisesLicenceId || franchisesLicenceIdNew
            ]
        const franchise = getState().providers.entities.providers[franchisesLicence.franchise_id]

        if (franchisesLicenceId && franchisesLicenceIdNew) {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'franchisesLicences',
                    key: franchisesLicenceId,
                    newKey: franchisesLicenceIdNew,
                    diff: { _saving: false },
                    dontTouch: true,
                })
            )
        }

        dispatch(
            changeEntity({
                store: 'providers',
                name: 'providers',
                key: franchisesLicence.franchise_id,
                diff: {
                    franchises_licences: franchise.franchises_licences
                        .filter(
                            (franchisesLicenceIdOther) =>
                                franchisesLicenceIdOther !== franchisesLicenceId
                        )
                        .concat(franchisesLicenceIdNew ? franchisesLicenceIdNew : []),
                },
            })
        )

        return Promise.resolve()
    }
