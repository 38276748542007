import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, MaxWidth } from '@advanza/ui'
import MonthPicker from 'components/billing/MonthPicker'
import DatePicker from 'components/DatePicker'
import FlexTable from 'components/FlexTable/FlexTable'
import AutoRow from 'components/misc/AutoRow'
import A from 'components/ui/A'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import Page from 'components/ui/Page'
import { useOpenCharges } from 'hooks/billingHooks'
import React, { Fragment, useState } from 'react'

const definitions = {
    service_provider_id: {
        name: '#',
    },
    billingFranchiseId: {
        name: 'Franchise',
    },
    name: {
        name: 'Name',
    },
    nrCharges: {
        name: 'nr charges',
    },
    totalAmount: {
        name: 'Total amount',
    },
    mandateId: {
        name: 'mandate',
    },
}

function createRows(values) {
    return values.map((row) => {
        return {
            cols: Object.keys(definitions).map((id) => {
                switch (id) {
                    case 'name':
                        return (
                            <A to={`/service/providers/provider/${row.service_provider_id}`}>
                                {row[id]}
                            </A>
                        )
                    case 'hasPaymentInfo':
                        return row[id] === '1' ? '' : 'No payment info!'
                    case 'totalAmount':
                        return parseFloat(row[id]) > 4999.99 ? (
                            <span>{row[id]} 🤑🤑🤑🤑</span>
                        ) : (
                            row[id]
                        )
                    default:
                        return row[id]
                }
            }),
            backgroundColor: row.totalAmount > 4999.99 && '#ffd7003d',
        }
    })
}

const OpenChargesPage = ({}) => {
    const {
        directDebit = [],
        manualTransfer = [],
        noData = [],
        isFetching,
        setMonth,
        isGenerating,
        onGenerateInvoices,
        aboutMonth,
        dates,
        setDates,
        clearErrors,
        responseError,
        errors = [],
    } = useOpenCharges()
    const [customMode, setCustomMode] = useState(dates.startDate)
    return (
        <Page>
            <Divider l />
            <MaxWidth maxWidth={900}>
                <AutoRow>
                    <button onClick={() => setCustomMode(customMode !== true)}>
                        {customMode ? 'month mode' : 'custom mode'}
                    </button>
                    <MonthPicker value={aboutMonth} onChange={setMonth} nrMonths={24} />
                    {customMode && (
                        <Fragment>
                            <DatePicker
                                onChange={(e) => setDates({ ...dates, startDate: e.target.value })}
                                format="YYYY-MM-DD"
                                value={dates.startDate}
                            />
                            <DatePicker
                                onChange={(e) => setDates({ ...dates, endDate: e.target.value })}
                                format="YYYY-MM-DD"
                                value={dates.endDate}
                            />
                            {(dates.startDate || dates.endDate) && (
                                <button onClick={() => setDates({})}>clear</button>
                            )}
                        </Fragment>
                    )}

                    <Button onClick={onGenerateInvoices} subtext={responseError}>
                        {responseError
                            ? 'error'
                            : isGenerating
                            ? '..generating'
                            : 'Generate invoices'}
                    </Button>
                </AutoRow>
                <Divider l />
                <LoadBoxWrapper isLoading={isFetching || isGenerating}>
                    {directDebit.length > 0 && (
                        <Fragment>
                            <b>Direct debit</b>
                            <Divider />
                            <FlexTable rows={createRows(directDebit)} definitions={definitions} />
                            <Divider l />
                        </Fragment>
                    )}
                    {manualTransfer.length > 0 && (
                        <Fragment>
                            <b>Manual transfer</b>
                            <Divider />
                            <FlexTable
                                rows={createRows(manualTransfer)}
                                definitions={definitions}
                            />
                            <Divider l />
                        </Fragment>
                    )}
                    {noData.length > 0 && (
                        <Fragment>
                            <b style={{ color: 'red' }}>No payment info!</b>
                            <Divider />
                            <FlexTable rows={createRows(noData)} definitions={definitions} />
                        </Fragment>
                    )}
                </LoadBoxWrapper>
                <Modal open={errors && errors.length > 0} close={clearErrors}>
                    {errors.map((error) => {
                        return (
                            <Row key={error}>
                                <Col x>
                                    <Icon name="warning" />
                                </Col>
                                <Col x>{error}</Col>
                            </Row>
                        )
                    })}
                </Modal>
            </MaxWidth>
        </Page>
    )
}

export default OpenChargesPage
