import { PreIcon } from '@advanza/ui'
import TableList from 'components/TableList'
import PauseDateEdit from 'components/pauses/PauseDateEdit'
import A from 'components/ui/A'
import { getDate } from 'date'
import { differenceInCalendarDays, format } from 'date-fns'
import { DAY_SCHEMES, PAUSE_TYPES, useFilterProvidersDates } from 'hooks/providersDatesHooks'
import { getFlag } from 'misc/flags'

const ActivePausesList = ({ filterId, selectable }) => {
    const { filter, providersDatesEntities, pages, isFetching, onInvalidateFilter } =
        useFilterProvidersDates(filterId)
    const { providers, providersDates, pauseReasons, users } = providersDatesEntities
    const today = new Date()

    const definitions = {
        id: { name: '#' },
        providerId: { name: 'providerId' },
        businessName: { name: 'business name' },
        created: { name: 'created' },
        user: { name: 'user' },
        pausePeriod: { name: 'pause period' },
        daysLeft: { name: 'days left' },
        pauseReason: { name: 'pause reason' },
        pauseType: { name: 'pause type' },
        edit: { name: 'edit', preventRedirect: true },
    }

    const getUrl = (providerId) => `/service/providers/provider/${providerId}/company`

    const renderUser = (userId) => {
        const user = users && users[userId]
        return (
            <>
                {user.first_name} {user.last_name}
            </>
        )
    }

    const renderRow = (id) => {
        const providersDate = providersDates[id]

        return {
            url: `/service/providers/provider/${providersDate.service_provider_id}`,
            cols: renderColumns(providersDate),
            id,
        }
    }

    const renderColumns = (providersDate) => {
        const provider = providers && providers[providersDate.service_provider_id]
        const pauseReason = (pauseReasons && pauseReasons[providersDate.pause_reason]) || {}

        const from = new Date(providersDate.date_from)
        const to = new Date(providersDate.date_to)

        return [
            <small className="c-grey">#{providersDate.provider_date_id}</small>,
            <small>
                <A className="classic" to={getUrl(provider.service_provider_id)}>
                    #{provider.service_provider_id}
                </A>
            </small>,
            <small>
                {getFlag(provider.country_code)}{' '}
                <A className="classic" to={getUrl(provider.service_provider_id)}>
                    {provider.business_name}
                </A>
            </small>,
            <small>{format(getDate(providersDate.created), 'yyyy-MM-dd HH:mm')}</small>,
            <small>{renderUser(providersDate.admin_user_id || provider.user)}</small>,
            <small>
                {format(from, 'dd-MMM')} - {format(to, 'dd-MMM')}
            </small>,
            <small>
                {providersDate.day_scheme === 0 ? (
                    `${differenceInCalendarDays(to, today)} days left`
                ) : (
                    <>
                        <PreIcon icon="repeat">{DAY_SCHEMES[providersDate.day_scheme]}</PreIcon>
                    </>
                )}
            </small>,
            <small>{pauseReason.reason}</small>,
            <small>{PAUSE_TYPES[providersDate.pause_type]}</small>,
            <small>
                <PauseDateEdit
                    key={providersDate.provider_date_id}
                    provider={provider}
                    providerDateId={providersDate.provider_date_id}
                    onSucces={onInvalidateFilter}
                    store="providersDates"
                />
            </small>,
        ]
    }

    return (
        <TableList
            isFetching={isFetching}
            filter={filter}
            pages={pages}
            selectable={selectable}
            renderRow={renderRow}
            definitions={definitions}
        />
    )
}

export default ActivePausesList
