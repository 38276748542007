import { Select, WithIcon, APP } from '@advanza/advanza_generic'
import LicencesContainer from 'containers/LicencesContainer'
import React from 'react'

class LicencesSelect extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.fetchLicences()
    }

    render() {
        const { licenceIds, licences, ...rest } = this.props
        return (
            <Select
                multiple
                optionKeys={{ msgKey: 'abbreviation', valueKey: 'licence_id' }}
                options={licenceIds.map((id) => licences[id])}
                {...rest}
            />
        )
    }
}

LicencesSelect.propTypes = {}

export default LicencesContainer(LicencesSelect)
