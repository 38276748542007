import { Divider, FixedWarningPopup, LinearLoader, MaxWidth, SubTitle } from '@advanza/ui'
import AnimatedPages from 'components/ui/AnimatedPages'
import ErrorAlert from 'components/ui/ErrorAlert'
import Page from 'components/ui/Page'
import ProgressBar from 'components/ui/ProgressBar'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import WizardFooter from './WizardFooter'
import style from './wizardPage.module.css'
import WizardStep from './WizardStep'

const TRANS = {
    EN: {
        btn_back: 'Back',
        btn_previous: 'Previous',
        btn_next: 'Next',
        btn_finish: 'Finish',
        wizard_progress_format: 'step {{step}} of {{steps}}',
        wizard_progress_last: 'Completed!',
        default_error_message: 'Something went wrong',
        page_num: 'Step {{pageNum}}',
    },
    NL: {
        btn_back: 'Terug',
        btn_previous: 'Vorige',
        btn_next: 'Verder',
        btn_finish: 'Voltooien',
        wizard_progress_format: 'stap {{step}} van {{steps}}',
        wizard_progress_last: 'Voltooid!',
        default_error_message: 'Er is iets misgegaan',
        page_num: 'Stap {{pageNum}}',
    },
}

const WizardPage = ({
    className = '',
    pages,
    url,
    finishUrl,
    lang = 'EN',
    isBusy = false,
    maxWidth = 674,
    progressLast = '',
    header = undefined,
    backText = undefined,
    nextText = undefined,
    backOnFirst = true,
}) => {
    const t = (key, pars = {}) => {
        let str = TRANS[lang][key] || key
        Object.keys(pars).forEach((par) => (str = str.replaceAll(`{{${par}}}`, pars[par])))
        return str
    }

    const history = useHistory()
    const params = useParams()
    const [errors, setErrors] = useState({})
    const [saveError, setSaveError] = useState(undefined)
    const [currentNextKey, setCurrentNextKey] = useState(undefined)
    const [backUrl, setBackUrl] = useState(undefined)

    useEffect(() => {
        setBackUrl(window.previousRoute)
    }, [])

    const currentPageKey = (params.page || '').replaceAll('-', '_')
    const currentPage = pages[currentPageKey]
    const currentPageIndex = Object.keys(pages).indexOf(currentPageKey)
    const isFirstPage = currentPageIndex === 0
    const isLastPage = currentPageIndex === Object.keys(pages).length - 1

    if (!currentPage) {
        return <Redirect to={url + '/' + Object.keys(pages)[0]} />
    }

    const isBusyCombined = isBusy || currentPage.isBusy || currentNextKey || currentNextKey === ''

    const changePage = (delta, extraNextKey = undefined) => {
        if (isBusyCombined) {
            return
        }

        const shouldGoNext = delta > 0
        shouldGoNext && setCurrentNextKey(extraNextKey)
        setTimeout(
            () => {
                const newIndex = currentPageIndex + delta
                const page =
                    (delta < 0 && params.backPage && params.backPage.replace(/-/g, '_')) ||
                    Object.keys(pages)[newIndex]

                if (page) {
                    history.push(url + '/' + page.replace(/_/g, '-'))
                } else if (newIndex < 0 && backUrl) {
                    history.push(backUrl)
                } else {
                    history.push(finishUrl)
                }

                shouldGoNext && setCurrentNextKey(undefined)
            },
            shouldGoNext ? 500 : 0
        )
    }

    const onNext = (extraNextKey = '') => {
        if (isBusyCombined) {
            return
        }

        const newErrors = currentPage.validate
            ? currentPage.validate(currentPageKey, extraNextKey, currentPage.extraNextButtons || {})
            : {}
        setErrors(newErrors)

        if (!Object.keys(newErrors).length) {
            setCurrentNextKey(extraNextKey)
            const saveFunc = currentPage.save || (() => Promise.resolve())
            saveFunc(currentPageKey, extraNextKey, currentPage.extraNextButtons || {})
                .then(({ moveNext = true } = {}) => {
                    setCurrentNextKey(undefined)

                    if (moveNext) {
                        changePage(1, extraNextKey)
                    }
                })
                .catch((reason) => {
                    setCurrentNextKey(undefined)

                    if (reason !== 403) {
                        setSaveError(Date.now())
                    }
                })
        }
    }

    return (
        <Page grey newStyle className={className}>
            <Divider l />
            <Divider xs />
            {isBusyCombined && <LinearLoader fixed />}
            <div className={[style.loadBox, isBusyCombined ? style.loading : ''].join(' ')}>
                <MaxWidth maxWidth={maxWidth}>
                    <SubTitle bold>
                        {isLastPage && currentPage.isSpecialLast
                            ? progressLast || t('wizard_progress_last')
                            : t('wizard_progress_format', {
                                  step: currentPageIndex + 1,
                                  steps: Object.keys(pages).length,
                              })}
                    </SubTitle>
                    <Divider m />
                    <ProgressBar
                        percentage={((currentPageIndex + 1) / Object.keys(pages).length) * 100}
                        newStyle
                    />
                    {header && (
                        <>
                            <Divider ml />
                            <div>{header}</div>
                        </>
                    )}
                    <Divider m />
                </MaxWidth>
                <AnimatedPages page={currentPageIndex + 1}>
                    {Object.keys(pages).map((p) => (
                        <WizardStep key={p} maxWidth={maxWidth} {...pages[p]} />
                    ))}
                </AnimatedPages>
            </div>
            <div>
                <ErrorAlert errors={errors} lang={lang} />
                <FixedWarningPopup fixed show={saveError}>
                    {t('default_error_message')}
                </FixedWarningPopup>
                <WizardFooter
                    maxWidth={maxWidth}
                    footerStyle={currentPage.footerStyle}
                    disabled={isBusyCombined}
                    checkIsValid={
                        currentPage.autoValidate && currentPage.validate
                            ? (extraNextKey) =>
                                  !Object.keys(currentPage.validate(currentPageKey, extraNextKey))
                                      .length
                            : undefined
                    }
                    currentNextKey={currentNextKey}
                    pageNumStr={t('page_num', { pageNum: currentPageIndex + 1 })}
                    pageDesc={currentPage.pageDesc}
                    createFooterComponent={currentPage.createFooterComponent}
                    backText={
                        currentPage.backText ??
                        backText ??
                        (isFirstPage || params.backPage ? t('btn_back') : t('btn_previous'))
                    }
                    extraNextButtons={currentPage.extraNextButtons}
                    nextText={
                        currentPage.nextText ??
                        nextText ??
                        (isLastPage ? t('btn_finish') : t('btn_next'))
                    }
                    onBack={
                        currentPage.onBack ||
                        (!isFirstPage || backOnFirst ? () => changePage(-1) : undefined)
                    }
                    onExtraNext={(key) => onNext(key)}
                    onNext={onNext}
                />
            </div>
        </Page>
    )
}

export default WizardPage
