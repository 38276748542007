import TableList from 'components/TableList'
import { useList } from 'hooks/listHooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import bansSchema from 'schemes/bansSchema'

export function useBansList(filterId = 'all') {
    const { countries } = useSelector((state) => state.countries)
    return useList(
        filterId,
        {
            url: 'office/bans/get-list',
            schema: bansSchema,
            reducerKey: 'bans',
        },
        { countries, sorting: ['ban_id', 'DESC'] }
    )
}

const BansList = ({ filterId = 'all' }) => {
    const {
        entities: { bans = {} } = {},
        extra: { definitionsBans = {} } = {},
        ...list
    } = useBansList(filterId)

    const renderRow = (banId) => {
        const ban = bans[banId] || {}
        return {
            cols: Object.keys(definitionsBans).map((key) => {
                if (key === 'service_provider_id' && ban.service_provider_id) {
                    return (
                        <Link
                            title="To pro"
                            className="plain"
                            to={`/service/providers/provider/${ban.service_provider_id}`}>
                            {ban.service_provider_id}
                        </Link>
                    )
                }

                return ban[key]
            }),
        }
    }

    return (
        <TableList definitions={definitionsBans} renderRow={renderRow} addListFeatures {...list} />
    )
}

export default BansList
