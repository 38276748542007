import React from 'react'
import { connect } from 'react-redux'
import { selectItems } from '../actions/lists'

function mapStateToProps(state, props) {
    const { entities: providerEntities, result, isFetching, filters } = state.providers
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey
    const services = state.services.entities.services

    return {
        providerEntities,
        pages: result[searchKey] || {},
        filter,
        services,
        isFetching,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        select: (ids, filterId) => dispatch(selectItems('providers', ids, filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
