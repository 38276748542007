import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { LICENCES_RECEIVE, LICENCES_REQUEST, LICENCES_CHANGE_FILTER } from 'actions/licences'
import { receiveList, changeFilter, changeEntity, invalidateFilters } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [LICENCES_RECEIVE]: receiveList,
    [LICENCES_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [LICENCES_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_LICENCES: changeEntity,
    INVALIDATE_LICENCES: invalidateFilters,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
