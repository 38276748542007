import { call } from '@advanza/api'
import { normalize } from 'normalizr'
import providersSchema from '../schemes/providersSchema'
import requestsSchema from '../schemes/requestsSchema'
import salesLeadsSchema from '../schemes/salesLeadsSchema'
import { changeEntity } from './entities'
import { _changeFilter, _fetchItems } from './lists'
import { preLoadNotes } from './notes'
import { PROVIDERS_RECEIVE_SINGLE } from './providers'
import { REQUESTS_RECEIVE_SINGLE } from './requests'

export const SALES_LEADS_REQUEST = 'SALES_LEADS_REQUEST'
export const SALES_LEADS_CHANGE_FILTER = 'SALES_LEADS_CHANGE_FILTER'
export const SALES_LEADS_RECEIVE_LIST = 'SALES_LEADS_RECEIVE_LIST'
export const SALES_LEADS_RECEIVE_SINGLE = 'SALES_LEADS_RECEIVE_SINGLE'

export function fetchSalesLeads(filterId) {
    return _fetchItems(filterId, {
        url: 'office/sales-leads/get-list',
        schema: salesLeadsSchema,
        reducerKey: 'salesLeads',
        requestActionName: SALES_LEADS_REQUEST,
        receiveActionName: SALES_LEADS_RECEIVE_LIST,
    })
}

export function changeSalesLeadsFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'salesLeads',
        actionType: SALES_LEADS_CHANGE_FILTER,
        fetchFunc: fetchSalesLeads,
    })
}

export function fetchSalesLead(salesLeadId) {
    return function (dispatch, getState) {
        const salesLeadEntities = getState().salesLeads.entities.salesLeads
        if (
            salesLeadEntities &&
            salesLeadEntities[salesLeadId] &&
            (salesLeadEntities[salesLeadId].provider ||
                salesLeadEntities[salesLeadId].request ||
                salesLeadEntities[salesLeadId].sales_leads_contact)
        ) {
            return Promise.resolve()
        }
        dispatch({ type: SALES_LEADS_REQUEST, id: salesLeadId })
        return call('office/sales-leads/get-one/' + salesLeadId).then((response) => {
            const { notes, request, provider } = response
            dispatch(
                preLoadNotes(`s_${salesLeadId}`, { salesLeadIds: [salesLeadId] }, { items: notes })
            )

            const { entities } = normalize([response.item], salesLeadsSchema)
            dispatch({ type: SALES_LEADS_RECEIVE_SINGLE, entities })
            if (request) {
                const { entities } = normalize([request], requestsSchema)
                dispatch({ type: REQUESTS_RECEIVE_SINGLE, entities })
            }
            if (provider) {
                const { entities } = normalize([provider], providersSchema)
                dispatch({ type: PROVIDERS_RECEIVE_SINGLE, entities })
            }
        })
    }
}

export function setSalesLeadStatus(salesLeadId, statusId) {
    return function (dispatch) {
        return call(`office/sales-leads/set-status/${salesLeadId}/${statusId}`, {
            method: 'post',
        }).then(() => {
            dispatch({ type: 'INVALIDATE_STATUSES' })
        })
    }
}

export function unLinkSalesLeadStatus(statusJoinTableId) {
    return function (dispatch) {
        return call(`office/sales-leads/unset-status/${statusJoinTableId}`, {
            method: 'post',
        }).then(() => {
            dispatch({ type: 'INVALIDATE_STATUSES' })
        })
    }
}

export function saveSalesLead(id) {
    return function (dispatch, getState) {
        const salesLead = getState().salesLeads.entities.salesLeads[id]
        return call('office/sales-leads/save/' + id, {
            json: {
                ...salesLead,
            },
        }).then((response) => {})
    }
}

function deleteSalesLead(id) {
    return function (dispatch) {
        return call(`office/sales-leads/delete/${id}`, { method: 'post' }).then(() => {
            dispatch(
                changeEntity({ store: 'salesLeads', name: 'salesLeads', key: id, remove: true })
            )
        })
    }
}

export function deleteSalesLeadsSelection(filterId) {
    return function (dispatch, getState) {
        const selection = getState().salesLeads.filters[filterId].selection
        const promises = selection.map((id) => dispatch(deleteSalesLead(id)))
        return Promise.all(promises).then(() => {
            dispatch(changeSalesLeadsFilter(filterId, { selection: [] }))
        })
    }
}
