import PropTypes from 'prop-types'
import React from 'react'

const PasswordStrengthBar = ({ password }) => {
    const score =
        password.length === 0 ? 'empty' : window.zxcvbn && window.zxcvbn(password || '').score
    return (
        <div className="password-strength">
            <div className="password-strength-meter">
                <div className={`password-strength-bar password-score-${score}`} />
            </div>
        </div>
    )
}

PasswordStrengthBar.propTypes = {
    score: PropTypes.any,
}

export default PasswordStrengthBar
