import { Select } from '@advanza/advanza_generic'
import React from 'react'
import StatusesPickerContainer from '../../containers/StatusesPickerContainer'
import OfficeLabel from '../OfficeLabel'

class StatusPicker extends React.Component {
    constructor(props) {
        super(props)
        this.onSelectStatus = this.onSelectStatus.bind(this)
    }

    componentDidMount() {
        const { fetchStatuses } = this.props
        fetchStatuses()
    }

    onSelectStatus(statuses) {
        const { onSelectStatus } = this.props
        const status = statuses[0]

        onSelectStatus(status.value)
    }

    render() {
        const { statuses, statusIds } = this.props

        const options = statusIds.map((id) => {
            const status = statuses[id]
            return {
                value: status.status_id,
                title: <OfficeLabel textOnly label={status} />,
                queryField: status.description + status.type,
            }
        })
        return (
            <Select
                options={options}
                onValueChange={this.onSelectStatus}
                className="select-min select-wide select-stealth"
                icon="label_important"
                useSearch
                placeholder="Set status"
            />
        )
    }
}

StatusPicker.propTypes = {}

export default StatusesPickerContainer(StatusPicker)
