import { call } from '@advanza/api'
import Modal from '@advanza/modal'
import { LoadingDots, PreIcon, Button } from '@advanza/ui'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import style from './RequestResendButton.module.css'

function useResend(requestId) {
    const [isLoading, setIsLoading] = useState()
    const [bounces, setBounces] = useState()
    const [response, setResponse] = useState()
    const dispatch = useDispatch()

    const getBounces = () => {
        if (isLoading === 'getBounces') {
            return Promise.resolve()
        }
        setIsLoading('getBounces')
        call('office/requests/get-request-email-bounces/' + requestId)
            .then((response) => {
                setBounces(response)
            })
            .finally(() => setIsLoading(false))
    }

    const resend = () => {
        if (isLoading === 'resend') {
            return Promise.resolve()
        }
        setIsLoading('resend')
        const shouldRemoveFromList = ''
        call(`office/requests/resend-request-confirmation/${requestId}/${shouldRemoveFromList}`)
            .then((response) => {
                dispatch({ type: 'INVALIDATE_EVENTS' })
            })
            .finally(() => setIsLoading(false))
    }

    const onlyRemoveFromBounceList = () => {
        if (isLoading === 'list') {
            return Promise.resolve()
        }
        setIsLoading('list')
        call('office/requests/remove-request-email-from-bounce/' + requestId)
            .then((response) => {
                setResponse(response.response)
            })
            .finally(() => setIsLoading(false))
    }

    const clear = () => {
        setBounces(null)
        setResponse(null)
    }

    return {
        bounces,
        isLoading,
        resend,
        response,
        getBounces,
        onlyRemoveFromBounceList,
        clear,
    }
}

const RequestResendButton = ({ requestId }) => {
    const { bounces, isLoading, response, resend, getBounces, onlyRemoveFromBounceList, clear } =
        useResend(requestId)
    return (
        <div className={style.root}>
            <Button name="text" onClick={getBounces}>
                {isLoading === 'getBounces' ? (
                    <div>
                        <LoadingDots /> loading
                    </div>
                ) : (
                    <PreIcon primColor icon="get_app">
                        Get bounces
                    </PreIcon>
                )}
            </Button>
            <Button name="text" onClick={onlyRemoveFromBounceList}>
                {isLoading === 'list' ? (
                    <div>
                        <LoadingDots /> removing..
                    </div>
                ) : (
                    <PreIcon primColor icon="delete_forever">
                        Remove email from bouncelist
                    </PreIcon>
                )}
            </Button>
            <Button name="text" onClick={resend}>
                {isLoading === 'resend' ? (
                    <div>
                        <LoadingDots /> resending..
                    </div>
                ) : (
                    <PreIcon primColor icon="forward_to_inbox">
                        Resend confirmation mail
                    </PreIcon>
                )}
            </Button>
            <Modal open={bounces} close={clear}>
                Bounces
                <pre>{JSON.stringify(bounces && bounces.bounces, null, 2)}</pre>
                <hr />
                Blocks
                <pre>{JSON.stringify(bounces && bounces.blocks, null, 2)}</pre>
            </Modal>
            <Modal open={response} close={clear}>
                {JSON.stringify(response, null, 2)}
            </Modal>
        </div>
    )
}

export default RequestResendButton
