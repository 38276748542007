import { WEBSITE_REGEX } from '@advanza/constants'
import { Col, Row } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import { Button, Divider, Icon } from '@advanza/ui'
import { useEntityShared } from 'hooks/sharedHooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { matchPlatformNames, SPEC } from './ProvidersPlatformsList'

export const getFields = () => ({
    name: { type: 'text', placeholder: 'Name', validator: (val) => val && 3 <= val.trim().length },
    url: { type: 'text', placeholder: 'Url', validator: (val) => val && val.match(WEBSITE_REGEX) },
})

const ProvidersPlatformEntity = ({ providersPlatformId }) => {
    const { platforms } = useSelector((state) => state.providers.platforms)
    const platformNames = Object.values(platforms).sort()
    const {
        entity: providersPlatform,
        onChangeEntity,
        renderInput,
        onDeleteEntity,
    } = useEntityShared(SPEC, providersPlatformId, getFields)
    const knownPlatformName = platformNames.find((platformName) =>
        matchPlatformNames(platformName, providersPlatform.name)
    )

    return (
        <Row middle="xs">
            <Col xs={3}>
                <SelectSimple
                    value={knownPlatformName || '-'}
                    onChange={(e) => onChangeEntity({ name: e.target.value })}
                    error={(providersPlatform._errors || {}).name}
                    options={platformNames.concat({ value: '-', name: 'OTHER...' })}
                    alwaysControlValue={true}
                />
                {!knownPlatformName && (
                    <>
                        <Divider m />
                        {renderInput('name')}
                    </>
                )}
            </Col>
            <Col xs={8}>{renderInput('url')}</Col>
            <Col xs={1}>
                <Button name="text" onClick={onDeleteEntity} disabled={providersPlatform._saving}>
                    <Icon name={providersPlatform._saving ? 'hourglass_empty' : 'delete_forever'} />
                </Button>
            </Col>
        </Row>
    )
}

export default ProvidersPlatformEntity
