import TableList from 'components/TableList'
import { useListPerCountry } from 'hooks/listHooks'
import { schema } from 'normalizr'
import React from 'react'
import GeoTargetingSelect from './GeoTargetingSelect'

export const TABLE_ALIASES = ['Countries', 'Regions', 'Municipalities', 'Subcities', 'PostalCodes']

export const useGeoTargetingList = (filterId = 'all') =>
    useListPerCountry(
        filterId,
        {
            url: 'office/geo-targeting/get-list',
            schema: [new schema.Entity('geos', {}, { idAttribute: 'geoId' })],
            reducerKey: 'geoTargeting',
        },
        { tableAlias: TABLE_ALIASES[0], sorting: ['geoId', 'ASC'] }
    )

const GeoTargetingList = ({ filterId = 'all' }) => {
    const {
        entities: { geos = {} } = {},
        extra: { definitionsGeoTargeting = {}, targets = {} } = {},
        ...list
    } = useGeoTargetingList(filterId)

    const renderRow = (geoId) => ({
        cols: Object.keys(definitionsGeoTargeting).map((key) =>
            key === 'geoTargetName' ? (
                <GeoTargetingSelect
                    key={key}
                    targets={targets}
                    geos={geos}
                    geoId={geoId}
                    tableAlias={(list.filter || {}).tableAlias}
                />
            ) : (
                (geos[geoId] || {})[key]
            )
        ),
    })

    return (
        <TableList
            definitions={definitionsGeoTargeting}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default GeoTargetingList
