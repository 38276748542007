import React from 'react'
import PropTypes from 'prop-types'

const CategoryIcon = ({ id, color, ...rest }) => {
    const iconMap = {
        1: 'icon-plant',
        2: 'icon-bulb',
        3: 'icon-plate',
        4: 'icon-home',
        5: 'icon-pen',
        6: 'icon-search',
        7: 'icon-case',
    }
    const className = [color ? `c-${color}` : '', iconMap[id]].join(' ')
    const style = {
        fontSize: 21,
        ...rest,
    }
    return <i style={style} className={className} />
}

CategoryIcon.propTypes = {
    id: PropTypes.any.isRequired,
}

export default CategoryIcon
