import { OptionStatus } from '@advanza/types/Option'
import { Divider } from '@advanza/ui'
import { replaceSubscriptionOptions } from 'actions/subscriptions'
import Checkbox from 'components/input/Checkbox'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const LeadEstimatorServiceOptions = ({ subscription, estimation = {} }) => {
    const { services, questions, options } = useSelector((state) => state.subscriptions.entities)
    const dispatch = useDispatch()
    const service = services[subscription.service_id]
    const optionEstimates = (estimation && estimation.options) || {}
    const totalRequests = (estimation && estimation.total) || 0

    const questionIds = service ? service.questions.filter((id) => questions[id].filterable) : []
    return (
        <div>
            {questionIds.map((questionId) => {
                const question = questions[questionId]
                const optionValues = question.options.map((id) => {
                    const option = options[id]
                    const estimate = optionEstimates[id.toString()]
                    const perc = estimate && Math.round((estimate / totalRequests) * 100)
                    return {
                        value: id,
                        name:
                            option.status === OptionStatus.ACTIVE ? (
                                <span>
                                    {option.value} {perc && <b>({perc}%)</b>}
                                </span>
                            ) : (
                                <del>{option.value}</del>
                            ),
                    }
                })
                return (
                    <Fragment key={questionId}>
                        <b>{question.question}</b>
                        <Divider />
                        <Checkbox
                            options={optionValues}
                            onChangeValue={(values) =>
                                dispatch(
                                    replaceSubscriptionOptions(subscription.subscription_id, values)
                                )
                            }
                            value={subscription.subscriptions_options}
                        />
                        <Divider />
                    </Fragment>
                )
            })}
        </div>
    )
}

export default LeadEstimatorServiceOptions
