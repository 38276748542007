import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import A from 'components/ui/A'
import { useCopyInvalidMatch } from 'hooks/matchesHooks'
import { useState } from 'react'
import { useSelector } from 'react-redux'

function useMatchDelayReason(currentMatch) {
    const { matches } = useSelector((state) => state.matches.entities)
    const now = new Date()
    const isDelayed = !currentMatch.confirmed && currentMatch.freeLeadDelay
    const hasActivePremiumExlusive =
        matches &&
        Object.keys(matches)
            .map((matchId) => matches[matchId])
            .filter((match) => match.request_id === currentMatch.request_id)
            .findIndex(
                (match) =>
                    match.confirmed &&
                    (match.isSigned || match.isFreeTrialLead) &&
                    match.exclusive_label &&
                    !match.exclusive_expired
            ) !== -1
    const nightlyExclusiveDelay =
        isDelayed && (now.getUTCHours() >= 19 || now.getUTCHours() <= 7) && hasActivePremiumExlusive
    const delayReason = nightlyExclusiveDelay
        ? 'This match is delayed until 9 AM, to prevent an exclusive premium match from expiring at night.'
        : "This match is 2 hours delayed, because it's not a premium match."

    return {
        isDelayed,
        delayReason,
    }
}

const ManualConfirmButton = ({ match }) => {
    const [open, setOpen] = useState(false)
    const {
        error,
        copyMatchAndConfirm,
        isPosting,
        result,
        copyRequestDetails,
        isCopying,
        isCopied,
    } = useCopyInvalidMatch(match.match_id)
    const { isDelayed, delayReason } = useMatchDelayReason(match)

    return (
        <>
            {!match.confirmed && (
                <button onClick={() => setOpen(true)}>
                    <Icon
                        name={
                            isDelayed
                                ? 'alarm_on'
                                : match.processingNewRequest
                                ? 'rotate_right'
                                : 'error'
                        }
                        orange
                    />
                </button>
            )}
            <Modal open={open} close={() => setOpen(false)} maxWidth={800}>
                <h3>Match #{match.match_id} is not confirmed</h3>
                <Divider m />
                {isDelayed ? (
                    <div style={{ color: '#f46511' }}>
                        <PreIcon name="alarm_on" orange>
                            {delayReason}
                        </PreIcon>
                        <Divider l />
                    </div>
                ) : match.processingNewRequest ? (
                    <div style={{ color: '#f46511' }}>
                        <PreIcon icon="rotate_right" orange>
                            The request of this match is still being processing. Please wait a few
                            minutes...
                        </PreIcon>
                        <Divider l />
                    </div>
                ) : null}
                {error && <div style={{ color: '#f00' }}>Error: {error.error}</div>}
                {result && result.status === 'success' && (
                    <div style={{ color: '#1aa225' }}>
                        <div>
                            Added to request:{' '}
                            <A to={`/service/requests/request/${result.requestId}`}>
                                #{result.requestId}
                            </A>
                        </div>
                        <Divider m />
                        <div>
                            New matchId:{' '}
                            <A to={`/service/matches/match/${result.matchId}`}>#{result.matchId}</A>
                        </div>
                    </div>
                )}
                {isCopied && isCopied.status === 'success' && (
                    <div style={{ color: '#1aa225' }}>
                        <div>
                            Added request details as message to matchId:{' '}
                            <A to={`/service/matches/match/${isCopied.matchId}`}>
                                <u>#{isCopied.matchId}</u>
                            </A>
                        </div>
                    </div>
                )}
                {!result && !isCopied && (
                    <div>
                        If the match belongs to an invalid request, you can try to:
                        <ul>
                            <li>
                                - <em>copy the match and confirm</em> if the request has a valid
                                duplicate
                            </li>
                            <li>
                                - <em>copy the request details only</em> if the provider has already
                                a charged match from the same user
                            </li>
                        </ul>
                    </div>
                )}
                <Divider l />
                <Row middle="xs" end="xs">
                    {result || isCopied ? (
                        <>
                            <Col x>
                                <Button name="primary" onClick={() => setOpen(false)}>
                                    Close
                                </Button>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col x>
                                <Button name="bordered" onClick={() => setOpen(false)}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col x>
                                <Button
                                    name="primary"
                                    disabled={isPosting}
                                    onClick={copyMatchAndConfirm}>
                                    <PreIcon name="content_copy" white>
                                        {isPosting ? `bezig...` : `Copy match and confirm`}
                                    </PreIcon>
                                </Button>
                            </Col>
                            <Col x>
                                <Button
                                    name="primary"
                                    disabled={isCopying}
                                    onClick={copyRequestDetails}>
                                    <PreIcon name="content_copy" white>
                                        {isCopying ? `bezig...` : `Copy request details only`}
                                    </PreIcon>
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            </Modal>
        </>
    )
}

export default ManualConfirmButton
