import { HoverText } from '@advanza/ui'
import style from './reclaimHoverText.module.scss'

const ReclaimHoverText = ({ trigger, reclaim = {} }) => {
    return (
        <HoverText trigger={trigger}>
            <table class={style.table}>
                <thead>
                    <tr>
                        <th></th>
                        <th>reclaims</th>
                        <th>matches</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Last 3 months</td>
                        <td>{reclaim.nrReclaimsLast3Month}</td>
                        <td>{reclaim.nrMatchesLast3Month}</td>
                        <td>
                            {reclaim.nrReclaimsLast3Month == 0
                                ? '-'
                                : Math.round(
                                      (reclaim.nrReclaimsLast3Month / reclaim.nrMatchesLast3Month) *
                                          100
                                  ) + '%'}
                        </td>
                    </tr>
                    <tr>
                        <td>All time</td>
                        <td>{reclaim.nrReclaimsTotal}</td>
                        <td>{reclaim.nrMatchesTotal}</td>
                        <td>
                            {Math.round((reclaim.nrReclaimsTotal / reclaim.nrMatchesTotal) * 100) +
                                '%'}
                        </td>
                    </tr>
                </tbody>
            </table>
        </HoverText>
    )
}

export default ReclaimHoverText
