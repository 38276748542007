import { Section } from '@advanza/advanza_generic'
import React from 'react'
import Page from '../ui/Page'
import LabelAdminList from './LabelAdminList'
import LabelFilter from './LabelFilter'

class LabelsPage extends React.Component {
    render() {
        return (
            <Page title="Labels">
                <div className="container">
                    <Section y>
                        <Section s>
                            <h1>Labels</h1>
                        </Section>
                        <LabelFilter
                            categories={[
                                'badges',
                                'matches',
                                'profile',
                                'profileBuilderWizard',
                                'serviceProviders',
                            ]}
                            filterId="all"
                            defaultOptions={{
                                pageSize: 40,
                                excludeCustom: true,
                                order: 'office_label_id DESC',
                            }}
                        />
                        <Section n>
                            <LabelAdminList selectable filterId="all" />
                        </Section>
                    </Section>
                </div>
            </Page>
        )
    }
}

LabelsPage.propTypes = {}

export default LabelsPage
