import { PreIcon } from '@advanza/ui'
import React from 'react'
import style from './radio.module.css'

class Checkbox extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.getValues = this.getValues.bind(this)
    }

    onChange(e) {
        const { value } = e.target
        const { onChangeValue } = this.props
        const values = this.getValues()
        const typedValue = isNaN(value) ? value : parseInt(value, 10)
        if (values.indexOf(typedValue) !== -1) {
            onChangeValue(values.filter((id) => id !== typedValue))
        } else {
            onChangeValue(values.concat([typedValue]))
        }
    }

    getValues() {
        const { value } = this.props
        return !value ? [] : Array.isArray(value) ? value : [value]
    }
    render() {
        const { options, name = 'checkbox_group', error, defaultErrorMsg = 'hallo' } = this.props
        const values = this.getValues()
        return (
            <div className={style.radios}>
                {options.map((option, i) => {
                    const typedValue = isNaN(option.value)
                        ? option.value
                        : parseInt(option.value, 10)
                    const isChecked = values.indexOf(typedValue) !== -1
                    return (
                        <div className={option.after ? style.itemContainer : ''} key={i}>
                            <label
                                className={[style.label, isChecked ? style.checked : ''].join(' ')}>
                                <input
                                    type="checkbox"
                                    className={style.radio}
                                    value={typedValue}
                                    checked={isChecked}
                                    name={name}
                                    onChange={this.onChange}
                                />
                                <span>
                                    <PreIcon
                                        overflowFix
                                        primColor={isChecked}
                                        icon={isChecked ? 'check_box' : 'check_box_outline_blank'}>
                                        <div>{option.name}</div>
                                    </PreIcon>
                                </span>
                            </label>
                            {option.after && <div>{option.after}</div>}
                        </div>
                    )
                })}
                {error && (
                    <div className={style.error}>
                        <PreIcon icon="error" fontSize={18}>
                            {(typeof error === 'string' && error) || defaultErrorMsg || ''}
                        </PreIcon>
                    </div>
                )}
            </div>
        )
    }
}

export default Checkbox
