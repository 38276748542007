import { Row, Col } from '@advanza/grid'
import { Icon } from '@advanza/ui'
import { saveProviderEntity } from 'actions/providers'
import { useChangeEntity } from 'hooks/entityHooks'
import React from 'react'

const QualificationEntity = ({ id, onDelete, onEnter }) => {
    const isNew = `${id}`.indexOf('new') === 0
    const fields = {
        name: {
            type: 'text',
            placeholder: 'Naam opleiding/certificaat',
            hidePlaceholder: true,
            autoFocus: isNew,
            validator: (val) => val && val.length > 0,
            onKeyDown: (e) => e.key === 'Enter' && onEnter(),
        },
    }
    const { renderInput } = useChangeEntity(
        {
            store: 'providers',
            name: 'qualifications',
            entityId: id,
        },
        fields
    )
    return (
        <Row middle="xs">
            <Col xs>{renderInput('name')}</Col>
            <Col x>
                <button onClick={() => onDelete(id)}>
                    <Icon name="delete_forever" />
                </button>
            </Col>
        </Row>
    )
}

export default QualificationEntity
