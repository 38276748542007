import {Section} from '@advanza/advanza_generic'
import {Col, Row} from '@advanza/grid'
import {call} from '@advanza/api'
import {Button, Divider, FixedWarningPopup} from '@advanza/ui'
import {useEntityShared} from 'hooks/sharedHooks'
import React, {useState} from 'react'
import AutoRow from 'components/misc/AutoRow'

export const SPEC = {
    store: 'licencesUpdaters',
    name: 'licencesUpdaters',
}

const fields = {
    licences_updater_id: {type: 'text', placeholder: 'licences_updater_id'},
    licence_id: {type: 'text', placeholder: 'licence_id'},
    api: {type: 'select', placeholder: 'api', options: [{value: 'import.io', title: 'import.io'}]},
    extractor: {type: 'text', placeholder: 'extractor'},
    active: {type: 'boolean', msg: 'active'},
    rulesText: {
        type: 'autoGrow', maxRows: 100, placeholder: 'rules',
        validator: (val) => {
            if (!val) {
                return true
            }
            try {
                JSON.parse(val)
                return true
            } catch (e) {
                return false
            }
        }
    },
}

const LicencesUpdatersEntity = ({licencesUpdaterId}) => {
    const [toast, setToast] = useState({})
    const {entity, onSaveEntity, renderInput} = useEntityShared(
        SPEC,
        licencesUpdaterId,
        fields
    )

    if (!licencesUpdaterId) {
        return null
    }

    const onSave = () =>
        onSaveEntity().then(
            () => setToast({id: Date.now(), type: 'save', message: 'Updater saved'}),
            (response) =>
                setToast({isError: true, id: Date.now(), type: 'save', message: JSON.stringify(response, null, 4)})
        )

    const onTest = (includeExport = false) => {
        setToast({id: Date.now(), type: 'test', message: '...creating test job'})
        let testEndpoint = includeExport ? 'test-run-signed-export' : 'test-run'
        call('office/licences-updaters/' + testEndpoint + '/' + licencesUpdaterId, {
            method: 'GET',
        }).then(
            (response) => setToast({
                id: Date.now(),
                type: 'test',
                message: 'Created test job' + JSON.stringify(response, null, 4)
            }),
            (response) => setToast({
                isError: true,
                id: Date.now(),
                type: 'test',
                message: JSON.stringify(response, null, 4)
            })
        )
    }

    const [formatted, setFormatted] = useState(false)
    if (!formatted && entity.rulesText) {
        try {
            entity.rulesText = JSON.stringify(JSON.parse(entity.rulesText), null, 4)
            setFormatted(true)
        } catch (e) {
            // pass
        }
    }

    return (
        <Section x y>
            <Row middle="xs">
                <Col x>{renderInput('licence_id')}</Col>
                <Col x>{renderInput('api')}</Col>
                <Col x>{renderInput('extractor')}</Col>
                <Col x>{renderInput('active')}</Col>
            </Row>
            <Divider m/>
            <pre>
                {renderInput('rulesText')}
            </pre>
            <Divider m/>
            <Row>
                <Col x>
                    <Button onClick={onSave} disabled={!entity._isTouched || entity._saving}>
                        {entity._saving ? '...saving' : 'Save'}
                    </Button>
                    {toast.isError && toast.type === 'save' &&
                        <div style={{color: 'red'}}>not saved: {toast.isError} {toast.message}</div>}
                </Col>
            </Row>
            <Row>
                <Col x>
                    <AutoRow>
                        <Button onClick={() => onTest()} disabled={entity._isTouched || entity._saving}>{'Test'}</Button>
                        <Button onClick={() => onTest(true)} disabled={entity._isTouched || entity._saving}>
                            {'Test with Signed Export'}
                        </Button>
                    </AutoRow>
                    {toast.type === 'test' && toast.message && <pre style={{'whiteSpace': 'pre-wrap'}}>
                        <div style={{color: toast.isError ? 'red' : 'initial'}}>
                            {toast.isError} {toast.message}
                        </div>
                    </pre>}
                </Col>
            </Row>
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </Section>
    )
}

export default LicencesUpdatersEntity
