import { Section } from '@advanza/advanza_generic'
import React from 'react'
import PropTypes from 'prop-types'
import { saveSalesLead } from '../../actions/salesLeads'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import OfficeLabel from '../OfficeLabel'
import EntityComponent from '../services/EntityComponent'

class SalesLeadPrioSwitch extends EntityComponent {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        this.onChangeEntity({ prio: e.target.value })
        this.save()
    }

    editFields = {
        prio: {
            type: 'select',
            options: [
                {
                    value: 'cold',
                    title: <OfficeLabel label={{ description: 'cold', color: '#BDE8FF' }} />,
                },
                {
                    value: 'medium',
                    title: <OfficeLabel label={{ description: 'medium', color: '#FFD5B3' }} />,
                },
                {
                    value: 'hot',
                    title: <OfficeLabel label={{ description: 'hot', color: '#FFD6D6' }} />,
                },
            ],
            className: 'select-min select-stealth',
        },
    }

    render() {
        return this.renderInput('prio')
    }
}

SalesLeadPrioSwitch.propTypes = {}

export default EntityComponentContainer(SalesLeadPrioSwitch, {
    store: 'salesLeads',
    name: 'salesLeads',
    saveFunc: saveSalesLead,
})
