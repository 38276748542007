import { call } from '@advanza/api'
import { capitalize } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { InputField, SelectCheckbox, SelectSimple } from '@advanza/input'
import Modal from '@advanza/modal'
import { OptionStatus } from '@advanza/types/Option'
import { QuestionStatus } from '@advanza/types/Question'
import { Button, Divider, HoverText, Icon, LinearLoader, LoadingDots, PreIcon } from '@advanza/ui'
import { fetchService, saveHighMunicipalities } from 'actions/services'
import { fetchAllMunicipalities } from 'actions/subscriptions'
import DatePicker from 'components/DatePicker'
import SafeButton from 'components/ui/SafeButton'
import { getDate } from 'date'
import format from 'date-fns/format'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './ServiceConfigPrices.module.css'

const PRICE_STATUS_FUTURE = 1
const PRICE_STATUS_CURRENT = 2
const PRICE_STATUS_PAST = 3
const PRICE_STATUS_DELETED = 4

const PRICE_STATUS_MAP = {
    [PRICE_STATUS_FUTURE]: 'future',
    [PRICE_STATUS_CURRENT]: 'current',
    [PRICE_STATUS_PAST]: 'past',
    [PRICE_STATUS_DELETED]: 'deleted',
}

const useServicePrices = (serviceId) => {
    const dispatch = useDispatch()
    const [prices, setPrices] = useState(undefined)
    const [newPrice, setNewPrice] = useState(null)
    const [highlight, setHighlight] = useState(null)
    const [invalidated, setInvalidated] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [endDeleteDate, setEndDeleteDate] = useState('')
    const [providerId, setProviderId] = useState(0)
    const [mailTo, setMailTo] = useState('')
    const {
        services = {},
        questions = {},
        options = {},
    } = useSelector((state) => state.services.entities)
    const service = services[serviceId] || {}
    const serviceQuestions = service.questions || []
    const filterOptions = {}
    serviceQuestions.forEach((id) => {
        const question = questions[id] || {}

        if (
            question.filterable &&
            [QuestionStatus.ACTIVE, QuestionStatus.NEW].includes(question.status) &&
            ['2', '3'].includes(question.type)
        ) {
            question.options.forEach((optionId) => {
                const option = options[optionId] || {}
                const prefix =
                    question.status === QuestionStatus.NEW
                        ? '[NEW QUESTION] '
                        : option.status === OptionStatus.NEW
                        ? '[NEW] '
                        : option.status === OptionStatus.HIDDEN
                        ? '[HIDDEN] '
                        : ''
                filterOptions[optionId] = {
                    optionId,
                    prefix: prefix,
                    question: question.question_name_filter,
                    value: option.value,
                }
            })
        }
    })

    useEffect(() => {
        dispatch(fetchService(serviceId))
    }, [])

    useEffect(() => {
        if (invalidated || (!isLoading && !prices)) {
            setIsLoading('fetching')
            call('office/services/get-prices/' + serviceId)
                .then(({ prices }) => setPrices(prices))
                .then(() => {
                    setInvalidated(false)
                    setIsLoading(false)
                })
        }
    }, [invalidated])

    useEffect(() => {
        if (newPrice && newPrice.type !== 'email') {
            editPrice('amount_high', null)

            if (newPrice.type !== 'marketPlace') {
                editPrice('option_id', null)
            }
        }
    }, [newPrice?.type])

    const clearError = () => setError(null)

    const endPrice = (priceId) =>
        call(`office/services/end-price/${priceId}/${endDeleteDate}`).finally(() =>
            setInvalidated(true)
        )

    const deletePrice = (priceId) =>
        call(`office/services/delete-price/${priceId}/${endDeleteDate}`).finally(() =>
            setInvalidated(true)
        )

    const editPrice = (key, value) => setNewPrice({ ...newPrice, [key]: value })

    const saveNewPrice = () => {
        if (isLoading === 'saving') {
            return Promise.resolve()
        }

        setIsLoading('saving')
        return call('office/services/add-price', { json: { ...newPrice, service_id: serviceId } })
            .then(
                ({ id }) => {
                    setNewPrice(null)
                    setHighlight(id)
                    setTimeout(() => setHighlight(null), 2500)
                },
                ({ msg }) => setError(msg || 'something went wrong')
            )
            .finally(() => {
                setInvalidated(true)
                setIsLoading(false)
            })
    }

    return {
        prices,
        newPrice,
        setNewPrice,
        highlight,
        error,
        isLoading,
        endDeleteDate,
        setEndDeleteDate,
        providerId,
        setProviderId,
        mailTo,
        setMailTo,
        service,
        filterOptions,
        clearError,
        endPrice,
        deletePrice,
        editPrice,
        saveNewPrice,
    }
}

const typeIconMap = {
    email: 'email',
    phone: 'phone',
    website: 'language',
    marketPlace: 'storefront',
}

const statusIconMap = {
    0: 'fiber_new',
    1: 'visibility',
    2: 'visibility_off',
    3: 'archived',
}

const getLeadType = (price) =>
    price.is_website_click
        ? 'website'
        : price.is_phone_click
        ? 'phone'
        : price.is_marketplace
        ? 'marketPlace'
        : 'email'

const ServiceConfigPrices = ({ serviceId }) => {
    const dispatch = useDispatch()
    const {
        prices = [],
        newPrice,
        setNewPrice,
        highlight,
        error,
        isLoading,
        endDeleteDate,
        setEndDeleteDate,
        providerId,
        setProviderId,
        mailTo,
        setMailTo,
        service = {},
        filterOptions = {},
        clearError,
        endPrice,
        deletePrice,
        editPrice,
        saveNewPrice,
    } = useServicePrices(serviceId)
    const finalTestMailTo = encodeURIComponent(
        mailTo || window?._user?.email || 'advanzaapp.mails@gmail.com'
    )
    const {
        result: { [service.country_code]: municipalitiesGrouped = {} } = {},
        entities: municipalities = {},
    } = useSelector((state) => state.subscriptions.municipalities)

    useEffect(() => {
        if (service.country_code) {
            dispatch(fetchAllMunicipalities(service.country_code))
        }
    }, [dispatch, service.country_code])

    const { protocol, hostname } = window.location

    return (
        <div className={[style.root, isLoading === 'fetching' ? style.loading : ''].join(' ')}>
            {isLoading && <LinearLoader absolute />}
            <b>High price municipalities</b>
            <Divider m />
            <SelectCheckbox
                wide
                stickyHeaderAndSearch
                options={Object.keys(municipalitiesGrouped)
                    .map((regionName) =>
                        municipalitiesGrouped[regionName].map((municipalityId) => ({
                            value: municipalityId,
                            name: (municipalities[municipalityId] || {}).name + ` (${regionName})`,
                        }))
                    )
                    .flat()
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase()
                        const nameB = b.name.toUpperCase()
                        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
                    })}
                value={service.highMunicipalityIds || []}
                onChangeValue={(highMunicipalityIds) =>
                    dispatch(saveHighMunicipalities(serviceId, highMunicipalityIds))
                }
            />
            <Divider l />
            <a
                href={`${protocol}//${hostname}/api/office/services/export-pros-with-prices/${serviceId}`}
                target="_blank"
                rel="noreferrer">
                <Button name="text">Export prices</Button>
            </a>
            {Object.keys(typeIconMap)
                .filter((leadType) => prices.some((price) => getLeadType(price) === leadType))
                .map((leadType) => (
                    <Fragment key={leadType}>
                        <Divider xl />
                        <PreIcon icon={typeIconMap[leadType]} fontSize={18}>
                            <h4>{capitalize(leadType)}</h4>
                        </PreIcon>
                        <Divider m />
                        <table className={style.table}>
                            <tbody>
                                <tr className={style.header}>
                                    {[
                                        'Low',
                                        ...(leadType === 'email' ? ['High'] : []),
                                        ...(['email', 'marketPlace'].includes(leadType)
                                            ? ['Question', 'Option']
                                            : []),
                                        'StartDate',
                                        'StartDateAll',
                                        'EndDate',
                                        'DeleteDate',
                                        'Pros',
                                    ].map((header) => (
                                        <th key={header}>{header}</th>
                                    ))}
                                    <th>
                                        <DatePicker
                                            placeholder="use date"
                                            format="YYYY-MM-DD"
                                            dayPickerProps={{ disabled: null }}
                                            clickUnselectsDay
                                            alignRight
                                            large={false}
                                            value={endDeleteDate}
                                            onChange={(e) => setEndDeleteDate(e.target.value)}
                                        />
                                    </th>
                                </tr>
                                {prices
                                    .filter((price) => getLeadType(price) === leadType)
                                    .map((price) => {
                                        const filterOption = filterOptions[price.option_id]
                                        const hasDouble = prices.some(
                                            (price2) =>
                                                price.price_id !== price2.price_id &&
                                                price.priceStatus === PRICE_STATUS_CURRENT &&
                                                price2.priceStatus === PRICE_STATUS_CURRENT &&
                                                price.is_phone_click === price2.is_phone_click &&
                                                price.is_website_click ===
                                                    price2.is_website_click &&
                                                price.is_marketplace === price2.is_marketplace &&
                                                price.option_id === price2.option_id
                                        )
                                        const className = [
                                            style.item,
                                            style[PRICE_STATUS_MAP[price.priceStatus]],
                                            hasDouble ? style.warning : '',
                                            highlight === price.price_id ? style.highlight : '',
                                            price.questionStatus === QuestionStatus.NEW ||
                                            price.optionStatus === OptionStatus.NEW
                                                ? style.isNewFilter
                                                : '',
                                        ].join(' ')
                                        return (
                                            <tr
                                                key={price.price_id}
                                                className={className}
                                                title={
                                                    hasDouble
                                                        ? 'Has double'
                                                        : capitalize(
                                                              PRICE_STATUS_MAP[price.priceStatus]
                                                          )
                                                }>
                                                <td className={style.amount}>
                                                    €{price.amount.toFixed(2)}
                                                </td>
                                                {leadType === 'email' && (
                                                    <td className={style.amount}>
                                                        {price.amount_high !== null
                                                            ? '€' + price.amount_high.toFixed(2)
                                                            : ''}
                                                    </td>
                                                )}
                                                {['email', 'marketPlace'].includes(leadType) && (
                                                    <>
                                                        <td>
                                                            {filterOption &&
                                                                filterOption.question && (
                                                                    <>
                                                                        {price.questionStatus ===
                                                                            QuestionStatus.NEW && (
                                                                            <Icon
                                                                                classNameIcon={
                                                                                    style.newIcon
                                                                                }
                                                                                name={
                                                                                    statusIconMap[
                                                                                        price
                                                                                            .questionStatus
                                                                                    ]
                                                                                }
                                                                            />
                                                                        )}
                                                                        {filterOption.question}
                                                                    </>
                                                                )}
                                                        </td>
                                                        <td>
                                                            {!price.option_id ? (
                                                                'all options'
                                                            ) : filterOption &&
                                                              price.optionStatus !==
                                                                  OptionStatus.ARCHIVED ? (
                                                                <>
                                                                    {price.optionStatus !==
                                                                        OptionStatus.ACTIVE && (
                                                                        <Icon
                                                                            classNameIcon={
                                                                                style.newIcon
                                                                            }
                                                                            name={
                                                                                statusIconMap[
                                                                                    price
                                                                                        .optionStatus
                                                                                ]
                                                                            }
                                                                        />
                                                                    )}
                                                                    {filterOption.value}
                                                                    {price.questionStatus ===
                                                                        QuestionStatus.NEW &&
                                                                        price.optionStatus !==
                                                                            OptionStatus.NEW && (
                                                                            <PreIcon
                                                                                icon="warning"
                                                                                red
                                                                                fontSize={16}>
                                                                                question is new, so
                                                                                option should also
                                                                                be new
                                                                            </PreIcon>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <>{`option gone (${price.option_id})`}</>
                                                            )}
                                                        </td>
                                                    </>
                                                )}
                                                <td>{price.start_date}</td>
                                                <td>{price.start_date_all}</td>
                                                <td>{price.end_date}</td>
                                                <td>
                                                    {price.deleted
                                                        ? format(
                                                              getDate(price.deleted),
                                                              'yyyy-MM-dd'
                                                          )
                                                        : ''}
                                                </td>
                                                <td>
                                                    <a
                                                        href={`${protocol}//${hostname}/api/office/services/export-pros-for-price/${price.price_id}`}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        <Button name="text">{price.pros}</Button>
                                                    </a>
                                                </td>
                                                <td>
                                                    <div className={style.textButtonCol}>
                                                        {!price.end_date && (
                                                            <SafeButton
                                                                action={() =>
                                                                    endPrice(price.price_id)
                                                                }
                                                                buttonText="END"
                                                                cancelText={<Icon name="close" />}
                                                                confirmMessage={'CONFIRM'}
                                                            />
                                                        )}
                                                        {!price.end_date && !price.deleted && '|'}
                                                        {!price.deleted && (
                                                            <SafeButton
                                                                action={() =>
                                                                    deletePrice(price.price_id)
                                                                }
                                                                buttonText="DELETE"
                                                                cancelText={<Icon name="close" />}
                                                                confirmMessage={'CONFIRM'}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </Fragment>
                ))}
            <Divider l />
            {newPrice && (
                <>
                    <button onClick={() => setNewPrice(null)}>CANCEL</button>
                    <Divider m />
                    <Row>
                        <Col xs={3}>
                            <InputField
                                placeholder="Amount low"
                                legend
                                type="number"
                                value={newPrice.amount}
                                onChange={(e) => editPrice('amount', e.target.value)}
                            />
                        </Col>
                        <Col xs={3}>
                            <InputField
                                placeholder="Amount high"
                                legend
                                type="number"
                                disabled={newPrice.type !== 'email'}
                                value={newPrice.amount_high}
                                onChange={(e) => editPrice('amount_high', e.target.value)}
                            />
                        </Col>
                        <Col xs={3}>
                            <SelectSimple
                                placeholder="Type"
                                legend
                                alwaysControlValue
                                options={[
                                    { name: 'Email', value: 'email' },
                                    { name: 'Phone', value: 'phone' },
                                    { name: 'Website', value: 'website' },
                                    { name: 'Marketplace', value: 'marketPlace' },
                                ]}
                                value={newPrice.type}
                                onChange={(e) => editPrice('type', e.target.value)}
                            />
                        </Col>
                        <Col xs={3}>
                            <SelectSimple
                                placeholder="Option"
                                legend
                                alwaysControlValue
                                disabled={
                                    newPrice.type !== 'email' && newPrice.type !== 'marketPlace'
                                }
                                options={[
                                    {
                                        name: 'All options',
                                        value: '-',
                                    },
                                ].concat(
                                    Object.values(filterOptions).map((filterOption) => ({
                                        name: `${filterOption.prefix}${filterOption.question} - ${filterOption.value}`,
                                        value: filterOption.optionId,
                                    }))
                                )}
                                value={newPrice.option_id || '-'}
                                onChange={(e) => editPrice('option_id', e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Divider m />
                    <Row>
                        <Col xs={3}>
                            <DatePicker
                                placeholder="Start date newly signed"
                                legend
                                format="YYYY-MM-DD"
                                dayPickerProps={{ disabled: null }}
                                clickUnselectsDay
                                value={newPrice.start_date}
                                onChange={(e) => editPrice('start_date', e.target.value)}
                            />
                        </Col>
                        <Col xs={3}>
                            <DatePicker
                                placeholder="Start date for all"
                                legend
                                format="YYYY-MM-DD"
                                dayPickerProps={{ disabled: null }}
                                clickUnselectsDay
                                value={newPrice.start_date_all}
                                onChange={(e) => editPrice('start_date_all', e.target.value)}
                            />
                        </Col>
                        <Col xs={3}>
                            <SelectSimple
                                placeholder="Set end & delete"
                                legend
                                alwaysControlValue
                                options={[
                                    { name: 'No', value: 'no' },
                                    {
                                        name: 'Same lead type, current & past prices',
                                        value: 'lead_type',
                                    },
                                    {
                                        name: 'Same lead type & option, current & past prices',
                                        value: 'lead_type_option',
                                    },
                                ]}
                                value={newPrice.setEndDelete}
                                onChange={(e) => editPrice('setEndDelete', e.target.value)}
                            />
                        </Col>
                        <Col className={style.saveButtonCol} xs={3}>
                            <Divider ml />
                            <Button disabled={isLoading === 'saving'} onClick={saveNewPrice}>
                                {isLoading === 'saving' ? <LoadingDots color="#fff" /> : 'Save'}
                            </Button>
                        </Col>
                    </Row>
                    <Divider xl name="border" />
                </>
            )}
            <Row end="xs" middle="xs">
                {!newPrice && (
                    <Col className={style.addPriceButtonCol} xs={3}>
                        <Button
                            name="text"
                            onClick={() =>
                                setNewPrice({
                                    amount: '10',
                                    amount_high: '',
                                    type: 'email',
                                    option_id: null,
                                    start_date: '',
                                    start_date_all: '',
                                    setEndDelete: 'no',
                                })
                            }>
                            <PreIcon name="add" primColor>
                                Add price
                            </PreIcon>
                        </Button>
                    </Col>
                )}
                <Col xs={3}>
                    <a
                        href={`${protocol}//${hostname}/api/office/services/export-price-changes-per-pro/${serviceId}`}
                        target="_blank"
                        rel="noreferrer">
                        <Button className={style.fullWidthButton} name="bordered">
                            Export changes
                        </Button>
                    </a>
                </Col>
                <Col xs={3}>
                    <SafeButton
                        className={style.fullWidthSafeButton}
                        action={() =>
                            call(`office/services/sent-price-increase-mails/${serviceId}`)
                        }
                        buttonText="Send price-increase mails"
                        cancelText={<Icon name="close" />}
                        confirmMessage={'Confirm'}
                        newStyle
                    />
                </Col>
                <Col x>
                    <HoverText
                        extraBubbleClassNames={[style.bigHover]}
                        noTail
                        trigger={<Icon fontSize={18} name="info" />}>
                        <ul className={style.ul}>
                            <li>
                                You cannot change the dates (and other fields) of a price after they
                                have been set. This is to prevent trouble and keep the prices as a
                                historical record.
                            </li>
                            <li>
                                If you set a start date for newly signed pro&apos;s in the future
                                and send the price-increase <i>announcement</i> mails with the
                                button, pro&apos;s that sign after the mailing but before the start
                                date will first get the old prices and then get the new prices when
                                the start date for all comes. However, they only get the price
                                increase <i>reminder</i> mail that mentions the <i>announcement</i>{' '}
                                mail that they didn&apos;t get. So it&apos;s better to (from good to
                                less good):
                                <ol>
                                    <li>
                                        Set the start date for newly signed in the future (for
                                        instance tomorrow) and send the <i>announcement</i> mail on
                                        that date or later (but before the start date for all).
                                    </li>
                                    <li>
                                        (Early during the day) set the start date for newly signed
                                        today after making sure nobody signed in the service today
                                        so far (otherwise they would switch to the new price right
                                        away without a message in advance). Then send the{' '}
                                        <i>announcement</i> mail.
                                    </li>
                                    <li>
                                        (Late during the day) set the start date for newly signed
                                        tomorrow and now send the <i>announcement</i> mail, hoping
                                        that nobody will sign in the service during the rest of the
                                        day.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Let all new prices start at date A for newly signed and at date B
                                for all pro&apos;s. Otherwise the mails will randomly pick the dates
                                from one of the new prices even though not all prices start then.
                                Also you will get multiple reminder mails (each time a new price
                                starts for all).
                            </li>
                            <li>
                                A price is chosen (after filtering by dates) by more specific first
                                and then by newer start date first. A new price will therefore
                                usually be strong enough to be used without putting an end (for
                                newly signed) or delete (for all) date on the old price(s), but it
                                is a good idea to add these anyway. Then you know for sure that they
                                will not be used anymore and it is needed if you want to overrule an
                                option specific price with a generic one.
                            </li>
                            <li>
                                If you add/remove prices for a filter question that isn&apos;t the
                                first filter question, the &apos;price question&apos; may change for
                                some or all of the pro&apos;s. You should be careful with this
                                scenario.
                            </li>
                            <li>
                                The &apos;pros&apos; column doesn&apos;t check if the price would be
                                overruled by another (regular or custom) price for a pro. It just
                                checks if the price is valid for the pro. Use &apos;Export
                                prices&apos; for a more accurate view of what prices a pro may
                                really be charged.
                            </li>
                            <li>
                                The mails will use a regional version based on whether or not there
                                is at least one price change for a price with a high price point.
                            </li>
                        </ul>
                    </HoverText>
                </Col>
            </Row>
            <Divider l />
            <div className={style.test}>
                <Row>
                    <Col xs={4}>
                        <InputField
                            placeholder="Test provider id"
                            legend
                            inputPlaceholder="Fallback: all providers"
                            type="number"
                            value={providerId || ''}
                            onChange={(e) => setProviderId(parseInt(e.target.value, 10) || 0)}
                        />
                    </Col>
                    <Col xs={4}>
                        <InputField
                            placeholder="Test mail to"
                            legend
                            inputPlaceholder="Fallback: your address"
                            value={mailTo}
                            onChange={(e) => setMailTo(e.target.value)}
                        />
                    </Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <Col xs={4}>
                        <SafeButton
                            className={style.fullWidthSafeButton}
                            action={() =>
                                call(
                                    `office/services/sent-price-increase-mails/${serviceId}/${providerId}/${finalTestMailTo}`
                                )
                            }
                            buttonText={
                                <PreIcon icon="rocket_launch" center>
                                    Test price-increase mails
                                </PreIcon>
                            }
                            cancelText={<Icon name="close" />}
                            confirmMessage={'Confirm'}
                            newStyle
                        />
                    </Col>
                    <Col xs={4}>
                        <SafeButton
                            className={style.fullWidthSafeButton}
                            action={() =>
                                call(
                                    `office/services/test-send-price-changes-task/${serviceId}/${providerId}/${finalTestMailTo}`
                                )
                            }
                            buttonText={
                                <PreIcon icon="alarm" center>
                                    Test reminder mails
                                </PreIcon>
                            }
                            cancelText={<Icon name="close" />}
                            confirmMessage={'Confirm'}
                            newStyle
                        />
                    </Col>
                </Row>
            </div>
            <Modal open={error} close={clearError}>
                <pre>{JSON.stringify(error)}</pre>
            </Modal>
        </div>
    )
}

export default ServiceConfigPrices
