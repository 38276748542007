import React from 'react'
import { connect } from 'react-redux'
import { addReview, changeReviewsFilter, deleteReviewSelection } from '../actions/reviews'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.reviews
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]
    return {
        filter,
        count,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeReviewsFilter(id, options)),
        addReview: (options, filterId) => dispatch(addReview(options, filterId)),
        deleteSelection: (filterId) => dispatch(deleteReviewSelection(filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
