import { schema } from 'normalizr'

const websiteClicksSchema = new schema.Entity(
    'websiteClicks',
    {},
    {
        idAttribute: 'website_click_id',
    }
)

export default [websiteClicksSchema]
