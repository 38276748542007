import { Divider } from '@advanza/ui'
import InvoicesList from 'components/billing/InvoicesList'
import Page from 'components/ui/Page'
import React from 'react'

const InvoicesPage = ({ filterId = 'all' }) => {
    return (
        <Page>
            <Divider l />
            <InvoicesList filterId={filterId} />
        </Page>
    )
}

export default InvoicesPage
