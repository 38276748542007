import { InputField } from '@advanza/input'
import { Button, LoadingDots, PreIcon } from '@advanza/ui'
import { changeSubscriptionTravelRange } from 'actions/subscriptions'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { useSubscriptionArea } from 'hooks/providerHooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import style from './ServiceAreaRadius.module.css'

function useServiceAreaRadius() {}

const ServiceAreaRadius = ({ subscriptionId }) => {
    const dispatch = useDispatch()
    const { postalCode, subscription, isSaving, saveMunicipalities, setByRadius } =
        useSubscriptionArea(subscriptionId)
    if (!postalCode || !subscription) {
        return <div></div>
    }
    return (
        <div className={style.root}>
            <div>
                <InputField
                    type="number"
                    inputStyle={{ maxWidth: 75 }}
                    after={'km'}
                    value={postalCode.distance}
                    onChange={({ target }) =>
                        dispatch(
                            changeSubscriptionTravelRange(subscription.subscription_id, {
                                ...postalCode,
                                distance: target.value,
                            })
                        )
                    }
                />
            </div>
            <SearchLocationInput
                dontSearchOnInit
                locationQuery={postalCode.place_name}
                countryCode={subscription.country_code}
                showIcon
                value={postalCode.place_name}
                onChangeLocation={(location) => {
                    dispatch(changeSubscriptionTravelRange(subscriptionId, location))
                }}
            />
            <div>
                <Button name="text" disabled={isSaving} onClick={setByRadius}>
                    <PreIcon primColor icon="refresh">
                        Update
                    </PreIcon>{' '}
                    {isSaving && <LoadingDots />}
                </Button>
            </div>
        </div>
    )
}

export default ServiceAreaRadius
