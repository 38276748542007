import { SelectCheckbox } from '@advanza/input'
import { useLogsList } from 'hooks/logsHook'
import React from 'react'

const LogsFilter = ({ filterId = 'all', options, selectOptions = [] }) => {
    const { filter = {}, changeFilter } = useLogsList(filterId, options)

    return (
        <SelectCheckbox
            large
            options={selectOptions}
            onChangeValue={(values) => values.length && changeFilter({ logTypes: values })}
            value={filter.logTypes || []}
        />
    )
}

export default LogsFilter
