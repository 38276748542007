import { Col, Row } from '@advanza/grid'
import { useActionModal } from '@advanza/modal'
import { Button, Icon } from '@advanza/ui'
import { undoReclaimRequest } from 'actions/reclaims'
import { FixedWarningPopup } from '@advanza/ui'
import FormattedTime from 'components/ui/FormattedTime'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

const UndoReclaimButton = ({ lead = {}, reclaim = {} }) => {
    const dispatch = useDispatch()
    const { openModal, renderModal } = useActionModal()
    const [toast, setToast] = useState({})
    const leadType = lead.match_id || reclaim.match_id ? 'match' : 'phone'
    const idField = leadType === 'match' ? 'match_id' : 'phone_click_id'

    return (
        <>
            <Button name="text" title="Undo and reopen reclaim request" onClick={openModal}>
                <Icon name="health_and_safety" />
            </Button>
            {renderModal({
                cancelText: 'No, keep as is',
                text: 'Yes, undo',
                func: () =>
                    dispatch(undoReclaimRequest(lead[idField] || reclaim[idField], leadType)).catch(
                        (response = {}) =>
                            setToast({
                                id: Date.now(),
                                message: response.error || JSON.stringify(response),
                            })
                    ),
                header: 'Are you sure?',
                body: (
                    <>
                        Do you want to undo and reopen the reclaim request below?
                        <br />
                        <br />
                        {leadType === 'match' ? 'Request' : 'Phone click'}:
                        {leadType === 'match' && (
                            <Row>
                                <Col xs={1}>-</Col>
                                <Col x>
                                    {lead.userName ||
                                        lead.userEmail ||
                                        (reclaim.firstName ? reclaim.firstName + ' ' : '') +
                                            (reclaim.lastName ? reclaim.lastName : '')}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={1}>-</Col>
                            <Col x>
                                <FormattedTime
                                    date={
                                        lead.created || reclaim.matchDate || reclaim.phoneClickDate
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                ),
            })}
            <FixedWarningPopup red show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </>
    )
}

export default UndoReclaimButton
