import { Section } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import Page from '../ui/Page'
import RequestView from './RequestView'

class RequestViewPage extends React.Component {
    render() {
        const { id, source } = this.props.match.params
        return (
            <Page title="Requests">
                <Section x>
                    <RequestView requestId={id} filterSource={source || 'default'} />
                </Section>
            </Page>
        )
    }
}

RequestViewPage.propTypes = {
    requestId: PropTypes.any,
}

export default RequestViewPage
