import { Divider } from '@advanza/ui'
import { useReclaimsList } from 'hooks/reclaimHooks'
import React from 'react'
import Chart from 'react-google-charts'

const ReclaimStats = ({ filterId = 'all', defaultOptions = {} }) => {
    const {
        stats: {
            nrReclaimsPerReason = [],
            reclaimPercentageLast3Months,
            reclaimPercentageAllTime,
            reclaimPercentageBenchmark,
        } = {},
    } = useReclaimsList(filterId, defaultOptions)
    const data = nrReclaimsPerReason.map((reason) => [
        reason.reason ? reason.reason : 'No reason',
        Number(reason.amount),
    ])

    data.unshift(['Reclaim', 'Reasons'])
    return (
        <>
            <Chart
                width={'500px'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    title: 'Reclaims by reason',
                }}
            />
            <b>Reclaim percentage</b>
            <Divider m />
            <div>Last 3 months: {reclaimPercentageLast3Months}%</div>
            <Divider s />
            <div>Entire timeline: {reclaimPercentageAllTime}%</div>
            <Divider s />
            <div>Benchmark: {reclaimPercentageBenchmark}%</div>
        </>
    )
}

export default ReclaimStats
