import { Icon } from '@advanza/ui'
import { useSubscriptionArea } from 'hooks/providerHooks'
import React, { useState } from 'react'
import style from './ServiceAreaListUtlimate.module.css'

const ServiceAreaListUltimate = ({ subscriptionId, isLeadEstimator }) => {
    const {
        municipalities,
        subscriptionMunicipalities,
        toggleMunicipality,
        saveMunicipalities,
        setCenter,
        municipalitiesGrouped,
        toggleMunicipalityMany,
        subscription,
    } = useSubscriptionArea(subscriptionId, isLeadEstimator)
    const [showMore, setShowMore] = useState({})
    const isLoaded =
        subscription &&
        municipalitiesGrouped &&
        municipalitiesGrouped[subscription.country_code] &&
        municipalities &&
        subscriptionMunicipalities
    return (
        <div className={style.root}>
            {isLoaded &&
                Object.keys(municipalitiesGrouped[subscription.country_code]).map((regionName) => {
                    const ids = municipalitiesGrouped[subscription.country_code][regionName]
                    const nrSelected = ids.filter((id) =>
                        subscriptionMunicipalities.includes(id)
                    ).length
                    const nrTotal = ids.length
                    const hasAll = nrSelected === nrTotal
                    const expanded = showMore[regionName]
                    return (
                        <div key={regionName} className={style.item}>
                            <div className={style.region}>
                                <div
                                    onClick={() => {
                                        toggleMunicipalityMany(ids, hasAll)
                                        !hasAll &&
                                            setCenter({
                                                ...municipalities[ids[Math.round(ids.length / 2)]],
                                                mapZoom: 8,
                                            })
                                        setTimeout(saveMunicipalities, 10)
                                    }}>
                                    <Icon name={hasAll ? 'check_box' : 'check_box_outline_blank'} />
                                </div>
                                <div
                                    className={style.regionName}
                                    onClick={() =>
                                        setShowMore({
                                            ...showMore,
                                            [regionName]: expanded !== true,
                                        })
                                    }>
                                    <div>
                                        {regionName}
                                        {nrSelected > 0 && (
                                            <div>
                                                <b>{nrSelected}</b> van de {nrTotal} geselecteerd
                                            </div>
                                        )}
                                    </div>
                                    <Icon name={expanded ? 'expand_less' : 'expand_more'} />
                                </div>
                            </div>
                            {expanded && (
                                <div className={style.expanded}>
                                    {ids.map((id) => {
                                        const mun = municipalities[id]
                                        const isChecked =
                                            subscriptionMunicipalities.indexOf(id) !== -1
                                        return (
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        toggleMunicipality(id)
                                                        setCenter(municipalities[id])
                                                        saveMunicipalities()
                                                    }}
                                                    key={id}>
                                                    <Icon
                                                        name={
                                                            isChecked
                                                                ? 'check_box'
                                                                : 'check_box_outline_blank'
                                                        }
                                                    />{' '}
                                                    {mun.name}
                                                </button>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
        </div>
    )
}

export default ServiceAreaListUltimate
