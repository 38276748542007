import { Icon } from '@advanza/ui'
import React from 'react'
import { useSelector } from 'react-redux'

const ProspectLabel = ({ providerId, emptyValue = '-', fontSize = 12 }) => {
    const { providers } = useSelector((state) => state.providers.entities)
    const provider = providers[providerId]
    const prospectLabel = provider.office_labels.filter(({ type }) => type === 'prospect')[0]
    const isNew = prospectLabel && prospectLabel.description.includes('*')
    return (
        <div
            style={{ cursor: 'help', whiteSpace: 'nowrap' }}
            title={`${prospectLabel && prospectLabel.description} prospect value ${
                isNew ? '(no data)' : ''
            }`}>
            {prospectLabel
                ? prospectLabel.description
                      .split('')
                      .map((i, it) => (
                          <Icon
                              style={{ color: isNew ? 'gray' : 'goldenrod' }}
                              fontSize={fontSize}
                              key={it}
                              name="euro_symbol"
                          />
                      ))
                : emptyValue}
        </div>
    )
}

export default ProspectLabel
