import Modal from '@advanza/modal'
import { LoadingDots, Icon } from '@advanza/ui'
import { call } from '@advanza/api'
import { useEmailStats } from 'hooks/closedLoopHooks'
import React, { useState } from 'react'
import style from './PartialMatchButton.module.css'

function usePartialMatchCheck(matchId) {
    const [response, setResponse] = useState(null)

    const onClick = () => {
        if (response === 'loading') {
            return
        }
        setResponse('loading')
        call('office/matches/partial-match-check/' + matchId).then((response) => {
            setResponse(JSON.stringify(response, null, 2))
        })
    }

    const clear = () => setResponse(null)

    return {
        response,
        onClick,
        clear,
    }
}
const PartialMatchButton = ({ matchId }) => {
    const { response, onClick, clear } = usePartialMatchCheck(matchId)
    return (
        <div>
            <button onClick={onClick}>
                <Icon name="broken_image" />
            </button>
            <Modal open={response} close={clear}>
                {response === 'loading' && <LoadingDots />}
                <pre>{response}</pre>
            </Modal>
        </div>
    )
}

export default PartialMatchButton
