import React, { useState } from 'react'
import MonthPicker from 'components/billing/MonthPicker'
import { InputField, SelectCheckbox, SelectSimple } from '@advanza/input/'
import { useReclaimReasons } from 'hooks/reclaimHooks'
import ServicesSelect from '../services/ServicesSelect'
import AutoRow from 'components/misc/AutoRow'
import CheckButton from 'components/CheckButton'

const ReclaimsFilters = ({ filter, changeFilter, showFilters }) => {
    const [searchValue, setSearchValue] = useState('')
    const { reclaimReasons } = useReclaimReasons()

    return (
        <AutoRow>
            {(!showFilters || showFilters.includes('service')) && (
                <ServicesSelect
                    onChangeValue={(serviceIds) => changeFilter({ serviceIds })}
                    value={filter.serviceIds || []}
                />
            )}
            {(!showFilters || showFilters.includes('date')) && (
                <MonthPicker
                    value={filter.aboutMonth}
                    onChange={(aboutMonth) => changeFilter({ aboutMonth })}
                />
            )}
            {(!showFilters || showFilters.includes('date')) && (
                <SelectSimple
                    options={[
                        { value: 1, name: 'last 1 month' },
                        { value: 3, name: 'last 3 months' },
                        { value: 6, name: 'last 6 months' },
                        { value: 12, name: 'last 12 months' },
                        { value: 24, name: 'last 24 months' },
                        { value: 0, name: '- All' },

                    ]}
                    placeholder='lastXMonths'
                    onChange={({ target }) => {
                        changeFilter({ lastXMonths: target.value })
                    }}
                    value={filter.lastXMonths}
                />
            )}    {(!showFilters || showFilters.includes('leadType')) && (
                <SelectSimple
                    options={[
                        { value: 'match', name: 'Match' },
                        { value: 'phone', name: 'Phone' }
                    ]}
                    placeholder='leadType'
                    onChange={({ target }) => {
                        changeFilter({ leadType: target.value })
                    }}
                    value={filter.leadType}
                />
            )}
            {(!showFilters || showFilters.includes('status')) && (
                <SelectSimple
                    options={[
                        { value: 'all', name: 'All' },
                        { value: 'open', name: 'Open' },
                        { value: 'accepted', name: 'Accepted' },
                        { value: 'denied', name: 'Denied' }
                    ]}
                    placeholder='select status'
                    onChange={({ target }) => {
                        changeFilter({ status: target.value })
                    }}
                    value={filter.status}
                />
            )}
            {(!showFilters || showFilters.includes('reason')) && (
                <SelectCheckbox
                    options={Object.keys(reclaimReasons)
                        .map((reasonId) => ({
                            value: parseInt(reasonId, 10),
                            name: reclaimReasons[reasonId].visible ? (
                                reclaimReasons[reasonId].name
                            ) : (
                                <s style={{ color: '#aaa' }}>{reclaimReasons[reasonId].name}</s>
                            )
                        }))
                        .concat({
                            value: -1,
                            name: 'No reason'
                        })}
                    onChangeValue={(values) => {
                        changeFilter({ reasonIds: values })
                    }}
                    value={filter.reasonIds}
                    placeholder='select reasons'
                />
            )}
            {(!showFilters || showFilters.includes('search')) && (
                <InputField
                    icon='search'
                    value={searchValue}
                    placeholder='search'
                    onChange={({ target }) => {
                        setSearchValue(target.value)
                    }}
                    onKeyDown={(e) => e.key === 'Enter' && changeFilter({ query: searchValue })}
                />
            )}
        </AutoRow>
    )
}

export default ReclaimsFilters
