import { Section } from '@advanza/advanza_generic'
import React from 'react'
import StatusesListContainer from '../../containers/StatusesListContainer'
import SalesLeadStatus from './SalesLeadStatus'

class SalesLeadStatusList extends React.Component {
    render() {
        const { pages = [[]], filter = {}, isFetching } = this.props
        const statusIds = pages[(filter && filter.page) || 0] || []

        if (statusIds.length === 0 && !isFetching) {
            return (
                <Section x y>
                    <em className="c-grey">no items</em>
                </Section>
            )
        }
        return (
            <div>
                {statusIds.map((id, i) => {
                    const isLast = i === statusIds.length - 1
                    return (
                        <Section key={id} s={isLast}>
                            <SalesLeadStatus id={id} />
                            {!isLast && <hr />}
                        </Section>
                    )
                })}
            </div>
        )
    }
}

SalesLeadStatusList.propTypes = {}

export default StatusesListContainer(SalesLeadStatusList)
