import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import LinearLoader from 'components/LinearLoader'
import AvatarEdit from 'components/providers/AvatarEdit'
import WebsiteImages from 'components/providers/WebsiteImages'
import EntityComponent from 'components/services/EntityComponent'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import React from 'react'
import { onFilesRejected } from '../../actions/files'
import { saveUser } from '../../actions/users'

class ProAvatarEdit extends EntityComponent {
    constructor(props) {
        super(props)
        this.onCrop = this.onCrop.bind(this)
        this.onChangeAvatar = this.onChangeAvatar.bind(this)
        this.onSelectWebsiteImage = this.onSelectWebsiteImage.bind(this)
    }

    onChangeAvatar(file) {
        const { avatarFile = {} } = this.props.entity
        this.onChangeEntity({
            avatarFile: { ...avatarFile, ...file },
            ...(file.filename && file.size
                ? { avatarImageDash: file.filename + '-' + file.size }
                : {}),
        })
    }

    editFields = {}

    onCrop(crop, crop2) {
        const { avatarFile = {} } = this.props.entity
        this.onChangeEntity({
            avatarFile: { ...avatarFile, crop: { ...crop, aspect: 1 }, crop2 },
        })
    }

    save() {
        super.save('providers').then((response) => {
            if (response) {
                this.onChangeEntity({
                    avatar_filename: response.user.avatar_filename,
                    avatarFile: null,
                    preview: null,
                    _isTouched: false,
                })
            }
        })
    }

    onSelectWebsiteImage(data, filename, size) {
        this.onChangeAvatar({
            preview: 'data:image/png;base64,' + data,
            base64: data,
            filename,
            size,
            crop: {},
        })
    }

    render() {
        const {
            entity = {},
            providerId,
            showSaveButton = true,
            blockedMap,
            lang = 'EN',
            onFilesRejected,
        } = this.props
        return (
            <div style={{ maxWidth: 500 }}>
                {entity._saving && <LinearLoader fixed />}
                <div>
                    <AvatarEdit
                        onChangeImage={this.onChangeAvatar}
                        onCrop={this.onCrop}
                        size={175}
                        user={entity}
                        crop={(entity.avatarFile && entity.avatarFile.crop) || {}}
                        file={entity.avatarFile}
                        blockedMap={blockedMap}
                        lang={lang}
                        onFilesRejected={onFilesRejected}
                    />
                </div>
                {showSaveButton && entity._isTouched && (
                    <Row end="xs">
                        <Col x>
                            <button className="btn" onClick={this.save} disabled={entity._saving}>
                                {entity._saving ? 'bezig..' : 'Opslaan'}
                            </button>
                        </Col>
                    </Row>
                )}
                <Divider s />
                <WebsiteImages
                    providerId={providerId}
                    buttonText={
                        lang === 'NL' ? 'Zoek website afbeeldingen' : 'Search website images'
                    }
                    onSelectImage={this.onSelectWebsiteImage}
                    blockedMap={blockedMap}
                    lang={lang}
                />
            </div>
        )
    }
}

ProAvatarEdit.propTypes = {}

export default EntityComponentContainer(ProAvatarEdit, {
    store: 'providers',
    name: 'users',
    saveFunc: saveUser,
    mapDispatchToProps: (dispatch) => {
        return {
            onFilesRejected: (files) => dispatch(onFilesRejected(files)),
        }
    },
})
