import {Col, Row} from '@advanza/grid'
import {Button, Divider} from '@advanza/ui'
import Page from 'components/ui/Page'
import React, {useState} from 'react'
import LicencesUpdatersList from './LicencesUpdatersList'
import {InputField, SelectSimple} from "@advanza/input";
import {call} from "@advanza/api";
import {useDispatch} from 'react-redux'
import LicencesSelect from "components/licences/LicencesSelect";

const LicencesUpdatersPage = ({filterId = 'all'}) => {
    const dispatch = useDispatch()
    const [isSaving, setIsSaving] = useState(false)
    const [selectLicence, setSelectLicence] = useState([])
    const [extractorId, setExtractorId] = useState('')

    return (
        <Page title="LicencesUpdaters">
            <Divider m/>
            <Row middle="xs">
                <Col x>
                    <h1>LicencesUpdaters</h1>
                </Col>
            </Row>
            <Divider m/>
            <Row>
                <Col sm={3}>
                    <LicencesSelect
                        placeholder="Select licence"
                        compact
                        multiple={false}
                        optionKeys={{msgKey: 'name', nameKey: 'name', valueKey: 'licence_id'}}
                        onValueChange={setSelectLicence}
                        value={selectLicence}
                        useSearch={true}
                        visibleSearch={true}
                    />
                </Col>
                <Col x>
                    <SelectSimple
                        placeholder="api"
                        compact
                        value="import.io"
                        options={[{value: 'import.io', title: 'import.io'}]}
                    />
                </Col>
                <Col x>
                    <InputField
                        placeholder="extractorId"
                        compact
                        value={extractorId}
                        onChange={(e) => setExtractorId(e.target.value)}
                    />
                </Col>
                <Col x>
                    <Button
                        disabled={!selectLicence.length || !extractorId || isSaving}
                        onClick={() => {
                            setIsSaving(true)
                            call('office/licences-updaters/save', {
                                json: {
                                    0: {
                                        licences_updater_id: '',
                                        licence_id: selectLicence.map(i => i.licence_id)[0],
                                        api: 'import.io',
                                        extractor: extractorId,
                                        active: 0,
                                    },
                                },
                            }).finally(() => {
                                setIsSaving(false)
                                dispatch({type: 'INVALIDATE_LICENCESUPDATERS'})
                            })
                        }}>
                        Add
                    </Button>
                </Col>
            </Row>
            <Divider m/>
            <LicencesUpdatersList filterId={filterId}/>
        </Page>
    )
}

export default LicencesUpdatersPage
