import style from './divider.module.css'
import React from 'react'
import PropTypes from 'prop-types'

const sizeNames = ['m', 'l', 's']
const sizes = {
    xs: 8,
    s: 16,
    sm: 24,
    m: 32,
    ml: 56,
    l: 64,
}

function getHeight(props) {
    for (let i = 0; i < sizeNames.length; i++) {
        if (props[sizeNames[i]]) {
            return sizes[sizeNames[i]]
        }
    }
    return sizes.s
}

const Divider = ({ name, height, ...rest }) => {
    return <div style={{ marginTop: getHeight(rest) / 2, marginBottom: getHeight(rest) / 2 }} />
}

Divider.propTypes = {}

export default Divider
