import { call } from '@advanza/api'
import { Divider, PreIcon } from '@advanza/ui'
import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

const TwoFactorSecret = () => {
    const [qrData, setQrData] = useState('')
    const [redirect, setRedirect] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        call('auth/get-two-factor-secret').then(
            ({ redirect, qrData }) => {
                const queryRedirect = new URLSearchParams(document.location.search).get('redirect')

                if (redirect) {
                    setRedirect(redirect + (queryRedirect ? `?redirect=${queryRedirect}` : ''))
                } else if (qrData) {
                    setQrData(qrData)
                }
            },
            ({ error }) => setError(error)
        )
    }, [])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            <h1>Two-factor authentication</h1>
            <Divider s />
            <h2>Secret</h2>
            <Divider m />
            {qrData && (
                <>
                    <Divider m />
                    Scan this QR code to get the secret in Google Authenticator (or similar app like
                    FreeOTP):
                    <Divider m />
                    <img src={qrData} alt="QR code" />
                </>
            )}
            <Divider m />
            <Link
                style={{ color: '#5788ff' }}
                to={`/two-factor-one-time-code${window.location.search}`}>
                Then go to the page to enter a one-time code
            </Link>
            {error && (
                <>
                    <Divider m />
                    <PreIcon icon="warning" style={{ color: 'red' }} outerStyle={{ color: 'red' }}>
                        {error}
                    </PreIcon>
                </>
            )}
        </>
    )
}

export default TwoFactorSecret
