import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    PROFILE_LABELS_CHANGE_FILTER,
    PROFILE_LABELS_RECEIVE_LIST,
    PROFILE_LABELS_REQUEST,
} from 'actions/profileLabels'
import { changeEntity, changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [PROFILE_LABELS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [PROFILE_LABELS_RECEIVE_LIST]: receiveList,
    [PROFILE_LABELS_CHANGE_FILTER]: changeFilter,
    INVALIDATE_PROVIDERS_LABELS: invalidateFilters,
    CHANGE_ENTITY_PROFILELABELS: changeEntity,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
