import { call } from '@advanza/api'
import websiteClicksSchema from 'schemes/websiteClicksSchema'
import { _changeFilter, _fetchItems } from './lists'

export const WEBSITECLICKS_REQUEST = 'WEBSITECLICKS_REQUEST'
export const WEBSITECLICKS_RECEIVE_LIST = 'WEBSITECLICKS_RECEIVE_LIST'
export const WEBSITECLICKS_CHANGE_FILTER = 'WEBSITECLICKS_CHANGE_FILTER'

export function fetchList(filterId) {
    return _fetchItems(filterId, {
        url: 'office/website-clicks/get-list',
        schema: websiteClicksSchema,
        requestActionName: WEBSITECLICKS_REQUEST,
        receiveActionName: WEBSITECLICKS_RECEIVE_LIST,
        reducerKey: 'websiteClicks',
    })
}

export function changeWebsiteClicksFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'websiteClicks',
        fetchFunc: fetchList,
        actionType: WEBSITECLICKS_CHANGE_FILTER,
    })
}

export function reclaimWebsiteClick(websiteClickId, date) {
    return function (dispatch, getState) {
        const websiteClick = getState().websiteClicks.entities.websiteClicks[websiteClickId]
        if (websiteClick.reclaimed) {
            return Promise.resolve()
        }
        return call(`office/reclaims/reclaim-manually/${websiteClickId}/website`, {
            method: 'post',
            json: { date },
        }).then((response) => {
            dispatch({ type: 'INVALIDATE_WEBSITECLICKS' })
            return Promise.resolve()
        })
    }
}
