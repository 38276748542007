import { Divider } from '@advanza/ui'
import Card from 'components/Card'
import { useEffect, useState } from 'react'
import { call } from '@advanza/api'
import { formatPriceWithoutCurrency } from '@advanza/func'

const UpcomingInvoiceCard = ({ provider }: { provider: any }) => {
    const [data, setData] = useState<
        | {
              amount_of_all_charges_except_reclaims: number
              amount_of_all_reclaims: number
              total_amount_debited: number
          }
        | undefined
    >()
    useEffect(() => {
        call(`office/providers/get-upcoming-invoice-info/${provider.service_provider_id}`).then(
            (response) => {
                setData(response.data)
            }
        )
    }, [])

    if (!data) {
        return null
    }

    const isNotSignedAndHasNoUpcomingInvoice =
        !provider.isSigned &&
        !data.amount_of_all_reclaims &&
        !data.amount_of_all_charges_except_reclaims &&
        !data.total_amount_debited

    if (isNotSignedAndHasNoUpcomingInvoice) {
        return null
    }

    return (
        <Card header="Upcoming invoice">
            <div>
                Total received requests:{' '}
                {`€${formatPriceWithoutCurrency(data.amount_of_all_charges_except_reclaims)}`}
            </div>
            <Divider s />
            <div>
                Deduction accepted reclaims:{' '}
                {`€${formatPriceWithoutCurrency(data.amount_of_all_reclaims)}`}
            </div>
            <Divider s />
            <div>
                Total amount next invoice (excl. VAT):{' '}
                {`€${formatPriceWithoutCurrency(data.total_amount_debited)}`}
            </div>
        </Card>
    )
}

export default UpcomingInvoiceCard
