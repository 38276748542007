import React from 'react'
import { Col, Row } from '@advanza/grid'
import { SelectCheckbox } from '@advanza/input'
import { Divider } from '@advanza/ui'
import Page from 'components/ui/Page'
import CancellationsList, { useCancellationsList } from './CancellationsList'

const CancellationsPage = ({ filterId = 'all' }) => {
    const {
        changeFilter,
        filter = {},
        extra: { cancelReasons = {} } = {},
    } = useCancellationsList(filterId)

    return (
        <Page title="Cancellations">
            <Divider m />
            <Row middle="xs">
                <Col x>
                    <h1>Cancellations</h1>
                </Col>
                <Col x>
                    <SelectCheckbox
                        value={filter.flags}
                        placeholder="All Cancellations"
                        options={Object.keys(cancelReasons).map((key) => ({
                            name: cancelReasons[key],
                            value: parseInt(key, 10),
                        }))}
                        onChangeValue={(flags) => changeFilter({ flags })}
                    />
                </Col>
            </Row>
            <Divider m />
            <CancellationsList filterId={filterId} />
        </Page>
    )
}

export default CancellationsPage
