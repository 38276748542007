import React from 'react'
import { connect } from 'react-redux'
import { selectItems } from '../actions/lists'
import { fetchReviews } from '../actions/reviews'

function mapStateToProps(state, props) {
    const { entities: reviewEntities, result, isFetching, filters } = state.reviews
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    return {
        reviewEntities,
        pages: result[searchKey] || {},
        filter,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        select: (ids, filterId) => dispatch(selectItems('reviews', ids, filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
