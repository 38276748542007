import { Button, LoadingDots, LinearLoader, Divider, PreIcon } from '@advanza/ui'
import QualityScore from 'components/providers/QualityScore'
import { useQualityScores } from 'hooks/providerHooks'
import React from 'react'
import style from './QualityScores.module.css'

const QualityScores = ({ providerId }) => {
    const { scores, recalcQualityScore, statsStartDate, debugInfo, isReCalcing, isLoading } =
        useQualityScores(providerId) || []
    return (
        <div className={style.root}>
            <Button name="text" onClick={() => recalcQualityScore(providerId)}>
                {isReCalcing ? (
                    <span>
                        calculating
                        <LoadingDots />
                    </span>
                ) : (
                    <PreIcon primColor icon="refresh">
                        Recalculate Quality Scores
                    </PreIcon>
                )}
            </Button>
            {isLoading && <LinearLoader />}
            {statsStartDate && (
                <div className={style.warning}>
                    <PreIcon icon="warning" red>
                        Stats start date set: {statsStartDate}
                    </PreIcon>
                </div>
            )}
            {scores.map((score, i) => (
                <div key={i}>
                    <QualityScore
                        open={i === 0}
                        first={i === 0}
                        data={score}
                        dataCompare={scores[i + 1]}
                        debugInfo={debugInfo[score.quality_score_id]}
                    />
                    <Divider l={i === 0} />
                </div>
            ))}
        </div>
    )
}

export default QualityScores
