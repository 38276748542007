import { Avatar } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import Card from 'components/Card'
import TableList from 'components/TableList'
import { useList } from 'hooks/listHooks'
import React from 'react'
import { useSelector } from 'react-redux'
import demandUsersSchema from 'schemes/demandUsersSchema'
import DemandUserEntity from './DemandUserEntity'
import style from './demandUsersList.module.css'

export function useDemandUsersList(filterId) {
    const { countries } = useSelector((state) => state.countries)
    return useList(
        filterId,
        {
            url: 'office/demand-users/get-list',
            schema: demandUsersSchema,
            reducerKey: 'demandUsers',
        },
        { countries, sorting: ['id', 'DESC'] }
    )
}

const DemandUsersList = ({ filterId = 'all' }) => {
    const {
        entities: { demandUsers = {} } = {},
        extra: { definitionsDemandUsers = {} } = {},
        ...list
    } = useDemandUsersList(filterId)

    const renderRow = (demandUserId) => {
        const demandUser = demandUsers[demandUserId] || {}
        return {
            cols: Object.keys(definitionsDemandUsers).map((key) => {
                if (key === 'first_name') {
                    return (
                        <Row middle="xs" className={style.noFlexWrap}>
                            <Col x>
                                <Avatar useLetterColor user={demandUser} sizePx={25} />
                            </Col>
                            <Col x>
                                <div className={style.ellipsis}>{demandUser.first_name}</div>
                            </Col>
                        </Row>
                    )
                }

                return demandUser[definitionsDemandUsers[key].displayField || key]
            }),
            expandable: (
                <Card expandableStyle>
                    <DemandUserEntity demandUserId={demandUserId} />
                </Card>
            ),
            expandOnClick: true,
        }
    }

    return (
        <TableList
            definitions={definitionsDemandUsers}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default DemandUsersList
