import React from 'react'

class LinearLoader extends React.Component {
    render() {
        const { fixed } = this.props
        return (
            <div className={`linear-progress-material ${fixed ? 'fixed' : ''}`}>
                <div className="bar bar1" />
                <div className="bar bar2" />
            </div>
        )
    }
}

LinearLoader.propTypes = {}

export default LinearLoader
