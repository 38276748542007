import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { Button, Divider, Icon, LoadingDots } from '@advanza/ui'
import { fetchProvider } from 'actions/providers'
import AdminUser from 'components/adminUsers/AdminUser'
import CheckButton from 'components/CheckButton'
import DatePicker from 'components/DatePicker'
import { SQL_DATE_FORMAT_FNS, utcToLocal } from 'date'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './salesPromos.module.css'

function useSalesPromos(providerId) {
    const provider = useSelector((state) => state.providers.entities.providers[providerId])
    const { isFetching } = useSelector((state) => state.providers)
    const dispatch = useDispatch()
    const [entity, setEntity] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [errors, setErrors] = useState({})

    const onSave = () => {
        if (
            !entity.discount ||
            entity.discount < 1 ||
            entity.discount > 100 ||
            !entity.start_date ||
            (entity.end_date && new Date(entity.end_date) < new Date(entity.start_date))
        ) {
            setErrors({ other: 'Check the fields' })
            return Promise.reject()
        }
        return call('office/providers/save-promo', { json: entity, providerId })
            .then(
                () => {
                    setEntity({})
                    dispatch(fetchProvider(providerId, true))
                },
                (response) => {
                    setErrors({
                        other: JSON.stringify(response),
                        ...response,
                    })
                }
            )
            .finally(() => setIsSaving(false))
    }
    const onDelete = (id) => {
        setIsSaving(id)
        return call('office/providers/delete-promo/' + id, { method: 'post' })
            .then(
                () => {
                    dispatch(fetchProvider(providerId, true))
                },
                (response) => {
                    setErrors({
                        other: JSON.stringify(response),
                    })
                }
            )
            .finally(() => setIsSaving(false))
    }

    return {
        promos: provider.sales_promos || [],
        onChange: (e) => {
            setEntity({ ...entity, [e.target.name]: e.target.value })
            setErrors({})
        },
        setEntity,
        onDelete,
        entity,
        onSave,
        isSaving: isFetching || isSaving,
        errors,
    }
}

const SalesPromos = ({ providerId }) => {
    const { entity, onSave, onDelete, isSaving, errors, onChange, setEntity, promos } =
        useSalesPromos(providerId)
    const newEntityOptions = {
        service_provider_id: providerId,
        discount: 50,
        start_date: utcToLocal(new Date(), SQL_DATE_FORMAT_FNS),
        end_date: null,
    }
    return (
        <div>
            <Button name="text" onClick={() => setEntity(newEntityOptions)}>
                Add promo
            </Button>
            {entity.start_date && (
                <div className={style.new}>
                    <Row style={{ flexWrap: 'no-wrap' }} middle="xs">
                        <Col x>
                            <InputField
                                placeholder="Discount"
                                legend
                                name="discount"
                                value={entity.discount}
                                inputStyle={{ maxWidth: 75 }}
                                after={'%'}
                                error={errors.discount}
                                onChange={onChange}
                                type="number"
                            />
                        </Col>
                        <Col x>
                            <DatePicker
                                placeholder="Start date"
                                legend
                                name="start_date"
                                onChange={onChange}
                                withIcon={false}
                                maxWidth={130}
                                large
                                error={errors.start_date}
                                format="YYYY-MM-DD"
                                dayPickerProps={{ disabled: { before: new Date() } }}
                                value={entity.start_date}
                            />
                        </Col>
                        <Col x>
                            <DatePicker
                                placeholder="End date"
                                legend
                                name="end_date"
                                onChange={onChange}
                                withIcon={false}
                                maxWidth={130}
                                large
                                error={errors.end_date}
                                format="YYYY-MM-DD"
                                dayPickerProps={{
                                    disabled: { before: new Date(entity.start_date) },
                                }}
                                value={entity.end_date}
                                clickUnselectsDay
                            />
                        </Col>
                        <Col x>
                            <CheckButton
                                msg="For lifetime"
                                checked={!entity.end_date}
                                onChange={() =>
                                    onChange({
                                        target: {
                                            name: 'end_date',
                                            value: entity.end_date
                                                ? null
                                                : utcToLocal(new Date(), SQL_DATE_FORMAT_FNS),
                                        },
                                    })
                                }
                            />
                        </Col>
                    </Row>
                    <Divider m />
                    {errors.other && <div style={{ color: 'red' }}>{errors.other}</div>}
                    <Button disabled={isSaving} onClick={onSave}>
                        {isSaving ? <LoadingDots color="#fff" /> : 'Add discount'}
                    </Button>
                </div>
            )}
            <Divider />
            {promos.map(({ sales_promo_id, discount, user_id, start_date, end_date }) => {
                const isOld = end_date && new Date(end_date + ' 23:59:50') < new Date()
                const className = [
                    style.item,
                    isOld ? style.old : '',
                    new Date(start_date + ' 00:00:00') <= new Date() && !isOld ? style.active : '',
                ].join(' ')
                return (
                    <Row key={sales_promo_id} className={className}>
                        <Col xs={2}>{discount}%</Col>
                        <Col xs={2}>{utcToLocal(start_date, 'dd MMM')}</Col>
                        <Col xs={1}>&mdash;</Col>
                        <Col xs={2}>
                            {end_date ? utcToLocal(end_date, 'dd MMM') : 'For lifetime'}
                        </Col>
                        <Col xs={4}>
                            By <AdminUser userId={user_id} />
                        </Col>
                        <Col x className={style.delete}>
                            <button
                                disabled={isSaving === sales_promo_id}
                                onClick={() => onDelete(sales_promo_id)}>
                                {isSaving === sales_promo_id ? (
                                    <LoadingDots />
                                ) : (
                                    <Icon name="delete_forever" />
                                )}
                            </button>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default SalesPromos
