import { SelectCheckbox } from '@advanza/input'
import { call } from '@advanza/api'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const PlatformsSelect = ({ ...rest }) => {
    const dispatch = useDispatch()
    const { isFetchingPlatforms, hasFetchedPlatforms, platforms } = useSelector(
        (state) => state.providers.platforms
    )
    useEffect(() => {
        if (isFetchingPlatforms || hasFetchedPlatforms) {
            return
        }
        dispatch({ type: 'PLATFORMS_REQUEST' })
        call('office/providers-platforms/get-platforms').then(
            ({ platforms }) =>
                dispatch({
                    type: 'PLATFORMS_RECEIVE',
                    platforms: Array.isArray(platforms) ? {} : platforms,
                }),
            () => dispatch({ type: 'PLATFORMS_RECEIVE' })
        )
    }, [dispatch, hasFetchedPlatforms, isFetchingPlatforms])

    return (
        <SelectCheckbox
            options={Object.keys(platforms).map((id) => {
                return {
                    value: parseInt(id),
                    name: platforms[id],
                }
            })}
            placeholder="platforms"
            {...rest}
        />
    )
}

export default PlatformsSelect
