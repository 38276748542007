import { Col, Row } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import Modal, { useScrollModal } from '@advanza/modal'
import { Button, Divider, Icon, LoadingDots } from '@advanza/ui'
import DatePicker from 'components/DatePicker'
import { DaySchemeSet, PauseTypeSet } from 'components/pauses/pauseEnums'
import SafeButton from 'components/ui/SafeButton'
import { format } from 'date-fns'
import { DAY_SCHEMES, countDays, useProvidersDate } from 'hooks/providersDatesHooks'
import { Provider } from 'interfaces/Providers'

interface Props {
    addMode?: boolean
    provider: Provider
    providerDateId?: number | string
    onSucces?: () => void
    store?: string
}

const PauseDateEdit = ({ addMode = false, provider, providerDateId, onSucces, store }: Props) => {
    const { openModal, closeModal, renderModal } = useScrollModal()
    const {
        isFetchingPauseReasons,
        pauseReasons,
        providersDate,
        onChange,
        onChangeEntity,
        onSave,
        isSaving,
        errors,
        setErrors,
        onCancel,
        onDelete,
    } = useProvidersDate(providerDateId, onSucces, store)

    const onOpen = () => {
        if (addMode) {
            onChangeEntity(
                {
                    service_provider_id: provider.service_provider_id,
                    pause_type: PauseTypeSet.RECEIVE,
                    day_scheme: DaySchemeSet.NO_REPEAT,
                    date_from: '',
                    date_to: '',
                },
                true
            )
        }
        openModal()
    }

    const isRepeated = providersDate?.day_scheme > 0

    const editModal = renderModal({
        heading: (
            <Row middle="xs">
                <Col x>
                    {addMode ? 'Add' : 'Edit'} pause period{' '}
                    {isRepeated && (
                        <>
                            (<Icon name="repeat" fontSize={16} red={providersDate.pause_deleted} />{' '}
                            {/* @ts-ignore*/}
                            <small>{DAY_SCHEMES[providersDate.day_scheme]}</small>)
                        </>
                    )}
                    {providersDate.date_to && !isRepeated && (
                        <>
                            (
                            {countDays(
                                providersDate.date_from
                                    ? new Date(providersDate.date_from)
                                    : new Date(),
                                new Date(providersDate.date_to)
                            )}{' '}
                            days)
                        </>
                    )}{' '}
                </Col>
                {!addMode && (
                    <Col x>
                        <Button name="bordered">
                            <SafeButton
                                action={() => onDelete().then(() => closeModal())}
                                cancelText={false}
                                // @ts-ignore
                                confirmMessage="Confirm delete?"
                                buttonText={
                                    <>
                                        <Icon name="delete" /> Delete pause
                                    </>
                                }
                            />
                        </Button>
                    </Col>
                )}
            </Row>
        ),
        headingBgColor: 'transparent',
        body: (
            <>
                <Divider m />
                <Row>
                    {providersDate.date_from && (
                        <Col xs={12} sm={6}>
                            <div>
                                <DatePicker
                                    dayPickerProps={{
                                        disabled: { after: new Date(providersDate.date_to) },
                                        defaultMonth: providersDate.date_from
                                            ? new Date(providersDate.date_from)
                                            : new Date(),
                                    }}
                                    onChange={onChange}
                                    name="date_from"
                                    legend
                                    placeholder="On pause from"
                                    opened
                                    value={providersDate.date_from || null}
                                />
                            </div>
                        </Col>
                    )}
                    <Col xs={12} sm={!providersDate.date_from ? 12 : 6}>
                        {!providersDate.date_from && (
                            <div>
                                <Button
                                    name="text"
                                    onClick={() =>
                                        onChangeEntity({
                                            date_from: format(new Date(), 'yyyy-MM-dd'),
                                        })
                                    }>
                                    Add from date
                                </Button>
                                <Divider m />
                            </div>
                        )}
                        <div>
                            <DatePicker
                                dayPickerProps={{
                                    disabled: {
                                        before: new Date(providersDate.date_from || new Date()),
                                    },
                                    defaultMonth: providersDate.date_to
                                        ? new Date(providersDate.date_to)
                                        : new Date(),
                                }}
                                onChange={onChange}
                                opened
                                legend
                                placeholder="On pause till"
                                name="date_to"
                                value={providersDate.date_to || null}
                            />
                        </div>
                    </Col>
                </Row>
                {!isRepeated && (
                    <>
                        <Divider m />
                        {isFetchingPauseReasons ? (
                            <>
                                Loading pause reasons <LoadingDots />
                            </>
                        ) : (
                            // @ts-ignore
                            <SelectSimple
                                legend
                                value={providersDate.pause_reason_id || '_hidden_'}
                                placeholder="Select pause reason"
                                hiddenOption={'Make a choice...'}
                                options={Object.keys(pauseReasons)
                                    .filter(
                                        (reasonId) =>
                                            (!provider.isSignedOrFreeTrial
                                                ? !pauseReasons[reasonId].premium_only
                                                : true) &&
                                            pauseReasons[reasonId].country_code ===
                                                provider.country_code
                                    )
                                    .map((reasonId) => {
                                        const option = pauseReasons[reasonId]
                                        return {
                                            name: option.reason,
                                            value: option.pause_reason_id,
                                        }
                                    })}
                                onChange={(e: any) =>
                                    onChangeEntity({ pause_reason_id: e.target.value })
                                }
                            />
                        )}
                    </>
                )}
                <Divider m />
            </>
        ),
        buttons: [
            {
                text: 'Cancel',
                name: 'link',
                func: onCancel,
                disabled: isSaving,
            },
            {
                text: isSaving ? 'Saving...' : 'Save pause period',
                func: onSave,
                disabled:
                    isSaving ||
                    !providersDate._isTouched ||
                    !providersDate.date_to ||
                    (!isRepeated && !providersDate.pause_reason_id),
            },
        ],
        closeButton: {
            func: onCancel,
        },
        maxWidth: !providersDate.date_from ? '420px' : '800px',
    })

    return (
        <>
            <Button name="text" onClick={onOpen}>
                {addMode ? 'Add pause period' : <Icon name="edit" />}
            </Button>
            {editModal}
            {/* @ts-ignore */}
            <Modal open={errors} close={() => setErrors(null)}>
                <h3>Error:</h3>
                <Divider />
                {JSON.stringify(errors)}
            </Modal>
        </>
    )
}

export default PauseDateEdit
