import { Icon } from '@advanza/ui'
import React from 'react'
import style from './label.module.css'

const Label = ({ text, bgColor, icon, color }) => {
    const colors = bgColor && bgColor.replace('#', '')
    return (
        <div
            style={{
                backgroundColor: bgColor,
                color,
            }}
            className={style.label}>
            {icon && <Icon style={{ color }} name={icon} fontSize={17} />} {text}
        </div>
    )
}

Label.propTypes = {}

export default Label
