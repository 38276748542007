import { Banner } from '@advanza/advanza_generic'
import { FileDropZone } from '@advanza/input'
import { Icon, Button } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import Balloon from '../ui/Balloon'

class EditImage extends React.Component {
    constructor(props) {
        super(props)
        this.onDropImg = this.onDropImg.bind(this)
        this.saveImage = this.saveImage.bind(this)
        this.state = {
            image: false,
            loading: false,
        }
    }

    onDropImg(files) {
        const Reader = new FileReader()
        Reader.onloadend = (e) => this.setState({ image: { url: e.target.result, file: files[0] } })
        Reader.readAsDataURL(files[0])
    }

    saveImage(e) {
        e.stopPropagation()
        const { save, context } = this.props
        const { image } = this.state
        if (!image) {
            return
        }
        this.setState({ loading: true })
        save(image.file, context).then(() => {
            this.setState({
                loading: false,
                image: false,
                savedImgId: window.performance.now().toString(),
            })
        })
    }

    render() {
        const { src, children, height, bgPositionSize, mime, onFilesRejected } = this.props
        const { image, savedImgId, loading } = this.state
        const bannerUrl = image ? image.url : src + `?q=${savedImgId}`

        return (
            <Balloon
                style={{ display: 'block' }}
                content={image ? null : loading ? 'loading' : 'Change image'}>
                <FileDropZone
                    style={{ width: '100%', cursor: loading ? 'wait' : 'pointer' }}
                    mime={mime}
                    multiple={false}
                    maxFiles={1}
                    onFilesAccepted={this.onDropImg}
                    onFilesRejected={onFilesRejected}>
                    <Banner
                        src={bannerUrl}
                        size={height}
                        opacity="0.7"
                        backgroundColor="transparent"
                        bgImg
                        bgImgPosition={bgPositionSize || 'center top / cover'}>
                        <div style={{ textAlign: 'center' }}>{children}</div>
                        {image && (
                            <Button name="default" onClick={this.saveImage}>
                                {loading ? 'saving..' : 'save'}{' '}
                                <Icon color="black">{loading ? 'hourglass_empty' : 'save'}</Icon>
                            </Button>
                        )}
                    </Banner>
                </FileDropZone>
            </Balloon>
        )
    }
}

EditImage.propTypes = {
    context: PropTypes.string,
    height: PropTypes.number,
    save: PropTypes.func,
    src: PropTypes.string,
}

export default EditImage
