import { FileDropZone } from '@advanza/input'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import { onFilesRejected } from 'actions/files'
import { getTitle, useProfileBuilderWizard } from 'hooks/profileBuilderHooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import style from './barImage.module.css'

const BarImage = ({
    providerId,
    disabled,
    url,
    context,
    weight,
    onAddFile,
    multi = false,
    width = 120,
}) => {
    const dispatch = useDispatch()
    const {
        user = {},
        imageFiles = [],
        notFoundContexts = {},
        currentContext,
        currentWeight,
        getScrapedImages,
        changeSelection,
        selectImage,
        deselectImage,
    } = useProfileBuilderWizard(providerId)
    const title = getTitle(context, weight)
    const isCurrent = context === currentContext && weight === currentWeight

    const onFilesAccepted = (files) =>
        files.forEach((file) => {
            const Reader = new FileReader()
            Reader.onloadend = (e) => onAddFile(e.target.result, file)
            Reader.readAsDataURL(file)
        })

    return (
        <div
            className={style.root}
            style={{ width }}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
                e.preventDefault()
                const key = e.dataTransfer.getData('text/plain')
                const scrapedImage = getScrapedImages().find(
                    (scrapedImage) => scrapedImage.key === key
                )

                if (scrapedImage && !disabled) {
                    selectImage(scrapedImage, context, weight)
                }
            }}>
            {url ? (
                <Button
                    className={[style.boxButton, style.found].join(' ')}
                    name="btnReset"
                    style={{
                        background: `url(${url}) no-repeat center center /contain`,
                    }}
                    disabled={disabled}
                    onClick={() =>
                        deselectImage(
                            context === 'avatar'
                                ? user.avatarImageDash
                                : imageFiles.find(
                                      (imageFile) =>
                                          imageFile.context === context &&
                                          imageFile.weight === weight
                                  )
                        )
                    }>
                    <div className={style.iconSelectedBlock}>
                        <Icon name="remove" />
                    </div>
                </Button>
            ) : (
                <FileDropZone
                    disabled={disabled}
                    allowedTypes={['image']}
                    multiple={multi}
                    maxFiles={multi ? 0 : 1}
                    onFilesAccepted={onFilesAccepted}
                    onFilesRejected={(files) => dispatch(onFilesRejected(files))}>
                    <Button
                        className={[style.boxButton, isCurrent ? style.isCurrent : ''].join(' ')}
                        name="btnReset"
                        disabled={disabled}>
                        <div className={style.title}>{title}</div>
                        <Icon classNameIcon={style.icon} name="file_upload" />
                    </Button>
                </FileDropZone>
            )}
            <Divider xs />
            <div className={style.bottom}>
                {url ? (
                    <PreIcon className={style.foundLine} icon="check_small">
                        {title}
                    </PreIcon>
                ) : (
                    isCurrent && (
                        <Button
                            className={style.notFoundButton}
                            name="text"
                            disabled={disabled}
                            onClick={() =>
                                changeSelection('images', {
                                    notFoundContexts: { ...notFoundContexts, [context]: true },
                                })
                            }>
                            Niet gevonden
                        </Button>
                    )
                )}
            </div>
        </div>
    )
}

export default BarImage
