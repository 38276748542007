import { connect } from 'react-redux'
import { fetchLabels } from 'actions/profileLabels'

function mapStateToProps(state, props) {
    const { entities, result, isFetching, filters } = state.profileLabels
    const { profileLabels } = entities
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    return {
        profileLabels,
        filter,
        pages: result[searchKey] || {},
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchLabels: (filterId) => dispatch(fetchLabels(filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
