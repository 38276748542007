import { APP, Icon } from '@advanza/advanza_generic'
import { Row, Col } from '@advanza/grid'
import { FileDropZone } from '@advanza/input'
import { saveFile, deleteFile, onFilesRejected } from 'actions/files'
import Divider from 'components/Divider'
import LinearLoader from 'components/LinearLoader'
import EntityComponent from 'components/services/EntityComponent'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import React from 'react'
import css from './entity.module.css'

class ProfileFileEntity extends EntityComponent {
    constructor(props) {
        super(props)
        this.getUrl = this.getUrl.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    editFields() {
        return {
            description: {
                type: 'text',
                placeholder: 'Bijschrift',
                onClick: (e) => e.stopPropagation(),
            },
            context: {
                type: 'select',
                className: 'select-stealth',
                options: [
                    { value: 'profile', title: 'profile' },
                    { value: 'banner', title: 'banner' },
                    { value: 'logo', title: 'logo' },
                    { value: 'hide_pro', title: 'hide_pro' },
                ],
            },
        }
    }

    getUrl(size = 'l') {
        const { entity } = this.props
        const isNew = typeof entity.file_id === 'string'
        if (entity.previewUrl) {
            return entity.previewUrl
        }
        if (entity.preview) {
            return entity.preview
        }
        if (entity.file_key) {
            const parts = entity.file_key.split('/')
            const fileName = parts.pop()

            return APP.S3_PUBLIC() + parts.join('/') + `/${size}_` + fileName
        }
    }

    save(e) {
        e.preventDefault()
        e.stopPropagation()
        super.save()
    }

    delete(e) {
        e.preventDefault()
        e.stopPropagation()
        super.delete()
    }

    onDrop(files) {
        const file = files[0]
        this.setState({ loading: true })
        const Reader = new FileReader()
        Reader.onloadend = (e) => {
            this.onChangeEntity({
                previewUrl: e.target.result,
                file,
                filename: file.name,
            })
            this.setState({ loading: false })
        }
        Reader.readAsDataURL(file)
    }

    render() {
        const { entity, height = 200, onFilesRejected } = this.props
        const style = {
            background: `url(${this.getUrl('m')}) no-repeat center center /cover`,
            height,
        }
        const isNew = typeof entity.file_id === 'string'
        return (
            <div>
                <div className={[css.fileEntity, entity._saving ? css.busy : ''].join(' ')}>
                    <Row between="xs" className={css.buttonRow}>
                        <Col xs>
                            {entity._isTouched && !isNew && (
                                <button onClick={this.save}>
                                    <Icon>save</Icon>
                                    <b>{entity._saving ? 'bezig..' : 'Opslaan'}</b>
                                </button>
                            )}
                        </Col>
                        <Col x>
                            <FileDropZone
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absoulte',
                                    cursor: 'pointer',
                                }}
                                allowedTypes={['image']}
                                onFilesAccepted={this.onDrop}
                                onFilesRejected={onFilesRejected}>
                                <button>
                                    <Icon>insert_photo</Icon>
                                </button>
                            </FileDropZone>
                        </Col>

                        <Col x>
                            <button onClick={this.delete}>
                                <Icon>delete_forever</Icon>
                            </button>
                        </Col>
                    </Row>
                    <div style={style}></div>
                    {entity._saving && <LinearLoader fixed />}
                </div>
                <Divider l />
                <div className={css.imageInput}>{this.renderInput('context')}</div>
            </div>
        )
    }
}

ProfileFileEntity.propTypes = {}

export default EntityComponentContainer(ProfileFileEntity, {
    store: 'files',
    name: 'files',
    saveFunc: saveFile,
    deleteFunc: deleteFile,
    mapDispatchToProps: (dispatch) => {
        return {
            onFilesRejected: (files) => dispatch(onFilesRejected(files)),
        }
    },
})
