import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    STATUSES_CHANGE_FILTER,
    STATUSES_RECEIVE_LIST,
    STATUSES_REQUEST,
} from '../actions/statuses'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
    selectItems,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [STATUSES_RECEIVE_LIST]: receiveList,
    [STATUSES_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: action.filterId || true },
        }),
    [STATUSES_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_STATUSES: changeEntity,
    INVALIDATE_STATUSES: invalidateFilters,
    STATUSES_SELECT_ITEMS: selectItems,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
