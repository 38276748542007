import React, { useState } from 'react'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import SearchPro from 'components/misc/SearchPro'
import SafeButton from 'components/ui/SafeButton'
import AutoRow from 'components/misc/AutoRow'
import ProSearchResult from 'components/misc/ProSearchResult'
import { useDispatch } from 'react-redux'
import { invalidateMatches } from 'actions/matches'
import { call } from '@advanza/api'

function useAddMatchManually(requestId, afterAddFn = null) {
    const [isAdding, setIsAdding] = React.useState(false)
    const [isAdded, setIsAdded] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [ignorePartialCheck, setIgnorePartialCheck] = React.useState(false)

    const addMatchManually = (providerId) => {
        setIsAdding(true)
        return call('office/requests/add-match-manually', {
            json: {
                requestId,
                providerId,
                ignorePartialCheck,
            },
        }).then(
            ({ match }) => {
                setIsAdded(true)
                afterAddFn && afterAddFn(match)
                setIsAdding(false)
                return Promise.resolve()
            },
            (err) => {
                setError(err)
                return Promise.reject(err)
            }
        )
    }

    return {
        addMatchManually,
        isAdding,
        ignorePartialCheck,
        setIgnorePartialCheck,
        isAdded,
        error,
    }
}

const AddMatchManuallyButton = ({ requestId = 0, serviceId = 0, filterId = '' }) => {
    const [showProsSearch, setShowProsSearch] = useState(false)
    const [selectedPro, setSelectedPro] = useState(null)
    const dispatch = useDispatch()
    const { addMatchManually, isAdded, ignorePartialCheck, setIgnorePartialCheck } =
        useAddMatchManually(requestId, () => {
            dispatch(invalidateMatches(filterId))
            dispatch({ type: 'INVALIDATE_SYSTEM_LOGS' })
            setSelectedPro(null)
            setShowProsSearch(false)
        })
    return (
        <AutoRow alignTop>
            <Button
                name="text"
                onClick={() => {
                    showProsSearch && setSelectedPro(null)
                    setShowProsSearch(showProsSearch !== true)
                }}>
                <PreIcon primColor={!showProsSearch} icon={showProsSearch ? 'close' : 'add'}>
                    {showProsSearch ? '' : 'Add match manually'}
                </PreIcon>
            </Button>
            {isAdded && (
                <PreIcon color="green" icon="celebration">
                    Match added!
                </PreIcon>
            )}
            {showProsSearch && (
                <div>
                    <SearchPro serviceId={serviceId} onSelect={setSelectedPro} />
                    {selectedPro && (
                        <div>
                            <Divider />
                            <AutoRow>
                                <Icon fontSize={18} name="check_box" />
                                <ProSearchResult
                                    providerId={selectedPro.service_provider_id}
                                    businessName={selectedPro.business_name}
                                    city={selectedPro.city}
                                    avatarFileName={selectedPro.avatarFileName}
                                    userId={selectedPro.userId}
                                />
                            </AutoRow>
                            <Divider />
                            <AutoRow>
                                <small>Allow partial match</small>
                                <Icon
                                    primColor={ignorePartialCheck}
                                    name={ignorePartialCheck ? 'toggle_on' : 'toggle_off'}
                                    onClick={() =>
                                        setIgnorePartialCheck(ignorePartialCheck !== true)
                                    }
                                />
                                <SafeButton
                                    textButton
                                    action={() => addMatchManually(selectedPro.service_provider_id)}
                                    useErrorPopup
                                    buttonText="Add match"
                                />
                            </AutoRow>
                        </div>
                    )}
                </div>
            )}
        </AutoRow>
    )
}

export default AddMatchManuallyButton
