import TableList from 'components/TableList'
import { useList } from 'hooks/listHooks'
import React from 'react'
import { Link } from 'react-router-dom'
import providersBuildersSchema from 'schemes/providersBuildersSchema'

export function useProvidersBuildersList(filterId) {
    return useList(
        filterId,
        {
            url: 'office/providers-builders/get-list',
            schema: providersBuildersSchema,
            reducerKey: 'providersBuilders',
        },
        { sorting: ['service_provider_id', 'ASC'] }
    )
}

const ProvidersBuildersList = ({ filterId = 'all' }) => {
    const {
        entities: { providersBuilders = {} } = {},
        extra: { definitionsProvidersBuilders = {} } = {},
        ...list
    } = useProvidersBuildersList(filterId)

    const renderRow = (providerId) => {
        const providersBuilder = providersBuilders[providerId] || {}
        return {
            cols: Object.keys(definitionsProvidersBuilders).map((key) => {
                if (key === 'business_name') {
                    return (
                        <Link
                            title="To pro"
                            className="plain"
                            to={`/service/providers/provider/${providersBuilder.service_provider_id}`}>
                            {providersBuilder.business_name}
                        </Link>
                    )
                }

                return providersBuilder[key]
            }),
        }
    }

    return (
        <TableList
            definitions={definitionsProvidersBuilders}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default ProvidersBuildersList
