import { Icon, Row, Section } from '@advanza/advanza_generic'
import { FileDropZone } from '@advanza/input'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import FilesFilterContainer from '../../containers/FilesFilterContainer'
import Pagination from '../Pagination'
import SafeButton from '../ui/SafeButton'

class FilesFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.deleteSelection = this.deleteSelection.bind(this)
        this.saveSelection = this.saveSelection.bind(this)
        this.onDropFiles = this.onDropFiles.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    deleteSelection() {
        const { filter, deleteSelection } = this.props
        return deleteSelection(filter.filterId)
    }

    saveSelection() {
        const { filter, saveSelection, addImageOptions } = this.props
        return saveSelection(filter.filterId, addImageOptions)
    }

    onDropFiles(files) {
        const { addFile, filterId, newEntityOptions } = this.props
        files.forEach((file) => {
            const Reader = new FileReader()
            const addFileFn = (url, options = {}) => {
                addFile(filterId, {
                    ...newEntityOptions,
                    preview: url,
                    base64: url.substr(url.indexOf(',') + 1),
                    description: '',
                    filename: file.name,
                    file,
                    size: file.size,
                    mime: file.type,
                    type: file.type.indexOf('image') !== -1 ? 'image' : 'document',
                    ...options,
                })
            }
            this.setState({ loading: true })
            Reader.onloadend = (e) => {
                const { result } = e.target
                addFileFn(result)
            }
            Reader.readAsDataURL(file)
        })
    }

    render() {
        const { filter, count, compact, onFilesRejected } = this.props
        if (!filter || compact) {
            return null
        }

        const hasSelection = filter.selection && filter.selection.length > 0
        const pageNr = filter.page || 0
        const dropZoneStyle = {
            height: 'auto',
            padding: 5,
            minWidth: 100,
            border: '1px dashed #c4c4c4',
            borderRadius: 7,
        }
        return (
            <Row className="v-center no-wrap" nm>
                <FileDropZone
                    style={dropZoneStyle}
                    onFilesAccepted={this.onDropFiles}
                    onFilesRejected={onFilesRejected}>
                    <button className="plain">
                        <Icon>add</Icon> add file
                    </button>
                </FileDropZone>
                {hasSelection && (
                    <Fragment>
                        <Section x="min">
                            <button onClick={this.saveSelection} className="btn">
                                Save
                            </button>
                        </Section>
                        <SafeButton
                            action={this.deleteSelection}
                            className="btn warning"
                            cancelText={false}
                            confirmMessage={`Delete ${filter.selection.length}`}
                            buttonText="Delete"
                        />
                    </Fragment>
                )}
                {count > filter.pageSize && (
                    <div className="to-right">
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            onPageChange={this.onPageChange}
                        />
                    </div>
                )}
            </Row>
        )
    }
}

FilesFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
}

export default FilesFilterContainer(FilesFilter)
