import { Section, Loading, Msg, Alert } from '@advanza/advanza_generic'
import { validateNewEmail } from '@advanza/advanza_generic/src/actions/auth'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

class EmailChanged extends Component {
    constructor(props) {
        super(props)
        this.success = this.success.bind(this)
        this.tokenInvalid = this.tokenInvalid.bind(this)
        this.state = {
            popup: false,
            redirect: false,
            loading: true,
        }
    }

    componentWillMount() {
        const { token } = this.props
        // try changing the email with the token.
        validateNewEmail(token).then(this.success, this.tokenInvalid)
    }

    // when the token validation is successful
    success(json) {
        const goToHome = () => this.setState({ redirect: '/' })
        this.setState({
            popup: (
                <Alert
                    title={
                        <Msg
                            id="login.email_change.success"
                            values={{
                                newEmail: json.email,
                            }}
                        />
                    }
                    onOk={goToHome}
                />
            ),
        })
    }

    tokenInvalid(json) {
        const dismissPopup = () => this.setState({ popup: false })
        switch (json.error) {
            case 'expired':
                this.setState({
                    popup: (
                        <Alert
                            title={<Msg id="login.email_reset.token_expired" />}
                            onOk={dismissPopup}
                        />
                    ),
                })
                break
            default:
                this.setState({
                    popup: <Alert title={<Msg id="error.fallback" />} onOk={dismissPopup} />,
                })
        }
    }

    render() {
        const { redirect, loading, popup } = this.state
        if (redirect) {
            return <Redirect to={redirect} />
        }
        return (
            <div>
                <Section n="mega" s>
                    <Section s>
                        <h1>
                            <Msg id="more.link.change_email" />
                        </h1>
                    </Section>
                </Section>
                {loading && <Loading />}

                {popup}
            </div>
        )
    }
}

export default EmailChanged
