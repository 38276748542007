import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function usePublicReviews(providerId) {
    const dispatch = useDispatch()
    const providers = useSelector((state) => state.providers.entities.providers)
    const provider = providers && providers[providerId]
    const isLocalTest = window.location.host.indexOf('l.') !== -1
    const isTest = window.location.host.indexOf('test') !== -1
    const domain =
        provider && provider.country_code.toUpperCase() !== 'NL'
            ? `trustlocal.${provider.country_code.toLowerCase()}`
            : 'trustoo.nl'
    const url =
        provider &&
        (isLocalTest ? 'http://l.' : isTest ? 'https://test.' : 'https://') +
            domain +
            (isLocalTest ? ':3000' : '') +
            provider.providerUrl +
            '/?reviews=' +
            provider.public_reviews
    const hasPublicReviews = provider && provider.public_reviews
    const [isGenerating, setIsGenerating] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)

    const onGenerate = () => {
        setIsGenerating(true)
        call(`providers/generate-public-reviews-key/public_reviews/${providerId}`, {
            method: 'post',
        }).then((response) => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: providerId,
                    diff: {
                        public_reviews: response.key,
                    },
                })
            )
            setIsGenerating(false)
            setIsGenerated(true)
        })
    }

    return {
        url,
        hasPublicReviews,
        onGenerate,
        isGenerating,
        isGenerated,
    }
}
