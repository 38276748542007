import { Col, Row } from '@advanza/grid'
import { InputField, SelectSimple } from '@advanza/input'
import { Icon } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import MatchFilterContainer from '../../containers/MatchFilterContainer'
import Pagination from '../Pagination'
import ServicesSelect from '../services/ServicesSelect'

class MatchFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onCountList = this.onCountList.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onChangeQuery = this.onChangeQuery.bind(this)
        this.onServiceIdsChange = this.onServiceIdsChange.bind(this)
        this.invalidate = this.invalidate.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.updateQuery = this.updateQuery.bind(this)
        this.state = { query: '' }
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {}, defaultOptions = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter(defaultOptions)
        }
    }

    onCountList() {
        this._changeFilter({ countList: true, didInvalidate: true })
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({
            page,
            didInvalidate: invalidate,
        })
    }

    onServiceIdsChange(serviceIds) {
        this._changeFilter({
            serviceIds,
            page: 0,
        })
    }

    invalidate() {
        this._changeFilter({
            didInvalidate: true,
            page: 0,
        })
    }

    onChangeQuery(e) {
        const { value } = e.target
        this.setState({ query: value }, () => {})
    }

    updateQuery() {
        this._changeFilter({
            query: this.state.query,
            page: 0,
        })
    }

    render() {
        const { filter, count, countOnPage, compact, showSearch, isFetching, hidePagination } =
            this.props
        if (!filter) {
            return null
        }

        const pageNr = filter.page || 0
        const showPagination = (countOnPage || count > 5 || count === '?') && !hidePagination
        return (
            <Row middle="xs">
                {!compact && (
                    <Col x>
                        <button onClick={this.invalidate}>
                            <Icon>{isFetching ? 'hourglass_empty' : 'refresh'}</Icon>
                        </button>
                    </Col>
                )}
                {!compact && (
                    <Col xs={4}>
                        <ServicesSelect
                            className="white"
                            onChangeValue={this.onServiceIdsChange}
                            value={filter.serviceIds || []}
                        />
                    </Col>
                )}
                {!compact && (
                    <Col x>
                        <SelectSimple
                            value={filter.selector || 'all'}
                            onChange={(e) => this._changeFilter({ selector: e.target.value })}
                            options={[
                                { name: 'All matches', value: 'all' },
                                { name: 'From dashboard', value: 'fromDashboard' },
                                { name: 'Declined', value: 'noInterest' },
                            ]}
                        />
                    </Col>
                )}
                {showSearch && (
                    <Col x>
                        <InputField
                            icon="search"
                            value={this.state.query}
                            placeholder="search"
                            onKeyDown={(e) => e.key === 'Enter' && this.updateQuery()}
                            onChange={this.onChangeQuery}
                        />
                    </Col>
                )}
                {showPagination && (
                    <Col end="xs" x>
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            countOnPage={countOnPage}
                            onCountList={this.onCountList}
                            onPageChange={this.onPageChange}
                        />
                    </Col>
                )}
            </Row>
        )
    }
}

MatchFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    countOnPage: PropTypes.number,
}

export default MatchFilterContainer(MatchFilter)
