import { Col, Row } from '@advanza/grid'
import { Button, Divider, PreIcon } from '@advanza/ui'
import RequestCandidateMatchStatus from 'components/candidateMatches/RequestCandidateMatchStatus'
import SystemLogsList from 'components/logs/SystemLogsList'
import AutoRow from 'components/misc/AutoRow'
import AddMatchManuallyButton from 'components/requests/AddMatchManuallyButton'
import RequestResendButton from 'components/requests/RequestResendButton'
import RequestStatusUpdateList from 'components/requests/RequestStatusUpdateList'
import AddRequestToSanity from 'components/services/AddRequestToSanity'
import RequestViewContainer from 'containers/RequestViewContainer'
import { utcToLocal } from 'date'
import { subYears } from 'date-fns'
import { hasAccess } from 'misc/user'
import React, { Fragment } from 'react'
import Card from '../Card'
import EventFilter from '../events/EventFilter'
import EventList from '../events/EventList'
import LinearLoader from '../LinearLoader'
import MatchFilter from '../matches/MatchFilter'
import MatchList from '../matches/MatchList'
import NotesFilter from '../notes/NotesFilter'
import NotesList from '../notes/NotesList'
import Restricted from '../Restricted'
import CategoryIcon from '../services/CategoryIcon'
import BackButton from '../ui/BackButton'
import SafeButton from '../ui/SafeButton'
import UserView from '../users/UserView'
import RequestCard from './RequestCard'
import RequestFilter from './RequestFilter'
import RequestList from './RequestList'
import RequestStatus from './RequestStatus'
import { Link } from 'react-router-dom'

class RequestView extends React.Component {
    fetch() {
        const { fetchRequest, requestId, fetchService, request, filterSource } = this.props
        if (request) {
            fetchService(request.service_id)
            fetchRequest(requestId, filterSource)
        } else {
            fetchRequest(requestId, filterSource).then(
                () => this.props.request && fetchService(this.props.request.service_id)
            )
        }
    }

    componentDidMount() {
        this.fetch.bind(this)()
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestId !== prevProps.requestId) {
            this.fetch.bind(this)()
        }
    }

    render() {
        const {
            request,
            requestId,
            service,
            user,
            declareValid,
            deleteRequest,
            generateDemandDashboardLink,
            filterSource,
            changeRequestTypeToInfoMessage,
        } = this.props
        const isLoaded = request && service && request.requests_options && service.questions
        if (!isLoaded) {
            return (
                <div className="container">
                    <LinearLoader fixed />
                    <h1>Request #{requestId}</h1>
                </div>
            )
        }
        const fallBack =
            filterSource === 'closed-loop'
                ? '/statistics/closed-loop-requests'
                : '/service/requests'
        const filterIdAssociations = `r_${requestId}`
        const disableHistoricRequest =
            new Date(request.created) < subYears(new Date(), 1) &&
            !hasAccess('historic_request_data')

        return (
            <div>
                <Row middle="xs">
                    <Col x>
                        <BackButton fallBack={fallBack} />
                    </Col>
                    <div>
                        <h1 style={{ lineHeight: '1.3' }}>Request #{requestId}</h1>
                        <AutoRow>
                            <RequestStatus status={request.status} />
                            <RequestCandidateMatchStatus request={request} />
                        </AutoRow>
                    </div>
                    <Col x>
                        <small>{utcToLocal(request.created, 'dd MMM HH:mm')}</small>
                    </Col>
                    <Col x>
                        <SafeButton
                            action={declareValid.bind(this, requestId)}
                            className="btn"
                            hideOnFinished
                            hidden={request.valid}
                            buttonText={'declare valid'}
                            confirmMessage="Declare valid & confirm matches"
                        />
                    </Col>
                    <Col x>
                        <SafeButton
                            action={deleteRequest.bind(this, requestId)}
                            className="btn black"
                            hideOnFinished
                            hidden={request.status !== 'INVALID'}
                            buttonText="declare invalid"
                            confirmMessage="Delete from office and demand"
                        />
                    </Col>
                    <Col x>
                        <SafeButton
                            action={changeRequestTypeToInfoMessage.bind(this, requestId)}
                            hideOnFinished
                            hidden={request.request_type === 'info_message'}
                            useErrorPopup
                            buttonText={
                                <Button name="yellowAlt">
                                    <PreIcon icon="redeem">
                                        Change to free info message{' '}
                                        {!request.valid && 'and declare valid'}
                                    </PreIcon>
                                </Button>
                            }
                            newType
                            confirmMessage={
                                <Button name="yellow">
                                    <PreIcon icon="redeem">Confirm change to free message</PreIcon>
                                </Button>
                            }
                        />
                    </Col>
                    {request.previous_request_id && (
                        <Col x style={{ marginLeft: 'auto' }}>
                            <Link to={`/service/requests/request/${request.previous_request_id}`}>
                                <Button appearance={'outlined_primary'} iconBefore={'arrow_back'}>
                                    {request.previous_request_id}
                                </Button>
                            </Link>
                        </Col>
                    )}
                    {request.next_request_id && (
                        <Col x style={request.next_request_id ? {} :{ marginLeft: 'auto' }}>
                            <Link to={`/service/requests/request/${request.next_request_id}`}>
                                <Button appearance={'outlined_primary'} iconAfter={'arrow_forward'}>
                                    {request.next_request_id}
                                </Button>
                            </Link>
                        </Col>
                    )}
                    <Col x>
                        {!disableHistoricRequest && user && (
                            <Fragment>
                                {user._dashboardLink ? (
                                    <a href={user._dashboardLink} target="_blank">
                                        go to demand dashboard
                                    </a>
                                ) : (
                                    <Button
                                        name="text"
                                        onClick={(e) =>
                                            generateDemandDashboardLink(requestId).then((link) => {
                                                window.open(link)
                                            })
                                        }>
                                        create demand dashboardlink
                                    </Button>
                                )}
                            </Fragment>
                        )}
                    </Col>
                    <Col x>
                        {!disableHistoricRequest && <AddRequestToSanity requestId={requestId} />}
                    </Col>
                </Row>
                <Divider m />
                <Row>
                    <Col xs={6}>
                        <Card
                            header={<span>{service.name} request</span>}
                            icon={<CategoryIcon color="blue" id={service.category_id} />}>
                            <RequestCard requestId={request.request_id} />
                        </Card>
                        <Divider m />
                        <Card header={<span>User #{request.user_id}</span>} icon="person">
                            <Restricted location="requests">
                                <Divider s />
                                <UserView
                                    useStore="requests"
                                    permissionLocation="requests"
                                    userId={request.user_id}
                                    hideSave={disableHistoricRequest}
                                />
                            </Restricted>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card header="Matches" icon="compare_arrows">
                            <MatchFilter
                                filterId={filterIdAssociations}
                                compact
                                hidePagination
                                defaultOptions={{ requestIds: [requestId] }}
                            />
                            <MatchList
                                compact
                                showCols={[
                                    'id',
                                    'created',
                                    'name',
                                    'firstChoice',
                                    'from_dashboard',
                                    'charge',
                                    'confirm',
                                    'stripes',
                                    'nrMsgs',
                                    'partialMatch',
                                    'reclaim',
                                ]}
                                filterId={filterIdAssociations}
                            />
                            <Divider />
                            <AddMatchManuallyButton
                                serviceId={request.service_id}
                                requestId={requestId}
                                filterId={filterIdAssociations}
                            />
                        </Card>
                        <Divider m />
                        <Card header="Notes" icon="speaker_notes">
                            <NotesFilter
                                filterId={filterIdAssociations}
                                defaultOptions={{
                                    requestIds: [requestId],
                                    pageSize: 3,
                                }}
                                newEntityOptions={{
                                    type: 'requests',
                                    foreign_key: requestId,
                                }}
                            />
                            <NotesList filterId={filterIdAssociations} />
                        </Card>
                        <Divider m />
                        <Card header="Logs" icon="🪵">
                            <Divider />
                            <SystemLogsList
                                filterId={filterIdAssociations}
                                options={{
                                    relation: 'requests',
                                    requestId,
                                }}
                            />
                        </Card>
                        <Divider m />
                        <Card header="Status updates" icon="update">
                            <Divider />
                            {request && <RequestStatusUpdateList request={request} />}
                        </Card>
                        <Divider m />
                        <Card header="Events" icon="event_note">
                            <EventFilter
                                filterId={filterIdAssociations}
                                hideSelect
                                defaultOptions={{
                                    requestIds: [requestId],
                                    pageSize: 5,
                                }}
                            />
                            <div style={{ maxWidth: '100%', overflow: 'auto' }}>
                                <EventList filterId={filterIdAssociations} />
                            </div>
                        </Card>
                        <Divider m />
                        <Card header="Actions" icon="run_circle">
                            <Divider m />
                            <RequestResendButton requestId={requestId} />
                        </Card>
                        <Divider m />
                        <Card header="Other requests" icon="assignment_ind">
                            <RequestFilter
                                filterId={filterIdAssociations}
                                filterSource={filterSource}
                                compact
                                defaultOptions={{
                                    userIds: [request.user_id],
                                    skip: [requestId],
                                    pageSize: 5,
                                }}
                            />
                            <RequestList
                                compact
                                showCols={['id', 'name', 'service', 'time']}
                                filterId={filterIdAssociations}
                                filterSource={filterSource}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default RequestViewContainer(RequestView)
