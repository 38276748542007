import React, { useState } from 'react'
import { Button, Divider, LoadingDots } from '@advanza/ui'
import Modal from '@advanza/modal'
import { call } from '@advanza/api'

const SyncSubServicesButton = ({ service }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const sync = () => {
        if (isLoading) {
            return Promise.resolve()
        }
        setIsLoading(true)
        call('office/subscriptions/sync-subscriptions', {
            payload: { serviceId: service.service_id },
        })
            .then(setResponse, setResponse)
            .finally(() => {
                setIsLoading(false)
            })
    }
    return (
        <>
            <Button onClick={() => setOpenConfirmModal(true)}>Sync</Button>
            <Modal maxWidth={650} open={openConfirmModal} close={() => setOpenConfirmModal(false)}>
                <h2>Sync with main service ({service.mainServiceName})</h2>
                <Divider />
                <p>
                    Sync subscriptions with {service.mainServiceName} based on option{' '}
                    {service.mainServiceOptionName}
                </p>
                <Divider />
                {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
                <Button onClick={sync}>{isLoading ? <LoadingDots color="white" /> : 'Sync'}</Button>
            </Modal>
        </>
    )
}

export default SyncSubServicesButton
