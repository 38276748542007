import { setProStatus, doAction } from 'actions/providers'
import { connect } from 'react-redux'
import { changeProviderFilter } from '../actions/providers'

function mapStateToProps(state, props) {
    const { filters, isFetching, result, counts } = state.providers
    const filter = filters[props.filterId]
    const filterResult = filter && result[filter.searchKey]
    const count = filter && counts[filter.searchKey]
    const countOnPage = filter && filterResult[filter.page || 0].length
    return {
        filter,
        countries: state.countries.countries,
        count,
        countOnPage,
        isFetching,
    }
}

export default connect(mapStateToProps, {
    changeFilter: changeProviderFilter,
    setStatus: setProStatus,
    doAction,
})
