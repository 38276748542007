import { Icon, Section } from '@advanza/advanza_generic'
import { Alert } from '@advanza/advanza_generic'
import React from 'react'
import AdminUsersCreateContainer from '../../containers/AdminUsersCreateContainer'
import NewAdminUserEntity from './NewAdminUserEntity'

class CreateAdminUserButton extends React.Component {
    constructor(props) {
        super(props)
        this.onAdd = this.onAdd.bind(this)
        this.afterSave = this.afterSave.bind(this)
        this.state = {
            newUserId: false,
        }
    }

    componentDidMount() {}

    onAdd() {
        const { addAdminUser } = this.props
        addAdminUser().then((id) => this.setState({ newUserId: id }))
    }
    afterSave() {
        this.setState({ newUserId: false })
    }
    render() {
        const { newUserId } = this.state
        return (
            <div>
                <button className="plain" onClick={this.onAdd}>
                    <Icon>add</Icon> add user
                </button>
                {newUserId && (
                    <Alert shouldAnimateOnClose close={this.afterSave}>
                        <h2>Create admin user</h2>
                        <Section s="plus" />
                        <NewAdminUserEntity afterSave={this.afterSave} entityId={newUserId} />
                    </Alert>
                )}
            </div>
        )
    }
}

CreateAdminUserButton.propTypes = {}

export default AdminUsersCreateContainer(CreateAdminUserButton)
