import { PreIcon } from '@advanza/ui'

const RequestCandidateMatchStatus = ({ request, compact = false }) => {
    if (!request.find_candidate_matches) {
        return null
    }

    const fontSize = compact ? 16 : 20
    const style = compact
        ? {
              display: 'inline-block',
          }
        : {}
    let text = ''
    if (!compact) {
        text =
            request.find_candidate_matches === 1
                ? 'finding candidate matches'
                : 'Candidate matches sent'
    }
    return (
        <PreIcon
            outerStyle={style}
            tagName="span"
            fontSize={fontSize}
            icon="switch_access_shortcut"
            green={request.find_candidate_matches === 2}
            orange={request.find_candidate_matches === 1}>
            {text}
        </PreIcon>
    )
}

export default RequestCandidateMatchStatus
