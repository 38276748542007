import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { useActionModal } from '@advanza/modal'
import { Button, Icon } from '@advanza/ui'
import { useBansList } from 'components/bans/BansList'
import Restricted from 'components/Restricted'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const FIELDS = [
    'advanza_id',
    'business_name',
    'kvk',
    'phone',
    'email',
    'street_number',
    'street',
    'postalcode',
    'place_name',
    'country_code',
]

const AddBanButton = () => {
    const { changeFilter } = useBansList()
    const { openModal, renderModal } = useActionModal()
    const { countries } = useSelector((state) => state.countries)
    const [fieldValues, setFieldValues] = useState({ country_code: countries[0] })
    const advanzaId = ('' + (fieldValues.advanza_id || '')).trim()
    const [errors, setErrors] = useState({})

    const invalidate = () => changeFilter({ didInvalidate: true, page: 0, result: {} })

    const onFetch = () =>
        call(`office/providers-bans/get-advanza-company/${advanzaId}`).then(
            ({ company }) => {
                setFieldValues(company)
                setErrors({})
            },
            () => setErrors({ advanza_id: true })
        )

    const onAddBan = () =>
        call(`office/providers-bans/add-ban`, { json: fieldValues }).then(() => {
            setFieldValues({ country_code: countries[0] })
            setErrors({})
            invalidate()
        })

    return (
        <Restricted location="providers">
            <Button onClick={() => openModal()}>
                <Icon name="add" /> Add ban
            </Button>
            {renderModal({
                cancelText: 'Cancel',
                text: 'Ok',
                func: onAddBan,
                header: 'Add ban',
                body: FIELDS.map((field) => (
                    <Row key={field} middle="xs">
                        <Col xs={3}>{field.replace(/_/g, ' ')}</Col>
                        <Col xs={6}>
                            <InputField
                                error={errors[field] && 'Check this field'}
                                value={fieldValues[field]}
                                onChange={(e) =>
                                    setFieldValues((fieldValues) => ({
                                        ...fieldValues,
                                        [field]: e.target.value,
                                    }))
                                }
                            />
                        </Col>
                        {field === 'advanza_id' && (
                            <Col xs={3}>
                                <Button
                                    disabled={
                                        advanzaId.length < 2 || Number.isNaN(Number(advanzaId))
                                    }
                                    onClick={onFetch}>
                                    <Icon name="search" /> Fetch
                                </Button>
                            </Col>
                        )}
                    </Row>
                )),
            })}
        </Restricted>
    )
}

export default AddBanButton
