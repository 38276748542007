import React from 'react'
import { connect } from 'react-redux'
import { fetchEvent } from '../actions/events'

function mapStateToProps(state, props) {
    const { events, ...eventEntities } = state.events.entities
    const event = events && events[props.eventId]
    return {
        event,
        eventEntities,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchEvent: (eventId) => dispatch(fetchEvent(eventId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
