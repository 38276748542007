import { Col, Row } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import { Button, Icon } from '@advanza/ui'
import Pagination from 'components/Pagination'
import SafeButton from 'components/ui/SafeButton'
import LabelFilterContainer from 'containers/LabelFilterContainer'
import PropTypes from 'prop-types'
import React from 'react'
import style from './label.module.css'

class LabelFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.addLabel = this.addLabel.bind(this)
        this.onCategoryChange = this.onCategoryChange.bind(this)
        this.deleteSelection = this.deleteSelection.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onCountList() {
        this._changeFilter({ countList: true, didInvalidate: true })
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    onCategoryChange(e) {
        const { value: category } = e.target
        this._changeFilter({ categories: [category] })
    }

    addLabel() {
        const { addLabel, filter } = this.props
        addLabel(
            {
                category:
                    filter.categories && filter.categories.length > 0 ? filter.categories[0] : '',
            },
            filter.filterId
        )
    }

    deleteSelection() {
        const { filter, deleteSelection } = this.props
        return deleteSelection(filter.filterId)
    }

    render() {
        const { filter, count, countOnPage, compact, categories } = this.props
        if (!filter || compact) {
            return null
        }

        const pageNr = filter.page || 0
        const selection = filter.selection
        const showPagination = countOnPage || count > 5 || count === '?'
        return (
            <Row middle="xs">
                <Col xs>
                    <SelectSimple
                        className={style.labelFilterSelect}
                        onChange={this.onCategoryChange}
                        emptyOption="All categories"
                        value={filter.categories || '-'}
                        options={categories.map((category) => {
                            return {
                                value: category,
                                name: category,
                            }
                        })}
                    />
                </Col>
                <Col x>
                    {selection && selection.length > 0 && (
                        <SafeButton
                            action={this.deleteSelection}
                            className="btn warning"
                            confirmMessage={`delete ${selection.length} labels`}
                            buttonText="delete"
                        />
                    )}
                </Col>

                <Col x>
                    <Button name="text" onClick={this.addLabel}>
                        <Icon name="add" /> add label
                    </Button>
                </Col>
                <Col x>
                    {showPagination && (
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            countOnPage={countOnPage}
                            onCountList={this.onCountList}
                            onPageChange={this.onPageChange}
                        />
                    )}
                </Col>
            </Row>
        )
    }
}

LabelFilter.propTypes = {
    categories: PropTypes.array.isRequired,
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
}

export default LabelFilterContainer(LabelFilter)
