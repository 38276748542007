import React from 'react'
import { connect } from 'react-redux'
import { fetchLabels } from '../actions/labels'
import { selectItems } from '../actions/lists'
import { fetchStatuses, unLinkStatus } from '../actions/statuses'

function mapStateToProps(state, props) {
    const { entities, result, isFetching, filters } = state.statuses
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey

    const { statuses = {} } = entities
    const categories = Object.keys(statuses).map((id) => statuses[id].category)
    const uniqueCategories = Array.from(new Set(categories))

    return {
        pages: result[searchKey] || {},
        entities,
        filter,
        categories: uniqueCategories,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        select: (ids, filterId) => dispatch(selectItems('statuses', ids, filterId)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)
