import ManualReclaimButton from 'components/matches/ManualReclaimButton'
import FormattedTime from 'components/ui/FormattedTime'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useList } from 'hooks/listHooks'
import React from 'react'
import phoneClicksSchema from 'schemes/phoneClicksSchema'
import TableList from '../TableList'

const definitions = {
    phone_click_id: { name: '#' },
    created: { name: 'date' },
    chargeAmount: { name: '€' },
    reclaim: { name: 'reclaim' },
    phone_number: { name: 'caller' },
    call_duration: { name: 'duration' },
}

function renderRow(id, entities) {
    const { phoneClicks } = entities
    const phoneClick = phoneClicks[id]

    return {
        cols: Object.keys(definitions).map((key) => {
            const val = phoneClick[key]
            switch (key) {
                case 'phone_click_id':
                    return <small>{phoneClick[key]}</small>
                case 'created':
                    return <FormattedTime date={val || ''} />
                case 'chargeAmount':
                    return val ? (
                        <small>
                            <b>€{parseFloat(val).toFixed(2)},-</b>
                        </small>
                    ) : (
                        '-'
                    )
                case 'call_duration':
                    return !val ? (
                        '-'
                    ) : (
                        <small>
                            {Math.floor(val / 60)}m {val % 60}s
                        </small>
                    )
                case 'reclaim':
                    return (
                        <ManualReclaimButton
                            lead={phoneClick}
                            id={phoneClick.phone_click_id}
                            leadType="phoneClicks"
                        />
                    )
                default:
                    return val
            }
        }),
    }
}

const PhoneClicksList = ({ filterId = 'all' }) => {
    const {
        filter = {},
        currentPage,
        ...rest
    } = useList(
        filterId,
        {
            url: 'office/phone-clicks/get-list',
            schema: phoneClicksSchema,
            reducerKey: 'phoneClicks',
        },
        {}
    )
    const rows = {}
    currentPage.forEach((id) => {
        rows[id] = renderRow(id, rest.entities)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <TableList
                {...rest}
                selectable={rest.selectable}
                filter={filter}
                rows={rows}
                definitions={definitions}
            />
        </LoadBoxWrapper>
    )
}

export default PhoneClicksList
