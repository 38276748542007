import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    CHANGE_SUBSCRIPTION_TRAVEL_RANGE,
    RECEIVE_ALL_GEOIDS,
    REPLACE_SUBSCRIPTION_GEOIDS,
    REPLACE_SUBSCRIPTION_OPTIONS,
    SUBSCRIPTION_DELETED,
    SUBSCRIPTION_STATUS_UPDATED,
    SUBSCRIPTIONS_CHANGE_FILTER,
    SUBSCRIPTIONS_RECEIVE_LIST,
    SUBSCRIPTIONS_RECEIVE_SINGLE,
    SUBSCRIPTIONS_REQUEST,
    REPLACE_SUBSCRIPTION_MUNICIPALITIES,
    REQUEST_GEOIDS,
} from '../actions/subscriptions'
import { changeFilter, changeEntity, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [SUBSCRIPTIONS_RECEIVE_LIST]: receiveList,
    [SUBSCRIPTIONS_CHANGE_FILTER]: changeFilter,
    INVALIDATE_SUBSCRIPTIONS: invalidateFilters,
    CHANGE_ENTITY_SUBSCRIPTIONS: changeEntity,

    [SUBSCRIPTIONS_RECEIVE_SINGLE]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            entities: { $deepMerge: action.entities },
            estimations: { $deepMerge: action.estimations || {} },
        }),
    [SUBSCRIPTIONS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [REQUEST_GEOIDS]: (state, action) =>
        update(state, {
            geoIds: {
                isFetching: { $set: true },
            },
        }),
    REQUEST_MUNICIPALITIES: (state, action) =>
        update(state, {
            municipalities: {
                isFetching: { $set: true },
            },
        }),
    [RECEIVE_ALL_GEOIDS]: (state, action) =>
        update(state, {
            geoIds: {
                isFetching: { $set: false },
                result: { $set: action.result },
                entities: { $set: action.entities },
            },
            municipalities: {
                isFetching: { $set: false },
                result: { $set: action.items },
                mapIndexMap: { $set: action.mapIndexMap },
                entities: { $set: action.entities },
            },
        }),
    [CHANGE_SUBSCRIPTION_TRAVEL_RANGE]: (state, action) =>
        update(state, {
            entities: {
                subscriptions: {
                    [action.subscriptionId]: {
                        postal_codes: { $set: [action.postalCode] },
                    },
                },
            },
        }),
    [SUBSCRIPTION_STATUS_UPDATED]: (state, action) =>
        update(state, {
            entities: {
                subscriptions: {
                    [action.subscriptionId]: {
                        active: { $set: action.active },
                    },
                },
            },
        }),
    [SUBSCRIPTION_DELETED]: (state, action) =>
        update(state, {
            entities: {
                subscriptions: {
                    [action.subscriptionId]: {
                        deleted: { $set: new Date() },
                    },
                },
            },
        }),
    [REPLACE_SUBSCRIPTION_OPTIONS]: (state, action) =>
        update(state, {
            entities: {
                subscriptions: {
                    [action.subscriptionId]: {
                        subscriptions_options: { $set: action.options },
                    },
                },
            },
        }),
    [REPLACE_SUBSCRIPTION_GEOIDS]: (state, action) =>
        update(state, {
            entities: {
                subscriptions: {
                    [action.subscriptionId]: {
                        subscriptions_geoids: { $set: action.geoIds },
                    },
                },
            },
        }),
    [REPLACE_SUBSCRIPTION_MUNICIPALITIES]: (state, action) =>
        update(state, {
            entities: {
                subscriptions: {
                    [action.subscriptionId]: {
                        subscriptions_municipalities: { $set: action.municipalities },
                    },
                },
            },
        }),
    SERVICE_AREA_SET_MAP_CENTER: (state, action) =>
        update(state, {
            municipalities: {
                mapCenter: { $set: action },
            },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    estimations: {},

    geoIds: {
        entities: {},
    },
    municipalities: {
        entities: {},
    },
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
