import PropTypes from 'prop-types'
import React from 'react'
import Page from '../ui/Page'
import MatchView from './MatchView'

const MatchViewPage = ({ match }) => {
    const { id } = match.params
    return (
        <Page title="Matches">
            <MatchView matchId={id} />
        </Page>
    )
}

MatchViewPage.propTypes = {
    matchId: PropTypes.any,
}

export default MatchViewPage
