import { useProfileBuilderWizard } from 'hooks/profileBuilderHooks'
import React from 'react'
import style from './pageVisualWebsiteCheck.module.css'

const PageVisualWebsiteCheck = ({ providerId }) => {
    const { websiteWithProtocol } = useProfileBuilderWizard(providerId)

    return (
        <iframe
            className={style.iframe}
            src={
                websiteWithProtocol.includes('https')
                    ? websiteWithProtocol
                    : websiteWithProtocol.replace('http', 'https')
            }
            sandbox="allow-downloads allow-forms allow-scripts"
        />
    )
}

export default PageVisualWebsiteCheck
