import { getIndexArray } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import BarAvatar from 'components/profileBuilderWizard/BarAvatar'
import BarImage from 'components/profileBuilderWizard/BarImage'
import {
    getImageUrl,
    getWeightStart,
    NUM_IMAGES,
    useProfileBuilderWizard,
} from 'hooks/profileBuilderHooks'
import React from 'react'
import style from './imagesBar.module.css'

const ImagesBar = ({ providerId, disabled }) => {
    const { imageFiles = [], selectImage } = useProfileBuilderWizard(providerId)

    const createCols = (context) =>
        getIndexArray(NUM_IMAGES[context]).map((i) => {
            const weight = getWeightStart(context) - i

            const onAddFile = (url, file) =>
                selectImage(
                    {
                        filename: file.name,
                        mime: file.type,
                        size: file.size,
                        type: 'image',
                        context,
                        weight,
                        preview: url,
                        base64: url.substr(url.indexOf(',') + 1),
                        file,
                    },
                    context,
                    weight
                )

            return (
                <Col key={i} className={[style.col, !i ? style.colFirst : ''].join(' ')}>
                    <BarImage
                        providerId={providerId}
                        disabled={disabled}
                        url={getImageUrl(
                            imageFiles.find(
                                (imageFile) =>
                                    imageFile.context === context && imageFile.weight === weight
                            )
                        )}
                        context={context}
                        weight={weight}
                        onAddFile={onAddFile}
                    />
                </Col>
            )
        })

    return (
        <Row className={style.root}>
            <Col x>
                <BarAvatar providerId={providerId} disabled={disabled} />
            </Col>
            {createCols('logo')}
            {createCols('profile')}
        </Row>
    )
}

export default ImagesBar
