import { Col, Row } from '@advanza/advanza_generic'
import { format } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'

const RequestsMonths = ({ requestsMonthsids = [] }) => {
    const { requestsMonths = {}, questions = {} } = useSelector((state) => state.requests.entities)
    const questionsIds = [
        ...new Set(
            requestsMonthsids.map((requestsMonthId) => requestsMonths[requestsMonthId].question_id)
        ),
    ]

    return (
        <div>
            {questionsIds.map((questionId) => (
                <Row y="min" key={`requests_months_${questionId}`}>
                    <Col style={{ maxWidth: 150 }}>
                        <div className="col-h">{questions[questionId].question_name_filter}</div>
                    </Col>
                    <Col>
                        <div>
                            {requestsMonthsids
                                .map((requestsMonthId) => requestsMonths[requestsMonthId])
                                .filter((requestsMonth) => (requestsMonth.question_id = questionId))
                                .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
                                .map((requestsMonth, index) => (
                                    <div key={index}>
                                        {format(new Date(requestsMonth.month), 'MMMM yyyy')}
                                    </div>
                                ))}
                        </div>
                    </Col>
                </Row>
            ))}
        </div>
    )
}

export default RequestsMonths
