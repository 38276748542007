import React from 'react'
import Page from '../ui/Page'
import SubscriptionView from './SubscriptionView'

const SubscriptionPage = ({ match }) => {
    const { id } = match.params
    return (
        <Page title={`Subscription #${id}`}>
            <SubscriptionView subscriptionId={id} />
        </Page>
    )
}

SubscriptionPage.propTypes = {}

export default SubscriptionPage
