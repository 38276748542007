import labelsSchema from '../schemes/labelsSchema'
import notesSchema from '../schemes/notesSchema'
import { changeEntity } from './entities'
import { call } from '@advanza/api'
import { _changeFilter, _fetchItems, _preLoadFilter } from './lists'
import { NOTES_RECEIVE_LIST } from './notes'

export const LABELS_RECEIVE_LIST = 'LABELS_RECEIVE_LIST'
export const LABELS_CHANGE_FILTER = 'LABELS_CHANGE_FILTER'
export const LABELS_REQUEST = 'LABELS_REQUEST'

export function fetchLabels(filterIdOrOptions) {
    return _fetchItems(filterIdOrOptions, {
        url: 'office/office-labels/get-list',
        schema: labelsSchema,
        requestActionName: LABELS_REQUEST,
        receiveActionName: LABELS_RECEIVE_LIST,
        reducerKey: 'labels',
    })
}

export function changeLabelsFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'labels',
        fetchFunc: fetchLabels,
        actionType: LABELS_CHANGE_FILTER,
    })
}

export function addLabel(options, filterId) {
    return function (dispatch) {
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                diff: {
                    office_label_id: newId,
                    ...options,
                },
                key: newId,
                filterId,
                name: 'labels',
                store: 'labels',
            })
        )
    }
}

export function saveLabel(labelId) {
    return function (dispatch, getState) {
        const label = getState().labels.entities.labels[labelId]
        const isNew = typeof labelId === 'string'
        return call(`office/office-labels/save/${isNew ? '' : labelId}`, {
            json: { ...label },
        }).then((response) => {
            if (isNew) {
                dispatch({ type: 'INVALIDATE_LABELS' })
            }
        })
    }
}

function deleteLabel(labelId) {
    return function (dispatch) {
        const isNew = typeof labelId === 'string'
        const removeLabelFromStore = () => {
            dispatch(changeEntity({ store: 'labels', name: 'labels', remove: true, key: labelId }))
        }
        if (isNew) {
            return removeLabelFromStore()
        }
        return call(`office/office-labels/delete/${labelId}`, { method: 'post' }).then(
            removeLabelFromStore
        )
    }
}

export function deleteLabelsSelection(filterId) {
    return function (dispatch, getState) {
        const selection = getState().labels.filters[filterId].selection
        const promises = selection.map((labelId) => dispatch(deleteLabel(labelId)))
        return Promise.all(promises).then(() => {
            dispatch(changeLabelsFilter(filterId, { selection: [] }))
        })
    }
}
