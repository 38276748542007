import React from 'react'
import { connect } from 'react-redux'
import { selectItems } from '../actions/lists'

function mapStateToProps(state, props) {
    const { entities: requestEntities, result, isFetching, filters } = state.requests
    const { entities: serviceEntities } = state.services
    const filter = filters[props.filterId]
    const searchKey = filter && filter.searchKey
    const services = serviceEntities.services

    return {
        requestEntities,
        pages: result[searchKey] || {},
        filter,
        services,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        select: (ids, filterId) => dispatch(selectItems('requests', ids, filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
