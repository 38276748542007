import { call } from '@advanza/api'
import { LoadingDots } from '@advanza/ui'
import { changeProviderFilter } from 'actions/providers'
import FlexTable from 'components/FlexTable/FlexTable'
import ListItemSelector from 'components/ListItemSelector'
import { getDate } from 'date'
import { format } from 'date-fns'
import { usePrevious } from 'hooks/miscHooks'
import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const LimitReachedOverview = ({ filterId }) => {
    const filter = useSelector((state) => state.providers.filters)[filterId] || {}
    const previousFilter = usePrevious(filter) || {}
    const dispatch = useDispatch()
    const [overview, setOverview] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (!overview || filter.serviceIds !== previousFilter.serviceIds) {
            setIsLoading(true)
            call('office/providers/limit-reached-overview', {
                query: filter,
            })
                .then(({ result }) => {
                    setOverview(result)
                })
                .finally(() => setIsLoading(false))
        }
    }, [filter])
    if (!overview) {
        return <LoadingDots />
    }
    const defs = {
        weekNr: { name: 'week' },
        timesReached: { name: 'timesReached' },
    }
    return (
        <div>
            {isLoading && <LoadingDots />}
            <FlexTable
                definitions={defs}
                headerSelector={<span></span>}
                rows={overview.map((cols) => {
                    const selectedWeeks = filter.limitReachedWeeks || []
                    const isSelected = selectedWeeks.includes(cols.weekNr)
                    return {
                        cols: Object.keys(defs).map((key) => {
                            switch (key) {
                                case 'weekNr':
                                    return (
                                        <Fragment>
                                            <div>
                                                <b>{cols.week} </b>
                                            </div>
                                            <small style={{ opacity: 0.75 }}>
                                                {format(getDate(cols.weekMin), 'd MMM')} -{' '}
                                                {format(getDate(cols.weekMax), 'd MMM')}
                                            </small>
                                        </Fragment>
                                    )
                                case 'timesReached':
                                    return <span>{cols.timesReached}x</span>
                                default:
                                    return cols[key]
                            }
                        }),
                        selector: (
                            <ListItemSelector
                                select={() =>
                                    dispatch(
                                        changeProviderFilter(filterId, {
                                            limitReachedWeeks: isSelected
                                                ? selectedWeeks.filter((nr) => nr !== cols.weekNr)
                                                : selectedWeeks.concat([cols.weekNr]),
                                        })
                                    )
                                }
                                isSelected={isSelected}
                            />
                        ),
                    }
                })}
            />
        </div>
    )
}

export default LimitReachedOverview
