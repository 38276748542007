import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, Divider, Icon, LoadingDots } from '@advanza/ui'
import { changeEntity } from 'actions/entities'
import noteStyle from 'components/notes/noteListItem.module.css'
import { formatRelative, localToUTC } from 'date'
import { addMonths, setDate } from 'date-fns'
import { useInvoicesList } from 'hooks/billingHooks'
import { formatMoney } from 'misc/money'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useOtherCharges = (providerId) => {
    const dispatch = useDispatch()

    const { isFetchingProviders, entities: stateProvidersEntities } = useSelector(
        (state) => state.providers
    )
    const {
        providers = {},
        photoProfilePackages = {},
        logs = {},
        users = {},
    } = stateProvidersEntities
    const { isFetchingInvoices, entities: stateInvoicesEntities } = useSelector(
        (state) => state.invoices
    )
    const { charges = {} } = stateInvoicesEntities
    const provider = providers[providerId] || {}
    const { otherCharges: chargeIds = [], logs: logIds = [] } = provider
    const chargeArr = chargeIds.map((chargeId) => charges[chargeId]).filter((charge) => charge)
    const logArr = logIds.map((logId) => logs[logId]).filter((log) => log)
    const { changeFilter } = useInvoicesList(`p_${providerId}`)
    const [savingId, setSavingId] = useState(null)
    const [responseModal, setResponseModal] = useState(null)
    const [isFetchingOtherCharges, setIsFetchingOtherCharges] = useState(false)
    const [newDescription, setNewDescription] = useState('')
    const [newAmount, setNewAmount] = useState('')
    const [invalidate, setInvalidate] = useState(false)
    const selection = provider.otherChargesSelection || []
    const onSelect = (id) => {
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'providers',
                key: providerId,
                diff: {
                    otherChargesSelection: selection.includes(id)
                        ? selection.filter((_id) => _id !== id)
                        : selection.concat([id]),
                },
            })
        )
    }
    useEffect(() => {
        if (isFetchingOtherCharges || (provider.otherCharges && !invalidate)) {
            return
        }

        setIsFetchingOtherCharges(true)
        call('office/invoices/get-charges-for-provider/' + providerId + '/1')
            .then((response) => {
                let charges = {}
                response.charges.forEach((charge) => (charges[charge.charge_id] = charge))
                const chargeIds = response.charges.map((charge) => charge.charge_id)

                dispatch({
                    type: 'SET_INVOICES',
                    action: {
                        entities: {
                            charges: { $deepMerge: charges },
                        },
                    },
                })
                dispatch(
                    changeEntity({
                        store: 'providers',
                        name: 'providers',
                        key: providerId,
                        diff: {
                            otherCharges: chargeIds,
                        },
                    })
                )
                if (invalidate) {
                    setInvalidate(false)
                }
            })
            .finally(() => setIsFetchingOtherCharges(false))
    }, [invalidate])

    const handleAddedLogs = (addedLogs) => {
        if (addedLogs.length) {
            addedLogs.forEach((log) =>
                dispatch(
                    changeEntity({
                        store: 'providers',
                        name: 'logs',
                        key: log.log_id,
                        diff: log,
                    })
                )
            )
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: providerId,
                    diff: {
                        logs: logIds.concat(addedLogs.map((log) => log.log_id)),
                    },
                })
            )
        }
    }

    const save = (chargeId, data = {}) => {
        if (chargeId < 0) {
            const newAmountFloat = parseFloat(newAmount)

            if (!newDescription.trim() || isNaN(newAmountFloat)) {
                setResponseModal('invalid input')
                return
            }

            data.service_provider_id = providerId
            data.description = newDescription
            data.charge_amount = newAmountFloat
        }

        setSavingId(chargeId)
        return call('office/invoices/save-other-charge/' + chargeId, { json: data })
            .then(({ diff, addedLogs }) => {
                const key = chargeId < 0 ? diff.charge_id : chargeId

                if (chargeId < 0) {
                    dispatch(
                        changeEntity({
                            store: 'providers',
                            name: 'providers',
                            key: providerId,
                            diff: {
                                otherCharges: chargeIds.concat(key),
                            },
                        })
                    )
                }

                dispatch(
                    changeEntity({
                        store: 'invoices',
                        name: 'charges',
                        key,
                        diff,
                    })
                )

                handleAddedLogs(addedLogs)
            })
            .finally(() => setSavingId(null))
    }

    /*
     * Generate an invoice for only the selected charges
     */
    const generateInvoiceFromSelection = () => {
        setSavingId(selection[0])
        call('office/invoices/create-invoice-from-charge-ids/' + providerId, {
            payload: { chargeIds: selection },
        })
            .then((response) => {
                setInvalidate(true)
                changeFilter({ didInvalidate: true })
                setResponseModal(response)
                selection.forEach((id) => onSelect(id))
            }, setResponseModal)
            .finally(() => setSavingId(false))
    }

    const remove = (chargeId) => {
        setSavingId(chargeId)
        return call('office/invoices/remove-other-charge/' + chargeId)
            .then(({ addedLogs }) => {
                dispatch(
                    changeEntity({
                        store: 'providers',
                        name: 'providers',
                        key: providerId,
                        diff: {
                            otherCharges: chargeIds.filter(
                                (chargeIdOld) => chargeIdOld !== chargeId
                            ),
                        },
                    })
                )

                handleAddedLogs(addedLogs)
            })
            .finally(() => setSavingId(null))
    }

    return {
        isFetching: isFetchingProviders || isFetchingInvoices || isFetchingOtherCharges,
        chargeArr,
        photoProfilePackages,
        logArr,
        users,
        savingId,
        save,
        remove,
        selection,
        onSelect,
        generateInvoiceFromSelection,
        newDescription,
        setNewDescription,
        newAmount,
        setNewAmount,
        responseModal,
        setResponseModal,
    }
}

const OtherCharges = ({ providerId }) => {
    const {
        isFetching,
        chargeArr,
        logArr,
        users,
        savingId,
        generateInvoiceFromSelection,
        save,
        remove,
        selection = [],
        onSelect,
        newDescription,
        setNewDescription,
        newAmount,
        setNewAmount,
        responseModal,
        setResponseModal,
    } = useOtherCharges(providerId)

    return (
        !isFetching && (
            <Row>
                <Col md={8}>
                    <Row>
                        <Col x>
                            <InputField
                                placeholder="Description"
                                value={newDescription}
                                onChange={(e) => setNewDescription(e.target.value)}
                            />
                            <Divider m />
                            <Button name="text" onClick={() => save(-1)} disabled={savingId}>
                                {savingId === -1 ? 'saving..' : 'Add to next invoice'}
                            </Button>
                        </Col>
                        <Col x>
                            <InputField
                                placeholder="Amount in euro"
                                value={newAmount}
                                onChange={(e) => setNewAmount(e.target.value)}
                            />
                            <Divider m />
                            <Button
                                name="text"
                                onClick={() =>
                                    save(-2, {
                                        datetime: localToUTC(addMonths(setDate(new Date(), 5), 1)),
                                    })
                                }
                                disabled={savingId}>
                                {savingId === -2 ? 'saving..' : 'Add to invoice after next'}
                            </Button>
                        </Col>
                    </Row>
                    {chargeArr.map(
                        ({ charge_id, datetime, description, charge_amount, invoice_id }) => {
                            const isSelected = selection.includes(charge_id)
                            return (
                                <div
                                    key={charge_id}
                                    style={{ borderTop: '1px solid #ccc', marginTop: 16 }}>
                                    <Divider m />
                                    <Row middle="xs">
                                        <Col xs={1}>
                                            {!invoice_id && (
                                                <Icon
                                                    onClick={() => onSelect(charge_id)}
                                                    name={
                                                        isSelected
                                                            ? 'check_box'
                                                            : 'check_box_outline_blank'
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col xs={3}>{formatRelative(datetime)}</Col>
                                        <Col xs={3}>{description}</Col>
                                        <Col xs={2}>{formatMoney(charge_amount, 'en')}</Col>
                                        <Col xs={2}>
                                            {(invoice_id ? '' : 'not ') + 'on invoice'}
                                        </Col>
                                        <Col xs={2}>
                                            {!invoice_id && (
                                                <Button
                                                    name="text"
                                                    disabled={savingId}
                                                    onClick={() => remove(charge_id)}>
                                                    Remove
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    )}
                    <Divider l />
                    {selection.length > 0 && (
                        <Fragment>
                            <Button onClick={generateInvoiceFromSelection}>
                                {savingId === selection[0] ? (
                                    <LoadingDots />
                                ) : (
                                    'Generate invoice for selection'
                                )}
                            </Button>
                        </Fragment>
                    )}
                </Col>
                <Col md={4} style={{ paddingLeft: 32 }}>
                    {logArr
                        .filter((log) => log.log_type === 'charges')
                        .sort((logA, logB) => logB.log_id - logA.log_id)
                        .map(({ log_id, message, created, user_id }) => (
                            <Row key={log_id} className={noteStyle.root}>
                                <Col xs={12}>
                                    <div className={noteStyle.body}>{message}</div>
                                </Col>
                                <Col>
                                    <Row middle="xs">
                                        <Col x>
                                            <small className="c-grey">
                                                {formatRelative(created)}
                                            </small>
                                        </Col>
                                        <Col x>
                                            <small className="c-grey">
                                                {(users[user_id] || {}).username}
                                            </small>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                </Col>
                <Modal close={() => setResponseModal(false)} open={responseModal}>
                    {responseModal && (
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {JSON.stringify(responseModal, null, 2)}
                        </pre>
                    )}
                </Modal>
            </Row>
        )
    )
}

export default OtherCharges
