import { Col, Row, Section } from '@advanza/advanza_generic'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import SalesLeadsViewContainer from '../../containers/SalesLeadsViewContainer'
import Card from '../Card'
import LinearLoader from '../LinearLoader'
import NotesFilter from '../notes/NotesFilter'
import NotesList from '../notes/NotesList'
import StatusesFilter from '../statuses/StatusesFilter'
import StatusPicker from '../statuses/StatusPicker'
import BackButton from '../ui/BackButton'
import SalesLeadEditLabels from './SalesLeadEditLabels'
import SalesLeadExternal from './SalesLeadExternal'
import SalesLeadProvider from './SalesLeadProvider'
import SalesLeadRequest from './SalesLeadRequest'
import SalesLeadScrapedProfile from './SalesLeadScrapedProfile'
import SalesLeadStatusList from './SalesLeadStatusList'

class SalesLeadView extends React.Component {
    constructor(props) {
        super(props)
        this.setStatus = this.setStatus.bind(this)
    }

    componentDidMount() {
        const { fetchSalesLead, fetchService, id } = this.props
        fetchSalesLead(id).then(() => {
            this.props.lead.service_id && fetchService(this.props.lead.service_id)
        })
    }

    setStatus(statusId) {
        const { id, setStatus } = this.props
        setStatus(id, statusId)
    }

    render() {
        const {
            lead = {},
            id,
            service,
            request,
            provider,
            providerEntities,
            salesLeadContact,
            isLoaded,
        } = this.props

        if (!isLoaded) {
            return <LinearLoader fixed />
        }

        const filterIdAssociations = `s_${id}`
        return (
            <div className="container">
                <Row nm className="v-center">
                    <Col c="auto">
                        <BackButton fallBack="/sales/leads" />
                    </Col>
                    <h1>Saleslead #{id}</h1>
                    {lead.service_provider_id && (
                        <Col c="auto">
                            <Link
                                className="btn"
                                to={`/service/providers/provider/${lead.service_provider_id}`}>
                                To Provider
                            </Link>
                        </Col>
                    )}
                    {lead.request_id && (
                        <Col c="auto">
                            <Link
                                className="btn"
                                to={`/service/requests/request/${lead.request_id}`}>
                                To Request
                            </Link>
                        </Col>
                    )}
                    {lead.service_id && (
                        <Col c="auto">
                            <Link className="btn" to={`/sales/site-info/${lead.service_id}`}>
                                Service info
                            </Link>
                        </Col>
                    )}
                </Row>
                <Row n="mega">
                    <Col c={6}>
                        <Card header="Contact" icon="person">
                            <Section x>
                                {request && service && <SalesLeadRequest {...this.props} />}
                                {provider && (
                                    <SalesLeadProvider
                                        providerEntities={providerEntities}
                                        provider={provider}
                                    />
                                )}
                                {salesLeadContact && !provider && (
                                    <SalesLeadExternal salesLeadContact={salesLeadContact} />
                                )}
                            </Section>
                        </Card>
                        {provider && (
                            <Fragment>
                                <Section s />
                                <Card header="Created Profile" icon="build">
                                    <Section x>
                                        <SalesLeadScrapedProfile
                                            providerEntities={providerEntities}
                                            provider={provider}
                                        />
                                    </Section>
                                </Card>
                            </Fragment>
                        )}
                        <Section s />
                        <Card
                            header="Status"
                            icon="timeline"
                            topRight={<StatusPicker onSelectStatus={this.setStatus} />}>
                            <StatusesFilter
                                filterId={filterIdAssociations}
                                statusType="salesLeads"
                                compact
                                defaultOptions={{ salesLeadIds: [id] }}
                            />
                            <SalesLeadStatusList filterId={filterIdAssociations} />
                        </Card>
                        <Section s />
                        <Card header="Labels" icon="label">
                            <SalesLeadEditLabels
                                classNames={['select-wrap-values']}
                                category="sales_leads"
                                entityId={id}
                            />
                        </Card>
                    </Col>
                    <Col c={6}>
                        <Card header="Notes" icon="speaker_notes">
                            <NotesFilter
                                defaultOptions={{ salesLeadIds: [id], pageSize: 5 }}
                                filterId={filterIdAssociations}
                                newEntityOptions={{
                                    foreign_key: id,
                                    type: 'salesLeads',
                                }}
                            />
                            <NotesList filterId={filterIdAssociations} />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

SalesLeadView.propTypes = {}

export default SalesLeadsViewContainer(SalesLeadView)
