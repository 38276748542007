import { useResizeObserver } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { Button, Divider, LoadingDots, MaxWidth, PreIcon } from '@advanza/ui'
import React from 'react'
import style from './wizardFooter.module.css'

const WizardFooter = ({
    maxWidth,
    footerStyle = {},
    disabled = false,
    checkIsValid = undefined,
    currentNextKey = undefined,
    pageNumStr = '',
    pageDesc = '',
    createFooterComponent = undefined,
    backText = '',
    extraNextButtons = {},
    nextText = '',
    onBack = undefined,
    onExtraNext = () => {},
    onNext = undefined,
}) => {
    const { observeRef, adjustRef } = useResizeObserver({ adjustOffset: 30 })

    return (
        <>
            <div ref={adjustRef} />
            <div className={style.root} style={footerStyle} ref={observeRef}>
                <MaxWidth maxWidth={maxWidth}>
                    <Row className={style.row} bottom="xs">
                        <Col className={style.firstCol} x>
                            <div className={style.firstColTop}>
                                {onBack && (
                                    <Button
                                        className={style.back}
                                        disabled={disabled}
                                        onClick={() => onBack()}
                                        data-test-id="wizard-back-button"
                                        name="text">
                                        <PreIcon icon="arrow_back">{backText}</PreIcon>
                                    </Button>
                                )}
                                {pageNumStr && <div className={style.pageNum}>{pageNumStr}</div>}
                            </div>
                            <Divider xs />
                            {pageDesc && <div className={style.pageDesc}>{pageDesc}</div>}
                        </Col>
                        {createFooterComponent && <Col x>{createFooterComponent(disabled)}</Col>}
                        {Object.keys(extraNextButtons).map((key) => (
                            <Col key={key} x>
                                <Button
                                    className={style.next}
                                    name={extraNextButtons[key].styleName}
                                    disabled={disabled || (checkIsValid && !checkIsValid(key))}
                                    onClick={() => onExtraNext(key)}>
                                    {currentNextKey === key ? (
                                        <LoadingDots color="#fff" />
                                    ) : (
                                        extraNextButtons[key].text || extraNextButtons[key]
                                    )}
                                </Button>
                            </Col>
                        ))}
                        {onNext && (
                            <Col x>
                                <Button
                                    className={style.next}
                                    data-test-id="wizard-next-button"
                                    disabled={disabled || (checkIsValid && !checkIsValid(''))}
                                    onClick={() => onNext()}>
                                    {currentNextKey === '' ? (
                                        <LoadingDots color="#fff" />
                                    ) : (
                                        nextText
                                    )}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </MaxWidth>
            </div>
        </>
    )
}

export default WizardFooter
