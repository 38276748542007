import { Row, Col } from '@advanza/grid'
import ProfileLabelsFilterContainer from 'containers/ProfileLabelsFilterContainer'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import Pagination from '../Pagination'

const ProfileLabelsFilter = ({
    filterId,
    changeFilter,
    defaultOptions = {},
    filter,
    count,
    countOnPage,
    compact,
}) => {
    const _changeFilter = (options) => {
        changeFilter(filterId, options)
    }
    const onPageChange = (page, invalidate = false) => {
        _changeFilter({ page, didInvalidate: invalidate })
    }
    const onCountList = () => {
        _changeFilter({ countList: true, didInvalidate: true })
    }

    useEffect(() => {
        if (!filter) {
            _changeFilter(defaultOptions)
        }
    }, [])

    useEffect(() => {
        if (filter && filter.didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            _changeFilter({})
        }
    }, [filter])

    return (
        <Fragment>
            {!filter || compact ? null : (
                <Fragment>
                    <Row middle="xs">
                        <Col x style={{ marginLeft: 'auto' }}>
                            <Pagination
                                current={filter.page || 0}
                                pageSize={filter.pageSize}
                                count={count}
                                countOnPage={countOnPage}
                                onCountList={onCountList}
                                onPageChange={onPageChange}
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    )
}

ProfileLabelsFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
    count: PropTypes.number,
    countOnPage: PropTypes.number,
}

export default ProfileLabelsFilterContainer(ProfileLabelsFilter)
