import { Icon, Section } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import AddServiceToSanity from 'components/services/AddServiceToSanity'
import ServicesListContainer from '../../containers/ServicesListContainer'
import TableList from '../TableList'

class ServicesList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    renderColumns(id) {
        const { serviceEntities } = this.props
        const { services } = serviceEntities
        const service = services[id]
        return [
            <small className="text">#{id}</small>,
            service.name,
            <Section className="label" bg={service.subscribable ? 'green' : 'magenta'}>
                {service.subscribable ? 'yes' : 'no'}
            </Section>,
            <Section className="label" bg={service.visible ? 'green' : 'magenta'}>
                {service.visible ? 'yes' : 'no'}
            </Section>,
            <a target="_blank" href={`https://app.advanza.nl/service/${service.url}`}>
                <Icon>link</Icon>
                {service.url}
            </a>,
            <Section className="label" bg={service.use_active_campaign ? 'green' : 'magenta'}>
                {service.use_active_campaign ? 'yes' : 'no'}
            </Section>,
            <AddServiceToSanity serviceId={service.service_id} />,
        ]
    }

    renderRow(id) {
        return {
            url: `/settings/services/service/${id}`,
            cols: this.renderColumns(id),
            id,
        }
    }

    definitions = {
        id: { name: '#id' },
        name: { name: 'name' },
        name2: { name: 'subscribable' },
        name3: { name: 'visible' },
        name4: { name: 'url' },
        name5: { name: 'use_active_campaign' },
        sanity: { name: 'sanity', preventRedirect: true },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

ServicesList.propTypes = {
    pages: PropTypes.any,
    serviceEntities: PropTypes.object,
    filter: PropTypes.object,
    isFetching: PropTypes.bool,
}

export default ServicesListContainer(ServicesList)
