import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { FILES_CHANGE_FILTER, FILES_RECEIVE_LIST, FILES_REQUEST } from '../actions/files'
import {
    changeEntity,
    changeFilter,
    changePage,
    invalidateFilters,
    receiveList,
    selectItems,
    swapIdInPage,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [FILES_RECEIVE_LIST]: receiveList,
    [FILES_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: action.filterId || true },
        }),
    [FILES_CHANGE_FILTER]: changeFilter,
    FILES_SWAP_ID_IN_PAGE: swapIdInPage,
    FILES_CHANGE_PAGE: changePage,
    CHANGE_ENTITY_FILES: changeEntity,
    INVALIDATE_FILES: invalidateFilters,
    FILES_SELECT_ITEMS: selectItems,
    REQUEST_SCRAPED_IMAGES_URLS: (state, action) =>
        update(state, {
            websiteImages: {
                [action.url]: { $set: false },
            },
        }),
    RECEIVE_SCRAPED_IMAGES_URLS: (state, action) =>
        update(state, {
            websiteImages: {
                [action.url]: { $set: { ...action } },
            },
        }),
    REQUEST_CRAWLED_DESCRIPTIONS: (state, action) =>
        update(state, {
            crawledDescriptions: {
                [action.url]: { $set: { isFetching: true } },
            },
        }),
    RECEIVE_CRAWLED_DESCRIPTIONS: (state, action) =>
        update(state, {
            crawledDescriptions: {
                [action.url]: { $set: { ...action.response, hasFetched: true } },
            },
        }),
    REQUEST_AI_DESCRIPTIONS: (state, action) =>
        update(state, {
            aiDescriptions: {
                [action.providerId]: { $set: { isFetching: true } },
            },
        }),
    RECEIVE_AI_DESCRIPTIONS: (state, action) =>
        update(state, {
            aiDescriptions: {
                [action.providerId]: { $set: { ...action.response, hasFetched: true } },
            },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    websiteImages: {},
    crawledDescriptions: {},
    aiDescriptions: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
