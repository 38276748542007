export function getWebsiteHostnameByCountryCode(countryCode, useTest = true) {
    const all = {
        NL: 'trustoo.nl',
        BE: 'trustlocal.be',
        DE: 'trustlocal.de',
    }

    const host = all[countryCode.toUpperCase()] || `trustlocal.${countryCode.toLowerCase()}`

    if (useTest && window.location.hostname.includes('test')) {
        return 'test.' + host
    } else if (useTest && window.location.hostname.includes('l.')) {
        return 'l.' + host
    }

    return host
}
