import { Section, Icon } from '@advanza/advanza_generic'
import { HoverText } from '@advanza/ui'
import AdminUser from 'components/adminUsers/AdminUser'
import React, { useState, Fragment } from 'react'
import style from './officeLabel.module.css'

const FLAG_HIGHLIGHT = 1

const OfficeLabel = ({ label, textOnly }) => {
    const [showUser, setShowUser] = useState(false)
    const { color, text_color, description } = label
    const colors = color.replace('#', '')
    const red = parseInt(colors.substr(0, 2), 16)
    const green = parseInt(colors.substr(2, 2), 16)
    const blue = parseInt(colors.substr(4, 2), 16)
    const className = red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? '' : 'c-white'
    const classNames = [
        className,
        label._joinData && label._joinData.flags & FLAG_HIGHLIGHT ? style.highlight : '',
    ].join(' ')

    if (textOnly) {
        return (
            <span
                style={{ backgroundColor: color, color: text_color, padding: '0 3px' }}
                className={classNames}>
                {description}
            </span>
        )
    }
    return (
        <div
            onContextMenu={(e) => {
                setShowUser(!showUser)
                e.preventDefault()
            }}
            style={{ backgroundColor: color, color: text_color }}
            className={`label ${classNames}`}>
            {!showUser ? (
                <Fragment>
                    {label.is_unique && <Icon className={className}>label_important</Icon>}
                    {description}
                </Fragment>
            ) : (
                <AdminUser tiny userId={label._joinData.user_id} />
            )}
        </div>
    )
}

OfficeLabel.propTypes = {}

export default OfficeLabel
