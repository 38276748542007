import { Col } from '@advanza/grid'
import { Divider, MaxWidth, PreIcon } from '@advanza/ui'
import LimitReachedOverview from 'components/limitReached/LimitReachedOverview'
import ProvidersFilter from 'components/providers/ProvidersFilter'
import ProvidersList from 'components/providers/ProvidersList'
import Restricted from 'components/Restricted'
import Page from 'components/ui/Page'
import queryString from 'querystring'
import React from 'react'
import { useSelector } from 'react-redux'
import style from './LimitReachedPage.module.css'

const LimitReachedPage = () => {
    const filterId = 'limitReached'
    const filter = useSelector((state) => state.providers.filters)[filterId]
    return (
        <Page title={<PreIcon icon="production_quantity_limits">Limits</PreIcon>}>
            <Divider xl />
            <MaxWidth maxWidth={1400}>
                <div className={style.header}>
                    <h1>Limit reached overview</h1>
                    <Restricted location="export" checkWrite={false}>
                        <PreIcon name="exit_to_app">
                            <a
                                target="_blank"
                                href={`${window.location.protocol}//${
                                    window.location.hostname
                                }/api/office/providers/export/?${queryString.stringify({
                                    ...filter,
                                    page: 1,
                                })}`}>
                                Export sheet
                            </a>
                        </PreIcon>
                    </Restricted>
                </div>
                <Divider m />
                <ProvidersFilter
                    filterId={filterId}
                    hideFilters={['nrNotes']}
                    defaultOptions={{
                        includeLimitReached: 1,
                        flags: ['signed'],
                        order: 'limitsReached DESC',
                    }}
                />
                <Divider l />
                <div className={style.row}>
                    <div>
                        <h3>Week overview</h3>
                        <Divider />
                        <LimitReachedOverview filterId={filterId} />
                    </div>
                    <div>
                        <h3>Providers</h3>
                        <Divider />
                        <ProvidersList
                            showCols={[
                                'status',
                                'ActiveStatus',
                                'service',
                                'name',
                                'weekLimit',
                                'limitsReached',
                            ]}
                            filterId="limitReached"
                            includeLimitReached
                        />
                    </div>
                </div>
            </MaxWidth>
            <Divider xl />
        </Page>
    )
}

export default LimitReachedPage
