export function bodyClickOutside(e, outsideTriggers) {
    let el = e.target
    const triggers = outsideTriggers.className ? [outsideTriggers] : outsideTriggers
    for (const i in triggers) {
        const {className, limit, action} = triggers[i]
        if (!className) {
            throw new Error("ClassName not provided ")
        }
        const outside = isOutside(className, limit)

        outside && action && action()
    }

    function isOutside(className, limit) {
        let outside = true
        const classNames = className instanceof Array ? className : [className]
        const parentClassNames = getClassNames(el, limit)
        for (const i in parentClassNames) {
            const parentClassName = parentClassNames[i]
            for (const i in classNames) {

                const className = classNames[i]
                if(parentClassName == className) {
                    outside = false
                }
            }
        }
        return outside
    }

    function getClassNames(el, limit) {
        const classNames = []
        const addClassNames = (string) => string.split &&
            string.split(' ').map((name) => name.length > 1 && classNames.push(name))

        el.className && addClassNames(el.className)
        for (let i = 0; i < (limit || 12); i++) {
            const parent = el && el.parentNode
            parent && parent.className && addClassNames(parent.className)
            el = parent
        }
        return classNames
    }

}