import { schema } from 'normalizr'

const notes = new schema.Entity('notes', {}, { idAttribute: 'note_id' })

const reclaimsSchema = new schema.Entity(
    'reclaims',
    {
        notes: [notes],
    },
    {
        idAttribute: 'reclaim_id',
    }
)

export default [reclaimsSchema]
