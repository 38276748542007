import { fetchLicences } from 'actions/licences'
import React from 'react'
import { connect } from 'react-redux'

function mapStateToProps(state) {
    const { licences = {} } = state.licences.entities
    return {
        licences,
        licenceIds: Object.keys(licences),
    }
}

export default connect(mapStateToProps, { fetchLicences })
