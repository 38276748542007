import { Col, Row } from '@advanza/grid'
import { Divider, Icon, PreIcon } from '@advanza/ui'
import { ChangeEvent, CSSProperties, MouseEvent, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import style from './checkbox.module.css'
import inputFieldStyle from './inputField.module.css'

export interface CheckboxChangeEvent {
    target: {
        name?: string
        value: boolean
    }
}

interface Props {
    className?: string
    style?: CSSProperties
    alt?: boolean
    top?: boolean
    title?: string
    name?: string
    disabled?: boolean
    error?: string | boolean
    defaultErrorMsg?: string
    value: boolean
    onChange: (e: CheckboxChangeEvent) => void
    onLabelClick?: (e: MouseEvent<HTMLLabelElement>) => void
    children?: ReactNode
}

const Checkbox = ({
    className = '',
    style: inlineStyle = {},
    alt = false,
    top = false,
    title = '',
    name = undefined,
    disabled = false,
    error = false,
    defaultErrorMsg = '',
    value,
    onChange,
    onLabelClick = undefined,
    children = undefined,
}: Props) => {
    const { t } = useTranslation()
    const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) =>
        onChange({
            target: { name, value: e.target.value === '1' },
        })

    return (
        <div
            data-field-has-error={error ? true : undefined}
            className={[
                style.checkbox,
                value ? style.checked : '',
                disabled ? style.disabled : '',
                className,
            ].join(' ')}
            style={inlineStyle}>
            <label onClick={onLabelClick}>
                <input
                    type="checkbox"
                    value={value ? 0 : 1}
                    onChange={onChangeCheckbox}
                    name={name}
                    disabled={disabled}
                />
                {alt ? (
                    <>
                        <Row style={{ flexWrap: 'noWrap' }}>
                            <Col xs>{title}</Col>
                            <Col x>
                                <Icon
                                    name={value ? 'check_box' : 'check_box_outline_blank'}
                                    fontSize={24}
                                />
                            </Col>
                        </Row>
                        <Divider m />
                        {children}
                    </>
                ) : (
                    <PreIcon top={top} icon={value ? 'check_box' : 'check_box_outline_blank'}>
                        <div>{children || title}</div>
                    </PreIcon>
                )}
            </label>
            {error && (
                <div className={inputFieldStyle.error}>
                    <Divider xs />
                    <PreIcon icon="error" fontSize={24}>
                        {(typeof error === 'string' && error) ||
                            defaultErrorMsg ||
                            t('form_default_error_single_checkbox')}
                    </PreIcon>
                </div>
            )}
        </div>
    )
}

export default Checkbox
