import { Col, Row } from '@advanza/grid'
import { useScrollModal } from '@advanza/modal'
import { Button, Divider, Icon } from '@advanza/ui'
import { Fragment, useState } from 'react'
import { arrayMove, List } from 'react-movable'
import style from './simpleOrderModal.module.css'

const SimpleOrderModal = ({
    orderedIds = [],
    renderItem = undefined,
    text = '',
    disabled = false,
    onSave = () => {},
}) => {
    const { openModal, renderModal } = useScrollModal()
    const [items, setItems] = useState([])

    const onOpen = () => {
        setItems(orderedIds)
        openModal()
    }

    const onChangeOrder = ({ oldIndex, newIndex }) => {
        const newOrder = arrayMove(items, oldIndex, newIndex)
        setItems(newOrder)
    }

    const modal = renderModal({
        heading: 'Adjust order',
        sub: text || undefined,
        body: (
            <List
                values={items}
                onChange={onChangeOrder}
                lockVertically={true}
                renderList={({ children, props }) => (
                    <div className={style.list} {...props}>
                        {children}
                        <Divider m />
                    </div>
                )}
                renderItem={({ value, index, isDragged, props }) => (
                    <div
                        className={[style.listItem, isDragged ? style.isDragged : ''].join(' ')}
                        {...props}>
                        <Row middle="xs" between="xs" className={style.listItemRow}>
                            <Col x>
                                <div className={style.number}>
                                    {props.key >= 0 ? props.key + 1 : '-'}
                                </div>
                            </Col>
                            <Col x>{renderItem ? renderItem(value, index) : value}</Col>
                            <Col x>
                                <div className={style.key}>
                                    <Icon name="import_export" fontSize={28} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            />
        ),
        buttons: [
            {
                text: 'Cancel',
                name: 'link',
            },
            {
                text: 'Save',
                func: () => onSave(items),
            },
        ],
        maxWidth: '600px',
    })

    return (
        <Fragment>
            {orderedIds.length > 1 && (
                <Button name="text" onClick={onOpen} disabled={disabled} className={style.editBtn}>
                    <Icon name="edit" primColor style={{ marginTop: '-4px' }} /> Adjust order
                </Button>
            )}
            {modal}
        </Fragment>
    )
}

export default SimpleOrderModal
