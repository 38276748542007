import { Icon } from '@advanza/ui'
import Card from 'components/Card'
import LabelEntity from 'components/labels/LabelEntity'
import ProfileLabelsListContainer from 'containers/ProfileLabelsListContainer'
import { useProvidersLabels } from 'hooks/providersLabelsHooks'
import PropTypes from 'prop-types'
import React from 'react'
import CheckButton from '../CheckButton'
import TableList from '../TableList'
import style from './profileLabels.module.css'

const ProfileLabelsList = ({ profileLabels, ...rest }) => {
    const { toggleActive, deleteLabel } = useProvidersLabels()
    const definitions = {
        id: { name: '#id' },
        description: { name: 'label' },
        active: { name: 'active', preventRedirect: true },
        type: { name: 'type' },
        delete: { name: 'del' },
        edit: { name: 'edit' },
    }

    const renderRow = (id) => {
        const profileLabel = profileLabels[id]
        const officeLabel = profileLabel.office_label

        return {
            id,
            cols: renderColumns(profileLabel),
            expandable: officeLabel.custom && (
                <Card expandableStyle>
                    <LabelEntity
                        entityId={officeLabel.office_label_id}
                        labelCategories={['profile']}
                        labelSubcategories={['platform', 'qualification']}
                        labelTypes={[]}
                    />
                </Card>
            ),
            expanded: typeof officeLabel.office_label_id === 'string',
            expandOnClick: true,
        }
    }

    const renderColumns = (profileLabel) => {
        const officeLabel = profileLabel.office_label
        const labelStyle = {
            color: officeLabel.text_color,
            backgroundColor: officeLabel.color,
        }

        return [
            <small className="c-grey">#{profileLabel.providers_labels_id}</small>,
            <div className={style.profileLabelWrapper}>
                <div className={style.profileLabel} style={labelStyle}>
                    {officeLabel.icon && <Icon name={officeLabel.icon} fontSize={12} />}
                    <span>{officeLabel.description}</span>
                </div>
            </div>,
            <CheckButton
                checked={profileLabel.active}
                msg="active"
                inlineBlock
                onChange={() => toggleActive(profileLabel.providers_labels_id)}
            />,
            officeLabel.custom ? 'Custom' : officeLabel.premium ? 'Premium' : 'General',
            !officeLabel.custom && (
                <Icon
                    name="delete"
                    onClick={() => deleteLabel(profileLabel.providers_labels_id)}
                    fontSize={16}
                />
            ),
        ]
    }

    return (
        <TableList {...rest} selectable={false} renderRow={renderRow} definitions={definitions} />
    )
}

ProfileLabelsList.propTypes = {
    profileLabels: PropTypes.object,
    filter: PropTypes.object,
    pages: PropTypes.any,
    isFetching: PropTypes.bool,
}

export default ProfileLabelsListContainer(ProfileLabelsList)
