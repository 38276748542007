import { Col, Loading, Row, Select } from '@advanza/advanza_generic'
import React from 'react'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'
import Card from '../Card'
import ServiceRequestsStatsGeoGraph from './ServiceRequestsStatsGeoGraph'
import ServiceRequestStatsCalendarChart from './ServiceRequestStatsCalendarChart'
import ServiceRequestStatsOptionsPieGraph from './ServiceRequestStatsOptionsPieGraph'

class ServiceRequestStatsAll extends React.Component {
    constructor(props) {
        super(props)
        this._changeOptions = this._changeOptions.bind(this)
        this.onChangeDays = this.onChangeDays.bind(this)
    }

    componentDidMount() {
        const { fetchStats, serviceId } = this.props
        fetchStats(serviceId, 'requestStats')
    }

    _changeOptions(options) {
        const { changeOptions, serviceId, fetchStats } = this.props
        changeOptions(serviceId, 'requestStats', { ...options, didInvalidate: true })
        fetchStats(serviceId, 'requestStats')
    }

    onChangeDays(e) {
        this._changeOptions({ days: e.target.value })
    }

    render() {
        const { serviceId, getStatsAndOptions } = this.props
        const { stats, options } = getStatsAndOptions('requestStats')
        if (!stats) {
            return null
        }
        const { isFetching } = options
        return (
            <div>
                <Row>
                    <Col c={12} s>
                        <h3>Request stats all services</h3>
                        <Row n nm className="v-center">
                            <span>
                                <span className="score">{isFetching ? '..' : stats.nrReq}</span>{' '}
                                requests last&nbsp;
                            </span>
                            <Select
                                value={options.days || 30}
                                onChange={this.onChangeDays}
                                className="select-stealth select-min select-wide"
                                options={[
                                    { value: 7, title: '7 days' },
                                    { value: 10, title: '10 days' },
                                    { value: 30, title: '30 days' },
                                    { value: 90, title: '3 months' },
                                    { value: 180, title: '6 months' },
                                    { value: 365, title: '1 year' },
                                ]}
                            />
                        </Row>
                    </Col>
                    <Col c={6}>
                        <Card header="Requests" icon="assignment">
                            <ServiceRequestsStatsGeoGraph serviceId={serviceId} />
                        </Card>
                    </Col>
                    <Col c={12}>
                        <ServiceRequestStatsCalendarChart serviceId={serviceId} />
                    </Col>
                </Row>
            </div>
        )
    }
}

ServiceRequestStatsAll.propTypes = {}

export default ServiceStatsContainer(ServiceRequestStatsAll)
