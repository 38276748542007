import { gridStyle } from '@advanza/grid'
import { Divider, PreIcon } from '@advanza/ui'
import ProfileProjectsEntity from 'components/profileProjects/ProfileProjectsEntity'
import { useUnsavedChangesModal } from 'hooks/modalHooks'
import { useProfileProjectsItem } from 'hooks/profileProjectsHooks'
import style from './profileProjects.module.css'

const ProfileProjectsEditModal = ({
    projectId,
    closeProjectModal,
    renderProjectModal,
    onClose,
}) => {
    const { isNew, isTouched, onDiscard, onSave, isSaving, error, getValidator } =
        useProfileProjectsItem(projectId)

    const closeModal = () => {
        closeProjectModal()
        onClose && onClose()
    }

    const onSaveChanges = () => {
        if (isNew || isTouched) {
            return onSave().then(
                () => closeModal(),
                () => {}
            )
        } else {
            closeModal()
        }
    }

    const onDiscardChanges = () => {
        if (isNew || !isTouched) {
            closeModal()
        } else {
            Promise.resolve(onDiscard()).then(() => closeModal())
        }
    }

    const { openModal: openCancelModal, modal: cancelModal } = useUnsavedChangesModal(
        isSaving,
        onSaveChanges,
        onDiscardChanges,
        'project'
    )

    const onCancelChanges = () => {
        if (isNew || isTouched) {
            openCancelModal()
        } else {
            // no usaved changes
            closeModal()
        }
    }

    const projectModal = renderProjectModal({
        body: (
            <div className={style.modalContent}>
                <div>
                    <Divider s className={gridStyle['invisible-xs']} />
                    {error ? (
                        <div className={style.warningText}>
                            <Divider m />
                            <PreIcon icon="warning" red>
                                {error}
                            </PreIcon>
                            <Divider m />
                        </div>
                    ) : (
                        <Divider l />
                    )}
                    <ProfileProjectsEntity projectId={projectId} getValidator={getValidator} />
                </div>
            </div>
        ),
        buttons: [
            {
                text: 'Cancel',
                name: 'bordered',
                func: onCancelChanges,
                closeOnSuccess: false,
            },
            {
                text: isSaving ? 'Saving...' : isNew || isTouched ? 'Save' : 'Back',
                func: onSaveChanges,
                closeOnSuccess: false,
            },
        ],
        closeButton: {
            func: onCancelChanges,
            closeOnSuccess: false,
        },
        maxWidth: '724px',
    })

    return (
        <>
            {projectModal}
            {cancelModal}
        </>
    )
}

export default ProfileProjectsEditModal
