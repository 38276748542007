import React from 'react'
import FlexTable from '../FlexTable/FlexTable'
import Page from '../ui/Page'
import SalesLeadView from './SalesLeadView'

const SalesLeadViewPage = ({ match }) => {
    const { id } = match.params
    return (
        <Page title={`Sales Lead #${id}`}>
            <SalesLeadView id={id} />
        </Page>
    )
}

SalesLeadViewPage.propTypes = {}

export default SalesLeadViewPage
