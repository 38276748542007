import React from 'react'
import { connect } from 'react-redux'

function mapStateToProps(state, props) {
    const { requests, ...requestEntities } = state.requests.entities
    const { services, ...serviceEntities } = state.services.entities
    const request = requests && requests[props.requestId]
    const service = request && services && services[request.service_id]
    return {
        requestEntities,
        serviceEntities,
        request,
        service,
    }
}

export default connect(mapStateToProps, null)
