import { Icon, JumpList, Row } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import ReviewFilterContainer from '../../containers/ReviewFilterContainer'
import Pagination from '../Pagination'
import SafeButton from '../ui/SafeButton'

class ReviewFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onPageChange = this.onPageChange.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.addReview = this.addReview.bind(this)
        this.deleteSelection = this.deleteSelection.bind(this)
    }

    _changeFilter(options) {
        const { changeFilter, filterId } = this.props
        changeFilter(filterId, options)
    }

    componentDidMount() {
        const { defaultOptions = {}, filter } = this.props
        if (!filter) {
            this._changeFilter(defaultOptions)
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    deleteSelection() {
        const { filter, deleteSelection } = this.props
        return deleteSelection(filter.filterId)
    }

    addReview() {
        const { filter, newEntityOptions, addReview } = this.props
        addReview(newEntityOptions, filter.filterId)
        this._changeFilter({ page: 0 })
    }

    render() {
        const { filter, count, compact, newEntityOptions } = this.props
        if (!filter || compact) {
            return null
        }

        const hasSelection = filter.selection && filter.selection.length > 0
        const pageNr = filter.page || 0
        return (
            <Row className="v-center" nm>
                {newEntityOptions && (
                    <button className="plain" onClick={this.addReview}>
                        <Icon>add</Icon> Add review
                    </button>
                )}
                {hasSelection && (
                    <JumpList
                        items={[
                            {
                                _element: (
                                    <SafeButton
                                        action={this.deleteSelection}
                                        className="btn"
                                        confirmMessage={`Delete ${filter.selection.length} reviews`}
                                        buttonText="Delete selection"
                                    />
                                ),
                            },
                        ]}
                    />
                )}
                {count > filter.pageSize && (
                    <div className="to-right">
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            onPageChange={this.onPageChange}
                        />
                    </div>
                )}
            </Row>
        )
    }
}

ReviewFilter.propTypes = {
    defaultOptions: PropTypes.object,
    filterId: PropTypes.string.isRequired,
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    compact: PropTypes.bool,
}

export default ReviewFilterContainer(ReviewFilter)
