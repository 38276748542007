import { Row, Col } from '@advanza/grid'
import { Divider, Button } from '@advanza/ui'
import A from 'components/ui/A'
import React, { Fragment } from 'react'
import { useCurrentCountry } from 'hooks/miscHooks'

const ExactExportBlock = ({
    items,
    type,
    isExporting,
    exportFunc,
    loadBatchesFunc,
    downloadUrl,
}) => {
    const blockIsExporting = isExporting === type
    const { protocol, hostname } = window.location
    const country = useCurrentCountry()
    return (
        <div>
            {items && items.count && (
                <Fragment>
                    <b>
                        {items.count} {type}
                    </b>
                    {items.count > 0 && (
                        <div>
                            <p>
                                <A
                                    external
                                    href={`${protocol}//${hostname}/api/office/invoices/${downloadUrl}/${items.batchNr}?country=${country}`}
                                    target="_blank">
                                    Download csv
                                </A>
                            </p>
                        </div>
                    )}
                    <Divider l />
                </Fragment>
            )}
            {items && items.prevBatches && (
                <Fragment>
                    {items.prevBatches.map(([batch, nr]) => {
                        return (
                            <p>
                                <A
                                    external
                                    href={`${protocol}//${hostname}/api/office/invoices/${downloadUrl}/${batch}?country=${country}`}
                                    target="_blank">
                                    Download csv batch #{batch} {type} ({nr})
                                </A>
                            </p>
                        )
                    })}
                </Fragment>
            )}
            <Row between="xs" middle="xs">
                <Col x>
                    <Button disabled={isExporting} onClick={exportFunc}>
                        {blockIsExporting ? '..exporting' : `Export ${type}`}
                    </Button>
                </Col>
                <Col x>
                    <Button disabled={isExporting} name="text" onClick={loadBatchesFunc}>
                        {blockIsExporting ? '..loading' : 'previous'}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default ExactExportBlock
