import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { Modal } from '@advanza/modal'
import { Button, Divider, Icon, LinearLoader } from '@advanza/ui'
import { changeEntity } from 'actions/entities'
import { fetchLabels } from 'actions/labels'
import { saveProvider } from 'actions/providers'
import { DAY_MONTH_YEAR_FORMAT_FNS, utcToLocal } from 'date'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './badges.module.css'

const BADGES = ['TopScore', 'TopPro']

const useBadges = (providerId) => {
    const dispatch = useDispatch()
    const { isFetching, entities: { providers = {} } = {} } = useSelector(
        (state) => state.providers
    )
    const provider = providers[providerId] || {}
    const { office_labels: ownLabels = [] } = provider
    const { isFetching: isFetchingAllLabels, entities: { labels: allLabels = {} } = {} } =
        useSelector((state) => state.labels)
    const [savingId, setSavingId] = useState(null)
    const [calcBadgesBusy, setCalcBadgesBusy] = useState(false)
    const [calcBadgesResult, setCalcBadgesResult] = useState({})

    useEffect(() => {
        dispatch(
            fetchLabels({
                searchKey: 'badges',
                categories: 'badges',
                noLimit: true,
            })
        )
    }, [])

    const remove = (officeLabelId) => {
        setSavingId(officeLabelId)
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'providers',
                key: providerId,
                diff: {
                    office_labels: ownLabels.filter(
                        (label) => label.office_label_id !== officeLabelId
                    ),
                },
            })
        )
        return dispatch(saveProvider(providerId)).finally(() => setSavingId(null))
    }

    const calcBadges = () => {
        setCalcBadgesBusy(true)
        setCalcBadgesResult({})
        return Promise.all(
            BADGES.map((badge) =>
                call(`office/providers/calc-badge/${providerId}/badge${badge}`).then((response) =>
                    setCalcBadgesResult((prevResult) => ({
                        ...prevResult,
                        [badge]: response.labelInfo,
                    }))
                )
            )
        ).finally(() => setCalcBadgesBusy(false))
    }

    return {
        isFetching: isFetching || isFetchingAllLabels,
        ownLabels,
        allLabels,
        savingId,
        calcBadgesBusy,
        calcBadgesResult,
        remove,
        calcBadges,
    }
}

const Badges = ({ providerId }) => {
    const {
        isFetching,
        ownLabels,
        allLabels,
        savingId,
        calcBadgesBusy,
        calcBadgesResult,
        remove,
        calcBadges,
    } = useBadges(providerId)
    const [officeLabelIdToDelete, setOfficeLabelIdToDelete] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const closeAndResetDialog = () => {
        if (!savingId) {
            setIsModalOpen(false)
        }
    }

    const sortedBadgeLabels = Object.values(allLabels)
        .filter((label) => label.category === 'badges')
        .sort((a, b) =>
            b.type.includes('top_pro')
                ? 1
                : a.type.includes('top_pro')
                ? -1
                : b.description > a.description
                ? 1
                : a.description > b.description
                ? -1
                : 0
        )

    const sortedOwnLabels = ownLabels.sort((a, b) =>
        b._joinData.created > a._joinData.created ? 1 : -1
    )

    return (
        !isFetching && (
            <>
                <Modal open={isModalOpen} close={closeAndResetDialog}>
                    {savingId && <LinearLoader absolute />}
                    <h2>Delete Badge &mdash; Are you sure?</h2>
                    <Divider m />
                    <span style={{ color: 'red' }}>This action can't be undone</span>
                    <Divider m />
                    <Button
                        disabled={savingId}
                        name="delete"
                        onClick={() => remove(officeLabelIdToDelete).then(closeAndResetDialog)}>
                        <Icon name="badge" red /> Delete Badge
                    </Button>
                </Modal>

                <Row end="xs">
                    <Col x>
                        <Button name="text" onClick={calcBadges}>
                            <Icon name="search" className="colorPrimary" /> View calculation
                        </Button>
                    </Col>
                </Row>

                {sortedBadgeLabels.map(({ office_label_id, description }) => {
                    const ownLabel = sortedOwnLabels.find(
                        (label) => label.office_label_id === office_label_id
                    )
                    return (
                        <div key={office_label_id}>
                            <Divider m />
                            <Row middle="xs">
                                <Col xs={3}>
                                    {description.replace(/(^|\s)[a-z]/g, (match) =>
                                        match.toUpperCase()
                                    )}
                                </Col>
                                <Col xs={1}>&mdash;</Col>
                                <Col xs={5}>
                                    {ownLabel && !ownLabel._joinData.active && '[inactive] '}
                                    {ownLabel
                                        ? 'Received at ' +
                                          utcToLocal(
                                              ownLabel._joinData.created,
                                              DAY_MONTH_YEAR_FORMAT_FNS
                                          )
                                        : 'Not qualified'}
                                </Col>
                                <Col xs={3}>
                                    {ownLabel && (
                                        <Button
                                            name="text"
                                            disabled={savingId}
                                            onClick={() => {
                                                setOfficeLabelIdToDelete(office_label_id)
                                                setIsModalOpen(true)
                                            }}>
                                            <Icon name="delete" />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    )
                })}
                {(Object.keys(calcBadgesResult).length > 0 || calcBadgesBusy) && (
                    <>
                        <Divider m />
                        <div className={style.box}>
                            {calcBadgesBusy && <LinearLoader absolute />}
                            {Object.keys(calcBadgesResult)
                                .filter((badge) => badge !== 'status')
                                .map((badge, i) => (
                                    <div key={badge}>
                                        {i > 0 && <Divider m />}
                                        <div>
                                            Has {badge}:{' '}
                                            {Object.values(calcBadgesResult[badge]).every(
                                                (item) => item
                                            )
                                                ? 'yes'
                                                : 'no'}
                                        </div>
                                        <ul className={style.indent}>
                                            {Object.keys(calcBadgesResult[badge]).map((cond) => (
                                                <li key={cond}>
                                                    {cond}:{' '}
                                                    {calcBadgesResult[badge][cond] ? 'yes' : 'no'}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                        </div>
                    </>
                )}
                <Divider l />
            </>
        )
    )
}

export default Badges
