const PROFILE_BUILDER_ALLOWED_PATHS = [
    /^\/service\/providers\/?$/i,
    /^\/service\/providers\/provider\/\d+\/profile/i,
    /^\/service\/providers\/provider\/\d+\/reviews/i,
    /^\/service\/providers\/subscription/i,
    /^\/service\/profile-report/i,
    /^\/service\/profile-builder-wizard/i,
]

const PROFILE_BUILDER_WIZARD_CONFIG_PATH = /^\/service\/providers-builders/i

export const PROFILE_BUILDER_REDIRECT = '/service/providers'

export const isProfileBuilder = () =>
    window._user &&
    (window._user.email.toLowerCase().indexOf('@prems.work') !== -1 ||
        window._user.username.toLowerCase().indexOf('@prems.work') !== -1)

export const isProfileBuilderAllowedLocation = () =>
    !isProfileBuilder() ||
    PROFILE_BUILDER_ALLOWED_PATHS.concat(
        hasAccess('profile_builder_wizard_config') ? PROFILE_BUILDER_WIZARD_CONFIG_PATH : []
    ).some((allowedPath) => allowedPath.test(window.location.pathname))

export const hasAccess = (key = '', mode = 'r') => {
    return window._user.permissions[key] && window._user.permissions[key][mode]
}

export const isSuperUser = () => !!(window._user && window._user.is_superuser)
