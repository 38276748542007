import { Row, Col } from '@advanza/grid'
import { Button, Divider, Icon, Ellipsis } from '@advanza/ui'
import TestimonialEntity from 'components/testimonials/TestimonialEntity'
import ExpandableEntity from 'components/FlexTable/ExpandableEntity'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useTestimonialsList, useAddTestimonial } from 'hooks/testimonialsHooks'
import React from 'react'

const definitions = {
    testimonial_id: { name: '#' },
    name: { name: 'name' },
    title: { name: 'company' },
    body: { name: 'quote' },
}

function renderRows(id, entities) {
    const { testimonials } = entities
    const entity = testimonials[id]

    return {
        id: id,
        cols: renderCols(entity),
        expandable: (
            <ExpandableEntity>
                <TestimonialEntity id={id} />
            </ExpandableEntity>
        ),
        expanded: entity._open,
        expandOnClick: true,
        isLoading: entity._saving,
    }
}

function renderCols(entity) {
    return Object.keys(definitions).map((key) => {
        switch (key) {
            case 'testimonial_id':
                return typeof entity[key] === 'string' ? <Icon name="fiber_new" /> : entity[key]

            case 'title':
                return <Ellipsis style={{ maxWidth: 100 }}>{entity[key]}</Ellipsis>

            case 'body':
                return <Ellipsis style={{ maxWidth: 200 }}>{entity[key]}</Ellipsis>

            default:
                return entity[key]
        }
    })
}

const TestimonialsList = ({ filterId = 'all', options }) => {
    const {
        filter = {},
        currentPage,
        selectable = true,
        changeFilter,
        ...rest
    } = useTestimonialsList(filterId, options)
    const addTestimonial = useAddTestimonial(filterId, options)

    const rows = {}
    currentPage.forEach((id) => {
        rows[id] = renderRows(id, rest.entities)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <TableList {...rest} filter={filter} rows={rows} definitions={definitions} />
            <Divider m />
            <Row end="xs">
                <Col x>
                    <Button name="text" onClick={addTestimonial}>
                        <Icon name="add" className="colorCta" style={{ marginTop: '-4px' }} /> Add
                        testimonial
                    </Button>
                </Col>
            </Row>
        </LoadBoxWrapper>
    )
}

export default TestimonialsList
