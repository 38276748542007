import { call } from '@advanza/api'
import { Row, Col } from '@advanza/grid'
import { useSimpleModal } from '@advanza/modal'
import { PreIcon, LoadingDots, Button } from '@advanza/ui'
import { fetchProvider } from 'actions/providers'
import { getDate } from 'date'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

export const MANUAL_PAUSE_REASON_REGULAR = 1
export const MANUAL_PAUSE_REASON_OVERDUE_INVOICE = 2

function _useLeadSettings(providerId) {
    const { leadSettings, providers } = useSelector((state) => state.providers.entities)
    return (providers && leadSettings[providers[providerId].lead_setting]) || {}
}

function useManualPause(providerId) {
    const { manual_pause } = _useLeadSettings(providerId)
    const [isSaving, setIsSaving] = useState(false)
    const [reason, setReason] = useState('')
    const onChangeReason = (e) => setReason(e.target.value)
    const dispatch = useDispatch()
    const pauseDate = manual_pause
    const { openModal, renderModal } = useSimpleModal()
    const modal = renderModal({
        header: 'Reason for manual pause',
        body: 'Please select the reason for the manual pause',
        buttons: [
            {
                text: 'Regular manual pause',
                func: () => onPause(MANUAL_PAUSE_REASON_REGULAR),
            },
            {
                text: 'Manual pause overdue invoice',
                func: () => onPause(MANUAL_PAUSE_REASON_OVERDUE_INVOICE),
            },
        ],
    })
    const onPause = (manualPauseReason = MANUAL_PAUSE_REASON_REGULAR) => {
        setIsSaving(true)
        return call(`office/providers/enable-manual-pause/${providerId}/${manualPauseReason}`, {
            payload: { reason },
        })
            .then(
                () => {
                    dispatch(fetchProvider(providerId, true))
                },
                () => {
                    return Promise.reject()
                }
            )
            .finally(() => {
                setIsSaving(false)
            })
    }

    const onResume = () => {
        setIsSaving(true)
        return call('office/providers/remove-manual-pause/' + providerId, { payload: { reason } })
            .then(
                () => {
                    dispatch(fetchProvider(providerId, true))
                },
                () => {
                    return Promise.reject()
                }
            )
            .finally(() => setIsSaving(false))
    }
    return {
        isSaving,
        onChangeReason,
        onPause,
        pauseDate,
        onResume,
        openModal,
        modal,
    }
}

const ProviderManualPause = ({ providerId }) => {
    const { pauseDate, isSaving, onResume, openModal, modal } = useManualPause(providerId)
    return (
        <Row middle="xs">
            <Col x>
                <Button name="text" onClick={() => (pauseDate ? onResume() : openModal())}>
                    <PreIcon icon={pauseDate ? 'play_arrow' : 'pause'} primColor>
                        {isSaving ? <LoadingDots /> : pauseDate ? 'Resume' : 'Set manual pause'}
                    </PreIcon>
                </Button>
                {modal}
            </Col>
            <Col x>
                {pauseDate && <em>On manual pause since {format(getDate(pauseDate), 'dd-MMM')}</em>}
            </Col>
        </Row>
    )
}

export default ProviderManualPause
