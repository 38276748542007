import { Divider, MaxWidth } from '@advanza/ui'
import LicencesFilter from 'components/licences/LicencesFilter'
import LicencesList from 'components/licences/LicencesList'
import Page from 'components/ui/Page'
import React from 'react'

const LicencesPage = ({}) => {
    return (
        <Page title="Licences">
            <MaxWidth>
                <h1>Licences</h1>
                <Divider l />
                <LicencesFilter
                    filterId="all"
                    defaultOptions={{
                        pageSize: 50,
                    }}
                />
                <LicencesList filterId="all" />
            </MaxWidth>
        </Page>
    )
}

LicencesPage.propTypes = {}

export default LicencesPage
