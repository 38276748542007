import { SelectSimple } from '@advanza/input'
import { Divider, MaxWidth } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import Page from 'components/ui/Page'
import React from 'react'
import { useDispatch } from 'react-redux'
import GeoTargetingList, { TABLE_ALIASES, useGeoTargetingList } from './GeoTargetingList'

const GeoTargetingPage = ({ filterId = 'all' }) => {
    const dispatch = useDispatch()
    const { changeFilter, filter = {} } = useGeoTargetingList(filterId)

    return (
        <Page title="Geo targeting">
            <MaxWidth>
                <Divider m />
                <AutoRow>
                    <h1>Geo targeting</h1>
                </AutoRow>
                <Divider m />
                <SelectSimple
                    options={TABLE_ALIASES}
                    value={filter.tableAlias}
                    onChange={(e) => {
                        dispatch({ type: 'GEOTARGETING_RESET' })
                        changeFilter({ tableAlias: e.target.value })
                    }}
                    placeholder="Level"
                    alwaysControlValue
                />
                <Divider m />
                <GeoTargetingList filterId={filterId} />
                <Divider xxxl />
            </MaxWidth>
        </Page>
    )
}

export default GeoTargetingPage
