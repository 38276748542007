import Cookies from "js-cookie"
import queryString from "query-string"

const fetch = require('isomorphic-fetch')

export class Api {

    constructor({baseEndpoint, isLocal, localBaseEndpoint}) {
        this.baseEndpoint = baseEndpoint
        this.isLocal = isLocal
        this.localBaseEndpoint = localBaseEndpoint
    }

    call(actionUrl, options) {

        const requestOptions = options || {}
        const isPOST = (requestOptions.method && requestOptions.method.toUpperCase() === 'POST') || requestOptions.payload || requestOptions.json

        requestOptions.credentials = 'include'
        if (requestOptions.payload) {
            const formData = new FormData()
            Object.keys(requestOptions.payload).forEach(key => {
                const data = requestOptions.payload[key]
                if (data instanceof Array) {
                    data.forEach(item => {
                        formData.append(`${key}[]`, item)
                    })

                } else if(data) {
                    formData.append(key, data )
                }
            })
            requestOptions.body = formData
            requestOptions.method = 'POST'
        }
        if (requestOptions.json) {
            requestOptions.body = JSON.stringify(requestOptions.json)
            requestOptions.method = 'POST'
            requestOptions.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        if (isPOST) {
            const {body: returnBody, headers: returnHeaders} = this._addCsrfToken(requestOptions.body, requestOptions.headers)
            requestOptions.body = returnBody
            requestOptions.headers = returnHeaders
        }

        if (requestOptions.query) {
            actionUrl += `?${queryString.stringify(options.query,{arrayFormat: 'bracket'})}`
        }

        const apiHost = this.getAddress()

        return fetch(apiHost + actionUrl, requestOptions)
            .then(response => response.json(), (error) => {
                return Promise.reject(error)
            })
            .then((json) => {
                if (json && json.status === 'success') {
                    return Promise.resolve(json)
                } else {
                    return Promise.reject(json)
                }
            })
    }

    getAddress() {
        if (this.isLocal) {
            return this.localBaseEndpoint
        }
        else {
            return this.baseEndpoint
        }
    }

    _tryJson(string) {
        try {
            return JSON.parse(string)
        } catch (e) {
            return false
        }
    }

    _addCsrfToken(requestBody, requestHeaders) {
        const csrfToken = Cookies.get('csrfToken')
        const bodyIsEmpty = !requestBody
        let body = requestBody || ''
        const headers = requestHeaders || {}
        const isJson = this._tryJson(body) !== false

        if (body instanceof FormData) {
            body.append('_csrfToken', csrfToken)
        } else if (isJson) {
            headers['X-CSRF-Token'] = csrfToken
        } else {
            const parsed = queryString.parse(body)
            body = queryString.stringify({...parsed, _csrfToken: csrfToken})
            if (bodyIsEmpty && !headers['Content-Type']) {
                headers['Content-Type'] = 'application/x-www-form-urlencoded'
            }
        }

        return {body, headers}
    }

}
const ApiInstance = new Api({
    baseEndpoint     : '/api/',
    isLocal          : process.env.NODE_ENV === `development`,
    localBaseEndpoint: window.location.protocol + '//' + window.location.hostname + '/api/'
})

export const call = (url, options) => {
    return ApiInstance.call(url, options)
}