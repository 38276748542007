import React from 'react'
import { connect } from 'react-redux'
import { changeRequestFilter } from '../actions/requests'

function mapStateToProps(state, props) {
    const { filters, isFetching, result, counts } = state.requests
    const filter = filters[props.filterId]
    const filterResult = filter && result[filter.searchKey]
    const count = filter && counts[filter.searchKey]
    const countOnPage = filter && filterResult[filter.page || 0].length
    return {
        countries: state.countries.countries,
        filter,
        count,
        countOnPage,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeRequestFilter(id, options)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
