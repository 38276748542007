import React from 'react'
import { connect } from 'react-redux'
import { fetchPermissions } from '../actions/adminUsers'

function mapStateToProps(state) {
    const { permissions } = state.adminUsers
    return {
        permissions,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: () => dispatch(fetchPermissions()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
