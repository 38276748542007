import React from 'react'

class ListKeyboardNav extends React.Component {
    constructor(props) {
        super(props)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.getActiveIndex = this.getActiveIndex.bind(this)
    }

    keyMap = {
        Enter: 13,
        ArrowDown: 40,
        ArrowUp: 38,
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown)
        this.initialFocusedElement = document.activeElement
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown)
    }

    onKeyDown(e) {
        if (e.keyCode === this.keyMap.ArrowDown) {
            this.down()
        } else if (e.keyCode === this.keyMap.ArrowUp) {
            this.up()
        } else if (e.keyCode === this.keyMap.Enter) {
            if (document.activeElement.tagName === 'INPUT') {
                this.ref.firstElementChild && this.ref.firstElementChild.click()
            } else {
                document.activeElement.click()
            }
        }
    }

    down() {
        const index = this.getActiveIndex()

        const nrChildren = this.ref.childElementCount

        if (index === -1) {
            this.ref.firstElementChild && this.ref.firstElementChild.focus()
        } else if (index >= 0 && index + 1 < nrChildren) {
            this.ref.childNodes[index + 1].focus()
        } else {
            this.initialFocusedElement.focus()
        }
    }

    up() {
        const index = this.getActiveIndex()
        if (index > 0) {
            this.ref.childNodes[index - 1].focus()
        } else {
            this.initialFocusedElement.focus()
        }
    }

    getActiveIndex() {
        const nrChildren = this.ref.childElementCount
        for (let i = 0; i < nrChildren; i++) {
            if (this.ref.childNodes[i] === document.activeElement) {
                return i
            }
        }

        return -1
    }

    render() {
        const { children, ...rest } = this.props

        return (
            <div ref={(ref) => (this.ref = ref)} {...rest}>
                {children.map((child) => {
                    return child
                })}
            </div>
        )
    }
}

ListKeyboardNav.propTypes = {}

export default ListKeyboardNav
