import { Icon } from '@advanza/ui'
import Legend from 'components/misc/Legend'
import React from 'react'
import style from './LogChange.module.css'

const LogChanges = ({ data }) => {
    return (
        <div className={style.root}>
            {Object.keys(data).map((field) => {
                const fieldData = data[field]
                return (
                    <div key={field} className={style.item}>
                        <Legend title={field}>{field}:</Legend>
                        <span className={style.old}>
                            {fieldData.old === '0' ? 'null' : fieldData.old || <i>-</i>}
                        </span>
                        <span className={style.arrow}>
                            <Icon name="east" fontSize={18} />
                        </span>
                        <span className={style.new}>{fieldData.new || '0'}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default LogChanges
