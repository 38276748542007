import { LinearLoader } from '@advanza/ui'
import React from 'react'
import { withRouter } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import style from './animatedPages.module.css'

class AnimatedPages extends React.Component {
    constructor(props) {
        super(props)
        this.changePage = this.changePage.bind(this)
        this.enableIsAnimating = this.enableIsAnimating.bind(this)
        this.disableIsAnimating = this.disableIsAnimating.bind(this)
        const shouldInitAnimation = props.initAnimation && props.page === 1
        this.state = {
            exitTimeout: shouldInitAnimation,
            currentPage: shouldInitAnimation ? false : props.page,
            previousPage: shouldInitAnimation ? 1 : props.page,
            isAnimating: false,
            minHeight: (this.pageEl && this.pageEl.clientHeight) || 1,
        }
        this.initialLocationSearch = window.location.search
    }

    componentDidMount() {
        const { initAnimation, page } = this.props
        if (initAnimation && page === 1) {
            setTimeout(() => {
                this.changePage(this.props.page)
            }, 100)
        }
        this.setState({
            minHeight: (this.pageEl && this.pageEl.clientHeight) || 1,
        })
    }

    componentDidUpdate(prevProps) {
        const { page } = this.props
        if (prevProps.page !== page) {
            if (this.state.exitTimeout) {
                clearInterval(this.timeout)
            }
            this.changePage(page, prevProps.page)
        }
    }

    changePage(page, prevPage) {
        const {
            options = {},
            pages,
            timeout = 150,
            history,
            scrollToTop,
            afterChange,
            location,
        } = this.props
        if (scrollToTop) {
            document.body.scrollTop = 0
            window.scrollTo(0, 0)
        }
        const isPrevious = pages
            ? prevPage
                ? Object.keys(pages).indexOf(page) < Object.keys(pages).indexOf(prevPage)
                : false
            : prevPage
            ? prevPage > page
            : true
        this.setState(
            {
                exitTimeout: true,
                minHeight: (this.pageEl && this.pageEl.clientHeight) || this.state.minHeight || 1,
                previousPage: prevPage || 1,
                isPrevious: isPrevious,
            },
            () => {
                const { url } = options[page - 1] || {}
                if (url && url !== location.pathname) {
                    history.push(url + this.initialLocationSearch)
                }
                afterChange && afterChange(page)
                this.timeout = setTimeout(() => {
                    this.setState({ exitTimeout: false, currentPage: this.props.page })
                }, timeout - 10)
            }
        )
    }

    enableIsAnimating() {
        this.setState({ isAnimating: true })
        const { setIsAnimating } = this.props
        setIsAnimating && setIsAnimating(true)
    }

    disableIsAnimating() {
        this.setState({
            isAnimating: false,
            minHeight: (this.pageEl && this.pageEl.clientHeight) || this.state.minHeight || 1,
        })
        const { setIsAnimating } = this.props
        setIsAnimating && setIsAnimating(false)
    }

    render() {
        const { children, isLoading, timeout = 150, smooth: alt, pages, components } = this.props
        const { exitTimeout, isPrevious, previousPage, currentPage, isAnimating } = this.state
        const classNames = {
            enter: isPrevious
                ? alt
                    ? style.enterPrevAlt
                    : style.enterPrev
                : alt
                ? style.enterAlt
                : style.enter,
            enterActive: alt ? style.enterActiveAlt : style.enterActive,
            exit: alt ? style.exitAlt : style.exit,
            enterDone: alt ? '' : style.enterDone,
            exitActive: isPrevious
                ? alt
                    ? style.exitActivePrevAlt
                    : style.exitActivePrev
                : alt
                ? style.exitActiveAlt
                : style.exitActive,
        }
        const currentPageIndex = pages
            ? isLoading
                ? previousPage
                : currentPage
            : (isLoading ? previousPage : currentPage) - 1
        return (
            <div className={[style.root, isAnimating ? style.busy : ''].join(' ')}>
                {isLoading && (
                    <div className={style.loading}>
                        <LinearLoader fixed />
                    </div>
                )}
                <div
                    className={style.wrapper}
                    style={{ minHeight: alt ? null : this.state.minHeight }}>
                    <CSSTransition
                        unmountOnExit
                        onEntering={this.enableIsAnimating}
                        onExiting={this.enableIsAnimating}
                        onExited={this.disableIsAnimating}
                        onEntered={this.disableIsAnimating}
                        classNames={classNames}
                        timeout={timeout}
                        in={!exitTimeout}>
                        <div ref={(el) => (this.pageEl = el)}>
                            {pages
                                ? pages[currentPageIndex] && pages[currentPageIndex].component
                                : components
                                ? components[currentPageIndex]
                                : children[currentPageIndex]}
                        </div>
                    </CSSTransition>
                </div>
            </div>
        )
    }
}

AnimatedPages.propTypes = {}

export default withRouter(AnimatedPages)
