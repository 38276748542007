import React, {Component} from 'react'

export default class Switch extends Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        const {onChange} = this.props
        const {name, value} = e.target

        const adjustedValue = value === 'true' ? true : (value === 'false') ? false : value

        onChange({...e, target: {name, value: adjustedValue}})
    }


    render() {
        const {checked, children,onChange, ...rest} = this.props
        return <label>
            {children}
            <div className={'switch ' + (checked ? 'on' : 'off')}>
                <div className="switch-button"/>
                <input
                    type="checkbox"
                    {...rest}
                    onChange={this.onChange}
                />
            </div>
        </label>
    }
}