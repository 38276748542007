import { SelectCheckbox } from '@advanza/input'
import { Icon } from '@advanza/ui'
import AdminUser from 'components/adminUsers/AdminUser'
import Card from 'components/Card'
import LogChanges from 'components/logs/LogChanges'
import AutoRow from 'components/misc/AutoRow'
import TableList from 'components/TableList'
import FormattedTime from 'components/ui/FormattedTime'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useSystemLogsList, useSystemLogTypes } from 'hooks/logsHook'
import React from 'react'
import RequestLogCandidateMatchItem from 'components/candidateMatches/RequestLogCandidateMatchItem'

const definitions = {
    user_id: { name: 'user' },
    created: { name: 'created' },
    log_type: { name: 'type' },
    message: { name: 'message' },
}

function renderRows(id, entities) {
    const { system_logs } = entities
    const entity = system_logs[id]
    const isCandidateMatchLog = entity.message === 'candidate_matches_sent'
    return {
        id: id,
        cols: renderCols(entity),
        expanded:isCandidateMatchLog,
        expandable: entity.message_json && Object.keys(entity.message_json).length > 0 && (
            <Card expandableStyle>
                {entity.relation === 'services' ? (
                    <LogChanges data={entity.message_json} />
                ) : isCandidateMatchLog ? <RequestLogCandidateMatchItem data={entity.message_json}/> : (
                    <pre>{JSON.stringify(entity.message_json, null, 2)}</pre>
                )}
            </Card>
        ),
    }
}

function renderCols(entity) {
    return Object.keys(definitions).map((key) => {
        switch (key) {
            case 'created':
                return <FormattedTime date={entity[key]} />
            case 'user_id':
                return entity[key] ? <AdminUser avatarOnly userId={entity[key]} /> : '-'
            case 'message':
                return <span>{entity[key]}</span>
            default:
                return entity[key]
        }
    })
}

const LogsList = ({ filterId = 'all', options }) => {
    const { filter = {}, currentPage, changeFilter, ...rest } = useSystemLogsList(filterId, options)
    const types = useSystemLogTypes(options.relation)
    const rows = {}
    currentPage.forEach((id) => {
        rows[id] = renderRows(id, rest.entities)
    })

    if (currentPage.length === 0) {
        return null
    }
    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <AutoRow>
                <SelectCheckbox
                    value={filter.logTypes}
                    placeholder="Log types"
                    onChangeValue={(logTypes) => changeFilter({ logTypes })}
                    options={
                        (types &&
                            types.map((type) => {
                                return { name: type, value: type }
                            })) ||
                        []
                    }
                />
                <button>
                    <Icon
                        name={rest.isFetching ? 'hourglass_full' : 'refresh'}
                        onClick={() => changeFilter({ didInvalidate: true, page: 0 })}
                    />
                </button>
            </AutoRow>
            <TableList {...rest} filter={filter} rows={rows} definitions={definitions} hideExport />
        </LoadBoxWrapper>
    )
}

export default LogsList
