import { Alert, FieldInput, Loading, Msg, Section, WithIcon } from '@advanza/advanza_generic'
import { requestNewEmail } from '@advanza/advanza_generic/src/actions/auth'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import isEmail from 'validator/es/lib/isEmail'

class RequestChangeEmail extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.afterSubmitSuccess = this.afterSubmitSuccess.bind(this)
        this.afterSubmitError = this.afterSubmitError.bind(this)
        this.submit = this.submit.bind(this)
        this.validate = this.validate.bind(this)
        this.form = this.form.bind(this)

        this.state = {
            currentEmail: props.currentEmail || (window._user && window._user.email) || '',
            email: { value: '', error: null },
            password: { value: '', error: null },
            loading: false,
            emailSent: false,
            popup: false,
        }
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: { ...this.state[name], value, error: null } })
    }

    validate() {
        const { email, password } = this.state

        const emailIsValid = isEmail(email.value)
        const passwordIsValid = password.value.length > 5

        if (!emailIsValid) {
            this.setState({ email: { ...this.state.email, error: true } })
        }
        if (!passwordIsValid) {
            this.setState({ password: { ...this.state.password, error: true } })
        }

        return emailIsValid && passwordIsValid
    }

    submit() {
        const { email, password, currentEmail } = this.state
        if (!this.validate()) {
            return
        }
        this.setState({ loading: true })
        const values = {
            newEmail: email.value,
            email: currentEmail,
            password: password.value,
        }
        requestNewEmail(values).then(this.afterSubmitSuccess, this.afterSubmitError)
    }

    afterSubmitSuccess() {
        this.setState({ loading: false, emailSent: true })
    }

    afterSubmitError(response) {
        const dismiss = () => this.setState({ popup: false })
        switch (response.error) {
            case 'password invalid':
                this.setState({
                    popup: (
                        <Alert
                            title={<Msg id="login.error.incorrect_password" />}
                            close={dismiss}
                            onOk={dismiss}
                        />
                    ),
                    password: { ...this.state.password, error: true },
                    loading: false,
                })
                break
            case 'email exists':
                this.setState({
                    popup: (
                        <Alert
                            title={<Msg id="login.change_email.email_exists" />}
                            close={dismiss}
                            onOk={dismiss}
                        />
                    ),
                    email: { ...this.state.email, error: true },
                    loading: false,
                })
                break
            default:
                this.setState({
                    popup: (
                        <Alert title={<Msg id="error.fallback" />} close={dismiss} onOk={dismiss} />
                    ),
                    loading: false,
                })
        }
    }

    submittedSuccessMsg(email) {
        return (
            <div>
                <Section y="mega" x>
                    <WithIcon bg="green" round icon="check">
                        <h3>
                            <Msg id="login.email_reset.success.1" values={{ newEmail: email }} />
                        </h3>
                        <Section y="min">
                            <Msg id="login.email_reset.success.2" />
                        </Section>
                    </WithIcon>
                </Section>
            </div>
        )
    }

    form() {
        return (
            <div>
                <Section n="mega" s>
                    <Section s>
                        <h1>
                            <Msg id="more.link.change_email" />
                        </h1>
                    </Section>
                    <FormattedMessage id="placeholder.current_email" />:
                    <div>
                        <b>{this.state.currentEmail}</b>
                    </div>
                </Section>
                <Section>
                    <FieldInput
                        msgid="placeholder.new_email"
                        onChange={this.handleChange}
                        icon="email"
                        newGStyle
                        state={this.state.email}
                        name="email"
                        autoComplete="off"
                    />
                </Section>
                <Section n="min">
                    <FieldInput
                        msgid="placeholder.password"
                        type="password"
                        icon="lock"
                        onChange={this.handleChange}
                        state={this.state.password}
                        newGStyle
                        name="password"
                        autoComplete="off"
                    />
                </Section>
                <Section n="plus" s="mega">
                    <button className="btn col-12" onClick={this.submit}>
                        <Msg id="form.button.save" />
                    </button>
                </Section>
            </div>
        )
    }

    render() {
        const { currentEmail, loading, popup, emailSent } = this.state
        return (
            <div>
                {emailSent ? this.submittedSuccessMsg(currentEmail) : this.form()}

                {loading && <Loading />}
                {popup}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
    }
}

export default connect(mapStateToProps)(RequestChangeEmail)
