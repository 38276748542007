import React from 'react'
import { connect } from 'react-redux'
import { fetchNotifications } from '../actions/users'

function mapStateToProps(state, props) {
    const { result: notificationIds = [], entities = {} } = state.notifications
    const { notificationSettings } = state[props.useStore].entities
    return {
        notificationIds,
        notifications: entities.notifications,
        notificationSettings,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNotifications: () => dispatch(fetchNotifications()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
