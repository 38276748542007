import { scrollToY } from '@advanza/func'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Icon } from '../src/Icon'

class Select extends React.Component {
    rootClass = 'select-input'
    transitionDuration = 140

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.onChange = this.onChange.bind(this)
        this.getKeys = this.getKeys.bind(this)
        this.clearFilters = this.clearFilters.bind(this)
        this.selectedItems = this.selectedItems.bind(this)
        this.selectResult = this.selectResult.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
        this.getValue = this.getValue.bind(this)
        this.bodyClick = this.bodyClick.bind(this)
        this.scrollValueIntoView = this.scrollValueIntoView.bind(this)
        this.getVisibleOptions = this.getVisibleOptions.bind(this)
        this.getIndexSelected = this.getIndexSelected.bind(this)
        this.onChangeNative = this.onChangeNative.bind(this)
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.native = this.native.bind(this)
        this.onChangeQuery = this.onChangeQuery.bind(this)

        this.state = {
            open: props.open || props.maximized || false,
            inTrans: false,
            searchQuery: '',
            selectedByKey: false,
            focus: false,
        }
    }

    bodyClick(e) {
        const { allowClickOutside } = this.props
        if (allowClickOutside) {
            return
        }
        const containerNode = ReactDOM.findDOMNode(this.wrapperEl)
        if (
            containerNode &&
            e.target !== containerNode &&
            !containerNode.contains(e.target) &&
            this.state.open
        ) {
            this.toggle()
        }
    }

    onChangeQuery(e) {
        this.setState({ searchQuery: e.target.value })
    }

    // The selected option is either selectedByKey or the checked option from value.
    getIndexSelected() {
        const options = this.getVisibleOptions()
        const { selectedByKey } = this.state
        const { valueKey } = this.getKeys()
        const value = this.getValue()

        const shouldCheckIfChecked = !selectedByKey && value.length > 0
        for (const i in options) {
            const indexInt = parseInt(i, 10)
            if (selectedByKey && selectedByKey.index === indexInt) {
                return indexInt
            }
            const checked =
                shouldCheckIfChecked &&
                value.filter((checked) => checked[valueKey] === options[i][valueKey]).length > 0
            if (checked) {
                return indexInt
            }
        }
        return false
    }

    onKeyPress(e) {
        const { key, target } = e
        const { open, selectedByKey } = this.state
        const containerNode = ReactDOM.findDOMNode(this.wrapperEl)
        const isOutsideElement =
            containerNode && target !== containerNode && !containerNode.contains(target)
        if (!open || isOutsideElement) {
            return
        }
        switch (key) {
            case 'Enter':
                selectedByKey && this.onChange(selectedByKey.option)
                break
            case 'Escape':
                this.close()
                break
            case 'ArrowDown':
            case 'Down':
                this.selectResult(1)
                break
            case 'ArrowUp':
            case 'Up':
                this.selectResult(-1)
                break
            case 'Backspace':
                this.setState({
                    searchQuery: this.state.searchQuery.substring(
                        0,
                        this.state.searchQuery.length - 1
                    ),
                })
                this.scrollValueIntoView()
                break
            case 'Shift':
                break
            default:
                break
        }
    }

    selectResult(indexModify) {
        const options = this.getVisibleOptions()
        const selectedIndex = this.getIndexSelected()
        const newIndex = selectedIndex === false ? 0 : selectedIndex + indexModify
        let selected = options[newIndex]
        if (!selected) {
            return
        }

        this.setState({ selectedByKey: { index: newIndex, option: selected } })
        this.scrollValueIntoView()
    }

    componentDidMount() {
        document.addEventListener('click', this.bodyClick)
        document.addEventListener('keydown', this.onKeyPress)
        document.addEventListener('touchend', this.bodyClick)
    }

    scrollValueIntoView() {
        const { scrollIntoViewMobile } = this.props
        setTimeout(() => {
            const checkedEl = this.selectedEl || this.checkedEl
            if (window.isMobile && scrollIntoViewMobile) {
                const { top } = this.containerEl.getBoundingClientRect()
                const docTop = window.pageYOffset
                scrollToY(top + docTop - 120)
            }
            if (!this.containerEl || !checkedEl) {
                return
            }
            const add = this.containerEl.clientHeight / 2 - checkedEl.clientHeight / 2
            this.containerEl.scrollTop = checkedEl.offsetTop - add
        }, 50)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.bodyClick)
        document.removeEventListener('keydown', this.onKeyPress)
        document.removeEventListener('touchend', this.bodyClick)
    }

    open() {
        if (this.state.inTrans) {
            return
        }
        this.setState({ open: true })
        this.scrollValueIntoView()

        this.inputQueryEl && this.inputQueryEl.focus()
    }

    close() {
        const { maximized, visibleSearch } = this.props
        if (!this.state.open || this.state.inTrans || maximized) {
            return
        }

        this.setState({ inTrans: true })
        setTimeout(() => {
            this.setState({
                open: false,
                inTrans: false,
                selectedByKey: false,
                ...(visibleSearch ? {} : { searchQuery: '' }),
            })
            document.activeElement.blur()
        }, this.transitionDuration)
    }

    toggle(e) {
        if (this.state.open) {
            this.close()
        } else {
            this.open()
        }
    }

    getKeys() {
        const { optionKeys } = this.props
        const defaultMap = {
            nameKey: 'name',
            valueKey: 'value',
            msgKey: 'title',
            queryKey: 'value',
        }
        if (!optionKeys) {
            return defaultMap
        } else {
            return { ...defaultMap, ...optionKeys }
        }
    }

    onChange(option) {
        const {
            onValueChange,
            multiple,
            closeOnChange,
            onChange,
            name,
            unShiftValue,
            onBeforeChange,
        } = this.props
        const value = this.getValue()
        const { valueKey } = this.getKeys()

        onBeforeChange && onBeforeChange(value)

        if (onChange) {
            if (!multiple || closeOnChange) {
                this.close()
            }

            return onChange({
                target: {
                    ...option,
                    name,
                    value: option[valueKey],
                },
            })
        }
        if (onValueChange) {
            if (multiple || closeOnChange) {
                const newValue = value.slice(0)
                let alreadyChecked = false
                value.map((item, i) => {
                    if (item[valueKey].toString() === option[valueKey].toString()) {
                        alreadyChecked = true
                        newValue.splice(i, 1)
                    }
                })
                if (!alreadyChecked) {
                    if (unShiftValue) {
                        newValue.unshift(option)
                    } else {
                        newValue.push(option)
                    }
                }
                onValueChange(newValue)
            } else {
                onValueChange([option])
            }
        }

        if (!multiple || closeOnChange) {
            this.close()
        }
    }

    clearFilters() {
        const { onValueChange, onChange, name } = this.props
        if (onChange) {
            return onChange({ target: { name, value: null } })
        } else if (onValueChange) {
            onValueChange([])
        }
    }

    getValue() {
        const { value, options } = this.props
        const { valueKey } = this.getKeys()

        const arrValue = value instanceof Array ? value : [value]
        if (!arrValue[0] && arrValue[0] !== 0) {
            return []
        }

        const shouldHydrateValues = !arrValue[0][valueKey]
        if (shouldHydrateValues) {
            return arrValue
                .map((val) => options.filter((option) => option[valueKey] === val)[0])
                .filter((val) => val || val === 0)
        } else {
            return arrValue
        }
    }

    getVisibleOptions() {
        const { options, useSearch } = this.props
        const optionsClone = options.slice(0)
        const value = this.getValue()
        const { valueKey, queryKey, msgKey } = this.getKeys()
        const { searchQuery, selectedByKey } = this.state
        const shouldSearch = searchQuery.length > 0 && useSearch
        const visibleOptions = []
        for (const i in optionsClone) {
            const option = { ...optionsClone[i] }
            option.checked =
                value.filter((checked) => checked[valueKey] === option[valueKey]).length > 0
            option.selectedByKey =
                selectedByKey && selectedByKey.option[valueKey] === option[valueKey]
            if (shouldSearch) {
                const queryField =
                    option.queryField || option[queryKey] || option[msgKey] || option[valueKey]
                const containsQuery =
                    queryField.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1

                if (!containsQuery) {
                    continue
                }
            }
            visibleOptions.push(option)
        }
        return visibleOptions
    }

    renderOptions() {
        const { name, multiple } = this.props
        const options = this.getVisibleOptions()
        const { valueKey, msgKey } = this.getKeys()
        const className = multiple ? 'checkbox' : 'radio'
        const type = className
        return (
            <div className={className}>
                {options.map((option, i) => {
                    const { msgid } = option
                    const message = msgid ? <FormattedMessage id={msgid} /> : option[msgKey]
                    return (
                        <label
                            key={i}
                            ref={
                                option.selectedByKey
                                    ? (el) => (this.selectedEl = el)
                                    : option.checked
                                    ? (el) => (this.checkedEl = el)
                                    : null
                            }
                            className={`row nm v-center ${option.checked ? 'checked' : ''} ${
                                option.selectedByKey ? 'hover' : ''
                            }`}>
                            <div className="option-icon">
                                <div />
                            </div>
                            <input
                                type={type}
                                name={name}
                                title={message}
                                onChange={(e) => this.onChange(option)}
                                checked={option.checked}
                                value={option[valueKey]}
                                disabled={option.disabled}
                            />
                            <div> {message}</div>
                        </label>
                    )
                })}
            </div>
        )
    }

    selectedItems() {
        const { multiple, placeholder, intl, renderItem, wrapValues, maximized, visibleSearch } =
            this.props
        const value = this.getValue()

        const className = ['values', multiple ? 'multiple' : 'single'].join(' ')

        const { msgKey } = this.getKeys()
        const nrValues = value.length
        const extraStyle = visibleSearch ? { padding: 5, margin: '0 10px 0 0' } : {}

        if (nrValues > 0 && !maximized) {
            return (
                <div
                    className={className}
                    style={{ ...extraStyle, whiteSpace: wrapValues && 'normal' }}>
                    {value.map((option, i) => {
                        const isLast = nrValues === i + 1
                        const message = option.msgid
                            ? intl.formatMessage({ id: option.msgid })
                            : option[msgKey]
                        if (typeof message === 'object') {
                            return message
                        }
                        if (renderItem) {
                            return renderItem(option, i)
                        } else {
                            return message + (!isLast ? ', ' : '')
                        }
                    })}
                </div>
            )
        } else if (placeholder) {
            return (
                <div className="values" style={extraStyle}>
                    {typeof placeholder === 'string'
                        ? intl.formatMessage({ id: placeholder })
                        : placeholder}
                </div>
            )
        } else {
            return null
        }
    }

    onFocus = () => this.setState({ focus: true })
    onBlur = () => this.setState({ focus: false })

    onKeyDown = ({ key }) => {
        if (this.state.focus && key === 'Enter') {
            this.open()
        }
    }

    onChangeNative(e) {
        const { value } = e.target
        const { valueKey } = this.getKeys()
        const options = this.getVisibleOptions()

        const option = options.filter(
            (option) => option[valueKey].toString() === value.toString()
        )[0]
        this.onChange(option)
    }

    native() {
        const options = this.getVisibleOptions()
        const { open, inTrans } = this.state
        const { valueKey, msgKey } = this.getKeys()
        const { msgid, msg, error, intl, placeholder } = this.props
        const title = placeholder
            ? intl.formatMessage({ id: placeholder })
            : msgid
            ? intl.formatMessage({ id: msgid })
            : msg
            ? msg
            : ''
        return (
            <div className="select-input native">
                <div
                    className={`trigger-body  new-g-style  material-input ${
                        error ? 'has-error' : ''
                    }`}>
                    <select onChange={this.onChangeNative} className="select-input material-label ">
                        <option value="" disabled selected hidden>
                            {title}
                        </option>
                        {options.map((option) => {
                            const message = option.msgid
                                ? intl.formatMessage({ id: option.msgid })
                                : option[msgKey]
                            return (
                                <option
                                    key={option[valueKey]}
                                    selected={option.checked}
                                    value={option[valueKey]}
                                    disabled={option.disabled}>
                                    {message}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <i className={'arrow ' + (open || inTrans ? 'down' : '')} />
            </div>
        )
    }

    render() {
        const { open, inTrans, focus } = this.state
        const {
            msgid,
            msg,
            icon,
            error,
            hideSelectedItems,
            multiple,
            className,
            staticBody,
            maximized,
            useSearch,
            visibleSearch,
            useNativeOnMobile,
        } = this.props
        const value = this.getValue()
        const title = msgid ? <FormattedMessage id={msgid} /> : msg ? msg : ''
        const selected = !hideSelectedItems && this.selectedItems()
        const classString = [
            this.rootClass,
            staticBody ? 'static-body' : '',
            'new-g-style',
            inTrans ? 'in-trans' : '',
            open ? 'open' : '',
            value.length > 0 ? 'valid' : '',
            error ? 'has-error' : '',
            className || '',
            maximized ? 'maximized' : '',
            focus ? 'focus' : '',
        ].join(' ')
        if (window.isMobile && !multiple && useNativeOnMobile) {
            return this.native()
        }
        return (
            <div
                tabIndex="0"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                ref={(el) => (this.wrapperEl = el)}
                className={classString}>
                <div
                    className={`trigger-body material-input ${error ? 'has-error' : ''}`}
                    onClick={this.toggle}>
                    {!maximized && (
                        <div
                            className={
                                'title material-label row nm v-center' +
                                (visibleSearch ? '' : ' no-wrap')
                            }
                            style={
                                visibleSearch && {
                                    height: 'auto',
                                    paddingTop: 5,
                                    paddingLeft: 5,
                                }
                            }>
                            {icon && (
                                <div className="select-icon">
                                    <Icon>{icon}</Icon>
                                </div>
                            )}
                            {title && <span className="col-auto prefix">{title}</span>}
                            {useSearch && (
                                <input
                                    type="text"
                                    value={this.state.searchQuery}
                                    ref={(el) => {
                                        this.inputQueryEl = el
                                    }}
                                    style={
                                        visibleSearch && {
                                            flexGrow: 1,
                                            flexBasis: 150,
                                            opacity: 1,
                                            border: '1px solid #eee',
                                            padding: 5,
                                            marginRight: 5,
                                            borderRadius: 9,
                                        }
                                    }
                                    placeholder="🔍"
                                    onChange={this.onChangeQuery}
                                />
                            )}
                            {selected}
                            <i
                                className={'arrow ' + (open || inTrans ? 'down' : '')}
                                style={visibleSearch && { marginBottom: 5, marginTop: 2 }}
                            />
                        </div>
                    )}
                    {error && <div className="error">{error}</div>}
                </div>
                <div
                    className="body"
                    style={{ display: open ? null : 'none' }}
                    ref={(el) => (this.containerEl = el)}>
                    <div className="inner">{this.renderOptions()}</div>
                </div>
            </div>
        )
    }
}

Select.propTypes = {
    allowClickOutside: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.any,
    hideSelectedItems: PropTypes.bool,

    // will render only the options and can't be closed
    maximized: PropTypes.bool,
    icon: PropTypes.any,
    msg: PropTypes.any,
    msgid: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.any,
    onChange: PropTypes.func,
    onValueChange: PropTypes.func,
    open: PropTypes.any,

    // use a native <select> on mobile. Best for accessibility
    useNativeOnMobile: PropTypes.bool,

    // array of options: {value, name, title, *queryField}
    options: PropTypes.any,
    // optionally assign the value, name and msg from your options objects.
    // (this way you can provide the raw data as options)
    optionKeys: PropTypes.shape({
        nameKey: PropTypes.string.isRequired,
        valueKey: PropTypes.string.isRequired,
        msgKey: PropTypes.string.isRequired,
    }),
    placeholder: PropTypes.any,

    // scroll the options into the viewport after opening the select.
    scrollIntoViewMobile: PropTypes.bool,

    // Change the body css position to static, default is absolute,
    staticBody: PropTypes.bool,

    // if true the options are filterable (you can start typing a query when the select is open)
    useSearch: PropTypes.bool,
    value: PropTypes.any.isRequired,
}

export default injectIntl(Select)
