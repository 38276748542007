import { Col, Row } from '@advanza/grid'
import { BreakText, Divider, Icon } from '@advanza/ui'
import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import style from './errorAlert.module.css'

const ErrorAlert = ({ errors, lang = 'EN' }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(true)
    }, [errors])

    return (
        <Fragment>
            {show && (
                <div className={style.alert}>
                    {Object.keys(errors).map((name, index) => {
                        return (
                            <Fragment key={index}>
                                {index > 0 && <Divider className={style.bgWhite} xs />}
                                <div className={style.alertBlock}>
                                    <Row between="xs" middle="xs">
                                        <Col xs>
                                            <Icon white fontSize={18} name="error" />
                                            {` ${
                                                lang === 'NL' ? 'Controleer je' : 'Check your'
                                            } ${name.toLowerCase()}!`}
                                        </Col>
                                        <Col x>
                                            <button
                                                onClick={() => {
                                                    setShow(false)
                                                }}>
                                                <Icon white fontSize={18} name="close" />
                                            </button>
                                        </Col>
                                    </Row>
                                    <ul>
                                        {errors[name] &&
                                            errors[name].map((errorMsg = '', index) => {
                                                return (
                                                    <li key={index}>
                                                        <BreakText text={errorMsg} />
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            )}
        </Fragment>
    )
}

ErrorAlert.defaultProps = {
    errors: {},
}

ErrorAlert.propTypes = {
    errors: PropTypes.object,
}

export default ErrorAlert
