import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from "react-dom"
import {Link} from "react-router-dom"
import {Icon} from "./Icon"
import WithIcon from "./WithIcon"

class jumpList extends React.Component {

    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.bodyClick = this.bodyClick.bind(this)
        this.id = Math.random().toString(36).substr(2, 5)
        this.state = {
            open   : false,
            inTrans: false
        }
    }

    bodyClick(e) {
        const containerNode = ReactDOM.findDOMNode(this.el)
        if (containerNode && e.target !== containerNode && !containerNode.contains(e.target) && this.state.open) {
            this.toggle()
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.bodyClick)
        window.addEventListener('touchend', this.bodyClick)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.bodyClick)
        window.removeEventListener('touchend', this.bodyClick)
    }


    toggle(e) {
        e && e.preventDefault()
        if (this.inTrans) {
            return
        }
        this.setState({open: this.state.open !== true, inTrans: true})
        setTimeout(() => this.setState({inTrans: false}), 200)
    }


    render() {
        const {open, inTrans} = this.state
        const {items, icon, button, className} = this.props
        const isOpened = open && !inTrans
        const classString = [
            'jump-list',
            open ? 'open' : 'close',
            inTrans ? 'in-trans' : '',
            className || ""
        ].join(' ')
        return <div className={classString} ref={el => this.el = el}>
            <div onClick={this.toggle}>
                {button || <button title="more">
                    <Icon>{(isOpened ? 'unfold_less' : 'more_vertical')}</Icon>
                </button>}
            </div>
            {(isOpened || inTrans) && <div className='body'>
                {items.map(({onClick, name, icon, url, className, color, _element},i) => {
                    return <div key={i} className={className || _element  ? 'custom' : ''} onClick={onClick}>
                        {_element ||
                        url && <Link className="block" to={url}>{name}</Link> ||
                        <WithIcon align='middle' sm
                                  color={color}
                                  icon={icon || <div style={{width: '21px'}}/>}>
                            <span className="ellipsis">{name}</span>
                        </WithIcon>}
                    </div>
                })}
            </div>}
        </div>
    }
}

jumpList.propTypes = {
    icon : PropTypes.string,
    items: PropTypes.array
}

export default jumpList