import { call } from '@advanza/api'
import { Button, LoadingDots, PreIcon } from '@advanza/ui'
import React, { useState } from 'react'

const MatchMailData = ({ providerId }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [debugData, setDebugData] = useState({})
    const dataKeys = [
        'isValidated',
        'isPremium',
        'nrAnswered',
        'nrAnsweredTotal',
        'hasAutoReply',
        'matchNr',
        'matchNrPremium',
        'hasExtendedMatchDetails',
    ]
    const filterData = (matchData, data) => {
        const filtered = {
            ...matchData,
            responseRate: data['matchNr'] <= 1 ? 0 : data['nrAnswered'] / Math.min(10, (data['matchNr'] - 1)),
        }
        for (const key in data) {
            if (dataKeys.includes(key)) {
                filtered[key] = data[key]
            }
        }
        setDebugData(filtered)
    }

    const getMatchMailData = () => {
        if (isFetching) {
            return false
        }
        setIsFetching(true)
        return call(`office/providers/get-match-mail-data/${providerId}`).then((response) => {
            setIsFetching(false)
            setHasFetched(true)
            filterData(response.matchData, response.data)
        })
    }

    return (
        <div>
            <Button name="text" onClick={() => getMatchMailData(providerId)}>
                {isFetching ? (
                    <LoadingDots />
                ) : (
                    <PreIcon primColor icon="refresh">
                        Get Last Match Mail Data
                    </PreIcon>
                )}
            </Button>
            {hasFetched && (
                <div>
                    <pre>{JSON.stringify(debugData, null, 2)}</pre>
                </div>
            )}
        </div>
    )
}

export default MatchMailData
