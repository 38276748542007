import { compareAsc, format, intlFormat, isValid, startOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { getDateFnsLocale, getLocale } from './locale'

const HHmm = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
}

const ddMM = {
    day: '2-digit',
    month: '2-digit',
}

const dMMM = {
    day: 'numeric',
    month: 'short',
}

interface OptionsMap {
    [key: string]: {
        [key: string]: string | boolean
    }
}

export const optionsMap: OptionsMap = {
    'dd-MM': { ...ddMM },
    'dd-MM HH:mm': { ...ddMM, ...HHmm },
    'dd-MM-yy': { ...ddMM, year: '2-digit' },
    'dd-MM-yy HH:mm': { ...ddMM, year: '2-digit', ...HHmm },
    'dd-MM-yyyy': { ...ddMM, year: 'numeric' },
    'dd-MM-yyyy HH:mm': { ...ddMM, year: 'numeric', ...HHmm },
    'dd-MM-yyyy HH:mm:ss': {
        ...ddMM,
        year: 'numeric',
        ...HHmm,
        second: '2-digit',
    },
    'd MMM': { ...dMMM },
    'd MMM HH:mm': { ...dMMM, ...HHmm },
    'd MMM yyyy': { ...dMMM, year: 'numeric' },
    'd MMM yyyy HH:mm': { ...dMMM, year: 'numeric', ...HHmm },
    'd MMMM': { day: 'numeric', month: 'long' },
    'd MMMM yyyy': { day: 'numeric', month: 'long', year: 'numeric' },
    'd MMMM yyyy HH:mm': { day: 'numeric', month: 'long', year: 'numeric', ...HHmm },
}

export const getDate = (string = '') => new Date(string.replace(' ', 'T'))

// test for DB UTC datetime string: "2021-04-02 09:10:34" or "2021-04-02T09:10:34+00:00" from Cake
const isFromDatabase = (dateTime: string) =>
    /(\d{4})-(\d{1,2})-(\d{1,2})(T|\s)(\d{2}):(\d{2}):(\d{2})/.test(dateTime)

export const getDateObject = (dateTime: string | Date | undefined): Date => {
    // date is undefined
    if (!dateTime) {
        return new Date()
    }

    // date is already a Date object
    if (dateTime instanceof Date) {
        return dateTime
    }

    // date is string
    return isFromDatabase(dateTime)
        ? new Date(dateTime.replace(' ', 'T') + (dateTime.indexOf('+00:00') === -1 ? '+00:00' : ''))
        : new Date(dateTime)
}

export const getDateObjectWithResettedTime = (dateTime: string | Date | undefined): Date => {
    const dateObject = getDateObject(dateTime)
    dateObject.setHours(0, 0, 0, 0)
    return dateObject
}

/**
 * Convert a datetime string or a Date object to a localized datetime string
 *
 * Important:
 * In Germany dots are used in date formats string: 21.10.2021 and 21. Oktober 2021
 * Date-fns itself has only 4 predefined variations for localized dateFormats (full, long, medium and short i.e. PPPP, PPP, PP, P)
 * For other formats use the Intl.DateTimeFormat to convert
 * See for examples: /pro/dev/dateFormat
 */
export const getDateTimeString = (
    dateTime: string | Date | undefined,
    formatString: string = 'dd-MM-yy HH:mm'
): string => {
    try {
        const dateObject = getDateObject(dateTime)
        if (!isValid(dateObject)) {
            throw new Error(`Invalid date: ${dateObject.getDate()}`)
        }

        if (formatString.indexOf('d') !== -1) {
            // use intFormat map
            const optionsObject = optionsMap[formatString]
            if (!optionsObject || Object.keys(optionsObject).length === 0) {
                throw new Error(`Invalid date format: ${formatString}`)
            }
            return intlFormat(dateObject, optionsObject, { locale: getLocale() })
        } else {
            // use date fns format
            return format(dateObject, formatString, { locale: getDateFnsLocale() })
        }
    } catch (e) {
        console.error(e)
        // don't crash the entire app
        return 'INVALID DATE'
    }
}

// day-only versions
export const compareAscDay = (date1: string | Date, date2: string | Date) =>
    compareAsc(startOfDay(getDateObject(date1)), startOfDay(getDateObject(date2)))

export const isPastDay = (date: string | Date) => compareAscDay(date, new Date()) === -1

export const isFutureDay = (date: string | Date) => compareAscDay(date, new Date()) === 1

export const useFormatDays = () => {
    const { t } = useTranslation('shared')
    const formatDays = (days: number) => (days === 1 ? t('format_day') : t('format_days', { days }))
    return { formatDays }
}
