import { Section } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import { useActionModal } from '@advanza/modal'
import { Button, Divider, FixedWarningPopup, Icon } from '@advanza/ui'
import { doGdpr, generateDashboardLink } from 'actions/demandUsers'
import { saveUser, sendPasswordMail } from 'actions/users'
import { useToast } from 'components/misc/Toaster'
import AvatarEdit from 'components/providers/AvatarEdit'
import RequestFilter from 'components/requests/RequestFilter'
import RequestList from 'components/requests/RequestList'
import Restricted from 'components/Restricted'
import SafeButton from 'components/ui/SafeButton'
import { subYears } from 'date-fns'
import { useChangeEntity } from 'hooks/entityHooks'
import { hasAccess } from 'misc/user'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import style from './demandUserEntity.module.css'

const fields = {
    first_name: { type: 'text', placeholder: 'Name' },
    email: { type: 'email', placeholder: 'Email' },
    phone: { type: 'tel', placeholder: 'Phone' },
}

const DemandUserEntity = ({ demandUserId }) => {
    const dispatch = useDispatch()
    const { onFilesRejected } = useToast()
    const [toast, setToast] = useState({})
    const [linkCopied, setLinkCopied] = useState(false)
    const { openModal, renderModal } = useActionModal()
    const { entity, onSaveEntity, renderInput, onChangeEntity } = useChangeEntity(
        {
            store: 'demandUsers',
            name: 'demandUsers',
            entityId: demandUserId,
            saveFunc: (userId) => saveUser(userId, 'demandUsers', 'demandUsers'),
        },
        fields
    )

    if (!demandUserId) {
        return null
    }

    const disableHistoricRequestUser =
        new Date(entity.lastRequestCreated) < subYears(new Date(), 1) &&
        !hasAccess('historic_request_data')

    const onChangeAvatar = (file) => {
        const { avatarFile = {} } = entity
        onChangeEntity({
            avatarFile: { ...avatarFile, ...file },
        })
    }

    const onCrop = (crop, crop2) => {
        const { avatarFile = {} } = entity
        onChangeEntity({
            avatarFile: { ...avatarFile, crop: { ...crop, aspect: 1 }, crop2 },
        })
    }

    const onSave = () =>
        onSaveEntity().then(
            () => {
                setToast({ id: Date.now(), message: 'Demand user saved' })
            },
            (response) => {
                setToast({ isError: true, id: Date.now(), message: JSON.stringify(response) })
            }
        )

    const onCopyLinkToClipboard = (ref) => {
        if (ref && !linkCopied) {
            ref.select()
            setTimeout(ref.select, 10)
            setTimeout(() => document.execCommand('copy'), 100)
            setLinkCopied(true)
        }
    }

    return (
        <Section x y>
            <Row>
                <Col x>
                    <AvatarEdit
                        onChangeImage={onChangeAvatar}
                        onCrop={onCrop}
                        size={175}
                        user={entity}
                        crop={(entity.avatarFile && entity.avatarFile.crop) || {}}
                        file={entity.avatarFile}
                        onFilesRejected={onFilesRejected}
                    />
                </Col>
                <Col x className={style.grow}>
                    <Row>
                        <Col x>{renderInput('first_name')}</Col>
                        <Col x className={style.grow}>
                            {renderInput('email')}
                        </Col>
                        <Col x>{renderInput('phone')}</Col>
                    </Row>
                    <Divider m />
                    <Restricted location="demand_users">
                        <Row between="xs">
                            <Col x>
                                <Button
                                    name="delete"
                                    onClick={() => openModal()}
                                    disabled={entity._saving}>
                                    <Icon name="delete_forever" red /> Delete user
                                </Button>
                                {renderModal({
                                    cancelText: "No, don't delete",
                                    text: 'Yes, delete',
                                    func: () => dispatch(doGdpr(demandUserId)),
                                    header: 'Delete demand user - Are you sure?',
                                    body: 'This demand user will be deleted.',
                                })}
                            </Col>
                            {!disableHistoricRequestUser && (
                                <Col x>
                                    <Row>
                                        <Col x>
                                            <SafeButton
                                                action={() =>
                                                    dispatch(sendPasswordMail(entity.email))
                                                }
                                                buttonText="Send password mail"
                                                successMessage="Mail sent!"
                                                confirmMessage="Confirm send mail"
                                                newStyle
                                            />
                                        </Col>
                                        <Col x>
                                            <Button
                                                onClick={onSave}
                                                disabled={!entity._isTouched || entity._saving}>
                                                {entity._saving ? '..saving' : 'Save'}
                                            </Button>
                                            {toast.isError && (
                                                <div style={{ color: 'red' }}>not saved</div>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Restricted>
                </Col>
            </Row>
            <Divider m />
            <Row between="xs">
                <Col x>
                    <h4>Requests</h4>
                </Col>
                {!disableHistoricRequestUser && (
                    <Col x>
                        <Restricted location="demand_users">
                            {entity._dashboardLink ? (
                                <input
                                    style={{ textOverflow: 'ellipsis' }}
                                    ref={onCopyLinkToClipboard}
                                    value={entity._dashboardLink}
                                    readOnly={true}
                                />
                            ) : (
                                <Button
                                    name="text"
                                    disabled={entity._saving}
                                    onClick={() => dispatch(generateDashboardLink(demandUserId))}>
                                    Create dashboardlink
                                </Button>
                            )}
                        </Restricted>
                    </Col>
                )}
            </Row>
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
            <Divider s />
            <RequestFilter
                filterId={`u_${demandUserId}`}
                compact
                defaultOptions={{ userIds: [demandUserId] }}
            />
            <RequestList filterId={`u_${demandUserId}`} />
        </Section>
    )
}

export default DemandUserEntity
