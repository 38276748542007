import { Col, Row } from '@advanza/advanza_generic'
import React from 'react'

const SalesLeadScrapedProfile = ({ provider, providerEntities }) => {
    const { users } = providerEntities
    const user = users[provider.user]
    const tableMap = [
        { key: 'service_provider_id', name: 'Service provider id' },
        { key: 'business_name', name: 'Business name' },
        { key: 'website', name: 'Website' },
    ]
    return (
        <div>
            {tableMap.map(({ key, name, item }) => {
                const answer = item || provider[key] || user[key]
                if (answer) {
                    return (
                        <Row key={name} y="min">
                            <Col c={4}>{name}</Col>
                            <Col c={8}>
                                <b>{answer}</b>
                            </Col>
                        </Row>
                    )
                }
            })}
        </div>
    )
}

export default SalesLeadScrapedProfile
