import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { REVIEWS_CHANGE_FILTER, REVIEWS_RECEIVE_LIST, REVIEWS_REQUEST } from '../actions/reviews'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
    selectItems,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [REVIEWS_RECEIVE_LIST]: receiveList,
    [REVIEWS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: action.filterId || true },
        }),
    [REVIEWS_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_REVIEWS: changeEntity,
    INVALIDATE_REVIEWS: invalidateFilters,
    REVIEWS_SELECT_ITEMS: selectItems,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
