import { SelectSimple } from '@advanza/input/'
import { addMonths } from 'date-fns'
import format from 'date-fns/format'
import React from 'react'

function getMonthOptions(nrMonths, nrFutureMonths, startYearMonth) {
    const options = []
    let date = new Date()

    if (nrFutureMonths) {
        date = addMonths(date, nrFutureMonths)
    }

    const showYears = parseInt(format(date, 'M')) < nrMonths || startYearMonth
    const nameFormat = showYears ? 'MMMM yyyy' : 'MMMM'

    for (let i = 0; i < nrMonths || startYearMonth; i++) {
        const value = format(date, 'yyyy-MM')
        options.push({
            value,
            name: format(date, nameFormat),
        })

        if (value === startYearMonth) {
            break
        }

        date = addMonths(date, -1)
    }

    return options
}

const MonthPicker = ({
    onChange,
    value,
    nrMonths = 6,
    nrFutureMonths = 0,
    startYearMonth,
    ...rest
}) => {
    return (
        <SelectSimple
            options={getMonthOptions(nrMonths, nrFutureMonths, startYearMonth)}
            placeholder="select month"
            alwaysControlValue
            emptyOption="<all>"
            onChange={({ target }) => {
                onChange(target.value)
            }}
            value={value}
            {...rest}
        />
    )
}

export default MonthPicker
