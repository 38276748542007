import { Col, Icon, Row } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'

const Pagination = ({ onPageChange, current, count, countOnPage, onCountList, pageSize }) => {
    if (count === '?') {
        return (
            <div className="clickable" onClick={() => onPageChange(current, true)}>
                <Icon>pageview</Icon>
            </div>
        )
    }

    const countList = count !== null
    const pages = countList ? Math.ceil(count / pageSize) : null
    const nrResultsPage = countList
        ? Math.min(pageSize, count - current * pageSize)
        : countOnPage || pageSize
    if ((countList && pages === 0) || (!countList && current === 0 && nrResultsPage < pageSize)) {
        return <small>({nrResultsPage})</small>
    }

    const hasNextPage = countList ? current + 1 < pages : nrResultsPage === pageSize
    const hasPrevPage = current > 0
    const nextPage = hasNextPage && current + 1
    const prevPage = hasPrevPage && current - 1
    return (
        <Row className="no-wrap v-center no-gutters">
            <Col c={3}>
                <button onClick={hasPrevPage ? () => onPageChange(prevPage) : null}>
                    <Icon color={hasPrevPage ? 'dark' : 'lighter'}>keyboard_arrow_left</Icon>
                </button>
            </Col>
            <Col c={6} className="center">
                <small>
                    {current * pageSize + 1}-{current * pageSize + nrResultsPage}
                </small>
                <br />
                {countList ? (
                    <small>of {count}</small>
                ) : (
                    <button onClick={onCountList}>
                        <small style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                            show total
                        </small>
                    </button>
                )}
            </Col>
            <Col c={3}>
                <button onClick={hasNextPage ? () => onPageChange(nextPage) : null}>
                    <Icon color={hasNextPage ? 'dark' : 'lighter'}>keyboard_arrow_right</Icon>
                </button>
            </Col>
        </Row>
    )
}

Pagination.propTypes = {
    current: PropTypes.number,
    onPageChange: PropTypes.func,
    count: PropTypes.any,
    countOnPage: PropTypes.number,
    onCountList: PropTypes.func,
    pageSize: PropTypes.any,
}

export default Pagination
