import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { LABELS_CHANGE_FILTER, LABELS_RECEIVE_LIST, LABELS_REQUEST } from '../actions/labels'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
    selectItems,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [LABELS_RECEIVE_LIST]: receiveList,
    [LABELS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [LABELS_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_LABELS: changeEntity,
    INVALIDATE_LABELS: invalidateFilters,
    LABELS_SELECT_ITEMS: selectItems,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
