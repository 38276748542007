import { call } from '@advanza/api'
import { normalize } from 'normalizr'
import eventsSchema from '../schemes/eventsSchema'
import { _changeFilter, _fetchItems } from './lists'

export const EVENTS_REQUEST = 'EVENTS_REQUEST'
export const EVENTS_RECEIVE_LIST = 'EVENTS_RECEIVE_LIST'
export const EVENTS_RECEIVE_SINGLE = 'EVENTS_RECEIVE_SINGLE'
export const EVENTS_CHANGE_FILTER = 'EVENTS_CHANGE_FILTER'
export const EVENTS_INVALIDATE = 'EVENTS_INVALIDATE'

export function fetchEvents(filterId) {
    return _fetchItems(filterId, {
        url: 'office/events/get-list',
        schema: eventsSchema,
        requestActionName: EVENTS_REQUEST,
        receiveActionName: EVENTS_RECEIVE_LIST,
        reducerKey: 'events',
    })
}

export function invalidateEvents(filterId) {
    return function (dispatch) {
        if (filterId) {
            dispatch(changeEventFilter(filterId, { didInvalidate: true }))
        } else {
            dispatch({ TYPE: EVENTS_INVALIDATE })
        }
    }
}

export function changeEventFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'events',
        fetchFunc: fetchEvents,
        actionType: EVENTS_CHANGE_FILTER,
    })
}

export function fetchEvent(eventId) {
    return function (dispatch, getState) {
        const eventEntities = getState().events.entities.events
        if (eventEntities && eventEntities[eventId]) {
            return Promise.resolve()
        }
        dispatch({ type: EVENTS_REQUEST })
        return call('office/events/get-one/' + eventId).then((response) => {
            const { entities } = normalize([response.item], eventsSchema)
            dispatch({ type: EVENTS_RECEIVE_SINGLE, entities, eventId })
        })
    }
}
