import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { Button, Divider } from '@advanza/ui'
import { saveProvider } from 'actions/providers'
import { useChangeEntity } from 'hooks/entityHooks'

function getFields(franchises) {
    return {
        franchise_id: {
            type: 'select',
            className: 'select-min fsize lg',
            options: [{ value: null, title: '' }].concat(franchises),
        },
    }
}

const useFranchises = () => {
    const dispatch = useDispatch()
    const { franchises } = useSelector((state) => state.providers)
    const { countries = [] } = useSelector((state) => state.countries)
    const [isFetchingFranchises, setIsFetchingFranchises] = useState(false)

    useEffect(() => {
        if (isFetchingFranchises || franchises) {
            return
        }

        setIsFetchingFranchises(true)
        call('office/providers/get-franchises')
            .then((response) => {
                dispatch({ type: 'FRANCHISES_RECEIVE', response })
            })
            .finally(() => setIsFetchingFranchises(false))
    }, [])

    const franchisesForCountry = (franchises || []).filter((franchise) =>
        countries.some((country) => country.toUpperCase() === franchise.country_code.toUpperCase())
    )

    return { isFetchingFranchises, franchises: franchisesForCountry }
}

const FranchiseSelect = ({ providerId }) => {
    const { isFetchingFranchises, franchises } = useFranchises()

    const {
        entity: provider,
        renderInput,
        onSaveEntity,
    } = useChangeEntity(
        {
            store: 'providers',
            name: 'providers',
            entityId: providerId,
            saveFunc: saveProvider,
        },
        getFields(franchises)
    )

    return (
        !isFetchingFranchises && (
            <>
                {renderInput('franchise_id')}
                <Divider m />
                <Row end="xs">
                    <Col x>
                        <Button
                            disabled={!provider._isTouched || provider._saving}
                            onClick={onSaveEntity}>
                            {provider._saving ? '..saving' : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </>
        )
    )
}

export default FranchiseSelect
