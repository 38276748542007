import { useSimpleModal } from '@advanza/modal'
import { Divider, PreIcon } from '@advanza/ui'
import { useEffect } from 'react'
import style from './missingPostalCodeIdWarning.module.scss'

interface Props {
    provider: {
        service_provider_id: number
        business_name: string
        postal_code_id: number | null
    }
}

const MissingPostalCodeIdWarning = ({ provider }: Props) => {
    const { openModal, renderModal } = useSimpleModal()
    const modal = renderModal({
        className: style.modalRoot,
        body: (
            <div>
                <PreIcon icon="warning" className={style.heading}>
                    <h2>WARNING!</h2>
                </PreIcon>
                <Divider s />
                <div className={style.subHeading}>
                    Enter a valid postal code and place for {provider.business_name}
                </div>
                <Divider s />
                <div>Without a valid postal code and place, the pro dashboard will not work!</div>
            </div>
        ),
        buttons: [
            {
                text: 'Close',
            },
        ],
        maxWidth: '800px',
    })

    useEffect(() => {
        if (provider.postal_code_id === null) {
            openModal()
        }
    }, [provider.service_provider_id])

    return <>{modal}</>
}

export default MissingPostalCodeIdWarning
