import PropTypes from 'prop-types'
import React from 'react'
import getClass from '../classNames'
import createProps from '../createProps'

const propTypes = {
    fluid: PropTypes.bool,
    className: PropTypes.string,
    tagName: PropTypes.string,
    children: PropTypes.node,
}

export default function Grid(props) {
    const containerClass = getClass(props.fluid ? 'container-fluid' : 'container')
    const classNames = [props.className, containerClass]

    return React.createElement(props.tagName || 'div', createProps(propTypes, props, classNames))
}

Grid.propTypes = propTypes
