import { Section } from '@advanza/advanza_generic'
import { Icon } from '@advanza/ui'
import profileLabelStyle from 'components/profileLabels/profileLabels.module.css'
import PropTypes from 'prop-types'
import React from 'react'
import LabelListContainer from '../../containers/LabelListContainer'
import TableList from '../TableList'
import style from './label.module.css'
import LabelEntity from './LabelEntity'

class LabelAdminList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    getTextColor(color) {
        const colors = color.replace('#', '')
        const red = parseInt(colors.substr(0, 2), 16)
        const green = parseInt(colors.substr(2, 2), 16)
        const blue = parseInt(colors.substr(4, 2), 16)
        return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? '#000' : '#fff'
    }

    renderColumns(id) {
        const { labels } = this.props.labelEntities
        const label = labels[id]
        const labelStyle = {
            color: label.text_color || (label.color && this.getTextColor(label.color)),
            backgroundColor: label.color,
        }

        return [
            <small className="c-grey">#{id}</small>,
            <div className={style.labelWrapper}>
                <div
                    className={
                        label.category === 'profile' ? profileLabelStyle.profileLabel : style.label
                    }
                    style={labelStyle}>
                    {label.icon && <Icon name={label.icon} fontSize={14} />}
                    <span>{label.description}</span>
                </div>
            </div>,
            label.category,
            label.subcategory,
            label.type,
            label.icon,
            <div
                style={{ height: 18, width: 18, borderRadius: 5, backgroundColor: label.color }}
            />,
            <div
                style={{
                    height: 18,
                    width: 18,
                    borderRadius: 5,
                    backgroundColor: label.text_color,
                }}
            />,
        ]
    }

    renderRow(id) {
        const { labelCategories, labelSubcategories, labelTypes } = this.props
        const { labels } = this.props.labelEntities
        const label = labels[id]

        return {
            id,
            cols: this.renderColumns(id),
            expandable: (
                <Section x y>
                    <LabelEntity
                        entityId={id}
                        labelCategories={labelCategories}
                        labelSubcategories={labelSubcategories}
                        labelTypes={labelTypes}
                        excludeCustom
                    />
                </Section>
            ),
            expanded: typeof label.office_label_id === 'string',
            expandOnClick: true,
        }
    }

    definitions = {
        id: { name: '#id' },
        description: { name: 'description' },
        category: { name: 'category' },
        subcategory: { name: 'subcategory' },
        type: { name: 'type' },
        icon: { name: 'icon' },
        color: { name: 'color' },
        textColor: { name: 'textColor' },
    }

    render() {
        const { selectable, ...rest } = this.props

        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

LabelAdminList.propTypes = {
    filter: PropTypes.object,
    pages: PropTypes.any,
    labelEntities: PropTypes.object,
    isFetching: PropTypes.bool,
}

export default LabelListContainer(LabelAdminList)
