import { schema } from 'normalizr'

const services_keywords_variations = new schema.Entity(
    'services_keywords_variations',
    {},
    {
        idAttribute: 'services_keywords_variation_id',
    }
)
export default [
    new schema.Entity(
        'keywords',
        {
            services_keywords_variations: [services_keywords_variations],
        },
        {
            idAttribute: 'services_keyword_id',
        }
    ),
]
