import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'
import { selectItems } from 'actions/lists'
import { fetchPauseReasons } from 'actions/pauseReasons'
import { changeProviderDatesFilter } from 'actions/providersDates'
import { differenceInCalendarDays, startOfDay } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const DAY_SCHEMES = {
    0: 'no repeat',
    1: 'weekly',
    2: 'biweekly',
    3: 'monthly',
    4: 'yearly',
}

export const PAUSE_TYPES = {
    0: 'no requests',
    1: 'no fulfill',
}

export function countDays(from = new Date(), to = new Date(), includeFirstDay = true) {
    if (!to) {
        return ''
    }

    let countDays = differenceInCalendarDays(to, from)
    if (includeFirstDay) {
        countDays++
    }

    return countDays
}

export function isPastDay(date = new Date()) {
    return startOfDay(date).getTime() < startOfDay(new Date()).getTime()
}

export function useProvidersDate(providersDateId, onSucces, store = 'providersDates') {
    const dispatch = useDispatch()
    const isNew = typeof providersDateId === 'string'
    const { isFetching: isFetchingPauseReasons, entities } = useSelector(
        (state) => state.pauseReasons
    )
    const { pauseReasons } = entities
    const { providersDates } = useSelector((state) => state[store].entities)
    const providersDate = (providersDates && providersDates[providersDateId]) || {}
    const [errors, setErrors] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const onChange = (e) => onChangeEntity({ [e.target.name]: e.target.value })

    const onChangeEntity = (changes, dontTouch = false) => {
        dispatch(
            changeEntity({
                store,
                name: 'providersDates',
                key: providersDateId,
                diff: { ...changes },
                dontTouch,
            })
        )
    }

    const onSave = () => {
        setIsSaving(true)
        return call(`office/providers/save-pause-period/${isNew ? '' : providersDateId}`, {
            json: providersDate,
        })
            .then(
                () => {
                    onSucces()
                },
                (response) => {
                    setErrors(response)
                    return Promise.reject()
                }
            )
            .finally(() => {
                setIsSaving(false)
            })
    }

    const onCancel = () => {
        if (providersDate._isTouched && providersDate._beforeSave) {
            onChangeEntity(providersDate._beforeSave)
        }
    }

    const onDelete = () => {
        setIsSaving(true)
        return call('office/providers/delete-pause-period/' + providersDateId, { method: 'post' })
            .then(
                () => {
                    onSucces()
                },
                () => {
                    return Promise.reject()
                }
            )
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        dispatch(fetchPauseReasons())
    }, [])

    return {
        isFetchingPauseReasons,
        pauseReasons,
        providersDate,
        onChange,
        onChangeEntity,
        onSave,
        isSaving,
        errors,
        setErrors,
        onCancel,
        onDelete,
    }
}

export function useFilterProvidersDates(filterId) {
    const dispatch = useDispatch()
    const { countries } = useSelector((state) => state.countries)
    const { services } = useSelector((state) => state.services.entities)
    const {
        entities: providersDatesEntities,
        filters,
        isFetching,
        result,
        counts,
    } = useSelector((state) => state.providersDates)
    const filter = filterId && filters[filterId]
    const filterResult = filter && result[filter.searchKey]
    const searchKey = filter && filter.searchKey
    const pages = result[searchKey] || {}
    const count = filter && counts[searchKey]
    const countOnPage = filter && filterResult[filter.page || 0].length

    const onChangeFilter = (options) => {
        dispatch(changeProviderDatesFilter(filterId, options))
    }

    const onInvalidateFilter = () => {
        onChangeFilter({ didInvalidate: true, page: 0 })
    }

    const onSelectProviders = (ids, filterId) => {
        dispatch(selectItems('providersDates', ids, filterId))
    }

    useEffect(() => {
        dispatch(fetchPauseReasons())
    }, [])

    return {
        filter,
        countries,
        services,
        providersDatesEntities,
        pages,
        count,
        countOnPage,
        isFetching,
        onChangeFilter,
        onInvalidateFilter,
        onSelectProviders,
    }
}
