import { Icon } from '@advanza/ui'
import React, { Fragment } from 'react'

const ListItemSelector = ({ isSelected, select, disabled }) => {
    return (
        <Fragment>
            <label className={`${isSelected ? 'checked' : ''} clickable`}>
                <Icon
                    primColor={isSelected && !disabled}
                    grey={disabled}
                    name={isSelected ? 'check_box' : 'check_box_outline_blank'}
                />
                <input
                    type="checkbox"
                    name="item"
                    onChange={select}
                    checked={isSelected}
                    disabled={disabled}
                />
            </label>
        </Fragment>
    )
}

export default ListItemSelector
