import { Icon, HoverText } from '@advanza/ui'
import { getDate } from 'date'
import { format } from 'date-fns'
import React, { useState } from 'react'
import style from './QualityScore.module.css'

const HeatValue = ({ value, scale = 10, isPercentage, lowIsBetter = true, className }) => {
    let deg = 60
    const isNegative = value < 0
    const diff = Math.min(Math.abs(value), 10)
    const brightness = (40 / (scale / 2)) * diff + (20 / scale) * diff
    if ((lowIsBetter && isNegative) || (!lowIsBetter && !isNegative)) {
        deg += brightness
    } else {
        deg -= brightness
    }
    const roundTo = isPercentage ? 0 : 2
    return (
        <div className={className} style={{ color: `hsl(${deg}deg,100%,40%)` }}>
            {isPercentage && !isNegative && '+'}
            {parseFloat(value).toFixed(roundTo)}
            {isPercentage && '%'}
        </div>
    )
}
const Score = ({ value, display, valueCompare, scale = 1, lowerIsBetter }) => {
    const compare = valueCompare && value - valueCompare

    return (
        <div className={style.score}>
            {value === null ? 'no data' : display || value}
            {Math.abs(compare) > 0 && (
                <HeatValue
                    value={(compare / valueCompare) * 100}
                    className={style.compare}
                    isPercentage
                    scale={100}
                    lowIsBetter={lowerIsBetter}
                />
            )}
        </div>
    )
}

function cleanDebugInfo(data) {
    const cleaned = {}
    for (const key in data) {
        if (key.indexOf('_') === 0) {
            cleaned[key] = data[key]
        }
    }

    return cleaned
}

function formatDisplayField(key, values) {
    const value = values[key]
    let formatted = value

    if (key === 'response_rate') {
        formatted += '%'
    } else if (key === 'response_time') {
        formatted = `${value}m (${Math.round(value / 60)}h)`
    }

    if (key === 'response_score' && values.response_rate === null) {
        formatted = 'no data'
    } else if (key === 'reclaim_score' && values.reclaim_percentage === null) {
        formatted = 'no data'
    } else if (key === 'unpaid_score' && values.unpaid_invoices === null) {
        formatted = 'no data'
    }

    return formatted
}

const QualityScore = ({ data, dataCompare = {}, first, open = false, debugInfo }) => {
    const [expanded, setExpanded] = useState(open)
    const col1Data = [
        ['response_time', 'response_time_z'],
        ['response_rate', 'response_rate_z'],
        ['response_time_p80', 'response_time_p80'],
        ['reclaim_percentage', 'reclaim_percentage_z'],
        ['unpaid_invoices'],
    ]
    const scores = ['response_score', 'reclaim_score', 'unpaid_score']
    const lowerIsNotBetter = ['response_rate']
    return (
        <div
            className={[style.root, expanded ? style.expanded : '', first ? style.first : ''].join(
                ' '
            )}>
            <div className={style.date} onClick={() => setExpanded(expanded !== true)}>
                {format(getDate(data.created), 'd MMM')} <Icon name="keyboard_arrow_right" />
            </div>
            <div className={style.main} onClick={() => setExpanded(expanded !== true)}>
                <div className={style.scores}>
                    {scores.map((key) => {
                        return (
                            <div key={key}>
                                <div>{key.replace('_', ' ').replace('score', '')}</div>
                                <Score
                                    value={data[key]}
                                    display={formatDisplayField(key, data)}
                                    lowerIsBetter={false}
                                    valueCompare={dataCompare[key]}
                                />
                            </div>
                        )
                    })}
                </div>
                <div className={style.total}>
                    <Score
                        value={data.total}
                        display={(data.total * 10).toFixed(1)}
                        lowerIsBetter={false}
                        valueCompare={dataCompare.total}
                    />
                </div>
            </div>
            <div className={style.more}>
                <div className={style.dataScores}>
                    <div className={style.head}>
                        <div></div>
                        <div>Value</div>
                        <div>
                            <HoverText
                                trigger={'Z value'}
                                text={
                                    <span>
                                        Hoeveel standaarddeviaties de waarde van het gemiddelde
                                        afwijkt
                                    </span>
                                }
                            />
                        </div>
                    </div>
                    {col1Data.map(([key, zValue]) => {
                        return (
                            <div key={key} className={style.data}>
                                <div className={style.name}>{key.replace('_', ' ')}</div>
                                <div className={style.value}>
                                    <Score
                                        value={data[key]}
                                        display={formatDisplayField(key, data)}
                                        lowerIsBetter={!lowerIsNotBetter.includes(key)}
                                        valueCompare={dataCompare[key]}
                                    />
                                </div>
                                <div className={style.zValue}>
                                    {data[`${key}_z`] && (
                                        <HeatValue
                                            value={data[`${key}_z`]}
                                            lowIsBetter={!lowerIsNotBetter.includes(key)}
                                            scale={5}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {debugInfo && (
                    <div className={style.debugInfo}>
                        <pre>{JSON.stringify(cleanDebugInfo(debugInfo), null, 2)}}</pre>
                    </div>
                )}
            </div>
        </div>
    )
}

export default QualityScore
