import { S3_PUBLIC } from '@advanza/constants'
import { Col, Row } from '@advanza/grid'
import { FileDropZone, InputField } from '@advanza/input'
import { OptionStatus } from '@advanza/types/Option'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import { onFilesRejected } from 'actions/files'
import {
    addSearchableKeyword,
    changeOptionOrder,
    deleteKeyword,
    deleteOption,
    onChangeKeyword,
} from 'actions/services'
import EntityComponent from 'components/services/EntityComponent'
import OptionGroupPicker from 'components/services/OptionGroupPicker'
import OptionIcon from 'components/services/OptionIcon'
import SafeButton from 'components/ui/SafeButton'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import style from './option.module.css'

class Option extends EntityComponent {
    constructor(props) {
        super(props)
        this.onDropImg = this.onDropImg.bind(this)
        this.removeIcon = this.removeIcon.bind(this)
        this.onChangeOptionGroup = this.onChangeOptionGroup.bind(this)
        this.changeOrder = this.changeOrder.bind(this)
        this.state = {
            file: false,
        }
    }

    editFields() {
        const { option, questionType } = this.props
        return {
            value: {
                type: 'text',
                style: {
                    textDecoration: option.status === OptionStatus.ACTIVE ? '' : 'line-through',
                },
                validator: (val) => val && val.length >= 1 && val.length < 155,
            },
            is_field: {
                isHidden: option.icon_name,
                type: 'boolean',
                msg: 'Is field',
            },
            is_pro: {
                type: 'boolean',
                msg: 'Is Pro',
            },
            status: {
                type: 'select',
                placeholder: 'Status',
                options: [
                    { title: <PreIcon name="fiber_new">New</PreIcon>, value: OptionStatus.NEW },
                    {
                        title: <PreIcon name="visibility">Active</PreIcon>,
                        value: OptionStatus.ACTIVE,
                    },
                    {
                        title: <PreIcon name="visibility_off">Hidden</PreIcon>,
                        value: OptionStatus.HIDDEN,
                    },
                ],
                onBeforeChange: (value) =>
                    value !== OptionStatus.ACTIVE && this.props.toggleShowHidden(true),
                className: style.optionStatus,
            },
            is_default: {
                type: 'boolean',
                msg: 'default wizard answer',
            },
            exclude_from_popular: {
                type: 'boolean',
                msg: 'Exclude from popular',
            },
            default_subscription: {
                type: 'boolean',
                msg: 'Default keyword filter',
            },
            is_hidden_default: {
                type: 'boolean',
                msg: 'Hidden default',
            },
            calculator_value: {
                type: 'number',
                msg: '€ calc. value',
                title: 'If left empty, this option will disable the calculator when selected',
            },
            numeric_value: {
                type: 'number',
                jump: false,
                icon: 'money',
                msg: 'numeric value',
                isHidden: !/\d/.test(option.value),
            },
            seo_h1: {
                placeholder: 'SEO H1',
            },
            option_url: {
                type: 'text',
                placeholder: 'url',
                onFocus: () =>
                    this.onChangeEntity({
                        option_url:
                            option.option_url || option.value.replace(/ /g, '-').toLowerCase(),
                    }),
            },
            slug: {
                type: 'text',
                placeholder: 'slug',
                onFocus: () =>
                    this.onChangeEntity({
                        slug:
                            option.slug ||
                            (option.option_url || option.value)
                                .replace(/\b[a-zA-Z][a-z]{0,2}\b/g, '')
                                .replace(/\W+/g, ' ')
                                .trim()
                                .replace(/\s+/g, '-')
                                .toLowerCase(),
                    }),
            },
            info_text: {
                type: 'text',
                placeholder: 'Info text',
            },
            add_answer: {
                type: 'boolean',
                msg: 'Add text',
                isHidden: option.icon_name || !['1', '2'].includes(questionType),
            },
        }
    }

    removeIcon(e) {
        e.stopPropagation()
        this.onChangeEntity({ icon_name: null })
        this.setState({ file: false })
    }

    onDropImg(files) {
        const { addOptionImage, option } = this.props
        const Reader = new FileReader()
        Reader.onloadend = (e) => this.setState({ file: { url: e.target.result, file: files[0] } })
        Reader.readAsDataURL(files[0])

        addOptionImage(option.option_id, files[0])
    }

    onChangeOptionGroup(values) {
        const value = values[0]
        this.onChangeEntity({ option_group_id: value.value })
    }

    changeOrder(optionId, upOrDown) {
        const { changeOptionOrder } = this.props
        changeOptionOrder(optionId, upOrDown)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.index !== this.props.index) {
            this.setState({ orderChanged: true })
            const direction = prevProps.index > this.props.index ? 'up' : 'down'
            setTimeout(
                () => {
                    this.setState({ orderChanged: false, direction })
                },
                direction === 'up' ? 100 : 150
            )
        }
    }

    render() {
        const { option, index } = this.props
        const { file } = this.state
        const imageUrl = file
            ? file.url
            : option.icon_name
            ? encodeURI(`${S3_PUBLIC()}option_icons/${option.option_id}/${option.icon_name}`)
            : false

        const dropzoneStyle = {
            cursor: 'pointer',
            height: 'auto',
            width: 60,
        }

        return (
            <Row
                middle="xs"
                className={[style.root, this.state.orderChanged ? style.orderChanged : ''].join(
                    ' '
                )}>
                <div>
                    <div
                        className="clickable"
                        onClick={this.changeOrder.bind(this, option.option_id, 'up')}>
                        <a className={style.orderButton}>
                            <Icon>keyboard_arrow_up</Icon>
                        </a>
                    </div>
                    <div
                        className="clickable"
                        onClick={this.changeOrder.bind(this, option.option_id, 'down')}>
                        <a className={style.orderButton}>
                            <Icon>keyboard_arrow_down</Icon>
                        </a>
                    </div>
                </div>
                <button style={{ display: !imageUrl ? 'none' : null }} onClick={this.removeIcon}>
                    <Icon>remove_circle</Icon>
                </button>
                <FileDropZone
                    style={imageUrl ? dropzoneStyle : {}}
                    allowedTypes={['image']}
                    allowSvg
                    multiple={false}
                    maxFiles={1}
                    onFilesAccepted={this.onDropImg}>
                    {imageUrl ? (
                        <OptionIcon option={option} url={imageUrl} />
                    ) : (
                        <Icon>add_a_photo</Icon>
                    )}
                </FileDropZone>
                <Col x>
                    <Row middle="xs" className={style.buttonRow}>
                        <Col x>
                            <h3>{index}.</h3>
                        </Col>
                        <Col x>
                            <small>#{option.option_id}</small>
                        </Col>
                        <Col x>{this.renderInput('status')}</Col>
                        <Col x>{this.renderInput('is_field')}</Col>
                        <Col x>{this.renderInput('is_pro')}</Col>
                        <Col x>{this.renderInput('is_default')}</Col>
                        <Col x>{this.renderInput('exclude_from_popular')}</Col>
                        <Col x>{this.renderInput('default_subscription')}</Col>
                        <Col x>{this.renderInput('is_hidden_default')}</Col>
                        {this.isVisible('add_answer') && (
                            <Col x>{this.renderInput('add_answer')}</Col>
                        )}
                        <Col x style={{ marginLeft: 'auto', marginRight: 0 }}>
                            <SafeButton
                                action={this.delete}
                                cancelText=" "
                                confirmMessage="Delete"
                                buttonText={<Icon>delete_forever</Icon>}
                            />
                        </Col>
                    </Row>
                    <Divider m />
                    <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                        <Col x>
                            <OptionGroupPicker
                                onValueChange={this.onChangeOptionGroup}
                                value={option.option_group_id}
                                questionId={option.question_id}
                            />
                        </Col>
                        <Col x>{this.renderInput('value')}</Col>
                        <Col x>{this.renderInput('calculator_value')}</Col>
                        {this.isVisible('numeric_value') && (
                            <Col x>{this.renderInput('numeric_value')}</Col>
                        )}
                        <Col x>{this.renderInput('option_url')}</Col>
                    </Row>
                    <Divider s />
                    <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                        <Col x>{this.renderInput('slug')}</Col>
                    </Row>
                    <Row middle={'xs'}>
                        {this.getEntity().option_url && (
                            <Col xs>
                                <Divider s />
                                {this.renderInput('seo_h1')}
                            </Col>
                        )}
                        <Col xs>
                            <Divider s />
                            {this.renderInput('info_text')}
                        </Col>
                    </Row>
                    <Row>
                        <Col x>
                            <Divider s />
                            <h4> Keywords </h4>
                        </Col>
                    </Row>
                    <Row middle={'xs'}>
                        <Col x>
                            <Divider s />
                            <Button
                                onClick={() => this.props.addSearchableKeyword(option.option_id)}>
                                Add Keyword
                            </Button>
                        </Col>
                    </Row>
                    {option.options_searchable_keywords.map((searchableKeyword, index) => {
                        return (
                            <Row middle={'xs'}>
                                <Col x>
                                    <Divider s />
                                    <InputField
                                        onChange={(e) =>
                                            this.props.onChangeKeyword(
                                                option.option_id,
                                                searchableKeyword.options_searchable_keyword_id,
                                                e.target.value
                                            )
                                        }
                                        value={searchableKeyword.keyword}
                                    />
                                </Col>
                                <Col x>
                                    <Icon
                                        style={{ cursor: 'pointer' }}
                                        name={'delete'}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.deleteKeyword(
                                                option.option_id,
                                                searchableKeyword.options_searchable_keyword_id
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row>
        )
    }
}

export default EntityComponentContainer(Option, {
    store: 'services',
    deleteFunc: deleteOption,
    name: 'options',

    mapDispatchToProps: (dispatch) => {
        return {
            changeOptionOrder: (...params) => {
                dispatch(changeOptionOrder(...params))
            },
            onFilesRejected: (files) => dispatch(onFilesRejected(files)),
            addSearchableKeyword: (optionId) => dispatch(addSearchableKeyword(optionId)),
            onChangeKeyword: (optionId, keywordId, data) =>
                dispatch(onChangeKeyword(optionId, keywordId, data)),
            deleteKeyword: (optionId, keywordId) => dispatch(deleteKeyword(optionId, keywordId)),
        }
    },
})
