import { call } from '@advanza/api'
import Modal from '@advanza/modal'
import { LoadingDots, Divider, Icon, Toggle } from '@advanza/ui'
import { fetchProvider } from 'actions/providers'
import LogsList from 'components/logs/LogsList'
import SafeButton from 'components/ui/SafeButton'
import { formatRelative } from 'date'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './LinkExchangeWidget.module.css'

function useLinkExchangeWidget(providerId) {
    const { link_exchange_widget } = useSelector(
        (state) => state.providers.entities.providers[providerId]
    )
    const hasWidget = link_exchange_widget && link_exchange_widget.widget_first_seen
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)

    const activate = () => {
        setIsLoading(true)
        return call('office/providers/activate-link-exchange-widget/' + providerId, {
            method: 'post',
        })
            .then(() => {
                dispatch(fetchProvider(providerId, true))
                setResponse('Link exchange is activated')
            }, setResponse)
            .finally(() => {
                setIsLoading(false)
            })
    }
    const deActivate = () => {
        setIsLoading(true)
        return call('office/providers/de-activate-link-exchange-widget/' + providerId, {
            method: 'post',
        })
            .then(() => {
                dispatch(fetchProvider(providerId, true))
                dispatch({ type: 'INVALIDATE_LOGS' })
                setResponse('Link exchange is de-activated')
            }, setResponse)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return {
        hasWidget,
        isActive: link_exchange_widget && link_exchange_widget.active,
        linkExchangeWidget: link_exchange_widget || {},
        activate,
        deActivate,
        response,
        isLoading,
        closeModal: () => setResponse(null),
    }
}

const LinkExchangeWidget = ({ providerId }) => {
    const {
        isActive,
        hasWidget,
        linkExchangeWidget,
        activate,
        deActivate,
        isLoading,
        response,
        closeModal,
    } = useLinkExchangeWidget(providerId)
    return (
        <div className={style.root}>
            <div className={style.row}>
                {isActive && (
                    <div className={style.green}>
                        <b>Website link enabled</b>
                    </div>
                )}
                <SafeButton
                    textButton
                    action={isActive ? deActivate : activate}
                    disabled={isLoading}
                    buttonText={
                        isLoading ? (
                            <LoadingDots />
                        ) : isActive ? (
                            'De-activate'
                        ) : (
                            'Activate link exchange (show website link)'
                        )
                    }
                />
            </div>
            {hasWidget && (
                <div className={style.stats}>
                    <b>Widget last seen: </b> {formatRelative(linkExchangeWidget.widget_last_seen)}
                    <Divider />
                    <small>
                        Widget first seen: {formatRelative(linkExchangeWidget.widget_first_seen)}{' '}
                        (counter: {linkExchangeWidget.visit_count})
                    </small>{' '}
                    <Icon name="explore" fontSize={16} title={linkExchangeWidget.widget_location} />
                </div>
            )}
            {!hasWidget && isActive && <small>Widget not installed</small>}
            <Toggle title="logs" showIcon dontMountChildrenUntilOpen>
                <div className={style.logs}>
                    <LogsList
                        filterId={`pro_${providerId}_link_exchange`}
                        hideExport
                        options={{
                            providerIds: [providerId],
                            logTypes: ['link_exchange'],
                            pageSize: 10,
                        }}
                    />
                </div>
            </Toggle>
            <Modal open={response} close={closeModal}>
                {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
            </Modal>
        </div>
    )
}

export default LinkExchangeWidget
