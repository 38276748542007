import { APP, Icon, Section } from '@advanza/advanza_generic'
import { FileDropZone } from '@advanza/input'
import PropTypes from 'prop-types'
import React from 'react'
import ReactCropLoader from './ReactCropLoader'

const makeAspectCrop = require('react-image-crop').makeAspectCrop
const getPixelCrop = require('react-image-crop').getPixelCrop

class AvatarEdit extends React.Component {
    constructor(props) {
        super(props)
        this.onDropFiles = this.onDropFiles.bind(this)
        this.getUrl = this.getUrl.bind(this)
        this.onDone = this.onDone.bind(this)
        this.getCroppedImg = this.getCroppedImg.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onImageLoaded = this.onImageLoaded.bind(this)
        this.state = {
            cropMode: false,
            pixelCrop: false,
        }
    }

    onDropFiles(files) {
        const { onChangeImage } = this.props
        const Reader = new FileReader()
        Reader.onloadend = (e) => {
            const { result } = e.target
            onChangeImage({
                preview: files[0].preview,
                base64: result.substr(result.indexOf(',') + 1),
                filename: files[0].name,
            })
            this.setState({ cropMode: true })
        }
        Reader.readAsDataURL(files[0])
    }

    getCroppedImg() {
        const { pixelCrop: crop } = this.state
        const canvas = document.createElement('canvas')
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(
            this.Image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                blob.name = '123'
                resolve(blob)
            }, 'image/jpeg')
        })
    }

    getUrl() {
        const { user, file } = this.props
        const { cropMode } = this.state
        if (!cropMode && file && file.croppedPreview) {
            return file.croppedPreview
        }
        if (file && file.preview) {
            return file.preview
        } else if (user.avatar_filename) {
            return APP.S3_PUBLIC() + `users/${user.id}/${user.avatar_filename}`
        } else {
            return false
        }
    }

    onDone() {
        const { onChangeImage } = this.props
        this.getCroppedImg().then((blob) => {
            onChangeImage({ croppedPreview: URL.createObjectURL(blob) })
            this.setState({ cropMode: false })
        })
    }

    onChange(crop, pixelCrop) {
        const { onCrop } = this.props
        onCrop(crop)
        this.setState({ pixelCrop })
    }

    onImageLoaded(image) {
        this.Image = image
        const { onCrop } = this.props
        const crop = makeAspectCrop(
            { aspect: 1, height: 100, x: 0, y: 0 },
            image.naturalWidth / image.naturalHeight
        )
        onCrop(crop)
        const pixelCrop = getPixelCrop(image, crop)
        this.setState({ pixelCrop })
    }

    render() {
        const { file, crop, size = 75, onFilesRejected } = this.props
        const url = this.getUrl()
        const { cropMode } = this.state
        const imgStyle = {
            background: `url(${url}) no-repeat center center /cover`,
            width: size,
            height: size,
            borderRadius: 7,
            display: 'flex',
            backgroundColor: 'rgba(0,0,0,0.1)',
            cursor: 'pointer',
        }

        return (
            <div>
                {file && cropMode && (
                    <ReactCropLoader
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onChange={this.onChange}
                        src={url}
                    />
                )}
                {!cropMode && (
                    <FileDropZone
                        style={imgStyle}
                        allowedTypes={['image']}
                        multiple={false}
                        maxFiles={1}
                        onFilesAccepted={this.onDropFiles}
                        onFilesRejected={onFilesRejected}>
                        {url ? (
                            <div className="dropzone-image" style={imgStyle}>
                                <Icon>add_a_photo</Icon>
                            </div>
                        ) : (
                            <button className="plain">+ Add avatar</button>
                        )}
                    </FileDropZone>
                )}

                {file && (
                    <Section>
                        <button
                            className="plain h3 "
                            onClick={
                                cropMode ? this.onDone : () => this.setState({ cropMode: true })
                            }>
                            <Icon> {cropMode ? 'check' : 'crop'}</Icon>
                            {cropMode ? 'done' : 'crop'}
                        </button>
                    </Section>
                )}
            </div>
        )
    }
}

AvatarEdit.propTypes = {
    file: PropTypes.object,
    onChangeImage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default AvatarEdit
