import React from 'react'
import { connect } from 'react-redux'
import { deleteStatusLabel, fetchSalesLead, setSalesLeadStatus } from '../actions/salesLeads'
import { fetchService } from '../actions/services'

function mapStateToProps(state, props) {
    const { entities, isFetching } = state.salesLeads

    const { providers, ...providerEntities } = state.providers.entities
    const { requests } = state.requests.entities
    const { services } = state.services.entities

    const { salesLeads, salesLeadsContacts } = entities

    const lead = salesLeads && salesLeads[props.id]
    const service = lead && services && services[lead.service_id]
    const request = lead && requests && requests[lead.request_id]
    const provider = lead && providers && providers[lead.service_provider_id]
    const salesLeadContact =
        lead && salesLeadsContacts && salesLeadsContacts[lead.sales_leads_contact_id]

    const isLoaded = request || provider || salesLeadContact

    return {
        lead,
        service,
        provider,
        providerEntities,
        salesLeadContact,
        request,
        isFetching,
        isLoaded,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchSalesLead: (id) => dispatch(fetchSalesLead(id)),
        fetchService: (id) => dispatch(fetchService(id)),
        setStatus: (id, statusId) => dispatch(setSalesLeadStatus(id, statusId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
