import PropTypes from 'prop-types'
import React, {Component} from "react"
import ReactDOM from 'react-dom'
import {Link} from "react-router-dom"
import {Icon} from "../src/Icon"

class Toggle extends Component {
    transitionDuration = 100

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.bodyClick = this.bodyClick.bind(this)

        this.state = {open: props.open}
    }

    bodyClick(e) {
        const {allowClickOutside} = this.props
        if (allowClickOutside || !this.containerEl) {
            return
        }
        
        const containerNode = ReactDOM.findDOMNode(this.containerEl)
        if (e.target !== containerNode && !containerNode.contains(e.target) && this.state.open) {
            this.toggle()
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.bodyClick)
        document.addEventListener('touchend', this.bodyClick)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.bodyClick)
        document.removeEventListener('touchend', this.bodyClick)
    }

    componentDidUpdate(prevProps) {
        const {open, triggerReCalc} = this.props
        if ((open === false || open === true) && open !== prevProps.open) {
            this.setOpen(open)
        }

        if (triggerReCalc && prevProps.triggerReCalc !== triggerReCalc) {
            this.forceUpdate()
        }
    }

    toggle() {
        if (this.state.inTrans) {
            return
        }
        this.setOpen(!this.state.open)
        const {onClick} = this.props
        onClick && onClick()
    }

    setOpen(open) {
        this.setState({open: open, inTrans: true})
        setTimeout(() => this.setState({inTrans: false}), this.transitionDuration)
    }

    render() {
        const {title, children, bgColor,bgColorOpen, url, icon, className} = this.props
        const {open, inTrans} = this.state
        const toggleClass = [
            'toggle',
            open ? 'open' : 'closed',
            inTrans ? 'in-trans' : '',
            bgColor ? `bg-${bgColor}` : '',
            open && bgColorOpen ? `bg-${bgColorOpen}` : '',
            className || ""
        ].join(' ')

        const toggle = <div className={toggleClass}
                            onClick={this.toggle}>
            <div className="title row nm v-center justify">
                {title}
                <div className={`toggle-icon ${open ? ' rotate' : ''}`}>
                    {icon ? icon(open, inTrans) :<Icon>arrow_forward_ios</Icon>}
                </div>
            </div>
        </div>
        return (
            <div ref={el => this.containerEl = el} className="toggle-container">
                {url ? <Link to={url} replace>{toggle}</Link> : toggle}
                <div className="body" style={{
                    display : 'block',
                    height  : open ? this.bodyEl && this.bodyEl.clientHeight : '0px',
                    overflow: 'hidden'
                }}>
                    <div ref={el => this.bodyEl = el}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

Toggle.propTypes = {
  allowClickOutside: PropTypes.bool,
  bgColor: PropTypes.string,
  bgColorOpen: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.any.isRequired,
  triggerReCalc: PropTypes.any,
  url: PropTypes.string
}

export default Toggle