import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    REQUEST_PAUSE_REASONS,
    RECEIVE_PAUSE_REASONS,
} from 'actions/pauseReasons'

const update = getUpdater()

const handlers = {
    [REQUEST_PAUSE_REASONS]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [RECEIVE_PAUSE_REASONS]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            hasFetched: { $set: true },
            entities: { $deepMerge: action.entities },
        }),
}

const initialState = {
    isFetching: false,
    hasFetched: false,
    entities: {
        pauseReasons: {},
    },
    result: {},
}

export default createReducer(initialState, handlers)
