import { Select } from '@advanza/advanza_generic'
import ServiceOptionGroupsContainer from 'containers/ServiceOptionGroupsContainer'
import React from 'react'
import PropTypes from 'prop-types'

class OptionGroupPicker extends React.Component {
    componentDidMount() {
        const { questionId, fetchOptionGroups } = this.props
        if (typeof questionId !== 'string') {
            fetchOptionGroups(questionId)
        }
    }

    render() {
        const { value, optionGroups, ...rest } = this.props
        return (
            <Select
                className="select-wide"
                options={optionGroups.map(({ option_group_id, name }) => {
                    return {
                        title: name,
                        value: option_group_id,
                    }
                })}
                value={value}
                {...rest}
            />
        )
    }
}

OptionGroupPicker.propTypes = {}

export default ServiceOptionGroupsContainer(OptionGroupPicker)
