import { call } from '@advanza/api'
import { localToUTC } from 'date'
import reviewsSchema from '../schemes/reviewsSchema'
import { changeEntity } from './entities'
import { _changeFilter, _fetchItems } from './lists'

export const REVIEWS_RECEIVE_LIST = 'REVIEWS_RECEIVE_LIST'
export const REVIEWS_CHANGE_FILTER = 'REVIEWS_CHANGE_FILTER'
export const REVIEWS_REQUEST = 'REVIEWS_REQUEST'

export function fetchReviews(filterId) {
    return _fetchItems(filterId, {
        url: 'office/reviews/get-list',
        schema: reviewsSchema,
        requestActionName: REVIEWS_REQUEST,
        receiveActionName: REVIEWS_RECEIVE_LIST,
        reducerKey: 'reviews',
    })
}

export function changeReviewsFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'reviews',
        fetchFunc: fetchReviews,
        actionType: REVIEWS_CHANGE_FILTER,
    })
}

export function saveReview(reviewId) {
    return function (dispatch, getState) {
        const review = getState().reviews.entities.reviews[reviewId]
        const isNew = typeof reviewId === 'string'
        return call(`office/reviews/save/${isNew ? 0 : reviewId}`, { json: review }).then(
            (response) => {
                if (isNew) {
                    dispatch({ type: 'INVALIDATE_REVIEWS' })
                }
            }
        )
    }
}

export function addReview(options, filterId) {
    return function (dispatch) {
        const newReviewId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                diff: {
                    review_id: newReviewId,
                    _open: true,
                    created: localToUTC(new Date()),
                    status: 1,
                    ...options,
                },
                key: newReviewId,
                filterId,
                name: 'reviews',
                store: 'reviews',
            })
        )
    }
}

function deleteReview(reviewId) {
    return function (dispatch) {
        const isNew = typeof reviewId === 'string'
        const removeFromStore = () => {
            dispatch(
                changeEntity({ store: 'reviews', name: 'reviews', remove: true, key: reviewId })
            )
        }
        if (isNew) {
            return removeFromStore()
        }
        return call(`office/reviews/delete/${reviewId}`, { method: 'post' }).then(removeFromStore)
    }
}
export function deleteReviewSelection(filterId) {
    return function (dispatch, getState) {
        const selection = getState().reviews.filters[filterId].selection
        const promises = selection.map((reviewId) => dispatch(deleteReview(reviewId)))
        return Promise.all(promises).then(() => {
            dispatch(changeReviewsFilter(filterId, { selection: [] }))
        })
    }
}

export function generateName(options) {
    return call(`office/misc/generate-name`, { query: options })
}
