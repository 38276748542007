import { Divider } from '@advanza/ui'
import React from 'react'
import ProfileIncentiveLabel from './ProfileIncentiveLabel'
import ProfileLabelsFilter from './ProfileLabelsFilter'
import ProfileLabelsList from './ProfileLabelsList'
import ProfileLabelsPicker from './ProfileLabelsPicker'

const ProfileLabels = ({ providerId }) => {
    const filterIdAssociations = `p_${providerId}`

    return (
        <div>
            <ProfileLabelsFilter
                filterId={filterIdAssociations}
                defaultOptions={{
                    providerIds: [providerId],
                    pageSize: 5,
                }}
            />
            <ProfileLabelsList filterId={filterIdAssociations} />
            <Divider m />
            <ProfileLabelsPicker filterId={filterIdAssociations} providerId={providerId} />
            <ProfileIncentiveLabel providerId={providerId} />
        </div>
    )
}

export default ProfileLabels
