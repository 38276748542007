import React, {useState} from 'react'
import {call} from '@advanza/api'
import AutoRow from 'components/misc/AutoRow'
import {Button, Icon, LoadingDots, Divider} from '@advanza/ui'
import Modal from '@advanza/modal'
import LicencesSelect from "components/licences/LicencesSelect";

function useExportProvidersByLicence() {
    const [isLoading, setIsLoading] = useState(false)

    const callExportProvidersByLicence = (options = {}) => {
        let queryOptions = {
            licence_ids: options.licence_ids.map(i => i.licence_id)
        }
        console.log(queryOptions, options)
        setIsLoading(true)
        return call('office/providers/export-providers-by-licence', {query: {...queryOptions}})
            .finally(() => setIsLoading(false))
    }

    return {
        isLoading,
        callExportProvidersByLicence,
    }
}

const ExportProvidersByLicence = ({}) => {
    const {isLoading, callExportProvidersByLicence} = useExportProvidersByLicence()
    const [openModal, setOpenModal] = useState(false)

    const [selectLicences, setSelectLicences] = useState([])

    return (
        <div>
            <AutoRow>
                <Button onClick={() => setOpenModal(true)}>
                    Create export for specific licence <Icon name="forum"/>
                </Button>
            </AutoRow>
            <Modal close={() => setOpenModal(false)} open={openModal} zIndex={102}>
                <h3>Export providers by licence</h3>
                <p>
                    <LicencesSelect onValueChange={setSelectLicences} value={selectLicences}/>
                </p>
                <Divider xxxl/>
                <Divider xxxl/>
                <Divider xxxl/>
                <AutoRow>
                    <Button
                        disabled={isLoading}
                        onClick={() =>
                            callExportProvidersByLicence({licence_ids: selectLicences}).then(() => setOpenModal(false))
                        }>
                        {isLoading ? <LoadingDots/> : <>Export</>}
                    </Button>
                </AutoRow>
            </Modal>
        </div>
    )
}

export default ExportProvidersByLicence
