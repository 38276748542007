import { schema } from 'normalizr'

const filesSchema = new schema.Entity(
    'files',
    {},
    {
        idAttribute: 'file_id',
    }
)

export default [filesSchema]
