import { Row, Col } from '@advanza/grid'
import { Divider, Button, LoadingDots } from '@advanza/ui'
import { saveRegionContent } from 'actions/regions'
import { useChangeEntity } from 'hooks/entityHooks'
import React from 'react'
import { useDispatch } from 'react-redux'

const RegionEntity = ({ id }) => {
    const dispatch = useDispatch()
    const fields = {
        content: {
            type: 'text',
            textArea: true,
        },
    }
    const { entity, renderInput, onSaveEntity } = useChangeEntity(
        {
            store: 'regions',
            name: 'regionContent',
            entityId: id,
            saveFunc: saveRegionContent,
        },
        fields
    )
    if (!id) {
        return null
    }

    return (
        <div>
            {renderInput('content')}
            <Divider />
            <Row end="xs">
                <Col x>
                    <Button disabled={entity._saving} onClick={onSaveEntity}>
                        {entity._saving ? <LoadingDots color="#fff" /> : 'Save'}
                    </Button>{' '}
                </Col>
            </Row>
        </div>
    )
}

export default RegionEntity
