import { S3_PUBLIC } from '@advanza/constants'
import { Col, Row } from '@advanza/grid'
import { Button, Divider, HoverText, Icon, LoadingDots, PreIcon } from '@advanza/ui'
import CalendlyOverview from 'components/providers/CalendlyOverview'
import ProspectLabel from 'components/providers/ProspectLabel'
import ProviderManualPause from 'components/providers/ProviderManualPause'
import { getActiveStatus } from 'components/providers/_providerActiveStatus'
import auroraStyle from 'css/aurora.module.css'
import { useAdminUser } from 'hooks/adminUsersHooks'
import { useIsTest } from 'hooks/miscHooks'
import { useProvider, useProviderStatsSettings } from 'hooks/providerHooks'
import { Fragment } from 'react'
import style from './providerStatus.module.css'

const ProviderStatus = ({ providerId, glowing = false }) => {
    const isTest = useIsTest()
    const { provider, user } = useProvider(providerId)
    const { onRemoveSlowLabel, onResetFreeTrialDayLimit, isSaving } =
        useProviderStatsSettings(providerId)
    const showPaymentInfoWarning = !provider.payment_info && provider.isSigned
    const activeStatus = getActiveStatus(provider)
    const isSlow = provider.slow_response
    const onboardingOwnerLabel = provider.office_labels.find(
        ({ type }) => type === 'onboarding_owner'
    )
    const { user: onboardingOwnerUser } = useAdminUser(
        ((onboardingOwnerLabel || {})._joinData || {}).user_id
    )

    return (
        <div>
            <Row style={{ flexWrap: 'nowrap' }}>
                <Col x>
                    <div className={glowing && auroraStyle.aurora} style={{ borderRadius: 8 }}>
                        <div className={style.avatar}>
                            <div
                                className={style.img}
                                style={{
                                    background: `url(${S3_PUBLIC()}users/${user.id}/${
                                        user.avatar_filename
                                    }) no-repeat center center /cover`,
                                }}
                            />
                            <div
                                className={style.icon}
                                style={{ backgroundColor: activeStatus.backgroundColor }}>
                                <Icon name={activeStatus.icon} white />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col x>
                    <h3>
                        {provider.business_name}{' '}
                        {provider.claimed && <Icon name="verified_user" primColor />}
                        {(provider.bans || []).length > 0 && (
                            <HoverText trigger={<Icon name="coronavirus" red />}>
                                Quarantined
                            </HoverText>
                        )}
                        {provider.responseTimeP80 > 24 && (
                            <Icon
                                title={
                                    'response time of ' + provider.responseTimeP80 + ' hours (p80)'
                                }
                                style={{ color: 'cornflowerblue' }}
                                name="elderly_woman"
                            />
                        )}
                    </h3>
                    <PreIcon icon="place" fontSize={18}>
                        {provider.cityName}
                    </PreIcon>
                    <div style={{ color: activeStatus.color }}>{activeStatus.title}</div>
                    {provider.isSigned && <ProviderManualPause providerId={providerId} />}
                    {showPaymentInfoWarning && (
                        <Fragment>
                            <Divider />
                            <div style={{ color: 'red' }}>No payment info!</div>
                        </Fragment>
                    )}
                    {isTest && provider.isFreeTrial && provider.active_free_trial.day_limit_reached && (
                        <Button name="link" disabled={isSaving} onClick={onResetFreeTrialDayLimit}>
                            {isSaving ? <LoadingDots /> : ' Reset free trial day limit'}
                        </Button>
                    )}
                    {isSlow && (
                        <Row middle="xs">
                            <Col x>
                                <i style={{ fontSize: 21 }}>🐢</i>
                            </Col>
                            <Col x>Slow response </Col>
                            <Col x>
                                {' '}
                                <Button name="link" disabled={isSaving} onClick={onRemoveSlowLabel}>
                                    {isSaving ? <LoadingDots /> : ' Reset'}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <CalendlyOverview providerId={provider.service_provider_id} />
                    <Divider s />
                    <ProspectLabel
                        providerId={provider.service_provider_id}
                        fontSize={18}
                        emptyValue=""
                    />
                </Col>
            </Row>
            {onboardingOwnerUser && (
                <>
                    <Divider s />
                    <h6>
                        Onboarding owner: {onboardingOwnerUser.first_name}{' '}
                        {onboardingOwnerUser.last_name}
                    </h6>
                </>
            )}
        </div>
    )
}

export default ProviderStatus
