import { schema } from 'normalizr'

export default [
    new schema.Entity(
        'invoices',
        {
            charges: [new schema.Entity('charges', {}, { idAttribute: 'charge_id' })],
        },
        {
            idAttribute: 'invoice_id',
        }
    ),
]
