import { call } from '@advanza/api'
import { S3_PUBLIC } from '@advanza/constants'
import { changeEntity } from '@advanza/redux_entity'
import { addProjectPhoto, saveFileEntity } from 'actions/providers'
import { useWindowSize } from 'hooks/miscHooks'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useProfileProjects(providerId) {
    const dispatch = useDispatch()
    const { isFetching, entities = {} } = useSelector((state) => state.providers)
    const { providers = {}, projects = {} } = entities
    const provider = providers[providerId] || {}
    const projectIds = provider.profile_projects || []
    const orderedProjectIds =
        (projects &&
            projectIds &&
            projectIds
                .map((id) => ({
                    id,
                    weight: (projects[id] && projects[id].weight) || 0,
                }))
                .sort((a, b) => b.weight - a.weight)
                .map((project) => project.id)) ||
        []

    const onSaveProjectOrder = (projectId, newWeight) => {
        const data = { weight: newWeight }
        return call(`office/providers/save-project/${projectId}`, {
            json: data,
        }).then(() => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'projects',
                    key: projectId,
                    diff: data,
                    historyCleanUp: true,
                })
            )
        })
    }

    return {
        provider,
        projectIds,
        orderedProjectIds,
        isFetching,
        onSaveProjectOrder,
    }
}

export function useProfileProjectsItem(projectId) {
    const dispatch = useDispatch()
    const { projects = {}, files = {} } = useSelector((state) => state.providers.entities)
    const project = projects[projectId]
    const providerId = project && project.service_provider_id
    const { provider, isFetching, projectIds } = useProfileProjects(providerId)
    const isNew = project && typeof project.profile_project_id === 'string'
    const isTouched = project && project._isTouched
    const orderedFileIds =
        (project &&
            project.files &&
            project.files
                .map((id) => ({ id, weight: files[id].weight }))
                .sort((a, b) => b.weight - a.weight)
                .map((item) => item.id)) ||
        []

    const getYearOptions = () => {
        const date = new Date()
        const currentYear = date.getFullYear()
        const nrRecentYears = 11
        // create array with recent years
        const options = [...Array(nrRecentYears).keys()].map((yearsAgo) => {
            const year = currentYear - yearsAgo
            return {
                name: year,
                value: year,
            }
        })

        // add project.year if this is not in the array anymore
        if (project.year <= currentYear - nrRecentYears) {
            options.push({
                name: project.year,
                value: project.year,
            })
        }
        return options
    }

    const onDiscard = () => {
        let backup = {}
        Object.keys(project._beforeSave)
            .filter(
                (name) => name.charAt(0) !== '_' && !['profile_project_id', 'files'].includes(name)
            )
            .forEach((key) => {
                backup[key] = project._beforeSave[key]
            })

        // delete files cannot be retrieved, so only remove new files
        const files = project.files.filter((fileId) => typeof fileId !== 'string')

        if (files.length === 0 && backup.active) {
            // if all files were deleted, then the project should be hidden
            backup.active = false
        }

        dispatch(
            changeEntity({
                store: 'providers',
                name: 'projects',
                key: projectId,
                diff: {
                    ...backup,
                    files,
                },
                dontTouch: true,
            })
        )
    }

    const [isDeleting, setIsDeleting] = useState(false)
    const onDelete = () => {
        setIsDeleting(true)
        // if new project: projectId in the store and the database are different
        const projectIdDatabase = project.profile_project_id
        return call(`office/providers/delete-project/${projectIdDatabase}`, {
            method: 'post',
        }).then(() => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: providerId,
                    diff: {
                        profile_projects: projectIds.filter((id) => id !== projectId),
                    },
                    preservePreviousTouch: true,
                })
            )
            setIsDeleting(false)
            return Promise.resolve()
        })
    }

    let validateProject = () => {}
    const getValidator = (validate) => {
        validateProject = validate
    }
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState(null)
    const onSave = () => {
        // validate new project
        const { isValid } = validateProject()

        if (isValid) {
            setError(null)
            setIsSaving(true)
            const project = projects[projectId]
            const isNew = typeof projectId === 'string'

            const projectData = { ...project }
            delete projectData.files
            if (isNew) {
                projectData.active = false // activate new projects after successful photo uploads
            }
            return call(`office/providers/save-project/${isNew ? 0 : projectId}`, {
                json: projectData,
            }).then((response) => {
                const { profile_project_id: databaseProjectId } = response.project

                // photos that need to be saved
                const unsavedProjectPhotos = project.files
                    .filter((fileId) => files[fileId]._isTouched)
                    .map((fileId) => ({
                        fileId,
                        projectId: databaseProjectId,
                    }))
                const activateProjectAfterPhotoUpload = isNew && project.active

                if (isNew) {
                    // add the project entity with the real projectId to the redux store
                    dispatch(
                        changeEntity({
                            store: 'providers',
                            name: 'projects',
                            key: projectId,
                            newKey: databaseProjectId,
                            diff: {
                                profile_project_id: databaseProjectId,
                            },
                            dontTouch: true,
                        })
                    )

                    // add new project to provider entity
                    dispatch(
                        changeEntity({
                            store: 'providers',
                            name: 'providers',
                            key: providerId,
                            diff: {
                                profile_projects: projectIds.concat([databaseProjectId]),
                            },
                            preservePreviousTouch: true,
                        })
                    )
                }

                const onSavingPhotosDone = () => {
                    setIsSaving(false)
                    untouchProject(databaseProjectId)
                    return Promise.resolve()
                }

                if (unsavedProjectPhotos.length === 0) {
                    // no unsaved photos found
                    return onSavingPhotosDone()
                }

                return Promise.all([
                    ...unsavedProjectPhotos.map(({ fileId, projectId }) =>
                        dispatch(
                            saveFileEntity(fileId, {
                                projectId,
                                activateProjectAfterPhotoUpload,
                            })
                        )
                    ),
                ])
                    .then(() => {
                        return onSavingPhotosDone()
                    })
                    .catch((errorMsg) => {
                        setIsSaving(false)
                        setError(errorMsg)
                        return Promise.reject()
                    })
            })
        } else {
            return Promise.reject()
        }
    }

    const untouchProject = (projectId) => {
        dispatch(
            changeEntity({
                store: 'profile',
                name: 'projects',
                key: projectId,
                diff: { _beforeSave: projects[projectId] },
                dontTouch: true,
            })
        )
    }

    const addNewFile = (options) => dispatch(addProjectPhoto(projectId, options))

    const onChangeFileOrder = () => {
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'projects',
                key: projectId,
                diff: {
                    _isTouched: true,
                },
            })
        )
    }

    return {
        project,
        getYearOptions,
        isNew,
        isTouched,
        onDiscard,
        isDeleting,
        onDelete,
        isSaving,
        onSave,
        getValidator,
        error,
        files,
        orderedFileIds,
        nrPhotos: orderedFileIds.length,
        addNewFile,
        onChangeFileOrder,
        disableButtons: isFetching || isSaving || isDeleting,
    }
}

export function useProfileProjectsPhoto(fileId, projectId) {
    const dispatch = useDispatch()
    const { files = {}, projects = {} } = useSelector((state) => state.providers.entities)
    const file = files[fileId]

    const removeFromStore = (options = {}) => {
        const project = projects[projectId] || {}
        const projectFilesIds = project.files || []
        dispatch(
            changeEntity({
                store: 'providers',
                name: 'projects',
                key: projectId,
                diff: {
                    ...(options.hasOwnProperty('projectIsActive')
                        ? {
                              active: options.projectIsActive,
                          }
                        : {}),
                    files: projectFilesIds.filter((fileIdStore) => fileIdStore !== fileId),
                },
                preservePreviousTouch: true,
            })
        )
    }

    const getFileUrl = (size = 'l') => {
        if (file.file_key) {
            const parts = file.file_key.split('/')
            const fileName = parts.pop()
            return S3_PUBLIC() + parts.join('/') + `/${size}_` + fileName
        } else {
            return file.previewUrl
        }
    }

    const { width } = useWindowSize()
    const getImgStyle = (size = 's') => {
        const bgHeight = width >= 1024 ? 160 : 108
        const bgSize = file.context === 'logo' ? 'contain' : 'cover'
        return {
            background: `url(${getFileUrl(size)}) no-repeat center center /${bgSize}`,
            height: bgHeight,
            width: bgHeight,
        }
    }

    const onDelete = () => {
        if (typeof file.file_id === 'string') {
            removeFromStore()
        } else {
            return call(`office/files/delete/${file.file_id}/${projectId}`, {
                method: 'post',
            }).then((response) => {
                removeFromStore(response)
            })
        }
    }

    return {
        entity: file,
        getFileUrl,
        getImgStyle,
        onDelete,
    }
}
