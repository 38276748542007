export const Colors = [
    '#dce9fe',
    '#d8d6ff',
    '#ffd6d6',
    '#fcdee7',
    '#ffd5b3',
    '#b8ffad',
    '#dee3e8',
    '#f7f5a1',
    '#f7e2fd',
    '#bde8ff',
]

export const ColorsBright = [
    '#5eb933',
    '#4086fa',
    '#ff8c28',
    '#ee386d',
    '#d380b6',
    '#69c8c4',
    '#FF5252',
    '#a68a00',
    '#ffcc00',
]
