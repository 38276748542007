import { Ellipsis, Icon, PreIcon } from '@advanza/ui'
import TableList from 'components/TableList'
import Balloon from 'components/ui/Balloon'
import FormattedTime from 'components/ui/FormattedTime'
import RequestListContainer from 'containers/RequestListContainer'
import { getDate } from 'date'
import { differenceInDays } from 'date-fns'
import { getRequestStatusInfo } from 'hooks/requestsHooks'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

const ClosedLoopRequestsList = ({ requestEntities, services, selectable, ...rest }) => {
    const today = new Date()
    const { requests, users } = requestEntities
    const definitions = {
        id: { name: 'id' },
        time: { name: 'created' },
        daysAgo: { name: 'days ago' },
        nrDemandReads: {
            name: (
                <Balloon content="Unique match view demand user">
                    <Icon name="person" />
                </Balloon>
            ),
        },
        name: { name: 'user name' },
        service: { name: 'service' },
        subject: { name: 'subject' },
        status: { name: 'status' },
        nrMatches: {
            name: <Balloon content="Number of matches">Matches</Balloon>,
        },
        matches: {
            name: (
                <Balloon content="Match was first choice / Match via wizard / Match via pro dashboard">
                    FstC/Wzd/Dshb
                </Balloon>
            ),
        },
        views: {
            name: (
                <Balloon content="Unique match view pro user">
                    <Icon name="login" />
                </Balloon>
            ),
        },
        nrReminders: {
            name: (
                <Balloon content="Pro reminders">
                    <Icon name="notifications_active" />
                </Balloon>
            ),
        },
        nrMessagesTotal: {
            name: <Balloon content="Total number of messages">messages</Balloon>,
        },
        messages: {
            name: <Balloon content="Has messages from pro, demand, bot">Has Pro/Dmd/Bot</Balloon>,
        },
        nrHasMadeContact: {
            name: (
                <Balloon content="How many unique pros / demands have made contact via messages and/or phone views">
                    <Icon name="waving_hand" /> Pro/Dmd
                </Balloon>
            ),
        },
        nrMessages: {
            name: (
                <Balloon content="How many unique pros / demands have sent a message">
                    <Icon name="message" /> Pro/Dmd
                </Balloon>
            ),
        },
        nrPhoneViews: {
            name: (
                <Balloon content="How many unique pros / demands have made a phone view">
                    <Icon name="phone_enabled" /> Pro/Dmd
                </Balloon>
            ),
        },
        closed: { name: 'Total time' },
        review: { name: 'Review' },
        reclaims: {
            name: (
                <Balloon content="Reclaims">
                    <Icon name="replay" />
                </Balloon>
            ),
        },
    }

    const renderRow = (id) => {
        const request = requests[id]

        return {
            url: `/statistics/closed-loop-requests/closed-loop/request/${id}`,
            cols: renderColumns(request),
            style: { color: `${request.status === 'DELETED' ? '#aaa' : 'inherit'}` },
            id,
        }
    }

    const renderColumns = (request) => {
        const service = (services && services[request.service_id]) || {}
        const user = users[request.user_id]
        const extraMatchesFromWizard =
            request.nrMatches - request.nrFirstChoice - request.nrFromDashboard
        const { statusInfo, hexColor } = getRequestStatusInfo(request.status)

        return [
            <small className="c-grey">#{request.request_id}</small>,
            <small className="c-grey to-right">
                <FormattedTime date={request.created} />
            </small>,
            <small className="c-grey to-right">
                {differenceInDays(today, getDate(request.created))} days
            </small>,
            renderValueWithIcon(request.nrDemandReads, 'sentiment_satisfied', 'green'),
            <Ellipsis style={{ maxWidth: '240px' }}>{user.username}</Ellipsis>,
            service.name,
            request.subject,
            <span style={{ color: hexColor() }}>{request.status}</span>,
            request.nrMatches,
            <span>
                {request.nrFirstChoice} - {extraMatchesFromWizard} - {request.nrFromDashboard}
            </span>,
            renderValueWithIcon(request.nrProReads, 'login'),
            renderValueWithIcon(request.nrReminders, 'notifications_active'),
            request.nrMessagesTotal,
            <span>
                {request.nrPro} - {request.nrDemand} - {request.nrBot}
            </span>,
            <span>
                {request.nrProsHasMadeContact} - {request.nrDemandHasMadeContact}
            </span>,
            <span>
                {request.nrProMessages} - {request.nrDemandMessages}
            </span>,
            <span>
                {request.nrProPhoneViews} - {request.nrDemandPhoneViews}
            </span>,
            <span style={{ display: 'inline-block', minWidth: '76px' }}>
                <PreIcon
                    name={statusInfo.icon}
                    className={request.closedRequest === '0' ? 'grey' : statusInfo.color}>
                    {request.status === 'DELETED' ? (
                        'N/A'
                    ) : (
                        <Fragment>
                            {differenceInDays(
                                request.closedRequest === '1' ? getDate(request.lastUpdate) : today,
                                getDate(request.created)
                            )}{' '}
                            days
                        </Fragment>
                    )}
                </PreIcon>
            </span>,
            <span>
                {request.reviewGiven === '1' ? (
                    <PreIcon name="star">{Math.round(request.reviewRating * 10) / 10}</PreIcon>
                ) : request.reviewInvite === '1' ? (
                    <Icon name="star" />
                ) : (
                    '-'
                )}
            </span>,
            renderValueWithIcon(request.nrReclaims, 'replay'),
        ]
    }

    const renderValueWithIcon = (value, icon, color) => {
        return (
            <span
                className="center"
                style={{ display: 'inline-block', width: '100%', position: 'relative' }}>
                {value > 0 ? (
                    <Fragment>
                        <Icon name={icon} className={color || 'blue'} />
                        <small
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                bottom: '-10px',
                            }}>
                            {value}x
                        </small>
                    </Fragment>
                ) : (
                    <small>-</small>
                )}
            </span>
        )
    }

    return (
        <TableList
            {...rest}
            selectable={selectable}
            renderRow={renderRow}
            definitions={definitions}
        />
    )
}

ClosedLoopRequestsList.propTypes = {
    requestEntities: PropTypes.object,
    pages: PropTypes.any,
    filter: PropTypes.object,
    isFetching: PropTypes.bool,
    services: PropTypes.object,
}

export default RequestListContainer(ClosedLoopRequestsList)
