import { SelectCheckbox } from '@advanza/input'
import { OptionStatus } from '@advanza/types/Option'
import { Divider, LoadingDots } from '@advanza/ui'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import LinearLoader from '../LinearLoader'

/**
 * Fetch, edit & save the service area for a subscription
 *
 */
class SubscriptionOptions extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.save = this.save.bind(this)
        this.state = {
            saving: false,
            touched: false,
        }
    }

    handleChange(values) {
        if (this.state.saving) {
            return
        }
        const { replaceSubscriptionOptions, subscriptionId } = this.props

        replaceSubscriptionOptions(subscriptionId, values)
        this.setState({ touched: true })
        this.save()
    }

    save() {
        const { saveOptions, subscription } = this.props
        if (this.state.saving) {
            return
        }
        this.setState({ saving: true })
        saveOptions(subscription.subscription_id).then(
            () => {
                this.setState({ saving: false, touched: false })
            },
            () => {
                this.setState({ saving: false })
            }
        )
    }

    render() {
        const { serviceEntities, subscription } = this.props
        if (!serviceEntities) {
            return <LoadingDots />
        }
        const { questions, options: optionEntities, services } = serviceEntities
        const { saving } = this.state
        const service = services && services[subscription.service_id]

        if (!service || !service.questions) {
            return <LinearLoader inside />
        }

        return (
            <Fragment>
                {saving && <LinearLoader fixed />}
                {service &&
                    service.questions.map((questionId) => {
                        const question = questions[questionId]
                        if (!question.filterable) {
                            return null
                        }
                        const options = question.options.map((optionId) => {
                            const option = optionEntities[optionId]
                            return {
                                value: optionId,
                                disabled: option.is_hidden_default,
                                name:
                                    option.status !== OptionStatus.ACTIVE ? (
                                        <del>{option.value}</del>
                                    ) : option.is_hidden_default ? (
                                        <em style={{ pointerEvents: 'none', color: 'grey' }}>
                                            {option.value}
                                        </em>
                                    ) : (
                                        option.value
                                    ),
                            }
                        })
                        return (
                            <Fragment key={questionId}>
                                <b>{question.question}</b>
                                <Divider />
                                <SelectCheckbox
                                    options={options}
                                    onChangeValue={this.handleChange}
                                    value={subscription.subscriptions_options}
                                />
                                <Divider />
                            </Fragment>
                        )
                    })}
            </Fragment>
        )
    }
}

SubscriptionOptions.propTypes = {
    subscriptionId: PropTypes.any.isRequired,
}

export default SubscriptionOptions
