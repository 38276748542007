import Card from 'components/Card'
import RegionEntity from 'components/regions/RegionEntity'
import TableList from 'components/TableList'
import { useRegionsList } from 'hooks/regionHooks'
import React from 'react'

const definitions = {
    name: { name: 'name' },
}

function renderRow(id, entities) {
    const { regions } = entities
    return {
        cols: Object.keys(definitions).map((key) => {
            return regions[id][key]
        }),
        expandable: (
            <Card expandableStyle>
                <RegionEntity id={regions[id].regions_content} />
            </Card>
        ),
    }
}

const RegionsList = ({ filterId = 'all' }) => {
    const list = useRegionsList(filterId)
    const rows = {}
    list.currentPage.forEach((id) => {
        rows[id] = renderRow(id, list.entities)
    })
    return <TableList definitions={definitions} {...list} rows={rows} />
}

export default RegionsList
