import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    NOTE_SENT,
    NOTE_TOUCH,
    NOTES_CHANGE_FILTER,
    NOTES_DISCARD_TOUCHED,
    NOTES_INVALIDATE,
    NOTES_RECEIVE_LIST,
    NOTES_REQUEST,
} from '../actions/notes'
import { changeEntity, changeFilter, invalidateFilters, receiveList } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [NOTES_RECEIVE_LIST]: receiveList,
    [NOTES_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_NOTES: changeEntity,
    INVALIDATE_NOTES: invalidateFilters,
    [NOTES_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [NOTES_INVALIDATE]: (state) =>
        update(state, {
            didInvalidate: { $set: true },
        }),

    [NOTE_SENT]: (state, action) =>
        update(state, {
            result: {
                [action.filter.searchKey]: { [0]: { $unshift: [action.noteId] } },
            },
        }),
    [NOTE_TOUCH]: (state, action) =>
        update(state, {
            touched: action.touched ? { $push: [action.noteId] } : { $arrDel: action.noteId },
        }),
    [NOTES_DISCARD_TOUCHED]: (state, action) =>
        update(state, {
            touched: { $set: [] },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
    touched: [],
}

export default createReducer(initialState, handlers)
