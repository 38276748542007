import { InputField, SelectSimple } from '@advanza/input'
import { ScrollModal } from '@advanza/modal'
import { Button, Divider } from '@advanza/ui'
import { useAddFreeLeads } from 'components/providers/FreeLeadsButton'
import { ChangeEvent, useState } from 'react'
import style from './freeLeads.module.scss'

export const FREE_TRIAL_LEAD_TYPE = 'free_trial'

interface Props {
    providerId: number
}

const FreeTrialButton = ({ providerId }: Props) => {
    const [showInstructions, setShowInstructions] = useState<boolean>(false)
    const initialNewEntity = {
        nr_leads: 3,
        comment: '',
        free_lead_type: FREE_TRIAL_LEAD_TYPE,
    }

    const { newEntity, onChangeNewEntity, isSaving, isOpen, onOpen, onClose, onSave, errors } =
        useAddFreeLeads(providerId, initialNewEntity)

    return (
        <>
            <Button onClick={onOpen} name="text">
                Start a free trial
            </Button>
            <ScrollModal
                isOpen={isOpen}
                onCloseModal={onClose}
                heading={<div>Start a free trial</div>}
                sub={
                    <>
                        {showInstructions ? (
                            <div
                                className={style.instructions}
                                onClick={() => setShowInstructions(false)}>
                                <b>How it works:</b>
                                <ul>
                                    <li>
                                        You can activate the free trial by giving the provider
                                        between 1 and 10 free trial leads
                                    </li>
                                    <li>
                                        The provider gets access to all the premium features during
                                        the trial
                                    </li>
                                    <li>
                                        The provider has the ability to reclaim free trial leads,
                                        and get a free trial lead back if the reclaim is accepted
                                    </li>
                                    <li>
                                        If all the free trial leads are used, the profile will go on
                                        pause ("limit reached") for 15 days
                                    </li>
                                    <li>
                                        During this period it still is possible to reclaim free
                                        trial leads. The trial ends automatically after the 15 days.
                                    </li>
                                    <li>
                                        If the provider doesn't become premium during this 15 days
                                        period, the profile will be snoozed for 6 weeks.
                                    </li>
                                    <li>There will be a day limit of 1</li>
                                    <li>Phone leads and website clicks are off by default</li>
                                    <li>There won't be a signing date</li>
                                    <li>There won't be an invoice</li>
                                </ul>
                            </div>
                        ) : (
                            <Button onClick={() => setShowInstructions(true)} name="link">
                                How does it work?
                            </Button>
                        )}
                    </>
                }
                buttons={[
                    {
                        text: 'Cancel',
                        name: 'link',
                        func: onClose,
                    },
                    {
                        text: 'Start free trial',
                        func: onSave,
                        disabled: newEntity.nr_leads === 0 || isSaving,
                    },
                ]}>
                <>
                    <SelectSimple
                        name="nr_leads"
                        value={newEntity.nr_leads}
                        legend
                        placeholder="Number of free trial leads"
                        options={[...Array(10).keys()]
                            .map((id) => ++id)
                            .map((id) => ({
                                value: id,
                                name: `${id} free trial lead${id !== 1 ? 's' : ''}`,
                            }))}
                        onChange={(e) => {
                            typeof e.target.value === 'number' &&
                                onChangeNewEntity({ nr_leads: e.target.value })
                        }}
                        error={!!errors?.nr_leads}
                    />
                    <Divider m />
                    <InputField
                        name="comment"
                        value={newEntity.comment}
                        min={0}
                        max={100}
                        error={errors?.comment}
                        legend
                        placeholder="Comment (optional)"
                        optional
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeNewEntity({ [e.target.name]: e.target.value })
                        }
                    />
                </>
            </ScrollModal>
        </>
    )
}

export default FreeTrialButton
