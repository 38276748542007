import { APP, Icon, Section } from '@advanza/advanza_generic'
import FileEntityComplex from 'components/files/FileEntityComplex'
import React from 'react'
import { FormattedRelative } from 'react-intl'
import FilesListContainer from '../../containers/FilesListContainer'
import TableList from '../TableList'
import Balloon from '../ui/Balloon'

class FilesList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    getImageUrl(file, size) {
        if (file.preview) {
            return file.preview
        }
        const parts = file.file_key.split('/')
        const fileName = parts.pop()

        return APP.S3_PUBLIC() + parts.join('/') + `/${size}_` + fileName
    }

    makeImageElement(file, imageSize, size) {
        const url = this.getImageUrl(file, imageSize)
        return (
            <div
                style={{
                    maxWidth: 500,
                    maxHeight: 500,
                    width: size,
                    height: size,
                    background: `url(${url}) no-repeat center center /cover`,
                }}
            />
        )
    }

    renderColumns(id) {
        const { files } = this.props.fileEntities
        const file = files[id]
        const thumbnail =
            file.type === 'image' ? (
                <Balloon content={this.makeImageElement(file, 'l', 250)}>
                    {this.makeImageElement(file, 's', 20)}
                </Balloon>
            ) : (
                <Icon>insert_drive_file</Icon>
            )
        return [
            <small className="c-grey">#{id}</small>,
            thumbnail,
            file.context,
            <small className="c-grey">
                <FormattedRelative value={file.created || new Date()} />
            </small>,
        ]
    }

    renderRow(id) {
        const { files } = this.props.fileEntities
        return {
            cols: this.renderColumns(id),
            expandOnClick: true,
            expandable: (
                <Section x y>
                    <FileEntityComplex entityId={id} />
                </Section>
            ),
            expanded: files[id]._open,
            id,
            isLoading: files[id]._saving,
        }
    }

    definitions = {
        id: { name: '#' },
        thumbnail: { name: <Icon small>image</Icon> },
        context: { name: 'context' },
        created: { name: 'time' },
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

export default FilesListContainer(FilesList)
