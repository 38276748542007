// noinspection JSIgnoredPromiseFromCall

import { call } from '@advanza/api'
import { WEBSITE_REGEX } from '@advanza/constants'
import { InputField, Radio } from '@advanza/input'
import { Divider, Icon } from '@advanza/ui'
import { changeEntity } from 'actions/entities'
import {
    acSaveProfileBuilderWizard,
    autoSavePreselectedDescription,
    saveNonAvatarImages,
} from 'actions/profileBuilders'
import { saveUser } from 'actions/users'
import WizardPage from 'components/ui/wizard/WizardPage'
import format from 'date-fns/format'
import { useDescription } from 'hooks/descriptionHooks'
import {
    IMAGE_FILE_CONTEXTS,
    NOTHING_LABELS,
    NUM_IMAGES,
    useProfileBuilderWizard,
} from 'hooks/profileBuilderHooks'
import { PROFILE_BUILDER_REDIRECT } from 'misc/user'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import isEmail from 'validator/es/lib/isEmail'
import ImagesBar from './ImagesBar'
import PageDescription from './PageDescription'
import PageImages from './PageImages'
import PageNote from './PageNote'
import PageVisualWebsiteCheck from './PageVisualWebsiteCheck'
import style from './profileBuilderWizardPage.module.css'

const ProfileBuilderWizardPage = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const providerId = parseInt(params.id, 10)
    const profileBuilderWizardUrl = `/service/profile-builder-wizard/${providerId}`
    const history = useHistory()
    const gotToPage = (pageKey) => {
        history.push(profileBuilderWizardUrl + '/' + pageKey.replace(/_/g, '-'))
        return Promise.resolve({ moveNext: false })
    }
    const {
        provider = {},
        user = {},
        websiteWithProtocol,
        service = {},
        hasFetchedImages,
        hasFetchedDescriptions,
        getSelection,
        getImageDashSelection,
        currentContext,
        getStats,
        changeProvider,
        changeUser,
        changeSelection,
    } = useProfileBuilderWizard(providerId)
    const {
        hasFetchedAiDescriptions,
        fetchAiDescriptions,
        preselectedDescription,
        descriptionPreselectMethod,
    } = useDescription(provider)
    const [needPageDescription, setNeedPageDescription] = useState(true)
    const saveProviderLabelsRef = useRef(Promise.resolve())
    const [redirect, setRedirect] = useState(null)

    const redirectToAssignedProvider = useCallback(
        () =>
            call(
                `office/providers-builders/get-assigned-provider-id/${providerId ? providerId : ''}`
            ).then((response) => {
                if (response.assignedProviderId) {
                    setRedirect(`/service/profile-builder-wizard/${response.assignedProviderId}`)
                } else {
                    setRedirect(PROFILE_BUILDER_REDIRECT)
                }

                return { moveNext: false }
            }),
        [providerId]
    )

    useEffect(() => {
        if (!providerId) {
            redirectToAssignedProvider()
        } else {
            setRedirect(null)
        }
    }, [providerId, redirectToAssignedProvider])

    useEffect(() => {
        fetchAiDescriptions()
    }, [fetchAiDescriptions])

    useEffect(() => {
        setNeedPageDescription(
            !dispatch(
                autoSavePreselectedDescription(
                    providerId,
                    preselectedDescription,
                    descriptionPreselectMethod
                )
            )
        )
    }, [descriptionPreselectMethod, dispatch, preselectedDescription, providerId])

    if (redirect) {
        return <Redirect to={redirect} push />
    }

    if (
        !provider.service_provider_id ||
        !user.id ||
        !service.service_id ||
        !hasFetchedAiDescriptions
    ) {
        return null
    }

    const saveChained = (pageKey, onFulfilled = undefined, onRejected = undefined) =>
        (saveProviderLabelsRef.current = saveProviderLabelsRef.current.finally(() =>
            dispatch(
                acSaveProfileBuilderWizard(providerId, pageKey, true, { stats: getStats(pageKey) })
            )
                .then(onFulfilled, onRejected)
                .catch((reason) => {
                    if (reason === 403) {
                        window.location = `/login?redirect=/service/profile-builder-wizard/${providerId}/${pageKey}`
                    } else {
                        alert(
                            `${format(
                                new Date(),
                                'yyyy-MM-dd HH:mm:ss'
                            )} Profielbouwer Wizard (${providerId}, ${pageKey}) opslaan mislukt: ${JSON.stringify(
                                reason
                            )}`
                        )
                    }
                })
        ))

    const labels = provider.office_labels || []
    const suspiciousPageKeys = [
        'suspicious_website',
        'suspicious_email_match',
        'suspicious_email_match_minor',
    ]
    const suspiciousPages = {}

    suspiciousPageKeys.forEach((pageKey) => {
        if (!labels.some((label) => label.type === pageKey)) {
            return
        }

        const type = pageKey.split('_')[1]
        const { choice = '', other = '' } = getSelection(pageKey)
        const otherFound = choice === 'incorrect_other_found'

        suspiciousPages[pageKey] = {
            pageDesc: `Hoort deze ${type} bij dit bedrijf?`,
            component: (
                <div className={style.infoBlock}>
                    <div>{provider.business_name}</div>
                    <div>
                        {provider.street} {provider.street_number}, {provider.postalcode},{' '}
                        {provider.cityName}
                    </div>
                    <div>{provider.phone}</div>
                    <div>{provider.email}</div>
                    <div>
                        <a href={websiteWithProtocol} target="_blank" rel="noreferrer">
                            {provider.website}
                        </a>
                    </div>
                    <Divider l />
                    <Radio
                        options={[
                            { name: `Correcte ${type}`, value: '' },
                            {
                                name: `Incorrecte ${type}: geen andere ${type} gevonden`,
                                value: 'incorrect_none_found',
                            },
                            {
                                name: `Incorrecte ${type}: andere ${type} gevonden${
                                    otherFound ? ':' : ''
                                }`,
                                value: 'incorrect_other_found',
                            },
                        ]}
                        value={choice}
                        onChange={(e) => changeSelection(pageKey, { choice: e.target.value || '' })}
                    />
                    <Divider sm />
                    {otherFound && (
                        <InputField
                            classNames={style.input}
                            newStyle
                            value={other}
                            onChange={(e) => changeSelection(pageKey, { other: e.target.value })}
                        />
                    )}
                </div>
            ),
            validate: (pageKey) => {
                if (getSelection(pageKey).choice === 'incorrect_other_found') {
                    const other = (getSelection(pageKey).other || '').trim()

                    if (
                        (type === 'website' && !other.match(WEBSITE_REGEX)) ||
                        (type === 'email' && !isEmail(other))
                    ) {
                        return { [type]: [`Geen geldige andere ${type} ingevuld`] }
                    }
                }

                return {}
            },
            save: (pageKey) => {
                const choice = getSelection(pageKey).choice || ''
                const other = (getSelection(pageKey).other || '').trim()
                saveChained(pageKey, () => {
                    if (choice === 'incorrect_other_found' && provider[type] !== other) {
                        dispatch(
                            changeEntity({
                                store: 'providers',
                                name: 'providers',
                                key: providerId,
                                diff: {
                                    [type]: other,
                                },
                            })
                        )

                        if (type === 'email') {
                            dispatch(
                                changeEntity({
                                    store: 'providers',
                                    name: 'users',
                                    key: user.id,
                                    diff: {
                                        username: other,
                                        email: other,
                                        email_notifications: other,
                                    },
                                })
                            )
                        }
                    }

                    if (choice !== 'incorrect_none_found') {
                        changeProvider({
                            office_labels: labels.filter((label) => label.type !== pageKey),
                        })
                    }
                })

                return choice === 'incorrect_none_found' ? gotToPage('note') : Promise.resolve()
            },
        }
    })

    const needVisualWebsiteCheck = !(provider.category_keywords || [])
        .concat(service.category_keywords || [])
        .some((servicesKeyword) => {
            const keywordLower = servicesKeyword.keyword.toLowerCase()
            return (
                servicesKeyword.service_id === service.service_id &&
                ((provider.business_name || '').toLowerCase().includes(keywordLower) ||
                    (provider.website || '').toLowerCase().includes(keywordLower) ||
                    servicesKeyword?._joinData?.on_homepage ||
                    servicesKeyword?._joinData?.count > 2)
            )
        })

    const firstFailedPageKey =
        suspiciousPageKeys.find((pageKey) => labels.some((label) => label.type === pageKey)) ||
        (getSelection('visual_website_check').choice && 'visual_website_check') ||
        (getSelection('description').choice === 'website_url_invalid' && 'description') ||
        (getSelection('images').choices?.includes('website_url_invalid') && 'images')

    const pages = {
        ...suspiciousPages,
        ...(needVisualWebsiteCheck
            ? {
                  visual_website_check: {
                      pageDesc: `Is ${provider.business_name} een geschikt bedrijf voor een offerte aanvraag in rubriek ${service.name}?`,
                      component: <PageVisualWebsiteCheck providerId={providerId} />,
                      extraNextButtons: {
                          fail_website_url_invalid: {
                              text: 'Website werkt niet',
                              styleName: 'underline',
                          },
                          fail_visual_website_check: {
                              text: 'Nee',
                              styleName: 'bordered',
                          },
                      },
                      nextText: 'Ja',
                      save: (pageKey, extraNextKey) => {
                          changeSelection(pageKey, {
                              choice: extraNextKey,
                          })
                          saveChained(pageKey)
                          return extraNextKey ? gotToPage('note') : Promise.resolve()
                      },
                  },
              }
            : {}),
        description: !needPageDescription
            ? null
            : {
                  pageDesc:
                      'Stel een bedrijfsomschrijving samen. Combineer de gevonden teksten en pas de omschrijving indien nodig aan.',
                  component: <PageDescription providerId={providerId} />,
                  extraNextButtons: {
                      ...(!needVisualWebsiteCheck
                          ? {
                                website_url_invalid: {
                                    text: 'Website werkt niet',
                                    styleName: 'underline',
                                },
                            }
                          : {}),
                      no_text_on_website: {
                          text: 'Geen beschrijving gevonden',
                          styleName: 'underline',
                      },
                  },
                  isBusy: !hasFetchedDescriptions,
                  validate: (_pageKey, extraNextKey) =>
                      extraNextKey || (provider.description || '').length >= 50
                          ? {}
                          : { beschrijving: ['Beschrijving korter dan 50 karakters'] },
                  save: (pageKey, extraNextKey, extraNextButtons) => {
                      changeSelection(pageKey, {
                          labels: Object.keys(extraNextButtons),
                          choice: extraNextKey,
                          other:
                              extraNextKey === 'website_url_invalid'
                                  ? null
                                  : extraNextKey === 'no_text_on_website'
                                  ? ''
                                  : provider.description,
                      })

                      if (extraNextKey === 'no_text_on_website') {
                          dispatch(
                              changeEntity({
                                  store: 'providers',
                                  name: 'providers',
                                  key: providerId,
                                  diff: {
                                      description: '',
                                  },
                              })
                          )
                      }

                      saveChained(pageKey)
                      return extraNextKey === 'website_url_invalid'
                          ? gotToPage('note')
                          : Promise.resolve()
                  },
              },
        images: {
            pageDesc: 'Controleer de gevonden afbeeldingen en vul de ontbrekende afbeeldingen aan.',
            component: <PageImages providerId={providerId} />,
            footerStyle: { paddingBottom: 35 },
            createFooterComponent: (disabled) => (
                <ImagesBar providerId={providerId} disabled={disabled} />
            ),
            extraNextButtons:
                !needVisualWebsiteCheck && !needPageDescription
                    ? { website_url_invalid: 'Website werkt niet' }
                    : {},
            isBusy: !hasFetchedImages || user._saving,
            autoValidate: true,
            validate: (pageKey, extraNextKey) =>
                extraNextKey || !currentContext
                    ? {}
                    : { [currentContext]: [`Geen/te weinig ${currentContext} geselecteerd`] },
            save: (pageKey, extraNextKey) => {
                changeSelection(pageKey, {
                    labels: extraNextKey
                        ? ['website_url_invalid']
                        : Object.values(NOTHING_LABELS).concat('few_photos', 'website_url_invalid'),
                    choices: extraNextKey
                        ? [extraNextKey]
                        : IMAGE_FILE_CONTEXTS.map((context) => {
                              const num = getImageDashSelection(context).length
                              return !num
                                  ? NOTHING_LABELS[context]
                                  : context === 'profile' && num < NUM_IMAGES[context]
                                  ? 'few_photos'
                                  : ''
                          }).filter((label) => label),
                })
                saveChained(pageKey)

                const doSaveUser = () =>
                    !user._isTouched
                        ? Promise.resolve()
                        : dispatch(saveUser(user.id, 'providers')).then(
                              (response) =>
                                  response &&
                                  changeUser({
                                      avatar_filename: response.user.avatar_filename,
                                      avatarFile: null,
                                      _isTouched: false,
                                  })
                          )

                return extraNextKey === 'website_url_invalid'
                    ? gotToPage('note')
                    : Promise.all([doSaveUser(), dispatch(saveNonAvatarImages(providerId))])
            },
        },
        note: {
            pageDesc: 'Voeg indien gewenst een notitie toe.',
            component: <PageNote providerId={providerId} />,
            onBack: firstFailedPageKey ? () => gotToPage(firstFailedPageKey) : undefined,
            extraNextButtons: {
                no_note: {
                    text: 'Geen notitie toevoegen',
                    styleName: 'bordered',
                },
            },
            nextText: 'Notitie toevoegen',
            validate: (pageKey, extraNextKey) =>
                extraNextKey || (getSelection(pageKey).other || '').trim()
                    ? {}
                    : { notitie: ['Notitie is leeg'] },
            save: (pageKey, extraNextKey) => {
                changeSelection(pageKey, {
                    choice: extraNextKey,
                    ...(extraNextKey ? { other: '' } : {}),
                })

                saveChained(pageKey)
                return redirectToAssignedProvider()
            },
        },
    }
    Object.keys(pages).forEach((pageKey) => !pages[pageKey] && delete pages[pageKey])

    return (
        <WizardPage
            className={style.root}
            pages={pages}
            url={profileBuilderWizardUrl}
            lang="NL"
            maxWidth={1200}
            header={
                <>
                    <b>Bedrijfsnaam:</b> {provider.business_name}
                    <a
                        className={style.providerLink}
                        href={websiteWithProtocol}
                        target="_blank"
                        rel="noreferrer">
                        <Icon name="link" />
                    </a>
                </>
            }
            backText=""
            backOnFirst={false}
        />
    )
}

export default ProfileBuilderWizardPage
