import {
    addMinutes,
    format,
    formatDistanceStrict,
    formatRelative as _formatRelative,
    parseISO,
} from 'date-fns'

type DateOrDateTime = Date | string | null | undefined

// The format you get when a SQL date is returned as string directly or from a FrozenDate.
const isSQLDate = (str: string) => /^\d{4}-\d{2}-\d{2}$/.test(str)
// The format you get when a SQL datetime is returned as string directly (NOT from a FrozenTime).
const isSQLDateTime = (str: string) => /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(str)

// we require timezone offset to avoid confusion, but milliseconds are optional, this is the format we get from a FrozenTime
//  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
const isStandardDateTime = (str: string) =>
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[+-]\d{2}:\d{2})$/.test(str)

// isUTC only affects strings in SQL datetime format (without timezone), in that case false will treat it as local time
export const getDateNew = (dateOrDateTime: DateOrDateTime, isUTC = true): Date | null => {
    if (!dateOrDateTime) {
        return null
    }

    if (dateOrDateTime instanceof Date) {
        return dateOrDateTime
    }

    // parseISO treats date and datetime without timezone as local time (as specified in ISO 8601)
    // TODO: treating it as local time might go wrong if JS runs on server
    if (isSQLDate(dateOrDateTime)) {
        return parseISO(dateOrDateTime)
    } else if (isSQLDateTime(dateOrDateTime)) {
        return parseISO((isUTC ? dateOrDateTime + '+00:00' : dateOrDateTime).replace(' ', 'T'))
    } else if (isStandardDateTime(dateOrDateTime)) {
        return parseISO(dateOrDateTime)
    } else {
        throw new Error('cannot construct a Date from string ' + dateOrDateTime)
    }
}

export const utcToLocal = (
    dateOrDateTime: DateOrDateTime,
    formatStr = DAY_MONTH_YEAR_TIME_FORMAT_FNS,
    fallback = ''
): string => {
    const date = getDateNew(dateOrDateTime)
    return date ? format(date, formatStr) : fallback
}

export const localToUTC = (
    dateOrDateTime: DateOrDateTime,
    formatStr = STANDARD_DATE_TIME_UTC_FORMAT_FNS,
    fallback: string | null = null
): string | null => {
    const date = getDateNew(dateOrDateTime, false)
    return date ? format(addMinutes(date, date.getTimezoneOffset()), formatStr) : fallback
}

export const formatDistanceStrictWrap = (
    date: DateOrDateTime,
    baseDate: DateOrDateTime,
    options: Parameters<typeof formatDistanceStrict>[2] = { addSuffix: true }
): string => {
    const _date = getDateNew(date)
    const _baseDate = getDateNew(baseDate)
    return _date && _baseDate ? formatDistanceStrict(_date, _baseDate, options) : ''
}

export const SQL_DATE_FORMAT_FNS = 'yyyy-MM-dd'
export const SQL_DATE_TIME_FORMAT_FNS = 'yyyy-MM-dd HH:mm:ss'
export const STANDARD_DATE_TIME_UTC_FORMAT_FNS = "yyyy-MM-dd'T'HH:mm:ss+00:00"
export const DAY_MONTH_YEAR_FORMAT_FNS = 'dd-MM-yyyy'
export const DAY_MONTH_YEAR_TIME_FORMAT_FNS = 'dd-MM-yyyy HH:mm'

export const getDate = (string: string | null | undefined) =>
    new Date(string ? string.replace(' ', 'T') : 0)

export const formatRelative = (str: string | null | undefined) =>
    _formatRelative(getDate(str), new Date()).replace(/(\d{1,2})\/(\d{1,2})\/(\d{4})/, '$2/$1/$3')

// test for DB datetime string ("2021-04-02T09:10:34+00:00" vs "2021-04-02 09:10:34")
export const isFromDatabase = (dateTime: string) =>
    /(\d{4})-(\d{1,2})-(\d{1,2})(T|\s)(\d{2}):(\d{2}):(\d{2})/.test(dateTime)
