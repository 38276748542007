import Icon from '@advanza/ui/Icon'
import { ComponentPropsWithoutRef, PropsWithChildren, ReactElement } from 'react'
import style from './button.module.scss'

interface OldProps {
    name?: string
    subtext?: any
    newStyle?: boolean
    [x: string]: any
}

interface Props extends ComponentPropsWithoutRef<'button'> {
    appearance?:
        | 'outlined_primary'
        | 'outlined_primary_less_prominent'
        | 'primary'
        | 'secondary'
        | 'text_primary'
        | 'whatsapp'
        | 'whatsapp_outlined'
    iconBefore?: string | ReactElement
    iconAfter?: string | ReactElement
    iconProps?: ComponentPropsWithoutRef<typeof Icon>
}

const ButtonIcon = ({
    icon,
    iconProps,
}: {
    icon: string | ReactElement
    iconProps?: ComponentPropsWithoutRef<typeof Icon>
}) => {
    return typeof icon === 'string' ? (
        <Icon
            library={'symbols'}
            name={icon}
            {...iconProps}
            classNameIcon={[style.icon, iconProps?.classNameIcon].join(' ')}
        />
    ) : (
        icon
    )
}

const Button = ({
    appearance,
    iconBefore,
    iconAfter,
    iconProps,

    //old props 👇
    name,
    children,
    subtext,
    newStyle = false,
    ...rest
}: OldProps & PropsWithChildren<Props>) => {
    if (appearance) {
        return (
            <button
                {...rest}
                className={[
                    style.basic,
                    !children && style.noText,
                    style[`appearance_${appearance}`],
                    rest.className,
                ].join(' ')}>
                {iconBefore && <ButtonIcon icon={iconBefore} iconProps={iconProps} />}
                {children && <div>{children}</div>}
                {iconAfter && <ButtonIcon icon={iconAfter} iconProps={iconProps} />}
            </button>
        )
    }
    const buttonClassName = (name && style[name]) || style.default
    return (
        <>
            <button
                {...rest}
                className={[
                    style.disabledStyle,
                    buttonClassName,
                    newStyle ? style.newStyle : '',
                    rest.className || '',
                ].join(' ')}>
                {children}
            </button>
            {subtext && <b className={style.subtext}>{subtext}</b>}
        </>
    )
}

export default Button
