import { createReducer, getUpdater } from '@advanza/advanza_generic'

const update = getUpdater()

const handlers = {
    RECEIVE_NOTIFICATIONS: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            entities: { $set: action.entities },
            result: { $set: action.result },
        }),
    REQUEST_NOTIFICATIONS: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
}

const initialState = {
    isFetching: false,
    entities: {},
    result: [],
}

export default createReducer(initialState, handlers)
