import { call } from '@advanza/api'
import Modal from '@advanza/modal'
import { LoadingDots } from '@advanza/ui'
import React, { Fragment, useState } from 'react'
import { SanitySvg } from 'components/services/AddServiceToSanity'

const AddRequestToSanity = ({ requestId }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(false)
    const create = () => {
        setIsLoading(true)
        call('office/requests/add-to-sanity/' + requestId, { method: 'post' })
            .then(setResponse, setResponse)
            .finally(() => setIsLoading(false))
    }

    const hasError = response && response.status === 'error'
    const isAdded = response && response.status === 'success'
    return (
        <Fragment>
            <button onClick={create}>
                {isLoading ? (
                    <LoadingDots />
                ) : hasError ? (
                    'error'
                ) : isAdded ? (
                    'added to sanity'
                ) : (
                    <span title="Add to sanity if it doesn't exist">
                        <SanitySvg />
                    </span>
                )}
            </button>
            <Modal open={hasError} close={() => setResponse(null)}>
                {response && <b>{response.error}</b>}
            </Modal>
        </Fragment>
    )
}

export default AddRequestToSanity
