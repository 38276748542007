import { Button, Icon } from '@advanza/ui'
import { createNewCustomPrice } from 'actions/customPrices'
import Card from 'components/Card'
import CustomPriceEntity from 'components/customPrices/CustomPriceEntity'
import FlexTable from 'components/FlexTable/FlexTable'
import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const CustomPricesList = ({ filterId, providerId }) => {
    const dispatch = useDispatch()
    const definitions = {
        custom_price_id: { name: '#' },
        leadType: { name: 'type' },
        serviceName: { name: 'service' },
        optionName: { name: 'option' },
        price_amount: { name: 'price' },
    }
    const { providers, customPrices } = useSelector((state) => state.providers.entities)
    const provider = providers[providerId]
    const onCreateEntity = useCallback(() => {
        dispatch(createNewCustomPrice(filterId, providerId))
    }, [provider])
    const { services = {}, options = {} } = useSelector((state) => state.subscriptions.entities)
    function renderRow(id) {
        const {
            serviceName,
            service_id,
            option_id,
            optionName,
            amount,
            is_phone_click,
            is_website_click,
            _open,
        } = customPrices[id]
        const leadType = is_website_click ? 'website' : is_phone_click ? 'phone' : 'email'
        return {
            cols: [
                id,
                leadType,
                (services[service_id] && services[service_id].name) || serviceName,
                (options[option_id] && options[option_id].value) || optionName,
                amount,
            ],
            expandable: (
                <Card expandableStyle>
                    <CustomPriceEntity entityId={id} />
                </Card>
            ),
            expandOnClick: true,
            expanded: _open,
            id,
        }
    }

    return (
        <div>
            <Button name="text" onClick={onCreateEntity}>
                Add
            </Button>
            <FlexTable
                rows={provider.custom_prices ? provider.custom_prices.map(renderRow) : []}
                definitions={definitions}
            />
        </div>
    )
}

CustomPricesList.propTypes = {}

export default CustomPricesList
