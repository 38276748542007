import { Col, Row } from '@advanza/grid'
import { Button, Divider, FixedWarningPopup } from '@advanza/ui'
import { addCbr, saveCbr } from 'actions/providers'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useChangeEntity } from 'hooks/entityHooks'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const FIELDS = {
    pass_perc: {
        type: 'int',
        placeholder: 'Examination %',
        max: 100,
        fallback: null,
        validator: (val) => !val || (0 <= val && val <= 100),
    },
    num_exams: {
        type: 'int',
        placeholder: 'Examination #',
        fallback: null,
        validator: (val) => !val || 0 <= val,
    },
    url: {
        type: 'string',
        placeholder: 'URL',
        fallback: null,
        validator: (val) => !val || /^https:\/\/www\.cbr\.nl\/\S+$/.test(val),
        defaultErrorMsg: 'Has to start with https://www.cbr.nl/',
    },
}

const Cbr = ({ providerId }) => {
    const dispatch = useDispatch()
    const [toast, setToast] = useState({})
    const { providers = {} } = useSelector((state) => state.providers.entities)
    const provider = providers[providerId]
    const {
        entity: cbr = {},
        onSaveEntity,
        renderInput,
    } = useChangeEntity(
        {
            store: 'providers',
            name: 'cbrs',
            entityId: provider && provider.cbr,
            saveFunc: saveCbr,
        },
        FIELDS
    )

    const onSave = () =>
        onSaveEntity().then(
            () => setToast({ id: Date.now(), message: 'Examination saved' }),
            (response) =>
                setToast({
                    isError: true,
                    id: Date.now(),
                    message: JSON.stringify(response) || 'Error',
                })
        )

    useEffect(() => {
        if (provider) {
            dispatch(addCbr(provider.service_provider_id))
        }
    }, [dispatch, provider])

    return (
        <LoadBoxWrapper isLoading={!provider || !provider.cbr}>
            <Row>
                <Col x>{renderInput('pass_perc')}</Col>
                <Col x>{renderInput('num_exams')}</Col>
            </Row>
            <Divider m />
            <Row>
                <Col x>{renderInput('url')}</Col>
            </Row>
            <Divider m />
            <Row end="xs">
                <Col x>
                    <Button disabled={!cbr._isTouched || cbr._saving} onClick={onSave}>
                        {cbr._saving ? '..saving' : 'Save'}
                    </Button>
                </Col>
            </Row>
            {toast.isError && <div style={{ color: 'red' }}>not saved</div>}
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </LoadBoxWrapper>
    )
}

export default Cbr
