import { Row, Section } from '@advanza/advanza_generic'
import React from 'react'
import Page from '../ui/Page'
import ProfileReportGenerator from './ProfileReportGenerator'
import { Divider } from '@advanza/ui'

const ProfileReportPage = ({}) => {
    return (
        <Page title="Providers">
            <div style={{ margin: 'auto', maxWidth: 1200 }}>
                <Section e="plus">
                    <h1>Profile Report</h1>
                </Section>
                <Divider l></Divider>
                <ProfileReportGenerator />
            </div>
        </Page>
    )
}

ProfileReportPage.propTypes = {}

export default ProfileReportPage
