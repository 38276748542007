import { createReducer, getUpdater } from '@advanza/advanza_generic'
import Cookie from 'js-cookie'
const update = getUpdater()

const handlers = {
    CHANGE_COUNTRY: (state, action) => {
        return { ...state, countries: action.countries }
    },
}

const initialState = {
    countries: [Cookie.get('_trustoo_office_country') || 'NL'],
}

export default createReducer(initialState, handlers)
