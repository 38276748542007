import { getAiDescriptions, getCrawledDescriptions } from 'actions/files'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const UNWANTED_WORDS = [
    'datenschutz',
    'imprint',
    'impressum',
    'impresum',
    'voorwaarden',
    'allgemeine-geschaeftsbedingungen',
    'allgemeine-geschäftsbedingungen',
    'agb',
    'terms-of-sales',
    'conditions-of-sales',
    'terms-and-conditions',
    'terms-conditions',
]

const WANTED_WORDS = [
    /* EN */
    /about/,
    /team/,
    /hello/,
    /story/,
    /profession/,
    /info/,
    /* NL & BE */
    /\bover\b/,
    /\bwij\b/,
    /\bmijn\b/,
    /\bik\b/,
    /* DE */
    /ueber/,
    /\bwir\b/,
    /meine/,
    /\bich\b/,
    /name/,
    /vita/,
    /biographie/,
]

const MAX_AUTO_PARAGRAPHS = 4
const MAX_AUTO_CHARLENGTH = 500

const cleanUrl = (url) => (url || '').replace(/https?:\/\/|www|\W/g, '').toLowerCase()
const matchUrls = (urlA, urlB) => cleanUrl(urlA) === cleanUrl(urlB)

export const useDescription = (provider = {}) => {
    const dispatch = useDispatch()
    const { crawledDescriptions, aiDescriptions } = useSelector((state) => state.files)

    const isFetchingCrawledDescriptions = crawledDescriptions[provider.website]?.isFetching
    const isFetchingAiDescriptions = aiDescriptions[provider.service_provider_id]?.isFetching
    const isFetchingDescriptions = isFetchingCrawledDescriptions || isFetchingAiDescriptions

    const hasFetchedCrawledDescriptions = crawledDescriptions[provider.website]?.hasFetched
    const hasFetchedAiDescriptions = aiDescriptions[provider.service_provider_id]?.hasFetched
    const hasFetchedDescriptions = hasFetchedCrawledDescriptions && hasFetchedAiDescriptions

    const fetchCrawledDescriptions = useCallback(
        () => dispatch(getCrawledDescriptions(provider.website, provider.country_code)),
        [dispatch, provider.country_code, provider.website]
    )

    const fetchAiDescriptions = useCallback(
        () => dispatch(getAiDescriptions(provider.service_provider_id)),
        [dispatch, provider.service_provider_id]
    )

    const fetchDescriptions = useCallback(
        () => Promise.all([fetchCrawledDescriptions(), fetchAiDescriptions()]),
        [fetchAiDescriptions, fetchCrawledDescriptions]
    )

    const descriptions = crawledDescriptions[provider.website]?.result || []
    const aiDescription = aiDescriptions[provider.service_provider_id] || {}

    const sortedDescriptions = descriptions
        .map((origItem) => {
            const item = { ...origItem }
            const path = new URL(item.response_url.toLowerCase()).pathname
            const snippet = item.text_snippet.toLowerCase()
            const businessName = (provider.business_name || '')
                .toLowerCase()
                .replace('B.V.', '')
                .trim()

            const priorityAdjustRules = [
                {
                    name: 'inUrl',
                    hide: true,
                    condition: UNWANTED_WORDS.find((str) => path.includes(str)),
                },
                {
                    name: 'inUrlWanted',
                    hide: false,
                    allowAuto: true,
                    adjust: -500,
                    condition: WANTED_WORDS.find((regex) => regex.test(path)),
                },
                {
                    name: 'inSnippet',
                    hide: true,
                    condition: [...UNWANTED_WORDS, 'cookie'].find((str) => snippet.includes(str)),
                },
                {
                    name: 'containsBusinessName',
                    hide: false,
                    condition: snippet.includes(businessName),
                },
            ]

            item.priority = item.priority ?? item.response_url.length
            item.rulesFlagged = []
            item.homepage = matchUrls(item.response_url, item.company_domain)
            item.allowAuto = false
            let shouldHide = false
            priorityAdjustRules.forEach((rule) => {
                if (rule.condition) {
                    if (rule.allowAuto) {
                        item.allowAuto = true
                    }
                    if (rule.hide) {
                        shouldHide = true
                    }
                    if (rule.adjust) {
                        item.priority += rule.adjust
                    }
                    item.rulesFlagged.push(`${rule.name}: ${rule.condition}`)
                }
            })

            item.hidden = shouldHide
            return item
        })
        .filter((item) => !item.hidden)
        .sort((a, b) => a.priority - b.priority)
        .slice(0, 99)

    let preselectedDescription = ''
    let descriptionPreselectMethod = ''

    if (matchUrls(aiDescription.website, provider.website) && aiDescription.long_description) {
        preselectedDescription = aiDescription.long_description
        descriptionPreselectMethod = 'ai'
    }

    if (!preselectedDescription && sortedDescriptions.length) {
        for (let i = 0; i <= MAX_AUTO_PARAGRAPHS; i++) {
            if (preselectedDescription.length >= MAX_AUTO_CHARLENGTH) {
                break
            }

            preselectedDescription = sortedDescriptions
                .filter(
                    ({ response_url, allowAuto, homepage }) =>
                        sortedDescriptions[0].response_url === response_url &&
                        (allowAuto || homepage)
                )
                .map(({ text_snippet }) => text_snippet)
                .slice(0, i)
                .join('\n\n')
                .trim()
        }

        if (preselectedDescription) {
            descriptionPreselectMethod = 'snippets_combine'
        }
    }

    return {
        isFetchingDescriptions,
        hasFetchedAiDescriptions,
        hasFetchedDescriptions,
        fetchAiDescriptions,
        fetchDescriptions,
        sortedDescriptions,
        preselectedDescription,
        descriptionPreselectMethod,
    }
}
