import { Section } from '@advanza/advanza_generic'
import { call } from '@advanza/api'
import { Checkbox } from '@advanza/input'
import { changeEntity } from '@advanza/redux_entity'
import Card from 'components/Card'
import ChannableFeedEntity from 'components/channableFeed/ChannableFeedEntity'
import TableList from 'components/TableList'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { useList } from 'hooks/listHooks'
import Option from 'interfaces/Options'
import Question from 'interfaces/Questions'
import Service from 'interfaces/Services'
import { useEffect } from 'react'
import servicesSchema from 'schemes/servicesSchema'
import { AppDispatch, RootState } from 'types/RootStateType'

export interface ChannableFeedService extends Service {
    selectedPart: string
    optionCount: number
    optionNames: string | null
}

const setAllOptions =
    (serviceId: number, channable: boolean) =>
    (dispatch: AppDispatch, getState: () => RootState) => {
        const { services, questions, options } = getState().channableFeed.entities
        const service: ChannableFeedService = services[serviceId]

        service.questions.forEach((questionId) =>
            (questions[questionId] as Question).options.forEach((optionId) => {
                const option: Option = options[optionId]

                if (option.channable !== channable) {
                    dispatch(
                        changeEntity({
                            store: 'channableFeed',
                            name: 'options',
                            key: optionId,
                            diff: { channable },
                        })
                    )
                    call(`office/services/save-option/${optionId}`, {
                        json: { channable },
                    })
                }
            })
        )
    }

export const updateSelectedPart =
    (serviceId: number) => (dispatch: AppDispatch, getState: () => RootState) => {
        const { services, questions, options } = getState().channableFeed.entities
        const service: ChannableFeedService = services[serviceId]

        dispatch(
            changeEntity({
                store: 'channableFeed',
                name: 'services',
                key: serviceId,
                diff: {
                    selectedPart:
                        service.questions
                            .map((questionId) =>
                                (questions[questionId] as Question).options.filter(
                                    (optionId) => (options[optionId] as Option).channable
                                )
                            )
                            .flat().length +
                        '/' +
                        service.optionCount,
                },
            })
        )
    }

export const useChannableFeedList = (filterId = 'all', defaultOptions = {}) => {
    const { countries } = useAppSelector((state) => state.countries)
    const result = useList(
        filterId,
        {
            url: 'office/channable-feed/get-list',
            schema: servicesSchema,
            reducerKey: 'channableFeed',
        },
        { ...defaultOptions, countries, sorting: ['service_id', 'DESC'] }
    )

    useEffect(() => {
        result.changeFilter({ countries })
    }, [countries])

    return result
}

const ChannableFeedList = ({ filterId = 'all' }) => {
    const dispatch = useAppDispatch()
    const {
        entities: { services = {} },
        extra: { definitionsChannableFeed = {} } = {},
        ...list
    } = useChannableFeedList(filterId)

    const renderRow = (serviceId: number) => {
        const service: ChannableFeedService = services[serviceId]
        return {
            cols: (Object.keys(definitionsChannableFeed) as (keyof ChannableFeedService)[]).map(
                (key) => {
                    if (key === 'channable') {
                        return (
                            <Checkbox
                                key={key}
                                value={service.channable}
                                onChange={(e) => {
                                    dispatch(
                                        changeEntity({
                                            store: 'channableFeed',
                                            name: 'services',
                                            key: serviceId,
                                            diff: { channable: e.target.value },
                                        })
                                    )
                                    call(`office/services/save-service/${serviceId}`, {
                                        json: { channable: e.target.value },
                                    })
                                    // @ts-ignore
                                    dispatch(setAllOptions(serviceId, e.target.value))
                                    // @ts-ignore
                                    dispatch(updateSelectedPart(serviceId))
                                }}
                                onLabelClick={(e) => e.stopPropagation()}
                            />
                        )
                    } else if (['subscribable', 'visible'].includes(key)) {
                        return (
                            // @ts-ignore
                            <Section
                                key={key}
                                className="label"
                                bg={service[key] ? 'green' : 'magenta'}>
                                {service[key] ? 'yes' : 'no'}
                            </Section>
                        )
                    } else if (key === 'selectedPart') {
                        return <b key={key}>{service.selectedPart}</b>
                    }

                    return service[key]
                }
            ),
            expandable: (
                <Card expandableStyle>
                    <ChannableFeedEntity serviceId={serviceId} />
                </Card>
            ),
            expandOnClick: true,
        }
    }

    return (
        <TableList
            definitions={definitionsChannableFeed}
            renderRow={renderRow}
            addListFeatures
            {...list}
        />
    )
}

export default ChannableFeedList
