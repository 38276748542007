import React from 'react'
import style from './CheckButton.module.css'

class CheckButton extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        const { onChange, onBeforeChange } = this.props
        const { name, value } = e.target

        const adjustedValue = value === 'true' ? true : value === 'false' ? false : value
        onBeforeChange && onBeforeChange()
        onChange({ ...e, target: { name, value: adjustedValue } })
    }

    render() {
        const { checked, name, value, msg, reverse, inlineBlock, error, defaultErrorMsg, ...rest } =
            this.props

        const isOn = reverse ? !checked : checked
        return (
            <>
                <label
                    className={[
                        style.root,
                        isOn ? style.checked : '',
                        error ? style.error : '',
                    ].join(' ')}>
                    <div
                        className={`check-button ${rest.className || ''} ${isOn ? 'on' : 'off'}`}
                        style={{ display: inlineBlock ? 'inline-block' : 'block' }}>
                        <input
                            {...rest}
                            name={name}
                            value={value}
                            onChange={this.onChange}
                            type="checkbox"
                        />
                        {msg}
                    </div>
                </label>
                {error && (
                    <div className={style.errorMsg}>
                        {(typeof error === 'string' && error) ||
                            defaultErrorMsg ||
                            'check this field'}
                    </div>
                )}
            </>
        )
    }
}

CheckButton.propTypes = {}

export default CheckButton
