import React from 'react'
import { connect } from 'react-redux'
import { changeSalesLeadsFilter, deleteSalesLeadsSelection } from '../actions/salesLeads'
import { deleteStatusesSelection } from '../actions/statuses'

function mapStateToProps(state, props) {
    const { filters, isFetching, counts } = state.salesLeads
    const filter = filters[props.filterId]
    const count = filter && counts[filter.searchKey]
    const { countries } = state.countries
    return {
        filter,
        count,
        countries,
        isFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: (id, options) => dispatch(changeSalesLeadsFilter(id, options)),
        deleteSelection: (filterId) => dispatch(deleteSalesLeadsSelection(filterId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
