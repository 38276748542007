import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    AGGREGATED_REVIEWS_CHANGE_FILTER,
    AGGREGATED_REVIEWS_RECEIVE_LIST,
    AGGREGATED_REVIEWS_REQUEST,
    AGGREGATED_REVIEWS_RECEIVE_SOURCES,
} from 'actions/aggregatedReviews'
import { changeEntity, changeFilter, receiveList, invalidateFilters } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [AGGREGATED_REVIEWS_RECEIVE_LIST]: receiveList,
    [AGGREGATED_REVIEWS_REQUEST]: (state, action) =>
        update(state, {
            isFetching: { $set: action.filterId || true },
        }),
    [AGGREGATED_REVIEWS_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_AGGREGATEDREVIEWS: changeEntity,
    INVALIDATE_AGGREGATED_REVIEWS: invalidateFilters,
    [AGGREGATED_REVIEWS_RECEIVE_SOURCES]: (state, action) =>
        update(state, {
            sources: { $set: action.sources },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
    sources: [],
}

export default createReducer(initialState, handlers)
