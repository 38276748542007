import { call } from '@advanza/api'
import { toLowerUnderscore } from '@advanza/func'
import { changeEntity } from './entities'

export const singularize = (str) => str.replace(/ies$/, 'y').replace(/s$/, '')
export const getPrim = (str) =>
    toLowerUnderscore(singularize(str)).replaceAll('provider', 'service_provider') + '_id'
export const toLowerDashed = (str) => toLowerUnderscore(str).replaceAll('_', '-')

const handleRel = (spec, entity, dispatch, getState, valueFunc) => {
    const { store, name, nameRel = undefined, singleRel = false } = spec

    if (!nameRel) {
        return
    }

    const primRel = getPrim(nameRel)
    const entityRel = getState()[store].entities[nameRel][entity[primRel]]
    const fieldRel = toLowerUnderscore(singleRel ? singularize(name) : name)

    dispatch(
        changeEntity({
            store,
            name: nameRel,
            key: entity[primRel],
            diff: {
                [fieldRel]: valueFunc(entityRel[fieldRel]),
            },
        })
    )
}

const validate =
    (spec, key, getFields = {}) =>
    (dispatch, getState) => {
        const { store, name } = spec
        const entity = getState()[store].entities[name][key]
        const newErrors = {}
        const fields = typeof getFields === 'function' ? getFields(entity) : getFields

        Object.keys(fields).forEach((field) => {
            const { validator, errorMsg } = fields[field]

            if (validator && !validator(entity[field], entity)) {
                newErrors[field] = errorMsg || true
            }
        })

        if (Object.keys(newErrors).length) {
            dispatch(
                changeEntity({
                    store,
                    name,
                    key,
                    diff: {
                        _errors: { ...(entity._errors || {}), ...newErrors },
                    },
                })
            )

            return false
        }

        return true
    }

export const save =
    (spec, key, getFields = {}, { controller, query = {} } = {}) =>
    (dispatch, getState) => {
        const { store, name, singleRel = false } = spec
        const prim = getPrim(name)
        const keys = Array.isArray(key) ? key : [key]
        const entities = {}

        if (keys.filter((key) => !dispatch(validate(spec, key, getFields))).length) {
            return Promise.reject('Validation failed')
        }

        keys.forEach((key) => {
            entities[key] = getState()[store].entities[name][key]
            dispatch(
                changeEntity({
                    store,
                    name,
                    key,
                    diff: {
                        _saving: true,
                        _beforeSave: entities[key],
                    },
                })
            )
        })

        return call(`office/${toLowerDashed(controller || name)}/save`, {
            json: entities,
            query: query,
        })
            .then(
                ({ keyMap }) => {
                    Object.keys(keyMap).forEach((tempKey) => {
                        const newKey = keyMap[tempKey]

                        dispatch(
                            changeEntity({
                                store,
                                name,
                                key: tempKey,
                                newKey,
                                diff: {
                                    [prim]: newKey,
                                    _saving: false,
                                    _beforeSave: { ...entities[key], [prim]: newKey },
                                },
                            })
                        )

                        handleRel(spec, entities[tempKey], dispatch, getState, (oldValue) =>
                            singleRel
                                ? oldValue === tempKey
                                    ? newKey
                                    : oldValue
                                : oldValue.map((keyOther) =>
                                      keyOther === tempKey ? newKey : keyOther
                                  )
                        )
                    })
                },
                (response) => {
                    if (response.error === 'fields') {
                        dispatch(
                            changeEntity({
                                store,
                                name,
                                key: response.key,
                                diff: {
                                    _errors: response,
                                },
                            })
                        )
                    }

                    return Promise.reject(response)
                }
            )
            .finally(() =>
                keys.forEach((key) =>
                    dispatch(
                        changeEntity({
                            store,
                            name,
                            key,
                            diff: {
                                _saving: false,
                            },
                        })
                    )
                )
            )
    }

export const remove = (spec, key) => (dispatch, getState) => {
    const { store, name, singleRel = false } = spec
    const prim = getPrim(name)
    const entity = getState()[store].entities[name][key]

    const removeFromStore = () => {
        handleRel(spec, entity, dispatch, getState, (oldValue) =>
            singleRel
                ? oldValue === key
                    ? null
                    : oldValue
                : oldValue.filter((keyOther) => keyOther !== key)
        )

        dispatch(changeEntity({ store, name, key, remove: true }))
    }

    if (typeof entity[prim] === 'string') {
        removeFromStore()
        return Promise.resolve()
    }

    return call(`office/${toLowerDashed(name)}/delete/` + entity[prim], { method: 'post' }).then(
        removeFromStore
    )
}

export const add =
    (spec, extraDiff = {}, keyRel = undefined) =>
    (dispatch, getState) => {
        const { store, name, nameRel = undefined, singleRel = false } = spec
        const prim = getPrim(name)
        const key = Math.random().toString(36).substr(2, 5)
        const diff = {
            [prim]: key,
            _open: true,
            ...extraDiff,
        }

        if (nameRel && keyRel) {
            const primRel = getPrim(nameRel)
            diff[primRel] = keyRel

            if (!singleRel) {
                const entityRel = getState()[store].entities[nameRel][keyRel]
                const fieldRel = toLowerUnderscore(name)
                const orderValues = entityRel[fieldRel].map(
                    (key) => getState()[store].entities[name][key].order_value || 0
                )
                diff.order_value = orderValues.length ? Math.max(...orderValues) + 1 : 0
            }
        }

        dispatch(
            changeEntity({
                store,
                name,
                key,
                diff,
            })
        )

        handleRel(spec, diff, dispatch, getState, (oldValue) =>
            singleRel ? key : oldValue.concat(key)
        )
    }

export const changeOrder = (spec, key, modify) => (dispatch, getState) => {
    const { store, name, nameRel = undefined, singleRel = false } = spec

    if (!nameRel || singleRel) {
        return
    }

    const prim = getPrim(name)
    const entity = getState()[store].entities[name][key]

    const primRel = getPrim(nameRel)
    const entityRel = getState()[store].entities[nameRel][entity[primRel]]
    const fieldRel = toLowerUnderscore(name)

    const orderedKeys = entityRel[fieldRel]
        .map((key) => getState()[store].entities[name][key])
        .sort((entityA, entityB) => (entityA.order_value || 0) - (entityB.order_value || 0))
        .map((entity) => entity[prim])

    const currentIndex = orderedKeys.indexOf(key)
    const newIndex = Math.max(currentIndex + modify, 0)
    const newOrder = orderedKeys.filter((keyOther) => keyOther !== key)
    newOrder.splice(newIndex, 0, key)
    newOrder.forEach((key, i) => {
        dispatch(
            changeEntity({
                store,
                name,
                key,
                diff: {
                    order_value: i,
                },
            })
        )
    })

    call(`office/${toLowerDashed(name)}/change-order`, { json: { newOrder } })
}

// no thunk
export const changeOrderPaged = (spec, changeFilter, id, up) =>
    call(`office/${toLowerDashed(spec.name)}/change-order-paged/${id}/${up ? 1 : 0}`, {
        method: 'post',
    }).finally(() => changeFilter({ didInvalidate: true, page: 0 }))
