import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { changeFilter, invalidateFilters, receiveList, changeEntity } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    PROVIDERSBUILDERS_RECEIVE_LIST: receiveList,
    PROVIDERSBUILDERS_CHANGE_FILTER: changeFilter,
    CHANGE_ENTITY_PROVIDERSBUILDERS: changeEntity,
    INVALIDATE_PROVIDERSBUILDERS: invalidateFilters,
    PROVIDERSBUILDERS_REQUEST: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    PROVIDERSBUILDERS_CLEAR_RESULT: (state) =>
        update(state, {
            result: { $set: {} },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    counts: {},
    entities: {},
    result: {},
    filters: {},
}

export default createReducer(initialState, handlers)
