import {
    FieldInput,
    KeyPressHandler,
    Loading,
    Msg,
    Section,
    WithIcon,
} from '@advanza/advanza_generic'
import { passwordReset } from '@advanza/advanza_generic/src/actions/auth'
import React from 'react'
import { Link } from 'react-router-dom'
import isEmail from 'validator/es/lib/isEmail'

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.submit = this.submit.bind(this)
        this.validate = this.validate.bind(this)
        this.afterSubmitError = this.afterSubmitError.bind(this)

        this.state = {
            email: { value: props.email || '' },
        }
    }

    validate() {
        const { email } = this.state
        const isValid = isEmail(email.value)
        if (!isValid) {
            this.setState({ email: { ...this.state.email, error: <Msg id="error.email" /> } })
        }
        return isValid
    }

    handleChange(e) {
        const { value } = e.target
        this.setState({ email: { value } })
    }

    submit() {
        const { email } = this.state
        if (!this.validate()) {
            return
        }
        this.setState({ loading: true })
        passwordReset(email.value).then(
            (json) => this.setState({ loading: false, submitted: true }),
            this.afterSubmitError
        )
    }

    afterSubmitError(error) {
        switch (error.error) {
            case 'not found':
                this.setState({
                    email: {
                        ...this.state.email,
                        error: <Msg id="login.error.no_user_found" />,
                    },
                })
                break
            default:
                this.setState({
                    email: {
                        ...this.state.email,
                        error: <Msg id="login.error.no_user_found" />,
                    },
                })
        }
        this.setState({ loading: false })
    }

    renderAfterSubmitted() {
        return (
            <div>
                <Section s>
                    <WithIcon icon="email">
                        <h1>
                            <Msg id="password_recovery.sent.header" />
                        </h1>
                    </WithIcon>
                </Section>
                <Section>
                    <Msg id="password_recovery.sent.p" />
                </Section>
            </div>
        )
    }

    render() {
        const { email, loading, submitted } = this.state
        if (submitted) {
            return this.renderAfterSubmitted()
        }
        return (
            <div className="pos-rel">
                <Section s>
                    <Link to="/login">
                        <WithIcon align="middle" icon="arrow_back_ios">
                            <Msg id="login.login_button" />
                        </WithIcon>
                    </Link>
                </Section>
                <Section s="plus">
                    <h1>
                        <Msg id="login.reset_password_title" />
                    </h1>
                </Section>
                <Section>
                    <FieldInput
                        state={email}
                        name="email"
                        autoFocus
                        newGStyle
                        type="email"
                        onChange={this.handleChange}
                        msgid="placeholder.email"
                        icon="email"
                    />
                </Section>
                <Section n>
                    <button type="submit" className="btn col-12" onClick={this.submit}>
                        <Msg id="login.reset_password.button" />
                    </button>
                    {loading && <Loading inside />}
                </Section>
                <KeyPressHandler onEnter={this.submit} />
            </div>
        )
    }
}

export default ResetPassword
