import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField, SelectSimple } from '@advanza/input'
import { Button, Divider, LoadingDots } from '@advanza/ui'
import Page from 'components/ui/Page'
import React, { useState } from 'react'

const getIntervalNum = (intervalStr) => parseInt(intervalStr, 10) || 60

const AdSheetsPage = () => {
    const [sheetName, setSheetName] = useState('services')
    const [intervalStr, setIntervalStr] = useState('60')
    const [isBusy, setIsBusy] = useState(false)
    const [error, setError] = useState(null)

    const onCalc = () => {
        setIsBusy(true)
        setError(null)
        return call(`office/providers/calc-sheet/${sheetName}/${getIntervalNum(intervalStr)}`).then(
            undefined,
            ({ error = 'Error' }) => {
                setIsBusy(false)
                setError(error)
            }
        )
    }

    return (
        <Page title="Ad sheets">
            <Row bottom="xs">
                <Col x>
                    <SelectSimple
                        options={[
                            { value: 'services' },
                            { value: 'devices' },
                            { value: 'keywords' },
                            { value: 'subcities' },
                            { value: 'filters' },
                        ]}
                        value={sheetName}
                        onChange={(e) => setSheetName(e.target.value)}
                        placeholder="Sheet name"
                        legend
                    />
                </Col>
                <Col x>
                    <InputField
                        value={intervalStr}
                        onChange={(e) => setIntervalStr(e.target.value)}
                        onBlur={() => setIntervalStr('' + getIntervalNum(intervalStr))}
                        placeholder="Interval"
                        legend
                    />
                </Col>
                <Col x>
                    <Button disabled={isBusy} onClick={onCalc}>
                        {isBusy ? (
                            <>
                                Busy <LoadingDots />
                            </>
                        ) : (
                            'Calculate'
                        )}
                    </Button>
                </Col>
            </Row>
            {error && (
                <>
                    <Divider m />
                    <div style={{ color: 'red' }}>{error}</div>
                </>
            )}
            <Divider m />
            The resulting csv file will be sent to Slack when done
        </Page>
    )
}

export default AdSheetsPage
