import React, { useState } from 'react'
import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { Button, Divider, Icon, LinearLoader } from '@advanza/ui'
import { useChangeEntity } from 'hooks/entityHooks'
import badgesStyle from './badges.module.css'
import style from './providerScores.module.css'

const renderScore = (score) => (
    <div className={style.score} style={{ backgroundColor: `hsl(${score * 100}, 100%, 35%, 0.2)` }}>
        {score * 10}
    </div>
)

const ProviderScores = ({ providerId, entityId }) => {
    const [updateScoresBusy, setUpdateScoresBusy] = useState(false)
    const [buildup, setBuildup] = useState([])
    const { entity: provider, onChangeEntity: onChangeProvider } = useChangeEntity(
        {
            store: 'providers',
            name: 'providers',
            entityId: providerId,
        },
        []
    )
    const { entity, onChangeEntity } = useChangeEntity(
        {
            store: 'providers',
            name: 'scores',
            entityId,
        },
        []
    )

    const updateScores = () => {
        setUpdateScoresBusy(true)
        setBuildup([])
        return call(`office/providers/update-scores/${providerId}`)
            .then(({ buildup, resultingScores: { total_score, ...otherScores } }) => {
                setBuildup(buildup)
                onChangeProvider({ total_score })
                onChangeEntity(otherScores)
            })
            .finally(() => setUpdateScoresBusy(false))
    }

    const renderBuildup = (item, i, numSiblings, level) => {
        if (Array.isArray(item)) {
            const result = item.map((subItem, i) =>
                renderBuildup(subItem, i, item.length, level + 1)
            )
            return item.length && !Array.isArray(item[0]) ? (
                <Row
                    key={i}
                    className={[style['level' + level], i === 0 ? style.first : ''].join(' ')}>
                    {result}
                </Row>
            ) : (
                result
            )
        } else {
            return (
                <Col key={i} xs={12 / numSiblings}>
                    {Number.isNaN(Number(item)) ? item : (Number(item) * 100).toFixed() / 100}
                </Col>
            )
        }
    }

    return (
        <div>
            <Row end="xs">
                <Col x>
                    <Button name="text" onClick={updateScores}>
                        <Icon name="search" className="colorPrimary" /> View calculation
                    </Button>
                </Col>
            </Row>
            <Divider m />
            <Row>
                <Col xs={3}>
                    <b>Reviews:</b>
                    {renderScore(entity.review_score)}
                </Col>
                <Col xs={3}>
                    <b>Expertise:</b>
                    {renderScore(entity.expertise)}
                </Col>
                <Col xs={3}>
                    <b>Profile:</b>
                    {renderScore(entity.presence)}
                </Col>
                <Col xs={3}>
                    <b>Total score:</b>
                    {renderScore(provider.total_score)}
                </Col>
            </Row>
            {(buildup.length > 0 || updateScoresBusy) && (
                <>
                    <Divider m />
                    <div className={badgesStyle.box}>
                        {updateScoresBusy && <LinearLoader absolute />}
                        {renderBuildup(buildup, 0, 1, 0)}
                    </div>
                </>
            )}
        </div>
    )
}

export default ProviderScores
