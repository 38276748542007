import { Section } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import { Ellipsis, HoverText, Icon } from '@advanza/ui'
import RequestCandidateMatchStatus from 'components/candidateMatches/RequestCandidateMatchStatus'
import Card from 'components/Card'
import { arrOf } from 'components/misc/misc'
import {
    Awin,
    Bing,
    BingPaid,
    Criteo,
    Facebook,
    GooglePaid,
    GoogleSearch,
    Instagram,
    Roomburg,
} from 'components/requests/requestSourceIcons'
import RequestStatus from 'components/requests/RequestStatus'
import RequestTypeIcon from 'components/requests/RequestTypeIcon'
import FormattedTime from 'components/ui/FormattedTime'
import { formatMoney } from 'misc/money'
import React from 'react'
import { Link } from 'react-router-dom'
import RequestListContainer from '../../containers/RequestListContainer'
import MatchList from '../matches/MatchList'
import TableList from '../TableList'
import style from './RequestList.module.css'

class RequestList extends React.Component {
    definitions = {
        id: { name: '#', preventRedirect: true },
        nrRequestsByUser: { name: 'rv' },
        name: { name: 'name' },
        service: { name: 'service' },
        location: { name: 'location' },
        subject: { name: 'subject' },
        match: { name: 'Pros' },
        source: { name: 'src' },
        other: { name: '' },
        rev: { name: <Icon fontSize={14} name="euro_symbol" /> },
        type: { name: 'Type' },
        status: { name: <Icon fontSize={14} name="navigation" /> },
        time: { name: 'time' },
    }

    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    getUrl(id) {
        const { filterSource } = this.props
        return filterSource === 'closed-loop'
            ? `/statistics/closed-loop-requests/closed-loop/request/${id}`
            : `/service/requests/request/${id}`
    }

    renderColumns(id) {
        const { requestEntities, services } = this.props
        const { requests, users } = requestEntities
        const request = requests[id]
        const service = (services && services[request.service_id]) || {}
        const user = users[request.user_id]
        const sourceIconMap = {
            googlePaid: <GooglePaid />,
            googleOrganic: <GoogleSearch />,
            bingOrganic: <Bing />,
            bingPaid: <BingPaid />,
            awin: <Awin />,
            'ig.com': <Instagram />,
            'fb.com': <Facebook />,
            cross_sell: <Icon name="add_shopping_cart" />,
            verbouwkosten: (
                <img
                    alt="favicon"
                    src="https://www.google.com/s2/favicons?domain=verbouwkosten.com&sz=24"
                />
            ),
            criteo: <Criteo />,
            performanceMax: <Icon name="insights" />,
            roomburg: <Roomburg />,
        }
        let icon = sourceIconMap[request.source]
        if (!icon) {
            try {
                const sourceHost = new URL(request.source).hostname
                icon = (
                    <img
                        alt="favicon"
                        src={`https://www.google.com/s2/favicons?domain=${sourceHost}&sz=24`}
                    />
                )
            } catch (e) {
                icon = <Icon name="not_listed_location" />
            }
        }

        return [
            <small className="c-grey">
                <Link to={this.getUrl(id)}>#{id}</Link>
            </small>,
            request.nrRequestsByUser > 1 ? (
                <Icon
                    title={request.nrRequestsByUser}
                    fontSize={18}
                    name="published_with_changes"
                />
            ) : (
                ''
            ),
            <Ellipsis style={{ maxWidth: 200 }}>{user.username}</Ellipsis>,
            service.name,
            request.postal_code && request.postal_code.place_name,
            <Ellipsis style={{ maxWidth: 200 }}>
                {(request.subject || '')
                    .substring((request.subject || '').indexOf('(') + 1)
                    .replace(')', '')}
            </Ellipsis>,
            <span>
                {arrOf(request.nrMatches || 0).map((i) => (
                    <Icon
                        fontSize={14}
                        name="person"
                        orange={i < request.nrReclaims && i >= request.nrReclaimsAccepted}
                        red={i < request.nrReclaimsAccepted}
                    />
                ))}
                <RequestCandidateMatchStatus request={request} compact />
            </span>,
            <HoverText
                text={request.source}
                trigger={<div className={style.sourceIcon}>{icon}</div>}
            />,
            <Row>
                {request.status === 'DELETED' && (
                    <Col x>
                        <Section className="label" bg="black" color="white">
                            Deleted
                        </Section>
                    </Col>
                )}
                {!request.valid && (
                    <Col x>
                        <Section className="label" bg="magenta">
                            Invalid
                        </Section>
                    </Col>
                )}
            </Row>,
            <small>
                <b>{request.sumCharges ? formatMoney(request.sumCharges) : '-'}</b>
            </small>,
            <RequestTypeIcon request={request} />,
            <RequestStatus key={request.request_id} status={request.status} compact />,
            <small className="c-grey to-right">
                <FormattedTime date={request.created} />
            </small>,
        ]
    }

    renderRow(id) {
        const { requestEntities } = this.props
        const { requests } = requestEntities
        const request = requests[id]

        return {
            url: this.getUrl(id),
            cols: this.renderColumns(id),
            expandable: (
                <Card expandableStyle>
                    <h2>Matches</h2>
                    <MatchList
                        showCols={[
                            'firstChoice',
                            'from_marketplace',
                            'from_dashboard',
                            'charge',
                            'name',
                            'nrMsgs',
                            'stripes',
                            'partialMatch',
                        ]}
                        filterId={`r_${id}`}
                        defaultOptions={{ requestIds: [id] }}
                    />
                </Card>
            ),
            style: { color: `${request.status === 'DELETED' ? '#aaa' : 'inherit'}` },
            id,
        }
    }

    render() {
        const { selectable, ...rest } = this.props
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

export default RequestListContainer(RequestList)
