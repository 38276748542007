import React from 'react'
import Page from 'components/ui/Page'
import AutoRow from 'components/misc/AutoRow'
import ServicesSelect from 'components/services/ServicesSelect'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { useCurrentCountry, useUrlQuery } from 'hooks/miscHooks'
import { Button, Divider, Icon } from '@advanza/ui'
import ServicesPlacesContentBlocks from 'components/seo/ServicesPlacesContentBlocks'

const SEOServicesPlacesContentsPage = ({}) => {
    const { query, onSetQuery } = useUrlQuery({})
    const countryCode = useCurrentCountry()
    return (
        <Page title="SEO Service Places Content Page">
            <Divider xl />
            <AutoRow>
                <h2>SEO service/place content blocks </h2>
                <div> </div>
                <ServicesSelect
                    placeholder={false}
                    multiple={false}
                    onChangeValue={(service) => onSetQuery({ service })}
                    value={query.service}
                />
                <div> </div>
                <Icon name="place" />
                <SearchLocationInput
                    countryCode={countryCode}
                    onChangeLocation={(place) => onSetQuery({ place: place.url_name })}
                    value={query.place}
                />
                <Button name="text" onClick={() => onSetQuery({ place: '' })}>
                    <Icon name="south_america" />
                </Button>
            </AutoRow>
            <Divider xxl name="border" />
            <ServicesPlacesContentBlocks place={query.place} service={query.service} />
        </Page>
    )
}

export default SEOServicesPlacesContentsPage
