import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField, Toggle } from '@advanza/input'
import { useActionModal } from '@advanza/modal'
import { Button, Divider, Icon } from '@advanza/ui'
import ServicesSelect from 'components/services/ServicesSelect'
import DateRangePicker from 'components/ui/DateRangePicker'
import Page from 'components/ui/Page'
import { addDays, format } from 'date-fns'
import queryString from 'querystring'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ProvidersBuildersList, { useProvidersBuildersList } from './ProvidersBuildersList'

const useRenderExport = (icon, header, funcUrl) => {
    const { openModal, renderModal } = useActionModal()
    const [startDate, setStartDate] = useState(addDays(new Date(), -7))
    const [endDate, setEndDate] = useState(addDays(new Date(), -1))
    const [onlyProfileBuilders, setOnlyProfileBuilders] = useState(true)
    const [perUser, setPerUser] = useState(false)

    return (
        <Col x>
            <Button onClick={() => openModal()}>
                <Icon name={icon} /> {header}
            </Button>
            {renderModal({
                cancelText: 'Cancel',
                text: 'Ok',
                func: () =>
                    call(`office/providers-builders/${funcUrl}`, {
                        json: {
                            startDateStr: format(startDate, 'yyyy-MM-dd'),
                            endDateStr: format(endDate, 'yyyy-MM-dd'),
                            onlyProfileBuilders,
                            perUser,
                        },
                    }),
                header,
                body: (
                    <>
                        <DateRangePicker
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            compactWithModal={false}
                        />
                        <Divider m />
                        <Toggle
                            value={onlyProfileBuilders}
                            legend
                            inline
                            placeholder="Only profile builders"
                            showText={false}
                            onChange={(e) => setOnlyProfileBuilders(e.target.value)}
                        />
                        <Toggle
                            value={perUser}
                            legend
                            inline
                            placeholder="Per user"
                            showText={false}
                            onChange={(e) => setPerUser(e.target.value)}
                        />
                    </>
                ),
                maxWidth: 1024,
            })}
        </Col>
    )
}

const ProvidersBuildersPage = ({ filterId = 'all' }) => {
    const dispatch = useDispatch()
    const { changeFilter, filter } = useProvidersBuildersList(filterId)
    const { openModal: openAddModal, renderModal: renderAddModal } = useActionModal()
    const { openModal: openRemoveModal, renderModal: renderRemoveModal } = useActionModal()
    const [serviceId, setServiceId] = useState(null)
    const [providerId, setProviderId] = useState(null)
    const [modalError, setModalError] = useState(null)
    const exportTimingReport = useRenderExport(
        'timer',
        'Export timing report',
        'export-timing-report'
    )
    const exportStatsReport = useRenderExport(
        'leaderboard',
        'Export stats report',
        'export-stats-report'
    )

    const invalidate = () => {
        dispatch({ type: 'PROVIDERSBUILDERS_CLEAR_RESULT' })
        changeFilter({ page: 0 })
    }

    const onAddProvidersToWizard = () => {
        setModalError(null)

        if (!serviceId) {
            setModalError('select a service')
            return Promise.reject()
        }

        return call(`office/providers-builders/add-providers-to-wizard`, {
            json: { serviceId, providerId },
        }).then(invalidate, ({ error = 'error' }) => {
            setModalError(error)
            return Promise.reject()
        })
    }

    const exportFilter = {}

    Object.keys(filter).forEach((key) => {
        if (Array.isArray(filter[key])) {
            exportFilter[key + '[]'] = filter[key]
        } else {
            exportFilter[key] = filter[key]
        }
    })

    const onRemoveProvidersFromWizard = () =>
        call(
            `office/providers-builders/remove-providers-from-wizard?${queryString.stringify(
                exportFilter
            )}`
        ).then(invalidate)

    return (
        <Page title="Profile builder wizard config">
            <Divider m />
            <Row middle="xs">
                <Col x>
                    <h1>Profile builder wizard config</h1>
                </Col>
                <Col x>
                    <Button
                        onClick={() => {
                            setModalError(null)
                            openAddModal()
                        }}>
                        <Icon name="add" /> Add providers
                    </Button>
                    {renderAddModal({
                        cancelText: 'Cancel',
                        text: 'Ok',
                        func: onAddProvidersToWizard,
                        header: 'Add providers',
                        body: (
                            <>
                                <Row middle="xs">
                                    <Col xs={6}>For service</Col>
                                    <Col xs={6}>
                                        <ServicesSelect
                                            onChangeValue={(serviceIds = []) => {
                                                setModalError(null)
                                                setServiceId(serviceIds[0])
                                            }}
                                            value={serviceId}
                                            useSelectedCountry={false}
                                            multiple={false}
                                            placeholder=""
                                            emptyOption=" "
                                        />
                                    </Col>
                                </Row>
                                <Row middle="xs">
                                    <Col xs={6}>Restrict to provider id</Col>
                                    <Col xs={6}>
                                        <InputField
                                            value={providerId || ''}
                                            onChange={(e) =>
                                                setProviderId(parseInt(e.target.value, 10) || null)
                                            }
                                        />
                                    </Col>
                                </Row>
                                {modalError && (
                                    <>
                                        <Divider m />
                                        <div style={{ color: 'red', textAlign: 'right' }}>
                                            {modalError}
                                        </div>
                                    </>
                                )}
                            </>
                        ),
                        wrapContent: false,
                        modalInsideStyle: { overflow: 'visible', padding: 24 },
                    })}
                </Col>
                <Col x>
                    <Button onClick={() => openRemoveModal()}>
                        <Icon name="remove" /> Remove providers
                    </Button>
                    {renderRemoveModal({
                        cancelText: 'Cancel',
                        text: 'Ok',
                        func: onRemoveProvidersFromWizard,
                        header: 'Remove currently filtered providers from wizard?',
                    })}
                </Col>
                {exportTimingReport}
                {exportStatsReport}
            </Row>
            <Divider m />
            <ProvidersBuildersList filterId={filterId} />
        </Page>
    )
}

export default ProvidersBuildersPage
