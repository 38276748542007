import { call } from '@advanza/api'
import { Button, Icon, LoadingDots } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import { useCurrentCountry } from 'hooks/miscHooks'
import React from 'react'

const InvalidateNextPageButton = ({ serviceUrl, paths, tag, btnText = 'Invalidate Next Page' }) => {
    const countryCode = useCurrentCountry()
    const [isLoading, setIsLoading] = React.useState(false)
    const [response, setResponse] = React.useState(false)

    const createInvalidation = () => {
        setIsLoading(true)
        call('office/services/invalidate-next-page', {
            query: {
                serviceUrl,
                countryCode,
                paths,
                tag,
            },
        })
            .then(
                (response) => {
                    if (window.location.hostname.indexOf('testoffice') !== -1) {
                        window.open(response.urls[0].url, '_blank')
                    }
                    setResponse(response)
                },
                (response) => {
                    setResponse(response)
                }
            )
            .finally(() => setIsLoading(false))
    }
    return (
        <Button onClick={createInvalidation} name="borderedPoppins">
            <AutoRow>
                {btnText}
                {isLoading && <LoadingDots />}
                {response && response.status === 'success' && (
                    <Icon title={JSON.stringify(response.urls)} name="thumb_up" green />
                )}
                {response && response.status === 'error' && (
                    <Icon title={response.error} name="thumb_down" red />
                )}
            </AutoRow>
        </Button>
    )
}

export default InvalidateNextPageButton
