import { saveProvider } from 'actions/providers'
import LicencesSelect from 'components/licences/LicencesSelect'
import EntityComponent from 'components/services/EntityComponent'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import React from 'react'

class ProvidersLicencesDropdown extends EntityComponent {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(values) {
        this.onChangeEntity({
            licences: values,
            dirtyLicences: true,
        })
    }

    render() {
        const { entity = {} } = this.props
        const { licences = [] } = entity
        return (
            <div>
                <LicencesSelect
                    onValueChange={this.onChange}
                    value={licences.map((licence) => licence.licence_id)}
                />
            </div>
        )
    }
}

export default EntityComponentContainer(ProvidersLicencesDropdown, {
    store: 'providers',
    name: 'providers',
    saveFunc: saveProvider,
})
