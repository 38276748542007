import { call } from '@advanza/advanza_generic'
import { Button, Divider, PreIcon } from '@advanza/ui'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

const Logout = () => {
    const [redirect, setRedirect] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        window._user &&
            call('auth/logout/1').then(
                () => (window.location = `/logout${window.location.search}`), // update client side with full reload
                ({ error }) => setError(error)
            )
    }, [])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            {!window._user && (
                <>
                    You are now logged out
                    <Divider m />
                    <Button onClick={() => setRedirect(`/login${window.location.search}`)}>
                        Log in
                    </Button>
                </>
            )}
            {error && (
                <>
                    <Divider m />
                    <PreIcon icon="warning" style={{ color: 'red' }} outerStyle={{ color: 'red' }}>
                        {error}
                    </PreIcon>
                </>
            )}
        </>
    )
}

export default Logout
