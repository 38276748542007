import { useState } from 'react'
import { call } from '@advanza/api'
import { Button, Divider } from '@advanza/ui'
import { useDispatch } from 'react-redux'
import Dialog from 'components/ui/modal/Dialog'
import AutoRow from 'components/misc/AutoRow'

type Props = {
    providerId: number
}
const GenerateInterimInvoiceButton = ({ providerId }: Props) => {
    const [loading, setLoading] = useState(false)
    const [response, setResponse]: [null | object, any] = useState(null)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    const generate = async () => {
        setLoading(true)
        try {
            const response = await call(`office/invoices/generate-interim-invoice/${providerId}`, {
                json: {
                    dateEnd: new Date().toISOString(),
                    dateStart: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                    ).toISOString(),
                },
            })
            setResponse(response)
            dispatch({ type: 'INVALIDATE_INVOICES' })
        } catch (e: any) {
            setResponse({ error: e })
        }
        setLoading(false)
    }

    return (
        <div>
            <Button appearance="secondary" onClick={() => setOpen(true)}>
                Generate interim invoice
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} closeButton>
                <h3>Are you sure?</h3>
                <Divider m />
                <AutoRow>
                    <Button appearance="primary" onClick={generate}>
                        {loading ? 'Generating...' : 'Continue generate interim invoice'}
                    </Button>
                    <Button appearance="secondary" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                </AutoRow>
                {response && <pre>{JSON.stringify({ response }, null, 2)}</pre>}
            </Dialog>
        </div>
    )
}

export default GenerateInterimInvoiceButton
