import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    PROVIDERS_DATES_REQUEST,
    PROVIDERS_DATES_RECEIVE_LIST,
    PROVIDERS_DATES_CHANGE_FILTER,
} from 'actions/providersDates'
import {
    changeEntity,
    changeFilter,
    receiveList,
    selectItems,
    invalidateFilters,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [PROVIDERS_DATES_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [PROVIDERS_DATES_RECEIVE_LIST]: receiveList,
    [PROVIDERS_DATES_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_PROVIDERSDATES: changeEntity,
    PROVIDERS_DATES_SELECT_ITEMS: selectItems,
    INVALIDATE_PROVIDERS: invalidateFilters,
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
}

export default createReducer(initialState, handlers)
