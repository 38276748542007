import React, {Component} from "react"
import {FormattedMessage} from "react-intl"
import {Icon} from "../src/Icon"

class FieldInput extends Component {
    constructor(props) {
        super(props)
        this.focus = this.focus.bind(this)
        this.handleAutoFocus = this.handleAutoFocus.bind(this)
        this.state = {
            focused: false
        }
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            this.handleAutoFocus()
        }
    }

    handleAutoFocus() {
        let success = false
        const intervals = [50,100, 200]
        const doAutoFocus = () => {
            if(success) {
                return
            }
            if (this.el) {
                success = true
                this.el.focus()
            }
        }
        for(const i in intervals) {
            setTimeout(doAutoFocus, intervals[i])
        }
    }

    focus(focused) {
        this.setState({focused})
    }

    render() {
        const {
            state, msgid, msg, labelClass, showError,
            reference, icon, before, jump: jumpFromProps,
            newGStyle, textArea, autoFocus,validator, ...rest
        } = this.props
        const {focused} = this.state

        const value = state.value || ""
        const jump = newGStyle ? false : jumpFromProps
        const empty = value.length === 0
        const up = jump !== false && focused || !empty
        const hasError = state.error && !focused && showError !== false
        const inputProps = {
            ...rest,
            value      : value,
            placeholder: "",
            onFocus    : e => {
                this.focus(true)
                rest.onFocus && rest.onFocus(e)
            },
            onBlur     : e => {
                this.focus(false)
                rest.onBlur && rest.onBlur(e)
            }
        }
        if (reference) {
            inputProps.ref = reference
        } else if (autoFocus) {
            inputProps.ref = el => this.el = el
        }


        const input = textArea ? <textarea {...inputProps}/> : <input {...inputProps}/>

        const inputIcon = icon ? (typeof icon === 'string' ? <Icon>{icon}</Icon> : icon) : false
        const labelText = msgid ?
            <FormattedMessage id={msgid}/> :
            <span>{msg}</span>

        const classString = [
            hasError ? 'has-error' : '',
            inputIcon ? 'with-icon' : '',
            newGStyle ? 'new-g-style' : '',
            textArea ? 'textarea' : ''
        ].join(' ')

        const labelClassString = [
            'material-label',
            up ? 'up ' : '',
            jump !== false ? 'jump ' : ' ',
            labelClass || '',
            focused ? 'focus' : ''
        ].join(' ')

        return (
            <div className={"material-input " + classString}>
                <label
                    className={labelClassString}>
                    {inputIcon && <div className="icon">{inputIcon}</div> }
                    <div className="label-text"> {labelText}</div>
                    {before && <div className="before">{before}</div> }
                    {input}
                    {this.props.children}
                </label>
                {hasError && <div className="error">{state.error}</div>}

            </div>
        )
    }
}

export default FieldInput