import { Col, Row } from '@advanza/grid'
import { Button, Divider } from '@advanza/ui'
import React from 'react'
import { useChangeEntity } from 'hooks/entityHooks'
import { saveLabel } from 'hooks/licencesHooks'

const fields = {
    description: {
        type: 'text',
        placeholder: 'description',
        msg: 'description',
    },
}

const LicencesLabelEntity = ({ entityId, onClose }) => {
    const { entity, renderInput, onSaveEntity } = useChangeEntity(
        {
            store: 'labels',
            name: 'labels',
            entityId,
            saveFunc: saveLabel,
        },
        fields
    )
    const isNew = typeof entityId === 'string'

    return (
        <Row end="xs">
            <Col xs={12}>
                {renderInput('description')}
                <Divider m />
            </Col>
            <Col x>
                <Button
                    disabled={entity._saving || !entity._isTouched}
                    style={{ height: '46px' }}
                    onClick={() => onSaveEntity().then(() => onClose())}>
                    {entity._saving ? 'Saving..' : isNew ? 'Create label' : 'Save label'}
                </Button>
            </Col>
        </Row>
    )
}

export default LicencesLabelEntity
