import { changeFilesFilter, getWebsiteImageUrls } from 'actions/files'
import {
    IMAGE_FILE_CONTEXTS,
    NOTHING_LABELS,
    NUM_IMAGES,
    useProfileBuilderWizard,
} from 'hooks/profileBuilderHooks'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ScrapedImagesSelectGrid from './ScrapedImagesSelectGrid'

const PageImages = ({ providerId }) => {
    const dispatch = useDispatch()
    const {
        provider = {},
        findLabel,
        filterId,
        filter,
        imageFiles = [],
        hasFetchedImages,
        getSelection,
        getImageDashSelection,
        getPreselectionScrapedImages,
        changeFile,
        changeSelection,
        selectImage,
    } = useProfileBuilderWizard(providerId)

    useEffect(() => {
        dispatch(getWebsiteImageUrls(providerId, provider.website))
    }, [dispatch, provider.website, providerId])

    useEffect(() => {
        if (!filter) {
            dispatch(changeFilesFilter(filterId, { providerIds: [providerId], pageSize: 999 }))
        }
    }, [dispatch, filter, filterId, providerId])

    useEffect(() => {
        if (hasFetchedImages && !getSelection('images').initDone) {
            changeSelection('images', { initDone: true })

            IMAGE_FILE_CONTEXTS.forEach(
                (context) =>
                    !getImageDashSelection(context).length &&
                    !findLabel(NOTHING_LABELS[context]) &&
                    getPreselectionScrapedImages(context).forEach((scrapedImage) =>
                        selectImage(scrapedImage, context)
                    )
            )
        }
    })

    useEffect(() => {
        if (hasFetchedImages) {
            const changeWeight = (imageFile) => {
                if (imageFile.weight !== weight) {
                    changeFile(imageFile.file_id, { weight })
                }

                weight--
            }

            let weight = NUM_IMAGES.logo + NUM_IMAGES.profile
            imageFiles
                .filter((imageFile) => imageFile.context === 'logo')
                .slice(0, NUM_IMAGES.logo)
                .forEach(changeWeight)

            weight = NUM_IMAGES.profile
            imageFiles.filter((imageFile) => imageFile.context === 'profile').forEach(changeWeight)

            if (0 < weight) {
                weight = 0
            }

            imageFiles
                .filter((imageFile) => imageFile.context === 'logo')
                .slice(NUM_IMAGES.logo)
                .forEach(changeWeight)
        }
    })

    return <ScrapedImagesSelectGrid providerId={providerId} />
}

export default PageImages
