import { useList } from 'hooks/listHooks'
import keywordsSchema from 'schemes/keywordsSchema'
import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'
import { useDispatch } from 'react-redux'

export function useKeywordsList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/services-keywords/get-list',
            schema: keywordsSchema,
            reducerKey: 'keywords',
        },
        defaultOptions
    )
}

export function useAddKeyword(filterId, options) {
    const dispatch = useDispatch()
    return () => {
        const newId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                diff: {
                    services_keyword_id: newId,
                    visible: true,
                    services_keywords_variations: [],
                    _open: true,
                    ...options,
                },
                key: newId,
                filterId,
                name: 'keywords',
                store: 'keywords',
            })
        )
    }
}

export function saveKeyword(keywordId) {
    return function (dispatch, getState) {
        const keyword = getState().keywords.entities.keywords[keywordId]
        const isNew = typeof keyword.services_keyword_id === 'string'
        return call('office/services-keywords/save/' + (isNew ? '' : keyword.services_keyword_id), {
            json: {
                ...keyword,
                services_keywords_variations: keyword.services_keywords_variations.map(
                    (id) => getState().keywords.entities.services_keywords_variations[id]
                ),
                services_keyword_id: isNew ? null : keyword.services_keyword_id,
            },
        }).then(({ id }) => {
            dispatch(
                changeEntity({
                    store: 'keywords',
                    name: 'keywords',
                    key: keywordId,
                    diff: {
                        services_keyword_id: id,
                    },
                })
            )
        })
    }
}
