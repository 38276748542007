import { changeEntity } from 'actions/entities'
import { call } from '@advanza/api'

export function createNewCustomPrice(filterId, providerId, options = {}) {
    return function (dispatch, getState) {
        const newId = Math.random().toString(36).substr(2, 5)
        const provider = getState().providers.entities.providers[providerId]
        dispatch(
            changeEntity({
                key: newId,
                filterId,
                name: 'customPrices',
                store: 'providers',
                diff: {
                    custom_price_id: newId,
                    amount: 10,
                    service_provider_id: providerId,
                    _open: true,
                    ...options,
                },
            })
        )
        dispatch(
            changeEntity({
                key: providerId,
                filterId,
                name: 'providers',
                store: 'providers',
                diff: {
                    custom_prices: provider.custom_prices.concat(newId),
                },
            })
        )
    }
}

export function saveCustomPrice(id) {
    return function (dispatch, getState) {
        const entity = getState().providers.entities.customPrices[id]
        return call('office/providers/save-custom-price', { json: entity }).then(
            (response) => {
                dispatch(
                    changeEntity({
                        key: entity.custom_price_id,
                        name: 'customPrices',
                        store: 'providers',
                        diff: { custom_price_id: response.id, _open: false },
                    })
                )
            },
            (response) => {
                return Promise.reject(response)
            }
        )
    }
}

export function deleteCustomPrice(id) {
    return function (dispatch, getState) {
        const { providers, customPrices } = getState().providers.entities
        const entity = customPrices[id]
        const isNew = typeof id === 'string'
        const provider = providers[entity.service_provider_id]
        const removeFromStore = () => {
            dispatch(
                changeEntity({
                    key: entity.service_provider_id,
                    name: 'providers',
                    store: 'providers',
                    diff: {
                        custom_prices: provider.custom_prices.filter((priceId) => id !== priceId),
                    },
                })
            )
        }
        if (isNew) {
            removeFromStore()
            return Promise.resolve()
        } else {
            return call('office/providers/delete-custom-price/' + id, { method: 'post' }).then(
                removeFromStore,
                (response) => {
                    return Promise.reject(response)
                }
            )
        }
    }
}
