import { Col, Row } from '@advanza/grid'
import { SelectCheckbox } from '@advanza/input'
import { Button, Divider, Icon } from '@advanza/ui'
import AdminUser from 'components/adminUsers/AdminUser'
import { format, isToday } from 'date-fns'
import { countDays, DAY_SCHEMES, isPastDay } from 'hooks/providersDatesHooks'
import { PauseReason, ProvidersDate } from 'interfaces/Providers'
import { CSSProperties, useMemo, useState } from 'react'
import style from './filteredPauseOverview.module.scss'

interface Props {
    pauseDates: ProvidersDate[]
    addButton: JSX.Element
    getEditButton: (providerDateId: number | string) => JSX.Element
    pauseReasons?: PauseReason[]
    gridStyle?: CSSProperties
}

const FilteredPauseOverview = ({
    pauseDates,
    addButton,
    getEditButton,
    pauseReasons,
    gridStyle,
}: Props) => {
    const [filter, setFilter] = useState(['future', 'active'])
    const getPeriod = (from: Date, to: Date, includeFirstDay = true) => {
        const nrDays = countDays(from, to, includeFirstDay)
        return `${nrDays} ${nrDays === 1 ? 'day' : 'days'}`
    }
    const dates = useMemo(() => {
        const dates = [...pauseDates]
        dates.forEach((date: ProvidersDate) => {
            const from = new Date(date.date_from)
            const to = new Date(date.date_to)
            const isRepeated = date.day_scheme !== 0
            const isActive =
                !date.pause_deleted &&
                (isPastDay(from) || isToday(from)) &&
                (isRepeated || !isPastDay(to))
            date._calculated = {
                from: from,
                to: to,
                isRepeated: isRepeated,
                isActive: isActive,
                isFuture: !date.pause_deleted && !isActive && !isPastDay(from),
                isPast: !date.pause_deleted && !isActive && isPastDay(from),
            }
        })

        return dates
    }, [pauseDates])

    return (
        <div>
            <Divider s />
            <Row bottom="xs" between="xs">
                <Col xs>{addButton}</Col>
                <Col x>
                    {/** @ts-ignore */}
                    <SelectCheckbox
                        customButton={
                            <Button name="link">
                                <span>
                                    <Icon name="filter_list" fontSize={18} primColor /> Filter
                                </span>
                            </Button>
                        }
                        options={[
                            {
                                value: 'future',
                                name: 'Future',
                                after: (
                                    <div className={style.afterName}>
                                        <Icon
                                            classNameIcon={style.afterNameIcon}
                                            name="event"
                                            fontSize={16}
                                        />
                                    </div>
                                ),
                            },
                            {
                                value: 'active',
                                name: 'Active',
                                after: (
                                    <div className={style.afterName}>
                                        <Icon
                                            classNameIcon={style.afterNameIcon}
                                            name="play_arrow"
                                            fontSize={16}
                                        />
                                    </div>
                                ),
                            },
                            {
                                value: 'past',
                                name: 'Past',
                                after: (
                                    <div className={style.afterName}>
                                        <Icon
                                            classNameIcon={style.afterNameIcon}
                                            name="arrow_circle_left"
                                            fontSize={16}
                                        />
                                    </div>
                                ),
                            },
                            {
                                value: 'deleted',
                                name: 'Deleted',
                                after: (
                                    <div className={style.afterName}>
                                        <Icon
                                            classNameIcon={style.afterNameIcon}
                                            name="delete"
                                            fontSize={16}
                                        />
                                    </div>
                                ),
                            },
                        ]}
                        onChangeValue={setFilter}
                        value={filter}
                        placeholder="Filter"
                    />
                </Col>
            </Row>
            <Divider s />
            <div className={style.grid} style={gridStyle}>
                {dates
                    ?.filter(
                        (date) =>
                            (filter.includes('future') && date._calculated?.isFuture) ||
                            (filter.includes('active') && date._calculated?.isActive) ||
                            (filter.includes('past') && date._calculated?.isPast) ||
                            (filter.includes('deleted') && date.pause_deleted)
                    )
                    .map((date: ProvidersDate) => {
                        const {
                            from = new Date(),
                            to = new Date(),
                            isRepeated,
                            isActive,
                            isFuture,
                        } = date._calculated || {}
                        const pauseReason =
                            pauseReasons && date.pause_reason_id
                                ? pauseReasons[date.pause_reason_id]
                                : undefined

                        return (
                            <>
                                <div>
                                    <Icon
                                        name={
                                            date.pause_deleted
                                                ? 'delete'
                                                : isActive
                                                ? 'play_arrow'
                                                : isFuture
                                                ? 'event'
                                                : 'arrow_circle_left'
                                        }
                                    />
                                </div>

                                <div>
                                    {date.date_from == date.date_to ? (
                                        format(from, 'dd-MMM')
                                    ) : (
                                        <>
                                            {format(from, 'dd-MMM')} - {format(to, 'dd-MMM')}
                                        </>
                                    )}
                                </div>
                                <div>
                                    <small>({getPeriod(from, to)})</small>
                                </div>
                                <div>
                                    <AdminUser sizePx={24} avatarOnly userId={date.admin_user_id} />
                                </div>
                                <div>
                                    {isRepeated ? (
                                        <>
                                            <Icon name="repeat" fontSize={16} />{' '}
                                            <small>{DAY_SCHEMES[date.day_scheme]}</small>
                                        </>
                                    ) : isActive ? (
                                        <>
                                            <b>{getPeriod(new Date(), to)}</b> left
                                        </>
                                    ) : null}
                                    {isFuture && (
                                        <>
                                            {isRepeated && ', '}
                                            starts in <b>{getPeriod(new Date(), from, false)}</b>
                                        </>
                                    )}
                                    {pauseReason && (
                                        <div>
                                            <small>
                                                {'['}
                                                {pauseReason.reason}
                                                {']'}
                                            </small>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {(isFuture || isActive) && getEditButton(date.provider_date_id)}
                                </div>
                            </>
                        )
                    })}
            </div>
        </div>
    )
}

export default FilteredPauseOverview
