import React, { useState } from 'react'
import { call } from '@advanza/api'
import { Button } from '@advanza/ui'
import { Modal } from '@advanza/modal'
import LoadingDotsTeam from '../../misc/LoadingDotsTeam'

function useHubooOrderStatus(providerId) {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)

    const getOrderStatus = () => {
        if (isLoading) {
            return Promise.resolve()
        }
        setIsLoading(true)
        call('office/providers/get-huboo-order-status/' + providerId)
            .then(setResponse, setResponse)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return {
        getOrderStatus,
        response,
        clear: () => setResponse(null),
        isLoading,
    }
}

const HubooOrderStatusButton = ({ providerId }) => {
    const { isLoading, getOrderStatus, response, clear } = useHubooOrderStatus(providerId)

    return (
        <>
            <Button name="text" onClick={getOrderStatus} disabled={isLoading}>
                {isLoading ? (
                    <LoadingDotsTeam name="pieter" />
                ) : (
                    <span>Get welcome package order status</span>
                )}
            </Button>
            {response && (
                <Modal maxWidth={1000} open={response} close={clear}>
                    <pre>{JSON.stringify(response, null, 2)}</pre>
                </Modal>
            )}
        </>
    )
}

export default HubooOrderStatusButton
