import { useIsTest } from 'hooks/miscHooks'
import { useProvider } from 'hooks/providerHooks'
import queryString from 'query-string'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

const ONBOARDING_LONG_SLUGS = {
    BE: 'trustlocal-belgie/onboarding-trustlocal',
    DE: 'trustlocal-deutschland/onboarding-trustlocal',
    NL: 'trustoo-succes/onboarding-trustoo',
}

function useEmbeddedForm(providerId, calendlyEventId) {
    const isTest = useIsTest()
    const { provider = {}, user = {} } = useProvider(providerId)
    const { calendlyEvents = {} } = useSelector((state) => state.providers.entities)
    const event = (calendlyEventId && calendlyEvents && calendlyEvents[calendlyEventId]) || {}
    const isFromPast = event.start_time && new Date(event.start_time) < new Date()

    const testUser = {
        first_name: 'Test',
        last_name: 'Trustoo',
        username: 'advanzaapp.mails@gmail.com',
        email: 'advanzaapp.mails@gmail.com',
        phone: '+316123456789',
    }

    const tagList = {
        hide_gdpr_banner: 1,
        hide_event_type_details: 1,
        utm_medium: 'office',
        utm_source: isTest ? 'test' : 'live',
        utm_campaign: 'activation_call',
        utm_term: event.reschedule_url ? 'reschedule' : 'new',
        utm_content: `${providerId}_${window._user.id}`,
    }

    const generateUrl = () =>
        (event.reschedule_url && !isFromPast
            ? event.reschedule_url
            : 'https://calendly.com/' + ONBOARDING_LONG_SLUGS[provider.country_code]) +
        '?' +
        queryString.stringify(tagList)

    return {
        user: isTest ? testUser : user,
        generateUrl,
    }
}

/**
 * CalendlyEmbeddedForm
 * Can be used for rescheduling or creating new event
 *
 * https://help.calendly.com/hc/en-us/articles/223147027?tab=advanced#3
 */
const CalendlyEmbeddedForm = ({ formName = 'form', providerId, calendlyEventId }) => {
    const formId = formName + '-calendly-inline-widget-id'
    const { user, generateUrl } = useEmbeddedForm(providerId, calendlyEventId)
    const [scriptIsLoaded, setScriptIsLoaded] = useState(false)
    const formRef = useRef()

    useEffect(() => {
        const formWrapper = document.getElementById(formId)
        const formScript = document.createElement('script')

        formScript.src = `https://assets.calendly.com/assets/external/widget.js`
        formScript.type = 'text/javascript'

        formWrapper.appendChild(formScript)
        formScript.onload = () => setScriptIsLoaded(true)

        return () => {
            formWrapper.removeChild(formScript)
        }
    }, [])

    useEffect(() => {
        if (scriptIsLoaded && formRef.current) {
            window.Calendly.initInlineWidget({
                url: generateUrl(),
                parentElement: formRef.current,
                prefill: {
                    name: user.first_name + ' ' + user.last_name,
                    email: user.username,
                    /*                     customAnswers: {
                        a1: user.phone, // should contain landcode
                    }, */
                },
                utm: {},
            })
        }
    }, [scriptIsLoaded, formRef.current])

    return <div ref={formRef} id={formId} style={{ height: '90vh' }} />
}

export default CalendlyEmbeddedForm
