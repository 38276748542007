import { fetchOptionGroups } from 'actions/services'
import React from 'react'
import { connect } from 'react-redux'

function mapStateToProps(state, props) {
    const { entities } = state.services.optionGroups
    const optionGroups = entities[props.questionId] || []
    return {
        optionGroups,
    }
}

export default connect(mapStateToProps, { fetchOptionGroups })
