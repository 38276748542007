import { call } from '@advanza/api'
import { normalize } from 'normalizr'
import servicesSchema from '../schemes/servicesSchema'
import { changeEntity } from './entities'
import { _changeFilter, _fetchItems } from './lists'

export const SERVICES_REQUEST = 'SERVICES_REQUEST'
export const SERVICES_RECEIVE_LIST = 'SERVICES_RECEIVE_LIST'
export const SERVICES_RECEIVE_SINGLE = 'SERVICES_RECEIVE_SINGLE'
export const SERVICES_CHANGE_FILTER = 'SERVICES_CHANGE_FILTER'
export const CHANGE_QUESTION_ORDER = 'CHANGE_QUESTION_ORDER'
export const QUESTION_DELETED = 'QUESTION_DELETED'
export const OPTION_DELETED = 'OPTION_DELETED'
export const RECEIVE_OPTION_GROUPS = 'RECEIVE_OPTION_GROUPS'
export const REQUEST_OPTION_GROUPS = 'REQUEST_OPTION_GROUPS'
export const INVALIDATE_SERVICE = 'INVALIDATE_SERVICE'

export function fetchServices(filterId) {
    return _fetchItems(filterId, {
        reducerKey: 'services',
        schema: servicesSchema,
        url: 'office/services/get-list',
        requestActionName: SERVICES_REQUEST,
        receiveActionName: SERVICES_RECEIVE_LIST,
    })
}

export function changeServiceFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'services',
        fetchFunc: fetchServices,
        actionType: SERVICES_CHANGE_FILTER,
    })
}

export function fetchService(serviceId) {
    return function (dispatch, getState) {
        const { entities, isFetching, didInvalidate } = getState().services
        const service = entities.services && entities.services[serviceId]
        if (
            (!didInvalidate && service && service.questions) ||
            (isFetching && isFetching === serviceId)
        ) {
            return Promise.resolve()
        }

        dispatch({ type: SERVICES_REQUEST, serviceId })

        return call(`office/services/get-one/${serviceId}`).then((response) => {
            const { entities } = normalize([response.item], servicesSchema)
            dispatch({ type: SERVICES_RECEIVE_SINGLE, entities })
        })
    }
}

export function invalidateService(serviceId) {
    return function (dispatch, getState) {
        dispatch({ type: INVALIDATE_SERVICE })
        dispatch(fetchService(serviceId))
    }
}

export function addQuestion(serviceId, data) {
    return function (dispatch, getState) {
        const key = Math.random().toString(36).substr(2, 5)
        const service = getState().services.entities.services[serviceId]
        dispatch(
            changeEntity({
                diff: {
                    _openToggle: true,
                    service_id: serviceId,
                    options: [],
                    question: "'New question'",
                    type: '1',
                    status: service.visible ? 0 : 1, // status new if the service is already visible, else status active
                    question_rules: [],
                    filterable: false,
                    question_id: key,
                    ...data,
                },
                key,
                name: 'questions',
                store: 'services',
            })
        )
        dispatch(
            changeEntity({
                diff: { questions: service.questions.concat(key) },
                key: serviceId,
                name: 'services',
                store: 'services',
                dontTouch: true,
            })
        )
    }
}

export function addOption(questionId, data) {
    return function (dispatch, getState) {
        const key = Math.random().toString(36).substr(2, 5)
        const question = getState().services.entities.questions[questionId]
        const service = getState().services.entities.services[question.service_id]
        dispatch(
            changeEntity({
                diff: {
                    option_id: key,
                    question_id: question.question_id,
                    status: service.visible ? 0 : 1, // status new if the service is already visible, else status active
                    options_searchable_keywords: [],
                    ...data,
                },
                key,
                name: 'options',
                store: 'services',
            })
        )

        dispatch(
            changeEntity({
                diff: { options: question.options.concat(key) },
                key: questionId,
                name: 'questions',
                dontTouch: true,
                store: 'services',
            })
        )
    }
}

export function addSearchableKeyword(optionId) {
    return function (dispatch, getState) {
        const option = getState().services.entities.options[optionId]
        dispatch(
            changeEntity({
                diff: {
                    options_searchable_keywords: option.options_searchable_keywords.concat({
                        options_searchable_keyword_id: Math.random().toString(36).substr(2, 5),
                        option_id: optionId,
                        keyword: '',
                    }),
                },
                key: optionId,
                name: 'options',
                store: 'services',
            })
        )
    }
}

export function onChangeKeyword(optionId, keywordId, data) {
    return function (dispatch, getState) {
        const option = getState().services.entities.options[optionId]
        const searchableKeywordIndex = option.options_searchable_keywords.findIndex(
            searchableKeyword => searchableKeyword.options_searchable_keyword_id === keywordId,
        );
        option.options_searchable_keywords[searchableKeywordIndex] = {
            options_searchable_keyword_id: keywordId,
            option_id: optionId,
            keyword: data,
        }

        dispatch(
            changeEntity({
                diff: {
                    options_searchable_keywords: option.options_searchable_keywords,
                },
                key: optionId,
                name: 'options',
                store: 'services',
            })
        )
    }
}

export function deleteKeyword(optionId, keywordId) {
    return function (dispatch, getState) {
        const option = getState().services.entities.options[optionId]

        dispatch(
            changeEntity({
                diff: {
                    options_searchable_keywords: option.options_searchable_keywords.filter(searchableKeyword => searchableKeyword.options_searchable_keyword_id !== keywordId),
                },
                key: optionId,
                name: 'options',
                store: 'services',
            })
        )
    }
}

export function saveService(serviceId) {
    return function (dispatch, getState) {
        const { services, faqs } = getState().services.entities
        const service = services[serviceId]

        return call(`office/services/save-service/${serviceId}`, {
            json: {
                ...service,
                faqs: service.faqs && service.faqs.map((id) => faqs[id]),
            },
        })
    }
}

// Saves the question and the associated options.
// Also saves the order value of each question according to the value in the state.
export function saveQuestion(questionId) {
    return function (dispatch, getState) {
        const {
            questions,
            options: optionEntities,
            services,
            bag_uses = {},
        } = getState().services.entities
        const question = questions[questionId]
        const service = services[question.service_id]
        const isNew = typeof question.question_id === 'string'
        const questionIndexes = service.questions.map((id, i) => {
            return { questionId: id, index: i }
        })
        const options = question.options.map((id) => optionEntities[id])
        const bagUseKey = question.bag_use || `-${question.question_id}`
        const bag_use = bag_uses[bagUseKey]
        const payload = {
            question: {
                ...question,
                options,
                question_rules: question.question_rules.map((optionId) => {
                    return { option_id: optionId }
                }),
                bag_use,
            },
            questionIndexes,
        }
        return call(`office/services/save-question${isNew ? '' : `/${question.question_id}`}`, {
            json: payload,
        }).then((response) => {
            dispatch(invalidateService(service.service_id))
        })
    }
}

export function deleteQuestion(questionId) {
    return function (dispatch, getState) {
        const isNew = typeof questionId === 'string'
        const serviceId = getState().services.entities.questions[questionId].service_id
        const afterDeleted = (response) =>
            setTimeout(() => {
                dispatch({ type: QUESTION_DELETED, serviceId, questionId })
                dispatch({
                    type: 'ADD_TOAST',
                    toast: {
                        msg: 'Question deleted ' + JSON.stringify(response.message, null, 2),
                        green: true,
                        icon: 'accessible_forward',
                    }
                })
            }, 200)
        if (isNew) {
            afterDeleted()
            return Promise.resolve()
        }
        return call('office/services/delete-question/' + questionId, { method: 'post' }).then(
            afterDeleted
        )
    }
}

export function deleteOption(optionId) {
    return function (dispatch, getState) {
        const isNew = typeof optionId === 'string'
        const questionId = getState().services.entities.options[optionId].question_id
        const afterDeleted = () =>
            setTimeout(() => {
                dispatch({ type: OPTION_DELETED, optionId, questionId })
            }, 200)
        if (isNew) {
            afterDeleted()
            return Promise.resolve()
        }
        return call('office/services/delete-option/' + optionId, { method: 'post' }).then(
            afterDeleted
        )
    }
}

export function addOptionImage(optionId, image) {
    return function (dispatch) {
        return call('office/services/add-option-image/' + optionId, {
            payload: { file: image },
        }).then((response) => {
            dispatch(
                changeEntity({
                    diff: { icon_name: response.fileName },
                    key: optionId,
                    store: 'services',
                    name: 'options',
                })
            )
        })
    }
}

export function changeQuestionOrder(questionId, upOrDown) {
    return function (dispatch) {
        dispatch({ type: CHANGE_QUESTION_ORDER, questionId, upOrDown })
        dispatch(
            changeEntity({
                store: 'services',
                name: 'questions',
                key: questionId,
                diff: { _isTouched: true },
            })
        )
    }
}

export function changeOptionOrder(optionId, upOrDown) {
    return function (dispatch, getState) {
        const { entities } = getState().services
        const option = entities.options[optionId]
        const question = entities.questions[option.question_id]
        const optionsClone = [...question.options]
        const index = optionsClone.indexOf(optionId)
        const newOrder = upOrDown === 'up' ? index - 1 : index + 1
        optionsClone.splice(newOrder, 0, optionsClone.splice(index, 1)[0])

        optionsClone.forEach((id, i) => {
            dispatch(
                changeEntity({
                    store: 'services',
                    name: 'options',
                    key: id,
                    diff: { order_value: i },
                })
            )
        })
    }
}

export function saveOption(optionId) {
    return function (dispatch, getState) {
        const option = getState().services.entities.options[optionId]
        return call(`office/services/save-option/${optionId}`, {
            json: {
                ...option,
            },
        })
    }
}

export function saveServiceBanner(serviceId, file, context = 'banner') {
    return function () {
        return call('office/services/saveImage/' + serviceId, { payload: { file, context } })
    }
}

export function fetchOptionGroups(questionId) {
    return function (dispatch, getState) {
        const { entities, isFetching } = getState().services.optionGroups
        if (isFetching || (entities && entities[questionId])) {
            return Promise.resolve()
        }
        dispatch({ type: REQUEST_OPTION_GROUPS })
        return call(`office/services/get-option-groups/${questionId}`).then((response) => {
            dispatch({ type: RECEIVE_OPTION_GROUPS, ...response, questionId })
        })
    }
}

export const saveHighMunicipalities = (serviceId, highMunicipalityIds) => (dispatch) => {
    dispatch(
        changeEntity({
            store: 'services',
            name: 'services',
            key: serviceId,
            diff: { highMunicipalityIds },
        })
    )

    return call(`office/services/save-high-municipalities/${serviceId}`, {
        json: highMunicipalityIds,
    })
}
