import { Icon, Section } from '@advanza/advanza_generic'
import React from 'react'
import PropTypes from 'prop-types'
import StatusesListContainer from '../../containers/StatusesListContainer'
import LabelEntity from '../labels/LabelEntity'
import TableList from '../TableList'
import StatusEntity from './StatusEntity'

class StatusesList extends React.Component {
    constructor(props) {
        super(props)
        this.renderRow = this.renderRow.bind(this)
    }

    renderRow(id) {
        const { categories, entities } = this.props
        const { statuses } = entities
        const status = statuses[id]

        return {
            id,
            cols: [
                <small className="c-grey">#{id}</small>,
                status.description,
                <div
                    style={{
                        height: 18,
                        width: 18,
                        borderRadius: 5,
                        backgroundColor: status.color,
                    }}
                />,
                status.category,
                status.type,
            ],
            expandable: (
                <Section x y>
                    <StatusEntity entityId={id} categories={categories} />
                </Section>
            ),
            isLoading: status._saving,
            expanded: typeof status.status_id === 'string',
            expandOnClick: true,
        }
    }

    definitions = {
        id: { name: '#id' },
        description: { name: 'description' },
        color: { name: <Icon small>format_color_text</Icon> },
        category: { name: 'category' },
        type: { name: 'type' },
    }

    render() {
        const { selectable, ...rest } = this.props

        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={this.definitions}
            />
        )
    }
}

StatusesList.propTypes = {}

export default StatusesListContainer(StatusesList)
