import React from 'react'
import { connect } from 'react-redux'
import {
    addOption,
    addOptionImage,
    addQuestion,
    changeQuestionOrder,
    deleteOption,
    deleteQuestion,
    saveOption,
    saveQuestion,
} from '../actions/services'

function mapStateToProps(state, props) {
    const { services, ...serviceEntities } = state.services.entities
    const { isFetching: serviceIsFetching } = state.services
    const service = services && services[props.serviceId]
    const countries = state.countries.countries
    return {
        serviceEntities,
        serviceIsFetching,
        service,
        countryCode: countries[0],
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveQuestion: (questionId) => dispatch(saveQuestion(questionId)),
        changeQuestionOrder: (questionId, upOrDown) =>
            dispatch(changeQuestionOrder(questionId, upOrDown)),
        addOptionImage: (optionId, file) => dispatch(addOptionImage(optionId, file)),
        saveOption: (optionId) => dispatch(saveOption(optionId)),
        addQuestion: (serviceId, data) => dispatch(addQuestion(serviceId, data)),
        addOption: (questionId, data) => dispatch(addOption(questionId, data)),
        deleteQuestion: (questionId) => dispatch(deleteQuestion(questionId)),
        deleteOption: (optionId) => dispatch(deleteOption(optionId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
