import { Select } from '@advanza/advanza_generic'
import { indexGenerator } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { Divider, Icon } from '@advanza/ui'
import EmailStatsBlock from 'components/closedLoop/EmailStatsBlock'
import Balloon from 'components/ui/Balloon'
import Page from 'components/ui/Page'
import { useEmailStats } from 'hooks/closedLoopHooks'
import { Fragment, useEffect } from 'react'
import LinearLoader from '../LinearLoader'

const infoMap = {
    RequestConfirmationMail: 'Confirmation of a new request',
    RequestReminders: 'Automatic reminders when response rate is lower dan 50% after 96 hours',
    RequestNoReplies: 'No replies after 96 hours, cta to add extra matches',
    MatchNoInterest: 'Basic pro has no interest in this match',
    AfterMatchPremium: 'Match mail to premium pro',
    AfterMatchBasic: 'Match mail to pro with basic profile (clean template)',
    AfterMatchPremiumReminder: 'Reminder to premium pro',
    AfterMatchBasicReminder: 'Match mail to pro with basic profile (clean template)',
    AutoReplyMessage: 'Auto reply message',
    AutoReplyMessageBundle: 'Auto reply message bundle (AB test variant)',
    AutoReplyMessageSplitted: 'Auto reply message splitted (AB test original)',
    RequestUpdateConfirmationMail: 'Update request data after duplicate merge',
    PhoneViewNotification: 'Notification 5 minutes after pro clicks (first time) on view phone',
    MiddleManReplyMail: 'Pro/demand email reply to new message',
    MessageMailToDemand: 'Pro did write a new dashboard message',
    MessageMailToDemandReminder: 'Reminder for unread pro message',
    MessageMailToPro: 'Demand did write a new dashboard message',
    MessageMailToProReminder: 'Reminder for unread demand message',
    CloseRequestMail:
        'Close request if chosen, Send reminders, Get extra matches (after 48h with one reply)',
    CloseRequestMailReminder1: 'Reminder 1 for CloseRequestMail (after 7days)',
    CloseRequestMailReminder2: 'Reminder 2 for CloseRequestMail (after 14days)',
    CloseRequestMailReminder3: 'Reminder 3 for CloseRequestMail (after 28days)',
    AfterProHiredMail: 'Congratulate pro with chosen match',
    VerifyHiredMail: 'Confirm pro is hired after pro has indicated that demand has chosen him',
    ChosenMatchesReviewInviteMail: 'Montly invite for pro to send review invites to chosen matches',
    RequestChoiceThanks: 'Ask klantenvertellen review (only NL)',
    ReviewInviteInternal: 'Ask demand (of chosen match) for a review',
}

const ClosedLoopEmailsPage = () => {
    const { days, isFetching, fetchEmailStats, emailStats, onChangeDays } = useEmailStats()
    const emails = Object.keys(emailStats)
    const generator = indexGenerator()

    useEffect(() => {
        fetchEmailStats()
    }, [])

    return (
        <Page title="Closed loop - Emails">
            <h2>Closed loop - Emails</h2>
            {isFetching && <LinearLoader />}
            <Divider m />
            <Row>
                <Col x>
                    <strong>Period:</strong>
                    <Select
                        value={days}
                        onChange={onChangeDays}
                        className="select-stealth select-min select-wide"
                        options={[
                            { value: 1, title: '1 days' },
                            { value: 3, title: '3 days' },
                            { value: 7, title: '7 days' },
                            { value: 10, title: '10 days' },
                            { value: 30, title: '30 days' },
                            { value: 90, title: '3 months' },
                            { value: 180, title: '6 months' },
                            { value: 365, title: '1 year' },
                        ]}
                    />
                </Col>
            </Row>
            <Divider xl />
            {emails &&
                emails.map((email) => {
                    const rowRates = ['openRate', 'conversionRate', 'clickRate']
                    const rowNumbers = ['nrMails', 'nrSend', 'nrReceived', 'nrOpened', 'nrClicked']
                    const rowLeftOver = Object.keys(emailStats[email]).filter((stat) => {
                        return !rowRates.includes(stat) && !rowNumbers.includes(stat)
                    })

                    return (
                        <Fragment key={generator.next().value}>
                            <strong>{email}</strong>{' '}
                            {infoMap[email] && (
                                <Balloon content={infoMap[email]}>
                                    <Icon small>info</Icon>
                                </Balloon>
                            )}
                            <Divider m />
                            <Row>
                                {rowRates.map((stats) => {
                                    return (
                                        <EmailStatsBlock
                                            key={generator.next().value}
                                            email={email}
                                            stats={stats}
                                        />
                                    )
                                })}
                                {rowLeftOver.map((stats) => {
                                    return (
                                        <EmailStatsBlock
                                            key={generator.next().value}
                                            email={email}
                                            stats={stats}
                                        />
                                    )
                                })}
                            </Row>
                            <Row>
                                {rowNumbers.map((stats) => {
                                    return (
                                        <EmailStatsBlock
                                            key={generator.next().value}
                                            email={email}
                                            stats={stats}
                                        />
                                    )
                                })}
                            </Row>
                            <Divider l />
                        </Fragment>
                    )
                })}
        </Page>
    )
}

export default ClosedLoopEmailsPage
