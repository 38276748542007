import { Select } from '@advanza/advanza_generic'
import AggregatedReviewsSourceContainer from 'containers/AggregatedReviewsSourceContainer'
import React from 'react'

class AggregatedReviewsSourceSelect extends React.Component {
    componentDidMount() {
        this.props.fetchAllSources()
    }

    render() {
        const { sources } = this.props
        return (
            <Select
                options={sources}
                placeholder="Review source"
                useSearch
                visibleSearch
                optionKeys={{ valueKey: 'aggregated_reviews_source_id', msgKey: 'source_name' }}
                {...this.props}
            />
        )
    }
}

AggregatedReviewsSourceSelect.propTypes = {}

export default AggregatedReviewsSourceContainer(AggregatedReviewsSourceSelect)
