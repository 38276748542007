import { connect } from 'react-redux'
import { fetchMatch } from '../actions/matches'
import { matchTimeLine } from '../selectors/matchTimeLineSelector'

function mapStateToProps(state, props) {
    const { matches, ...matchEntities } = state.matches.entities
    const match = matches && matches[props.matchId]
    return {
        match,
        matchEntities,
        timeLine: matchTimeLine(state, props),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMatch: (matchId) => dispatch(fetchMatch(matchId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
