import { Col, Row } from '@advanza/grid'
import { Button, Divider } from '@advanza/ui'
import LabelPicker from 'components/labels/LabelPicker'
import { utcToLocal } from 'date'
import React from 'react'
import DatePicker from '../DatePicker'

class ProfileReportGenerator extends React.Component {
    /** TODO: Cleanup */

    constructor(props) {
        super(props)
        const date = new Date()
        this.state = {
            branch: null,
            primary_label_id: 1,
            secondary_label_id: 1,
            start_date: date,
            end_date: date,
        }
    }

    formatDate(date) {
        return utcToLocal(date, 'yyyy-MM-dd')
    }

    changeLabel(label, type) {
        if (0 in label) {
            this.setState({
                [type]: label[0]['office_label_id'],
            })
        }
    }

    changeDate(event) {
        const type = event.target.name
        const date = new Date(event.target.value)
        this.setState({
            [type]: date,
        })
    }

    render() {
        const id_pri = this.state.primary_label_id
        const id_sec = this.state.secondary_label_id
        const start_date = this.formatDate(this.state.start_date)
        const end_date = this.formatDate(this.state.end_date)
        const endpoint = `/api/office/profile-report/create-profile-report-csv`
        const params = `?label_primary=${id_pri}&label_secondary=${id_sec}&start_date=${start_date}&end_date=${end_date}`
        const generate_href = endpoint + params
        return (
            <div>
                <Row>
                    {['primary_label_id', 'secondary_label_id'].map((type) => (
                        <Col sm="auto">
                            <small>{type}</small>
                            <LabelPicker
                                value={this.state[type]}
                                categories={['serviceProviders']}
                                onValueChange={(e) => this.changeLabel(e, type)}
                            />
                        </Col>
                    ))}
                </Row>
                <Divider l></Divider>
                <Row>
                    {['start_date', 'end_date'].map((type) => (
                        <Col sm="auto">
                            <DatePicker
                                onChange={this.changeDate.bind(this)}
                                dayPickerProps={{
                                    showOutsideDays: false,
                                }}
                                value={this.state[type]}
                                name={type}
                            />
                        </Col>
                    ))}
                </Row>
                <Divider l></Divider>
                <Row>
                    <Button>
                        <a target="_blank" external href={generate_href}>
                            Generate report
                        </a>
                    </Button>
                </Row>
            </div>
        )
    }
}

export default ProfileReportGenerator
