import React from 'react'
import { Route } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'

export const Rout = ({
    component,
    componentDesktop,
    renderDesktop,
    render,
    notRestricted,
    ...otherProps
}) => {
    const RouteComponent = notRestricted ? Route : PrivateRoute

    const shouldRenderMobile = window.isMobile && (component || render)
    const shouldRenderDesktop = !window.isMobile && (componentDesktop || renderDesktop)

    if (shouldRenderDesktop) {
        return (
            <RouteComponent {...otherProps} component={componentDesktop} render={renderDesktop} />
        )
    }
    if (shouldRenderMobile) {
        return <RouteComponent {...otherProps} component={component} render={render} />
    }

    return <RouteComponent {...otherProps} component={component} render={render} />
}
