import {
    Col,
    FieldInput,
    Msg,
    Row,
    Section,
    Select,
    Switch,
    Tab,
    Icon,
    WithIcon,
} from '@advanza/advanza_generic'
import { findGeoIdsByRange } from 'actions/subscriptions'
import ServiceAreaMap from 'components/subscriptions/ServiceAreaMap'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import PropTypes from 'prop-types'

import React from 'react'
import Chart from 'react-google-charts'
import LinearLoader from '../LinearLoader'

/**
 * Fetch, edit and save the service area for a subscription.
 *
 */
class SubscriptionArea extends React.Component {
    constructor(props) {
        super(props)
        this.toggleOption = this.toggleOption.bind(this)
        this.toggleOptions = this.toggleOptions.bind(this)
        this.saveGeoids = this.saveGeoids.bind(this)
        this.switchMode = this.switchMode.bind(this)
        this.renderList = this.renderList.bind(this)
        this.renderMap = this.renderMap.bind(this)
        this.state = {
            saving: false,
            mode: 'list',
            touched: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.subscriptionId !== this.props.subscriptionId) {
            const { fetchGeoIds } = this.props
            fetchGeoIds(this.props.subscriptionId)
        }
    }

    toggleOptions(options) {
        if (this.state.saving) {
            return
        }
        const { replaceSubscriptionGeoids, subscription, subscriptionId } = this.props
        const diff = options.filter((id) => subscription.subscriptions_geoids.indexOf(id) === -1)
        const shouldUncheck = diff.length === 0
        if (shouldUncheck) {
            replaceSubscriptionGeoids(
                subscriptionId,
                subscription.subscriptions_geoids.filter((id) => options.indexOf(id) === -1)
            )
        } else {
            replaceSubscriptionGeoids(
                subscriptionId,
                subscription.subscriptions_geoids.concat(options)
            )
        }
        this.saveGeoids()
    }

    toggleOption(e) {
        const { value } = e.target
        if (this.state.saving) {
            return
        }
        const { replaceSubscriptionGeoids, subscription, subscriptionId } = this.props
        const alreadyCheckedIndex = subscription.subscriptions_geoids.indexOf(value)
        const alreadyChecked = alreadyCheckedIndex !== -1
        const subscriptionsGeoIdsClone = subscription.subscriptions_geoids.slice(0)
        if (alreadyChecked) {
            subscriptionsGeoIdsClone.splice(alreadyCheckedIndex, 1)
            replaceSubscriptionGeoids(subscriptionId, subscriptionsGeoIdsClone)
        } else {
            replaceSubscriptionGeoids(subscriptionId, subscriptionsGeoIdsClone.concat([value]))
        }
        this.saveGeoids()
    }

    saveGeoids() {
        const { saveGeoIds, subscription, replaceSubscriptionGeoids, subscriptionId } = this.props
        const { mode } = this.state
        if (this.state.saving) {
            return
        }
        this.setState({ saving: true })

        const afterSave = () => this.setState({ saving: false, touched: false })

        if (mode !== 'maps') {
            saveGeoIds(subscription.subscription_id).then(afterSave, afterSave)
        } else {
            const postalCode = subscription.postal_codes.length > 0 && subscription.postal_codes[0]
            if (!postalCode) {
                return this.setState({ saving: false })
            }
            const { latitude, longitude, distance } = postalCode
            findGeoIdsByRange(latitude, longitude, distance, subscription.country_code).then(
                (geoIds) => {
                    replaceSubscriptionGeoids(subscriptionId, geoIds)
                    saveGeoIds(subscription.subscription_id).then(afterSave)
                },
                afterSave
            )
        }
    }

    switchMode(mode) {
        this.setState({ mode })
    }

    renderMap() {
        const { subscription, changeSubscriptionTravelRange } = this.props
        const { touched, saving } = this.state
        const postalCode =
            (subscription &&
                subscription.postal_codes.length > 0 &&
                subscription.postal_codes[0]) ||
            {}
        const touch = () => this.setState({ touched: true })
        const afterPlaceChanged = (place) => {
            changeSubscriptionTravelRange(subscription.subscription_id, {
                ...place,
                distance: postalCode.distance,
            })
            touch()
        }
        const rangeChange = (distance) => {
            changeSubscriptionTravelRange(subscription.subscription_id, {
                ...postalCode,
                distance,
            })
            touch()
        }
        return (
            <Section>
                <Row nm className="v-center" s="min">
                    <Col c="8">
                        {postalCode && (
                            <SearchLocationInput
                                dontSearchOnInit
                                locationQuery={postalCode.place_name}
                                countryCode={subscription.country_code}
                                showIcon
                                value={postalCode.place_name}
                                onChangeLocation={afterPlaceChanged}
                            />
                        )}
                    </Col>
                    <Col c="3" np>
                        {postalCode && (
                            <FieldInput
                                newGStyle
                                state={{
                                    value: postalCode.distance === -1 ? 50 : postalCode.distance,
                                }}
                                type="number"
                                max={100}
                                className="commute-input"
                                icon={!window.isMobile && 'commute'}
                                msgid="How far will you travel?"
                                onChange={({ target }) =>
                                    rangeChange(target.value > 100 ? 100 : target.value)
                                }
                            />
                        )}
                    </Col>
                    <Col np c="1">
                        &nbsp;{'km'}
                    </Col>
                </Row>
                <Section s>
                    {postalCode && <ServiceAreaMap postalCodeEntity={postalCode} />}
                </Section>

                {touched && (
                    <button onClick={this.saveGeoids} className="btn cta animate-in">
                        <Msg id={saving ? 'saving' : 'save'} />
                    </button>
                )}
            </Section>
        )
    }

    _loopGeoids(func) {
        const { subscription } = this.props
        const { result, entities } = this.props.geoIds
        return (
            subscription &&
            result &&
            result
                .filter(({ country }) => country === subscription.country_code)
                .map(({ regionName, geoIds, ...rest }) => {
                    let regionChecked = true
                    let totalPopulation = 0
                    let checkedPopulation = 0
                    const checkedPlaces = []
                    const checkedOptions = []
                    const options = geoIds.map((geoId) => {
                        const postalCode = entities[geoId]
                        totalPopulation += postalCode.population
                        const isChecked =
                            subscription.subscriptions_geoids.filter((id) => id === geoId).length >
                            0
                        const option = {
                            name: geoId,
                            value: geoId,
                            queryField: postalCode.name,
                            title: <span>{postalCode.name}</span>,
                        }
                        if (!isChecked) {
                            regionChecked = false
                        } else {
                            checkedPlaces.push(postalCode.place_name)
                            checkedOptions.push(option)
                            checkedPopulation += postalCode.population
                        }
                        return option
                    })
                    return func({
                        regionName,
                        geoIds,
                        regionChecked,
                        checkedOptions,
                        checkedPlaces,
                        entities,
                        checkedByPopulation: (100 / totalPopulation) * checkedPopulation,
                        checkedPopulation,
                        options,
                    })
                })
        )
    }

    renderList() {
        return (
            <Section>
                {this._loopGeoids(
                    ({ regionName, geoIds, regionChecked, checkedOptions, options }) => {
                        return (
                            <Row key={regionName} className="no-wrap v-center" s="min">
                                <div style={{ width: 50 }}>
                                    <Switch
                                        onChange={(e) => this.toggleOptions(geoIds)}
                                        checked={regionChecked}
                                    />
                                </div>
                                <Col style={{ maxWidth: 'calc(100% - 50px)' }} c={12}>
                                    <Select
                                        onChange={this.toggleOption}
                                        msg={regionName}
                                        multiple
                                        staticBody
                                        useSearch
                                        options={options}
                                        value={checkedOptions}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                )}
            </Section>
        )
    }

    getRegionCode(region) {}
    geoChart() {
        const values = []
        let population = 0
        const country = this.props.subscription.country_code
        this._loopGeoids(
            ({
                regionName,
                options,
                geoIds,
                entities,
                checkedOptions,
                checkedPopulation,
                checkedByPopulation,
            }) => {
                const code =
                    country === 'NL'
                        ? regionName.indexOf('-') !== -1
                            ? regionName
                                  .split('-')
                                  .map((val) => val.charAt(0))
                                  .join('')
                            : regionName.substr(0, 2)
                        : entities[geoIds[0]].iso
                values.push([`${country}-${code.toUpperCase()}`, checkedByPopulation])
                population += checkedPopulation
            }
        )
        return (
            <div>
                <Chart
                    chartType="GeoChart"
                    data={[['Provinces', 'Coverage'], ...values]}
                    options={{
                        region: country,
                        displayMode: 'regions',
                        resolution: 'provinces',
                        backgroundColor: '#fff',
                        datalessRegionColor: '#fff',
                        colorAxis: {
                            minValue: 0,
                            maxValue: 100,
                            colors: ['#fff', '#5eb933'],
                        },
                    }}
                    mapsApiKey="AIzaSyAuOvu_ssjMyygZX7GFSOs7rXW7Obt_wqk"
                />
                <Icon small>people</Icon> <small>{population}</small>
            </div>
        )
    }

    render() {
        const { saving, mode } = this.state
        return (
            <div className="pos-rel">
                {saving && <LinearLoader fixed />}
                <Section x y>
                    {this.geoChart()}
                </Section>
                <div className={`subscription-area  ${saving ? 'saving' : ''}`}>
                    <Tab
                        className="center"
                        value={this.state.mode}
                        onChange={this.switchMode}
                        options={[
                            {
                                id: 'list',
                                name: (
                                    <WithIcon icon="list" align="middle">
                                        Cities
                                    </WithIcon>
                                ),
                            },
                            {
                                id: 'maps',
                                name: (
                                    <WithIcon icon="near_me" align="middle">
                                        Range
                                    </WithIcon>
                                ),
                            },
                        ]}
                    />

                    {mode === 'list' ? this.renderList() : this.renderMap()}
                </div>
            </div>
        )
    }
}

SubscriptionArea.propTypes = {
    changeSubscriptionTravelRange: PropTypes.func,
    fetchGeoIds: PropTypes.any,
    geoIds: PropTypes.object,
    replaceSubscriptionGeoids: PropTypes.func,
    saveGeoIds: PropTypes.func,
    subscription: PropTypes.object,
    subscriptionId: PropTypes.any.isRequired,
}

export default SubscriptionArea
