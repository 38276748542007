import { call } from '@advanza/api'
import { SelectSimple } from '@advanza/input'
import { useActionModal } from '@advanza/modal'
import { Button } from '@advanza/ui'
import DatePicker from 'components/DatePicker'
import { useToast } from 'components/misc/Toaster'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import style from './resendFailedEmails.module.css'

const ResendFailedEmails = () => {
    const { addToast } = useToast()
    const [filter, setFilter] = useState({
        eventDate: format(new Date(), 'yyyy-MM-dd'),
        eventCategory: 'afterMatch',
    })

    const { openModal, renderModal } = useActionModal()
    const [isLoading, setIsLoading] = useState(false)

    const onDateChange = (e) => {
        const { name, value } = e.target
        setFilter({ ...filter, [name]: value })
    }

    const onSend = (additional = {}) => {
        if (isLoading) {
            return Promise.resolve()
        }

        setIsLoading(true)
        return call('office/misc/resend-failed-emails-job', {
            json: {
                ...filter,
                ...additional,
            },
        })
            .then(
                () => {
                    addToast({ msg: 'Job created', green: true, icon: 'rowing' })
                },
                (err) => alert(JSON.stringify(err, null, 2))
            )
            .finally(() => setIsLoading(false))
    }

    return (
        <div className={style.root}>
            <div className={style.flex}>
                <div>
                    <DatePicker
                        legend
                        placeholder="Date"
                        onChange={onDateChange}
                        dayPickerProps={{
                            disabled: { after: new Date() },
                            showOutsideDays: false,
                        }}
                        value={filter.eventDate}
                        name="eventDate"
                    />
                </div>
                <div>
                    <SelectSimple
                        legend
                        placeholder="Event category"
                        className={style.eventSelect}
                        options={[
                            { name: 'Request confirmation', value: 'requestConfirmation' },
                            { name: 'After match', value: 'afterMatch' },
                            { name: 'Message mails', value: 'messageMails' },
                            { name: 'Reclaim answer', value: 'reclaimAnswer' },
                            { name: 'Premium cancel', value: 'premiumCancel' },
                            { name: 'Monthly report', value: 'monthlyReport' },
                        ]}
                        value={filter.eventCategory}
                        onChange={(e) => setFilter({ ...filter, eventCategory: e.target.value })}
                    />
                </div>
                <div>
                    <Button disabled={isLoading} onClick={openModal} className={style.button}>
                        Resend emails
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={isLoading}
                        onClick={() => onSend({ eventCategory: 'all', exportEvents: true })}
                        name="yellowAlt"
                        className={style.button}>
                        Export all categories
                    </Button>
                </div>
            </div>
            {renderModal({
                header: 'Resend failed emails',
                body: (
                    <div className={style.confirmationText}>
                        This will resend all failed emails from <strong>{filter.eventDate}</strong>{' '}
                        and event category <strong>{filter.eventCategory}</strong>. Are you sure?
                    </div>
                ),
                cancelText: 'Cancel',
                text: 'Yes, resend mails',
                func: () => onSend({ resendEmails: true }),
            })}
        </div>
    )
}

export default ResendFailedEmails
