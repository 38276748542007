import { ADMINUSERS_REQUEST, ADMINUSERS_RECEIVE_LIST } from 'actions/adminUsers'
import { changeEntity } from 'actions/entities'
import { _fetchItems } from 'actions/lists'
import { useDispatch, useSelector } from 'react-redux'
import adminUsersSchema from 'schemes/adminUsersSchema'
import regionsSchema from 'schemes/regionsSchema'
import { call } from '@advanza/api'

export function fetchRegions(filterId = 'all') {
    return _fetchItems(filterId, {
        url: 'office/regions/get-list',
        schema: regionsSchema,
        requestActionName: 'REGIONS_REQUEST',
        receiveActionName: 'REGIONS_RECEIVE_LIST',
        reducerKey: 'regions',
    })
}

export function saveRegionContent(id) {
    return function (dispatch, getState) {
        const data = getState().regions.entities.regionContent[id]
        dispatch(
            changeEntity({
                store: 'regions',
                name: 'regionContent',
                key: id,
                diff: { _saving: true },
            })
        )
        return call('office/regions/save-content/' + id, { json: data }).then(() => {
            dispatch({ type: 'INVALIDATE_REGIONS' })
        }, console.log)
    }
}
