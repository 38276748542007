import { call } from '@advanza/api'
import { fetchRegions } from 'actions/regions'
import { useList } from 'hooks/listHooks'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { schema, normalize } from 'normalizr'
import relcaimsSchema from 'schemes/reclaimsSchema'
import regionsSchema from 'schemes/regionsSchema'

export function useRegionsList(filterId, defaultOptions) {
    const { countries } = useSelector((state) => state.countries)
    return useList(
        filterId,
        {
            url: 'office/regions/get-list',
            schema: regionsSchema,
            reducerKey: 'regions',
        },
        { ...defaultOptions, countries }
    )
}
