import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import { useChangeEntity } from 'hooks/entityHooks'

const fields = () => {
    return {
        use_construction_date: {
            type: 'boolean',
            msg: 'Construction date',
        },
        use_status: {
            type: 'boolean',
            msg: 'Status',
        },
        use_object_type: {
            type: 'boolean',
            msg: 'Object type',
        },
    }
}

const BagUse = ({ entityId }) => {
    const { countries = [] } = useSelector((state) => state.countries)
    const { renderInput } = useChangeEntity(
        {
            store: 'services',
            name: 'bag_uses',
            entityId,
        },
        fields()
    )

    return countries.indexOf('NL') === -1 ? null : (
        <>
            <div style={{ textAlign: 'left' }}>Add Kadaster data (NL)</div>
            <Divider m />
            <Row middle="xs">
                <Col x>{renderInput('use_construction_date')}</Col>
                <Col x>{renderInput('use_status')}</Col>
                <Col x>{renderInput('use_object_type')}</Col>
            </Row>
        </>
    )
}

export default BagUse
