import { call } from '@advanza/api'
import { Row, Col } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, Divider, LoadingDots, Icon } from '@advanza/ui'
import DatePicker from 'components/DatePicker'
import { getDate } from 'date'
import { format } from 'date-fns'
import LoadingDotsTeam from 'misc/LoadingDotsTeam'
import React, { useState } from 'react'

function useGenerateEmailImages(serviceId) {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [date, setDate] = useState(false)
    const [time, setTime] = useState(false)
    const [tasks, setTasks] = useState(null)

    // Get the previously created tasks with their status.
    const getTasks = () => {
        call('office/services/generate-email-images-tasks/' + serviceId).then(({ tasks }) => {
            setTasks(
                tasks.map((task) => {
                    const isFinished = task.finished_at
                    const inProgress = task.started_at && !isFinished
                    const isFuture = !isFinished && !inProgress && task.scheduled_at
                    const icon = isFinished ? 'check' : inProgress ? 'play_arrow' : 'event_upcoming'
                    const waitMinutes =
                        isFuture && 7 - (getDate(task.scheduled_at).getMinutes() % 7)

                    return {
                        isFuture,
                        isFinished,
                        inProgress,
                        scheduled: format(getDate(task.scheduled_at), 'd MMM H:mm'),
                        finished:
                            (task.finished_at && format(getDate(task.finished_at), 'd MMM H:mm')) ||
                            '',
                        waitMinutes,
                        icon,
                        ...task,
                    }
                })
            )
        })
    }

    const generate = () => {
        setIsLoading(true)
        return call('office/services/generate-email-images/' + serviceId, {
            method: 'post',
            payload: {
                scheduleDate: date && `${date} ${time || '00:00:00'}`,
            },
        })
            .then(() => {
                setResponse('Task added, please wait for it finish')
            }, setResponse)
            .finally(() => setIsLoading(false))
    }

    return {
        generate,
        isLoading,
        response,
        getTasks,
        tasks,
        isOpen,
        setOpen,
        date,
        setDate,
        time,
        setTime,
        close: () => {
            setResponse(null)
            setOpen(false)
        },
    }
}

const GenerateTop10EmailWidgetsButton = ({ serviceId }) => {
    const {
        isLoading,
        generate,
        getTasks,
        date,
        tasks,
        setDate,
        setTime,
        time,
        isOpen,
        setOpen,
        response,
        close,
    } = useGenerateEmailImages(serviceId)
    return (
        <>
            <Button name="borderedPoppins" onClick={setOpen} disabled={isLoading}>
                {isLoading ? (
                    <LoadingDotsTeam size={22} name="wurst" />
                ) : (
                    'Generate top10 images for email'
                )}
            </Button>
            <button title="Get previous tasks">
                <Icon name="playlist_add_check_circle" onClick={getTasks} />
            </button>
            {tasks && (
                <div>
                    {tasks.map(
                        ({
                            isFinished,
                            inProgress,
                            waitMinutes,
                            isFuture,
                            finished,
                            scheduled,
                            icon,
                        }) => (
                            <div key={scheduled}>
                                <Icon fontSize={18} green={isFinished} name={icon} />
                                {' scheduled: '}
                                {scheduled}
                                {isFuture && ` wait ${waitMinutes} minutes`}
                                {isFinished && ` finished: ${finished}`}
                                {inProgress && ` in progress`}
                            </div>
                        )
                    )}
                    {tasks && tasks.length === 0 && <span>No tasks</span>}
                </div>
            )}
            <Modal open={isOpen} close={close}>
                <Divider />
                <h3>Create top10 sticker images for AC emails </h3>
                <Divider m />
                <Button onClick={generate}>
                    {isLoading ? (
                        <LoadingDots />
                    ) : date ? (
                        <>
                            Schedule at {date} {time || '00:00'}
                        </>
                    ) : (
                        'Generate now'
                    )}
                </Button>
                <Divider l name="border" />
                {!date && 'Or schedule the task to run at a later moment:'}
                <Divider m />
                <Row>
                    <Col x>
                        <DatePicker
                            value={date}
                            format={'YYYY-MM-DD'}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder={'Select date'}
                            dayPickerProps={{
                                disabled: { before: new Date() },
                                showOutsideDays: false,
                            }}
                            opened
                        />
                    </Col>
                    <Col x>
                        <SelectSimple
                            placeholder="select timeslot"
                            onChange={(e) => setTime(e.target.value)}
                            hidePlaceholder
                            value={time}
                            emptyOption="00:00"
                            options={[
                                { value: '00:00', name: '00:00' },
                                { value: '06:00', name: '06:00' },
                                { value: '09:00', name: '09:00' },
                                { value: '15:00', name: '15:00' },
                                { value: '23:00', name: '23:00' },
                                { value: '04:00', name: '04:00' },
                            ]}
                        />
                    </Col>
                </Row>
                {date && (
                    <button
                        onClick={() => {
                            setDate(null)
                            setTime(null)
                        }}>
                        Reset
                    </button>
                )}
                <Divider xl name="border" />
                {isLoading && <LoadingDotsTeam name="dion" size={50} />}
                {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
                <Divider xxl />
            </Modal>
        </>
    )
}

export default GenerateTop10EmailWidgetsButton
