import { useScrollModal } from '@advanza/modal'
import { PreIcon } from '@advanza/ui'
import CalendlyEmbeddedForm from 'components/providers/CalendlyEmbeddedForm'
import FormattedTime from 'components/ui/FormattedTime'
import { useProvider } from 'hooks/providerHooks'
import React from 'react'
import { useDispatch } from 'react-redux'

function useCalendlyModal(providerId, calendlyEvent = {}) {
    const dispatch = useDispatch()
    const { openModal, renderModal } = useScrollModal()
    const { provider = {} } = useProvider(providerId)
    const calendlyEventId = (calendlyEvent && calendlyEvent.calendly_event_id) || null
    const isReschedule = Boolean(calendlyEventId)

    const modal = renderModal({
        heading: (
            <>
                {isReschedule ? 'Reschedule onboarding call' : 'Schedule onboarding call'} with{' '}
                {provider.business_name}
            </>
        ),
        headingBgColor: 'light',
        sub: isReschedule ? (
            <div>
                Original time:{' '}
                <FormattedTime
                    date={calendlyEvent.start_time}
                    format="EEEE d MMMM yyyy 'at' HH:mm"
                />
            </div>
        ) : null,
        body: (
            <CalendlyEmbeddedForm
                formName={`modal-form-${calendlyEventId}`}
                providerId={providerId}
                calendlyEventId={calendlyEventId}
            />
        ),
        maxWidth: '1010px',
        hideScrollBar: true,
        hideBodyPadding: true,
    })

    const onOpenModal = () => {
        // trigger window event listeners
        dispatch({ type: 'CALENDLY_OPEN_MODAL', providerId, calendlyEventId })

        // open modal
        openModal()
    }

    return {
        modal,
        onOpenModal,
        isReschedule,
    }
}

const CalendlyModal = ({ providerId, calendlyEvent = {} }) => {
    const { modal, onOpenModal, isReschedule } = useCalendlyModal(providerId, calendlyEvent)

    return (
        <>
            <button
                onClick={onOpenModal}
                style={{
                    color: '#f46511',
                    textDecoration: 'none',
                }}>
                <PreIcon name="edit_calendar" fontSize={18} orange>
                    {isReschedule ? 'Reschedule' : 'Schedule onboarding call'}
                </PreIcon>
            </button>
            {modal}
        </>
    )
}

export default CalendlyModal
