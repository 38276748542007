import { call } from '@advanza/api'
import { InputField } from '@advanza/input'
import { Button, PreIcon, LoadingDots, MaxWidth, Divider } from '@advanza/ui'
import { fetchAllSources } from 'actions/aggregatedReviews'
import { FixedWarningPopup } from '@advanza/ui'
import Boolean from 'components/ui/Boolean'
import Page from 'components/ui/Page'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './aggregatedReviewsPage.module.css'

const AggregatedReviewsPage = () => {
    const dispatch = useDispatch()
    const { sources } = useSelector((state) => state.aggregatedReviews)
    const [reFetch, setReFetch] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    useEffect(() => {
        setIsFetching(true)
        dispatch(fetchAllSources(true)).then(() => setIsFetching(false))
    }, [reFetch])
    const [edit, setEdit] = useState(false)

    return (
        <Page>
            <Divider l />
            <MaxWidth>
                <div className={[style.root, isFetching ? style.loading : ''].join(' ')}>
                    <h3>Aggregated reviews</h3>
                    <Divider ml />
                    {isFetching && <LoadingDots />}
                    {edit === 'new' && (
                        <AggregatedReviewEntity reFetch={setReFetch} close={() => setEdit(false)} />
                    )}
                    {edit !== 'new' && (
                        <Button name="text" onClick={() => setEdit('new')}>
                            <PreIcon icon="add" primColor>
                                Add review source
                            </PreIcon>
                        </Button>
                    )}
                    <Divider l />
                    <b>Review sources:</b>
                    <Divider />
                    <table>
                        <tbody>
                            <th>id</th>
                            <th>name</th>
                            <th>url</th>
                            <th>rating scale</th>
                            {sources.map((source) => {
                                const id = source.aggregated_reviews_source_id
                                if (edit === id) {
                                    return (
                                        <tr key={id}>
                                            <td colspan="12">
                                                <AggregatedReviewEntity
                                                    reFetch={setReFetch}
                                                    close={() => setEdit(false)}
                                                    source={source}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                                return (
                                    <tr onClick={() => setEdit(id)} key={id}>
                                        <td>{id}</td>
                                        <td>{source.source_name}</td>
                                        <td>{source.url}</td>
                                        <td>{source.rating_scale_is_ten ? 10 : 5}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </MaxWidth>
        </Page>
    )
}

const AggregatedReviewEntity = ({ close, source = {}, reFetch }) => {
    const [values, setValues] = useState(source)
    const [isSaving, setIsSaving] = useState(false)
    const [response, setResponse] = useState(false)
    const onSave = () => {
        if (!isSaving) {
            setIsSaving(true)
            call('office/aggregated-reviews/save-source/', {
                json: {
                    ...values,
                    rating_scale_is_ten: !!values.rating_scale_is_ten,
                },
            })
                .then((r) => {
                    setResponse(r)
                    reFetch(Date.now())
                    close()
                }, setResponse)
                .finally(() => setIsSaving(false))
        }
    }
    return (
        <div className={style.edit}>
            <b>{values.source_name}</b>
            <Divider m />
            <InputField
                onChange={(e) => setValues({ ...values, source_name: e.target.value })}
                value={values.source_name}
                placeholder="name"
            />
            <div className={style.row}>
                <InputField
                    onChange={(e) => setValues({ ...values, url: e.target.value })}
                    value={values.url}
                    placeholder="url"
                />
                <Boolean
                    name="rating scale is 10"
                    value={values.rating_scale_is_ten}
                    onChange={() =>
                        setValues({
                            ...values,
                            rating_scale_is_ten: values.rating_scale_is_ten !== true,
                        })
                    }
                />
            </div>
            <div className={style.row}>
                <Button onClick={onSave} disabled={isSaving}>
                    {isSaving ? <LoadingDots /> : 'Save'}
                </Button>
                <Button name="text" onClick={close}>
                    cancel
                </Button>
            </div>
            <FixedWarningPopup show={response} red={response.status !== 'success'}>
                {response.status !== 'success' && (
                    <pre style={{ width: '100%' }}>{JSON.stringify(response, null, 2)}</pre>
                )}
            </FixedWarningPopup>
        </div>
    )
}

export default AggregatedReviewsPage
