import CheckButton from 'components/CheckButton'
import React from 'react'

export default ({ value, onChange, name = 'test' }) => {
    return (
        <CheckButton
            checked={value}
            msg={name}
            onChange={(e) => {
                onChange(value !== true)
            }}
        />
    )
}
