import { createReducer } from '@advanza/advanza_generic'

const handlers = {
    ADD_TOAST: (state, action) => {
        return { ...state, [action.toast.id]: action.toast }
    },
    DELETE_TOAST: (state, action) => {
        return {
            ...Object.keys(state).reduce((result, id) => {
                if (id !== String(action.id)) {
                    result[id] = state[id]
                }
                return result
            }, {}),
        }
    },
    DELETE_ALL: () => ({}),
}

const initialState = {}

export default createReducer(initialState, handlers)
